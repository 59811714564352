import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  Input,
  RadioGroup,
  ToggleButton,
} from '@alfredclub/hom-planner-theme';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import { useDefaultValueRadioGroup } from '../../../hooks';
import { useSelector, useDispatch } from 'react-redux';
import { Register, SetValue } from '../../../models/commonTypes';
import {
  DeliveryOptions,
  EventLeadRequired,
  selectedTagColor,
  selectedTagTextColor,
} from '../../../constants';
import { getServiceCategories, IServiceState } from '../../../redux/service';
import {
  getCategoryTags,
  clearCategoryTags,
  clearServiceCategoryTags,
} from '../../../redux/service/services.action';

const BasicDetails: React.FC<{
  formErrors: Record<string, object>;
  register: Register;
  setValue: SetValue;
  serviceData: object;
  isEdit: boolean;
  onChangeServiceName: any;
  isPackage: boolean;
}> = ({
  formErrors,
  register,
  setValue,
  serviceData,
  isEdit,
  onChangeServiceName,
  isPackage,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false);
  const { categories, categoryTagsList, serviceCategoryTagsList } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const [checked, setChecked] = useState(serviceData?.['status']);
  const [bookingChecked, setBookingChecked] = useState(
    serviceData?.['recurringBooking']
  );
  const [deliveryOptions, setDeliveryOptions] = useState(DeliveryOptions);
  const [eventLeadOptions, setEventLeadOptions] = useState(EventLeadRequired);
  useEffect(() => {
    if (serviceCategoryTagsList?.length) {
      setValue('categoryTags', serviceCategoryTagsList);
    }
    return () => {
      dispatch(clearCategoryTags());
      dispatch(clearServiceCategoryTags());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceCategoryTagsList]);
  useDefaultValueRadioGroup(
    serviceData,
    'virtualMode',
    deliveryOptions,
    setDeliveryOptions,
    isEdit
  );
  useDefaultValueRadioGroup(
    serviceData,
    'eventLeadRequired',
    eventLeadOptions,
    setEventLeadOptions,
    isEdit
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    arrayKey: any[],
    name: string,
    setAction: {
      (
        value: React.SetStateAction<
          {
            id: any;
            title: string;
            value: boolean;
            labelClass: string;
            checked: boolean;
          }[]
        >
      ): void;
      (
        // tslint:disable-next-line:unified-signatures
        value:
          | React.SetStateAction<
              {
                id: any;
                title: string;
                value: boolean;
                labelClass: string;
                checked: boolean;
              }[]
            >
          | any[]
      ): void;
    }
  ) => {
    const updatedOptions = arrayKey.filter((item, itemIndex) => {
      if (itemIndex !== index) {
        item.checked = false;
      } else {
        arrayKey[index].checked = e.currentTarget.checked;
      }
      return arrayKey;
    });
    setValue(
      name,
      name !== 'eventLeadRequired'
        ? arrayKey[index].value === false
          ? false
          : true
        : arrayKey[index].value
    );
    setAction([...updatedOptions]);
  };

  return (
    <div className={`mt-16`}>
      <FormTitle title="Basic Details" />
      {Object.keys(formErrors).length > 0 && (
        <span className="error-message">Please fill required fields</span>
      )}
      <div className="grid grid-cols-2 gap-x-2 gap-y-0">
        <div>
          <Input
            isLabelShow={true}
            label="Service Name"
            name="name"
            ref={register({ required: true })}
            onInputChange={(e) => {
              onChangeServiceName(e.currentTarget.value);
            }}
            isError={formErrors['name'] ? true : false}
            value={serviceData?.['name']}
            isMandatory={true}
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Display Name"
            name="displayName"
            ref={register}
            placeholder="Enter here"
          />
        </div>
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={true}
            label="Category"
            options={categories}
            onMenuOpen={async () => {
              setLoading(true);
              if (categories.length === 0) {
                await dispatch(getServiceCategories());
              }
              setLoading(false);
            }}
            loading={loading}
            ref={register({ name: 'elementCategory' }, { required: false })}
            onOptionChange={(e) => {
              setValue('elementCategory', e);
            }}
            placeholder=""
            className="drop_input_label"
            value={{
              name: serviceData?.['elementCategory']?.['name'],
              id: serviceData?.['elementCategory']?.['id'],
            }}
          />
        </div>
        <div>
          <ToggleButton
            label="Active Service"
            onChange={(e) => setChecked(e.currentTarget.checked)}
            isLabelShow={checked}
            checked={checked}
            uncheckedText="Inactive"
            checkedText="Enabled"
            name="status"
            ref={register}
          />
        </div>
        <div>
          <RadioGroup
            options={deliveryOptions}
            label="Mode of Delivery"
            onChange={(_, index) =>
              onChange(
                _,
                index,
                deliveryOptions,
                'virtualMode',
                setDeliveryOptions
              )
            }
            isLabelShow={true}
            ref={() => register({ name: 'virtualMode' })}
          />
        </div>
        <div>
          <ToggleButton
            label="Recurring booking"
            onChange={(e) => setBookingChecked(e.currentTarget.checked)}
            isLabelShow={bookingChecked}
            secondaryLabel=""
            checked={bookingChecked}
            uncheckedText="Inactive"
            checkedText="Enabled"
            name="recurringBooking"
            ref={register}
          />
        </div>
        {/* <div className="pointer-events-none">
          <RadioGroup
            options={eventLeadOptions}
            label="Event Lead Required"
            onChange={(_, index) =>
              onChange(
                _,
                index,
                eventLeadOptions,
                'eventLeadRequired',
                setEventLeadOptions
              )
            }
            isLabelShow={true}
            ref={register({ name: 'eventLeadRequired' })}
          />
        </div> */}
        <div>
          <Input
            isLabelShow={true}
            label="Maximum attendees"
            name="maxAttendees"
            ref={register({
              validate: {
                positive: (value: string) => parseInt(value, 10) >= 0,
              },
            })}
            inputType="number"
            isError={formErrors?.['maxAttendees'] ? true : false}
          />
        </div>
        <div className="col-span-2">
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={true}
            isMulti={true}
            label="Category tag"
            options={categoryTagsList}
            onMenuOpen={async () => {
              setCategoryLoading(true);
              await dispatch(getCategoryTags());
              setCategoryLoading(false);
            }}
            ref={() => register({ name: 'categoryTags' })}
            onOptionChange={async (e) => {
              setValue('categoryTags', e === null ? [] : e);
            }}
            placeholder="Select"
            value={
              serviceCategoryTagsList?.length
                ? serviceCategoryTagsList
                : serviceData?.['categoryTags']?.length &&
                  serviceData?.['categoryTags']
            }
            className="service_input_width categoryTags"
            backgroundColor={selectedTagColor}
            textColor={selectedTagTextColor}
            loading={categoryLoading}
            disabled={isPackage}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
