import React, { useState } from 'react';
import { Button, PrimaryLabel, TextArea } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import ModalPopup from '../ReactModal/Modal';
import { useDispatch } from 'react-redux';
import {
  createInventoryItems,
  getInventoryItems,
} from '../../redux/inventory/inventory.action';

interface IFormInput {
  itemList: string;
}
const AddNewItemPopup: React.FC<{ modalState: boolean; onClosePopup: any }> = ({
  modalState,
  onClosePopup,
}: any) => {
  const dispatch = useDispatch();
  const [checkItems, setCheckItemState] = useState<boolean>(false);
  const { register, errors, handleSubmit } = useForm<IFormInput>();
  const onSubmit = async (data: IFormInput) => {
    setCheckItemState(false);
    const newItemList = data.itemList.split(',').map((item: string) => {
      if (item.trim() !== '') {
        return { name: item, location: '' };
      } else {
        return null;
      }
    });
    const itemListArray = newItemList.filter((item) => item !== null);
    if (itemListArray.length > 0) {
      await dispatch(createInventoryItems({ items: itemListArray }));
      await dispatch(getInventoryItems());
      onModalClose();
    } else {
      setCheckItemState(true);
    }
  };

  const onModalClose = () => {
    setCheckItemState(false);
    onClosePopup();
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onModalClose}
      className="inventory_modal"
      shouldCloseOnOverlayClick={false}
    >
      <PrimaryLabel
        labelText="Add New Items (comma-separated)"
        className="item_label"
      />
      <PrimaryLabel labelText="*" className="text-red-500" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          label=""
          placeholder="Item 1, Item 2"
          name="itemList"
          ref={register({ required: true })}
          className="textarea-popup"
        />
        {(errors.itemList || checkItems) && (
          <div className="error">Please enter comma-separated value</div>
        )}
        <Button label="Add Items" className="add_item_button" />
      </form>
    </ModalPopup>
  );
};

export default AddNewItemPopup;
