// tslint:disable: semicolon
import React, { Component } from 'react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import MessageForm from './MessageForm';
import { IChatMessageProps } from '../../../models';
import { getImageUrl } from '../../../utils';
class Message extends Component<IChatMessageProps, { showEditForm: boolean }> {
  state = {
    showEditForm: false,
  };
  onEditClick = () => {
    this.setState({
      showEditForm: !this.state.showEditForm,
    });
  };
  render() {
    const {
      isTeacher,
      teacherName,
      message,
      username,
      onUpdateMessage,
    } = this.props;
    const { showEditForm } = this.state;
    const me = username === message.author;
    const classes = classNames('Message', {
      log: !message.author,
      me,
    });
    return (
      <div className={classes}>
        {message.author && (
          <>
            <div className="message-layout">
              {(isTeacher && me) ||
              message.author === teacherName ||
              message.author === 'hOM Admin' ? (
                <img
                  alt="user-icon"
                  src={getImageUrl('Events/adminthumbnailforchat.svg')}
                />
              ) : (
                <div>
                  <p className="author-circle">{message.author[0]}</p>
                </div>
              )}

              <div className="message-body">
                <div
                  className={
                    (isTeacher && me) || message.author === teacherName
                      ? 'admin-author font-medium'
                      : 'author font-medium'
                  }
                >
                  {message.author}
                  <img
                    alt="edit-icon"
                    src={getImageUrl('ConsumerApp/give-feedback.svg')}
                    data-tip="Edit message"
                    data-for="editMessage"
                    onClick={this.onEditClick}
                    className="edit-icon"
                  />

                  <ReactTooltip
                    id="editMessage"
                    className="custom-tooltip"
                    effect="solid"
                    html={true}
                    place="left"
                    multiline={false}
                  />
                </div>
                <div className="user-message">
                  {showEditForm ? (
                    <MessageForm
                      askUserName={false}
                      pastEvent={false}
                      btnText="Submit"
                      editFormStyle="edit-form"
                      body={message.body}
                      editForm={showEditForm}
                      disabled={false}
                      messageId={message.sid}
                      onUpdateMessage={onUpdateMessage}
                      onEditFormSubmit={this.onEditClick}
                    />
                  ) : message.body && message.body.includes('http') ? (
                    <a href={message.body} target="_blank" rel="noreferrer">
                      {message.body}
                    </a>
                  ) : (
                    message.body
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Message;
