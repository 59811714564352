import { Input } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { removeImage } from '../../../redux/common/common.action';
import { getUploadedImageUrl, loadState, splitImageName } from '../../../utils';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import FileInput from '../../Common/FileInput';
import { useDispatch } from 'react-redux';
import { Register, Vendor } from '../../../models';

const Certifications: React.FC<{
  register: Register;
  vendorData: Vendor;
  reset: (
    values?: Record<string, any>,
    omitResetState?: Record<string, boolean>
  ) => void;
  refreshIndividualForm: (form: Vendor) => void;
}> = ({ register, vendorData, reset, refreshIndividualForm }) => {
  const dispatch = useDispatch();
  const onRemoveImage = async (name: string) => {
    await dispatch(removeImage({ [name]: { id: null } }, 'IndVendorForm'));
    reset(loadState('vendorIndividualForm')); // File list error resolved
    refreshIndividualForm(loadState('vendorIndividualForm')); // Refresh New state
  };

  return (
    <>
      <FormTitle title="Certifications and Images" />
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          {!vendorData?.['certification']?.['file'] ? (
            <FileInput
              label="Certifications"
              isLabelShow={true}
              inputSectionClass="m-0"
              name="certification.id"
              ref={register}
            />
          ) : (
            <Input
              isLabelShow={true}
              isAnchor={true}
              anchorHref={getUploadedImageUrl(
                vendorData?.['certification']?.['file']
              )}
              label="Certifications"
              anchorText={`${splitImageName(
                vendorData?.['certification']?.['file']
              )}`}
              onRemoveImage={() => onRemoveImage('certification')}
            />
          )}
        </div>
        <div>
          {!vendorData?.['vendorPortrait']?.['file'] ? (
            <FileInput
              label="Vendor Portrait"
              isLabelShow={true}
              inputSectionClass="m-0"
              name="vendorPortrait.id"
              ref={register}
            />
          ) : (
            <Input
              isLabelShow={true}
              isAnchor={true}
              anchorHref={getUploadedImageUrl(
                vendorData?.['vendorPortrait']?.['file']
              )}
              label="Vendor Portrait"
              anchorText={`${splitImageName(
                vendorData?.['vendorPortrait']?.['file']
              )}`}
              onRemoveImage={() => onRemoveImage('vendorPortrait')}
            />
          )}
        </div>
        <div>
          {!vendorData?.['vendorAgreement']?.['file'] ? (
            <FileInput
              label="Vendor Agreement"
              isLabelShow={true}
              inputSectionClass="m-0"
              name="vendorAgreement.id"
              ref={register}
            />
          ) : (
            <Input
              isLabelShow={true}
              isAnchor={true}
              anchorHref={getUploadedImageUrl(
                vendorData?.['vendorAgreement']?.['file']
              )}
              label="Vendor Agreement"
              anchorText={`${splitImageName(
                vendorData?.['vendorAgreement']?.['file']
              )}`}
              onRemoveImage={() => onRemoveImage('vendorAgreement')}
            />
          )}
        </div>
        <div>
          {!vendorData?.['vendorInsurance']?.['file'] ? (
            <FileInput
              label="Vendor Insurance"
              isLabelShow={true}
              inputSectionClass="m-0"
              name="vendorInsurance.id"
              ref={register}
            />
          ) : (
            <Input
              isLabelShow={true}
              isAnchor={true}
              anchorHref={getUploadedImageUrl(
                vendorData?.['vendorInsurance']?.['file']
              )}
              label="Vendor Insurance"
              anchorText={`${splitImageName(
                vendorData?.['vendorInsurance']?.['file']
              )}`}
              onRemoveImage={() => onRemoveImage('vendorInsurance')}
            />
          )}
        </div>
        <div>
          {!vendorData?.['otherImages']?.['file'] ? (
            <FileInput
              label="Other Images"
              isLabelShow={true}
              inputSectionClass="m-0"
              name="otherImages.id"
              ref={register}
            />
          ) : (
            <Input
              isLabelShow={true}
              isAnchor={true}
              anchorHref={getUploadedImageUrl(
                vendorData?.['otherImages']?.['file']
              )}
              label="Other Images"
              anchorText={`${splitImageName(
                vendorData?.['otherImages']?.['file']
              )}`}
              onRemoveImage={() => onRemoveImage('otherImages')}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Certifications;
