import * as Yup from 'yup';
export const broadcastMessageSchema = Yup.object()
  .shape({
    buildings: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required(),
        })
      )
      .min(1, 'Mandatory fields required')
      .required(),
    message: Yup.string().required('Mandatory fields required'),
    recipientType: Yup.number().required('Mandatory fields required'),
  })
  .required();
