import React, { useEffect, useState } from 'react';
import { Button, Dropdown, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import ModalPopup from '../../ReactModal/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignVendorToImportedTasks,
  clearServiceImportedTaskState,
  getPackageElementList,
  getServiceTaskList,
  IServiceState,
} from '../../../redux/service';
import { clearServiceTaskListForm, IFormState } from '../../../redux/form';
import { useHistory } from 'react-router-dom';

const AssignImportedTaskPopup: React.FC<any> = ({
  modalState,
  modalClose,
  serviceId,
}: any) => {
  const { register, handleSubmit, setValue, errors } = useForm();
  const { push, location } = useHistory();
  const { packageElementList, serviceData, importedServiceTask } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { serviceSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [showPackageElementList, setPackageElementList] = useState(
    packageElementList
  );
  const [elementName] = useState(
    serviceSetupForm?.name ? serviceSetupForm?.name : serviceData?.name
  );
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const params = {
      groups: importedServiceTask,
      manuallyAssign: typeof data?.element?.id === 'number' ? false : true,
      element: data?.['element'],
    };
    await dispatch(assignVendorToImportedTasks(params, Number(serviceId)));
    modalClose();
    await dispatch(clearServiceTaskListForm());
    await dispatch(clearServiceImportedTaskState());
    await dispatch(getServiceTaskList(Number(serviceId)));
    push(`${location.pathname}${location.search}`, {
      taskImported: false,
      serviceId: null,
    });
  };

  const onModalClose = () => {
    modalClose();
    dispatch(clearServiceImportedTaskState());
    push(`${location.pathname}${location.search}`, {
      taskImported: false,
      serviceId: null,
    });
  };

  const [isPackageCheck] = useState(
    serviceSetupForm?.isPackage
      ? serviceSetupForm?.isPackage
      : serviceData?.isPackage
  );

  useEffect(() => {
    if (packageElementList?.length) {
      setPackageElementList([]);
      const packageArray = [...packageElementList];
      if (
        serviceSetupForm?.['productionTeams']?.length > 0 &&
        serviceSetupForm?.['productionTeamRequired']
      ) {
        serviceSetupForm?.['productionTeams']?.forEach((item) => {
          packageArray.push({
            id: item.vendorType.id,
            name: item.vendorType.name,
            productionTeamTask: true,
          });
        });
      }
      // packageArray.push({
      //   id: null,
      //   name: 'Organiser (manually assign)',
      //   productionTeamTask: false,
      // });
      setPackageElementList([...showPackageElementList, ...packageArray]);
    } else {
      setPackageElementList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageElementList]);

  const [elementList, setElementList] = useState([]);
  useEffect(() => {
    if (
      serviceSetupForm?.['productionTeams']?.length > 0 &&
      serviceSetupForm?.['productionTeamRequired']
    ) {
      setElementList([]);
      // elementList.push({
      //   id: null,
      //   name: 'Organiser (manually assign)',
      //   productionTeamTask: false,
      // });
      elementList.push({
        id: serviceId,
        name: elementName,
        productionTeamTask: false,
      });
      serviceSetupForm?.['productionTeams']?.forEach((item) => {
        elementList.push({
          id: item.vendorType.id,
          name: item.vendorType.name,
          productionTeamTask: true,
        });
      });
    } else {
      elementList.push(
        // {
        //   id: null,
        //   name: 'Organiser (manually assign)',
        //   productionTeamTask: false,
        // },
        { id: serviceId, name: elementName, productionTeamTask: false }
      );
    }
    setElementList([...elementList]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceSetupForm?.['productionTeams']]);

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onModalClose}
      className="delete-modal"
    >
      <h1 className="group-header">Assign Task Template</h1>
      <hr className="header-hr" />
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <PrimaryLabel labelText="Select Vendor" />
          <Dropdown
            options={isPackageCheck ? showPackageElementList : elementList}
            label="Element"
            labelKey="name"
            isMulti={true}
            valueKey="id"
            ref={() => register({ name: 'element' }, { required: true })}
            placeholder="Select vendor"
            onMenuOpen={async () => {
              if (isPackageCheck && showPackageElementList?.length <= 0) {
                await dispatch(getPackageElementList(Number(serviceId)));
              }
            }}
            onOptionChange={(e) => {
              setValue('element', e);
              // setElementId(e);
            }}
            isError={errors?.['element'] ? true : false}
          />
        </div>
        <Button
          label="Assign Tasks"
          className="block mt-4"
          backgroundColor="#e89a89"
        />
      </form>
    </ModalPopup>
  );
};

export default AssignImportedTaskPopup;
