import React from 'react';

const EventCreationTabs: React.FC<{
  tabType: boolean;
  eventType: boolean;
  onTabClick: () => void;
  imgSrc?: string;
  tabName: string;
  editEventCheck: boolean;
  imgClass?: string;
  className?: string;
  isRecurring?: boolean;
}> = ({
  tabType,
  eventType,
  onTabClick,
  imgSrc,
  tabName,
  editEventCheck,
  imgClass,
  className,
  isRecurring,
}) => {
  return (
    <div
      className={`eventType service_element ${
        eventType === tabType
          ? 'eventTypeActive'
          : `eventTypeIdle ${editEventCheck && 'disable-event-type'} ${
              isRecurring && 'disable-package'
            }`
      }`}
      onClick={onTabClick}
    >
      {imgSrc && <img src={imgSrc} alt="icon" className={imgClass} />}
      <p className={`p_service ${className}`}>{tabName}</p>
    </div>
  );
};

export default EventCreationTabs;
