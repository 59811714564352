import {
  Dropdown,
  Input,
  PrimaryLabel,
  TextArea,
  Timepicker,
  TitleBar,
} from '@alfredclub/hom-planner-theme';
import React, { useState, Fragment } from 'react';
import {
  IEventWeekDays,
  IRecurringEventForm,
  IRecurringServiceDropdown,
} from '../../../models';
import {
  getESTTime,
  getImageUrl,
  getUploadedImageUrl,
  splitImageName,
} from '../../../utils';
import LabelWithIcon from './LabelWithIcon';
import { useSelector, useDispatch } from 'react-redux';
import { getEventServiceList } from '../../../redux/event';
import { EVENT_MIN_TIME, utcTimeFormat } from '../../../constants/index';
import { getServiceDetail } from '../../../redux/service/services.action';
import AnchorElement from '../../Common/AnchorElement';
import FileInput from '../../Common/FileInput';
import { imageUpload } from '../../../redux/common';

export const RecurringDayEventSetup: React.FC<{
  eventData: Event;
  virtual: boolean;
  fields: IRecurringEventForm[];
  weekDaysList: IEventWeekDays[];
  isEdit: boolean;
  onChange: (
    e: HTMLInputElement | { value: string | object; name: string },
    index: number,
    data?: object,
    key?: string
  ) => void;
}> = ({ eventData, virtual, fields, weekDaysList, onChange }) => {
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState('');
  const [apiStatus, apiCallStatus] = useState(false);
  const { eventServices } = useSelector((state) => state.events);
  eventServices?.sort((a, b) => a.name.localeCompare(b.name));
  const onServiceChange = (
    services: IRecurringServiceDropdown | React.ChangeEvent<HTMLElement>,
    index: number
  ) => {
    delete services?.['isPackage'];
    for (const key of Object.keys(services)) {
      if (key === 'id') {
        const event = {
          value: { [key]: services[key] },
          name: 'service',
        };
        onChange(event, index);
      } else {
        if (key !== 'name') {
          const event = {
            value:
              key === 'maxAttendees' ? Number(services[key]) : services[key],
            name: key === 'maxAttendees' ? 'maxCapacity' : key,
          };
          onChange(event, index);
        }
      }
    }
  };
  const selectEvent = async (id: number | null) => {
    const serviceDetails = await dispatch(getServiceDetail(id));
    window.open(
      `/data-resources/services/edit-${
        serviceDetails ? 'package' : 'service'
      }/${id}?tab=${serviceDetails ? 'elements' : 'setup'}&serviceType=${
        serviceDetails ? '2' : '1'
      }`,
      '_blank'
    );
  };
  const handleFileUpload = async (e) => {
    const eventImage = new FormData();
    eventImage.append('file', e.target.files[0]);
    const fileData = await dispatch(imageUpload(eventImage));
    return fileData;
  };

  return (
    <div className="col-span-3 weekday_section">
      {fields &&
        fields.length > 0 &&
        fields.map((_, index) => {
          return (
            <Fragment key={index}>
              <TitleBar
                headerTitle={weekDaysList?.[index]?.['fullName']}
                className="recurring_title"
              />
              <div className="grid grid-cols-3 gap-x-2 gap-y-0">
                <div className="col-span-1 recurring_ls">
                  <LabelWithIcon
                    imgSrc={getImageUrl('Events/buildingconfig.svg')}
                    labelText="Set Time"
                    className="img_label"
                  />
                  <div className="mb-1 flex pt-4">
                    <Timepicker
                      labelName="Start time"
                      onTimeChange={(e) => {
                        const event = {
                          name: 'startTime',
                          value: e,
                        };
                        onChange(event, index);
                        setStartTime(e);
                      }}
                      minTime={EVENT_MIN_TIME}
                      name="startTime"
                      isError={!fields?.[index]?.[`startTime`]}
                      selectedTime={
                        fields?.[index]?.startTime
                          ? getESTTime(
                              fields?.[index]?.startTime,
                              utcTimeFormat,
                              eventData?.['timezone']?.zoneValue
                            )
                          : ''
                      }
                    />
                    <div className="px-2 justify-center items-center flex">
                      <img src={getImageUrl('Events/Arrow.svg')} alt="icon" />
                    </div>
                    <Timepicker
                      labelName="End time"
                      onTimeChange={(e) => {
                        const event = {
                          name: 'endTime',
                          value: e,
                        };
                        onChange(event, index);
                      }}
                      minTime={startTime}
                      name="endTime"
                      isError={!fields?.[index]?.[`endTime`]}
                      selectedTime={
                        fields?.[index]?.endTime
                          ? getESTTime(
                              fields?.[index]?.endTime,
                              utcTimeFormat,
                              eventData?.['timezone']?.zoneValue
                            )
                          : ''
                      }
                    />
                  </div>
                  <div className="mt-12">
                    <LabelWithIcon
                      imgSrc={getImageUrl('Events/streaming.svg')}
                      labelText="Streaming Settings"
                      className="img_label"
                    />
                    <div className="grid grid-cols-1 gap-x-2 gap-y-0">
                      <div className="py-4 prerecorded">
                        <input
                          type="checkbox"
                          className="checkbox pre-recorded-checkbox"
                          name="preRecorded"
                          checked={fields?.[index]?.['preRecorded']}
                          onChange={(e) => {
                            const isChecked = !fields?.[index]?.['preRecorded'];
                            onChange(e.currentTarget, index, {
                              preRecorded: isChecked,
                            });
                          }}
                        />

                        <PrimaryLabel
                          className="pre-recorded-label"
                          labelText="This is a pre-recorded event"
                        />
                      </div>
                      <div className="mt-4">
                        <PrimaryLabel labelText="Tenant URL" />
                        <Input
                          label=""
                          placeholder="vimeo.com"
                          name="tenantUrl"
                          value={fields?.[index]?.tenantUrl ?? null}
                          onInputBlur={(e) => onChange(e.currentTarget, index)}
                          disabled={eventData?.['isPastEvent']}
                        />
                      </div>
                      <div className="mt-4">
                        <PrimaryLabel labelText="Maximum Attendees" />
                        <PrimaryLabel labelText="*" className="text-red-500" />
                        <Input
                          label=""
                          name="maxCapacity"
                          inputType="number"
                          isError={!fields?.[index]?.['maxCapacity']}
                          value={fields?.[index]?.['maxCapacity'] ?? null}
                          onInputBlur={(e) => onChange(e.currentTarget, index)}
                          disabled={eventData?.['isPastEvent']}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="setup_event">
                    <LabelWithIcon
                      imgSrc={getImageUrl('Events/setupevent-1.svg')}
                      labelText="Event Details"
                      className="img_label"
                    />
                    <div className="grid grid-cols-2 gap-x-2 gap-y-0">
                      <div className="mt-4">
                        <div className="flex justify-between dropdown_class">
                          <div>
                            <span className="label_text">Select Event</span>
                            <span className="text-red-500">*</span>
                          </div>
                          {fields?.length && fields?.[index]?.service?.id && (
                            <img
                              src={getImageUrl('Events/activeLink.svg')}
                              alt="icon"
                              onClick={() =>
                                selectEvent(fields?.[index]?.service?.id)
                              }
                              className="cursor-pointer"
                            />
                          )}
                        </div>
                        <Dropdown
                          asyncDropdown={false}
                          labelKey="name"
                          valueKey="id"
                          isLabelShow={false}
                          label="Event Service"
                          options={eventServices}
                          onMenuOpen={async () => {
                            apiCallStatus(true);
                            if (eventServices?.length <= 0) {
                              await dispatch(
                                getEventServiceList(
                                  eventData?.['serviceCategory']['id'],
                                  Number(virtual),
                                  Number(eventData?.['recurring'])
                                )
                              );
                            }
                            apiCallStatus(false);
                          }}
                          loading={apiStatus}
                          name="service"
                          isError={!fields?.[index]?.[`service`]}
                          onOptionChange={(e) => {
                            onServiceChange(e, index);
                          }}
                          value={{
                            name: fields?.[index]?.service?.name,
                            id: fields?.[index]?.service?.id,
                          }}
                          disabled={eventData?.['isPastEvent']}
                        />
                      </div>
                      <div className="mt-4">
                        <PrimaryLabel labelText="Event Display Title" />
                        <PrimaryLabel labelText="*" className="text-red-500" />
                        <Input
                          label=""
                          placeholder="Type here"
                          name="displayName"
                          isError={!fields?.[index]?.['displayName']}
                          value={fields?.[index]?.displayName ?? null}
                          onInputBlur={(e) => onChange(e.currentTarget, index)}
                          disabled={eventData?.['isPastEvent']}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-2 gap-y-0">
                      <div className="mt-4">
                        <PrimaryLabel labelText="Event Description" />
                        <TextArea
                          label=""
                          placeholder="Add description"
                          isLabelShow={false}
                          name="description"
                          value={fields?.[index]?.description ?? null}
                          onTextAreaChange={(e) =>
                            onChange(e.currentTarget, index)
                          }
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-2 gap-y-0">
                      <div className="mt-4">
                        <div className="flex justify-between">
                          <PrimaryLabel
                            labelText="Poster link"
                            className="block"
                          />
                          <AnchorElement
                            link={fields?.[index]?.marketingLinks}
                          />
                        </div>
                        <Input
                          label=""
                          placeholder="Paste link here"
                          name="marketingLinks"
                          onInputBlur={(e) => onChange(e.currentTarget, index)}
                          value={fields?.[index]?.marketingLinks ?? null}
                        />
                      </div>
                      <div className="mt-3">
                        <PrimaryLabel labelText="Event Card Image" />
                        {!fields?.[index]?.['eventImage']?.['file'] ? (
                          <FileInput
                            label="Event Card Image"
                            isLabelShow={false}
                            inputSectionClass="m-0"
                            name="event_image"
                            onInputChange={async (e) => {
                              const data = await handleFileUpload(e);
                              if (data) {
                                onChange(e, index, data, 'eventImage');
                              }
                            }}
                          />
                        ) : (
                          <Input
                            isLabelShow={false}
                            isAnchor={true}
                            anchorHref={getUploadedImageUrl(
                              fields?.[index]?.['eventImage']?.['file']
                            )}
                            label=""
                            inputSectionClass="fileUpload"
                            anchorText={`${splitImageName(
                              fields?.[index]?.['eventImage']?.['file']
                            )}`}
                            onRemoveImage={(e) => {
                              onChange(e, index, { id: null }, 'eventImage');
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
    </div>
  );
};

export default RecurringDayEventSetup;
