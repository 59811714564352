import thunk from 'redux-thunk';
import { applyMiddleware, createStore } from 'redux';
import { rootReducer } from './root.reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { saveStateToLocalStorage } from '../utils';

export const configureStore = () => {
  const reduxStore = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
  reduxStore.subscribe(() => {
    saveStateToLocalStorage({
      formData: { ...reduxStore.getState().forms },
    });
  });
  return reduxStore;
};

export const store = configureStore();
