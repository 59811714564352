import React, { useState, useEffect } from 'react';
import CommonLayout from '../../CommonLayout/CommonLayout';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '@alfredclub/hom-planner-theme';
import { useHistory } from 'react-router-dom';
import {
  getStaffDetail,
  getStaffListing,
} from '../../../redux/staff/staff.action';
import { useFetch, usePagination } from '../../../hooks';
import AddNewStaff from './AddNewStaff';
import { TOOLTIP_TEXT } from '../../../constants/index';
import { getSerialNumber } from '../../../utils';
import { IStaffState } from '../../../redux/staff/staff.state';
const StaffListing = () => {
  const { status } = useFetch(getStaffListing);
  const [showAddStaffPopup, setAddStaffPopupState] = useState(false);
  const { pages, staffCount } = useSelector(
    (state: { buildingStaff: IStaffState }) => state.buildingStaff
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [pageNo, setPage] = useState(0);
  const pagination = usePagination();

  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);

  const onPageClick = async (page: number) => {
    await pagination(pages, page - 1, getStaffListing);
    setPage(page - 1);
  };

  const handleOnCellClick = async (e: any) => {
    setLoadingState(true);
    setRowState(e.row.values.id);
    await dispatch(getStaffDetail(e.row.values.id));
    history.push(`/data-resources/clients/staff/${e.row.values.id}`);
  };

  const onAddStaff = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setAddStaffPopupState(true);
  };

  const onCloseStaff = (e: Event) => {
    e.preventDefault();
    setAddStaffPopupState(false);
  };
  let timer = null;
  const onSearch = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getStaffListing(10, 0, 'short', inputValue, 1, false)
          );
          res(data);
        }, 1000))
    );
  };
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  return (
    <div>
      {showAddStaffPopup && (
        <AddNewStaff
          modalState={showAddStaffPopup}
          onCloseStaff={onCloseStaff}
        />
      )}
      {status === 'fetched' ? (
        <CommonLayout
          headerTitle="Building Staff"
          showIcon={true}
          showTable={true}
          showSearchBar={true}
          showSortButton={true}
          navIconToolTipText={TOOLTIP_TEXT.buildingStaff.new}
          tableColumnsData={pages?.get(pageNo)?.data}
          tableColumnsHeaders={[
            {
              Header: 'Sno',
              accessor: 'id',
              Cell: (row: any) => {
                return <div>{getSerialNumber(pageNo, row.row.index)}</div>;
              },
            },
            {
              Header: 'Staff',
              accessor: (row: any) => (
                <>
                  {row.name}
                  <Loader
                    color="#e89a89"
                    position="center"
                    loading={showLoader && setRowClick === row.id}
                    size="0.75rem"
                    className={`${
                      showLoader && setRowClick === row.id && 'row_loader'
                    }`}
                  />
                </>
              ),
              className: 'tableAnchor table_cell',
            },
            {
              Header: 'Designation',
              accessor: 'designation',
            },
            {
              Header: 'Contact',
              accessor: 'contact',
            },
            {
              Header: 'Management',
              accessor: 'managementCompany',
            },
          ]}
          showPagination={!(staffCount < 10) && true}
          recordsCount={staffCount}
          onPageChange={(pageNumber) => onPageClick(pageNumber)}
          onCellClick={(e) => handleOnCellClick(e)}
          onClick={(e) => onAddStaff(e)}
          promiseOptions={onSearch}
          optionLabel="name"
          optionKey="id"
          placeholder="Search by staff"
          onSearchOptionSelect={async (option) => {
            if (option) {
              setLoadingState(true);
              setRowState(option.id);
              await dispatch(getStaffDetail(option.id));
              history.push(`/data-resources/clients/staff/${option.id}`);
            }
          }}
        />
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
    </div>
  );
};
export default StaffListing;
