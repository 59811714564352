import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Loader } from '@alfredclub/hom-planner-theme';
import ProgressBar from './ProgressBar';
import TimelineHeadings from './TimelineHeadings';
import FeedTable from './FeedTable';
import { getEventTimeline } from '../../../redux/event';
import { ILoaderState } from '../../../redux/loader/loader.state';
import { IEventsState } from '../../../redux/event';
import Footer from '../../Common/Footer';
import { getImageUrl } from '../../../utils';
import { TimelineArray } from '../../../models';
import { defaultTimezone } from '../../../constants';
import './TimeLineFeed.scss';
import { IFormState } from '../../../redux/form';

const TimeLineFeed = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const eventId = params['eventId'];
  const {
    recurringSetupForm: eventData,
    eventSetupForm: eventPublised,
  } = useSelector((state: { forms: IFormState }) => state.forms);
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { eventTimeLine } = useSelector(
    (state: { events: IEventsState }) => state.events
  );

  useEffect(() => {
    async function getEventTimeLine() {
      await dispatch(
        getEventTimeline(
          Number(eventId),
          localStorage.getItem('isRecurring') ? true : false,
          eventData?.timezone?.zoneValue ?? defaultTimezone
        )
      );
    }
    getEventTimeLine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const onBackButton = () => {
    push(
      `/events/${eventId}/create?step=task-management&edit=true&tab=task-list`
    );
  };

  const onNextButton = () => {
    if (eventPublised?.['published']) {
      push(
        `/events/${eventId}/create?step=users&edit=true&tab=total-rsvps&pageNo=0`
      );
    } else {
      push(`/events?tab=published&pageNo=0`);
    }
  };

  return (
    <div className="timeline_feed">
      {loading ? (
        <Loader
          color="#e89a89"
          position="center"
          loading={true}
          size="0.75rem"
        />
      ) : (
        eventTimeLine?.timeline?.length &&
        eventTimeLine.timeline.map((data: TimelineArray, index: number) => {
          return (
            <div key={index}>
              <div
                className={index === 0 ? 'header-first-line' : 'header-line'}
              />
              {eventTimeLine?.recurring ? (
                <div className="timeline-heading text-lg">
                  <span className="ml-10 font-medium">
                    {data.weekday}: {data.eventScheduled.text}
                  </span>
                  {(data?.manuallyAssigned?.show ||
                    data?.inviteAccepted?.show) && (
                    <>
                      <span className="p-2">|</span>
                      <span>{`${
                        data?.manuallyAssigned?.show
                          ? `${data?.manuallyAssigned?.subText?.['name']} Assigned`
                          : data?.inviteAccepted?.show
                          ? `${data?.inviteAccepted?.subText?.['name']} Assigned`
                          : ''
                      }`}</span>
                    </>
                  )}
                </div>
              ) : (
                <div className="timeline-heading-2 font-medium text-lg">
                  <span className="ml-10">{`${
                    data?.manuallyAssigned?.show
                      ? `${data?.manuallyAssigned?.subText?.['name']} Assigned`
                      : data?.inviteAccepted?.show
                      ? `${data?.inviteAccepted?.subText?.['name']} Assigned`
                      : ''
                  }`}</span>
                </div>
              )}
              <ProgressBar
                timeline={data}
                cancelled={eventTimeLine?.['cancelled']}
              />
              <TimelineHeadings
                timeline={data}
                cancelled={eventTimeLine?.['cancelled']}
              />
              <FeedTable timeline={data} />
              <Footer
                buttonText="Save and Next"
                isArrow={false}
                buttonClass="recurring_continue"
                onClick={() => onNextButton()}
                isRightIcon={true}
                rightIcon={getImageUrl('Events/continuearrow.svg')}
                rightIconClass="pl-4"
                type="button"
                isLeftButton={true}
                leftButtonClass="footer_left_button"
                leftButtonType="button"
                onLeftButtonClick={() => onBackButton()}
                leftButtonText="Back"
                className="recurring_footer"
                isRightButton={true}
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default TimeLineFeed;
