import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Topnav, Button, TagBox } from '@alfredclub/hom-planner-theme';
import { Table } from '@alfredclub/hom-planner-theme';
import ReactTooltip from 'react-tooltip';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { usePagination } from '../../hooks';
import { findIndexFromArrayOfObject, loadState } from '../../utils';
import { useFetch } from '../../hooks';
import { getAllVendors, getVendors } from '../../redux/vendor/vendors.action';
import CommonNavigation from '../Common/CommonNavigation';
import { updateServiceVendorsForm } from '../../redux/form/form.action';
import { IVendorState } from '../../redux/vendor/vendor.state';
import { IFormState } from '../../redux/form/form.state';

const TagVendors = () => {
  const [pageNo, setPage] = useState(0);
  const { pages, vendorCount } = useSelector(
    (state: { vendors: IVendorState }) => state.vendors
  );
  const pagination = usePagination();
  const dispatch = useDispatch();
  const params = useParams();
  const { push } = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  // Decrypt the value of popup fields
  const [serviceType] = useState(parseInt(query.get('serviceType'), 10));
  const [indexNumber] = useState(parseInt(query.get('elementIndex'), 10));
  const serviceId = parseInt(params['serviceId'], 10);
  const [addServiceId] = useState(query.get('id'));
  const { status } = useFetch(getAllVendors, [10, 0, 'short', '', 1]);
  const [isCancelIcon, setCancelState] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | object>('');
  const [setRowClick, setRowState] = useState(null);

  const { serviceVendorForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [isEdit] = useState(serviceId ? true : false);
  const [editServiceData] = useState(
    isEdit ? loadState('serviceVendorForm') : serviceVendorForm
  );
  const [vendorArray, setVendorArray] = useState(
    serviceId || addServiceId
      ? editServiceData?.vendorRequirements?.[indexNumber]?.vendors
      : []
  );
  useEffect(() => {
    setVendorArray(
      serviceId || addServiceId
        ? editServiceData?.vendorRequirements?.[indexNumber]?.vendors
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceVendorForm]);
  const onPageClick = async (page: number) => {
    await pagination(
      pages,
      page - 1,
      getAllVendors(10, page - 1, 'short', '', 1),
      true
    );
    setPage(page - 1);
  };

  // Table cell checkbox handler
  const handleOnChange = async (
    _: React.ChangeEvent<HTMLInputElement>,
    rowData?: any
  ) => {
    const selectedVendors = vendorArray ?? [];
    const vendorIdIndex = findIndexFromArrayOfObject(
      selectedVendors,
      'vendorId',
      rowData
    );
    if (vendorIdIndex !== -1) {
      selectedVendors.splice(vendorIdIndex, 1);
    } else {
      selectedVendors.push({ ...rowData });
    }
    setVendorArray(() => [...selectedVendors]);
  };
  let timer = null;
  const onSearch = async (querySearch: string) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getVendors(10, 0, 'short', querySearch, 1, 1, false)
          );
          res(data);
        }, 1000))
    );
  };

  const onSubmit = async () => {
    if (editServiceData?.vendorRequirements?.length) {
      editServiceData.vendorRequirements[indexNumber].vendors = vendorArray;
    } else {
      editServiceData?.vendorRequirements.push({
        vendors: vendorArray,
        requirements: {},
      });
    }
    await dispatch(updateServiceVendorsForm({ data: editServiceData }));
    if (!isEdit) {
      push(
        `/data-resources/services/${
          serviceType === 1 ? `add-service` : `add-package`
        }?serviceType=${serviceType}&id=${addServiceId}&tagVendor=${true}&tab=vendors`
      );
    } else {
      push(
        `/data-resources/services/${
          serviceType === 1 ? `edit-service` : `edit-package`
        }/${serviceId}?serviceType=${serviceType}&tagVendor=${true}&tab=vendors`
      );
    }
  };

  const navLink = !isEdit
    ? `/data-resources/services/${
        serviceType === 1 ? `add-service` : `add-package`
      }?serviceType=${serviceType}&id=${addServiceId}&tab=vendors`
    : `/data-resources/services/${
        serviceType === 1 ? `edit-service` : `edit-package`
      }/${serviceId}?serviceType=${serviceType}&tab=vendors`;

  const onClickCancel = async () => {
    await dispatch(getVendors());
    setSearchValue('');
    setCancelState(false);
  };

  const getCategories = (categories: number[]) => {
    return categories?.map((data: number, i: number) => {
      return (
        <span key={i}>{` ${data}${
          i + 1 !== categories.length ? ',' : ''
        }`}</span>
      );
    });
  };

  const selectCategory = async (e) => {
    setRowState(e.row.index);
  };

  return (
    <>
      <Topnav
        headerTitle="Tag Vendors"
        showSearchBar={true}
        promiseOptions={onSearch}
        placeholder="Search vendor"
        optionLabel="vendorName"
        optionKey="vendorId"
        showCancelIcon={isCancelIcon}
        onSearchOptionSelect={async (option) => {
          if (option) {
            setSearchValue(option);
            await dispatch(
              getVendors(10, 0, 'short', '', 1, 1, true, option.vendorId)
            );
            setCancelState(true);
          }
        }}
        onClickOfCloseIcon={onClickCancel}
        defaultSearchValue={[searchValue]}
      />
      {status === 'fetched' && (
        <div className="mx-8 tag-vendor-section mt-3">
          <div>
            <CommonNavigation
              linkTo={navLink}
              header="Add Service"
              subHeader="Tag Vendors"
              className="btn-position"
            />
            <Button label="Save and go back" onClick={onSubmit} />
            <Button
              backgroundColor="#ffffff"
              className="px-sort-button mt-12 mb-4"
              label="Sort by"
              buttonText="#5d5d5d"
              onClick={() => {}}
            />
            <Table
              tableColumns={[
                {
                  id: 'checkbox',
                  Header: 'Sno',
                  accessor: 'vendorId',
                  Cell: (row: any) => {
                    return (
                      <div className="table_checkbox">
                        <input
                          type="checkbox"
                          className="checkbox"
                          onChange={(e) => {
                            handleOnChange(e, row.row.original);
                          }}
                          checked={
                            vendorArray?.length > 0
                              ? findIndexFromArrayOfObject(
                                  vendorArray,
                                  'vendorId',
                                  row.row.original
                                ) !== -1
                                ? true
                                : false
                              : false
                          }
                        />
                      </div>
                    );
                  },
                },
                {
                  Header: 'Vendor name',
                  className: 'tableAnchor',
                  accessor: 'vendorName',
                },
                {
                  Header: 'Category tag',
                  accessor: 'categoryTags',
                  Cell: (row: any) => {
                    return (
                      <>
                        <div className="flex">
                          {row.row.original?.categoryTags?.length > 0 &&
                            row.row.original?.categoryTags.map(
                              (category: string, index: number) => {
                                if (index > 1 || category === null) {
                                  return <></>;
                                }
                                return (
                                  <div key={index}>
                                    <TagBox
                                      tagLabel={category}
                                      className="tagbox_position_selected_building pointer-events-none"
                                      hideRemoveTagIcon={true}
                                    />
                                  </div>
                                );
                              }
                            )}
                          {row.row.original?.categoryTags?.length > 2 && (
                            <div
                              className="mt-3"
                              data-tip=""
                              data-for="categories"
                            >
                              <span>
                                +{row.row.original?.categoryTags?.length - 2}
                              </span>
                            </div>
                          )}
                        </div>
                        {row.row.original?.categoryTags?.length > 0 &&
                          setRowClick === row.row.index && (
                            <ReactTooltip
                              id="categories"
                              getContent={() => {
                                return getCategories(
                                  row.row.original?.categoryTags
                                );
                              }}
                            />
                          )}
                      </>
                    );
                  },
                },
                {
                  Header: 'Contact',
                  accessor: 'contact',
                },
                {
                  Header: 'Region',
                  accessor: 'region',
                },
                {
                  Header: 'Status',
                  Cell: (row: any) =>
                    !row?.row?.original?.status ? 'Inactive' : 'Active',
                },
              ]}
              tableData={pages?.get(pageNo)?.data}
              onCellClick={() => {}}
              showPagination={!(vendorCount < 10) && true}
              totalRecordsCount={vendorCount}
              className="mt-8 w-full"
              onPageChange={(pageNumber) => onPageClick(pageNumber)}
              onCellMouseEnter={(e) => selectCategory(e)}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default TagVendors;
