import React from 'react';
import { Dropdown } from '@alfredclub/hom-planner-theme';
import { useDispatch, useSelector } from 'react-redux';
import { getManagements } from '../../../../redux/management/management.action';
import { getClusters } from '../../../../redux/cluster/cluster.action';
import { Link } from 'react-router-dom';
import FormTitle from './FormTitle';
import { updateBuildingForm } from '../../../../redux/form/form.action';
import { Register, SetValue } from '../../../../models';
import { IClusterState } from '../../../../redux/cluster';
import { IManagementState } from '../../../../redux/management';
const ManagementDetails: React.FC<{
  register: Register;
  setValue: SetValue;
  managementCompany: object;
  buildingData: object;
  buildingName: string;
  isEdit: boolean;
  buildingId: number;
  getValues: (payload?: string | string[]) => object;
}> = ({
  register,
  setValue,
  managementCompany,
  buildingData,
  buildingName,
  isEdit,
  buildingId,
  getValues,
}) => {
  const dispatch = useDispatch();
  const { allManagements } = useSelector(
    (state: { managements: IManagementState }) => state.managements
  );
  const { clusters } = useSelector(
    (state: { clusters: IClusterState }) => state.clusters
  );
  if (allManagements.length > 0 || Object.keys(allManagements).length > 0) {
    allManagements?.sort((a, b) => a.companyName.localeCompare(b.companyName));
  }

  return (
    <>
      <FormTitle title="Management" />
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="companyName"
            valueKey="id"
            isLabelShow={true}
            label="Management Company"
            options={allManagements}
            onMenuOpen={async () => {
              if (
                allManagements.length <= 0 ||
                Object.keys(allManagements).length <= 0
              ) {
                await dispatch(getManagements(0, 0, 'short', '', 1));
              }
            }}
            ref={() => register({ name: 'managementCompany' })}
            onOptionChange={async (e) => {
              const management = {
                id: e['id'],
                companyName: e['companyName'],
              };
              setValue('managementCompany', management);
              await dispatch(updateBuildingForm(getValues()));
              await dispatch(getClusters(0, 0, 'short', '', 1));
            }}
            placeholder="Select"
            value={{
              companyName: buildingData?.['managementCompany']?.['companyName'],
              id: buildingData?.['managementCompany']?.['id'],
            }}
          />
          <span className="no-management">Not finding management?</span>{' '}
          <Link
            className="custom_label float-none"
            to={
              isEdit
                ? `/data-resources/clients/management/add-management?buildingName=${buildingName}&buildingId=${buildingId}`
                : `/data-resources/clients/management/add-management?buildingName=${buildingName}`
            }
          >
            Add management company
          </Link>
        </div>
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="clusterName"
            valueKey="id"
            isLabelShow={true}
            label="Cluster"
            options={clusters}
            ref={() => register({ name: 'cluster' })}
            onOptionChange={async (e) => {
              const cluster = {
                id: e['id'],
                clusterName: e['clusterName'],
              };
              setValue('cluster', cluster);
              await dispatch(updateBuildingForm(getValues()));
            }}
            placeholder={
              !managementCompany || !managementCompany?.['id']
                ? 'Please select Management company first'
                : 'Select'
            }
            disabled={!managementCompany || !managementCompany?.['id']}
            value={
              buildingData && {
                clusterName: buildingData?.['cluster']?.['clusterName'],
                id: buildingData?.['cluster']?.['id'],
              }
            }
          />
        </div>
      </div>
    </>
  );
};

export default ManagementDetails;
