import React, { useEffect, useState } from 'react';
import './taskTemplate.scss';
import { getImageUrl } from '../../utils';
import CommonLoader from '../Common/CommonLoader';
import { ILoaderState } from '../../redux/loader';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dropdown,
  Input,
  PrimaryLabel,
  TextArea,
} from '@alfredclub/hom-planner-theme';
import { taskStartTime, timeOfTaskList } from '../../constants';
import {
  addTaskToGroups,
  getTaskGroups,
  ITaskTemplateState,
  updateTasksOfGroup,
} from '../../redux/taskTemplate';
import { IAddGroupTasks } from '../../models';

const AddNewTaskPopup: React.FC<{
  closePane: () => void;
  groupId: number;
  isEdit: boolean;
}> = ({ closePane, groupId, isEdit }) => {
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { groupTaskDetail } = useSelector(
    (state: { taskTemplate: ITaskTemplateState }) => state.taskTemplate
  );
  const dispatch = useDispatch();
  const [taskTimeLabel, setTaskTimeLabel] = useState(null);
  const [selectedTimeOfTask, setSelectedTimeOfTask] = useState<object[]>([]);
  const [servicesAndEventsStatus, setServicesAndEventsStatus] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    reset,
  } = useForm<IAddGroupTasks>();
  const taskTimeObjValue = taskStartTime.filter(
    (data) => data.value === taskTimeLabel
  );

  const onSubmit = async (data: IAddGroupTasks) => {
    if (!isEdit) {
      data['editServicesAndEvents'] = false;
      await dispatch(addTaskToGroups(groupId, data));
    } else {
      data['editServicesAndEvents'] = servicesAndEventsStatus;
      await dispatch(updateTasksOfGroup(groupTaskDetail?.['id'], data));
    }
    await dispatch(getTaskGroups(''));
    closePane();
  };

  const selectedValue =
    groupTaskDetail?.['timeValue'] &&
    taskTimeObjValue.length > 0 &&
    taskTimeObjValue[0].timeValues.filter(
      (data) => data.value === groupTaskDetail?.['timeValue']
    );

  useEffect(() => {
    async function getTaskDetailMethod() {
      if (isEdit) {
        reset({
          ...groupTaskDetail,
          timeOfTask: groupTaskDetail?.['timeOfTask'],
          timeValue: groupTaskDetail?.['timeValue'],
        });
        setServicesAndEventsStatus(groupTaskDetail?.['editServicesAndEvents']);
        setSelectedTimeOfTask([
          {
            label:
              Number(groupTaskDetail?.['timeOfTask']) === 0
                ? 'Day before event'
                : Number(groupTaskDetail?.['timeValue']) < 1
                ? 'Event day - before event starts'
                : 'Event day - After event starts',
            value: Number(groupTaskDetail?.['timeOfTask']),
          },
        ]);
        setTaskTimeLabel(
          groupTaskDetail?.['timeOfTask'] === 0
            ? 0
            : groupTaskDetail?.['timeValue'] < 1
            ? 1
            : 2
        );
        return;
      } else {
        setSelectedTimeOfTask([]);
        setTaskTimeLabel(null);
        reset({});
      }
    }
    getTaskDetailMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, groupTaskDetail]);
  const onGroupOptionChange = (check: boolean) => {
    setValue('editServicesAndEvents', check);
    setServicesAndEventsStatus(!servicesAndEventsStatus);
  };
  return (
    <>
      {loading ? (
        <CommonLoader loading={loading} />
      ) : (
        <>
          <h1 className="h1_custom">
            {isEdit ? 'Edit Task' : 'Create New Task'}
            <img
              src={getImageUrl('data-resources/cancel.svg')}
              alt="close"
              className="inline-flex float-right cursor-pointer"
              onClick={closePane}
            />
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mx-12 mt-10 grid grid-cols-2 gap-4">
              <div>
                <PrimaryLabel labelText="Schedule Task" />
                <Dropdown
                  options={timeOfTaskList}
                  label="Time of task"
                  ref={() =>
                    register({ name: 'timeOfTask' }, { required: true })
                  }
                  labelKey="label"
                  valueKey="value"
                  placeholder="Select time"
                  onOptionChange={(e) => {
                    const timeOfTask = Number(e['headerValue']);
                    setTaskTimeLabel(Number(e['value']));
                    setValue('timeOfTask', timeOfTask);
                  }}
                  isError={errors?.['timeOfTask'] ? true : false}
                  value={isEdit && selectedTimeOfTask}
                  resetValue={!isEdit ? true : false}
                />
              </div>
              <div>
                <PrimaryLabel
                  labelText={`${
                    taskTimeObjValue.length > 0
                      ? taskTimeObjValue[0].label
                      : 'Task time'
                  }`}
                />
                <Dropdown
                  options={
                    taskTimeObjValue.length > 0 &&
                    taskTimeObjValue[0].timeValues
                  }
                  label="Task time"
                  ref={() =>
                    register({ name: 'timeValue' }, { required: true })
                  }
                  placeholder="Select when"
                  labelKey="title"
                  valueKey="value"
                  onOptionChange={(e) => {
                    const timeValue = Number(e['value']);
                    setValue('timeValue', timeValue);
                  }}
                  isError={errors?.['timeValue'] ? true : false}
                  value={
                    isEdit && [
                      {
                        title: selectedValue?.[0]?.['title'],
                        value: selectedValue?.[0]?.['value'],
                      },
                    ]
                  }
                  resetValue={!isEdit ? true : false}
                />
              </div>
              <div className="col-span-2">
                <PrimaryLabel labelText="Task Title" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  label="Task Title"
                  name="title"
                  placeholder="Type here"
                  ref={register({ required: true })}
                  isError={errors.title ? true : false}
                  value={isEdit ? groupTaskDetail?.['title'] : ''}
                />
              </div>
              <div className="col-span-2">
                <PrimaryLabel labelText="Task Description" />
                <TextArea
                  name="description"
                  label="Task Description"
                  ref={register}
                  placeholder="Type here.."
                  defaultValue={isEdit ? groupTaskDetail?.['description'] : ''}
                />
              </div>
              <div className="col-span-2">
                <PrimaryLabel labelText="Add Link" />
                <Input
                  label="Add Link"
                  name="documentLink"
                  placeholder="Paste link here"
                  ref={register}
                  value={isEdit ? groupTaskDetail?.['documentLink'] : ''}
                />
              </div>
              {isEdit && (
                <div className="col-span-2">
                  <PrimaryLabel labelText="Publish changes applicable on:" />
                  <div className="flex mt-2 task-radio">
                    <div className="flex text-center justify-center justify-items-center content-center">
                      <input
                        type="radio"
                        className="taskradio cursor-pointer mr-1"
                        name="editServicesAndEvents"
                        onChange={(e) => onGroupOptionChange(e.target.checked)}
                        checked={!servicesAndEventsStatus}
                        ref={register}
                      />
                      <PrimaryLabel labelText="Service templates only" />
                    </div>
                    <div className="flex ml-8">
                      <input
                        type="radio"
                        name="editServicesAndEvents"
                        className="taskradio cursor-pointer mr-1"
                        onChange={(e) => onGroupOptionChange(e.target.checked)}
                        checked={servicesAndEventsStatus}
                        ref={register}
                      />
                      <PrimaryLabel labelText="Service templates and events" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Button
              label={isEdit ? `Save changes` : `Add to list`}
              className={`${
                loading ? 'add_to_list button_disable' : 'add_to_list'
              }`}
              disabled={loading}
            />
          </form>
        </>
      )}
    </>
  );
};

export default AddNewTaskPopup;
