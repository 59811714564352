import React, { useState } from 'react';
import { loadState } from '../../utils';
import EventMetaDetails from '../Events/EventsLayout/EventMetaDetails';
import './PDFTaskListHeader.scss';

const PDFTaskListHeader: React.FC<{ hideLabelColor: boolean }> = ({
  hideLabelColor,
}) => {
  const eventData = loadState('eventSetupForm');
  const [eventHeader] = useState(
    eventData?.['displayName'] ?? 'Event Planning'
  );
  return (
    <>
      <p className="pdf-event-heading">{eventHeader}</p>
      <EventMetaDetails hideLabelColor={hideLabelColor} />
    </>
  );
};

export default PDFTaskListHeader;
