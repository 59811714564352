import { http } from '../common/http.service';
import { WHITELABEL_BASE_URL } from '../../constants/index';
import { Whitelabel } from '../../models/whitelabel';
import { AxiosResponse } from 'axios';

export const addWhitelabelApi = async (
  buildingId: number,
  data: Whitelabel
): Promise<Whitelabel> =>
  http
    .post<Whitelabel>(`${WHITELABEL_BASE_URL}/${buildingId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Whitelabel>) => {
      return response.data;
    });

export const getWhitelabelApi = async (
  buildingId: number
): Promise<Whitelabel> =>
  http
    .get<Whitelabel>(`${WHITELABEL_BASE_URL}/${buildingId}`, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<Whitelabel>) => {
      return response.data;
    });

export const updateWhitelabelApi = async (
  buildingId: number,
  data: Whitelabel
): Promise<Whitelabel> =>
  http
    .put<Whitelabel>(`${WHITELABEL_BASE_URL}/${buildingId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Whitelabel>) => {
      return response.data;
    });
