export const GET_INVENTORY_GROUPS = 'GET_INVENTORY_GROUPS';
export const DELETE_INVENTORY_GROUPS_ITEMS = 'DELETE_INVENTORY_GROUPS_ITEMS';
export const SEARCH_INVENTORY_GROUPS = 'SEARCH_INVENTORY_GROUPS';
export const GET_INVENTORY_ITEMS_LIST = 'GET_INVENTORY_ITEMS_LIST';
export const UPDATE_INVENTORY_ITEM_LOCATION = 'UPDATE_INVENTORY_ITEM_LOCATION';
export const SEARCH_INVENTORY_ITEMS = 'SEARCH_INVENTORY_ITEMS';
export const DELETE_INVENTORY_ITEMS = 'DELETE_INVENTORY_ITEMS';
export const UPDATE_INVENTORY_GROUP = 'UPDATE_INVENTORY_GROUP';
export const DELETE_INVENTORY_GROUP = 'DELETE_INVENTORY_GROUP';
export const UPDATE_INVENTORY_GROUP_ITEM_LOCATION =
  'UPDATE_INVENTORY_GROUP_ITEM_LOCATION';
