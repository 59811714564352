import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Loader } from '@alfredclub/hom-planner-theme';
import Accordion from '../../Common/Accordian/Accordion';
import { timelineDateFormat, timelineTimeFormat } from '../../../constants';
import { TimelineArray } from '../../../models';
import { TinyObject } from '../../../models';
import { getESTTime } from '../../../utils';
import { getVendorIndividualDetail } from '../../../redux/vendor/vendors.action';
import './TimeLineFeed.scss';
interface ProgressBarProps {
  timeline: TimelineArray;
}

const FeedTable: React.FC<ProgressBarProps> = ({ timeline }) => {
  const dispatch = useDispatch();
  const {
    eventPublished,
    inviteAccepted,
    eventScheduled,
    eventInvite,
    manuallyAssigned,
    eventCancelled,
    eventTimezone,
  } = timeline;
  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);
  const [getTextIndex, setTextIndex] = useState(null);
  const timeLineArray = [];
  if (eventScheduled?.show) {
    timeLineArray.push({
      datetime: eventScheduled?.datetime,
      text: eventScheduled?.text,
      subText: '',
    });
  }
  if (eventInvite?.show) {
    timeLineArray.push({
      datetime: eventInvite?.datetime,
      text: `${eventInvite.text} Vendor Requests Sent`,
      subText: eventInvite?.subText ? eventInvite.subText : '',
    });
  }
  if (manuallyAssigned?.show) {
    timeLineArray.push({
      datetime: manuallyAssigned?.datetime,
      text: manuallyAssigned?.subText,
      subText: '',
    });
  } else if (inviteAccepted?.show) {
    timeLineArray.push({
      datetime: inviteAccepted?.datetime,
      text: inviteAccepted?.subText,
      subText: '',
    });
  }
  if (eventPublished?.show) {
    timeLineArray.push({
      datetime: eventPublished?.datetime,
      text: eventPublished?.text,
      subText: '',
    });
  }
  if (eventCancelled?.show) {
    timeLineArray.push({
      datetime: eventCancelled?.datetime,
      text: eventCancelled?.text,
      subText: '',
    });
  }
  const selectVendor = async (id: number, index: number) => {
    setLoadingState(true);
    setRowState(id);
    if (index >= 0) {
      setTextIndex(index);
    }
    await dispatch(getVendorIndividualDetail(id));
    setRowState(null);
    setTextIndex(null);
    setLoadingState(false);
    window.open(`/data-resources/vendors/${id}`, '_blank');
  };
  const showVendorsName = (name: string) => {
    return name.trim();
  };
  return (
    <div className="ml-6 pb-10 feed-table">
      <Accordion
        title={<span className="timeline-table-heading text-lg">Timeline</span>}
        content={
          <Table
            className="timeline-table"
            tableColumns={[
              {
                accessor: 'datetime',
                Cell: ({ row }) => {
                  return (
                    <div className="text-center">
                      <div className="time-line-date font-medium text-sm">
                        <span>
                          {getESTTime(
                            row.original.datetime,
                            timelineDateFormat,
                            eventTimezone?.zoneValue
                          )}
                        </span>
                      </div>
                      <div className="time-line-time font-medium text-sm">
                        <span>
                          {getESTTime(
                            row.original.datetime,
                            timelineTimeFormat,
                            eventTimezone?.zoneValue
                          )}{' '}
                          {eventTimezone?.shortName}
                        </span>
                      </div>
                    </div>
                  );
                },
              },
              {
                accessor: 'text',
                Cell: ({ row }) => {
                  return (
                    <>
                      <div className="text-base main-text-color">
                        {typeof row?.original?.text === 'string' ? (
                          <span>{row.original.text}</span>
                        ) : (
                          <>
                            <span
                              className="cursor-pointer sub-text-color"
                              onClick={() =>
                                selectVendor(row.original.text?.id, row.index)
                              }
                            >
                              {row.original.text?.name}
                            </span>{' '}
                            <span>Accepted</span>
                          </>
                        )}
                        <Loader
                          color="#e89a89"
                          position="center"
                          loading={showLoader && getTextIndex === row.index}
                          size="0.75rem"
                          className={`${
                            showLoader &&
                            getTextIndex === row.index &&
                            'row_loader'
                          }`}
                        />
                      </div>
                      {row.original.subText && (
                        <div className="text-sm">
                          {row.original.subText.map(
                            (data: TinyObject, i: number) => {
                              return (
                                <>
                                  <span
                                    className="cursor-pointer sub-text-color"
                                    key={i}
                                    onClick={() => selectVendor(data.id, -1)}
                                  >
                                    {showVendorsName(data.name)}
                                  </span>
                                  {row.original.subText.length - 1 !== i && (
                                    <span>{', '}</span>
                                  )}
                                  <Loader
                                    color="#e89a89"
                                    position="center"
                                    loading={
                                      showLoader && setRowClick === data.id
                                    }
                                    size="0.75rem"
                                    className={`${
                                      showLoader &&
                                      setRowClick === data.id &&
                                      'row_loader'
                                    }`}
                                  />
                                </>
                              );
                            }
                          )}
                        </div>
                      )}
                    </>
                  );
                },
              },
            ]}
            tableData={timeLineArray}
            onCellMouseEnter={() => {}}
            showPagination={false}
          />
        }
        className="margin-bottom"
      />
    </div>
  );
};

export default FeedTable;
