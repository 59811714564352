import * as spacesTypes from './space.constant';
import { initialSpaceState, ISpaceState } from './space.state';

export const spaceReducer = (
  state: ISpaceState = initialSpaceState,
  action: { type: string; page: number; payload: any; spacesCount: number }
): ISpaceState => {
  switch (action.type) {
    case spacesTypes.GET_SPACES:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        spacesCount: action.spacesCount,
      };

    case spacesTypes.SEARCH_SPACES_DATA:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        spacesCount: action.spacesCount,
      };
    case spacesTypes.GET_SPACE_DETAIL:
      return {
        ...state,
        spaceData: action.payload,
      };
    case spacesTypes.GET_EVENT_SPACES:
      return {
        ...state,
        eventSpaces: action.payload,
      };
    case spacesTypes.EMPTY_EVENT_SPACES:
      return {
        ...state,
        eventSpaces: {},
      };
    default:
      return state;
  }
};
