import React from 'react';
import { Link } from 'react-router-dom';

const CommonNavigation: React.FC<{
  linkTo: string;
  header: string;
  subHeader: string;
  className?: string;
}> = ({ linkTo, header, subHeader, className }) => {
  return (
    <div className={`navigation-section mb-8 ${className}`}>
      <Link className="navigation-link float-none" to={linkTo}>
        {header}
      </Link>
      <span>&gt;</span>
      <p className="sub-navigation-header">{subHeader}</p>
    </div>
  );
};

export default CommonNavigation;
