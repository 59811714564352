import React, { useEffect, useState } from 'react';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import SlideDrawer from '../../Common/SliderPane';
import Backdrop from '../../Common/SliderPane/Backdrop';
import AddPricing1 from './AddPricing';
import Footer from '../../Common/Footer';
import DraggableList from '../../Common/DraggableList';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import PriceListItem from './PriceListItem';
import './PricingIndex.scss';
import {
  clearPackageElementsForm,
  clearServicePriceForm,
  clearServiceSetupForm,
  clearServiceTaskListForm,
  clearServiceVendorForm,
} from '../../../redux/form/form.action';
import { loadState } from '../../../utils';
import { IFormState } from '../../../redux/form';
import {
  clearSinglePriceDetail,
  getPackageServicePrice,
  getSimpleServicePrice,
  IServiceState,
} from '../../../redux/service';
import { ILoaderState } from '../../../redux/loader';

const PricingIndex = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [elementId, setElementId] = useState(null);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [serviceType] = useState(Number(query.get('serviceType')));
  const [serviceId] = useState(query.get('serviceId'));
  const serviceIdEdit = parseInt(params['serviceId'], 10);
  const [isPriceEdit, setPriceEdit] = useState<boolean>(false);
  const { pricingForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const { serviceData } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { serviceSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [isEdit] = useState(serviceIdEdit ? true : false);
  const [serviceSetupData] = useState(
    isEdit ? loadState('serviceSetupForm') : serviceSetupForm
  );

  const { packageElementForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const elementsAssociated = packageElementForm?.['packageElements'].map(
    (data: any) => ({
      id: data.id,
    })
  );
  const elementIds =
    elementsAssociated?.length > 0
      ? elementsAssociated.map(({ id }) => id)
      : [];

  const toggleDrawer = async (id: any) => {
    setDrawerOpen(!drawerOpen);
    setElementId(id);
    if (drawerOpen) {
      setPriceEdit(false);
      await dispatch(clearSinglePriceDetail());
    }
  };
  let backdrop: {};
  if (drawerOpen) {
    backdrop = <Backdrop close={() => toggleDrawer(null)} />;
  }

  // Code added for Edit case (Tab click)
  useEffect(() => {
    async function getElementVendor() {
      if (serviceIdEdit) {
        if (elementIds?.length > 0) {
          await dispatch(getPackageServicePrice(elementIds));
        } else {
          await dispatch(getSimpleServicePrice(Number(serviceIdEdit)));
        }
      }
      await dispatch(clearServiceVendorForm());
    }
    getElementVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceIdEdit, dispatch]);

  const onSubmit = async () => {
    clearServiceForms();
    push(`/data-resources/services`);
  };

  const clearServiceForms = async () => {
    await dispatch(clearServiceSetupForm());
    await dispatch(clearPackageElementsForm());
    await dispatch(clearServicePriceForm());
    await dispatch(clearServiceVendorForm());
    await dispatch(clearServiceTaskListForm());
  };

  // For handling multiple pricing case (in Package)
  const filteredElement = [];
  let itemIndex = -1;
  if (pricingForm?.['pricing']?.length > 0) {
    pricingForm?.['pricing'].forEach((item) => {
      if (filteredElement.length > 0) {
        filteredElement.forEach((bChild) => {
          if (bChild.hasOwnProperty('elementId')) {
            itemIndex = filteredElement.findIndex(
              (child) => child.elementId === item.element['id']
            );
          }
        });
        if (itemIndex !== -1) {
          filteredElement[itemIndex].data.push({ ...item });
        } else {
          filteredElement.push({
            elementId: item.element['id'],
            data: [{ ...item }],
          });
        }
      } else {
        filteredElement.push({
          elementId: item.element['id'],
          data: [{ ...item }],
        });
      }
    });
  }
  return (
    <div className="priceList_wrapper">
      <SlideDrawer show={drawerOpen} slideClass="side-drawer open">
        <>
          <h1 className="h1_custom">
            {isPriceEdit ? 'Edit Pricing' : 'Create New Pricing'}
          </h1>
          <AddPricing1
            closePane={() => toggleDrawer(null)}
            serviceId={Number(serviceId ?? serviceIdEdit)}
            serviceType={serviceType}
            elementId={elementId}
            elementIds={elementIds}
            isEdit={isPriceEdit}
          />
        </>
      </SlideDrawer>
      {backdrop}

      {elementsAssociated?.length > 0 ? (
        packageElementForm?.['packageElements'].map((data: any) => {
          return filteredElement.map((item: any) => {
            // Package Elements price case
            if (item.elementId === data.id) {
              return (
                <div key={item.id}>
                  <PrimaryLabel
                    labelText={`${data.name}`}
                    className="mx-12 mt-8 sub-header"
                  />
                  <DraggableList dragList={item.data} isDragDisabled={true}>
                    <PriceListItem
                      serviceId={Number(serviceIdEdit ?? serviceId)}
                      serviceType={serviceType}
                      elementIds={elementIds}
                      modalAction={() => toggleDrawer(data.id)}
                      setEdit={setPriceEdit}
                    />
                  </DraggableList>
                  <Button
                    type="button"
                    label="Add Pricing"
                    onClick={() => toggleDrawer(data.id)}
                    className="add_price_list"
                  />
                </div>
              );
            } else {
              return null;
            }
          });
        })
      ) : (
        // Normal Element price case
        <div>
          <PrimaryLabel
            labelText={`${
              isEdit ? serviceSetupData?.['name'] : serviceData?.['name']
            }`}
            className="mx-12 mt-8 sub-header"
          />
          {pricingForm?.['pricing'].length > 0 && (
            <DraggableList
              dragList={pricingForm?.['pricing']}
              isDragDisabled={true}
            >
              <PriceListItem
                serviceId={
                  serviceId ? Number(serviceId) : Number(serviceIdEdit)
                }
                serviceType={serviceType}
                elementIds={elementIds}
                modalAction={() => toggleDrawer(null)}
                setEdit={setPriceEdit}
              />
            </DraggableList>
          )}
          <Button
            type="button"
            label="Add Pricing"
            onClick={() => toggleDrawer(null)}
            className="add_price_list"
          />
        </div>
      )}
      <Footer
        buttonText={`Save ${serviceType > 1 ? 'Package' : 'Element'}`}
        isArrow={false}
        onClick={onSubmit}
        showLoader={loading}
      />
    </div>
  );
};

export default PricingIndex;
