import React from 'react';

const Chevron: React.FC<{
  className: string;
  height?: number;
  width: number;
  fill: string;
}> = ({ className, height, width, fill }) => {
  return (
    <svg
      className={className}
      height={height}
      width={width}
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.60396 4.39622L0.164193 0.95635C-0.0547311 0.737533 -0.0547311 0.382758 0.164193 0.164047C0.382921 -0.0546822 0.737678 -0.0546822 0.95639 0.164047L4.00006 3.2078L7.04362 0.164135C7.26244 -0.0545937 7.61716 -0.0545937 7.83589 0.164135C8.0547 0.382864 8.0547 0.737621 7.83589 0.956439L4.39607 4.39631C4.28665 4.50568 4.1434 4.5603 4.00008 4.5603C3.85668 4.5603 3.71332 4.50557 3.60396 4.39622Z"
        fill={`${fill ?? '#5D5D5D'}`}
      />
    </svg>
  );
};

export default Chevron;
