import React, { useEffect, useState } from 'react';
import { Button, PrimaryLabel, Topnav } from '@alfredclub/hom-planner-theme';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Child from './Child';
import {
  eventActions,
  eventPageUrl,
  eventsNavigation,
  scheduleActions,
} from '../../../constants';
import moment from 'moment';
import {
  eventTimeDateFormat,
  eventStandardFormat,
  EVENT_DATE_FORMAT,
  EVENT_YEAR_FORMAT,
} from '../../../constants';
import { useSelector } from 'react-redux';
import {
  clearAssignedVendor,
  clearGetInfoStatus,
  clearVendorRequirements,
  getEventDetail,
  IEventsState,
} from '../../../redux/event';
import { getESTTime, getDateFormatted } from '../../../utils';
import { getImageUrl, getSepratedTime } from '../../../utils';
import { useDispatch } from 'react-redux';
import {
  cancelEvent,
  deleteEvent,
  getRecurringEventDetail,
  publishEvent,
  updateEventSetup,
  pastEventPublish,
  clearBuildingsByTimezone,
  updateRecurringEventSetup,
  checkRequiredField,
  cloneEvent,
} from '../../../redux/event';
import {
  clearEventSetupForm,
  clearRecurringSetupForm,
  IFormState,
} from '../../../redux/form';
import CreateEventPopup from '../CreateEventPopup';
import { defaultTimezone, timeFormat } from '../../../constants/index';
import {
  productionTeamSchema,
  recurringInPersonSchema,
  recurringSchema,
  singleInPersonSchema,
  singleVirtualSchema,
} from '../../../validations/eventCreation';
import {
  openBroadcastModal,
  resetModalValues,
} from '../../../redux/modal/modal.action';
import { useFetch } from '../../../hooks';
import CommonLoader from '../../Common/CommonLoader';
import { ILoaderState } from '../../../redux/loader';

const EventsLayout = () => {
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const [eventId, setEventId] = useState(
    useParams<{ eventId: string }>().eventId
  );
  const isEdit = Boolean(query.get('edit'));

  const [clonedEvent, setClonedEvent] = useState(false);
  const { recurringSetupForm: recurringEventData } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const isVirtualEvent = (recurring) => {
    if (recurring) {
      return recurringEventData?.['virtual'];
    } else {
      return eventData?.['virtual'];
    }
  };

  // Handle Event actions dropdown change
  const onEventStateChange = async (eventState) => {
    if (Number(eventState.value) === 1) {
      await dispatch(deleteEvent(Number(eventId), isRecurring));
      history.push(eventPageUrl);
    } else if (Number(eventState.value) === 2) {
      await dispatch(
        cancelEvent(
          Number(eventId),
          Number(
            isRecurring
              ? !recurringEventData?.['cancelled']
              : !eventData?.['cancelled']
          ),
          isRecurring
        )
      );
      history.push(eventPageUrl);
    } else if (Number(eventState.value) === 3) {
      await dispatch(publishEvent(false, Number(eventId), isRecurring));
      history.push(eventPageUrl);
    } else if (Number(eventState.value) === 4) {
      await dispatch(openBroadcastModal());
      await dispatch(resetModalValues(true));
    } else if (Number(eventState.value) === 5) {
      await dispatch(clearBuildingsByTimezone());

      const clonedEventId = await dispatch(
        cloneEvent(Number(eventId), isRecurring)
      );
      toggleClonedEvent();
      setEventId(() => clonedEventId);
      toggleModalState();
    }
  };
  const toggleClonedEvent = () => {
    setClonedEvent(!clonedEvent);
  };
  const recurringSaveExit = async (isPublished?: boolean) => {
    try {
      const virtualEvent = recurringEventData?.['virtual'];
      let recurringValidation;
      if (virtualEvent) {
        recurringValidation = await recurringSchema.validate({
          buildings: recurringEventData?.['buildings'],
          scheduleEvents: recurringEventData?.['scheduleEvents'],
        });
      } else {
        recurringValidation = await recurringInPersonSchema.validate({
          buildings: recurringEventData?.['buildings'],
          scheduleEvents: recurringEventData?.['scheduleEvents'],
        });
      }

      const getScheduleEvents = recurringValidation?.['scheduleEvents'].map(
        (item) => {
          const obj = Object.assign({}, item);
          obj['startTime'] = getSepratedTime(
            obj['startTime'],
            isRecurring
              ? recurringEventData?.timezone?.zoneValue
              : eventData?.timezone?.zoneValue ?? defaultTimezone,
            timeFormat
          );
          obj['endTime'] = getSepratedTime(
            obj['endTime'],
            isRecurring
              ? recurringEventData?.timezone?.zoneValue
              : eventData?.timezone?.zoneValue ?? defaultTimezone,
            timeFormat
          );
          return obj;
        }
      );
      const data = {
        visibility: recurringEventData?.['visibility'],
        buildings: clonedEvent ? [] : recurringValidation?.['buildings'],
        scheduleEvents: getScheduleEvents,
        visibleTo: recurringEventData?.['visibleTo'],
      };
      await dispatch(updateRecurringEventSetup(Number(eventId), data));
      if (isPublished) {
        await dispatch(publishEvent(true, Number(eventId), isRecurring));
        await dispatch(clearEventSetupForm());
        await dispatch(clearRecurringSetupForm());
      }
      history.push(eventPageUrl);
    } catch (error) {
      dispatch(checkRequiredField(error?.['message']));
    }
  };

  const { eventInviteesData, eventBuildingData } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  // eventData is an alias to eventSetupform
  const { eventSetupForm: eventData } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );

  const [eventHeader, setEventHeader] = useState(
    eventData?.['displayName'] ?? 'Event Planning'
  );
  const isRecurring = localStorage.getItem('isRecurring') ? true : false;
  const { status } = useFetch(
    isRecurring ? getRecurringEventDetail : getEventDetail,
    [Number(eventId)],
    isEdit
  );
  const eventType =
    isVirtualEvent(isRecurring) ?? query.get('virtual') === 'true';
  const eventBuilding = isRecurring
    ? recurringEventData?.['buildings']?.length
    : eventData?.['buildings']?.length;
  const [modalState, toggleModal] = useState<boolean>(false);
  const buildingCount =
    eventBuilding ??
    (eventInviteesData?.['buildings']?.length || eventBuildingData?.length);

  const singleEventSaveExit = async (isPublished?: boolean) => {
    try {
      let singleFormValidate;
      if (eventData?.['virtual']) {
        singleFormValidate = await singleVirtualSchema.validate({
          ...eventData,
        });
      } else {
        singleFormValidate = await singleInPersonSchema.validate({
          ...eventData,
        });
      }
      if (
        eventData?.['productionTeamExist'] ||
        eventData?.['productionTeamRequired']
      ) {
        singleFormValidate = await productionTeamSchema.validate({
          ...eventData,
          productionTeams: eventData?.['productionTeams'],
        });
      }
      await dispatch(updateEventSetup(Number(eventId), singleFormValidate, false, isPublished));
      if (isPublished) {
        await dispatch(publishEvent(true, Number(eventId), isRecurring));
        await dispatch(clearEventSetupForm());
        await dispatch(clearRecurringSetupForm());
      }
      history.push(eventPageUrl);
    } catch (error) {
      dispatch(checkRequiredField(error?.['message']));
    }
  };
  const onSaveExit = async () => {
    if (isRecurring) {
      recurringSaveExit();
    } else {
      singleEventSaveExit();
    }
  };
  const toggleModalState = () => {
    toggleModal(!modalState);
  };
  const redirectToMain = () => {
    history.push(eventPageUrl);
  };
  // Render Event name on topbar
  const renderTab = (step: string): void => {
    if (eventData?.['displayName'] !== '') {
      const titleName = eventData?.['displayName'];
      switch (step) {
        case 'setup':
          return setEventHeader(titleName);
        case 'recurring':
          return setEventHeader('Scheduling');
        case 'vendor-assignment':
          return setEventHeader(titleName);
        case 'task-management':
          return setEventHeader('Tasks and Inventory: '.concat(titleName));
        case 'timeline':
          return setEventHeader('Timeline: '.concat(titleName));
        case 'users':
          return setEventHeader('Users');
        default:
          return setEventHeader('Event Planning');
      }
    } else {
      if (isRecurring) {
        return setEventHeader('Schedule Event');
      } else {
        return setEventHeader('Event Planning');
      }
    }
  };
  useEffect(() => {
    (async () => await dispatch(clearGetInfoStatus()))();
    return async () => {
      localStorage.removeItem('isRecurring');
      await dispatch(clearEventSetupForm());
      await dispatch(clearRecurringSetupForm());
      await dispatch(clearAssignedVendor());
      await dispatch(clearGetInfoStatus());
    };
  }, [dispatch]);
  // Handle Edit Schedule Button Click
  const onEditSchedule = async () => {
    // await dispatch(clearEventSetupForm());
    await dispatch(clearBuildingsByTimezone());
    await dispatch(clearVendorRequirements());
    if (eventData?.['parentSchedule']) {
      localStorage.setItem('isRecurring', '1');
      setEventId(eventData?.['parentSchedule']);
      history.push(
        `/events/${eventData?.['parentSchedule']}/create?step=recurring&edit=true`
      );
    }
  };

  // Handle Publish event click
  const onPublishEvent = async () => {
    if (!eventData?.['isPastEvent']) {
      if (isRecurring) {
        recurringSaveExit(true);
      } else {
        singleEventSaveExit(true);
      }
    } else {
      await dispatch(pastEventPublish());
      history.push(eventPageUrl);
    }
  };

  return (
    <div className="flex layout_wrapper">
      <div className={`w-40 z-0 left_side`}>
        <div className="p-4">
          {isRecurring ? (
            <PrimaryLabel
              labelText={`${moment(recurringEventData?.['startDate']).format(
                EVENT_DATE_FORMAT
              )} -
                ${moment(recurringEventData?.['endDate']).format(
                EVENT_DATE_FORMAT
              )} ${moment(recurringEventData?.['endDate']).format(
                EVENT_YEAR_FORMAT
              )}`}
              className={`sub-header block leading-tight ${'label_color'} h-auto`}
            />
          ) : (
            <PrimaryLabel
              labelText={getDateFormatted(
                eventData?.sourceDate,
                eventTimeDateFormat
              )}
              className={`sub-header block leading-tight ${'label_color'}`}
            />
          )}
          <p
            className={`block leading-loose ${'label_color'} ${isRecurring || eventData?.recurring
                ? 'pointer-events-none'
                : 'cursor-pointer ontime_hover'
              }`}
            onClick={toggleModalState}
          >
            {!isRecurring
              ? `${getESTTime(
                eventData.startDatetime,
                eventStandardFormat,
                eventData?.timezone?.zoneValue
              )} - ${getESTTime(
                eventData.endDatetime,
                eventStandardFormat,
                eventData?.timezone?.zoneValue
              )}
             ${eventData?.['timezone']?.['shortName'] ?? defaultTimezone}`
              : 'Recurring Schedule'}
          </p>
          <PrimaryLabel
            labelText={`${buildingCount} Buildings`}
            className="block leading-loose lbl_count"
          />
          {eventData?.['parentSchedule'] &&
            !recurringEventData?.['startDate'] &&
            !eventData?.['isCloned'] && (
              <>
                <PrimaryLabel
                  labelText="Part of Recurring"
                  className="lbl_count"
                />
                <div className="left_edit_event">
                  <Button
                    label="Edit Schedule"
                    className="edit_schedule text-F15"
                    isLeftIcon={true}
                    leftIcon={getImageUrl('Events/editschedule.svg')}
                    type="button"
                    onClick={onEditSchedule}
                  />
                </div>
              </>
            )}
        </div>
        {modalState && (
          <CreateEventPopup
            modalState={modalState}
            toggleModal={toggleModalState}
            editEventId={Number(eventId)}
            clonedEvent={clonedEvent}
            toggleClonedEvent={toggleClonedEvent}
            setEventId={setEventId}
          />
        )}
        {eventData?.['cancelled'] && (
          <div className="event_cancel">
            <img
              src={getImageUrl('Events/cancelwhite.svg')}
              alt="cancel"
              className="inline pr-2"
            />
            <PrimaryLabel labelText="Event Cancelled" className="label_color" />
          </div>
        )}

        <div className="left_event_type">
          <div className="eventType_text">
            {eventData?.['serviceCategory']?.['name'] ??
              recurringEventData?.['serviceCategory']?.['name']}
            &nbsp;
            {eventType ? 'Virtual' : 'InPerson'}
          </div>
        </div>

        <div className={`pt-2`}>
          <ul>
            {eventsNavigation.map((item) => {
              return (
                <li
                  key={item.id}
                  className={`li_inactive ${query.get('step') ===
                    (isRecurring && item.step === 'setup'
                      ? 'recurring'
                      : item.step) && `li_active`
                    } ${!eventData?.['published'] && item.step === 'users'
                      ? 'hidden'
                      : ''
                    } ${isRecurring && item.step === 'users' && 'hidden'
                    } cursor-pointer`}
                  id={item.id.toString()}
                  onClick={() => {
                    history.push(
                      `/events/${eventId}/create?step=${!isRecurring
                        ? item.step
                        : item.step === 'setup'
                          ? 'recurring'
                          : item.step
                      }${item.step === 'vendor-assignment'
                        ? `&tab=vendor-invite&edit=true`
                        : item.step === 'users'
                          ? `&tab=total-rsvps&edit=true`
                          : `&edit=true`
                      }${item.step === 'task-management' ? `&tab=task-list` : ''
                      }`
                    );
                    renderTab(item.step);
                  }}
                >
                  {!isRecurring
                    ? item.title
                    : item.title === 'Event Setup'
                      ? 'Scheduling'
                      : item.title}
                </li>
              );
            })}
          </ul>

          <button className="event-navbar-btn" onClick={onSaveExit}>
            Save and exit
          </button>
        </div>
      </div>

      <div className={`w-full`}>
        <Topnav
          headerTitle={eventHeader}
          showIcon={false}
          hideProfile={true}
          rightButtonText={`${eventData?.['published'] ? 'Publish changes' : 'Publish Event'
            }`}
          onRightButtonClick={() => onPublishEvent()}
          rightButtonClass={`${(eventData?.['isPastEvent'] ||
              recurringEventData?.['isPastEvent'] || loading) &&
            'cancel_publish'
            }`}
          showEventDropdown={true}
          dropdownPlaceHolder={`${recurringEventData?.['recurring']
              ? `Schedule Actions`
              : `Event Actions`
            }`}
          dropdownOptions={
            recurringEventData?.['recurring'] ? scheduleActions : eventActions
          }
          onDropdownChange={async (e) => await onEventStateChange(e)}
          isPublished={eventData?.['published']}
          publishedText="Published"
          showCloseIcon={true}
          onCloseIconClick={() => {
            redirectToMain();
          }}
          eventActionPrefix={`${recurringEventData?.['recurring'] ? `schedule` : `event`
            }`}
        />
        {status === 'fetched' ? (
          <Child
            step={query.get('step')}
            render={renderTab}
            recurring={isRecurring}
          />
        ) : (
          <CommonLoader loading={status !== 'fetched'} />
        )}
      </div>
    </div>
  );
};

export default EventsLayout;
