import * as loadingTypes from '../loader/loader.constant';
import * as toastTypes from '../toast/toast.constant';
import * as whiteLabelTypes from '../whitelabel/whitelabel.constant';
import * as formTypes from '../form/form.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import { Whitelabel } from '../../models/whitelabel';
import { addWhitelabelApi, getWhitelabelApi } from './whitelabel.service';

export const addWhitelabel = (buildingId: number, data: Whitelabel) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const whitelabel: Whitelabel = await addWhitelabelApi(buildingId, data);
  if (whitelabel) {
    dispatch({
      type: whiteLabelTypes.ADD_WHITELABEL,
      payload: whitelabel,
    });
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.building.whiteLabel,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getWhitelabel = (buildingId) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const whitelabel: Whitelabel = await getWhitelabelApi(buildingId);
  if (whitelabel?.['error']?.['code'] === 404) {
    const emptyObj = {
      buttonColor: '',
      buttonText: '',
      logo: { id: null },
      topBar: '',
      topBarText: '',
    };
    dispatch({
      type: formTypes.UPDATE_BUILDING_WHITELABEL_FORM,
      payload: emptyObj,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else {
    dispatch({
      type: formTypes.UPDATE_BUILDING_WHITELABEL_FORM,
      payload: whitelabel,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
