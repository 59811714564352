import React, { useEffect, useState } from 'react';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import SlideDrawer from '../../Common/SliderPane';
import Backdrop from '../../Common/SliderPane/Backdrop';
import AddTask from './AddTask';
import Footer from '../../Common/Footer';
import DraggableList from '../../Common/DraggableList';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import TaskListItem from './TaskListItem';
import './TaskList.scss';
import {
  getServiceTaskList,
  serviceVendors,
  clearPackageElementList,
  clearServiceTaskDetail,
} from '../../../redux/service/services.action';
import { clearServiceTaskListForm, IFormState } from '../../../redux/form';
import { clearServiceVendorForm } from '../../../redux/form/form.action';
import { ILoaderState } from '../../../redux/loader';
import { getImageUrl } from '../../../utils';
import AssignImportedTaskPopup from './AssignImportedTaskPopup';

const TaskList = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const { location, push } = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { serviceTaskList } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [serviceId] = useState(Number(query.get('id')));
  const serviceIdEdit = parseInt(params['serviceId'], 10);
  const [isEdit] = useState(serviceIdEdit ? true : false);
  const [taskEdit, setTaskEdit] = useState<boolean>(false);
  const [addTaskBtnState, setAddTaskBtnState] = useState<boolean>(false);
  const [taskTemplateCheck, setTaskTemplateCheck] = useState<boolean>(false);
  const [showTempTitle, setShowTempTitle] = useState<boolean>(false);
  const [taskTemplateName, setTaskTemplateName] = useState<string>('');
  const [serviceType] = useState(Number(query.get('serviceType')));
  const [taskId, setTaskId] = useState(null);
  const taskImported = location.state?.['taskImported'] ?? false;
  const importedServiceId = location.state?.['serviceId'] ?? false;

  const [assignVendorModal, setAssignVendorModal] = useState<boolean>(
    taskImported ?? false
  );

  const toggleDrawer = async () => {
    await dispatch(clearPackageElementList());
    setDrawerOpen(!drawerOpen);
    if (drawerOpen) {
      setTaskEdit(false);
      setTaskId(null);
      setTaskTemplateCheck(false);
      setTaskTemplateName('');
      setAddTaskBtnState(false);
      setShowTempTitle(false);
      await dispatch(clearServiceTaskDetail());
    }
  };

  const removeTemplateTitle = () => {
    setShowTempTitle(true);
  };

  const taskTemplateData = (taskName: string) => {
    setTaskTemplateCheck(taskName ? true : false);
    setTaskTemplateName(taskName);
  };

  let backdrop: {};
  if (drawerOpen) {
    backdrop = <Backdrop close={toggleDrawer} />;
  }
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  // Code added for Edit case (Specially on Tab click)
  useEffect(() => {
    async function getEventTaskList() {
      if (serviceIdEdit) {
        await dispatch(clearServiceTaskListForm());
        await dispatch(clearPackageElementList());
        await dispatch(getServiceTaskList(Number(serviceIdEdit)));
        await dispatch(serviceVendors(Number(serviceIdEdit)));
        await dispatch(clearServiceVendorForm());
      }
    }
    getEventTaskList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceIdEdit, dispatch]);

  const onImportTemplate = () => {
    push(`/data-resources/task-template`, {
      serviceTask: true,
      isEdit,
      serviceId: Number(isEdit ? params['serviceId'] : serviceId),
      serviceType: Number(serviceType),
    });
  };

  const onSubmit = async () => {
    if (!isEdit) {
      push(
        `/data-resources/services/add-service?tab=inventory&serviceType=${serviceType}&id=${serviceId}`
      );
    } else {
      push(
        `/data-resources/services/edit-service/${serviceIdEdit}/?tab=inventory&serviceType=${serviceType}&id=${serviceIdEdit}`
      );
    }
  };

  return (
    <div className="tabList_wrapper">
      {assignVendorModal && (
        <AssignImportedTaskPopup
          modalState={assignVendorModal}
          modalClose={() => setAssignVendorModal(!assignVendorModal)}
          serviceId={importedServiceId}
        />
      )}
      <div className="pl-5 relative">
        <Button
          className={`add_task_list`}
          label="Add Task"
          type="button"
          onClick={() => setAddTaskBtnState(!addTaskBtnState)}
        />
        <div
          className={`addtask-content ${addTaskBtnState ? 'block' : 'hidden'}`}
        >
          <ul className="cursor-pointer">
            <li onClick={toggleDrawer}>
              <img
                src={getImageUrl('Events/add-new-task.svg')}
                alt="error"
                className="inline-flex pr-2"
              />
              Add new task
            </li>
            <li onClick={onImportTemplate}>
              <img
                src={getImageUrl('Events/import-task.svg')}
                alt="error"
                className="inline-flex pr-2"
              />
              Import task template
            </li>
          </ul>
        </div>
      </div>

      <SlideDrawer
        show={drawerOpen}
        slideClass="side-drawer h-full overflow-y-scroll side-drawer-event-mng-width open"
      >
        <>
          <img
            src={getImageUrl('data-resources/cancel.svg')}
            alt="close"
            className="inline-flex float-right cursor-pointer"
            onClick={toggleDrawer}
          />
          <h1 className="h1_custom">
            {taskEdit ? 'Edit Task' : 'Create New Task'}
            {taskTemplateName && !showTempTitle && (
              <PrimaryLabel
                labelText={`Template: ${taskTemplateName}`}
                className="float-right"
              />
            )}
          </h1>

          <AddTask
            closePane={toggleDrawer}
            closeTemplatetitle={removeTemplateTitle}
            serviceId={isEdit ? serviceIdEdit : serviceId}
            serviceType={serviceType}
            isEdit={taskEdit}
            taskId={taskId}
            isTaskTemplate={taskTemplateCheck}
          />
        </>
      </SlideDrawer>
      {backdrop}
      {serviceTaskList.length > 0 && (
        <DraggableList dragList={serviceTaskList}>
          <TaskListItem
            serviceId={isEdit ? serviceIdEdit : serviceId}
            serviceType={serviceType}
            drawer={toggleDrawer}
            setTaskEdit={setTaskEdit}
            setTaskId={setTaskId}
            taskTemplateData={taskTemplateData}
          />
        </DraggableList>
      )}

      <Footer
        buttonText="Continue"
        isArrow={true}
        onClick={onSubmit}
        disabled={loading}
        buttonClass={`${loading ? 'button_disable' : ''}`}
        showLoader={loading}
      />
    </div>
  );
};

export default TaskList;
