import React from 'react';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useHistory } from 'react-router-dom';
import ModalPopup from '../ReactModal/Modal';
import '../Clients/Building/BuildingPopup/BuildingPopup.scss';

const DeleteElementPopup: React.FC<any> = ({
  modalState,
  isDelete,
  modalClose,
  onDelete,
  onArchive,
  redirectValue,
  headerText,
  subHeader,
  buttonLabel,
  modalClass,
}: any) => {
  const history = useHistory();
  const onSubmit = async () => {
    if (isDelete) {
      await onDelete();
    } else {
      await onArchive();
    }
    if (!redirectValue) {
    } else {
      history.push(`${redirectValue}`);
    }
  };
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={modalClose}
      className={`delete_modal ${modalClass}`}
    >
      <PrimaryLabel
        className="sub-header header_style"
        labelText={headerText}
      />
      <br />
      <PrimaryLabel
        className={`sub_header_child ${isDelete && 'delete_building'}`}
        labelText={subHeader}
      />
      <div className="button_div">
        <Button
          label={buttonLabel}
          className=" block delete_building_button"
          onClick={onSubmit}
          backgroundColor="#e89a89"
        />
      </div>
    </ModalPopup>
  );
};

export default DeleteElementPopup;
