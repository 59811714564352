import React, { useState, useEffect } from 'react';
import { Button, Table, Topnav } from '@alfredclub/hom-planner-theme';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CommonNavigation from '../../Common/CommonNavigation';
import { useFetch, usePagination } from '../../../hooks';
import { getBuildingStaffWithMngId } from '../../../redux/staff/staff.action';
import { findIndexFromArrayOfObject, loadState } from '../../../utils';
import './Staff.scss';
import { updateBuildingStaff } from '../../../redux/form/form.action';
import { IStaffState } from '../../../redux/staff/staff.state';

const BuildingStaffSelection: React.FC = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const [pageNo, setPage] = useState(0);
  const pagination = usePagination();
  const [managementId] = useState(Number(query.get('managementId')));
  const [buildingId] = useState(Number(query.get('buildingId')) ?? null);
  const [buildingName] = useState(query.get('buildingName'));
  const isEdit = Boolean(query.get('edit'));
  const { status } = useFetch(getBuildingStaffWithMngId, [
    managementId,
    buildingId,
  ]);
  const { pages, staffCount } = useSelector(
    (state: { buildingStaff: IStaffState }) => state.buildingStaff
  );
  const [staffArray, setBuildingStaff] = useState([]);

  const onPageClick = async (page: number) => {
    await pagination(
      pages,
      page - 1,
      getBuildingStaffWithMngId(managementId, buildingId, 10, page - 1),
      true
    );
    setPage(page - 1);
  };

  const handleOnChange = async (rowData: any) => {
    const selectedStaff = staffArray;
    const staffIdIndex = findIndexFromArrayOfObject(
      selectedStaff,
      'id',
      rowData
    );
    if (staffIdIndex !== -1) {
      selectedStaff.splice(staffIdIndex, 1);
    } else {
      selectedStaff.push({ ...rowData });
    }
    setBuildingStaff(() => [...selectedStaff]);
  };

  const onSubmit = async () => {
    await dispatch(updateBuildingStaff(staffArray));
    if (!isEdit) {
      push(
        `/data-resources/clients/buildings/add-building?buildingName=${buildingName}`
      );
      return;
    }
    push(`/data-resources/clients/buildings/${query.get('buildingId')}`);
  };
  useEffect(() => {
    const formData = loadState('buildingForm');
    setBuildingStaff(() => [...formData?.['buildingStaff']]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Topnav headerTitle="Building Staff" />
      <div className="mx-10 mt-4">
        <form onSubmit={onSubmit}>
          <CommonNavigation
            linkTo={
              !isEdit
                ? `/data-resources/clients/buildings/add-building?buildingName=${buildingName}`
                : `/data-resources/clients/buildings/${query.get('buildingId')}`
            }
            header="Add new building"
            subHeader="Building Staff"
          />
          <Button label="Save and go back" className="save-goback" />
          {status === 'fetched' && (
            <Table
              tableColumns={[
                {
                  id: 'checkbox',
                  accessor: 'id',
                  Cell: (row: any) => {
                    return (
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={() => {
                          handleOnChange(row.row.original);
                        }}
                        checked={
                          staffArray.length > 0
                            ? findIndexFromArrayOfObject(
                                staffArray,
                                'id',
                                row.row.original
                              ) !== -1
                              ? true
                              : false
                            : false
                        }
                      />
                    );
                  },
                  Header: 'Sno',
                },
                {
                  Header: 'Staff',
                  accessor: 'name', // accessor is the "key" in the data
                  className: 'tableAnchor', // use this class for highlight the column value
                },
                {
                  Header: 'Designation',
                  accessor: 'designation',
                },
                {
                  Header: 'Contact',
                  accessor: 'contact',
                },
              ]}
              tableData={pages?.get(pageNo)?.data}
              showPagination={!(staffCount < 10) && true}
              onPageChange={(pageNumber) => onPageClick(pageNumber)}
              totalRecordsCount={staffCount}
              className="mt-2"
              onCellMouseEnter={() => {}}
            />
          )}
        </form>
      </div>
    </>
  );
};
export default BuildingStaffSelection;
