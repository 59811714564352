import { http } from '../common/http.service';
import {
  BuildingBlock,
  BuildingBlockArray,
  BuildingBlockDetail,
} from '../../models/buildingblock';
import {
  BUILDING_BLOCKS_BASE_URL,
  ADD_BUILDING_BLOCKS_BASE_URL,
  DELETE_BUILDING_BLOCKS_BASE_URL,
  RECORDS_LIMIT,
  RECORDS_CMP,
  RECORDS_PAGE,
  RECORDS_QUERY,
  ALL_RECORDS,
  GET_BUILDING_BLOCK_DETAIL__URL,
  UPDATE_BLOCK,
} from '../../constants/index';
import { PaginatedResponse, DeleteResponse } from '../../models';
import { AxiosResponse } from 'axios';

export const getBuildingBlocksApi = async (
  buildingId: number,
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS
): Promise<PaginatedResponse<BuildingBlock>> =>
  http
    .get<PaginatedResponse<BuildingBlock>>(
      `${BUILDING_BLOCKS_BASE_URL}/${buildingId}?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<BuildingBlock>>) => {
      return response.data;
    });

export const addBuildingBlocksApi = async (
  buildingId: number,
  blocks: BuildingBlockArray
): Promise<BuildingBlock> =>
  http
    .post<BuildingBlock>(
      `${ADD_BUILDING_BLOCKS_BASE_URL}/${buildingId}`,
      {
        blocks,
      },
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<BuildingBlock>) => {
      return response.data;
    });

export const getBuildingBlockDetailApi = async (
  blockId: number
): Promise<BuildingBlockDetail> =>
  http
    .get(`${GET_BUILDING_BLOCK_DETAIL__URL}/${blockId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<BuildingBlockDetail>) => {
      return response.data;
    });

export const updateBlockApi = async (
  blockId: number,
  data: object
): Promise<BuildingBlock> =>
  http
    .put(`${UPDATE_BLOCK}/${blockId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<BuildingBlock>) => {
      return response.data;
    });

export const deleteBuildingBlocksApi = async (
  blockId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`${DELETE_BUILDING_BLOCKS_BASE_URL}/${blockId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });
