import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  Input,
  PrimaryLabel,
} from '@alfredclub/hom-planner-theme';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllVendors,
  getVendorIndividualDetail,
} from '../../../redux/vendor/vendors.action';
import { getImageUrl } from '../../../utils';
import LabelWithIcon from '../EventSetup/LabelWithIcon';
import {
  cancelVendorAssignment,
  getVendorAssignmentInformation,
  vendorManuallyAssigned,
} from '../../../redux/event/event.action';
import { Register, SetValue } from '../../../models';
import { IEventsState } from '../../../redux/event';
import { ILoaderState } from '../../../redux/loader';
import { defaultTimezone } from '../../../constants';
import ReactTooltip from 'react-tooltip';
import { IFormState } from '../../../redux/form';
import { IVendorState } from '../../../redux/vendor';

const ManuallyAssigned: React.FC<{
  register: Register;
  setValue: SetValue;
  eventId: number;
  isAssigned: object;
  onCancel: () => void;
  onSubmit: (e: any, index: number) => void;
  fieldIndex: number;
  isRecurring: boolean;
  reset: () => void;
  weekday: number;
}> = ({
  register,
  setValue,
  eventId,
  isAssigned,
  onCancel,
  fieldIndex,
  isRecurring,
  weekday,
}) => {
  const { allVendors } = useSelector((state: IVendorState) => state.vendors);
  const dispatch = useDispatch();
  const { eventVendorAssigned } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const {
    eventSetupForm: singleEventData,
    recurringSetupForm: eventData,
  } = useSelector((state: { forms: IFormState }) => state.forms);
  const [vendorId, setVendorId] = useState<number | null>(null);
  const [selectedVendor, setVendor] = useState([]);
  const isVirtualEvent = (recurring) => {
    if (recurring) {
      return eventData?.['virtual'];
    } else {
      return singleEventData?.['virtual'];
    }
  };

  const onCancelAssignment = async () => {
    let data = {};
    if (isRecurring) {
      data = {
        weekday: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.weekday,
      };
    }
    await dispatch(cancelVendorAssignment(eventId, data, isRecurring, weekday));
    await dispatch(
      getVendorAssignmentInformation(
        eventId,
        eventData?.['recurring'],
        fieldIndex
      )
    );
    setVendor([]);
    onCancel();
  };

  const onVendorUrlChange = async (e) => {
    const vendorObj = [
      {
        docList: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.docList,
        note: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.note,
        vendor: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.vendor,
        customStreamUrl: e.target.value,
        weekday: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.weekday,
      },
    ];
    await dispatch(
      vendorManuallyAssigned(
        { vendorAssign: [vendorObj[0]] },
        eventId,
        eventData?.['recurring'],
        eventData?.timezone?.zoneValue ?? defaultTimezone,
        weekday
      )
    );
  };
  const selectVendor = async () => {
    await dispatch(getVendorIndividualDetail(vendorId));
    window.open(`/data-resources/vendors/${vendorId}`, '_blank');
  };
  return (
    <div className="manually_assignment p-6">
      <div>
        <div className="flex justify-between dropdown_class">
          <PrimaryLabel labelText="Select vendor" className="block" />
          {Object.keys(eventVendorAssigned).length > 0 &&
          eventVendorAssigned?.[weekday]?.['vendorAssign'].length > 0
            ? ''
            : vendorId && (
                <img
                  src={getImageUrl('Events/activeLink.svg')}
                  alt="icon"
                  onClick={selectVendor}
                  className="cursor-pointer"
                />
              )}
        </div>
        <Dropdown
          asyncDropdown={false}
          labelKey="vendorName"
          valueKey="vendorId"
          isLabelShow={false}
          label="Vendor"
          options={allVendors}
          onMenuOpen={async () => {
            if (allVendors.length === 0) {
              await dispatch(getAllVendors(0, 0, 'short', '', 1, 1));
            }
          }}
          ref={() => register({ name: `vendorAssign[${fieldIndex}].vendor` })}
          onOptionChange={async (e) => {
            const vendor = {
              id: e['vendorId'],
              name: e['vendorName'],
            };
            setVendorId(vendor.id);
            setValue(`vendorAssign[${fieldIndex}].vendor`, vendor);
            const updateVendor = [...selectedVendor, vendor];
            setVendor(updateVendor);
          }}
          loading={loading}
          placeholder="Type and search"
          value={{
            vendorId:
              eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.[
                'vendor'
              ]?.['id'],
            vendorName:
              eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.[
                'vendor'
              ]?.['vendorName'],
          }}
          isCustomDropIcon={true}
          ddlClass={`dropdown_class ${
            Object.keys(eventVendorAssigned).length > 0 &&
            eventVendorAssigned?.[weekday]?.['vendorAssign'].length > 0
              ? 'pointer-events-none invite_disable'
              : ''
          }`}
          sectionClass="inline-flex"
          resetValue={!selectedVendor?.[fieldIndex]}
        />
        <div
          data-tip={`${
            Object.keys(eventVendorAssigned).length > 0 &&
            eventVendorAssigned?.[weekday]?.['vendorAssign']?.length > 0
              ? 'Please cancel existing vendor assignment to assign new vendor'
              : ''
          }`}
          data-for="invite"
          className="inline-grid"
        >
          <Button
            label="Assign"
            isLeftIcon={true}
            leftIcon={getImageUrl('Events/assign.svg')}
            disabled={selectedVendor?.length <= 0 || isAssigned[weekday]}
            className={`assign_vendor ${
              (selectedVendor?.length <= 0 || isAssigned[weekday]) &&
              'button_disable'
            }`}
            onClick={() => setVendorId(null)}
          />
        </div>
        <ReactTooltip
          className="invite-tooltip"
          place="bottom"
          effect="float"
          offset={{ left: 70 }}
          id="invite"
        />
      </div>
      {isAssigned[weekday] &&
        Object.keys(eventVendorAssigned).length > 0 &&
        eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.[
          'manuallyAssigned'
        ] && (
          <>
            <div className="pt-8">
              <img
                src={getImageUrl('Events/done.svg')}
                alt="icon"
                className="inline-flex pr-2"
              />
              <span
                onClick={async () => {
                  await dispatch(
                    getVendorIndividualDetail(
                      eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.[
                        'vendor'
                      ]?.['id']
                    )
                  );
                  window.open(
                    `/data-resources/vendors/${eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.['vendor']?.['id']}`,
                    '_blank'
                  );
                }}
                className="sub-header img_label cursor-pointer sub-text-color underline"
              >{`${eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.['vendor']?.['vendorName']}`}</span>{' '}
              <span className="sub-header img_label">Assigned</span>
              <a
                href={`mailto:${eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.['vendor']?.['email']}`}
              >
                <img
                  src={getImageUrl('Events/email-icon.svg')}
                  alt="email"
                  className="block float-right"
                />
              </a>
              <div onClick={onCancelAssignment}>
                <PrimaryLabel
                  labelText="Cancel Assignment"
                  className="block cancel_assign"
                />
              </div>
            </div>

            <div className={`pt-8 ${!isVirtualEvent(isRecurring) && 'hidden'}`}>
              <LabelWithIcon
                imgSrc={getImageUrl('Events/streaming.svg')}
                labelText="Streaming Settings"
                className="img_label"
              />
              <div className="grid grid-cols-1 gap-x-2 gap-y-0">
                <div className="mt-4">
                  <PrimaryLabel labelText="Vendor URL" />
                  <Input
                    label=""
                    placeholder="vimeo.com"
                    name={`vendorAssign[${fieldIndex}].streamLink`}
                    ref={register()}
                    value={
                      eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]
                        ?.customStreamUrl
                    }
                    onInputBlur={(e) => {
                      onVendorUrlChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default ManuallyAssigned;
