import * as modalTypes from './modal.constant';

export const openBroadcastModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_BROADCAST_MODAL,
  });
};

export const closeModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.CLOSE_MODAL,
  });
};

export const openRegionModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.OPEN_REGION_MODAL,
  });
};

export const closeRegionModal = () => async (dispatch) => {
  dispatch({
    type: modalTypes.CLOSE_REGION_MODAL,
  });
};

export const resetModalValues = (data: boolean) => async (dispatch) => {
  dispatch({
    type: modalTypes.RESET_MODAL_VALUES,
    payload: data,
  });
};
