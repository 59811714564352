import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Topnav } from '@alfredclub/hom-planner-theme';
import '../../../Clients/Companies/AddNewCompany/Companies.scss';
import AddUnitForm from './addUnitForm';
import { IBlocksState } from '../../../../redux/buildingblocks';

const AddBlockForm = () => {
  const {
    buildingBlockArray = [],
    buildingId,
    buildingName,
    blockCount,
    buildingType,
  } = useSelector((state: { company: IBlocksState }) => state.company);
  const [unitsBlockArray] = useState({
    buildingBlockObj: buildingBlockArray,
  });
  const unitsArray = [];
  const size = 14;
  const unitArray = [unitsBlockArray.buildingBlockObj];
  while (unitArray[0].length > 0) {
    unitsArray.push(unitArray[0].splice(0, size));
  }
  return (
    <div className="flex">
      <div className="w-full">
        <Topnav
          headerTitle={`Add units: ${buildingName}`}
          showIcon={false}
          showSearchBar={false}
        />
        <section className={'table-wrapper mx-8 mt-6'}>
          <AddUnitForm
            unitsSlicedArray={unitsArray}
            buildingId={Number(buildingId)}
            blockCount={blockCount}
            buildingName={buildingName}
            buildingType={buildingType}
          />
        </section>
      </div>
    </div>
  );
};

export default AddBlockForm;
