import React, { Fragment } from 'react';
import { Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { PricingType, Register } from '../../../models';
import { perAttendeeRange } from '../../../constants/index';

const PricingFields: React.FC<{
  errors: Record<string, object>;
  register: Register;
  priceObjValue: PricingType[];
  className: string;
  fields: any[];
  isRange?: boolean;
}> = ({ errors, register, priceObjValue, className, fields, isRange }) => {
  return (
    <>
      {fields.map((field, index) => {
        return (
          <Fragment key={field.id}>
            {isRange && (
              <div className="mx-12 mt-8">
                <PrimaryLabel
                  className="range-text"
                  labelText={perAttendeeRange?.[index]?.['range']}
                />
              </div>
            )}
            <div className="hidden mt-4">
              <Input
                label=""
                name={`details[${index}].memberRange`}
                ref={register({ required: false })}
                value={`${
                  isRange ? perAttendeeRange?.[index]?.['value'] : '0'
                }`}
              />
            </div>
            <div
              className={`mx-12 ${className} grid grid-cols-3 gap-x-3 gap-y-0 gap-2`}
            >
              <div>
                <PrimaryLabel labelText="Total Cost to Building" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                {priceObjValue.length > 0 && (
                  <PrimaryLabel
                    labelText={`USD ${priceObjValue[0].label.toLowerCase()}`}
                    className="right_side_label"
                  />
                )}
                <Input
                  label="Total Cost to Building"
                  name={`details[${index}].buildingCost`}
                  ref={register({
                    validate: {
                      positive: (value) => parseInt(value, 10) > 0,
                    },
                  })}
                  inputType="number"
                  isLabelShow={false}
                  inputSectionClass="m-0"
                  isError={
                    errors?.details?.[index]?.['buildingCost'] ? true : false
                  }
                  value={field?.buildingCost}
                />
              </div>
              <div>
                <PrimaryLabel labelText="Vendor Cut" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                {priceObjValue.length > 0 && (
                  <PrimaryLabel labelText="USD" className="right_side_label" />
                )}
                <Input
                  label="Vendor Cut"
                  name={`details[${index}].vendorCut`}
                  ref={register({
                    validate: {
                      positive: (value) => parseInt(value, 10) > 0,
                    },
                  })}
                  isLabelShow={false}
                  inputSectionClass="m-0 mb-0"
                  inputType="number"
                  isError={
                    errors?.details?.[index]?.['vendorCut'] ? true : false
                  }
                  value={field?.vendorCut}
                />
              </div>
              <div>
                <PrimaryLabel labelText="HOM Profit" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                {priceObjValue.length > 0 && (
                  <PrimaryLabel labelText="USD" className="right_side_label" />
                )}
                <Input
                  label="HOM Profit"
                  name={`details[${index}].profit`}
                  ref={register({
                    validate: {
                      positive: (value) => parseInt(value, 10) > 0,
                    },
                  })}
                  isLabelShow={false}
                  inputType="number"
                  inputSectionClass="m-0 mb-0"
                  isError={errors?.details?.[index]?.['profit'] ? true : false}
                  value={field?.profit}
                />
              </div>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};
export default PricingFields;
