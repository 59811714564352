import {
  getClustersApi,
  addClusterApi,
  getClusterDetailApi,
  updateClusterApi,
  deleteClusterApi,
} from './cluster.service';
import * as clusterTypes from './cluster.constants';
import * as loadingTypes from '../loader/loader.constant';
import * as toastTypes from '../toast/toast.constant';
import * as formTypes from '../form/form.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import { Cluster, PaginatedResponse, DeleteResponse } from '../../models';
export const getClusters = (
  limit?: number,
  page?: number,
  cmp?: string,
  query?: string,
  all?: number,
  updateReducer = true
) => async (dispatch) => {
  const paginatedResponse: PaginatedResponse<Cluster> = await getClustersApi(
    cmp,
    limit,
    page,
    query,
    all
  );
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (paginatedResponse.data && updateReducer) {
    dispatch({
      type: clusterTypes.GET_CLUSTERS,
      payload: paginatedResponse.data,
      clusterCount: paginatedResponse.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else if (paginatedResponse.data && !updateReducer) {
    return paginatedResponse.data;
  }
};

export const searchClusters = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: clusterTypes.SEARCH_CLUSTERS_DATA,
      payload: data,
      clusterCount: data.length,
      page,
    });
  } else {
    await getClusters(10, page, 'short', query)(dispatch);
  }
};

export const addCluster = (data: Cluster) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const cluster: Cluster = await addClusterApi(data);

  if (cluster) {
    dispatch({
      type: clusterTypes.ADD_CLUSTER,
      payload: cluster,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getClusterDetail = (clusterId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const cluster: Cluster = await getClusterDetailApi(clusterId);
  if (cluster) {
    dispatch({
      type: formTypes.UPDATE_CLUSTER_FORM,
      payload: cluster,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const updateCluster = (clusterId: number, data: Cluster) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedCluster: Cluster = await updateClusterApi(clusterId, data);

  if (updatedCluster) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.cluster.updated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteCluster = (clusterId: number) => async (dispatch) => {
  const deleteResponse: DeleteResponse = await deleteClusterApi(clusterId);
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (deleteResponse.delete) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.cluster.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
