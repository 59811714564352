import React, { useState } from 'react';
import { Input, Dropdown } from '@alfredclub/hom-planner-theme';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import { Register, SetValue, Vendor } from '../../../models';
import { updateVendorIndividualForm } from '../../../redux/form';
import { useDispatch, useSelector } from 'react-redux';
import { IRegionState } from '../../../redux/region';
import { getTimezone } from '../../../redux/region/regions.action';

const BasicDetails: React.FC<{
  formErrors: Record<string, object>;
  register: Register;
  setValue: SetValue;
  setVendorName: (value: string) => void;
  vendorData: Vendor;
  isVendorContact: boolean;
}> = ({
  formErrors,
  register,
  setValue,
  setVendorName,
  vendorData,
  isVendorContact,
}) => {
  const dispatch = useDispatch();
  const [zoneLoading, setZoneLoading] = useState<boolean>(false);
  const { timezones } = useSelector(
    (state: { regions: IRegionState }) => state.regions
  );
  return (
    <div className={`${!isVendorContact && 'mt-16'}`}>
      <FormTitle title="Basic Details" />
      {Object.keys(formErrors).length > 0 && (
        <span className="error-message">Please fill required fields</span>
      )}
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          <Input
            isLabelShow={true}
            label="Vendor name"
            name="name"
            ref={register({ required: true })}
            onInputChange={(e) => {
              setVendorName(e.currentTarget.value);
            }}
            isError={formErrors['name'] ? true : false}
            isMandatory={true}
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Email Address"
            name="email"
            isError={formErrors?.['email']?.['message'] ? true : false}
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
            inputType="email"
            isMandatory={true}
            onInputBlur={async (e) =>
              await dispatch(
                updateVendorIndividualForm(e.target.value, 'email')
              )
            }
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Personal Phone number"
            name="cellPhone"
            ref={register}
            onInputBlur={async (e) =>
              await dispatch(
                updateVendorIndividualForm(e.target.value, 'cellPhone')
              )
            }
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Business Phone number"
            name="businessPhone"
            ref={register}
            onInputBlur={async (e) =>
              await dispatch(
                updateVendorIndividualForm(e.target.value, 'businessPhone')
              )
            }
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Home Address"
            name="address"
            ref={register}
            onInputBlur={async (e) =>
              await dispatch(
                updateVendorIndividualForm(e.target.value, 'address')
              )
            }
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Birthday"
            ref={register({ name: 'birthday' })}
            onDateChange={(date) => setValue('birthday', date)}
            showDatepicker={true}
            placeholder="Select Date"
            selectedDate={
              vendorData?.['birthday']
                ? new Date(vendorData?.['birthday'])
                : new Date()
            }
            labelClass=""
          />
        </div>
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="fullName"
            valueKey="id"
            isLabelShow={true}
            isMandatory={true}
            isError={formErrors['timezone'] ? true : false}
            label="Current timezone"
            options={timezones}
            onMenuOpen={async () => {
              setZoneLoading(true);
              if (timezones.length === 0) {
                await dispatch(getTimezone());
              }
              setZoneLoading(false);
            }}
            loading={zoneLoading}
            ref={register({ name: 'timezone' }, { required: true })}
            onOptionChange={async (e) => {
              const timezone = {
                id: e['id'],
                shortName: e['shortName'],
              };
              setValue('timezone', timezone);
            }}
            placeholder="Select"
            value={
              vendorData && {
                fullName: vendorData?.['timezone']?.['fullName'],
                id: vendorData?.['timezone']?.['id'],
              }
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
