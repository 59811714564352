import * as buildingTypes from './building.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as toastTypes from '../toast/toast.constant';
import * as formTypes from '../form/form.constant';
import { ALL_RECORDS, TOAST_MESSAGES } from '../../constants/index';
import { addSpacesApi } from '../space/spaces.service';
import { addBuildingBlocksApi } from '../buildingblocks/buildingblocks.service';
import {
  Building,
  DeleteResponse,
  PaginatedResponse,
  Whitelabel,
} from '../../models';
import { BuildingBlock } from '../../models/buildingblock';
import {
  addWhitelabelApi,
  // updateWhitelabelApi,
} from '../whitelabel/whitelabel.service';
import {
  getBuildingsApi,
  getBuildingsByManagementApi,
  addBuildingsApi,
  getBuildingDetailApi,
  updateBuildingApi,
  deleteBuildingApi,
  checkBuildingCodeApi,
} from './building.service';
export const getBuildings = (
  offset?,
  page?,
  cmp?,
  query?,
  all?,
  updateReducer = true,
  searchBuilding?
) => async (dispatch) => {
  const buildingResponse: PaginatedResponse<Building> = await getBuildingsApi(
    cmp,
    offset,
    page,
    query,
    all,
    searchBuilding
  );
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (buildingResponse.data && updateReducer) {
    dispatch({
      type:
        all === 1
          ? buildingTypes.GET_ALL_BUILDINGS
          : buildingTypes.GET_BUILDINGS,
      payload: buildingResponse.data,
      buildingCount: buildingResponse.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else if (!updateReducer && buildingResponse.data) {
    return buildingResponse.data;
  }
};

export const getBuildingsByManagement = (
  filterId?: number,
  all: number = ALL_RECORDS
) => async (dispatch) => {
  const buildingsData: PaginatedResponse<Building> = await getBuildingsByManagementApi(
    filterId,
    all
  );
  if (buildingsData.data) {
    dispatch({
      type: buildingTypes.GET_ALL_BUILDINGS_MANAGEMENT,
      payload: buildingsData.data,
    });
  }
};

export const searchBuildings = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: buildingTypes.SEARCH_BUILDINGS_DATA,
      payload: data,
      buildingCount: data.length,
      page,
    });
  } else {
    await getBuildings(10, page, 'short', query)(dispatch);
  }
};

export const addBuilding = (
  building: Building,
  spaces?,
  blocks?,
  whitelabel?
) => async (dispatch) => {
  const checkBuildingCode = await checkBuildingCodeApi(building?.buildingCode);
  if (!checkBuildingCode.data.exists) {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const newBuildingData = await addBuildingsApi(building);
    if (newBuildingData) {
      if (spaces.length > 0) {
        await addSpacesApi({
          building: { id: newBuildingData?.['id'] },
          spaces,
        });
      }
      if (blocks?.length > 0) {
        await addBuildingBlocksApi(newBuildingData?.['id'], blocks);
      }
      if (whitelabel) {
        await addWhitelabelApi(newBuildingData?.['id'], whitelabel);
      }
      dispatch({
        type: toastTypes.SUCCESS_TOAST,
        toastMessage: TOAST_MESSAGES.building.added,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  } else {
    dispatch({
      type: toastTypes.ERROR_TOAST,
      toastMessage: TOAST_MESSAGES.building.buildingCode,
    });
  }
};

export const getBuildingDetail = (buildingId: number) => async (dispatch) => {
  const building: Building = await getBuildingDetailApi(buildingId);
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (building) {
    dispatch({
      type: formTypes.UPDATE_BUILDING_FORM,
      payload: building,
    });
    dispatch({
      type: buildingTypes.FETCHED_BUILDING_DETAIL,
      payload: building ? true : false,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const setFetchBuildingState = () => async (dispatch) => {
  dispatch({
    type: buildingTypes.FETCHED_BUILDING_DETAIL,
    payload: true,
  });
};

export const updateBuilding = (
  buildingId: number,
  data: Building,
  blocks?: BuildingBlock[],
  whitelabel?: Whitelabel
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedBuilding = await updateBuildingApi(buildingId, data);
  if (updatedBuilding) {
    // Will use this in future
    // if (spaces.length > 0) {
    //   const updatedSpaces = spaces.filter((item) => !item.hasOwnProperty('id'));
    //   await addSpacesApi({
    //     building: { id: buildingId },
    //     spaces: updatedSpaces,
    //   });
    // }
    if (blocks.length > 0) {
      await addBuildingBlocksApi(buildingId, { blocks });
    }
    console.log(whitelabel, 'whitelabel');
    // if (whitelabel) {
    //   if (whitelabel) {
    //     await updateWhitelabelApi(
    //       updatedBuldingData?.['data']?.['id'],
    //       whitelabel
    //     );
    //   }
    // }
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.building.updated,
    });
    // dispatch({
    //   type: formTypes.EMPTY_BUILDING_FORM,
    //   payload: {},
    // });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteBuilding = (buildingId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const deleteResponse: DeleteResponse = await deleteBuildingApi(buildingId);
  if (deleteResponse.delete) {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const checkBuildingCodeStatus = (buildingCode: string) => async (
  dispatch
) => {
  const CheckBuildingCodeStatus = await checkBuildingCodeApi(buildingCode);
  if (CheckBuildingCodeStatus.data) {
    dispatch({
      type: buildingTypes.CHECK_BUILDING_CODE,
      payload: CheckBuildingCodeStatus.data,
    });
  }
};
export const clearBuildingStatusCheck = () => async (dispatch) => {
  const data = {
    exists: false,
  };
  dispatch({
    type: buildingTypes.CHECK_BUILDING_CODE,
    payload: data,
  });
};

export const saveBuildingId = (data: number) => async (dispatch) => {
  dispatch({
    type: buildingTypes.SAVE_BUILDING_ID,
    payload: data,
  });
};
