import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadState } from '../../../utils';
import BasicDetails from './BasicDetails';
import '../Services.scss';
import ServiceDetail from './ServiceDetail';
import ServiceDescription from './Description';
import DeleteElementPopup from '../../Common/DeleteElementPopup';
import Footer from '../../Common/Footer';
import {
  setupService,
  updateServiceSetup,
  deleteSetup,
  getServiceTaskList,
} from '../../../redux/service/services.action';
import { imageUpload } from '../../../redux/common/common.action';
import { useFetch, usePageRefresh } from '../../../hooks';
import { getInventoryGroups } from '../../../redux/inventory/inventory.action';
import { clearServiceVendorForm } from '../../../redux/form/form.action';
import { IFormState } from '../../../redux/form';
import { IInventoryState } from '../../../redux/inventory';
import { ILoaderState } from '../../../redux/loader';
import { Service } from '../../../models';
import {
  defaultCancellationPolicy,
  defaultMaxAttendees,
} from '../../../constants';
import ProductionTeam from './ProductionTeam';

const AddElementSetupForm: React.FC<{
  onChangeServiceName?: any;
}> = ({ onChangeServiceName }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const serviceId = parseInt(params['serviceId'], 10);
  const [trunkRequirement, setTrunkRequirement] = useState([]);

  const { serviceSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [isEdit] = useState(serviceId ? true : false);
  const [serviceSetupData, refreshServiceSetup] = useState(
    isEdit ? loadState('serviceSetupForm') : serviceSetupForm
  );

  const query = new URLSearchParams(search);
  const { packageElementForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const elementsAssociated = packageElementForm?.['packageElements'].map(
    (data: any) => ({
      id: data.id,
    })
  );
  const elementIds =
    elementsAssociated?.length > 0
      ? elementsAssociated.map(({ id }) => id)
      : [];

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  // Decrypt the value of popup fields
  const [serviceType] = useState(query.get('serviceType'));
  const [servicePopupData] = useState(
    query.get('service') ? JSON.parse(atob(query.get('service'))) : null
  );

  const [fields, setFieldArray] = useState([]);
  const [fieldChange, setFieldChanges] = useState<boolean>(false);
  const [fieldDelete, setFieldDelete] = useState<boolean>(false);

  const [showSetupDelete, setSetupDelete] = useState({
    showModal: false,
    isDelete: false,
  });

  // ******************** Task list ********************/
  useEffect(() => {
    async function getEventTaskList() {
      await dispatch(clearServiceVendorForm());
    }
    getEventTaskList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Bind the trunk requirements dropdown
  // ****************** Inventory Tab *******************/
  useFetch(getInventoryGroups, ['', 1]);
  const { inventoryGroups } = useSelector(
    (state: { inventory: IInventoryState }) => state.inventory
  );
  useEffect(() => {
    if (inventoryGroups?.length > 0) {
      const updatedTrunk = inventoryGroups.filter((data: any) => {
        return data.trunk;
      });
      setTrunkRequirement(() => [
        { name: 'No Trunk Required', id: null },
        ...updatedTrunk,
      ]);
    }
  }, [inventoryGroups]);

  // ****************** Production Team start *******************/
  useEffect(() => {
    setFieldArray(() => serviceSetupForm?.['productionTeams']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Used in Production team vendorType changed and reset the value of categoryTag
  useEffect(() => {
    if (fieldChange && fields?.length > 0) {
      fields?.forEach((item, index) => {
        fields[index]['categoryTag'] = item?.['categoryTag'];
      });
      setFieldChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldChange]);

  // Used in Production team delete
  useEffect(() => {
    if (fieldDelete && fields?.length > 0) {
      fields?.forEach((item, index) => {
        fields[index]['vendorType'] = item?.['vendorType'];
        fields[index]['categoryTag'] = item?.['categoryTag'];
      });
      setFieldDelete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldDelete]);

  // ****************** Production Team End *******************/

  const {
    name,
    cancellationPolicy,
    maxAttendees,
    ...otherFieldsWithoutName
  } = serviceSetupData;
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    reset,
    unregister,
  } = useForm<Service>({
    defaultValues: {
      name: servicePopupData
        ? servicePopupData?.['name']
        : serviceSetupData?.['name'],
      cancellationPolicy: cancellationPolicy
        ? cancellationPolicy
        : defaultCancellationPolicy,
      maxAttendees: maxAttendees ?? defaultMaxAttendees,
      productionTeams: fields,
      ...otherFieldsWithoutName,
    },
  });
  usePageRefresh(true, serviceId ? false : true);

  const onAddProductionTeam = async (
    isToggle: boolean,
    addNewItem: boolean
  ) => {
    if (isToggle) {
      if (addNewItem) {
        fields.push({
          vendorType: { id: null },
          categoryTag: { id: null },
        });
        setFieldArray(() => [...fields]);
      } else {
        setFieldArray(() => serviceSetupForm?.['productionTeams']);
      }
    } else {
      setFieldArray([]);
    }
  };

  const onDeleteProdTeam = async (index: number) => {
    const deletedProdTeam = fields;
    deletedProdTeam.splice(index, 1);
    setFieldArray(() => [...deletedProdTeam]);
  };

  const getServiceCategory = () => {
    return servicePopupData
      ? servicePopupData?.['elementCategory']
      : serviceSetupData?.['elementCategory'];
  };

  const saveDataValues = (data: Service) => {
    data.elementCategory =
      Object.keys(data.elementCategory).length > 0
        ? data.elementCategory
        : getServiceCategory();
    data.virtualMode = servicePopupData?.['virtualMode'] ?? data.virtualMode;
    data.duration = data['duration'] = Number(data['duration']);
    data.maxAttendees = data['maxAttendees'] = Number(data['maxAttendees']);
    data.inventoryByHom = data['inventoryByHom']?.['id']
      ? data['inventoryByHom']
      : { id: null };
    data.elementsAssociated = serviceType === '1' ? [] : elementsAssociated;
    data.eventLeadRequired = false;
    if (serviceSetupForm['productionTeams'].length > 0) {
      data.productionTeams =
        !data.productionTeamRequired && !isEdit
          ? []
          : serviceSetupForm['productionTeams'];
    } else {
      data.productionTeamRequired = false;
      data.productionTeams =
        !data.productionTeamRequired && !isEdit
          ? []
          : serviceSetupForm['productionTeams'];
    }
  };

  const redirectToPage = async (data) => {
    let latestServiceId;
    if (!serviceId) {
      // Add case
      data.isPackage = serviceType === '1' ? false : true;
      latestServiceId = await dispatch(setupService(data, data.isPackage));

      if (latestServiceId) {
        if (elementIds?.length > 0) {
          await dispatch(getServiceTaskList(latestServiceId));
        }
        push(
          `/data-resources/services/add-service?tab=task-list&serviceType=${serviceType}&id=${latestServiceId}`
        );
      }
    } else {
      // update case
      data.isPackage = serviceSetupData?.['isPackage'];
      await dispatch(updateServiceSetup(serviceId, data));
      await dispatch(getServiceTaskList(Number(serviceId)));
      push(
        `/data-resources/services/edit-service/${serviceId}/?tab=task-list&isEdit=${isEdit}&serviceType=${serviceType}`
      );
    }
  };
  const saveUploadedFiles = async (data) => {
    if (!serviceSetupData?.['eventImage']['id']) {
      const eventImage = new FormData();
      eventImage.append('file', data['eventImage']['id'][0]);
      data['eventImage'] = data['eventImage']['id'][0]
        ? await dispatch(imageUpload(eventImage))
        : { id: null, file: null };
    } else {
      data.eventImage = serviceSetupData?.['eventImage'];
    }

    if (!serviceSetupData?.['cosImage']['id']) {
      const cosImage = new FormData();
      cosImage.append('file', data['cosImage']['id'][0]);
      data['cosImage'] = data['cosImage']['id'][0]
        ? await dispatch(imageUpload(cosImage))
        : { id: null, file: null };
    } else {
      data.cosImage = serviceSetupData?.['cosImage'];
    }

    if (!serviceSetupData?.['eventPoster']['id']) {
      const eventPoster = new FormData();
      eventPoster.append('file', data['eventPoster']['id'][0]);
      data['eventPoster'] = data['eventPoster']['id'][0]
        ? await dispatch(imageUpload(eventPoster))
        : { id: null, file: null };
    } else {
      data.eventPoster = serviceSetupData?.['eventPoster'];
    }
  };
  const onSubmit = async (data: Service) => {
    await saveDataValues(data);
    await saveUploadedFiles(data);
    await redirectToPage(data);
  };

  const onDelete = async () => {
    await dispatch(deleteSetup(serviceId));
  };

  return (
    <>
      {showSetupDelete.showModal && (
        <DeleteElementPopup
          isDelete={showSetupDelete.isDelete}
          modalState={showSetupDelete.showModal}
          modalClose={() =>
            setSetupDelete({ showModal: false, isDelete: false })
          }
          onDelete={onDelete}
          modalClass="delete_service"
          headerText="Are you sure you want to delete this Service?"
          subHeader="Services are deactivated for 30 days before permanent deletion"
          buttonLabel="Delete Service"
          redirectValue="/data-resources/services"
        />
      )}
      <div className={`mx-10 addsetup_section ${!isEdit && 'pb-16'}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <BasicDetails
            formErrors={errors}
            register={register}
            setValue={setValue}
            serviceData={servicePopupData ?? serviceSetupData}
            isEdit={isEdit}
            onChangeServiceName={onChangeServiceName}
            isPackage={serviceType === '1' ? false : true}
          />
          <ServiceDetail
            register={register}
            setValue={setValue}
            serviceData={serviceSetupData}
            serviceId={serviceId}
            trunkRequirement={trunkRequirement}
            inventoryByHom={inventoryGroups}
            formErrors={errors}
            isEdit={isEdit}
          />
          <ProductionTeam
            formErrors={errors}
            register={register}
            setValue={setValue}
            onAddProductionTeam={onAddProductionTeam}
            fields={fields}
            unregister={unregister}
            setFieldChanges={setFieldChanges}
            fieldChange={fieldChange}
            isEdit={isEdit}
            onDeleteProdTeam={onDeleteProdTeam}
            setFieldDelete={setFieldDelete}
            fieldDelete={fieldDelete}
          />
          <ServiceDescription
            register={register}
            serviceData={serviceSetupData}
            refreshServiceSetup={refreshServiceSetup}
            reset={reset}
          />
          <Footer
            buttonText="Continue"
            isArrow={true}
            disabled={loading}
            buttonClass={`${loading ? 'button_disable' : ''}`}
            showLoader={loading}
          />
        </form>
        {isEdit && (
          <div className="delete_building_text">
            <label
              onClick={() => {
                setSetupDelete({ showModal: true, isDelete: true });
              }}
            >
              Delete Service
            </label>
            <span>
              Services are deactivated for 30 days before permanent deletion
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default AddElementSetupForm;
