import { Pagination, User } from '../../models';
import { Map } from 'immutable';

export interface IUserState extends Pagination {
  users: User[];
  usersCount: number;
  usersData: object;
  selectedBuilding: object;
  allUsers: object;
}

export const initialUserState: IUserState = {
  users: [],
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  usersCount: 0,
  usersData: null,
  selectedBuilding: null,
  allUsers: [],
};
