import { http } from '../common/http.service';
import {
  RECORDS_LIMIT,
  RECORDS_CMP,
  RECORDS_PAGE,
  RECORDS_QUERY,
  ALL_RECORDS,
  USER_BASE_URL,
  USER_DETAILS_URL,
} from '../../constants/index';
import { User, PaginatedResponse, DeleteResponse } from '../../models';
import { AxiosResponse } from 'axios';

export const getBuildingUserApi = async (
  buildingId: number,
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS
): Promise<PaginatedResponse<User>> =>
  http
    .get<PaginatedResponse<User>>(
      `${USER_BASE_URL}/${buildingId}?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<User>>) => {
      return response.data;
    });

export const addUserApi = async (data: User): Promise<User> =>
  http
    .post<User>(`${USER_BASE_URL}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<User>) => {
      return response.data;
    });

export const getUserDetailApi = async (userId: number): Promise<User> =>
  http
    .get<User>(`${USER_DETAILS_URL}/${userId}`, { errorHandler: true })
    .then((response: AxiosResponse<User>) => {
      return response.data;
    });

export const updateUserApi = async (
  userId: number,
  data: object
): Promise<User> =>
  http
    .put(`${USER_DETAILS_URL}/${userId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<User>) => {
      return response.data;
    });

export const deleteUserApi = async (userId: number): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`${USER_DETAILS_URL}/${userId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });
