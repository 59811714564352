import React from 'react';
import './index.scss';
import { getImageUrl } from '../../../utils';

const SlideDrawer: React.FC<{
  show: boolean;
  slideClass: string;
  showCloseIcon?: boolean;
  closePane?: any;
  children: JSX.Element;
}> = ({ show, children, slideClass, showCloseIcon, closePane }) => {
  // const [drawerOpen, setDrawerOpen] = useState(show);
  let drawerClasses = 'side-drawer';
  if (show) {
    drawerClasses = `${slideClass}`;
  }
  const closeDrawer = () => {
    closePane();
  };
  return (
    <div className={drawerClasses}>
      {showCloseIcon && (
        <img
          className="float-right cursor-pointer"
          src={getImageUrl('Events/closeeventpopup.svg')}
          alt="close"
          onClick={closeDrawer}
        />
      )}
      {children}
    </div>
  );
};

export default SlideDrawer;
