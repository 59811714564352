import React, { useEffect, useState } from 'react';
import { Button, Dropdown, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import ModalPopup from '../../ReactModal/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  IEventsState,
  getEventTask,
  assignVendorToImportedTasksForEvents,
  clearEventImportedTaskState,
  geteventsElementslist,
} from '../../../redux/event';
import { clearServiceTaskListForm, IFormState } from '../../../redux/form';
import { useHistory } from 'react-router-dom';

const ImportTaskTemplatePopup: React.FC<any> = ({
  modalState,
  modalClose,
  eventId,
}: any) => {
  const { register, handleSubmit, setValue, errors } = useForm();
  const { push, location } = useHistory();
  const { importedEventTask, eventTasks } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const { eventSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [elementName] = useState(eventSetupForm?.name);
  const dispatch = useDispatch();

  const { eventElementsList } = useSelector(
    (state: { events: IEventsState }) => state.events
  );

  const onSubmit = async (data) => {
    const params = {
      groups: importedEventTask,
      element: data?.['element'],
    };
    await dispatch(
      assignVendorToImportedTasksForEvents(params, Number(eventId))
    );
    modalClose();
    await dispatch(clearServiceTaskListForm());
    await dispatch(clearEventImportedTaskState());
    await dispatch(getEventTask(Number(eventId), false));
    push(`${location.pathname}${location.search}`, {
      taskImported: false,
      eventId: null,
    });
  };

  useEffect(() => {
    if (eventTasks?.elements?.length) {
      let packageArray = [];
      if (eventSetupForm?.['elements']?.length > 0) {
        eventSetupForm?.['elements']?.forEach((item) => {
          packageArray.push({
            id: item.id,
            name: item.elementName,
          });
        });
      } else {
        packageArray = [...eventTasks.elements];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [elementList, setElementList] = useState([]);
  useEffect(() => {
    if (eventSetupForm?.['elements']?.length > 0) {
      setElementList([]);
      eventSetupForm?.['elements']?.forEach((item) => {
        elementList.push({
          id: item.id,
          name: item.elementName,
        });
      });
    } else {
      elementList.push({ id: 1, name: elementName });
    }
    setElementList([...elementList]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSetupForm?.['elements']]);

  const onModalClose = () => {
    modalClose();
    dispatch(clearEventImportedTaskState());
    push(`${location.pathname}${location.search}`, {
      taskImported: false,
      eventId: null,
    });
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onModalClose}
      className="delete-modal"
    >
      <h1 className="group-header">Assign Task Template</h1>
      <hr className="header-hr" />
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <PrimaryLabel labelText="Select Vendor" />
          <Dropdown
            options={eventElementsList}
            label="Element"
            labelKey="name"
            isMulti={true}
            valueKey="id"
            ref={() => register({ name: 'element' }, { required: true })}
            placeholder="Select vendor"
            onMenuOpen={async () => {
              if (eventElementsList?.length <= 0) {
                await dispatch(geteventsElementslist(eventId, false, null));
              }
            }}
            onOptionChange={(e) => {
              setValue('element', e);
              // setElementId(e);
            }}
            isError={errors?.['element'] ? true : false}
          />
        </div>
        <Button
          label="Assign Tasks"
          className="block mt-4"
          backgroundColor="#e89a89"
        />
      </form>
    </ModalPopup>
  );
};

export default ImportTaskTemplatePopup;
