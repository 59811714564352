import React, { useState, useEffect } from 'react';
import { Input, Button } from '@alfredclub/hom-planner-theme';
import CommonLayout from '../../CommonLayout/CommonLayout';
import AddSpaceInfo from './SpaceFields';
import FormTitle from '../Building/AddBuilding/FormTitle';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSpace,
  deleteSpace,
  getSpaceDetail,
  updateSapce,
} from '../../../redux/space/space.action';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DeleteElementPopup from '../../Common/DeleteElementPopup';
import { imageUpload } from '../../../redux/common/common.action';
// import { loadState } from '../../../utils';
import CommonNavigation from '../../Common/CommonNavigation';

import {
  clearSpaceForm,
  updateSpaceForminBuilding,
} from '../../../redux/form/form.action';
import './Spaces.scss';
import { IFormState } from '../../../redux/form/form.state';
import { ILoaderState } from '../../../redux/loader/loader.state';
import { SpaceArray } from '../../../models';

const AddSpace = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const params = useParams();
  const query = new URLSearchParams(search);

  // Decrypt popup value
  const [buildingData] = useState(
    query.get('building') ? JSON.parse(atob(query.get('building'))) : null
  );
  const [buildingName] = useState(query.get('buildingName'));
  const [buildingParamId] = useState(query.get('buildingId'));
  const [spaceId] = useState(params?.['spaceId'] ?? null);
  const dispatch = useDispatch();
  const { spaceForm: spaceData } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  // const [spaceData, refreshSpaceForm] = useState(
  //   spaceId ? loadState('spaceForm') : spaceForm
  // );
  const {
    register,
    handleSubmit,
    control,
    reset,
    errors,
  } = useForm<SpaceArray>({
    defaultValues: {
      spaces: [
        {
          name: buildingData?.['spaceName'] ?? '',
          location: '',
          maxCapacity: null,
          goalCapacity: null,
          securityAccess: '',
          videoUrl: '',
          water: '',
          bathroomAccess: '',
          garbageArea: '',
          trunk: '',
          outlets: '',
          wifi: '',
          floorPlan: { id: null },
        },
      ],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'spaces',
  });

  const [showSpaceDelete, setShowSpaceDelete] = useState({
    showModal: false,
    isDelete: false,
  });
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  useEffect(() => {
    if (spaceData) {
      reset({
        buildingName: spaceData?.['building']['name'],
        spaces: [spaceData],
      });
    }
  }, [spaceData, reset]);

  useEffect(() => {
    async function getSpaceInfo() {
      if (spaceId) {
        await dispatch(getSpaceDetail(spaceId));
      }
    }
    getSpaceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  const onSubmit = async (data: SpaceArray) => {
    if (buildingName) {
      data['spaces'][0]['maxCapacity'] = Number(
        data['spaces'][0]['maxCapacity']
      );
      data['spaces'][0]['goalCapacity'] = Number(
        data['spaces'][0]['goalCapacity']
      );

      if (!spaceData?.['floorPlan']?.['id']) {
        const formData = new FormData();
        if (data['spaces'][0]['floorPlan']['id'][0]) {
          formData.append('file', data['spaces'][0]['floorPlan']['id'][0]);
          data['spaces'][0]['floorPlan'] = data['spaces'][0]['floorPlan']['id']
            ? await dispatch(imageUpload(formData))
            : { id: null, file: null };
        } else {
          data['spaces'][0]['floorPlan'] = { id: null, file: null };
        }
      } else {
        data['spaces'][0]['floorPlan'] = spaceData?.['floorPlan'];
      }
      if (buildingParamId) {
        data.building = {
          id: Number(buildingParamId),
        };
      }
      if (buildingParamId) {
        const addSpaceResponse = await dispatch(addSpace(data));
        if (addSpaceResponse?.spaces?.length) {
          data['spaces'][0]['id'] = addSpaceResponse?.spaces[0]?.id;
        }
      }
      await dispatch(updateSpaceForminBuilding(data));
      await dispatch(clearSpaceForm());
      push(
        buildingParamId
          ? `/data-resources/clients/buildings/${buildingParamId}`
          : `/data-resources/clients/buildings/add-building?buildingName=${buildingName}`
      );
      return;
    }
    data.building = {
      id: buildingData
        ? buildingData?.['building']?.['id']
        : spaceData?.['building']['id'],
    };
    data['spaces'][0]['maxCapacity'] = Number(data['spaces'][0]['maxCapacity']);
    data['spaces'][0]['goalCapacity'] = Number(
      data['spaces'][0]['goalCapacity']
    );

    if (!spaceData?.['floorPlan']?.['id']) {
      const formData = new FormData();
      if (data['spaces'][0]['floorPlan']['id'][0]) {
        formData.append('file', data['spaces'][0]['floorPlan']['id'][0]);
        data['spaces'][0]['floorPlan'] = data['spaces'][0]['floorPlan']['id']
          ? await dispatch(imageUpload(formData))
          : { id: null, file: null };
      } else {
        data['spaces'][0]['floorPlan'] = { id: null, file: null };
      }
    } else {
      data['spaces'][0]['floorPlan'] = spaceData?.['floorPlan'];
    }
    if (!spaceId) {
      await dispatch(addSpace(data));
    } else {
      await dispatch(
        updateSapce(spaceId, {
          building: data?.['building'],
          ...data?.['spaces'][0],
        })
      );
    }
    await dispatch(clearSpaceForm());
    push(`/data-resources/clients/spaces`);
  };

  const onDelete = async () => {
    await dispatch(deleteSpace(spaceId));
  };

  return (
    <section>
      <CommonLayout
        headerTitle={`${spaceId ? 'Edit' : 'Add'} Space`}
        showIcon={false}
        showSearchBar={false}
        showTable={false}
      />
      {showSpaceDelete.showModal && (
        <DeleteElementPopup
          isDelete={showSpaceDelete.isDelete}
          modalState={showSpaceDelete.showModal}
          modalClose={() =>
            setShowSpaceDelete({ showModal: false, isDelete: false })
          }
          onDelete={onDelete}
          headerText="Are you sure you want to delete this Space?"
          subHeader="Spaces are deactivated for 30 days before permanent deletion"
          buttonLabel="Delete Space"
          redirectValue="/data-resources/clients/spaces"
          modalClass="space_modal"
        />
      )}

      <div className="add-space px-10">
        {buildingName && (
          <CommonNavigation
            linkTo={
              buildingParamId
                ? `/data-resources/clients/buildings/${buildingParamId}`
                : `/data-resources/clients/buildings/add-building?buildingName=${buildingName}`
            }
            header={`${buildingParamId ? 'Edit' : 'Add new'} building`}
            subHeader="Add new space"
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Button
            label={`${buildingName ? 'Save and go back' : 'Save changes'}`}
            className={`${loading ? 'button_disable' : ''}`}
            disabled={loading}
          />
          <div className="form_title">
            <FormTitle title="Basic Details" />
          </div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8 w-full">
            <Input
              isLabelShow={true}
              label="Building name"
              name="buildingName"
              value={buildingName ?? buildingData?.['building']?.['name']}
              ref={register}
              disabled={true}
            />
          </div>
          <FormTitle title="Space details" />

          <AddSpaceInfo
            fields={fields}
            register={register}
            spaceId={spaceId}
            error={errors}
            // refreshSpaceForm={refreshSpaceForm}
            reset={reset}
            spaceData={spaceData}
            spaceName={buildingData?.['spaceName']}
          />
        </form>
        {spaceId && (
          <div className="delete_space">
            <label
              className="w-full"
              onClick={() => {
                setShowSpaceDelete({ showModal: true, isDelete: true });
              }}
            >
              Delete Space
            </label>
            <span>
              Spaces are deactivated for 30 days before permanent deletion
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default AddSpace;
