import {
  RECORDS_QUERY,
  RECORDS_CMP,
  RECORDS_LIMIT,
  RECORDS_PAGE,
  ALL_RECORDS,
} from '../../constants/index';
import { INVENTORY_BASE_URL } from '../../constants/index';
import { http } from '../common/http.service';
import { DeleteResponse, Inventory, PaginatedResponse } from '../../models';
import { AxiosResponse } from 'axios';
import { Item } from '../../models/item';

export const getInventoryGroupsApi = async (
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS,
  id: number = 0
): Promise<PaginatedResponse<Inventory>> =>
  http
    .get<PaginatedResponse<Inventory>>(
      `${INVENTORY_BASE_URL}/groups?query=${query}&all=${all}${
        id ? `&entity_id=${id}` : ``
      }`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Inventory>>) => {
      return response.data;
    });

export const getInventoryItemsApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS,
  id: number = 0
): Promise<PaginatedResponse<Item>> =>
  http
    .get<PaginatedResponse<Item>>(
      `${INVENTORY_BASE_URL}/items?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all=${all}${
        id ? `&entity_id=${id}` : ``
      }`,
      { errorHandler: true }
    )
    .then((response: AxiosResponse<PaginatedResponse<Item>>) => {
      return response.data;
    });

export const createInventoryGroupsApi = async (
  data: object
): Promise<PaginatedResponse<Inventory>> =>
  http
    .post<PaginatedResponse<Inventory>>(`${INVENTORY_BASE_URL}/groups`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<PaginatedResponse<Inventory>>) => {
      return response.data;
    });

export const createInventoryItemsApi = async (
  data
): Promise<PaginatedResponse<Item>> =>
  http
    .post<PaginatedResponse<Item>>(`${INVENTORY_BASE_URL}/items`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<PaginatedResponse<Item>>) => {
      return response.data;
    });

export const addItemsToInventoryGroupsApi = async (
  groupId: number,
  data
): Promise<PaginatedResponse<Item>> =>
  http
    .put<PaginatedResponse<Item>>(
      `${INVENTORY_BASE_URL}/group/add-items/${groupId}`,
      data,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Item>>) => {
      return response.data;
    });

export const removeItemsToInventoryGroupsApi = async (
  groupId: number,
  data
): Promise<{ itemId: number }> =>
  http
    .put<{ itemId: number }>(
      `${INVENTORY_BASE_URL}/group/remove-item/${groupId}`,
      data,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<{ itemId: number }>) => {
      return response.data;
    });

export const deleteInventoryGroupsApi = async (
  groupId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`${INVENTORY_BASE_URL}/groups/${groupId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });

export const deleteInventoryItemsApi = async (
  itemId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`${INVENTORY_BASE_URL}/items/${itemId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });

export const updateInventoryItemApi = async (
  itemId: number,
  data
): Promise<Item> =>
  http
    .put<Item>(`${INVENTORY_BASE_URL}/group/update-item/${itemId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Item>) => {
      return response.data;
    });

export const updateInventoryGroupApi = async (
  groupId,
  data
): Promise<Inventory> =>
  http
    .put<Inventory>(
      `${INVENTORY_BASE_URL}/group/update-group/${groupId}`,
      data,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<Inventory>) => {
      return response.data;
    });
