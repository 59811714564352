import React, { useState } from 'react';
import { Input, ToggleButton } from '@alfredclub/hom-planner-theme';
import FormTitle from './FormTitle';
import { Register } from '../../../../models';
import BuildingWhitelabellingPopup from '../../../BuildingWhitelabellingPopup/BuildingWhitelabellingPopup';
import { ButtonType } from '../../../../enums';
import { useDispatch, useSelector } from 'react-redux';
import { Building } from '../../../../models/building';
import { getWhitelabel } from '../../../../redux/whitelabel';
import { loadState } from '../../../../utils';
import { IBuildingState } from '../../../../redux/building/Building.state';
import { checkBuildingCodeStatus } from '../../../../redux/building/buildings.action';
import { useDebounce } from '../../../../hooks';

const WebsiteDetails: React.FC<{
  buildingId: number;
  buildingData: Building;
  register: Register;
  isEdit: boolean;
  reset: (
    values?: Record<string, any>,
    omitResetState?: Record<string, boolean>
  ) => void;
  refreshBuildingForm: (buildiingForm: Building) => void;
  formErrors: Record<string, object>;
}> = ({
  register,
  buildingId,
  buildingData,
  isEdit,
  reset,
  refreshBuildingForm,
  formErrors,
}) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(buildingData.whitelabelActive);
  const [passCodeEnabled, setPassCodeEnabled] = useState(
    buildingData?.['passCodeEnabled']
  );
  const { debounce } = useDebounce(1000);

  const { buildingCodeStatus } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const urlExist = () => {
    return buildingCodeStatus || formErrors['buildingCode'] ? true : false;
  };
  const urlExistStatus = async (value: string) => {
    await dispatch(checkBuildingCodeStatus(value));
  };
  const [showWhitelabelModal, setShowWhitelabelModal] = useState(false);
  return (
    <>
      <FormTitle
        title="Website Details"
        subTitle={`${buildingCodeStatus ? ' hOM url already exists' : ''}`}
        subheaderClass="url-error-message pl-16"
      />
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          <Input
            inputType="text"
            isLabelShow={true}
            label="HOM URL"
            name="buildingCode"
            isRightLabel={true}
            rightLabel="hom.events"
            className={isEdit && 'disable-input'}
            labelClass="website_input_label"
            ref={register({ required: true })}
            isError={urlExist()}
            isMandatory={true}
            onInputChange={(e) => {
              const name = e.target.value;
              if (name.length > 0) {
                debounce(urlExistStatus, String(name).toLowerCase());
              }
            }}
          />
        </div>
        {isEdit && (
          <div>
            <ToggleButton
              label="White Labeling"
              onChange={(e) => {
                setChecked(e.currentTarget.checked);
              }}
              isLabelShow={checked}
              secondaryLabel="Customize"
              checked={checked}
              checkedText="Yes"
              name="whitelabelActive"
              ref={register}
              type={ButtonType.BUTTON}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (buildingId) {
                  await dispatch(getWhitelabel(buildingId));
                }
                setShowWhitelabelModal(true);
              }}
            />
            {showWhitelabelModal && (
              <BuildingWhitelabellingPopup
                modalState={showWhitelabelModal}
                toggleModal={() => setShowWhitelabelModal(!showWhitelabelModal)}
                buildingWhiteLabelData={
                  loadState('buildingForm')?.buildingWhiteLabel
                }
                buildingId={buildingId}
                refreshBuildingForm={refreshBuildingForm}
                reset={reset}
              />
            )}
          </div>
        )}
        <div>
          <ToggleButton
            label="Building Passcode"
            onChange={(e) => setPassCodeEnabled(e.currentTarget.checked)}
            isLabelShow={passCodeEnabled}
            checked={passCodeEnabled}
            uncheckedText="Disabled"
            checkedText="Enabled"
            name="passCodeEnabled"
            ref={register}
          />
        </div>
        {passCodeEnabled && (
          <div>
            <Input
              inputType="text"
              isLabelShow={true}
              label="Enter Building Passcode"
              name="passCode"
              ref={register}
            />
          </div>
        )}
        <div>
          <Input
            isLabelShow={true}
            label="AOS Building ID"
            name="alfredOsId"
            ref={register({ required: false })}
            isMandatory={false}
          />
        </div>
      </div>
    </>
  );
};

export default WebsiteDetails;
