import React from 'react';
import ModalPopup from '../../../ReactModal/Modal';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import './CompanyPopup.scss';

const DeleteUnitPopup: React.FC<any> = ({
  modalState,
  modalClose,
  deleteblock,
  dataName,
}: any) => {
  const onSubmit = async () => {
    deleteblock(dataName?.name);
  };
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={modalClose}
      className="delete_company_modal"
    >
      <PrimaryLabel
        className="sub-header header_style"
        labelText="Are you sure you want to delete this Unit?"
      />
      <div className="company_button_div mt-12">
        <Button
          label="No"
          className="close_button item_close_button"
          onClick={modalClose}
        />
        <Button
          label="Yes, Delete"
          className="yes_delete item_close_button"
          onClick={onSubmit}
        />
      </div>
    </ModalPopup>
  );
};

export default DeleteUnitPopup;
