import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Table,
  ToggleButton,
  Topnav,
} from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import FormTitle from '../Building/AddBuilding/FormTitle';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import DeleteElementPopup from '../../Common/DeleteElementPopup';
import {
  addStaff,
  deleteStaff,
  updateStaff,
} from '../../../redux/staff/staff.action';
import { useFormDebounce } from '../../../hooks';
import { loadState } from '../../../utils';
import './Staff.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IFormState } from '../../../redux/form/form.state';
import {
  updateStaffForm,
  clearStaffForm,
} from '../../../redux/form/form.action';
import { ILoaderState } from '../../../redux/loader/loader.state';
import { Staff } from '../../../models';

const AddBuildingStaff: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const { buildingStaffForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );

  const {
    register,
    handleSubmit,
    reset,
    errors,
    setValue,
    getValues,
  } = useForm<Staff>();

  // Decrypt the value of popup fields
  const [buildingData] = useState(
    query.get('building') ? JSON.parse(atob(query.get('building'))) : null
  );

  const staffId = params['staffId'] ? parseInt(params['staffId'], 10) : 0;
  useFormDebounce(updateStaffForm, 1000, getValues, clearStaffForm);
  const [staffData] = useState(
    staffId !== 0 ? loadState('buildingStaffForm') : buildingStaffForm
  );
  const [showStaffDelete, setShowStaffDelete] = useState({
    showModal: false,
    isDelete: false,
  });

  const [buildings, setUpdatedBuilding] = useState(
    staffId !== 0 ? staffData?.['buildingAccess'] : buildingData?.['building']
  );

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  useEffect(() => {
    if (staffId !== 0) {
      reset(staffData);
    }
  }, [staffData, reset, staffId]);

  const handleOnChange = async (e: any, rowData?: any) => {
    const newBuildingArray = [];
    buildings.filter(
      (building: { id: any; name: any; dashboardAccess: any }) => {
        if (building.id === rowData?.['id']) {
          newBuildingArray.push({
            id: building.id,
            name: building.name,
            dashboardAccess: e.currentTarget.checked,
          });
        } else {
          newBuildingArray.push({
            id: building.id,
            name: building.name,
            dashboardAccess: building.dashboardAccess
              ? building.dashboardAccess
              : false,
          });
        }
        return buildings;
      }
    );
    setUpdatedBuilding(newBuildingArray);
  };
  const onSubmit = async (data: Staff) => {
    data.name = buildingData ? buildingData?.['name'] : staffData?.['name'];
    data.birthday = data?.['birthday'] ? data?.['birthday'] : '';
    data.management = {
      id: buildingData
        ? buildingData?.['management']?.['id']
        : staffData?.['management']?.['id'],
      companyName: buildingData
        ? buildingData?.['management']?.['companyName']
        : staffData?.['management']?.['companyName'],
    };

    const newBuildingArray = [];
    buildings.filter((building) => {
      newBuildingArray.push({
        id: building.id,
        name: building.name,
        dashboardAccess: building.dashboardAccess
          ? building.dashboardAccess
          : false,
      });
      return buildings;
    });
    data.buildingAccess = newBuildingArray;

    if (staffId === 0) {
      await dispatch(addStaff(data));
    } else {
      await dispatch(updateStaff(staffId, data));
    }
    history.push(`/data-resources/clients/staff`);
  };

  const onDeleteStaff = async () => {
    await dispatch(deleteStaff(staffId));
  };

  return (
    <>
      <Topnav
        headerTitle={`${
          staffId !== 0 ? `${staffData?.['name']}` : 'Add Building Staff'
        }`}
      />
      {showStaffDelete.showModal && (
        <DeleteElementPopup
          isDelete={showStaffDelete.isDelete}
          modalState={showStaffDelete.showModal}
          modalClose={() =>
            setShowStaffDelete({ showModal: false, isDelete: false })
          }
          onDelete={onDeleteStaff}
          headerText="Are you sure you want to delete this Staff?"
          subHeader="Staff profile will be deactivated for 30 days before permanent deletion"
          buttonLabel="Delete Staff"
          redirectValue="/data-resources/clients/staff"
          modalClass="space_modal"
        />
      )}
      <div className="mx-10 staff-section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Button
            label={`${!staffId ? 'Save changes' : 'Save changes'}`}
            className={`${loading && 'button_disable'}`}
            disabled={loading}
          />
          {(errors?.email || errors?.contact) && (
            <span className="error-info">Please enter correct value</span>
          )}
          <div className="pt-12">
            <FormTitle title="Basic Details" />
          </div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
            <div>
              <Input
                isLabelShow={true}
                label="Name"
                name="name"
                ref={register}
                value={buildingData?.['name']}
                disabled={true}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Designation"
                name="designation"
                ref={register}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Phone number"
                name="contact"
                isError={errors?.['contact'] ? true : false}
                isMandatory={true}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                    message: 'Invalid phone number',
                  },
                })}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Email Address"
                name="email"
                isError={errors?.['email'] ? true : false}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                inputType="email"
                className={`${staffId && 'pointer-events-none'}`}
                isMandatory={true}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Birthday"
                ref={() => register({ name: 'birthday' })}
                onDateChange={(date) => setValue('birthday', date)}
                showDatepicker={true}
                placeholder="MM-DD-YYYY"
                selectedDate={
                  staffData?.['birthday'] && new Date(staffData?.['birthday'])
                }
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Management Company"
                name="management.companyName"
                value={buildingData?.['management']?.['companyName']}
                disabled={true}
                ref={register}
              />
            </div>
          </div>

          <FormTitle title="Buildings & Access" />
          <div>
            <Table
              tableColumns={[
                {
                  Header: 'Sno',
                  accessor: 'id',
                  Cell: (row: any) => {
                    return <div>{row.row.index + 1}</div>;
                  },
                },
                {
                  Header: 'Building name',
                  accessor: 'name',
                  className: 'table_col_building',
                },
                {
                  Header: 'Dashboard Access',
                  accessor: 'dashboardAccess',
                  Cell: (row: any) => {
                    return (
                      <ToggleButton
                        label=""
                        onChange={(e) => {
                          handleOnChange(e, row.row.original);
                        }}
                        checked={buildings[row.row.index].dashboardAccess}
                        isLeftLabel={true}
                      />
                    );
                  },
                  className: 'table_col_dashboard',
                },
              ]}
              tableData={
                staffId !== 0
                  ? staffData['buildingAccess']
                  : buildingData?.['building']
              }
              showPagination={false}
              className="mt-2"
              onCellClick={() => {}}
              onCellMouseEnter={() => {}}
            />
          </div>
        </form>
        {staffId > 0 && (
          <div className="delete_space">
            <label
              className="w-max"
              onClick={() => {
                setShowStaffDelete({ showModal: true, isDelete: true });
              }}
            >
              Delete Staff
            </label>
            <span>
              Staff profile will be deactivated for 30 days before permanent
              deletion
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default AddBuildingStaff;
