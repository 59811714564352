import React, { useState } from 'react';
import { useFetch } from '../../hooks';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Loader } from '@alfredclub/hom-planner-theme';
import SlideDrawer from '../Common/SliderPane';
import { findIndexFromArrayOfObject, getImageUrl } from '../../utils';
import Accordion from '../Common/Accordian/Accordion';
import { TOOLTIP_TEXT } from '../../constants/index';
import ReactTooltip from 'react-tooltip';
import { importTaskTemplateToEvent, IEventsState } from '../../redux/event';
import CreateEditTaskGroup from './CreateEditTaskGroup';
import Backdrop from '../Common/SliderPane/Backdrop';
import DraggableList from '../Common/DraggableList';
import GroupTaskList from './GroupTaskList';
import AddNewTaskPopup from './AddNewTaskPopup';
import {
  clearGroupTaskDetail,
  getTaskGroups,
  getTaskGroupsDetail,
  ITaskTemplateState,
} from '../../redux/taskTemplate';
import {
  importTaskTemplateToService,
  IServiceState,
} from '../../redux/service';
import CommonNavigation from '../Common/CommonNavigation';

const IMAGE_BASE = 'data-resources/inventory';

const TaskGroups: React.FC<{
  serviceTask?: boolean;
  serviceId?: number;
  serviceType?: number;
  isEdit?: boolean;
  eventId?: boolean;
}> = ({ serviceTask, serviceId, serviceType, isEdit, eventId }) => {
  const { status } = useFetch(getTaskGroups, ['', null]);
  const [addGroupState, toggleAddGroupState] = useState<boolean>(false);
  const [editGroup, setEditGroup] = useState<boolean>(false);
  const [groupItem, setGroupData] = useState(null);
  const [groupId, setGroupId] = useState<number>(null);
  const [editGroupTask, setEditGroupTask] = useState<boolean>(false);

  const { taskGroups } = useSelector(
    (state: { taskTemplate: ITaskTemplateState }) => state.taskTemplate
  );

  const { serviceTaskGroupList } = useSelector(
    (state: { services: IServiceState }) => state.services
  );

  const { eventTaskGroupList } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const [selectedItem, setSelectedItem] = useState(
    serviceId
      ? serviceTaskGroupList ?? []
      : eventId
      ? eventTaskGroupList ?? []
      : []
  );
  const dispatch = useDispatch();

  const createButtonLabel = () => {
    return (
      <>
        <img
          src={getImageUrl(`${IMAGE_BASE}/group-icon.svg`)}
          alt="trunk-label"
        />
        <span>Create Group</span>
      </>
    );
  };

  const onCreateGroup = () => {
    setGroupData(null);
    setEditGroup(false);
    toggleAddGroupState(!addGroupState);
  };

  const onEditGroup = async (editGroupId: number) => {
    const groupData = await dispatch(getTaskGroupsDetail(editGroupId));
    setGroupData(groupData);
    setEditGroup(true);
    toggleAddGroupState(!addGroupState);
  };

  const onAddItem = async (id: number) => {
    setGroupId(id);
    setEditGroupTask(false);
    await dispatch(clearGroupTaskDetail());
    toggleDrawer();
  };

  const handleOnChange = async (rowData: any) => {
    const items = [...selectedItem];
    const itemIdIndex = findIndexFromArrayOfObject(items, 'id', rowData);
    if (itemIdIndex !== -1) {
      items.splice(itemIdIndex, 1);
    } else {
      items.push({ id: rowData['id'], isImportedTask: true });
    }
    setSelectedItem(() => [...items]);
    await dispatch(
      serviceTask
        ? importTaskTemplateToService(items)
        : importTaskTemplateToEvent(items)
    );
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = async () => {
    setDrawerOpen(!drawerOpen);
  };

  let backdrop: {};
  if (drawerOpen) {
    backdrop = <Backdrop close={toggleDrawer} />;
  }

  const toggleTaskGroupDrawer = async () => {
    setEditGroup(false);
    toggleAddGroupState(!addGroupState);
  };

  let createEditTaskbackdrop: {};
  if (addGroupState) {
    createEditTaskbackdrop = <Backdrop close={toggleTaskGroupDrawer} />;
  }

  const checkPrevGroupSelected = (item) => {
    return findIndexFromArrayOfObject(serviceTaskGroupList, 'id', item) !== -1
      ? true
      : false;
  };

  const goBackToImportLocation = () => {
    return serviceId
      ? !isEdit
        ? `/data-resources/services/add-service?tab=task-list&serviceType=${serviceType}&id=${serviceId}`
        : `/data-resources/services/edit-service/${serviceId}?tab=task-list&serviceType=${serviceType}`
      : `/events/${eventId}/create?step=task-management&edit=true&tab=task-list`;
  };
  return status === 'fetched' ? (
    <section className="taskgroup-wrapper ml-8 mr-8">
      {(serviceId || eventId) && (
        <CommonNavigation
          linkTo={goBackToImportLocation()}
          header={serviceId ? 'Go back to services' : 'Go back to event'}
          subHeader="Import Template"
        />
      )}
      <Button
        label={createButtonLabel()}
        type="button"
        className="create_group_btn"
        onClick={onCreateGroup}
      />
      {taskGroups?.map((item, index: number) => {
        return (
          <div key={index} className={`m-0 ${index <= 0 && 'mt-5'}`}>
            <Accordion
              active="active"
              title={
                <p className="flex mb-2">
                  {(serviceTask || eventId) && (
                    <input
                      type="checkbox"
                      className={`checkbox mx-2`}
                      disabled={checkPrevGroupSelected(item)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleOnChange(item);
                      }}
                      checked={
                        findIndexFromArrayOfObject(selectedItem, 'id', item) !==
                        -1
                          ? true
                          : false
                      }
                    />
                  )}
                  <span
                    data-tip={TOOLTIP_TEXT.inventory.group.edit}
                    className="cursor-pointer underline text-F18"
                    onClick={() => onEditGroup(item?.['id'])}
                  >
                    {item.name}
                  </span>
                </p>
              }
              content={
                <>
                  <DraggableList
                    dragList={item['tasks'] ? item['tasks'] : []}
                    className="dragTask"
                  >
                    <GroupTaskList
                      drawer={toggleDrawer}
                      setEditGroupTask={setEditGroupTask}
                    />
                  </DraggableList>
                  <button
                    className="float-none text-F16 pt-3 text-Color_2 inline-flex"
                    type="button"
                    onClick={() => onAddItem(item.id)}
                  >
                    +Add Task
                  </button>
                </>
              }
              invClass="group-name"
            />
          </div>
        );
      })}
      <ReactTooltip />
      <div>
        <SlideDrawer
          show={drawerOpen}
          slideClass="side-drawer new-task-side-drawer h-full overflow-y-scroll side-drawer-event-mng-width open"
        >
          <AddNewTaskPopup
            closePane={() => {
              toggleAddGroupState(false);
              toggleDrawer();
            }}
            groupId={groupId}
            isEdit={editGroupTask}
          />
        </SlideDrawer>
        {backdrop}
        <SlideDrawer
          show={addGroupState}
          slideClass="p-10 side-drawer h-full overflow-y-scroll side-drawer-create-group-width open manage-group-wrapper"
        >
          <CreateEditTaskGroup
            onClosePopup={(id) => {
              if (id) {
                onAddItem(id);
                setTimeout(() => {
                  toggleAddGroupState(!addGroupState);
                }, 1000);
              } else {
                setGroupData(null);
                toggleAddGroupState(!addGroupState);
                setEditGroup(false);
              }
            }}
            isEdit={editGroup}
            groupItem={groupItem}
          />
        </SlideDrawer>
        {createEditTaskbackdrop}
      </div>
    </section>
  ) : (
    <Loader
      color="#e89a89"
      position="center"
      loading={status !== 'fetched'}
      size="0.75rem"
    />
  );
};

export default TaskGroups;
