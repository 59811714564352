import React, { useState, useEffect } from 'react';
import CommonLayout from '../../CommonLayout/CommonLayout';
import BuildingPopup from './BuildingPopup/BuildingPopup';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '@alfredclub/hom-planner-theme';
import { useHistory } from 'react-router-dom';

import {
  getBuildings,
  getBuildingDetail,
  clearBuildingStatusCheck,
  saveBuildingId,
} from '../../../redux/building/buildings.action';
import { useFetch, usePagination } from '../../../hooks';
import './AddBuilding/AddBuildingForm.scss';
import ReactTooltip from 'react-tooltip';
import { clearBuildingForm } from '../../../redux/form/form.action';
import { saveBuildingBlocksLocaly } from '../../../redux/buildingblocks/buildingblocks.action';
import { TOOLTIP_TEXT } from '../../../constants/index';
import { getSerialNumber } from '../../../utils';
import { IBuildingState } from '../../../redux/building';

const BuildingListing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { status } = useFetch(getBuildings);
  const [showAddBuildingPopup, setAddBuildingPopupState] = useState(false);

  const [pageNo, setPage] = useState(0);
  const { pages, buildingCount } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);
  const pagination = usePagination();
  const onPageClick = async (page: number) => {
    await pagination(pages, page - 1, getBuildings);
    setPage(page - 1);
  };
  let timer = null;
  const promiseOptions = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getBuildings(10, 0, 'short', inputValue, 1, false)
          );
          res(data);
        }, 1000))
    );
  };
  const onAddBuilding = async (e) => {
    e.preventDefault();
    await dispatch(saveBuildingId(null));
    await dispatch(clearBuildingForm());
    await dispatch(clearBuildingStatusCheck());
    setAddBuildingPopupState(true);
  };
  const onClosePopup = (e: Event) => {
    e.preventDefault();
    setAddBuildingPopupState(false);
  };
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  return (
    <div>
      {showAddBuildingPopup && (
        <BuildingPopup
          modalState={showAddBuildingPopup}
          onClosePopup={onClosePopup}
        />
      )}
      {status === 'fetched' ? (
        <CommonLayout
          headerTitle="Buildings"
          showTable={true}
          showSortButton={true}
          promiseOptions={promiseOptions}
          optionLabel="name"
          optionKey="id"
          tableColumnsData={pages?.get(pageNo)?.data}
          navIconToolTipText={TOOLTIP_TEXT.buildings.new}
          tableColumnsHeaders={[
            {
              Header: 'Sno',
              Cell: (row: any) => {
                return <div>{getSerialNumber(pageNo, row.row.index)}</div>;
              },
            },
            {
              Header: '',
              accessor: 'buildingType',
              Cell: (row: any) => (
                <div
                  data-tip={
                    row.value === 'R'
                      ? TOOLTIP_TEXT.buildings.residential
                      : TOOLTIP_TEXT.buildings.commercial
                  }
                  className={
                    row.value === 'R' ? 'circle_cell' : 'circle_cell_company'
                  }
                >
                  {row.value}
                  <ReactTooltip />
                </div>
              ),
            },
            {
              Header: 'Building',
              className: 'tableAnchor',
              accessor: (row: any) => (
                <>
                  <button
                    onClick={async () => {
                      setLoadingState(true);
                      setRowState(row.id);
                      dispatch(saveBuildingBlocksLocaly({}));
                      await dispatch(clearBuildingStatusCheck());
                      await dispatch(saveBuildingId(null));
                      await dispatch(getBuildingDetail(parseInt(row.id, 10)));
                      await dispatch(saveBuildingId(parseInt(row.id, 10)));
                      history.push(`buildings/${row.id}`);
                    }}
                  >
                    {row.name}
                  </button>
                  <Loader
                    color="#E89A89"
                    position="center"
                    loading={showLoader && setRowClick === row.id}
                    size="0.75rem"
                    className={`${
                      showLoader && setRowClick === row.id && 'row_loader'
                    }`}
                  />
                </>
              ),
            },
            {
              Header: 'Management Company',
              accessor: 'management',
            },
            {
              Header: 'Cluster',
              accessor: 'clusterName',
            },
            {
              Header: 'Status',
              accessor: (row: any) => (!row.active ? 'Inactive' : 'Active'),
            },
          ]}
          showSearchBar={true}
          showIcon={true}
          showPagination={!(buildingCount < 10) && true}
          recordsCount={buildingCount}
          onPageChange={(pageNumber) => onPageClick(pageNumber)}
          onSearchOptionSelect={async (option) => {
            if (option) {
              dispatch(saveBuildingBlocksLocaly({}));
              await dispatch(clearBuildingStatusCheck());
              // await dispatch(getBuildingDetail(parseInt(option.id, 10)));
              history.push(`buildings/${option.id}`);
            }
          }}
          onClick={(e) => onAddBuilding(e)}
          placeholder="Search Building"
        />
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
    </div>
  );
};
export default BuildingListing;
