import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Button, Dropdown, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import {
  pricingTypeOptions,
  pricingLabelOptions,
  perAttendeeRange,
} from '../../../constants/index';
import { getRegions } from '../../../redux/region/regions.action';
import { useDispatch, useSelector } from 'react-redux';
import {
  addServicePrice,
  editSingleServicePricing,
  getPackageServicePrice,
  getSimpleServicePrice,
} from '../../../redux/service/services.action';
import { ILoaderState } from '../../../redux/loader';
import { IRegionState } from '../../../redux/region';
import { Pricing, PricingRequest } from '../../../models';
import PricingFields from './PricingFields';
import { IServiceState } from '../../../redux/service';
import CommonLoader from '../../Common/CommonLoader';

const AddPricing: React.FC<{
  closePane: any;
  serviceId: number;
  serviceType: number;
  elementId: number;
  elementIds: any[];
  isEdit: boolean;
}> = ({ closePane, serviceId, elementId, elementIds, isEdit }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const priceFieldArray = {
    details: [
      {
        buildingCost: null,
        profit: null,
        vendorCut: null,
        memberRange: null,
      },
    ],
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    errors,
    control,
  } = useForm<PricingRequest>({
    defaultValues: {
      pricing: [priceFieldArray],
    },
  });
  const { singlePricingDetail } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pricing',
  });
  const [setTypeOfPrice, SetPriceType] = useState(null);
  const { regions } = useSelector(
    (state: { regions: IRegionState }) => state.regions
  );
  const priceObjValue = pricingLabelOptions.filter(
    (data) => data.value === setTypeOfPrice
  );
  const selectedPricingType = pricingTypeOptions.filter(
    (data) =>
      data.value === singlePricingDetail?.['pricing']?.[0]?.['pricingType']
  );
  useEffect(() => {
    if (isEdit) {
      remove();
      append(singlePricingDetail?.['pricing']?.[0]?.['details']);
      setValue('regions', singlePricingDetail?.['pricing']?.[0]?.['regions']);
      setValue(
        'pricingType',
        singlePricingDetail?.['pricing']?.[0]?.['pricingType']
      );
      return;
    }
    remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);
  const saveUpdatePricing = async (paramId: number, data: Pricing) => {
    if (isEdit) {
      data['id'] = singlePricingDetail?.['pricing']?.[0]?.['id'];
      await dispatch(
        editSingleServicePricing(Number(paramId), { pricing: [data] })
      );
      return;
    }
    await dispatch(addServicePrice({ pricing: [data] }, Number(paramId)));
  };
  const onSubmit = async (data: Pricing) => {
    // Package case (add/edit)
    if (elementId) {
      data['element'] = {
        id: Number(elementId),
      };
      await saveUpdatePricing(elementId, data);
      await dispatch(getPackageServicePrice(elementIds));
    } else {
      data['element'] = {
        id: Number(serviceId),
      };
      await saveUpdatePricing(serviceId, data);
      await dispatch(getSimpleServicePrice(Number(serviceId)));
    }
    reset();
    closePane();
  };

  const onTypeChange = (value: number) => {
    if (value === 0) {
      remove();
      for (const field of perAttendeeRange) {
        if (field.id <= perAttendeeRange.length) {
          append(priceFieldArray);
        }
      }
      return;
    }
    remove();
    append(priceFieldArray);
  };
  const renderFields = () => {
    return (
      (priceObjValue.length > 0 || isEdit) && (
        <>
          <PricingFields
            fields={fields}
            errors={errors}
            register={register}
            priceObjValue={priceObjValue}
            className={`${priceObjValue[0]?.value === 0 ? 'mt-2' : 'mt-8'}`}
            isRange={
              isEdit && selectedPricingType[0]?.value === 0
                ? true
                : priceObjValue[0]?.value === 0
                ? true
                : false
            }
          />
          <Button
            label="Add pricing"
            className={`${
              loading ? 'add_price button_disable' : 'add_price mt-8'
            }`}
            disabled={loading}
          />
        </>
      )
    );
  };
  return (
    <>
      {loading ? (
        <CommonLoader loading={loading} />
      ) : (
        <form className="pricing-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-12 grid grid-cols-1 gap-x-2 gap-y-0 gap-2">
            {errors.pricing && (
              <div className="error title_error">
                Please fill required fields
              </div>
            )}
            <PrimaryLabel labelText="Region" />
            <Dropdown
              asyncDropdown={false}
              labelKey="regionName"
              valueKey="id"
              isLabelShow={false}
              label="Region"
              options={regions}
              onMenuOpen={async () => {
                if (regions.length === 0) {
                  await dispatch(getRegions(0, 0, 'short', 1));
                }
              }}
              loading={loading}
              ref={() => register({ name: 'regions' })}
              onOptionChange={(e) => {
                setValue('regions', e);
              }}
              placeholder="Select"
              isMulti={true}
              className="text_area_label"
              resetValue={loading || !isEdit}
              isError={errors?.pricing?.['regions'] ? true : false}
              value={singlePricingDetail?.['pricing']?.[0]?.['regions']}
            />
          </div>
          <div className="mx-12 mt-8 grid grid-cols-3 gap-x-3 gap-y-0 gap-2">
            <div>
              <PrimaryLabel labelText="Type of Pricing" />
              <Dropdown
                label="Type of Pricing"
                ref={() => register({ name: 'pricingType' })}
                isLabelShow={false}
                options={pricingTypeOptions}
                labelKey="label"
                valueKey="value"
                onOptionChange={(e) => {
                  SetPriceType(Number(e['value']));
                  setValue('pricingType', Number(e['value']));
                  onTypeChange(Number(e['value']));
                }}
                resetValue={loading || !isEdit}
                placeholder="Select"
                className="drop_input_label"
                value={selectedPricingType}
                disabled={isEdit}
              />
            </div>
          </div>
          {renderFields()}
        </form>
      )}
    </>
  );
};

export default AddPricing;
