import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Input,
  PrimaryLabel,
  ToggleButton,
} from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryTags, IServiceState } from '../../redux/service';
import {
  selectedTagColor,
  selectedTagTextColor,
  taskTemplateGroupOptions,
  TASK_TEMPLATE,
  TOOLTIP_TEXT,
} from '../../constants';
import { ICreateTaskGroup } from '../../models';
import {
  createTaskGroups,
  deleteTaskGroups,
  getTaskGroups,
  updateTaskGroups,
} from '../../redux/taskTemplate';
import { getServices } from '../../redux/service/services.action';
import DeletePopup from './DeletePopup';
import ReactTooltip from 'react-tooltip';
import { ILoaderState } from '../../redux/loader';
import { getImageUrl } from '../../utils';
// setName

const CreateEditTaskGroup: React.FC<any> = ({
  onClosePopup,
  isEdit = false,
  groupItem = null,
}: any) => {
  const [deleteGroupState, setDeleteGroup] = useState<boolean>(false);
  const [addToService, setAutoServiceToggle] = useState<boolean>(false);
  const [toggleState, setToggleState] = useState<boolean>(false);
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false);
  const [taskGroup, setTaskGroup] = useState(taskTemplateGroupOptions);
  const [productionTeamCheck, setProductionTeamCheck] = useState<boolean>(
    false
  );
  const [virtualServiceCheck, setVirtualServiceCheck] = useState<boolean>(
    false
  );
  const taskGroupDefaultState = taskTemplateGroupOptions;
  const [elements, setElements] = useState([]);
  const {
    register,
    errors,
    handleSubmit,
    reset,
    setValue,
  } = useForm<ICreateTaskGroup>();
  const dispatch = useDispatch();
  const { categoryTagsList, allServices } = useSelector(
    (state: { services: IServiceState }) => state.services
  );

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const onSubmit = async (data: ICreateTaskGroup) => {
    const params = {
      name: data.name,
      addToService: data.addToService
        ? data?.categoryTags?.length > 0 || data?.allServices?.length > 0
          ? true
          : false
        : false,
      categoryTags: data?.categoryTags ?? [],
      allServices: data?.allServices ?? [],
      virtualServices: data?.categoryTags?.length ? false : virtualServiceCheck,
      productionTeam: productionTeamCheck,
    };
    if (!isEdit) {
      const createTaskGroup = await dispatch(createTaskGroups(params));
      reset({});
      setAutoServiceToggle(false);
      setProductionTeamCheck(false);
      setValue('allServices', []);
      setValue('categoryTags', []);
      setElements([]);
      await dispatch(getTaskGroups(''));
      onClosePopup(createTaskGroup?.id ?? null);
    } else {
      await dispatch(updateTaskGroups(params, groupItem?.['id']));
      reset({});
      setAutoServiceToggle(false);
      setProductionTeamCheck(false);
      setValue('allServices', []);
      setValue('categoryTags', []);
      setElements([]);
      await dispatch(getTaskGroups(''));
      onClosePopup();
    }
  };

  const onDeleteGroup = async () => {
    await dispatch(deleteTaskGroups(groupItem?.['id']));
    setDeleteGroup(!deleteGroupState);
    onClosePopup();
    await dispatch(getTaskGroups(''));
  };

  const onServiceToggleOFF = async () => {
    if (isEdit) {
      const params = {
        name: groupItem?.name,
        addToService: false,
        allServices: groupItem?.allServices,
        virtualServices: groupItem?.virtualServices,
        categoryTags: groupItem?.categoryTags,
      };
      await dispatch(updateTaskGroups(params, groupItem?.['id']));
      setToggleState(false);
      onClosePopup();
      await dispatch(getTaskGroups(''));
    }
  };

  const onGroupOptionChange = (data, index) => {
    const updatedOptions = taskGroup.filter((dataItem, itemIndex) => {
      if (itemIndex !== index) {
        dataItem.checked = false;
      } else {
        taskGroup[index].checked = true;
      }
      return taskGroup;
    });
    setProductionTeamCheck(false);
    setVirtualServiceCheck(data.name === 'virtualServices' ? true : false);
    setValue('allServices', []);
    setValue('categoryTags', []);
    setElements([]);
    setTaskGroup([...updatedOptions]);
  };

  const onCancelDeleteClick = () => {
    if (deleteGroupState) {
      setDeleteGroup(!deleteGroupState);
    } else {
      setToggleState(false);
      setAutoServiceToggle(!addToService);
    }
  };

  useEffect(() => {
    if (isEdit) {
      const updatedOptions = taskGroup.filter((dataItem, itemIndex) => {
        if (groupItem?.categoryTags?.length && itemIndex === 0) {
          dataItem.checked = true;
        } else if (
          !groupItem?.categoryTags?.length &&
          !groupItem?.virtualServices &&
          itemIndex === 1
        ) {
          dataItem.checked = true;
        } else if (
          !groupItem?.categoryTags?.length &&
          groupItem?.virtualServices &&
          itemIndex === 2
        ) {
          dataItem.checked = true;
        } else {
          dataItem.checked = false;
        }
        return taskGroup;
      });
      setTaskGroup([...updatedOptions]);
      setVirtualServiceCheck(groupItem.virtualServices);
      setProductionTeamCheck(groupItem?.productionTeam);
      reset({
        ...groupItem,
        allServices: groupItem?.['allServices'],
        virtualServices: groupItem?.['virtualServices'],
        categoryTags: groupItem?.['categoryTags'],
      });
      setElements(
        isEdit
          ? groupItem?.[
              groupItem?.categoryTags?.length ? 'categoryTags' : 'allServices'
            ]
          : []
      );
      setAutoServiceToggle(groupItem?.['addToService']);

      setToggleState(false);
      return;
    } else {
      reset({});
      setElements([]);
      setProductionTeamCheck(false);
      setAutoServiceToggle(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    return () => {
      reset({});
      setAutoServiceToggle(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAllelements = async (type: string) => {
    setCategoryLoading(true);
    const services = await dispatch(
      getServices(10, 0, 'short', '', 1, true, type)
    );
    setValue('allServices', services);
    setElements(services);
    setCategoryLoading(false);
  };
  return (
    <>
      <div className="flex justify-between w-auto">
        <h1 className="group-header pt-8">
          {isEdit ? 'Manage Group' : 'Create Task Group'}
        </h1>
        <img
          className="float-right cursor-pointer"
          src={getImageUrl('Events/closeeventpopup.svg')}
          alt="close"
          onClick={() => {
            setTaskGroup(taskGroupDefaultState);
            onClosePopup();
          }}
        />
      </div>
      <PrimaryLabel labelText="Group Name" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="GroupName"
          placeholder="Type here"
          name="name"
          ref={register({ required: true })}
          isError={errors?.['name'] ? true : false}
        />
        <div className={`pt-8 ${!toggleState && 'pb-4'}`}>
          <PrimaryLabel
            labelText="Auto-add Task group to Services and Events"
            className=""
          />
          <div
            className="float-right"
            data-tip={
              addToService && isEdit
                ? TOOLTIP_TEXT.taskTemplate.serviceToggle
                : ''
            }
            data-for="serviceToggle"
          >
            <ToggleButton
              label=""
              onChange={(e) => {
                setAutoServiceToggle(e.currentTarget.checked);
                setToggleState(!e.currentTarget.checked);
              }}
              checked={addToService}
              name="addToService"
              ref={register}
              isLeftLabel={true}
            />
          </div>
          <ReactTooltip
            className="serviceToggle-tooltip"
            place="bottom"
            effect="float"
            offset={{ left: 70 }}
            id="serviceToggle"
          />
        </div>

        {addToService && (
          <div className="pt-4">
            {taskGroup?.map((itemObj, index: number) => {
              return (
                <div className="task-radio" key={index}>
                  <div className="flex">
                    <input
                      type="radio"
                      className="taskradio cursor-pointer"
                      onChange={() => onGroupOptionChange(itemObj, index)}
                      checked={itemObj.checked}
                      ref={() => register({ name: 'virtualServices' })}
                    />
                    <div className="w-full inline-flex justify-between">
                      <div>
                        <PrimaryLabel
                          labelText={itemObj.title}
                          className="text-Color_22 align-top"
                        />
                      </div>
                      {itemObj.addAllCheck && itemObj.checked && (
                        <div className="add-all-text">
                          <span
                            onClick={() => {
                              selectAllelements(itemObj.type);
                            }}
                            className="cursor-pointer"
                          >
                            Add all
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {itemObj.checked && (
                    <>
                      <div className="pb-3">
                        <Dropdown
                          asyncDropdown={false}
                          labelKey="name"
                          valueKey="id"
                          isLabelShow={false}
                          isMulti={true}
                          label=""
                          options={
                            itemObj.addAllCheck ? allServices : categoryTagsList
                          }
                          onMenuOpen={async () => {
                            setCategoryLoading(true);
                            itemObj.addAllCheck
                              ? await dispatch(
                                  getServices(
                                    10,
                                    0,
                                    'short',
                                    '',
                                    1,
                                    true,
                                    itemObj.type
                                  )
                                )
                              : await dispatch(getCategoryTags());
                            setCategoryLoading(false);
                          }}
                          ref={() =>
                            register(
                              {
                                name: itemObj.addAllCheck
                                  ? 'allServices'
                                  : 'categoryTags',
                              },
                              { required: true }
                            )
                          }
                          onOptionChange={async (e) => {
                            setValue(
                              itemObj.addAllCheck
                                ? 'allServices'
                                : 'categoryTags',
                              e
                            );
                          }}
                          placeholder={itemObj.placeholderText}
                          value={elements}
                          className="service_input_width categoryTags"
                          backgroundColor={selectedTagColor}
                          textColor={selectedTagTextColor}
                          loading={categoryLoading}
                          isError={
                            errors?.[
                              itemObj.addAllCheck
                                ? 'allServices'
                                : 'categoryTags'
                            ]
                              ? true
                              : false
                          }
                          resetValue={
                            isEdit
                              ? elements &&
                                groupItem?.[
                                  groupItem?.categoryTags?.length
                                    ? 'categoryTags'
                                    : 'allServices'
                                ] &&
                                elements?.length >
                                  groupItem?.[
                                    groupItem?.categoryTags?.length
                                      ? 'categoryTags'
                                      : 'allServices'
                                  ].length
                                ? true
                                : false
                              : elements?.length > 0
                              ? true
                              : false
                          }
                          classNamePrefix={
                            itemObj.addAllCheck ? 'allservice-elements' : ''
                          }
                        />
                      </div>
                      {itemObj.addAllCheck && (
                        <div className="table_checkbox pb-10 ">
                          <input
                            type="checkbox"
                            className="checkbox rounded"
                            onChange={(e) => {
                              setValue('productionTeam', e.target.checked);
                              setProductionTeamCheck(e.target.checked);
                            }}
                            ref={() => register({ name: 'productionTeam' })}
                            checked={productionTeamCheck}
                          />
                          <PrimaryLabel
                            labelText="Include production teams of selected elements"
                            className="text-Color_22 align-center text-Color_6"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}
        {isEdit && (deleteGroupState || toggleState) && (
          <DeletePopup
            modalState={isEdit && (deleteGroupState || toggleState)}
            modalClose={() => onCancelDeleteClick()}
            onDelete={deleteGroupState ? onDeleteGroup : onServiceToggleOFF}
            headerText={
              deleteGroupState
                ? TASK_TEMPLATE.deleteGroup
                : TASK_TEMPLATE.toggleService
            }
            subHeader="Are you sure you want to continue?"
            buttonLabel="Yes, continue"
            cancelButtonLabel="No, cancel"
          />
        )}

        <div className="flex pt-20 justify-between">
          <Button
            label={!isEdit ? 'Start Creating' : 'Save changes'}
            isArrowShow={!isEdit}
            disabled={loading}
            className={loading && `group-button`}
          />
          {groupItem && (
            <PrimaryLabel
              labelText="Delete group"
              onClick={() => setDeleteGroup(!deleteGroupState)}
              className="my-2 cursor-pointer text-Color_19 text-F16 pt-6 inline-flex"
            />
          )}
        </div>
      </form>
    </>
  );
};

export default CreateEditTaskGroup;
