import React, { useState } from 'react';
import { getImageUrl, linkString } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteServiceTask,
  getServiceTaskList,
} from '../../../redux/service/services.action';
import { IServiceState } from '../../../redux/service';
import { clearServiceTaskListForm } from '../../../redux/form/form.action';
import DeletePopup from '../DeletePopup';
import { TOOLTIP_TEXT } from '../../../constants/index';
import ReactTooltip from 'react-tooltip';
import { IFormState } from '../../../redux/form';
import { Task } from '../../../models';
/*
  1. TaskListItem is cloned in Draggable list component
  2. Item is comming from Draggable list component
*/
const TaskListItem: React.FC<{
  item?: Task;
  serviceId: number;
  serviceType: number;
  drawer: () => void;
  setTaskEdit: any;
  setTaskId: (taskId: number) => void;
  taskTemplateData: (name: string) => void;
}> = ({
  item,
  serviceId,
  serviceType,
  drawer,
  setTaskEdit,
  setTaskId,
  taskTemplateData,
}) => {
  const [showSetupDelete, setSetupDelete] = useState({
    showModal: false,
    isDelete: false,
  });

  const { packageElementForm, serviceSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const { serviceData } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const elementsAssociated = packageElementForm?.['packageElements'].map(
    (data: any) => ({
      id: data.id,
    })
  );
  const [deleteItem, setDeleteItem] = useState(0);
  const dispatch = useDispatch();

  const deleteTask = async () => {
    await dispatch(deleteServiceTask(deleteItem));
    await dispatch(clearServiceTaskListForm());
    if (serviceType > 1) {
      if (elementsAssociated?.length > 0) {
        await dispatch(getServiceTaskList(serviceId));
      }
    } else {
      await dispatch(getServiceTaskList(Number(serviceId)));
    }
  };

  const timeObjValue =
    item['timeOfTask'] === 0
      ? 'Day before the event'
      : item['timeValue'] < 0
      ? 'Event day - before event starts'
      : item['timeValue'] > 0 && 'Event day - After event starts';

  const getTask = async (data: Task) => {
    setTaskEdit(true);
    setTaskId(Number(data?.['id']));
    drawer();
    taskTemplateData(item['groupName'] ?? '');
  };

  return (
    <>
      {showSetupDelete.showModal && (
        <DeletePopup
          isDelete={showSetupDelete.isDelete}
          modalState={showSetupDelete.showModal}
          modalClose={() =>
            setSetupDelete({ showModal: false, isDelete: false })
          }
          onDelete={deleteTask}
          headerText="Are you sure you want to delete this task?"
          buttonLabel="Yes, delete"
          cancelButtonLabel="No, cancel"
        />
      )}
      <div className={`task-list-item cursor-pointer`}>
        <div onClick={() => getTask(item)}>
          <div className="flex">
            <p className="p_title">{item['title']}</p>
            <p className="p_timeOfTask">
              <img
                src={getImageUrl(`data-resources/time.svg`)}
                alt="time"
                className="pr-2 inline-flex"
              />
              {timeObjValue}
            </p>
          </div>
          <p className="p_vendor flex">
            <img
              data-tip={TOOLTIP_TEXT.services.task.element}
              src={getImageUrl(`data-resources/element+vendor.svg`)}
              alt="vendor"
              className="pr-2 inline-flex"
            />
            {item?.element?.length > 0 ? (
              <p>
                {item.element.map((data, i) => {
                  return `${data.name}${
                    i < item.element.length - 1 ? ', ' : ''
                  }`;
                })}
              </p>
            ) : (
              serviceSetupForm?.name ?? serviceData?.name
            )}
          </p>
          <p className="p_description">
            <img
              data-tip={TOOLTIP_TEXT.services.task.description}
              src={getImageUrl(`data-resources/description.svg`)}
              alt="description"
              className="pr-2 inline-flex"
            />
            {item['description']}
          </p>
          {item['documentLink'] && (
            <a
              href={linkString(item['documentLink'])}
              target="_blank"
              className="cursor-pointer a_link"
              rel="noreferrer"
            >
              <img
                data-tip={TOOLTIP_TEXT.services.task.description}
                src={getImageUrl(`data-resources/link.svg`)}
                alt="description"
                className="pr-2 inline-flex"
              />
              {item['documentLink']}
            </a>
          )}
          {item['groupName'] && (
            <div className="p_templateName flex items-center justify-center">
              <p>Template task</p>
            </div>
          )}
        </div>
        <img
          onClick={() => {
            setDeleteItem(item['id']);
            setSetupDelete({
              showModal: true,
              isDelete: true,
            });
          }}
          src={getImageUrl(`data-resources/delete.svg`)}
          alt="delete"
          className="delete_task inline-flex"
        />
      </div>
      <ReactTooltip />
    </>
  );
};

export default TaskListItem;
