import React, { Fragment, useEffect, useState } from 'react';
import {
  Dropdown,
  PrimaryLabel,
  ToggleButton,
} from '@alfredclub/hom-planner-theme';
import { Register, SetValue } from '../../../models/commonTypes';
import { getProductionTeam, IServiceState } from '../../../redux/service';
import { useSelector, useDispatch } from 'react-redux';
import { IProductionTeamProps, TinyObject } from '../../../models';
import { IFormState, updateEventSetupForm } from '../../../redux/form';
import LabelWithIcon from './LabelWithIcon';
import { getImageUrl } from '../../../utils';
import ReactTooltip from 'react-tooltip';

const ElementProdTeam: React.FC<{
  formErrors: object;
  register: Register;
  setValue: SetValue;
  onAddProductionTeam: any;
  fields: IProductionTeamProps[];
  unregister: Register;
  isEdit: boolean;
  onDeleteProdTeam: (index: number) => void;
  onFieldInputChange: (
    key: string,
    value: string | TinyObject | number | boolean
  ) => void;
  setToggleState: any;
  toggleState: boolean;
  onChange: (
    e: HTMLInputElement | { value: string | object },
    index: number
  ) => void;
  isPast?: boolean;
}> = ({
  register,
  onAddProductionTeam,
  fields,
  isEdit,
  onDeleteProdTeam,
  onFieldInputChange,
  setToggleState,
  toggleState,
  onChange,
  isPast,
}) => {
  const dispatch = useDispatch();
  const { productionTeamList } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { eventSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );

  const [vendorTypeLoading, setVendorTypeLoading] = useState<boolean>(false);
  const [fieldDelete, setFieldDelete] = useState<boolean>(false);

  const onToggleChange = async (e) => {
    setToggleState(e.currentTarget.checked);
    onFieldInputChange('productionTeamExist', e.currentTarget.checked);
    onAddProductionTeam(
      e.currentTarget.checked,
      isEdit
        ? eventSetupForm?.['productionTeams']?.length > 0
          ? false
          : true
        : true
    );
  };

  const onVendorTypeChange = (
    productionTeam: React.ChangeEvent<HTMLElement>,
    index: number
  ) => {
    for (const key of Object.keys(productionTeam)) {
      if (key === 'id') {
        const event = {
          value: { [key]: productionTeam[key] },
        };
        onChange(event, index);
      }
    }
  };

  // Used to update the reducer on field length changed
  useEffect(() => {
    if (fields?.length > 0) {
      dispatch(
        updateEventSetupForm({
          ...eventSetupForm,
          productionTeams: fields,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  // Used to reset the vendor type DDL on delete of vendor type
  useEffect(() => {
    if (fieldDelete && fields?.length > 0) {
      fields?.forEach((item, index) => {
        fields[index] = item;
      });
      setFieldDelete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldDelete]);

  return (
    <div className="mt-16">
      <LabelWithIcon
        imgSrc={getImageUrl('Events/setupevent-1.svg')}
        labelText="Production Team"
        className="img_label"
      />
      <div className="grid grid-cols-2 gap-x-2 gap-y-0">
        <div className="mt-4">
          <div className="pt-6 pb-4">
            <label className="label_text">Requirement</label>
            <div className={`float-right ${isPast && 'pointer-events-none'}`}>
              <ToggleButton
                label="Requirement"
                onChange={(e) => onToggleChange(e)}
                isLabelShow={false}
                checked={toggleState}
                uncheckedText="No"
                checkedText="Yes"
                name="productionTeamRequired"
                ref={register}
                isLeftLabel={true}
              />
            </div>
          </div>
        </div>
      </div>
      {toggleState &&
        fields?.map((_, index) => {
          return (
            <Fragment key={index}>
              <div className="grid grid-cols-2 gap-x-2 gap-y-0">
                <div>
                  <PrimaryLabel
                    labelText="Select Vendor Type"
                    className={index === 0 ? 'block' : 'hidden'}
                  />
                  <Dropdown
                    asyncDropdown={false}
                    labelKey="name"
                    valueKey="id"
                    isLabelShow={false}
                    label="Vendor type"
                    options={productionTeamList}
                    onMenuOpen={async () => {
                      setVendorTypeLoading(true);
                      if (productionTeamList.length === 0) {
                        await dispatch(getProductionTeam());
                      }
                      setVendorTypeLoading(false);
                    }}
                    name={`productionTeams[${index}]`}
                    isError={!fields?.[index]?.['id']}
                    onOptionChange={(e) => {
                      onVendorTypeChange(e, index);
                    }}
                    placeholder=""
                    className="drop_input_label"
                    value={fields?.[index] ?? null}
                    loading={vendorTypeLoading}
                    resetValue={fieldDelete ? true : false}
                    disabled={isPast}
                  />
                </div>
                <div className={`${index === 0 ? 'mt-8' : 'mt-3'}`}>
                  <img
                    src={getImageUrl('Events/remove-prod.svg')}
                    alt="remove-prod"
                    className={`inline-block cursor-pointer ${
                      isPast && 'pointer-events-none cursor-not-allowed'
                    }`}
                    onClick={() => {
                      onDeleteProdTeam(fields?.[index]?.['id']);
                      setFieldDelete(true);
                    }}
                    data-tip="Remove"
                  />
                  <ReactTooltip />
                </div>
              </div>
            </Fragment>
          );
        })}
      {toggleState && (
        <div className={`${isPast && 'hidden'}`}>
          <PrimaryLabel
            labelText="+Add another"
            onClick={(e) => onAddProductionTeam(e, true)}
            labelColor="#e89a89"
            className="cursor-pointer underline"
          />
        </div>
      )}
    </div>
  );
};

export default ElementProdTeam;
