import React, { useMemo } from 'react';
import Select, { components } from 'react-select';
import { getImageUrl, getSepratedTime } from '../../../utils';
import { useDispatch } from 'react-redux';
import { timelineTimeFormat } from '../../../constants/index';
import { getClusterBuildings } from '../../../redux/event';

const formatOptionLabel = (option) => {
  return (
    <div key={option.id} className="flex justify-between">
      <div>
        {option.name}
        {option.startDatetime &&
          `(${getSepratedTime(
            option.startDatetime,
            option.regionTimezone,
            timelineTimeFormat
          )})`}
      </div>
      <div>
        {option.clusterId && !option.clusterSelected ? (
          <>
            <img
              src={getImageUrl('Events/add_cluster_icon.svg')}
              height="30px"
              width="30px"
              alt="cluster"
              title="Add Cluster"
              data-for="addCluster"
              onClick={async () => {
                option.singleClick = false;
                await option.selectCluster();
                option.singleClick = true;
                option.clusterSelected = true;
              }}
            />
          </>
        ) : (
          option.clusterId && (
            <p
              className="cluster-added"
              onClick={async () => {
                option.singleClick = false;
                await option.deSelectCluster();
                option.singleClick = true;
                option.clusterSelected = false;
              }}
            >
              Cluster added
            </p>
          )
        )}
      </div>
    </div>
  );
};

const Control = ({ children, ...rest }) => (
  <components.Control {...rest}>
    <img
      src={getImageUrl('Events/searchbuilding.svg')}
      alt="search"
      className="px-2"
    />
    {children}
  </components.Control>
);

const StyledDropdown = (props) => {
  const { eventData } = props;
  const dispatch = useDispatch();
  const newOptions = useMemo(() => {
    const updatedOptions = [];
    if (props.options?.length > 0) {
      props.options.forEach((item) => {
        item['buildings'].forEach((buildingItem, buildingIndex) => {
          updatedOptions.push({
            id: buildingItem.id,
            regionId: item.id,
            regionName: item.regionName,
            name: buildingItem.name,
            clusterId: buildingItem.clusterId,
            checked: false,
            clusterSelected: false,
            singleClick: true,
            startDatetime: eventData.startDatetime,
            index: buildingIndex,
            regionTimezone: item.regionTimezone,
            selectCluster: async () =>
              await dispatch(
                getClusterBuildings(
                  props.eventId,
                  buildingItem.clusterId,
                  props.isRecurring,
                  false,
                  !eventData?.virtual,
                  eventData?.timezone?.id
                    ? eventData?.timezone?.zoneValue
                    : item.regionTimezone
                )
              ),
            deSelectCluster: async () =>
              await dispatch(
                getClusterBuildings(
                  props.eventId,
                  buildingItem.clusterId,
                  props.isRecurring,
                  true,
                  !eventData?.virtual,
                  eventData?.timezone?.id
                    ? eventData?.timezone?.zoneValue
                    : item.regionTimezone
                )
              ),
          });
        });
      });
    }
    return updatedOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.options,
    eventData.startDatetime,
    dispatch,
    props.eventId,
    props.isRecurring,
  ]);

  const styles = {
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? '#ffd3c9' : '#ffffff',
        color: '#3f3f3f;',
        backgroundImage:
          state.isSelected ||
          props.data.findIndex((building) => building.id === state.data.id) > -1
            ? `url(${getImageUrl('Events/searchbuildingselected.svg')})`
            : `url(${getImageUrl('Events/add_building_icon.svg')})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '10px',
        padding: '1.32rem 1.12rem 1rem 3rem',
        cursor: 'pointer',
      };
    },
    placeholder: (provided) => ({
      ...provided,
      color: '#c2c2c2',
    }),
  };

  return (
    <Select
      {...props}
      className="w-full select_field_width"
      isSearchable={true}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Control,
      }}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={(e) => e['name']}
      getOptionValue={(e) => e['name']}
      options={newOptions}
      closeMenuOnSelect={false}
      isMulti={true}
      controlShouldRenderValue={false}
      styles={styles}
      isClearable={false}
      isDisabled={eventData?.isPastEvent}
      dropdownIndicator={false}
      hideSelectedOptions={false}
    />
  );
};

export default StyledDropdown;
