import React, { useState } from 'react';
import {
  Button,
  PrimaryLabel,
  Input,
  Dropdown,
} from '@alfredclub/hom-planner-theme';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ModalPopup from '../ReactModal/Modal';
import { getBuildings } from '../../redux/building';
import './Users.scss';
import { IBuildingState } from '../../redux/building';
interface IFormInput {
  firstName: string;
  lastName: string;
  buildingObj: object;
}
const AddUserPopup: React.FC<any> = ({ modalState, onClosePopup }: any) => {
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<IFormInput>();
  const dispatch = useDispatch();
  const { allBuildings } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);

  // Encrypt the value of popup fields
  const onSubmit = () => {
    push(
      `/data-resources/users/add-user?user=${btoa(
        JSON.stringify(getValues(['buildingObj', 'firstName', 'lastName']))
      )}`
    );
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClosePopup}
      className="add-cluster-modal"
      shouldCloseOnOverlayClick={false}
    >
      <label className="label">Add New User</label>
      <form onSubmit={handleSubmit(onSubmit)}>
        {(errors.firstName || errors.lastName) && (
          <div className="staff-error">Please fill the all fields</div>
        )}
        <PrimaryLabel labelText="First Name" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Input
          name="firstName"
          label=""
          placeholder=""
          className="input-control-space"
          ref={register({ required: true })}
        />

        <PrimaryLabel labelText="Last Name" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Input
          name="lastName"
          label=""
          placeholder=""
          className="input-control-space"
          ref={register({ required: true })}
        />

        <PrimaryLabel labelText="Select Buildings" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Dropdown
          asyncDropdown={false}
          labelKey="name"
          valueKey="id"
          isLabelShow={false}
          label="Building"
          options={allBuildings}
          onMenuOpen={async () => {
            setLoading(true);
            if (allBuildings?.length === 0) {
              await dispatch(getBuildings(0, 0, 'short', '', 1));
            }
            setLoading(false);
          }}
          loading={loading}
          ref={() => register({ name: 'buildingObj' }, { required: false })}
          onOptionChange={(e) => {
            setValue('buildingObj', e);
          }}
          placeholder=""
          sectionClass="control-space"
          isMulti={true}
        />

        <Button label="Continue" isArrowShow={true} />
      </form>
    </ModalPopup>
  );
};

export default AddUserPopup;
