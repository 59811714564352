import {
  addSpacesApi,
  getSpacesApi,
  getSpaceDetailApi,
  updateSpaceApi,
  deleteSpaceApi,
  getEventSpacesListApi,
} from './spaces.service';
import * as spaceTypes from './space.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as toastTypes from '../toast/toast.constant';
import * as formTypes from '../form/form.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import { Space, PaginatedResponse, SpaceArray } from '../../models';
export const getSpaces = (
  offset?,
  page?,
  cmp?,
  query?,
  all?,
  updateReducer = true
) => async (dispatch) => {
  const spacesData: PaginatedResponse<Space> = await getSpacesApi(
    cmp,
    offset,
    page,
    query,
    all
  );
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (spacesData.data && updateReducer) {
    dispatch({
      type: spaceTypes.GET_SPACES,
      payload: spacesData.data,
      spacesCount: spacesData.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else if (spacesData.data && !updateReducer) {
    return spacesData.data;
  }
};

export const searchSpace = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: spaceTypes.SEARCH_SPACES_DATA,
      payload: data,
      spacesCount: data.length,
      page,
    });
  } else {
    await getSpaces(10, page, 'short', query)(dispatch);
  }
};

export const addSpace = (data: SpaceArray) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const newSpacesData: Space = await addSpacesApi(data);

  if (newSpacesData) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.space.added,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return newSpacesData;
  }
};

export const getSpaceDetail = (spaceId: number) => async (dispatch) => {
  const spaceData: Space = await getSpaceDetailApi(spaceId);
  if (spaceData) {
    dispatch({
      type: formTypes.UPDATE_SPACE_FORM,
      payload: spaceData,
    });
  }
};

export const updateSapce = (spaceId: number, data: Space) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedSpaceData: Space = await updateSpaceApi(spaceId, data);

  if (updatedSpaceData) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.space.updated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteSpace = (spaceId: number) => async (dispatch) => {
  const deleteSpaceData = await deleteSpaceApi(spaceId);
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (deleteSpaceData.delete) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.space.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getEventSpaceList = (buildingId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const paginatedResponse: PaginatedResponse<Space> = await getEventSpacesListApi(
    buildingId
  );
  if (paginatedResponse.data) {
    dispatch({
      type: spaceTypes.GET_EVENT_SPACES,
      payload: paginatedResponse.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
export const clearEventSpaces = () => async (dispatch) => {
  dispatch({
    type: spaceTypes.EMPTY_EVENT_SPACES,
  });
};
