import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import IndividualVendors from './IndividualServiceVendors';
import ElementServiceVendors from './ElementServiceVendors';
const ServiceVendors = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [serviceType] = useState(query.get('serviceType'));
  return (
    <div className="vendor-section-service">
      {serviceType === '1' ? <IndividualVendors /> : <ElementServiceVendors />}
    </div>
  );
};
export default ServiceVendors;
