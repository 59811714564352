import * as buildingTypes from './building.constant';
import { IBuildingState, initialBuildingState } from './Building.state';

export const buildingReducer = (
  state: IBuildingState = initialBuildingState,
  action: { type: string; page: number; payload: any; buildingCount: number }
): IBuildingState => {
  switch (action.type) {
    case buildingTypes.GET_BUILDINGS:
    case buildingTypes.SEARCH_BUILDINGS_DATA:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        buildingCount: action.buildingCount,
      };
    case buildingTypes.GET_BUILDING_DETAIL:
      return {
        ...state,
        buildingData: action.payload,
      };
    case buildingTypes.GET_ALL_BUILDINGS:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        allBuildings: action.payload,
      };
    case buildingTypes.GET_ALL_BUILDINGS_MANAGEMENT:
      return {
        ...state,
        allBuildingsByManagement: [...action.payload],
      };
    case buildingTypes.CHECK_BUILDING_CODE:
      return {
        ...state,
        buildingCodeStatus: action.payload.exists,
      };
    case buildingTypes.SAVE_BUILDING_ID:
      return {
        ...state,
        isBuildingId: action.payload,
      };
    case buildingTypes.FETCHED_BUILDING_DETAIL:
      return {
        ...state,
        isBuildingDetailFetched: action.payload,
      };
    default:
      return state;
  }
};
