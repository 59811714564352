import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AdminChat from './components/Events/AdminChat/AdminChat';
import Layout from './components/Layout/Layout';

export const App: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isChatWindow = query.get('eventId') ? true : false;
  return !isChatWindow ? <Layout history={history} /> : <AdminChat />;
};

export default App;
