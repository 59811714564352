import {
  getInventoryGroupsApi,
  removeItemsToInventoryGroupsApi,
  getInventoryItemsApi,
  updateInventoryItemApi,
  deleteInventoryItemsApi,
  addItemsToInventoryGroupsApi,
  updateInventoryGroupApi,
  deleteInventoryGroupsApi,
  createInventoryGroupsApi,
  createInventoryItemsApi,
} from './inventory.service';
import * as loadingTypes from '../loader/loader.constant';
import * as inventoryTypes from './inventory.constant';
import * as toastTypes from '../toast/toast.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import { PaginatedResponse, Inventory, DeleteResponse } from '../../models';
import { Item } from '../../models/item';

export const getInventoryGroups = (
  query?: string,
  all?: number,
  updateReducer = true,
  id?: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const inventoryGroupsData: PaginatedResponse<Inventory> = await getInventoryGroupsApi(
    query,
    all,
    id
  );
  if (inventoryGroupsData.data && updateReducer) {
    dispatch({
      type: inventoryTypes.GET_INVENTORY_GROUPS,
      payload: inventoryGroupsData.data,
      count: inventoryGroupsData.total,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return inventoryGroupsData.data;
  } else if (inventoryGroupsData.data && !updateReducer) {
    return inventoryGroupsData.data;
  }
};

export const getInventoryItems = (
  offset?,
  page?,
  cmp?,
  query?,
  all?,
  updateReducer = true,
  id?
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const inventoryItems: PaginatedResponse<Item> = await getInventoryItemsApi(
    cmp,
    offset,
    page,
    query,
    all,
    id
  );
  if (inventoryItems.data && updateReducer) {
    dispatch({
      type: inventoryTypes.GET_INVENTORY_ITEMS_LIST,
      payload: inventoryItems.data,
      count: inventoryItems.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return inventoryItems.data;
  } else if (inventoryItems.data && !updateReducer) {
    return inventoryItems.data;
  }
};

export const deleteGroupInventoryItems = (
  groupId: number,
  itemId: number,
  itemIndex: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const deletedRecord = await removeItemsToInventoryGroupsApi(groupId, {
    itemId,
  });
  if (deletedRecord.itemId === itemId) {
    dispatch({
      type: inventoryTypes.DELETE_INVENTORY_GROUPS_ITEMS,
      id: itemId,
      index: itemIndex,
    });
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.inventory.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteInventoryItems = (page: number, itemId: number) => async (
  dispatch
) => {
  const deletedRecord: DeleteResponse = await deleteInventoryItemsApi(itemId);
  if (deletedRecord.delete) {
    dispatch({
      type: inventoryTypes.DELETE_INVENTORY_ITEMS,
      id: itemId,
      page,
    });
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.inventory.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteInventoryGroup = (groupId: number) => async (dispatch) => {
  const deletedRecord: DeleteResponse = await deleteInventoryGroupsApi(groupId);
  if (deletedRecord.delete) {
    dispatch({
      type: inventoryTypes.DELETE_INVENTORY_GROUP,
      id: groupId,
    });
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.inventory.groupDeleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const searchInventory = (data, query) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: inventoryTypes.SEARCH_INVENTORY_GROUPS,
      payload: data,
      count: data.length,
    });
  } else {
    await getInventoryGroups(query)(dispatch);
  }
};

export const updateInventoryItem = (itemId: number, data: object) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedItem: Item = await updateInventoryItemApi(itemId, data);
  if (updatedItem) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.inventory.itemLocationUpdate,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const updateInventoryGroup = (
  groupId: number,
  data: object,
  groupIndex: number
) => async (dispatch) => {
  const updatedGroup: Inventory = await updateInventoryGroupApi(groupId, data);
  if (updatedGroup) {
    dispatch({
      type: inventoryTypes.UPDATE_INVENTORY_GROUP,
      index: groupIndex,
      data,
    });
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.inventory.groupUpdated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const updateItemLocation = (
  pageNo: number,
  index: number,
  location
) => async (dispatch) => {
  dispatch({
    type: inventoryTypes.UPDATE_INVENTORY_ITEM_LOCATION,
    pageNo,
    index,
    location,
  });
};

export const updateGroupItemLocation = (groupIndex, index, location) => async (
  dispatch
) => {
  dispatch({
    type: inventoryTypes.UPDATE_INVENTORY_GROUP_ITEM_LOCATION,
    groupIndex,
    index,
    location,
  });
};

export const searchInventoryItems = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: inventoryTypes.SEARCH_INVENTORY_ITEMS,
      payload: data,
      count: data.length,
      page,
    });
  } else {
    await getInventoryItems(10, page, 'short', query)(dispatch);
  }
};

export const createInventoryItems = (data: object) => async (dispatch) => {
  const itemsAdded: PaginatedResponse<Item> = await createInventoryItemsApi(
    data
  );
  if (itemsAdded.items) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.inventory.added,
    });
  }
};

export const createInventoryGroups = (data: object) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const groupAdded: PaginatedResponse<Inventory> = await createInventoryGroupsApi(
    data
  );
  if (groupAdded.items) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.inventory.groupAdded,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
export const addItemsToInventoryGroups = (
  groupId: number,
  data: object
) => async (dispatch) => {
  const itemAddedToGroup: PaginatedResponse<Item> = await addItemsToInventoryGroupsApi(
    groupId,
    data
  );
  if (itemAddedToGroup.items) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.inventory.added,
    });
  }
};
