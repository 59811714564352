import * as staffTypes from './staff.constant';
import { initialStaffState, IStaffState } from './staff.state';

export const staffReducer = (
  state: IStaffState = initialStaffState,
  action: { type: any; page: any; payload: any; staffCount: number }
): IStaffState => {
  switch (action.type) {
    case staffTypes.GET_STAFF:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        staffCount: action.staffCount,
      };
    case staffTypes.SEARCH_STAFF_DATA:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        staffCount: action.staffCount,
      };
    case staffTypes.GET_STAFF_DETAIL:
      return {
        ...state,
        staffData: action.payload,
      };
    default:
      return state;
  }
};
