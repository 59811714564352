export const GET_SERVICES = 'GET_SERVICES';
export const SEARCH_SERVICES_DATA = 'SEARCH_SERVICES_DATA';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SETUP_SERVICES = 'SETUP_SERVICES';
export const GET_ALL_SERVICES = 'GET_ALL_SERVICES';
export const GET_SERVICE_DETAIL = 'GET_SERVICE_DETAIL';
export const GET_SERVICE_VENDORS = 'GET_SERVICE_VENDORS';
export const SET_SERVICES_PRICE = 'SET_SERVICES_PRICE';
export const GET_SERVICE_VENDORS_LIST = 'GET_SERVICE_VENDORS_LIST';
export const GET_MULTIPLE_SERVICE_VENDORS_LIST =
  'GET_MULTIPLE_SERVICE_VENDORS_LIST';
export const REMOVE_UPLOADED_IMAGE = 'REMOVE_UPLOADED_IMAGE';
export const GET_SERVICE_DOCUMENTS = 'GET_SERVICE_DOCUMENTS';
export const PACKAGE_ELEMENT_LIST = 'PACKAGE_ELEMENT_LIST';
export const CLEAR_SERVICE_SINGLE_TASK = 'CLEAR_SERVICE_SINGLE_TASK';
export const EDIT_SERVICE_TASK = 'EDIT_SERVICE_TASK';
export const GET_SERVICE_SINGLE_TASK = 'GET_SERVICE_SINGLE_TASK';
export const GET_SINGLE_PRICE_DETAIL = 'GET_SINGLE_PRICE_DETAIL';
export const EDIT_SERVICE_PRICING = 'EDIT_SERVICE_PRICING';
export const CLEAR_SINGLE_PRICE_DETAIL = 'CLEAR_SINGLE_PRICE_DETAIL';
export const GET_CATEGORY_TAGS = 'GET_CATEGORY_TAGS';
export const GET_SERVICE_CATEGORY_TAGS = 'GET_SERVICE_CATEGORY_TAGS';
export const CLEAR_CATEGORY_TAGS = 'CLEAR_CATEGORY_TAGS';
export const CLEAR_SERVICE_CATEGORY_TAGS = 'CLEAR_SERVICE_CATEGORY_TAGS';
export const GET_PRODUCTION_TEAM = 'GET_PRODUCTION_TEAM';
export const IMPORT_TASK_TO_SERVICE = 'IMPORT_TASK_TO_SERVICE';
export const UPDATE_SERVICE_TASK_GROUP_SELECTED =
  'UPDATE_SERVICE_TASK_GROUP_SELECTED';
export const SERVICE_VENDOR_LIST = 'SERVICE_VENDOR_LIST';
export const CLEAR_SERVICE_TASK_GROUP_LIST = 'CLEAR_SERVICE_TASK_GROUP_LIST';
export const CLEAR_SERVICE_IMPORTED_TASK_STATE =
  'CLEAR_SERVICE_IMPORTED_TASK_STATE';
export const CLEAR_ALL_SERVICES = 'CLEAR_ALL_SERVICES';
export const FETCH_SERVICE_DETAIL = 'FETCH_SERVICE_DETAIL';
