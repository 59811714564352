import { getRegionsApi, getTimezoneApi } from './regions.service';
import * as regionTypes from './region.constant';
import { PaginatedResponse, Region, Timezone } from '../../models';

export const getRegions = (limit?, page?, cmp?, all?) => async (dispatch) => {
  const paginatedResponse: PaginatedResponse<Region> = await getRegionsApi(
    cmp,
    limit,
    page,
    all
  );
  if (paginatedResponse.data) {
    dispatch({
      type: regionTypes.GET_REGIONS,
      payload: paginatedResponse.data,
      regionCount: paginatedResponse.total,
    });
  }
};

export const getTimezone = () => async (dispatch) => {
  const paginatedResponse: PaginatedResponse<Timezone> = await getTimezoneApi();
  if (paginatedResponse.data) {
    dispatch({
      type: regionTypes.GET_TIMEZONE,
      payload: paginatedResponse.data,
      timezoneCount: paginatedResponse.total,
    });
  }
};
