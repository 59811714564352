import React, { useState } from 'react';
import { Input, RadioGroup, Dropdown } from '@alfredclub/hom-planner-theme';
import {
  vendorCostRangeFilter,
  workAssociation,
} from '../../../constants/index';
import { useDefaultValueRadioGroup } from '../../../hooks';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import { Register, SetValue, Vendor } from '../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryTags } from '../../../redux/service/services.action';
import { updateVendorIndividualForm } from '../../../redux/form';
import { IServiceState } from '../../../redux/service';
import { selectedTagColor, selectedTagTextColor } from '../../../constants';

const WorkDetails: React.FC<{
  register: Register;
  setValue: SetValue;
  vendorData: Vendor;
  isEdit: boolean;
  formErrors: Record<string, object>;
}> = ({ register, setValue, vendorData, isEdit, formErrors }) => {
  const dispatch = useDispatch();

  const [listOfWorkAssociation, setListOfWorkAssociation] = useState(
    workAssociation
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { categoryTagsList } = useSelector(
    (state: { services: IServiceState }) => state.services
  );

  useDefaultValueRadioGroup(
    vendorData,
    'association',
    listOfWorkAssociation,
    setListOfWorkAssociation,
    isEdit
  );
  const onChange = (e, index) => {
    const updatedOptions = listOfWorkAssociation.filter((item, itemIndex) => {
      if (itemIndex !== index) {
        item.checked = false;
      } else {
        listOfWorkAssociation[index].checked = e.currentTarget.checked;
      }
      return listOfWorkAssociation;
    });
    setValue('association', listOfWorkAssociation[index].value);
    setListOfWorkAssociation([...updatedOptions]);
  };
  const selectedVendorCost = vendorCostRangeFilter.filter(
    (data) => data.value === vendorData?.['vendorCostRange']
  );
  return (
    <>
      <FormTitle title="Work Details" />
      <div className="grid grid-cols-1 gap-x-2 gap-y-0">
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={true}
            isMulti={true}
            label="Category tag"
            options={categoryTagsList}
            onMenuOpen={async () => {
              if (categoryTagsList?.length === 0) {
                setLoading(true);
                await dispatch(getCategoryTags());
                setLoading(false);
              }
            }}
            ref={() => register({ name: 'categoryTags' })}
            onOptionChange={async (e) => {
              setValue('categoryTags', e === null ? [] : e);
              await dispatch(
                updateVendorIndividualForm(e === null ? [] : e, 'categoryTags')
              );
            }}
            placeholder="Select"
            value={vendorData && vendorData?.['categoryTags']}
            className="service_input_width categoryTags"
            backgroundColor={selectedTagColor}
            textColor={selectedTagTextColor}
            loading={loading}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          <RadioGroup
            options={listOfWorkAssociation}
            label="Association"
            isLabelShow={true}
            ref={register({ name: 'association' }, { required: true })}
            onChange={(e, index) => onChange(e, index)}
            isError={formErrors?.['association'] ? true : false}
            className="input-height"
            textFieldClassName="input-height"
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Date of Sub Hire"
            ref={() => register({ name: 'dateOfSubHire' })}
            onDateChange={(date) => setValue('dateOfSubHire', date)}
            showDatepicker={true}
            placeholder="Select Date"
            selectedDate={
              vendorData?.['dateOfSubHire']
                ? new Date(vendorData?.['dateOfSubHire'])
                : new Date()
            }
            labelClass=""
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Date of Full time hire"
            ref={() => register({ name: 'dateOfFtHire' })}
            onDateChange={(date) => setValue('dateOfFtHire', date)}
            showDatepicker={true}
            placeholder="Select Date"
            selectedDate={
              vendorData?.['dateOfFtHire']
                ? new Date(vendorData?.['dateOfFtHire'])
                : new Date()
            }
            labelClass=""
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Slack Contact link"
            name="slackContactLink"
            ref={register}
            onInputBlur={async (e) =>
              await dispatch(
                updateVendorIndividualForm(e.target.value, 'slackContactLink')
              )
            }
          />
        </div>
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="costRange"
            valueKey="value"
            isLabelShow={true}
            isMulti={false}
            label="Vendor cost range"
            options={vendorCostRangeFilter}
            ref={() => register({ name: 'vendorCostRange' })}
            onOptionChange={async (e) => {
              setValue('vendorCostRange', e?.['value']);
              await dispatch(
                updateVendorIndividualForm(e?.['value'], 'vendorCostRange')
              );
            }}
            placeholder="Select"
            value={selectedVendorCost}
            loading={loading}
            isSearchable={false}
          />
        </div>
      </div>
    </>
  );
};

export default WorkDetails;
