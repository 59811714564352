import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  ToggleButton,
  Topnav,
  Loader,
} from '@alfredclub/hom-planner-theme';
import {
  addBuildingBlocks,
  updateBuildingBlock,
  saveBuildingBlocksLocaly,
  getBuildingBlocks,
} from '../../../../redux/buildingblocks/buildingblocks.action';
import { useHistory, useLocation } from 'react-router-dom';
import {
  updateUnitCount,
  updatecompanyForminUnits,
} from '../../../../redux/form/form.action';
import CommonNavigation from '../../../Common/CommonNavigation';
import { IBlocksState } from '../../../../redux/buildingblocks/buildingblocks.state';
import { ILoaderState } from '../../../../redux/loader/loader.state';
import { CompanyRequest } from '../../../../models';

const AddCompanyForm: React.FC = () => {
  const {
    buildingBlockArray,
    buildingId,
    editBlock,
    buildingName,
    buildingType,
    blockId,
  } = useSelector((state: { company: IBlocksState }) => state.company);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [isEdit] = useState(query.get('isEdit'));
  const [buildingIdParam] = useState(query.get('buildingId'));
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
  } = useForm<CompanyRequest>({
    defaultValues: {
      blocks: buildingBlockArray,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: 'blocks',
  });
  const [companyBlock, setCompanyBlock] = useState({
    blocks: buildingBlockArray,
  });
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const saveData = (value: any, index: number, fieldValue: string) => {
    const newBlockArray = [...companyBlock.blocks];
    newBlockArray[index][fieldValue] = value;
    setCompanyBlock((previousState) => {
      return { ...previousState, blocks: newBlockArray };
    });
  };

  const onSubmit = async () => {
    if (editBlock) {
      const updatedBlockData = companyBlock.blocks[0];
      await dispatch(updateBuildingBlock(blockId, updatedBlockData));
      history.push(
        `/data-resources/clients/companies-units/?buildingId=${buildingId}`
      );
    } else {
      if (isEdit === 'true') {
        await dispatch(
          addBuildingBlocks(Number(buildingIdParam), companyBlock.blocks)
        );
        const buildingBlocks = await dispatch(
          buildingType === 'C'
            ? getBuildingBlocks(Number(buildingIdParam))
            : getBuildingBlocks(Number(buildingIdParam), 0, 0, 'short', '', 1)
        );
        if (buildingBlocks?.length) {
          await dispatch(updateUnitCount(buildingBlocks?.length));
        }
        history.push(`/data-resources/clients/buildings/${buildingIdParam}`);
      } else {
        if (buildingId) {
          await dispatch(
            addBuildingBlocks(Number(buildingId), companyBlock.blocks)
          );
          history.push(
            `/data-resources/clients/companies-units/?buildingId=${buildingId}`
          );
        } else {
          const building_type = buildingType;
          const building_name = buildingName;

          const buildingBlockLocalObj = {
            newBlockArray: {
              newBlockArray: companyBlock.blocks,
            },
            buildingType: building_type,
            buildingName: building_name,
            editBlock: false,
            blockId: null,
          };
          await dispatch(updateUnitCount(companyBlock.blocks.length));
          await dispatch(updatecompanyForminUnits(companyBlock.blocks));
          await dispatch(saveBuildingBlocksLocaly(buildingBlockLocalObj));
          history.push(
            `/data-resources/clients/buildings/add-building?buildingName=${buildingName}`
          );
        }
      }
    }
  };
  const validateEmailHr = (data: any, value1: string, value2: string) => {
    if (!data?.[value1] && !data?.[value2]) {
      return false;
    } else if (data?.[value1] && !data?.[value2]) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (companyBlock) {
      reset({
        blocks: companyBlock.blocks,
      });
    }
  }, [companyBlock, reset]);
  const deleteCompany = (index: number) => {
    const newBlockArray = [...companyBlock.blocks];
    newBlockArray.splice(index, 1);
    setCompanyBlock((previousState) => {
      return { ...previousState, blocks: newBlockArray };
    });
  };
  return (
    <>
      <Topnav headerTitle={`${editBlock ? 'Edit Company' : 'Add Companies'}`} />
      <div className="mx-10 add-block-section mt-3">
        {!editBlock && !buildingId && (
          <CommonNavigation
            linkTo={
              isEdit
                ? `/data-resources/clients/buildings/${buildingIdParam}`
                : `/data-resources/clients/buildings/add-building?buildingName=${buildingName}`
            }
            header={`${
              isEdit === 'true' ? 'Edit Building' : 'Add new building'
            }`}
            subHeader="Companies/Units"
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Button
            label={`Save ${buildingId ? 'changes' : 'and go back'}`}
            className={`${
              loading ? 'button_disable save-changes' : 'save-changes'
            }`}
            disabled={loading}
          />
          <div className="mt-16 inline-flex" />
          {loading && (
            <Loader
              color="#e89a89"
              position="center"
              loading={true}
              size="0.75rem"
            />
          )}
          {errors?.['blocks'] && (
            <span className="company_error">
              Please fill fields marked below*
            </span>
          )}
          {fields?.length > 0 &&
            fields.map((data: any, i: number) => {
              return (
                <div key={i} className="flex flex-col">
                  {fields?.length > 1 && (
                    <div className="flex justify-end delete-company">
                      <span onClick={() => deleteCompany(i)}>
                        Delete company
                      </span>
                    </div>
                  )}
                  <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
                    <div>
                      <Input
                        isLabelShow={true}
                        label="Company name"
                        name={`blocks[${i}].name`}
                        onInputBlur={(e) => {
                          saveData(e.currentTarget.value, i, 'name');
                        }}
                        value={data?.name ? data.name : ''}
                        isError={
                          errors?.['blocks']?.[i]?.['name'] ? true : false
                        }
                        ref={register({
                          required: true,
                        })}
                        isMandatory={true}
                      />
                    </div>
                    <div>
                      <Input
                        isLabelShow={true}
                        label="Number of Employees"
                        name={`blocks[${i}].noOfEmployees`}
                        value={data?.noOfEmployees ? data.noOfEmployees : ''}
                        onInputBlur={(e) => {
                          saveData(
                            parseInt(e.currentTarget.value, 10),
                            i,
                            'noOfEmployees'
                          );
                        }}
                        ref={register}
                      />
                    </div>
                    <div>
                      <Input
                        isLabelShow={true}
                        label="HR Manager name"
                        name={`blocks[${i}].hrManagerName`}
                        value={data?.hrManagerName ? data.hrManagerName : ''}
                        onInputBlur={(e) => {
                          saveData(e.currentTarget.value, i, 'hrManagerName');
                        }}
                        ref={register({
                          required: validateEmailHr(
                            data,
                            'email',
                            'hrManagerName'
                          ),
                        })}
                        isError={
                          errors?.['blocks']?.[i]?.['hrManagerName']
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div>
                      <Input
                        isLabelShow={true}
                        label="Email Address"
                        value={data?.email ? data.email : ''}
                        name={`blocks[${i}].email`}
                        onInputBlur={(e) => {
                          saveData(e.currentTarget.value, i, 'email');
                        }}
                        ref={register({
                          required: validateEmailHr(
                            data,
                            'hrManagerName',
                            'email'
                          ),
                        })}
                        isError={
                          errors?.['blocks']?.[i]?.['email'] ? true : false
                        }
                      />
                    </div>
                    <div>
                      <Input
                        isLabelShow={true}
                        label="Contact number"
                        value={data?.contact ? data.contact : ''}
                        name={`blocks[${i}].contact`}
                        onInputBlur={(e) => {
                          saveData(e.currentTarget.value, i, 'contact');
                        }}
                        disabled={data?.hrManagerName ? false : true}
                        ref={register}
                      />
                    </div>
                    <div>
                      <ToggleButton
                        label="Dashboard Access"
                        onChange={(e) =>
                          saveData(
                            e.currentTarget.checked,
                            i,
                            'dashboardAccess'
                          )
                        }
                        isLabelShow={data?.dashboardAccess ? true : false}
                        checked={data?.dashboardAccess ? true : false}
                        checkedText="Yes"
                        name={`blocks[${i}].dashboardAccess`}
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </form>
      </div>
    </>
  );
};

export default AddCompanyForm;
