import React, { useState } from 'react';
import { Dropdown, Input, ToggleButton } from '@alfredclub/hom-planner-theme';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from '../../../redux/region/regions.action';
import { useHistory } from 'react-router-dom';
import { getServices } from '../../../redux/service/services.action';
import { Register, SetValue } from '../../../models/commonTypes';
import { ILoaderState } from '../../../redux/loader/loader.state';
import { IRegionState } from '../../../redux/region/region.state';
import { IServiceState } from '../../../redux/service/service.state';
import { Vendor } from '../../../models';
import { updateVendorServices } from '../../../redux/form';

const OnlineServices: React.FC<{
  register: Register;
  setValue: SetValue;
  vendorData: Vendor;
  vendorName: string;
  vendorId: number;
  isEdit: boolean;
  isVendorContact: boolean;
  vendorContact: string;
}> = ({
  vendorData,
  register,
  setValue,
  vendorName,
  isEdit,
  vendorId,
  isVendorContact,
  vendorContact,
}) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { regions } = useSelector(
    (state: { regions: IRegionState }) => state.regions
  );
  const { allServices } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const [checked, setChecked] = useState(
    isEdit ? vendorData?.['onlineServices']?.['availability'] : false
  );

  const addAssociatedBuilding = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (isEdit) {
      push(
        `/data-resources/vendors/add-building?service=online&vendorName=${vendorName}&edit=true&vendorId=${vendorId}`
      );
      return;
    }
    push(
      `/data-resources/vendors/add-building?service=online&vendorName=${vendorName}${
        isVendorContact
          ? `&isContact=${isVendorContact}&vendorContact=${vendorContact}`
          : ``
      }`
    );
  };

  return (
    <>
      <FormTitle title="Virtual Services" />
      <div className="grid grid-cols-1 gap-x-2 gap-y-0">
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={true}
            isMulti={true}
            label="Services"
            options={allServices}
            onMenuOpen={async () => {
              if (allServices.length === 0) {
                await dispatch(
                  getServices(0, 0, 'short', '', 1, true, '', true)
                );
              }
            }}
            loading={loading}
            ref={() => register({ name: 'onlineServices.services' })}
            onOptionChange={async (e) => {
              setValue('onlineServices.services', e);
              await dispatch(
                updateVendorServices(e, 'onlineServices', 'services')
              );
            }}
            placeholder="Select"
            value={vendorData && vendorData?.['onlineServices']?.['services']}
            className="service_input_width"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          <div>
            <ToggleButton
              label="Virtual Services"
              onChange={(e) => setChecked(e.currentTarget.checked)}
              isLabelShow={checked}
              checked={checked}
              checkedText="Active"
              name="onlineServices.availability"
              ref={register}
            />
          </div>
          <div>
            <Input
              isLabelShow={true}
              label="Associated Buildings"
              isButton={true}
              inputSectionClass="my-2"
              labelClass="associated_building"
              onButtonClick={(e) => addAssociatedBuilding(e)}
              value={`${
                vendorData &&
                Object.keys(vendorData?.['onlineServices']).length > 0
                  ? vendorData?.['onlineServices']?.['buildings']?.length
                  : 0
              } buildings`}
            />
          </div>
        </div>
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="regionName"
            valueKey="id"
            isLabelShow={true}
            label="Market"
            options={regions}
            sectionClass="region-field"
            onMenuOpen={async () => {
              if (regions.length === 0) {
                await dispatch(getRegions(0, 0, 'short', 1));
              }
            }}
            loading={loading}
            ref={() => register({ name: 'onlineServices.serviceRegions' })}
            onOptionChange={async (e) => {
              setValue('onlineServices.serviceRegions', e);
              await dispatch(
                updateVendorServices(e, 'onlineServices', 'serviceRegions')
              );
            }}
            isMulti={true}
            value={
              vendorData && vendorData?.['onlineServices']?.['serviceRegions']
            }
          />
        </div>
      </div>
    </>
  );
};

export default OnlineServices;
