import React, { useState, useEffect } from 'react';
import CommonLayout from '../../CommonLayout/CommonLayout';
import { useSelector } from 'react-redux';
import { Loader } from '@alfredclub/hom-planner-theme';
import {
  getManagementDetail,
  getManagements,
} from '../../../redux/management/management.action';
import { useFetch, usePagination } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TOOLTIP_TEXT } from '../../../constants/index';
import { getSerialNumber } from '../../../utils';
import { IManagementState } from '../../../redux/management';

const ManagementListing = () => {
  const { status } = useFetch(getManagements);
  const history = useHistory();
  const { pages, managementCount } = useSelector(
    (state: { managements: IManagementState }) => state.managements
  );
  const dispatch = useDispatch();

  const [pageNo, setPage] = useState(0);
  const pagination = usePagination();

  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);

  const onPageClick = async (page: number) => {
    await pagination(pages, page - 1, getManagements);
    setPage(page - 1);
  };
  let timer = null;
  const onSearch = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getManagements(10, 0, 'short', inputValue, 1, false)
          );
          res(data);
        }, 1000))
    );
  };
  const onAddManagement = () => {
    history.push('/data-resources/clients/management/add-management');
  };

  const handleOnCellClick = async (e: any) => {
    setLoadingState(true);
    setRowState(e.row.values.id);
    await dispatch(getManagementDetail(e.row.values.id));
    history.push(`management/${e.row.values.id}`);
  };
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  return (
    <div>
      {status === 'fetched' ? (
        <CommonLayout
          headerTitle="Management"
          showIcon={true}
          showTable={true}
          navIconToolTipText={TOOLTIP_TEXT.managements.new}
          showSearchBar={true}
          showSortButton={true}
          tableColumnsData={pages?.get(pageNo)?.data}
          tableColumnsHeaders={[
            {
              Header: 'Sno',
              accessor: 'id',
              Cell: (row: any) => {
                return <div>{getSerialNumber(pageNo, row.row.index)}</div>;
              },
            },
            {
              Header: 'Management Company',
              className: 'tableAnchor', // use this class for highlight the column value
              accessor: (row: any) => (
                <>
                  {row.companyName}
                  <Loader
                    color="#e89a89"
                    position="center"
                    loading={showLoader && setRowClick === row.id}
                    size="0.75rem"
                    className={`${
                      showLoader && setRowClick === row.id && 'row_loader'
                    }`}
                  />
                </>
              ),
            },
            {
              Header: 'No. of Clusters',
              accessor: 'clusterCount',
            },
            {
              Header: 'No. of Bldg',
              accessor: 'buildingCount',
            },
          ]}
          showPagination={!(managementCount < 10) && true}
          recordsCount={managementCount}
          onPageChange={(pageNumber) => onPageClick(pageNumber)}
          promiseOptions={onSearch}
          optionLabel="companyName"
          optionKey="id"
          onCellClick={(e) => handleOnCellClick(e)}
          onClick={onAddManagement}
          onSearchOptionSelect={async (option) => {
            if (option) {
              setLoadingState(true);
              setRowState(option.id);
              await dispatch(getManagementDetail(option.id));
              history.push(`management/${option.id}`);
            }
          }}
          placeholder="Search by management"
        />
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
    </div>
  );
};
export default ManagementListing;
