import React from 'react';
import { TextArea } from '@alfredclub/hom-planner-theme';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import { Register } from '../../../models';
import { updateVendorIndividualForm } from '../../../redux/form';
import { useDispatch } from 'react-redux';

const VendorBio: React.FC<{
  register: Register;
}> = ({ register }) => {
  const dispatch = useDispatch();
  return (
    <>
      <FormTitle title="Vendor Details" />
      <div className="grid grid-cols-1 gap-x-2 gap-y-0 pb-8">
        <div>
          <TextArea
            isLabelShow={true}
            showSubLabel={true}
            subLabelName="(visible to users)"
            label="Vendor Bio"
            name="vendorBio"
            placeholder="Type here.."
            ref={register}
            labelClass="w-48"
            onTextAreaChange={async (e) =>
              await dispatch(
                updateVendorIndividualForm(e.target.value, 'vendorBio')
              )
            }
          />
        </div>
        <div>
          <TextArea
            isLabelShow={true}
            showSubLabel={true}
            subLabelName="(for admin use)"
            label="Vendor Notes"
            name="notes"
            placeholder="Type here.."
            ref={register}
            labelClass="w-48"
            onTextAreaChange={async (e) =>
              await dispatch(
                updateVendorIndividualForm(e.target.value, 'notes')
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default VendorBio;
