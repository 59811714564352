export interface IModalState {
  openBroadcast: boolean;
  openRegion: boolean;
  resetModal: boolean;
}

export const initialModalState: IModalState = {
  openBroadcast: false,
  openRegion: false,
  resetModal: false,
};
