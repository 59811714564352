import React, { useState, useEffect } from 'react';
import { Input, RadioGroup } from '@alfredclub/hom-planner-theme';
import { useHistory } from 'react-router-dom';
import { typesOfProperty } from '../../../../constants/index';
import FormTitle from './FormTitle';
import { useSelector } from 'react-redux';
import { useDefaultValueRadioGroup } from '../../../../hooks';
import BuildingAddCompanies from '../../Companies/AddNewCompany/BuildingAddCompanies';
import { Register, SetValue } from '../../../../models';
import { Building } from '../../../../models/building';
import { IBlocksState } from '../../../../redux/buildingblocks';

const TypesOfProperty: React.FC<{
  register: Register;
  setValue: SetValue;
  buildingData: Building;
  isEdit: boolean;
  buildingId: number;
  buildingName: string;
  formErrors: Record<string, object>;
}> = ({
  register,
  setValue,
  buildingData,
  isEdit,
  buildingId,
  buildingName,
  formErrors,
}) => {
  const history = useHistory();
  const [listOfPropertyType, setListOfPropertyType] = useState(typesOfProperty);
  const [openAddUnitModal, setAddUnitModal] = useState(false);
  const [getBlockCount, setBlockCount] = useState(buildingData?.blockCount);
  const [getbuildingType, setBuildingType] = useState(
    buildingData?.propertyType
      ? buildingData.propertyType
      : listOfPropertyType[0].value
  );
  const { buildingBlockArray = [] } = useSelector(
    (state: { company: IBlocksState }) => state.company
  );
  useDefaultValueRadioGroup(
    buildingData,
    'propertyType',
    listOfPropertyType,
    setListOfPropertyType,
    isEdit
  );
  useEffect(() => {
    if (!isEdit) {
      const updatedOptions = listOfPropertyType.filter((item) => {
        if (buildingData?.['propertyType'] === item.value) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return listOfPropertyType;
      });
      setListOfPropertyType([...updatedOptions]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingData]);
  const onChange = (e, index) => {
    const updatedOptions = listOfPropertyType.filter((item, itemIndex) => {
      if (itemIndex !== index) {
        item.checked = false;
      } else {
        listOfPropertyType[index].checked = e.currentTarget.checked;
      }
      return listOfPropertyType;
    });
    setBuildingType(listOfPropertyType[index].value);
    setValue('propertyType', listOfPropertyType[index].value);
    setListOfPropertyType([...updatedOptions]);
  };
  useEffect(() => {
    if (buildingBlockArray?.length) {
      setBlockCount(buildingBlockArray.length);
    }
    if (buildingData?.blockCount) {
      setBlockCount(buildingData?.blockCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingBlockArray, buildingData]);
  const openBuilding = () => {
    if (buildingId && getBlockCount) {
      history.push(
        `/data-resources/clients/companies-units?buildingId=${buildingId}&addBuilding=${true}&building_name=${buildingName}`
      );
    } else {
      setAddUnitModal(true);
    }
  };
  const onCloseUnitPopup = async () => {
    setAddUnitModal(false);
  };
  const buildingDataObj = {
    building: {
      name: buildingName,
      buildingType: getbuildingType,
      buildingMode: isEdit,
      id: buildingId ? buildingId : null,
    },
    buildingType: getbuildingType,
  };
  return (
    <>
      <FormTitle title="Types of Property" />
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          <RadioGroup
            isLabelShow={true}
            options={listOfPropertyType}
            label="Type of Property"
            ref={() => register({ name: 'propertyType' }, { required: true })}
            isError={formErrors['propertyType'] ? true : false}
            onChange={(e, index) => onChange(e, index)}
            // isDisabled={buildingId && getBlockCount ? true : false}
            isMandatory={true}
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Units/Companies"
            ref={register}
            onButtonClick={openBuilding}
            isButton={true}
            inputType="text"
            isAddUnits={true}
            buttonText={`${
              buildingId && getBlockCount
                ? 'View'
                : getbuildingType === 'R'
                ? 'Add Units'
                : 'Add Company'
            }`}
            className={`${buildingId && getBlockCount && 'justify-end'}`}
            buttonType="button"
            disabled={true}
            value={
              getBlockCount > 0
                ? `${getBlockCount} ${
                    getbuildingType === 'R' ? 'Units' : 'Companies'
                  }`
                : ''
            }
            labelClass="unit_company_label"
          />
        </div>
        {openAddUnitModal && (
          <BuildingAddCompanies
            modalState={openAddUnitModal}
            onClosePopup={onCloseUnitPopup}
            buildingBlockData={buildingDataObj}
            addUnitForm={false}
            CommonLayoutCheck={false}
          />
        )}
      </div>
    </>
  );
};

export default TypesOfProperty;
