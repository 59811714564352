import React, { useState } from 'react';
import { Topnav } from '@alfredclub/hom-planner-theme';
import CommonTabCompponent from '../Common/CommonTabComponent';
import { useSelector, useDispatch } from 'react-redux';
import {
  emptyUserIds,
  getStaffUsers,
  getUserIds,
  getUsers,
  getVendorUsers,
  sendEmail,
} from '../../redux/account/account.action';
import Footer from '../Common/Footer';
import { emailDropDownOptions } from '../../constants';
import { EmailDropDown } from '../../interfaces';
import { IAccountsState } from '../../redux/account/account.state';
import { ILoaderState } from '../../redux/loader/loader.state';
import Users from './Users';
import Vendors from './Vendors';
import Staff from './Staff';

const Accounts = () => {
  const dispatch = useDispatch();
  const [option, setOption] = useState(null);
  const [defaultValue, setValue] = useState<string | object>('');
  const [showCancelIcon, setCancelIcon] = useState<boolean>(false);
  const [searchLoading, setLoading] = useState<boolean>(false);
  const { userIds } = useSelector(
    (state: { accounts: IAccountsState }) => state.accounts
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const onDropdown = (e: EmailDropDown) => {
    setOption(e.value);
  };
  const onRowCheck = async (id: number) => {
    await dispatch(getUserIds(id));
  };
  const queryParams = new URLSearchParams(document.location.search);
  const tab = queryParams.get('tab');
  const fetchAccountData = async (
    dataId: number,
    query: string,
    all: number,
    updateReducer: boolean
  ) => {
    setLoading(true);
    await dispatch(
      tab === 'users'
        ? getUsers(10, 0, 'short', query, all, dataId, updateReducer)
        : tab === 'vendors'
        ? getVendorUsers(10, 0, 'short', query, all, dataId, updateReducer)
        : getStaffUsers(10, 0, 'short', query, all, dataId, updateReducer)
    );
    setLoading(false);
  };
  let timer = null;
  const onSearch = async (query: string) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          let data = null;
          if (tab === 'users') {
            data = await dispatch(
              getUsers(10, 0, 'short', query, 1, null, false)
            );
          }
          if (tab === 'vendors') {
            data = await dispatch(
              getVendorUsers(10, 0, 'short', query, 1, null, false)
            );
          } else {
            data = await dispatch(
              getStaffUsers(10, 0, 'short', query, 1, null, false)
            );
          }
          const customData = data?.map((element) => {
            return {
              accountId: element?.accountId,
              firstName: `${element?.firstName} ${element?.lastName}`,
            };
          });
          res(customData);
        }, 1000))
    );
  };
  const onSubmit = async () => {
    await dispatch(sendEmail({ accountIds: userIds, emailType: option }));
    await dispatch(emptyUserIds());
  };
  const onClickOfClose = async () => {
    setValue('');
    setCancelIcon(false);
    fetchAccountData(null, '', 0, true);
  };
  return (
    <>
      <Topnav
        headerTitle="Accounts"
        showSearchBar={true}
        placeholder={`Search ${tab === 'building-staff' ? 'staff' : tab}`}
        promiseOptions={onSearch}
        optionLabel="firstName"
        optionKey="accountId"
        onSearchOptionSelect={async (selectedOption) => {
          if (selectedOption) {
            setValue(selectedOption);
            setCancelIcon(true);
            fetchAccountData(selectedOption.accountId, '', 1, true);
          }
        }}
        showCancelIcon={showCancelIcon}
        defaultSearchValue={[defaultValue]}
        onClickOfCloseIcon={onClickOfClose}
        cacheOptions={false}
      />
      <CommonTabCompponent className="ml-4">
        <div data-label="Users" data-click={true}>
          <Users onRowCheck={onRowCheck} isLoading={searchLoading} />
        </div>
        <div data-label="Vendors" data-click={true}>
          <Vendors onRowCheck={onRowCheck} isLoading={searchLoading} />
        </div>
        <div data-label="Building-Staff" data-click={true}>
          <Staff onRowCheck={onRowCheck} isLoading={searchLoading} />
        </div>
      </CommonTabCompponent>
      <Footer
        buttonText="Send"
        showCount={true}
        showDropDown={true}
        emailDropDownOptions={emailDropDownOptions}
        dropDownText="Send Link for"
        showDropdownText={true}
        count={userIds.length}
        disabled={loading || userIds.length <= 0 || !option}
        className="account_footer"
        onOptionChange={onDropdown}
        onClick={onSubmit}
        tab={tab}
        buttonClass="footer_button"
      />
    </>
  );
};

export default Accounts;
