import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Input,
  Topnav,
  ToggleButton,
} from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FormTitle from '../Clients/Building/AddBuilding/FormTitle';
import DeleteElementPopup from '../Common/DeleteElementPopup';
import { getBuildings } from '../../redux/building/buildings.action';
import { loadState } from '../../utils';
import './Users.scss';
import { useFormDebounce } from '../../hooks';
import { clearUserForm, updateUserForm } from '../../redux/form';
import { addUser, deleteUser, updateUser } from '../../redux/user';
import { IBuildingState } from '../../redux/building';
import { IFormState } from '../../redux/form';
import { ILoaderState } from '../../redux/loader';
import { User } from '../../models';

const UserProfile: React.FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const { userForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const {
    register,
    handleSubmit,
    reset,
    errors,
    setValue,
    getValues,
  } = useForm<User>();
  const { allBuildings } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  // Decrypt the value of popup fields
  const [popupData] = useState(
    query.get('user') ? JSON.parse(atob(query.get('user'))) : null
  );
  useFormDebounce(updateUserForm, 1000, getValues, clearUserForm);
  const userId = params['userId'] ? parseInt(params['userId'], 10) : 0;
  const [usersData] = useState(userId > 0 ? loadState('userForm') : userForm);
  const [showUserDelete, setShowUserDelete] = useState({
    showModal: false,
    isDelete: false,
  });
  const [loadingValue, setLoading] = useState(false);
  const [marketing, setMarketing] = useState(usersData?.['marketingEmails']);
  const [weeklyDigest, setWeeklyDigest] = useState(usersData?.['newsDigest']);

  useEffect(() => {
    if (userId > 0) {
      reset(usersData);
    }
  }, [usersData, reset, userId]);

  const [selectedBuildings, setUpdatedBuilding] = useState(
    userId > 0 ? usersData?.['buildings'] : popupData?.['buildingObj']
  );

  const onSubmit = async (data: User) => {
    const buildingData = [];
    selectedBuildings.map((item: any) => {
      return buildingData.push({
        id: item.id,
        name: item.name,
      });
    });
    data.buildings = buildingData;
    data.isActive = true;

    if (!userId) {
      data.registrationDate = '';
      data.block = { id: null };
      const latestUser = await dispatch(addUser(data));
      if (latestUser) {
        push(`/data-resources/users`);
      }
    } else {
      data.block = { id: usersData?.['block']?.['id'] };
      data.email = usersData?.['email'];
      await dispatch(updateUser(userId, data));
      push(`/data-resources/users`);
    }
  };

  const onDeleteUser = async () => {
    await dispatch(deleteUser(userId));
  };

  const onArchive = async () => {
    const data = getValues();
    data.block = { id: usersData?.['block']?.['id'] };
    data.isActive = !usersData?.['isActive'];
    await dispatch(updateUser(userId, data));
  };

  return (
    <>
      <Topnav
        headerTitle={`${
          !userId
            ? 'Add User'
            : `${usersData?.['firstName']} ${usersData?.['lastName']}`
        }`}
      />
      {showUserDelete.showModal && (
        <DeleteElementPopup
          isDelete={showUserDelete.isDelete}
          modalState={showUserDelete.showModal}
          modalClose={() =>
            setShowUserDelete({ showModal: false, isDelete: false })
          }
          onDelete={onDeleteUser}
          onArchive={onArchive}
          headerText={`Are you sure you want to ${
            showUserDelete.isDelete
              ? 'delete'
              : `${usersData?.['isActive'] ? 'deactivate' : 'activate'}`
          } this User?`}
          subHeader={`${
            showUserDelete.isDelete
              ? 'User will be deactivated for 30 days before being permanently deleted.'
              : 'You can re-activate the user anytime.'
          }`}
          buttonLabel={`${
            showUserDelete.isDelete
              ? 'Delete User'
              : `${usersData?.['isActive'] ? 'Deactivate' : 'Activate'} User`
          }`}
          redirectValue="/data-resources/users"
          modalClass="delete_user_popup"
        />
      )}
      <div className="mx-10 staff-section mt-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Button
            label="Save changes"
            className={`${loading ? 'button_disable staff-add' : 'staff-add'}`}
            disabled={loading}
          />
          {(errors?.email || errors?.cellPhone) && (
            <span className="error-info">Please enter correct value</span>
          )}
          <FormTitle title="User Details" />
          <div className="grid grid-cols-2 gap-x-2 gap-y-0">
            <div>
              <Input
                isLabelShow={true}
                label="First Name"
                name="firstName"
                ref={register}
                value={popupData?.['firstName']}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Last Name"
                name="lastName"
                ref={register}
                value={popupData?.['lastName']}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Date of registration"
                ref={() => register({ name: 'registrationDate' })}
                onDateChange={(date) => setValue('registrationDate', date)}
                showDatepicker={true}
                placeholder="Select Date"
                selectedDate={
                  usersData?.['registrationDate']
                    ? new Date(usersData?.['registrationDate'])
                    : new Date()
                }
                disabled={true}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Company/Unit"
                name="block.name"
                ref={register}
                value={
                  usersData?.['block']?.['name']
                    ? usersData?.['block']?.['name']
                    : ''
                }
                disabled={true}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Email Address"
                name="email"
                isError={errors?.['email'] ? true : false}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                inputType="email"
                disabled={userId > 0 ? true : false}
                isMandatory={true}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Phone number"
                name="cellPhone"
                isError={errors?.['cellPhone']?.['message'] ? true : false}
                ref={register({
                  required: false,
                  pattern: {
                    value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
                    message: 'Invalid phone number',
                  },
                })}
              />
            </div>
            <div>
              <ToggleButton
                label="Marketing emails"
                onChange={(e) => setMarketing(e.currentTarget.checked)}
                isLabelShow={marketing}
                checked={marketing}
                uncheckedText="No"
                checkedText="Yes"
                name="marketingEmails"
                ref={register}
              />
            </div>
            <div>
              <ToggleButton
                label="Weekly Digest"
                onChange={(e) => setWeeklyDigest(e.currentTarget.checked)}
                isLabelShow={weeklyDigest}
                checked={weeklyDigest}
                uncheckedText="No"
                checkedText="Yes"
                name="newsDigest"
                ref={register}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-2 gap-y-0 pb-8">
            <div>
              <Dropdown
                asyncDropdown={false}
                labelKey="name"
                valueKey="id"
                isLabelShow={true}
                label="Buildings Registered to"
                options={allBuildings}
                onMenuOpen={async () => {
                  setLoading(true);
                  if (allBuildings.length === 0) {
                    await dispatch(getBuildings(0, 0, 'short', '', 1));
                  }
                  setLoading(false);
                }}
                loading={loadingValue}
                ref={() => register({ name: 'buildings' }, { required: false })}
                onOptionChange={(e) => {
                  setUpdatedBuilding(e);
                }}
                value={selectedBuildings}
                placeholder=""
                className="ddl_label_width"
                isMulti={true}
              />
            </div>
          </div>
        </form>
        {userId > 0 && (
          <>
            <div className="de-activate">
              <label
                onClick={() => {
                  setShowUserDelete({ showModal: true, isDelete: false });
                }}
              >{`${
                usersData.isActive ? 'Deactivate' : 'Activate'
              } User`}</label>
              <span>You can re-activate the user anytime.</span>
            </div>
            <div className="delete_space">
              <label
                onClick={() => {
                  setShowUserDelete({ showModal: true, isDelete: true });
                }}
              >
                Delete User
              </label>
              <span>
                User will be deactivated for 30 days before being permanently
                deleted.
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserProfile;
