import { Pagination } from '../../models';
import { Map } from 'immutable';

export interface IBlocksState extends Pagination {
  block: object;
  blockCount: number;
  isCommercial: boolean;
  buildingBlockArray: any[];
  buildingType: string;
  buildingId: string;
  buildingName: string;
  editBlock: boolean;
  blockId: number;
  deleteBlock: any;
  addBuildingBlockData: any;
  updateBuildingBlockData: boolean;
  allBuildingBlock: any[];
}

export const initialBlockState: IBlocksState = {
  block: {},
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  blockCount: 0,
  isCommercial: false,
  buildingBlockArray: [],
  buildingType: '',
  buildingId: '',
  buildingName: '',
  editBlock: false,
  blockId: 0,
  addBuildingBlockData: null,
  updateBuildingBlockData: false,
  deleteBlock: false,
  allBuildingBlock: [],
};
