import React, { useState } from 'react';
import {
  Button,
  PrimaryLabel,
  RadioGroup,
  Dropdown,
} from '@alfredclub/hom-planner-theme';
import ModalPopup from '../../../ReactModal/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { typesOfProperty } from '../../../../constants/index';
import { getBuildings } from '../../../../redux/building/buildings.action';
import BuildingAddCompanies from './BuildingAddCompanies';
import { getBuildingBlocks } from '../../../../redux/buildingblocks/buildingblocks.action';
import './Companies.scss';
import { IBuildingState } from '../../../../redux/building/Building.state';
interface IFormInput {
  building: string;
  buildingType: string;
}
const AddNewCompany: React.FC<{
  modalState: boolean;
  onCloseCompany: () => void;
}> = ({ modalState, onCloseCompany }) => {
  const {
    register,
    errors,
    handleSubmit,
    getValues,
    setValue,
  } = useForm<IFormInput>();
  const [isOpenPop, setOpenPopup] = useState(modalState);
  const dispatch = useDispatch();
  const { allBuildings } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const [loading, setLoading] = useState(false);
  const [
    showBuildingAddCompaniesPopup,
    setAddBuildingCompaniesPopupState,
  ] = useState(false);
  const [listOfPropertyType, setListofProperty] = useState({
    propertyObj: typesOfProperty,
  });
  const onSubmit = () => {
    setOpenPopup(false);
    setAddBuildingCompaniesPopupState(true);
  };
  const closeModalPopup = () => {
    setOpenPopup(false);
    onCloseCompany();
  };
  const buildingChange = async (e: any) => {
    const building = {
      id: e['id'],
      name: e['name'],
      buildingType: e['buildingType'],
    };
    const selectedBuilding = e['id'];
    const buildingType = e['buildingType'];
    const newBuildingArray = [...listOfPropertyType.propertyObj];
    newBuildingArray.map((data: any) => {
      if (data.value === buildingType) {
        return (data.checked = true);
      } else {
        return (data.checked = false);
      }
    });
    setValue('building', building);
    setValue('buildingType', e['buildingType']);
    setListofProperty({ ...listOfPropertyType, propertyObj: newBuildingArray });
    if (selectedBuilding) {
      await dispatch(
        buildingType === 'R'
          ? getBuildingBlocks(selectedBuilding, 0, 0, 'short', '', 1)
          : getBuildingBlocks(selectedBuilding)
      );
    }
  };

  return (
    <div>
      {showBuildingAddCompaniesPopup && (
        <BuildingAddCompanies
          modalState={showBuildingAddCompaniesPopup}
          onClosePopup={onCloseCompany}
          buildingBlockData={getValues(['building', 'buildingType'])}
          addUnitForm={false}
          CommonLayoutCheck={false}
        />
      )}
      <ModalPopup
        isOpen={isOpenPop}
        onRequestClose={() => closeModalPopup()}
        className="add-company-modal transform-modal"
        shouldCloseOnOverlayClick={false}
      >
        <label className="label">Add Companies/Units</label>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PrimaryLabel labelText="Choose Building" />
          <PrimaryLabel labelText="*" className="text-red-500" />
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={false}
            label="Building"
            options={allBuildings}
            onMenuOpen={async () => {
              setLoading(true);
              if (allBuildings?.length === 0) {
                await dispatch(getBuildings(0, 0, 'short', '', 1));
              }
              setLoading(false);
            }}
            loading={loading}
            placeholder=""
            ddlClass="ddl-space"
            name="buildingName"
            ref={() => register({ name: 'building' }, { required: true })}
            onOptionChange={(e) => {
              buildingChange(e);
            }}
          />
          <PrimaryLabel labelText="Type of property" />
          <RadioGroup
            isLabelShow={false}
            options={listOfPropertyType.propertyObj}
            ref={() => register({ name: 'buildingType' }, { required: false })}
            name="buildingType"
            isDisabled={true}
          />
          <Button label="Next" isArrowShow={true} className="mt-5" />
          {errors.building && (
            <div className="error">Please choose building</div>
          )}
        </form>
      </ModalPopup>
    </div>
  );
};

export default AddNewCompany;
