import {
  deleteGroupTaskApi,
  updateTasksOfGroupApi,
  addTaskToGroupsApi,
  deleteTaskGroupsApi,
  createTaskGroupsApi,
  getTasksGroupsApi,
  getTasksGroupsDetailApi,
  updateTaskGroupsApi,
} from './taskservice';
import * as loadingTypes from '../loader/loader.constant';
import * as taskTypes from './task.constant';
import * as toastTypes from '../toast/toast.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import {
  PaginatedResponse,
  ICreateTaskGroup,
  IDeleteResponse,
} from '../../models';
import { IGroupTasks, ITaskGroupDetail } from '.';

export const createTaskGroups = (data: object) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const groupAdded: PaginatedResponse<ICreateTaskGroup> = await createTaskGroupsApi(
    data
  );
  if (groupAdded) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.taskTemplate.groupAdded,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return groupAdded;
  }
};

export const updateTaskGroups = (data: object, groupId: number) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const groupUpdate: ICreateTaskGroup = await updateTaskGroupsApi(
    data,
    groupId
  );
  if (groupUpdate) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.taskTemplate.groupUpdated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getTaskGroups = (query: string, groupId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const taskGroupsData: PaginatedResponse<ITaskGroupDetail> = await getTasksGroupsApi(
    query,
    groupId
  );
  if (taskGroupsData.data) {
    dispatch({
      type: taskTypes.GET_TASKS_GROUPS,
      payload: taskGroupsData.data,
      count: taskGroupsData.total,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return taskGroupsData.data;
  }
};

export const addTaskToGroups = (groupId: number, data: object) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const itemAddedToGroup = await addTaskToGroupsApi(groupId, data);
  if (itemAddedToGroup) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.taskTemplate.added,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getTaskGroupsDetail = (groupId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const taskGroupsData: PaginatedResponse<ITaskGroupDetail> = await getTasksGroupsDetailApi(
    groupId
  );
  if (taskGroupsData) {
    dispatch({
      type: taskTypes.GET_GROUP_DETAIL,
      payload: taskGroupsData,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
  return taskGroupsData;
};

export const getGroupTaskDetail = (
  taskGroupsData: ITaskGroupDetail,
  groupId: number,
  taskId: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  dispatch({
    type: taskTypes.GET_GROUP_TASK_DETAIL,
    payload: taskGroupsData,
    groupId,
    taskId,
  });
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const clearGroupTaskDetail = () => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  dispatch({
    type: taskTypes.CLEAR_GROUP_TASK_DETAIL,
  });
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const updateTasksOfGroup = (itemId: number, data: IGroupTasks) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedItem: IGroupTasks = await updateTasksOfGroupApi(itemId, data);
  if (updatedItem) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.taskTemplate.taskUpdate,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteGroupTask = (itemId: number, data: IGroupTasks) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const deletedRecord = await deleteGroupTaskApi(itemId, data);
  if (deletedRecord) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.taskTemplate.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteTaskGroups = (groupId: number) => async (dispatch) => {
  const deletedRecord: IDeleteResponse = await deleteTaskGroupsApi(groupId);
  if (deletedRecord.data) {
    // dispatch({
    //   type: taskTypes.DELETE_INVENTORY_GROUP,
    //   id: groupId,
    // });
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.inventory.groupDeleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
