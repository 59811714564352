import * as modalTypes from './modal.constant';
import { initialModalState, IModalState } from './modal.state';

export const modalReducer = (
  state: IModalState = initialModalState,
  action: { type: string; payload: any }
): IModalState => {
  switch (action.type) {
    case modalTypes.OPEN_BROADCAST_MODAL:
      return {
        ...state,
        openBroadcast: true,
      };
    case modalTypes.CLOSE_MODAL:
      return {
        ...state,
        openBroadcast: false,
      };
    case modalTypes.OPEN_REGION_MODAL:
      return {
        ...state,
        openRegion: true,
      };
    case modalTypes.CLOSE_REGION_MODAL:
      return {
        ...state,
        openRegion: false,
      };
    case modalTypes.RESET_MODAL_VALUES:
      return {
        ...state,
        resetModal: action.payload,
      };
    default:
      return state;
  }
};
