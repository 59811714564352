import React, { useMemo, useState } from 'react';
import { Button, Loader, Table, Topnav } from '@alfredclub/hom-planner-theme';
import { CommonLayoutProps } from '../../interfaces';
import { tableData, tableColumns, profileOptions } from '../../constants/index';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TabComponent from '../Common/TabComponent';
import '../Clients/Companies/AddNewCompany/Companies.scss';
import { clearAuth } from '../../redux/authentication';
import { MenuType } from '../../enums';
import GenerateReport from '../Common/GenerateReport';
import CommonFilters from '../Common/CommonFilters';

const CommonLayout: React.FC<CommonLayoutProps> = ({
  headerTitle,
  showIcon,
  showSearchBar,
  showTable,
  showSortButton = true,
  tableColumnsData = tableData,
  tableColumnsHeaders = tableColumns,
  showPagination = true,
  recordsCount,
  onSearch = () => {},
  onPageChange = () => {},
  onCellClick = () => {},
  onCellMouseEnter = () => {},
  onClick = () => {},
  tabType,
  onTabChange,
  showTabs,
  tabFirst,
  tabSecond,
  placeholder,
  tblClassName,
  navIconToolTipText = null,
  promiseOptions,
  optionLabel,
  optionKey,
  onSearchOptionSelect,
  showCancelIcon,
  defaultValue,
  onClickOfCancel,
  cacheOptions,
  refreshTable = false,
  isLoading = false,
  vendorTab = false,
  headerTitleClass = '',
  setPage,
}) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [openReport, setOpenReport] = useState<boolean>(false);
  const tableColumnsHead = useMemo(() => tableColumnsHeaders, [
    tableColumnsHeaders,
  ]);
  const tableColumnsApiData = useMemo(() => tableColumnsData, [
    tableColumnsData,
  ]);
  const onLogout = async () => {
    localStorage.clear();
    await dispatch(clearAuth());
    push('/login');
  };
  const onMenuChange = async (id: number) => {
    switch (id) {
      case MenuType.Report:
        setOpenReport(true);
        return;
      case MenuType.Logout:
        onLogout();
        break;
    }
  };

  return (
    <div className="flex">
      <div className="w-full main_wrapper">
        <Topnav
          headerTitle={headerTitle}
          showIcon={showIcon}
          showSearchBar={showSearchBar}
          onSearch={onSearch}
          onClick={onClick}
          placeholder={placeholder}
          toolTipText={navIconToolTipText}
          profileOptions={profileOptions}
          onProfileChange={(e) => {
            onMenuChange(Number(e['value']));
          }}
          promiseOptions={promiseOptions}
          optionLabel={optionLabel}
          optionKey={optionKey}
          onSearchOptionSelect={onSearchOptionSelect}
          showCancelIcon={showCancelIcon}
          defaultSearchValue={defaultValue}
          onClickOfCloseIcon={onClickOfCancel}
          cacheOptions={cacheOptions}
          headerTitleClass={headerTitleClass}
        />
        {showTabs && (
          <TabComponent
            tabType={tabType}
            onTabChange={onTabChange}
            tabFirst={tabFirst}
            tabSecond={tabSecond}
          />
        )}
        {openReport && (
          <GenerateReport
            modalState={openReport}
            onClosePopup={() => setOpenReport(false)}
          />
        )}
        {showTable && (
          <section
            className={
              'table-wrapper mx-8 ' +
              (!showSortButton ? 'mt-6' : `${tblClassName}`)
            }
          >
            {vendorTab ? (
              <CommonFilters setPage={setPage} />
            ) : (
              <Button
                backgroundColor="#ffffff"
                className="px-sort-button mt-8"
                label="Sort by"
                buttonText="#5d5d5d"
                onClick={() => {}}
              />
            )}
            {refreshTable && isLoading ? (
              <Loader
                color="#e89a89"
                position="center"
                loading={isLoading}
                size="0.75rem"
              />
            ) : (
              <Table
                tableColumns={tableColumnsHead}
                tableData={tableColumnsApiData}
                showPagination={showPagination}
                onPageChange={(pageNumber) => {
                  onPageChange(pageNumber);
                }}
                totalRecordsCount={recordsCount}
                onCellClick={onCellClick}
                onCellMouseEnter={onCellMouseEnter}
                className={`${!showSortButton || vendorTab ? 'mt-3' : 'mt-20'}`}
              />
            )}
          </section>
        )}
      </div>
    </div>
  );
};

export default CommonLayout;
