import { Pagination, Vendor, Tiny } from '../../models';
import { Map } from 'immutable';

export interface IVendorState extends Pagination {
  vendors: Vendor;
  vendorCount: number;
  vendorData: object;
  allVendors: object[];
  vendorsForSuperHero: Tiny[];
  vendorFilters: object;
  isTable: boolean;
  fetchedVendor?: boolean;
}

export const initialVendorState: IVendorState = {
  vendors: {},
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  vendorCount: 0,
  vendorData: null,
  allVendors: [],
  vendorsForSuperHero: [],
  vendorFilters: {},
  isTable: true,
  fetchedVendor: false,
};
