import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EventSetupForm from '../EventSetup/EventSetupForm';
import RecurringEventSetup from '../EventSetup/RecurringEventSetup';
import VendorAssignmentForm from '../VendorAssignment';
import TimeLine from '../TimeLineFeed';
import RSVPAttendeesListing from '../RSVPsAttendees/RSVPAttendeesListing';
import SlideDrawer from '../../Common/SliderPane';
import Backdrop from '../../Common/SliderPane/Backdrop';
import BroadcastForm from '../RSVPsAttendees/BroadcastForm';
import {
  closeModal,
  resetModalValues,
} from '../../../redux/modal/modal.action';
import { IModalState } from '../../../redux/modal/modal.state';
import EventTaskInventory from '../TaskManagement';

const Child: React.FC<{
  step: string;
  render: (step: string) => void;
  recurring: boolean;
}> = ({ step, render, recurring }) => {
  const dispatch = useDispatch();
  const { openBroadcast } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );
  const toggleDrawer = async () => {
    await dispatch(closeModal());
    await dispatch(resetModalValues(true));
  };
  let backdrop: {};
  if (openBroadcast) {
    backdrop = <Backdrop close={toggleDrawer} />;
  }

  const renderChild = () => {
    switch (step) {
      case 'setup':
        render(step);
        return <EventSetupForm />;
      case 'recurring':
        render(step);
        return <RecurringEventSetup />;
      case 'vendor-assignment':
        render(step);
        return <VendorAssignmentForm />;
      case 'task-management':
        render(step);
        return <EventTaskInventory />;
      case 'timeline':
        render(step);
        return <TimeLine />;
      case 'users':
        render(step);
        return <RSVPAttendeesListing />;
      default:
        return <EventSetupForm />;
    }
  };
  return (
    <>
      {renderChild()}
      {!recurring && (
        <>
          <SlideDrawer show={openBroadcast} slideClass="side-drawer open">
            {openBroadcast && <BroadcastForm closePane={toggleDrawer} />}
          </SlideDrawer>
          {backdrop}
        </>
      )}
    </>
  );
};

export default Child;
