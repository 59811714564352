import * as formTypes from './form.constant';
import { IFormState, inititalFormState } from './form.state';
import { weekDaysArray } from '../../constants/index';
import { checkPastEvent } from '../../utils';

export const formReducer = (
  state: IFormState = inititalFormState,
  action: { [x: string]: any; service: string; pendingService: string }
): IFormState => {
  switch (action['type']) {
    case formTypes.UPDATE_BUILDING_FORM:
      return {
        ...state,
        buildingForm: { ...state.buildingForm, ...action['payload'] },
      };

    case formTypes.UPDATE_BUILDING_SPACE_FORM:
      return {
        ...state,
        buildingForm: {
          ...state.buildingForm,
          buildingSpaces: state.buildingForm['buildingSpaces'].concat(
            ...action['payload']['spaces']
          ),
        },
      };

    case formTypes.UPDATE_BUILDING_WHITELABEL_FORM:
      return {
        ...state,
        buildingForm: {
          ...state.buildingForm,
          buildingWhiteLabel: action['payload'],
        },
      };

    case formTypes.UPDATE_COMPANY_UNIT_FORM:
      return {
        ...state,
        buildingForm: {
          ...state.buildingForm,
          buildingBlocks: state.buildingForm['buildingBlocks'].concat(
            ...action['payload']
          ),
        },
      };
    case formTypes.UPDATE_UNIT_COUNT:
      return {
        ...state,
        buildingForm: {
          ...state.buildingForm,
          blockCount: action['payload'],
        },
      };
    case formTypes.UPDATE_BUILDING_FORM_STAFF:
      return {
        ...state,
        buildingForm: {
          ...state.buildingForm,
          buildingStaff: [...action['payload']],
        },
      };
    case formTypes.DELETE_SPACE_FROM_BUILDING:
      const updatedSpaces = state.buildingForm['buildingSpaces'].filter(
        (_, index) => index !== action['index']
      );
      return {
        ...state,
        buildingForm: {
          ...state.buildingForm,
          buildingSpaces: updatedSpaces,
        },
      };
    case formTypes.EMPTY_BUILDING_FORM:
      return {
        ...state,
        buildingForm: inititalFormState.buildingForm,
      };
    case formTypes.UPDATE_MANAGEMENT_FORM:
      return {
        ...state,
        managementForm: { ...state.managementForm, ...action['payload'] },
      };
    case formTypes.EMPTY_MANAGEMENT_FORM:
      return {
        ...state,
        managementForm: inititalFormState.managementForm,
      };
    case formTypes.UPDATE_CLUSTER_FORM:
      return {
        ...state,
        clusterForm: { ...state.clusterForm, ...action['payload'] },
      };
    case formTypes.EMPTY_CLUSTER_FORM:
      return {
        ...state,
        clusterForm: inititalFormState.clusterForm,
      };
    case formTypes.UPDATE_BUILDING_STAFF:
      return {
        ...state,
        buildingStaffForm: { ...state.buildingStaffForm, ...action['payload'] },
      };
    case formTypes.EMPTY_BUILDING_STAFF:
      return {
        ...state,
        buildingStaffForm: inititalFormState.buildingStaffForm,
      };
    case formTypes.UPDATE_SPACE_FORM:
      return {
        ...state,
        spaceForm: { ...state.spaceForm, ...action['payload'] },
      };
    case formTypes.EMPTY_SPACE_FORM:
      return {
        ...state,
        spaceForm: inititalFormState.spaceForm,
      };
    case formTypes.UPDATE_USER_FORM:
      return {
        ...state,
        userForm: { ...state.userForm, ...action['payload'] },
      };
    case formTypes.EMPTY_USER_FORM:
      return {
        ...state,
        userForm: inititalFormState.userForm,
      };

    case formTypes.UPDATE_COMPANY_VENDOR_FORM:
      return {
        ...state,
        vendorCompanyForm: { ...state.vendorCompanyForm, ...action['payload'] },
      };
    case formTypes.EMPTY_COMPANY_VENDOR_FORM:
      return {
        ...state,
        vendorCompanyForm: inititalFormState.vendorCompanyForm,
      };
    case formTypes.UPDATE_COMPANY_INDIVIDUAL_FORM:
      const venueService =
        Object.keys(action['payload']?.['venueServices']).length > 0
          ? action['payload']?.['venueServices']
          : { buildings: [] };
      const onlineService =
        Object.keys(action['payload']?.['onlineServices']).length > 0
          ? action['payload']?.['onlineServices']
          : { buildings: [] };
      return {
        ...state,
        vendorIndividualForm: {
          ...state.vendorIndividualForm,
          ...action['payload'],
          venueServices: venueService,
          onlineServices: onlineService,
        },
      };
    case formTypes.UPDATE_COMPANY_INDIVIDUAL_CERTIFICATE_IMAGES:
      return {
        ...state,
        vendorIndividualForm: {
          ...state.vendorIndividualForm,
          ...action['payload'],
        },
      };
    case formTypes.UPDATE_INDIVIDUAL_FORM:
      return {
        ...state,
        vendorIndividualForm: {
          ...state.vendorIndividualForm,
          [action.keyName]: action['payload'],
        },
      };
    case formTypes.UPDATE_COMPANY_VENDOR_CONTACTS:
      return {
        ...state,
        vendorCompanyForm: {
          ...state.vendorCompanyForm,
          vendorContacts: state.vendorCompanyForm['vendorContacts'].concat(
            action['payload']
          ),
        },
      };
    case formTypes.EMPTY_INDIVIDUAL_FORM:
      return {
        ...state,
        vendorIndividualForm: inititalFormState.vendorIndividualForm,
      };
    case formTypes.UPDATE_SERVICE_SETUP_FORM:
      return {
        ...state,
        serviceSetupForm: { ...state.serviceSetupForm, ...action['payload'] },
      };
    case formTypes.EMPTY_SERVICE_SETUP_FORM:
      return {
        ...state,
        serviceSetupForm: {
          ...inititalFormState.serviceSetupForm,
          productionTeams: [],
        },
      };
    case formTypes.UPDATE_COMPANY_INDIVIDUAL_BUILDINGS:
      state.vendorIndividualForm[action.service] = {
        ...state.vendorIndividualForm[action.service],
        buildings: [...action['payload']],
      };
      state.vendorIndividualForm[action.pendingService] = {
        ...state.vendorIndividualForm[action.pendingService],
        buildings:
          state.vendorIndividualForm[action.pendingService]?.['buildings']?.[
            'length'
          ] > 0
            ? [
              ...state.vendorIndividualForm[action.pendingService]?.[
              'buildings'
              ],
            ]
            : [],
      };

      return {
        ...state,
        vendorIndividualForm: {
          ...state.vendorIndividualForm,
        },
      };

    case formTypes.UPDATE_INDIVIDUAL_SERVICES:
      let actionValue = null;
      if (action['payload']) {
        actionValue = [...action['payload']];
      }
      state.vendorIndividualForm[action.service] = {
        ...state.vendorIndividualForm[action.service],
        [action.serviceChild]: actionValue ?? [],
      };
      return {
        ...state,
        vendorIndividualForm: {
          ...state.vendorIndividualForm,
        },
      };
    case formTypes.UPDATE_PACKAGE_ELEMENTS_FORM:
      return {
        ...state,
        packageElementForm: {
          ...state.packageElementForm,
          packageElements: state.packageElementForm['packageElements'].concat(
            ...action['payload']
          ),
        },
      };
    case formTypes.EMPTY_PACKAGE_ELEMENTS_FORM:
      return {
        ...state,
        packageElementForm: inititalFormState.packageElementForm,
      };
    case formTypes.UPDATE_SERVICE_VENDOR_FORM:
      return {
        ...state,
        serviceVendorForm: {
          ...state.serviceVendorForm,
          vendorRequirements: action['payload'],
        },
      };
    case formTypes.EMPTY_SERVICE_VENDOR_FORM:
      return {
        ...state,
        serviceVendorForm: inititalFormState.serviceVendorForm,
      };
    case formTypes.UPDATE_SERVICE_PRICE_FORM:
      return {
        ...state,
        pricingForm: { ...state.pricingForm, ...action['payload'] },
      };
    case formTypes.EMPTY_SERVICE_PRICE_FORM:
      return {
        ...state,
        pricingForm: inititalFormState.pricingForm,
      };
    // case formTypes.UPDATE_TASK_LIST:
    //   return {
    //     ...state,
    //     serviceTaskList: state.serviceTaskList.concat(action['payload']),
    //   };
    case formTypes.UPDATE_TASK_LIST_EDIT:
      return {
        ...state,
        serviceTaskList: [...state.serviceTaskList, ...action['payload']],
      };

    case formTypes.REMOVE_TASK:
      const updatedServiceTaskList = state.serviceTaskList.filter(
        (item) => item['id'] !== action['payload']
      );
      return {
        ...state,
        serviceTaskList: updatedServiceTaskList,
      };
    case formTypes.EMPTY_TASK_LIST:
      return {
        ...state,
        serviceTaskList: inititalFormState.serviceTaskList,
      };
    case formTypes.UPDATE_SERVICE_ELEMENTS_VENDOR_FORM:
      return {
        ...state,
        serviceVendorForm: {
          ...state.serviceVendorForm,
          vendorRequirements: state.serviceVendorForm['vendors'].concat(
            ...action['payload']
          ),
        },
      };
    case formTypes.GET_CLUSTER_BUILDINGS_SINGLE_OPTION_ISRECURRING:
      return {
        ...state,
        recurringSetupForm: {
          ...state.recurringSetupForm,
          buildings: [...action.payload],
        },
      };
    case formTypes.GET_CLUSTER_BUILDINGS_SINGLE_OPTION:
      return {
        ...state,
        eventSetupForm: {
          ...state.eventSetupForm,
          buildings: [...action.payload],
        },
      };
    case formTypes.GET_CLUSTER_BUILDINGS_SINGLE:
      const filteredSingle = state.eventSetupForm.buildings.filter((elem) =>
        action.payload.find(({ id }) => elem.id !== id)
      );
      return {
        ...state,
        eventSetupForm: {
          ...state.eventSetupForm,
          buildings: [...filteredSingle, ...action.payload],
        },
      };
    case formTypes.GET_CLUSTER_BUILDINGS_ISRECURRING:
      const filteredReccurring = state.recurringSetupForm.buildings.filter(
        (elem) => action.payload.find(({ id }) => elem.id !== id)
      );
      return {
        ...state,
        recurringSetupForm: {
          ...state.recurringSetupForm,
          buildings: [...filteredReccurring, ...action.payload],
        },
      };
    case formTypes.CLEAR_CLUSTER_BUILDINGS_ISRECURRING:
      const filteredBuildings = state.recurringSetupForm.buildings.filter(
        (item) => item?.['clusterId'] !== action['clusterId']
      );
      return {
        ...state,
        recurringSetupForm: {
          ...state.recurringSetupForm,
          buildings: [...filteredBuildings],
        },
      };
    case formTypes.CLEAR_CLUSTER_BUILDINGS_SINGLE:
      const filteredSingleBuildings = state.eventSetupForm.buildings.filter(
        (item) => item?.['clusterId'] !== action['clusterId']
      );
      return {
        ...state,
        eventSetupForm: {
          isRecurring: state.eventSetupForm.isRecurring,
          ...state.eventSetupForm,
          buildings: [...filteredSingleBuildings],
        },
      };
    case formTypes.UPDATE_EVENT_SETUP_FORM:
      const filterNonPackage = action['payload']['serviceElements']?.filter(
        ({ isPackage }) => !isPackage
      );
      return {
        ...state,
        eventSetupForm: {
          isRecurring: false,
          ...state.eventSetupForm,
          ...action['payload'],
          maxCapacity: parseInt(action['payload']['maxCapacity'], 10),
          isPastEvent: checkPastEvent(action['payload']['endDatetime']),
          serviceElements: filterNonPackage,
        },
      };
    case formTypes.UPDATE_EVENT_VISIBILITY:
      return {
        ...state,
        eventSetupForm: {
          isRecurring: false,
          ...state.eventSetupForm,
          ...action['payload'],
        },
      };
    case formTypes.EMPTY_EVENT_SETUP_FORM:
      return {
        ...state,
        eventSetupForm: inititalFormState.eventSetupForm,
      };

    case formTypes.UPDATE_RECURRING_SETUP_FORM:
      const weekdaysObj = weekDaysArray.filter((item) => {
        return action['payload']?.['scheduleEvents']?.find(
          ({ weekday }) => weekday === item.id
        );
      });
      return {
        ...state,
        eventSetupForm: {
          ...state.eventSetupForm,
          isRecurring: true,
        },
        recurringSetupForm: {
          ...state.recurringSetupForm,
          ...action['payload'],
          weekDays: weekdaysObj,
          recurring: true,
          isPastEvent: checkPastEvent(action['payload']['endDate']),
        },
      };
    case formTypes.UPDATE_RECURRING_FORM_SCHEDULES:
      const weekdaysObjs = weekDaysArray.filter((item) => {
        return action['payload']?.['scheduleEvents']?.find(
          ({ weekday }) => weekday === item.id
        );
      });
      return {
        ...state,
        eventSetupForm: {
          ...state.eventSetupForm,
          isRecurring: true,
        },
        recurringSetupForm: {
          ...state.recurringSetupForm,
          ...action['payload'],
          weekDays: weekdaysObjs,
        },
      };
    case formTypes.EMPTY_RECURRING_SETUP_FORM:
      return {
        ...state,
        recurringSetupForm: inititalFormState.recurringSetupForm,
      };
    case formTypes.EMPTY_CLONE_RECURRING_SETUP_FORM:
      return {
        ...state,
        recurringSetupForm: {
          ...inititalFormState.recurringSetupForm,
          recurring: true,
        },
      };
    case formTypes.EVENT_TASK_LIST:
      return {
        ...state,
        taskList: {
          ...state.taskList,
          data: [...action['payload']?.['data']],
          recurring: action['payload']?.['recurring'],
        },
      };
    case formTypes.UPDATE_EVENT_TASK_LIST:
      return {
        ...state,
        taskList: {
          data: [...action['payload']?.['data']],
          recurring: action['payload']?.['recurring'],
        },
      };
    case formTypes.EMPTY_AUXILIARY_VENDORS_FIELDS:
      return {
        ...state,
        serviceSetupForm: {
          ...state.serviceSetupForm,
          productionTeamRequired: false,
          productionTeams: [],
        },
      };
    default:
      return state;
  }
};
