import { useEffect } from 'react';

export const useDefaultValueRadioGroup = (
  state: object,
  property: string,
  optionsList: any[],
  action: any,
  isEdit: boolean
) => {
  useEffect(() => {
    if (isEdit) {
      const updatedOptions = optionsList.filter((item) => {
        if (state?.[property] === item.value) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return optionsList;
      });
      action([...updatedOptions]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, action, isEdit, property]);
};
