import { useEffect } from 'react';

export const useSetPageNo = (pageNo) => {
  const query = new URLSearchParams(window.location.search);
  useEffect(() => {
    query.set('pageNo', pageNo.toString());
    window.history.replaceState(null, null, '?' + query.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);
};
