import { http } from '../common/http.service';
import {
  RECORDS_LIMIT,
  RECORDS_CMP,
  RECORDS_PAGE,
  RECORDS_QUERY,
  ALL_RECORDS,
  CLUSTER_BASE_URL,
} from '../../constants/index';
import { Cluster, PaginatedResponse, DeleteResponse } from '../../models';
import { AxiosResponse } from 'axios';

export const getClustersApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS,
  filter: string = '',
  filterBy: number = 0
): Promise<PaginatedResponse<Cluster>> =>
  http
    .get<PaginatedResponse<Cluster>>(
      `${CLUSTER_BASE_URL}?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all=${all}&filter=${filter}&filter_value=${filterBy}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Cluster>>) => {
      return response.data;
    });

export const addClusterApi = async (data: Cluster): Promise<Cluster> =>
  http
    .post<Cluster>(`${CLUSTER_BASE_URL}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Cluster>) => {
      return response.data;
    });

export const getClusterDetailApi = async (
  clusterId: number
): Promise<Cluster> =>
  http
    .get<Cluster>(`${CLUSTER_BASE_URL}/${clusterId}`, { errorHandler: true })
    .then((response: AxiosResponse<Cluster>) => {
      return response.data;
    });

export const updateClusterApi = async (
  clusterId: number,
  data: Cluster
): Promise<Cluster> =>
  http
    .put<Cluster>(`${CLUSTER_BASE_URL}/${clusterId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Cluster>) => {
      return response.data;
    });

export const deleteClusterApi = async (
  clusterId: number
): Promise<DeleteResponse> =>
  http
    .delete(`${CLUSTER_BASE_URL}/${clusterId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });
