import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input, Table, Topnav } from '@alfredclub/hom-planner-theme';
import FormTitle from '../Building/AddBuilding/FormTitle';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import DeleteElementPopup from '../../Common/DeleteElementPopup';
import { deleteCluster, updateCluster } from '../../../redux/cluster';
import { useHistory } from 'react-router-dom';
import './Cluster.scss';
import { useFormDebounce, usePageRefresh } from '../../../hooks';
import {
  clearClusterForm,
  updateClusterForm,
} from '../../../redux/form/form.action';
import { loadState } from '../../../utils';
import { IFormState } from '../../../redux/form/form.state';
import { ILoaderState } from '../../../redux/loader/loader.state';
import { Cluster } from '../../../models';

const AddClusterProfile: React.FC = () => {
  const { register, handleSubmit, reset, getValues } = useForm<Cluster>();
  const { clusterForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const params = useParams();
  const dispatch = useDispatch();
  const clusterId = parseInt(params['clusterId'], 10);
  const [isEdit] = useState(clusterId ? true : false);
  const [isEditClick, setEditClick] = useState(false);
  const [clusterData] = useState(
    clusterId ? loadState('clusterForm') : clusterForm
  );
  const [showClusterDelete, setShowClusterDelete] = useState({
    showModal: false,
    isDelete: false,
  });
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const [buildings, setUpdatedBuilding] = useState(
    clusterData?.['buildings'] ?? []
  );
  usePageRefresh(true, clusterId ? false : true);
  useFormDebounce(updateClusterForm, 1000, getValues, clearClusterForm);
  const { push } = useHistory();
  // Reset the data object so that data can convert as per form elements. e.g: name="clusterUrl"
  useEffect(() => {
    if (clusterId || isEdit || clusterData) {
      reset(clusterData);
    }
  }, [reset, clusterId, clusterData, isEdit]);

  // Update the cluster
  const onSubmit = async () => {
    await dispatch(updateCluster(clusterId, { buildings }));
    push('/data-resources/clients/clusters');
  };

  // Table cell checkbox handler
  const handleOnChange = async (e: any, rowData?: any) => {
    const checkedBuildings = buildings.filter((building) => {
      if (building.id === rowData?.['id']) {
        building.select = e.currentTarget.checked;
      }
      return buildings;
    });
    setUpdatedBuilding(checkedBuildings);
  };

  const onDelete = async () => {
    await dispatch(deleteCluster(clusterId));
  };

  return (
    <>
      <Topnav
        headerTitle={`${
          clusterId
            ? `${clusterData?.['clusterName']}`
            : `Create a Cluster: ${clusterData?.['clusterName']}`
        }`}
      />
      {showClusterDelete.showModal && (
        <DeleteElementPopup
          isDelete={showClusterDelete.isDelete}
          modalState={showClusterDelete.showModal}
          modalClose={() =>
            setShowClusterDelete({ showModal: false, isDelete: false })
          }
          onDelete={onDelete}
          headerText="Are you sure you want to delete this Cluster?"
          subHeader="The cluster URL will be deactivated and buildings will be released from the cluster. Building data will remain intact."
          buttonLabel="Delete Cluster"
          modalClass="h-64"
          redirectValue="/data-resources/clients/clusters"
        />
      )}
      <div className="mx-10 cluster_profile pt-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Button
            label="Save Cluster"
            className={`${
              loading ? 'button_disable save_cluster' : 'save_cluster'
            }`}
            disabled={loading}
          />
          <div className="basic_title">
            <FormTitle title="Basic Details" />
          </div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-0 w-full">
            <div>
              <Input
                isLabelShow={true}
                label="Management"
                name="management.companyName"
                ref={register}
                disabled={true}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-0">
            <div>
              <Input
                name="clusterUrl"
                isLabelShow={true}
                label="Cluster URL"
                rightLabel="hom.events"
                className="input-control-space"
                isRightLabel={true}
                labelClass="mgmnt_label"
                ref={register}
                disabled={true}
              />
            </div>
          </div>

          <div className="inline-flex">
            <FormTitle title="Choose Buildings to add to the cluster:" />
          </div>
          <Link
            className="custom_label float-none edit_cluster"
            to="#"
            onClick={() => {
              setEditClick(!isEditClick);
            }}
          >
            Edit Cluster
          </Link>
          <Table
            tableColumns={[
              {
                id: 'checkbox',
                Header: 'Sno',
                accessor: 'id',
                Cell: (row: any) => {
                  return !isEditClick ? (
                    <div className="index_row">{row.row.index + 1}</div>
                  ) : (
                    <div className="table_checkbox">
                      <input
                        type="checkbox"
                        className="checkbox"
                        onChange={(e) => {
                          handleOnChange(e, row.row.original);
                        }}
                        checked={row.row.original.select}
                      />
                    </div>
                  );
                },
              },
              {
                Header: 'Building',
                accessor: 'name',
                className: 'tableAnchor',
              },
              {
                Header: 'Management Company',
                accessor: 'management',
              },
              {
                Header: 'Cluster',
                accessor: 'clusterName',
              },
              {
                Header: 'Building Status',
                accessor: (row: any) => (!row.active ? 'Inactive' : 'Active'),
              },
            ]}
            tableData={clusterData?.['buildings']}
            showPagination={false}
            className="cluster_table mt-2"
            onCellClick={(e) => handleOnChange(e)}
            onCellMouseEnter={() => {}}
          />
        </form>
        {isEdit && (
          <div className="delete_cluster">
            <label
              onClick={() => {
                setShowClusterDelete({ showModal: true, isDelete: true });
              }}
            >
              Delete Cluster
            </label>
            <span>
              The cluster URL will be deactivated and buildings will be released
              from the cluster. Building data will remain intact.
            </span>
          </div>
        )}
      </div>
    </>
  );
};
export default AddClusterProfile;
