import React from 'react';
import { getImageUrl } from '../../../utils';
import { TimelineArray } from '../../../models';
import './TimeLineFeed.scss';
interface ProgressBarProps {
  timeline: TimelineArray;
  cancelled: boolean;
}
const ProgressBar: React.FC<ProgressBarProps> = ({ timeline, cancelled }) => {
  const feedStatusCheck = (num: number) => {
    return timeline?.feedStatus === num ? true : false;
  };
  const vendorInviteCheck = () => {
    if (
      (!timeline?.manuallyAssigned?.show && !timeline?.eventInvite?.show) ||
      (timeline?.manuallyAssigned?.show && timeline?.eventInvite?.show) ||
      (!timeline?.manuallyAssigned?.show && timeline?.eventInvite?.show)
    ) {
      return (
        <div
          className={`dot ${
            timeline?.eventInvite?.show ||
            feedStatusCheck(4) ||
            feedStatusCheck(2)
              ? 'timeline-stage-done'
              : 'timeline-stage-not-done'
          }`}
        >
          <img src={getImageUrl('Events/timeline1.svg')} alt="icon" />
        </div>
      );
    }
  };
  return (
    <>
      {cancelled && (
        <div className="container">
          <div className="cancel_message">
            <img
              src={getImageUrl('Events/cancelred.svg')}
              alt="cancel"
              className="pr-2"
            />
            <div className="text">Event Cancelled</div>
          </div>
        </div>
      )}
      <div className={`ml-6 ${cancelled && 'cancel_event_overlay'}`}>
        <div
          className={`progress-line ${
            !timeline?.eventInvite?.show && timeline?.manuallyAssigned?.show
              ? 'invite-not-sent'
              : 'invite-sent'
          }`}
        >
          <div
            className={`progress--percentage w-${
              feedStatusCheck(0) ? 0 : timeline.feedStatus + '/4'
            }`}
          />
        </div>
        <div className="flex flex-row mb-6">
          {vendorInviteCheck()}
          <div
            className={`dot ${
              timeline?.manuallyAssigned?.show || feedStatusCheck(4)
                ? 'timeline-stage-done'
                : 'timeline-stage-not-done'
            }`}
          >
            <img src={getImageUrl('Events/timeline2.svg')} alt="icon" />
          </div>
          <div
            className={`dot ${
              feedStatusCheck(4)
                ? 'timeline-stage-done'
                : 'timeline-stage-not-done'
            }`}
          >
            <img src={getImageUrl('Events/timeline3.svg')} alt="icon" />
          </div>
          <div
            className={`dot ${
              feedStatusCheck(4)
                ? 'timeline-stage-done'
                : 'timeline-stage-not-done'
            }`}
          >
            <img src={getImageUrl('Events/timeline4.svg')} alt="icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
