import * as taskTypes from './task.constant';
import { ITaskTemplateState, initialTaskTemplateState } from './task.state';

export const taskReducer = (
  state: ITaskTemplateState = initialTaskTemplateState,
  action: {
    [x: string]: string | number;
    type: string;
    page: number;
    payload: any;
    count: number;
    pageNo: number;
    groupIndex: number;
    groupId: number;
    taskId: number;
  }
): ITaskTemplateState => {
  switch (action.type) {
    case taskTypes.GET_TASKS_GROUPS:
      return {
        ...state,
        taskGroups: action['payload'],
        totalCount: action['count'],
      };
    case taskTypes.GET_GROUP_DETAIL:
      return {
        ...state,
        taskGroupDetail: action['payload'],
        taskGroupTasks: action['payload']?.['tasks'],
      };
    case taskTypes.GET_GROUP_TASK_DETAIL:
      const groupData = action['payload']?.filter(
        (d) => d.id === action?.groupId
      );
      const groupTask =
        groupData &&
        groupData?.[0]?.['tasks']?.filter((d) => d.id === action?.taskId);

      return {
        ...state,
        groupTaskDetail: groupTask[0],
      };
    case taskTypes.CLEAR_GROUP_TASK_DETAIL:
      return {
        ...state,
        groupTaskDetail: {},
      };
    default:
      return state;
  }
};
