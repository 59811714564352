import * as blockTypes from './buildingblocks.constant';
import { IBlocksState, initialBlockState } from './buildingblocks.state';

export const blockReducer = (
  state: IBlocksState = initialBlockState,
  action: {
    type: string;
    page: number;
    payload: any;
    blockCount: any;
    isCommercial: any;
  }
): IBlocksState => {
  switch (action.type) {
    case blockTypes.GET_BLOCKS:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        blockCount: action.blockCount,
        isCommercial: action.isCommercial,
        allBuildingBlock: action.payload,
      };
    case blockTypes.BUILDING_BLOCK_ARRAY:
      return {
        ...state,
        buildingBlockArray: action?.payload?.newBlockArray?.newBlockArray,
        buildingType: action?.payload?.buildingType,
        buildingId: action?.payload?.buildingId,
        buildingName: action?.payload?.buildingName,
        editBlock: action?.payload?.editBlock,
        blockId: action?.payload?.blockId,
      };
    case blockTypes.ADD_BUILDING_BLOCKS:
      return {
        ...state,
        addBuildingBlockData: true,
      };
    case blockTypes.SEARCH_BLOCKS_DATA:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        blockCount: action.blockCount,
        isCommercial: action.isCommercial,
      };
    case blockTypes.UPDATE_BUILDING_BLOCK:
      return {
        ...state,
        updateBuildingBlockData: true,
      };
    case blockTypes.EMPTY_UNITS_LIST:
      return {
        ...state,
        allBuildingBlock: [],
      };
    default:
      return state;
  }
};
