import { getStaffWithMngIdApi } from './staff.service';
import {
  getStaffApi,
  addStaffApi,
  getStaffDetailApi,
  updateStaffApi,
  deleteStaffApi,
} from './staff.service';
import * as staffTypes from './staff.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as toastTypes from '../toast/toast.constant';
import * as formTypes from '../form/form.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import { Staff, DeleteResponse, PaginatedResponse } from '../../models';

export const getStaffListing = (
  limit?: number,
  page?: number,
  cmp?: string,
  query?: string,
  all?: number,
  updateReducer = true
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const paginatedResponse: PaginatedResponse<Staff> = await getStaffApi(
    cmp,
    limit,
    page,
    query,
    all
  );
  if (paginatedResponse.data && updateReducer) {
    dispatch({
      type: staffTypes.GET_STAFF,
      payload: paginatedResponse.data,
      staffCount: paginatedResponse.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else if (paginatedResponse.data && !updateReducer) {
    return paginatedResponse.data;
  }
};

export const getBuildingStaffWithMngId = (
  managementId: number,
  buildingId?: number,
  limit?: number,
  page?: number,
  cmp?: string,
  query?: string,
  all?: number
) => async (dispatch) => {
  const paginatedResponse: PaginatedResponse<Staff> = await getStaffWithMngIdApi(
    managementId,
    buildingId,
    cmp,
    limit,
    page,
    query,
    all
  );
  if (paginatedResponse.data) {
    dispatch({
      type: staffTypes.GET_STAFF,
      payload: paginatedResponse.data,
      staffCount: paginatedResponse.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const searchStaff = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: staffTypes.SEARCH_STAFF_DATA,
      payload: data,
      staffCount: data.length,
      page,
    });
  } else {
    await getStaffListing(10, page, 'short', query)(dispatch);
  }
};

export const addStaff = (data: Staff) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const staff: Staff = await addStaffApi(data);
  if (staff) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.staff.added,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getStaffDetail = (staffId: number) => async (dispatch) => {
  const staff: Staff = await getStaffDetailApi(staffId);
  if (staff) {
    dispatch({
      type: formTypes.UPDATE_BUILDING_STAFF,
      payload: staff,
    });
  }
};

export const updateStaff = (staffId: number, data: Staff) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedStaff: Staff = await updateStaffApi(staffId, data);
  if (updatedStaff) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.staff.updated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteStaff = (staffId: number) => async (dispatch) => {
  const deleteResponse: DeleteResponse = await deleteStaffApi(staffId);
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (deleteResponse.delete) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.staff.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
