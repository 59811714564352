import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterRegion from '../Common/RegionModal';
import { CategorySearchDropdown } from '@alfredclub/hom-planner-theme';
import { openRegionModal } from '../../redux/modal/modal.action';
import { IModalState } from '../../redux/modal/modal.state';
import { getRegions } from '../../redux/region';
import { CloseDropdownOnOutsideClick, defaultRegions } from '../../utils';
import { getCategoryTags, IServiceState } from '../../redux/service';
import Cookies from 'universal-cookie';
import {
  getFilteredVendors,
  IVendorState,
  setFilteredVendors,
  setVendorTable,
} from '../../redux/vendor';
const CommonFilters: React.FC<{ setPage?: (page: number) => void }> = ({
  setPage,
}) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRefRegion = useRef<HTMLDivElement>(null);
  const [regionSelected, setRegionSelected] = useState([]);
  const [openTagMenu, setTagMenuOpen] = useState<boolean>(false);
  const [openRegionMenu, setRegionMenuOpen] = useState<boolean>(false);
  const [resetValue, reset] = useState<boolean>(false);
  const cookies = new Cookies();
  const { openRegion } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );
  const { vendorFilters } = useSelector(
    (state: { vendors: IVendorState }) => state.vendors
  );
  const { categoryTagsList } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const selectAnotherRegion = async () => {
    await dispatch(openRegionModal());
    await dispatch(getRegions(0, 10, 'short', 1));
    setRegionMenuOpen(false);
  };
  const clearSelection = async (isRegion?: boolean, isTag?: boolean) => {
    setPage(0);
    await dispatch(setVendorTable(false));
    await dispatch(
      setFilteredVendors({
        regions: isRegion ? [] : vendorFilters?.['regions'],
        categoryTags: isTag ? [] : vendorFilters?.['categoryTags'],
      })
    );
    setTagMenuOpen(false);
    setRegionMenuOpen(false);
    await dispatch(
      getFilteredVendors(
        10,
        0,
        'short',
        isRegion ? [] : vendorFilters?.['regions']?.[0]?.['id'],
        isTag ? [] : vendorFilters?.['categoryTags']?.[0]?.['id']
      )
    );
    await dispatch(setVendorTable(true));
  };
  const onSave = async () => {
    setPage(0);
    await dispatch(setVendorTable(false));
    await dispatch(
      getFilteredVendors(
        10,
        0,
        'short',
        vendorFilters?.['regions']?.[0]?.['id'],
        vendorFilters?.['categoryTags']?.[0]?.['id']
      )
    );
    await dispatch(setVendorTable(true));
    setRegionMenuOpen(false);
    setTagMenuOpen(false);
  };
  CloseDropdownOnOutsideClick(
    wrapperRefRegion,
    setRegionMenuOpen,
    openRegionMenu
  );
  const isTagFilterActive = () => {
    if (
      Object.keys(vendorFilters).length > 0 &&
      vendorFilters?.['categoryTags'] &&
      vendorFilters?.['categoryTags'].length > 0
    ) {
      return true;
    }
    return false;
  };
  const isRegionFilterActive = () => {
    if (
      Object.keys(vendorFilters).length > 0 &&
      vendorFilters?.['regions'] &&
      vendorFilters?.['regions'].length > 0
    ) {
      return true;
    }
    return false;
  };
  CloseDropdownOnOutsideClick(wrapperRef, setTagMenuOpen, openTagMenu);
  return (
    <div className="inline-flex space-x-2 mt-8">
      <div ref={wrapperRefRegion}>
        <CategorySearchDropdown
          classNamePrefix="vendor-category-select-ddl"
          labelKey="regionName"
          valueKey="id"
          options={
            cookies.get('regions')
              ? cookies.get('regions').reverse()
              : defaultRegions()
          }
          categoryLabel={
            isRegionFilterActive() || resetValue
              ? vendorFilters?.['regions']?.[0]?.['regionName']
              : 'Market'
          }
          onOptionChange={async (e) => {
            await dispatch(
              setFilteredVendors({
                regions: [e],
                categoryTags:
                  vendorFilters?.['categoryTags'] &&
                  vendorFilters?.['categoryTags'].length > 0
                    ? vendorFilters?.['categoryTags']
                    : [],
              })
            );
          }}
          chooseAnotherOption={selectAnotherRegion}
          onClearIconClick={() => clearSelection(true, false)}
          isMulti={false}
          recentSearchLabelText="Recently searched market"
          isSimpleDropdown={false}
          onMenuOpen={() => {
            setRegionMenuOpen(true);
            reset(false);
          }}
          menuIsOpen={openRegionMenu}
          onSaveBtnClick={onSave}
          resetValue={resetValue}
          value={isRegionFilterActive() ? vendorFilters?.['regions']?.[0] : []}
        />
      </div>
      <FilterRegion
        modalState={openRegion}
        selectedRegion={regionSelected}
        setRegion={setRegionSelected}
        isVendorFilter={true}
        setReset={reset}
        setPage={setPage}
      />
      <div ref={wrapperRef}>
        <CategorySearchDropdown
          classNamePrefix="vendor-category-select-ddl"
          dropdownDivClass="status-filter-ddl"
          labelKey="name"
          valueKey="id"
          options={categoryTagsList}
          onOptionChange={async (e) => {
            await dispatch(
              setFilteredVendors({
                regions:
                  vendorFilters && vendorFilters?.['regions']
                    ? vendorFilters?.['regions']
                    : [],
                categoryTags: [e],
              })
            );
          }}
          categoryLabel="Category tag"
          onSaveBtnClick={onSave}
          isMulti={false}
          isSimpleDropdown={true}
          onMenuOpen={async () => {
            if (categoryTagsList.length <= 0) {
              await dispatch(getCategoryTags());
            }
            setTagMenuOpen(true);
          }}
          menuIsOpen={openTagMenu}
          onClearIconClick={() => {
            clearSelection(false, true);
          }}
          resetValue={false}
          value={
            isTagFilterActive() ? vendorFilters?.['categoryTags']?.[0] : {}
          }
        />
      </div>
    </div>
  );
};
export default CommonFilters;
