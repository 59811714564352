export const GET_ACCOUNTS_USERS = 'GET_ACCOUNTS_USERS';
export const SEARCH_ACCOUNTS_USERS = 'GET_ACCOUNTS_USERS';
export const GET_ACCOUNTS_VENDORS = 'GET_ACCOUNTS_VENDORS';
export const SEARCH_ACCOUNTS_VENDORS = 'GET_ACCOUNTS_VENDORS';
export const GET_ACCOUNTS_STAFF = 'GET_ACCOUNTS_STAFF';
export const SEARCH_ACCOUNTS_STAFF = 'GET_ACCOUNTS_STAFF';
export const EMAIL_ERROR = 'EMAIL_ERROR';
export const RESET_EMAIL_ERROR = 'RESET_EMAIL_ERROR';
export const SET_USER_IDS = 'SET_USER_IDS';
export const EMPTY_SET_USER_IDS = 'EMPTY_SET_USER_IDS';
