import React, { useEffect, useState } from 'react';
import { useFetch } from '../../hooks';
import {
  deleteGroupInventoryItems,
  deleteInventoryGroup,
  getInventoryGroups,
} from '../../redux/inventory/inventory.action';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Loader } from '@alfredclub/hom-planner-theme';
import { findIndexFromArrayOfObject, getImageUrl } from '../../utils';
import InventoryDeleteItemPopup from './DeleteItemPopup';
import CreateEditGroupPopup from './CreateEditGroup';
import { useHistory } from 'react-router-dom';
import Accordion from '../Common/Accordian/Accordion';
import { TOOLTIP_TEXT } from '../../constants/index';
import ReactTooltip from 'react-tooltip';
import { IInventoryState } from '../../redux/inventory';
import InventoryTable from './InventoryTable';
import { addEventsInventoryGroups, IEventsState } from '../../redux/event';
import { addServiceInventoryGroups } from '../../redux/service';

const IMAGE_BASE = 'data-resources/inventory';

const InventoryGroups: React.FC<{
  eventsInventory?: boolean;
  serviceInventory?: boolean;
  setTabState?: (tab: string) => void;
}> = ({ eventsInventory = false, serviceInventory = false, setTabState }) => {
  const { push } = useHistory();
  const [selectedItem, setSelectedItem] = useState([]);
  const { status } = useFetch(getInventoryGroups, ['', 1]);
  const [modalState, modalStateToggle] = useState(false);
  const [addGroupState, toggleAddGroupState] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [groupItem, setGroupData] = useState(null);
  const [groupDelete, setGroupDelete] = useState(false);
  const [item, setItem] = useState({ groupId: 0, itemId: 0, index: 0 });
  const { inventoryGroups } = useSelector(
    (state: { inventory: IInventoryState }) => state.inventory
  );
  const queryParams = new URLSearchParams(document.location.search);

  const { eventsInvetoryGroups } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const dispatch = useDispatch();

  const deleteItem = async () => {
    if (!groupDelete) {
      await dispatch(
        deleteGroupInventoryItems(
          item['groupId'],
          item['itemId'],
          item['index']
        )
      );

      await dispatch(getInventoryGroups());
    } else {
      await dispatch(deleteInventoryGroup(item['groupId']));
    }
    modalStateToggle(!modalState);
  };

  useEffect(() => {
    setTabState(queryParams.get('tab'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.get('tab')]);

  const buttonLabel = () => {
    return (
      <>
        <img
          src={getImageUrl(`${IMAGE_BASE}/group-icon.svg`)}
          alt="trunk-label"
        />
        <span>Create Group</span>
      </>
    );
  };
  const onCreateGroup = () => {
    setGroupData(null);
    setEditGroup(false);
    toggleAddGroupState(!addGroupState);
  };

  const onAddItem = (id: number) => {
    const groupItems = inventoryGroups.filter((d) => d.id === id);
    const groupData = window.btoa(
      JSON.stringify({
        groupId: id,
        addItem: true,
        items: groupItems?.[0]?.['items'],
      })
    );
    push(
      `/data-resources/inventory?tab=item-list&pageNo=0&groupData=${groupData}`
    );
  };
  const onEditGroup = (groupData: { index?: any }, index: any) => {
    groupData.index = index;
    setGroupData(groupData);
    setEditGroup(true);
    toggleAddGroupState(!addGroupState);
  };

  const handleOnChange = async (rowData: any) => {
    const items = [...selectedItem];
    const itemIdIndex = findIndexFromArrayOfObject(items, 'id', rowData);
    if (itemIdIndex !== -1) {
      items.splice(itemIdIndex, 1);
    } else {
      const newData = rowData.items.map((v) => {
        delete v.id;
        return serviceInventory
          ? {
              ...v,
              itemCount: 0,
              element: { id: null },
              manuallyAssign: false,
            }
          : {
              ...v,
              itemCount: 0,
              vendor: { id: null },
              manuallyAssign: false,
            };
      });
      items.push({ ...rowData, items: [...newData] });
    }
    setSelectedItem(() => [...items]);
    await dispatch(
      serviceInventory
        ? addServiceInventoryGroups(items)
        : addEventsInventoryGroups(items)
    );
  };
  return status === 'fetched' ? (
    <section className="group_wrapper ml-8 mr-8">
      <Button
        label={buttonLabel()}
        type="button"
        className="create_group_btn"
        onClick={onCreateGroup}
      />
      <CreateEditGroupPopup
        onClosePopup={() => {
          setGroupData(null);
          toggleAddGroupState(false);
          setEditGroup(false);
        }}
        modalState={addGroupState}
        edit={editGroup}
        groupItem={groupItem}
      />
      <InventoryDeleteItemPopup
        modalClose={() => {
          modalStateToggle(!modalState);
          setGroupDelete(false);
        }}
        modalState={modalState}
        headerText={
          !groupDelete
            ? 'Are you sure you want to remove this item from the group?'
            : 'Are you sure you want to delete this group?'
        }
        subHeader={
          groupDelete ? 'You will not lose any items in the group' : ''
        }
        buttonLabel={!groupDelete ? 'Yes, remove item' : 'Yes, delete'}
        cancelButtonLabel="No, cancel"
        onDelete={deleteItem}
        isDelete={true}
      />
      {inventoryGroups.map(
        (
          group: {
            name?: any;
            id?: number;
            trunk?: any;
            items?: any;
            index?: any;
          },
          index: number
        ) => {
          return (
            <div key={index} className={`m-0 ${index <= 0 && 'mt-5'}`}>
              <Accordion
                title={
                  <p className="flex mb-2">
                    {eventsInventory && (
                      <input
                        type="checkbox"
                        className="checkbox mx-2"
                        onChange={(e) => {
                          e.stopPropagation();
                          handleOnChange(group);
                        }}
                        checked={
                          findIndexFromArrayOfObject(
                            eventsInvetoryGroups,
                            'id',
                            group
                          ) !== -1
                            ? true
                            : false
                        }
                      />
                    )}
                    <span
                      data-tip={TOOLTIP_TEXT.inventory.group.edit}
                      className="cursor-pointer underline text-F18"
                      onClick={() => onEditGroup(group, index)}
                    >
                      {group.name}
                    </span>
                    {group.trunk && (
                      <img
                        className="ml-2"
                        src={getImageUrl(`${IMAGE_BASE}/trunk-label.svg`)}
                        alt={group.name}
                      />
                    )}
                  </p>
                }
                content={
                  <>
                    <InventoryTable
                      data={group.items}
                      showingGroups={true}
                      groupIndex={index}
                      showPagination={false}
                      modalStateToggle={modalStateToggle}
                      showCheckBox={false}
                      groupItem={item}
                      groupId={group.id}
                      setGroupItem={setItem}
                    />
                    <button
                      className="float-none text-F16 pt-3 text-Color_2 inline-flex"
                      type="button"
                      onClick={() => onAddItem(group.id)}
                    >
                      + Add item to group
                    </button>
                  </>
                }
                invClass="group-name"
              />
            </div>
          );
        }
      )}
      <ReactTooltip />
    </section>
  ) : (
    <Loader
      color="#e89a89"
      position="center"
      loading={status !== 'fetched'}
      size="0.75rem"
    />
  );
};

export default InventoryGroups;
