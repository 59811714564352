import React, { useState } from 'react';
import { getImageUrl, linkString } from '../../utils';
import { TOOLTIP_TEXT } from '../../constants/index';
import ReactTooltip from 'react-tooltip';
import DeletePopup from './DeletePopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearGroupTaskDetail,
  deleteGroupTask,
  getGroupTaskDetail,
  getTaskGroups,
  IGroupTasks,
  ITaskTemplateState,
} from '../../redux/taskTemplate';

/*
  1. TaskListItem is cloned in Draggable list component
  2. Item is comming from Draggable list component
*/
const GroupTaskList: React.FC<{
  item?: IGroupTasks;
  drawer: () => void;
  groupId?: number;
  setEditGroupTask?: any;
}> = ({ item, drawer, setEditGroupTask }) => {
  const [showTaskDelete, setTaskDelete] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { taskGroups } = useSelector(
    (state: { taskTemplate: ITaskTemplateState }) => state.taskTemplate
  );

  const deleteTask = async () => {
    const itemObj = {
      title: item.title,
      description: item.description,
      timeOfTask: item.timeOfTask,
      timeValue: item.timeValue,
      documentLink: item.documentLink,
    };
    await dispatch(deleteGroupTask(item.id, itemObj));
    await dispatch(getTaskGroups(''));
    setTaskDelete(!showTaskDelete);
  };

  const timeObjValue =
    item['timeOfTask'] === 0
      ? 'Day before the event'
      : item['timeValue'] < 0
      ? 'Event day - before event starts'
      : item['timeValue'] > 0 && 'Event day - After event starts';

  const getTask = async (data) => {
    setEditGroupTask(true);
    await dispatch(clearGroupTaskDetail());
    await dispatch(
      getGroupTaskDetail(
        taskGroups,
        Number(data?.['groupId']),
        Number(data?.['id'])
      )
    );
    drawer();
  };

  return (
    <>
      {showTaskDelete && (
        <DeletePopup
          modalState={showTaskDelete}
          modalClose={() => setTaskDelete(!showTaskDelete)}
          onDelete={deleteTask}
          headerText="This will remove the task from all services templates and events permanently."
          subHeader="Are you sure you want to continue?"
          buttonLabel="Yes, continue"
          cancelButtonLabel="No, cancel"
        />
      )}
      <div className="grouptask-item">
        <div onClick={() => getTask(item)}>
          <div className="flex">
            <p className="p_title">{item['title']}</p>
            <p className="p_timeOfTask">
              <img
                src={getImageUrl(`data-resources/time.svg`)}
                alt="time"
                className="pr-2 inline-flex"
              />
              {timeObjValue}
            </p>
          </div>
          <p className="p_vendor">
            <img
              data-tip={TOOLTIP_TEXT.services.task.element}
              src={getImageUrl(`data-resources/element+vendor.svg`)}
              alt="vendor"
              className="pr-2 inline-flex"
            />
            Unassigned
          </p>
          <p className="p_description">
            <img
              data-tip={TOOLTIP_TEXT.services.task.description}
              src={getImageUrl(`data-resources/description.svg`)}
              alt="description"
              className="pr-2 inline-flex"
            />
            {item['description']}
          </p>
          {item['documentLink'] && (
            <a
              href={linkString(item['documentLink'])}
              target="_blank"
              className="cursor-pointer a_link"
              rel="noreferrer"
            >
              <img
                data-tip={TOOLTIP_TEXT.services.task.description}
                src={getImageUrl(`data-resources/link.svg`)}
                alt="description"
                className="pr-2 inline-flex"
              />
              {item['documentLink']}
            </a>
          )}
        </div>
        <img
          onClick={() => {
            setTaskDelete(!showTaskDelete);
          }}
          src={getImageUrl(`data-resources/delete.svg`)}
          alt="delete"
          className="delete_task inline-flex"
        />
      </div>
      <ReactTooltip />
    </>
  );
};

export default GroupTaskList;
