import { TASKS_BASE_URL } from '../../constants/index';
import { http } from '../common/http.service';
import {
  IAddGroupTasks,
  ICreateTaskGroup,
  IDeleteResponse,
  PaginatedResponse,
} from '../../models';
import { AxiosResponse } from 'axios';
import { IGroupTasks, ITaskGroupDetail } from '.';

export const createTaskGroupsApi = async (
  data: object
): Promise<PaginatedResponse<ICreateTaskGroup>> =>
  http
    .post<PaginatedResponse<ICreateTaskGroup>>(`${TASKS_BASE_URL}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<PaginatedResponse<ICreateTaskGroup>>) => {
      return response.data;
    });

export const updateTaskGroupsApi = async (
  data: object,
  groupId: number
): Promise<ICreateTaskGroup> =>
  http
    .put<ICreateTaskGroup>(`${TASKS_BASE_URL}/edit-group/${groupId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<ICreateTaskGroup>) => {
      return response.data;
    });

export const getTasksGroupsApi = async (
  query?: string,
  groupId?: number | null
): Promise<PaginatedResponse<ITaskGroupDetail>> =>
  http
    .get<PaginatedResponse<ITaskGroupDetail>>(
      `${TASKS_BASE_URL}/${query ? `?query=${query}` : ''}${groupId ? `?group_id=${groupId}` : ''}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<ITaskGroupDetail>>) => {
      return response.data;
    });

export const addTaskToGroupsApi = async (
  groupId: number,
  data
): Promise<PaginatedResponse<IAddGroupTasks>> =>
  http
    .post<PaginatedResponse<IAddGroupTasks>>(
      `${TASKS_BASE_URL}/${groupId}/`,
      data,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<IAddGroupTasks>>) => {
      return response.data;
    });

export const getTasksGroupsDetailApi = async (
  groupId: number
): Promise<PaginatedResponse<ITaskGroupDetail>> =>
  http
    .get<PaginatedResponse<ITaskGroupDetail>>(`${TASKS_BASE_URL}/${groupId}/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<PaginatedResponse<ITaskGroupDetail>>) => {
      return response.data;
    });

export const updateTasksOfGroupApi = async (
  itemId: number,
  data: IGroupTasks
): Promise<IGroupTasks> =>
  http
    .put<IGroupTasks>(`${TASKS_BASE_URL}/edit/${itemId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IGroupTasks>) => {
      return response.data;
    });

export const deleteGroupTaskApi = async (
  itemId: number,
  data
): Promise<{ itemId: number }> =>
  http
    .put<{ itemId: number }>(`${TASKS_BASE_URL}/delete/${itemId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<{ itemId: number }>) => {
      return response.data;
    });

export const deleteTaskGroupsApi = async (
  groupId: number
): Promise<IDeleteResponse> =>
  http
    .delete<IDeleteResponse>(`${TASKS_BASE_URL}/${groupId}/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IDeleteResponse>) => {
      return response.data;
    });
