import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Loader, Table, Topnav } from '@alfredclub/hom-planner-theme';
import { useFetch, usePagination } from '../../hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { findIndexFromArrayOfObject, loadState } from '../../utils';
import CommonNavigation from '../Common/CommonNavigation';
import './AddVendor/AddVendorForm.scss';
import { updateServicesBuildings } from '../../redux/form/form.action';
import { getBuildings } from '../../redux/building';
import { IBuildingState } from '../../redux/building/Building.state';

const AddAssociatedBuildings = () => {
  const { status } = useFetch(getBuildings);
  const [pageNo, setPage] = useState(0);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const vendorName = queryParams.get('vendorName');
  const isEdit = Boolean(queryParams.get('edit'));
  const [isCancelIcon, setCancelState] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | object>('');
  const { pages, buildingCount } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const [buildingArray, setBuildingArray] = useState([]);
  const pagination = usePagination();

  const [isVendorContact] = useState(queryParams.get('isContact'));

  const onPageClick = async (page: number) => {
    await pagination(pages, page - 1, getBuildings);
    setPage(page - 1);
  };

  const onSearch = async (query: string) => {
    // await search(pages, buildings, query, searchBuildings, 'name', pageNo);
    let timer = null;
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getBuildings(10, 0, 'short', query, 1, false)
          );
          res(data);
        }, 1000))
    );
  };

  // Table cell checkbox handler
  const handleOnChange = async (_, rowData?: any) => {
    const selectedBuildings = buildingArray;
    const buildingIdIndex = findIndexFromArrayOfObject(
      selectedBuildings,
      'id',
      rowData
    );
    if (buildingIdIndex !== -1) {
      selectedBuildings.splice(buildingIdIndex, 1);
    } else {
      selectedBuildings.push({ id: rowData.id });
    }
    setBuildingArray(() => [...selectedBuildings]);
  };

  const onSubmit = async () => {
    await dispatch(
      updateServicesBuildings(buildingArray, queryParams.get('service'))
    );
    if (!isEdit) {
      push(
        `/data-resources/vendors/add-indvendor?vendorName=${vendorName}${
          isVendorContact
            ? `&vendorContact=${queryParams.get('vendorContact')}`
            : ``
        }`
      );
      return;
    }
    push(`/data-resources/vendors/${queryParams.get('vendorId')}`);
  };
  const onClickCancel = async () => {
    await dispatch(getBuildings(10, 0, 'short', '', 0, true));
    setSearchValue('');
    setCancelState(false);
  };

  useEffect(() => {
    if (isEdit) {
      const formData = loadState('vendorIndividualForm');
      const serviceType =
        queryParams.get('service') === 'venu'
          ? 'venueServices'
          : 'onlineServices';
      if (
        formData[serviceType]?.['buildings'] &&
        formData[serviceType]?.['buildings'].length > 0
      ) {
        setBuildingArray(() => [...formData[serviceType]?.['buildings']]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div>
      <Topnav
        headerTitle="Add Associated Buildings"
        showSearchBar={true}
        promiseOptions={onSearch}
        placeholder="Search Building"
        optionLabel="name"
        optionKey="id"
        showCancelIcon={isCancelIcon}
        onSearchOptionSelect={async (option) => {
          if (option) {
            setSearchValue(option);
            await dispatch(
              getBuildings(10, 0, 'short', '', 1, true, option.id)
            );
            setCancelState(true);
          }
        }}
        onClickOfCloseIcon={onClickCancel}
        defaultSearchValue={[searchValue]}
      />
      <div className="mx-10 add-associated pt-3">
        {status === 'fetched' ? (
          <div>
            <CommonNavigation
              linkTo="#"
              header={vendorName}
              subHeader="Add Associated Buildings"
              className="navigation__link"
            />
            <Button
              backgroundColor="#ffffff"
              className="px-sort-button mt-2"
              label="Sort by"
              buttonText="#5d5d5d"
              type="button"
            />

            <Button
              label="Save and go back"
              className="staff-add"
              type="button"
              onClick={onSubmit}
            />
            <Table
              tableColumns={[
                {
                  id: 'checkbox',
                  Header: 'Sno',
                  accessor: 'id',
                  Cell: (row: any) => {
                    return (
                      <div className="table_checkbox">
                        <input
                          type="checkbox"
                          className="checkbox"
                          onChange={(e) => {
                            handleOnChange(e, row.row.original);
                          }}
                          checked={
                            buildingArray.length > 0
                              ? findIndexFromArrayOfObject(
                                  buildingArray,
                                  'id',
                                  row.row.original
                                ) !== -1
                                ? true
                                : false
                              : false
                          }
                        />
                      </div>
                    );
                  },
                },
                {
                  Header: '',
                  accessor: 'buildingType',
                  Cell: (row: any) => (
                    <div
                      className={
                        row.value === 'R'
                          ? 'circle_cell'
                          : 'circle_cell_company'
                      }
                    >
                      {row.value}
                    </div>
                  ),
                },
                {
                  Header: 'Building',
                  className: 'tableAnchor',
                  accessor: 'name',
                },
                {
                  Header: 'Management Company',
                  accessor: 'management',
                },
                {
                  Header: 'Cluster',
                  accessor: 'clusterName',
                },
                {
                  Header: 'Status',
                  accessor: (row: any) => (!row.active ? 'Inactive' : 'Active'),
                },
              ]}
              tableData={pages?.get(pageNo)?.data}
              onCellClick={() => {}}
              showPagination={!(buildingCount < 10) && true}
              totalRecordsCount={buildingCount}
              className="mt-2"
              onPageChange={(pageNumber) => onPageClick(pageNumber)}
              onCellMouseEnter={() => {}}
            />
          </div>
        ) : (
          <Loader
            color="#e89a89"
            position="center"
            loading={status !== 'fetched'}
            size="0.75rem"
          />
        )}
      </div>
    </div>
  );
};
export default AddAssociatedBuildings;
