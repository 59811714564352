import { Pagination } from '../../models';
import { Map } from 'immutable';

export interface ISpaceState extends Pagination {
  spaces: object;
  spacesCount: number;
  eventSpaces: object;
  spaceData: object;
}

export const initialSpaceState: ISpaceState = {
  spaces: {},
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  spacesCount: 0,
  spaceData: null,
  eventSpaces: {},
};
