import { TinyObject } from './../../models/tiny';
import { Pagination } from '../../models';
import { Map } from 'immutable';
export interface InventoryItemsState {
  id: number;
  name: string;
  location: string;
  itemCount?: number;
  vendor?: TinyObject;
}

export interface InventoryGroupsState {
  id: number;
  name: string;
  trunk?: boolean;
  items: InventoryItemsState[];
  itemCount?: number;
  vendor?: TinyObject;
  manuallyAssign?: boolean;
}

export interface IInventoryState extends Pagination {
  inventoryGroups: InventoryGroupsState[];
  inventoryItems: object;
  totalCount: number;
}

export const initialInventoryState: IInventoryState = {
  inventoryGroups: [],
  inventoryItems: {},
  totalCount: 0,
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
};
export interface IGroupItem {
  groupId: number;
  itemId: number;
  index: number;
}
