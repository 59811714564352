import React, { Fragment } from 'react';
import { Input } from '@alfredclub/hom-planner-theme';
import FileInput from '../../Common/FileInput';
import { getUploadedImageUrl, splitImageName } from '../../../utils';
import { useDispatch } from 'react-redux';
import { removeImage } from '../../../redux/common/common.action';
import { Register } from '../../../models';

const AddSpaceInfo: React.FC<{
  fields: any[];
  register: Register;
  spaceId: number;
  error: any;
  // refreshSpaceForm: any;
  reset: (
    values?: Record<string, any>,
    omitResetState?: Record<string, boolean>
  ) => void;
  spaceData: object;
  spaceName?: string;
}> = ({
  fields,
  register,
  spaceId,
  error,
  // refreshSpaceForm,
  reset,
  spaceData,
  spaceName,
}) => {
  const dispatch = useDispatch();
  const onRemoveImage = async (name: string) => {
    await dispatch(removeImage({ [name]: { id: null } }, 'spaceForm'));
    // reset(loadState('spaceForm')); // File list error resolved
    // refreshSpaceForm(loadState('spaceForm')); // Refresh New state
  };

  return (
    <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
      {fields.map((field, index) => {
        return (
          <Fragment key={field.id}>
            <Input
              label="Space name"
              name={`spaces[${index}].name`}
              ref={register({ required: true })}
              isLabelShow={true}
              inputSectionClass="m-0"
              value={field?.name ?? spaceName}
              isError={error?.['spaces']?.[index]?.['name'] ? true : false}
              isMandatory={true}
            />
            <Input
              label="Space location"
              name={`spaces[${index}].location`}
              ref={register}
              isLabelShow={true}
              inputSectionClass="m-0"
              value={
                spaceId ? (!field?.location ? null : field?.location) : null
              }
            />
            <Input
              label="Max Capacity"
              name={`spaces[${index}].maxCapacity`}
              ref={register({
                validate: {
                  positive: (value: string) => parseInt(value, 10) > 0,
                },
              })}
              isLabelShow={true}
              inputSectionClass="m-0"
              inputType="number"
              value={spaceId ? field?.maxCapacity : null}
              isError={
                error?.['spaces']?.[index]?.['maxCapacity'] ? true : false
              }
            />
            <Input
              label="Goal Capacity"
              name={`spaces[${index}].goalCapacity`}
              isLabelShow={true}
              inputSectionClass="m-0"
              inputType="number"
              ref={register({
                validate: {
                  positive: (value: string) => parseInt(value, 10) >= 0,
                },
              })}
              value={spaceId ? field?.goalCapacity : null}
              isError={
                error?.['spaces']?.[index]?.['goalCapacity'] ? true : false
              }
            />
            <Input
              label="Security Access"
              name={`spaces[${index}].securityAccess`}
              ref={register}
              isLabelShow={true}
              inputSectionClass="m-0"
              value={
                spaceId
                  ? !field?.securityAccess
                    ? null
                    : field?.securityAccess
                  : null
              }
            />
            <Input
              label="Video of the space"
              name={`spaces[${index}].videoUrl`}
              ref={register}
              isLabelShow={true}
              inputSectionClass="m-0"
              value={
                spaceId ? (!field?.videoUrl ? null : field?.videoUrl) : null
              }
            />
            <Input
              label="Access to Water"
              name={`spaces[${index}].water`}
              ref={register}
              isLabelShow={true}
              inputSectionClass="m-0"
              value={spaceId ? (!field?.water ? null : field?.water) : null}
            />
            <Input
              label="Bathroom"
              name={`spaces[${index}].bathroomAccess`}
              ref={register}
              isLabelShow={true}
              inputSectionClass="m-0"
              value={
                spaceId
                  ? !field?.bathroomAccess
                    ? null
                    : field?.bathroomAccess
                  : null
              }
            />
            <Input
              label="Garbage"
              name={`spaces[${index}].garbageArea`}
              ref={register}
              isLabelShow={true}
              inputSectionClass="m-0"
              value={
                spaceId
                  ? !field?.garbageArea
                    ? null
                    : field?.garbageArea
                  : null
              }
            />
            <Input
              label="Trunk"
              isLabelShow={true}
              inputSectionClass="m-0"
              name={`spaces[${index}].trunk`}
              ref={register}
              value={spaceId ? (!field?.trunk ? null : field?.trunk) : null}
            />
            <Input
              label="Outlets"
              isLabelShow={true}
              inputSectionClass="m-0"
              name={`spaces[${index}].outlets`}
              ref={register}
              value={spaceId ? (!field?.outlets ? null : field?.outlets) : null}
            />
            <Input
              label="Wifi name, password"
              isLabelShow={true}
              inputSectionClass="m-0"
              name={`spaces[${index}].wifi`}
              ref={register}
              placeholder="Enter comma-seperated wifi and password here"
              value={spaceId ? (!field?.wifi ? null : field?.wifi) : null}
            />
            {!spaceData?.['floorPlan']?.['file'] ? (
              <FileInput
                label="Floor Plan"
                isLabelShow={true}
                inputSectionClass="m-0"
                name={`spaces[${index}].floorPlan.id`}
                ref={register}
              />
            ) : (
              <Input
                isLabelShow={true}
                isAnchor={true}
                anchorHref={getUploadedImageUrl(
                  spaceData?.['floorPlan']?.['file']
                )}
                label="Floor Plan"
                anchorText={`${splitImageName(
                  spaceData?.['floorPlan']?.['file']
                )}`}
                onRemoveImage={() => onRemoveImage('floorPlan')}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
export default AddSpaceInfo;
