import React, { useEffect, useState } from 'react';
import { getImageUrl } from '../../utils';

const CommonTabCompponent: React.FC<{
  className?: string;
  onTabClick?: any;
  children: JSX.Element[];
}> = ({ className, children, onTabClick = null }: any) => {
  const query = new URLSearchParams(window.location.search);
  const [activeTab, setActiveTab] = useState(query.get('tab'));
  useEffect(() => {
    setActiveTab(query.get('tab'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('tab')]);

  const onTabChange = (d) => {
    if (d?.props['data-click']) {
      setActiveTab(d?.props['data-label'].toLowerCase());
      query.set('tab', d?.props['data-label'].toLowerCase());
      if (query.get('pageNo')) {
        query.delete('pageNo');
      }
      if (onTabClick) {
        onTabClick(d?.props['data-label'].toLowerCase());
      }
      window.history.replaceState(null, null, '?' + query.toString());
    }
  };

  return (
    <>
      <nav className={`flex flex-col sm:flex-row bottom-line  ${className}`}>
        {children?.map(
          (d: JSX.Element, index: number) =>
            d && (
              <span
                key={index}
                onClick={() => {
                  onTabChange(d);
                }}
                className={`m-4 mb-0 cursor-pointer text-center tab_list ${
                  d?.props['data-label'].toLowerCase() === activeTab &&
                  `tab_list_active ${
                    d?.props['data-class'] && 'usertab_Active'
                  }`
                } ${
                  !d?.props['data-click'] &&
                  d?.props['data-label'].toLowerCase() !== activeTab &&
                  'cursor-not-allowed'
                } ${
                  d?.props['data-label'].toLowerCase() === activeTab &&
                  d?.props['data-icon'] &&
                  `event_vendor_active`
                } ${d?.props['data-class'] && 'user_rsvp_tab'}`}
              >
                {d?.props['data-label'].includes('-')
                  ? d?.props['data-label'].replace('-', ' ')
                  : d?.props['data-label']}
                {d?.props['data-label'].toLowerCase() === activeTab && (
                  <img
                    className={`w-full pt-4 ${
                      d?.props['data-class'] && 'hidden'
                    }`}
                    src={
                      d?.props['data-icon']
                        ? getImageUrl(`Events/greentab.svg`)
                        : getImageUrl(`data-resources/tab.svg`)
                    }
                    alt={d?.props['data-label']}
                  />
                )}
                {d?.props['data-class'] && (
                  <span className={`w-full block`}>
                    {d?.props['data-count']}
                  </span>
                )}
              </span>
            )
        )}
      </nav>

      <div className="tab-content">
        {children.map((child) => {
          if (child && child?.props['data-label'].toLowerCase() !== activeTab) {
            return undefined;
          }
          return child && child?.props.children;
        })}
      </div>
    </>
  );
};

export default CommonTabCompponent;
