import {
  getBuildingUserApi,
  getUserDetailApi,
  addUserApi,
  updateUserApi,
  deleteUserApi,
} from './users.service';
import * as userTypes from './user.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as toastTypes from '../toast/toast.constant';
import * as formTypes from '../form/form.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import { DeleteResponse, PaginatedResponse, User } from '../../models';

export const getBuildingUsers = (
  buildingId: number,
  limit?: number,
  page?: number,
  cmp?: string,
  query?: string,
  all?: number
) => async (dispatch) => {
  const paginatedResponse: PaginatedResponse<User> = await getBuildingUserApi(
    buildingId,
    cmp,
    limit,
    page,
    query,
    all
  );
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (paginatedResponse.data) {
    dispatch({
      type: userTypes.GET_BUILDING_USERS,
      payload: paginatedResponse.data,
      userCount: paginatedResponse.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return paginatedResponse.data;
  }
};

export const searchUsers = (data, query, page, buildingId) => async (
  dispatch
) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: userTypes.SEARCH_USERS_DATA,
      payload: data,
      userCount: data.length,
      page,
    });
  } else {
    await getBuildingUsers(buildingId, 10, page, 'short', query)(dispatch);
  }
};

export const addUser = (data: User) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const user: User = await addUserApi(data);
  if (user) {
    dispatch({
      type: userTypes.ADD_USER,
      payload: user,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    if (user?.['id']) {
      dispatch({
        type: toastTypes.SUCCESS_TOAST,
        toastMessage: TOAST_MESSAGES.user.added,
      });
    }
    return user?.['id'];
  }
};

export const getUserDetail = (userId: number) => async (dispatch) => {
  const user: User = await getUserDetailApi(userId);
  if (user) {
    dispatch({
      type: formTypes.UPDATE_USER_FORM,
      payload: user,
    });
  }
};

export const updateUser = (userId: number, data: object) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedUser: User = await updateUserApi(userId, data);
  if (updatedUser) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.user.updated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteUser = (userId: number) => async (dispatch) => {
  const deleteResponse: DeleteResponse = await deleteUserApi(userId);
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (deleteResponse.delete) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.user.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const setSelectedBuilding = (data: any) => async (dispatch) => {
  dispatch({
    type: userTypes.SET_SELECTED_BUILDING,
    payload: data,
  });
};

export const clearUserList = () => async (dispatch) => {
  dispatch({
    type: userTypes.EMPTY_USERS_LIST,
  });
};
