import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

export const useFetch = (action, actionArguments?, callAction = true) => {
  const [status, setStatus] = useState('idle');
  const dispatch = useDispatch();
  const dependecies = [dispatch, action];
  useEffect(() => {
    const fetchData = async () => {
      setStatus('fetching');
      if (callAction && actionArguments) {
        await dispatch(action(...actionArguments));
        setStatus('fetched');
        return;
      }
      if (callAction) {
        await dispatch(action());
        setStatus('fetched');
      }
      if (!callAction) {
        setStatus('fetched');
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependecies);

  return { status };
};
