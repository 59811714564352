import React, { useState } from 'react';
import { Calendar, Loader, Table } from '@alfredclub/hom-planner-theme';
import ReactTooltip from 'react-tooltip';
import { useSetPageNo } from '../../hooks';
import { useSelector, useDispatch } from 'react-redux';
import { getHeroVendors } from '../../redux/event/event.action';
import { IEventsState } from '../../redux/event';
import { EventDashboardProps } from '../../models';
import AddHeroVendorPopup from '../Vendors/AddHeroVendorPopup';
import {
  setQueryParameters,
  getImageUrl,
  getESTTime,
  getDateFormatted,
} from '../../utils';
import {
  EVENT_DATE_FORMAT,
  TOOLTIP_TEXT,
  eventCustomTimeFormat,
} from '../../constants';

const SuperHeroVendors: React.FC<EventDashboardProps> = ({
  status,
  onDateChange,
  onMonthChange,
  clearMonthSelection,
  yearly,
  setYearly,
  calendarSelectedDate,
  pageNo,
  setPage,
}) => {
  const dispatch = useDispatch();
  const { pages, heroVendorsCount, eventCount, eventDateType, monthInfo } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const [heroVendorModal, openHeroVendorModal] = useState<boolean>(false);
  const tableColumns = [
    {
      Header: ' ',
      Cell: ({ row }) => {
        return (
          <span
            data-tip={
              row.original.virtual
                ? TOOLTIP_TEXT.events.virtual
                : TOOLTIP_TEXT.events.inPerson
            }
          >
            <img
              src={
                row.original.virtual
                  ? getImageUrl('Events/virtual.svg')
                  : getImageUrl('Events/inperson.svg')
              }
              alt="virtual"
            />
            <ReactTooltip />
          </span>
        );
      },
      className: 'table-cell table-img',
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return (
          <span>
            {getDateFormatted(row.original.sourceDate, EVENT_DATE_FORMAT)}
          </span>
        );
      },
      className: `table-cell `,
    },
    {
      Header: 'Time',
      Cell: ({ row }) => {
        return (
          <span>
            {getESTTime(
              row.original.startDatetime,
              eventCustomTimeFormat,
              row.original.shsTimezone
            )}
            {' - '}
            {getESTTime(
              row.original.endDatetime,
              eventCustomTimeFormat,
              row.original.shsTimezone
            )}
          </span>
        );
      },
      className: `table-cell`,
    },
    {
      Header: 'Vendor name',
      accessor: 'vendor.name',
    },
    {
      Header: 'Region',
      accessor: 'region.regionName',
    },
    {
      Header: 'Booking type',
      Cell: ({ row }) => {
        return <span>{row.original.recurring ? 'Recurring' : 'Single'}</span>;
      },
    },
    {
      Header: ' ',
      accessor: 'startDatetime',
      className: 'hidden',
    },
  ];
  const onPageClick = async (page: number) => {
    await dispatch(getHeroVendors(10, page, 'short', 0, eventDateType.date));
    setPage(page);
  };
  useSetPageNo(pageNo);
  const onClickUnit = () => {
    openHeroVendorModal(true);
  };
  const toggleModalState = () => {
    openHeroVendorModal(!openHeroVendorModal);
  };
  return (
    <>
      <div className="my-5">
        <Calendar
          isToggleCalendar={false}
          setCalendarDate={async (selectedDate) => {
            await onDateChange(selectedDate);
            setPage(0);
          }}
          selectedClass="date-selected"
          hoverClass="date-hover"
          clearDate={status === 'fetched' ? calendarSelectedDate : false}
          eventCount={eventCount}
          onMonthOrYearChange={onMonthChange}
          showDots={true}
          onViewChange={() => {
            setQueryParameters('0', 'pageNo');
            setPage(0);
            clearMonthSelection();
            setYearly(!yearly);
          }}
          // selectedCalendarDate={monthInfo?.date ?? eventDateType.date}
          selectedCalendarDate={String(monthInfo?.utcDate)}
        />
      </div>
      {status === 'fetched' ? (
        <>
          <div className="float-right cursor-pointer text-Color_5 text-MobileFont flex">
            <img
              src={getImageUrl('Events/superhero.svg')}
              alt="virtual"
              className="mr-2"
            />
            <span className="" onClick={() => onClickUnit()}>
              Mark Superhero
            </span>
          </div>
          <Table
            tableColumns={tableColumns}
            tableData={pages?.get(pageNo)?.data}
            showPagination={!(heroVendorsCount < 10) && true}
            totalRecordsCount={heroVendorsCount}
            onPageChange={(pageNumber) => onPageClick(pageNumber - 1)}
            className="mt-16"
            onCellClick={() => { }}
            onCellMouseEnter={() => { }}
          />
        </>
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
      {heroVendorModal && (
        <AddHeroVendorPopup
          toggleEvent={() => openHeroVendorModal(false)}
          toggleModal={toggleModalState}
          modalState={heroVendorModal}
        />
      )}
    </>
  );
};

export default SuperHeroVendors;
