import React, { useEffect, useState } from 'react';
import CommonTabCompponent from '../Common/CommonTabComponent';
import InventoryGroups from './InventoryGroups';
import InventoryItems from './InventoryItems';
import { Topnav } from '@alfredclub/hom-planner-theme';
import { useDebounce } from '../../hooks';
import { useSelector, useDispatch } from 'react-redux';
import {
  getInventoryGroups,
  getInventoryItems,
} from '../../redux/inventory/inventory.action';
import './inventory.scss';
import Footer from '../Common/Footer';
import { useHistory } from 'react-router-dom';
import { getQueryParameters } from '../../utils';
import {
  addInventoryToEvent,
  clearEventItems,
  IEventsState,
} from '../../redux/event';
import { clearServiceItems, addInventoryToService } from '../../redux/service';

const Inventory = () => {
  const dispatch = useDispatch();
  const { debounce } = useDebounce(1000);

  const { eventsInvetoryItems, eventsInvetoryGroups } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const queryParams = new URLSearchParams(document.location.search);
  const { location, push } = useHistory();
  const eventId = getQueryParameters('event');
  const eventsInventory = location.state?.['eventsInventory'];
  const serviceId = getQueryParameters('service');
  const serviceType = getQueryParameters('serviceType');
  const serviceInventory = location.state?.['serviceInventory'];
  const isEdit = location.state?.['isEdit'];
  const [activeTab, setActiveTab] = useState(queryParams.get('tab'));
  const [showIcon, setIcon] = useState<boolean>(false);
  const [defaultValue, setSearchvalue] = useState<object[]>(null);
  let timer = null;
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  useEffect(() => {
    if (eventsInventory || serviceInventory) {
      (async () =>
        await dispatch(
          eventsInventory ? clearEventItems() : clearServiceItems()
        ))();
    }
  }, [eventsInventory, serviceInventory, dispatch]);
  const onSearch = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          let data = [];
          if (activeTab === 'groups') {
            data = await dispatch(getInventoryGroups(inputValue, 1, false));
            res(data);
            return;
          }
          data = await dispatch(
            getInventoryItems(10, 0, 'short', inputValue, 1, false)
          );
          res(data);
        }, 1000))
    );
  };
  const clearSearchValue = async () => {
    setIcon(false);
    setSearchvalue(null);
    if (activeTab === 'groups') {
      return await dispatch(getInventoryGroups('', 1));
    }
    await dispatch(getInventoryItems());
  };
  return (
    <>
      <Topnav
        headerTitle="Inventory"
        showSearchBar={true}
        placeholder="Search Inventory"
        onSearch={(e) => debounce(onSearch, e.currentTarget.value)}
        promiseOptions={onSearch}
        optionLabel="name"
        optionKey="id"
        onSearchOptionSelect={async (option) => {
          if (option) {
            setSearchvalue([option]);
            setIcon(true);
            if (activeTab === 'groups') {
              dispatch(getInventoryGroups('', 1, true, option.id));
              return;
            }
            await dispatch(
              getInventoryItems(10, 0, 'short', '', 1, true, option.id)
            );
          }
        }}
        showCancelIcon={showIcon}
        defaultSearchValue={defaultValue}
        onClickOfCloseIcon={() => clearSearchValue()}
      />
      <CommonTabCompponent className="ml-4">
        <div data-label="Groups" data-click={true}>
          <InventoryGroups
            eventsInventory={eventsInventory}
            serviceInventory={serviceInventory}
            setTabState={setActiveTab}
          />
        </div>
        <div data-label="Item-List" data-click={true}>
          <InventoryItems
            eventsInventory={eventsInventory}
            serviceInventory={serviceInventory}
            setTabState={setActiveTab}
          />
        </div>
      </CommonTabCompponent>
      {eventsInventory && (
        <Footer
          buttonText="Add to inventory"
          onClick={async () => {
            const updatedData = eventsInvetoryItems.map((item) => {
              delete item['id'];
              return { ...item };
            });
            const data = [
              ...eventsInvetoryGroups,
              { name: '', items: [...updatedData] },
            ];

            await dispatch(
              serviceId
                ? addInventoryToService({ inventory: data }, Number(serviceId))
                : addInventoryToEvent({ inventory: data }, Number(eventId))
            );
            push(
              serviceInventory
                ? !isEdit
                  ? `/data-resources/services/add-service?tab=inventory&serviceType=${serviceType}&id=${serviceId}`
                  : `/data-resources/services/edit-service/${serviceId}?tab=inventory&serviceType=1`
                : `/events/${eventId}/create?step=task-management&edit=true&tab=inventory`,
              serviceInventory
                ? { eventsInventory: false, serviceInventory: false }
                : { eventsInventory: false }
            );
          }}
        />
      )}
    </>
  );
};

export default Inventory;
