import React, { useState } from 'react';
import FormTitle from './FormTitle';
import { useHistory } from 'react-router-dom';
import { Loader } from '@alfredclub/hom-planner-theme';
import { Table } from '@alfredclub/hom-planner-theme';
import { useDispatch } from 'react-redux';
import { getStaffDetail } from '../../../../redux/staff/staff.action';

const BuildingStaff: React.FC<{
  managementCompany: object;
  buildingName: string;
  buildingData: object;
  isEdit: boolean;
  buildingId: number;
}> = ({
  managementCompany,
  buildingName,
  buildingData,
  isEdit,
  buildingId,
}) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);

  const handleOnCellClick = async (e: any) => {
    setLoadingState(true);
    setRowState(e.row.values.id);
    await dispatch(getStaffDetail(e.row.values.id));
    setLoadingState(false);
    setRowState(null);
    window.open(`/data-resources/clients/staff/${e.row.values.id}`, '_blank');
  };

  return (
    <>
      <FormTitle title="Building Staff" />
      <div className="grid grid-cols-1 gap-x-2 gap-y-0 pb-8">
        <div>
          {managementCompany && managementCompany?.['id'] ? (
            <>
              {buildingData['buildingStaff'].length > 0 && (
                <Table
                  tableColumns={[
                    {
                      Header: 'Sno',
                      accessor: 'id',
                      Cell: (row: any) => {
                        return <div>{row.row.index + 1}</div>;
                      },
                    },
                    {
                      Header: 'Building Staff',
                      accessor: (row: any) => (
                        <>
                          {row.name}
                          <Loader
                            color="#e89a89"
                            position="center"
                            loading={showLoader && setRowClick === row.id}
                            size="0.75rem"
                            className={`${
                              showLoader &&
                              setRowClick === row.id &&
                              'row_loader'
                            }`}
                          />
                        </>
                      ),
                      className: 'tableAnchor table_cell',
                    },
                    {
                      Header: 'Designation',
                      accessor: 'designation',
                    },
                    {
                      Header: 'Contact',
                      accessor: 'contact',
                    },
                    {
                      Header: 'Dashboard Access',
                      accessor: 'dashboardAccess',
                      Cell: (row: any) => {
                        return row.row.original['dashboardAccess']
                          ? 'Yes'
                          : 'No';
                      },
                    },
                  ]}
                  onCellClick={(e) => handleOnCellClick(e)}
                  tableData={buildingData['buildingStaff']}
                  showPagination={false}
                  onCellMouseEnter={() => {}}
                />
              )}
              <button
                className="custom_label float-none"
                type="button"
                onClick={() => {
                  if (isEdit) {
                    push(
                      `/data-resources/clients/buildings/add-staff?managementId=${managementCompany?.['id']}&buildingId=${buildingId}&edit=${isEdit}`
                    );
                    return;
                  }
                  push(
                    `/data-resources/clients/buildings/add-staff?buildingName=${buildingName}&managementId=${managementCompany?.['id']}`
                  );
                }}
              >
                +Add Building Staff
              </button>
            </>
          ) : (
            <p className="select-management">
              Select Management company first.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default BuildingStaff;
