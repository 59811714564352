import { AxiosResponse } from 'axios';
import {
  BroadcastMessageTo,
  BroadcastRelatedDetail,
  ChatDetail,
  Event,
  EventInventory,
  IClientReport,
  IVendorRequirments,
  PaginatedResponse,
  SuperHero,
} from '../../models';
import {
  EVENTS_BASE_URL,
  YEAR,
  MONTH,
  EVENTS_PARAM,
  MONTHLY_VIEW,
  YEARLY_VIEW,
  RECORDS_CMP,
  RECORDS_LIMIT,
  RECORDS_PAGE,
  RECORDS_QUERY,
  EVENTS_PER_DAY,
  EVENT_SEARCH_TYPE,
  EVENTS_BASE_RECURRING_URL,
  DEFAULT_PARAM,
  BROADCAST_URL,
  EVENTS_KEYWORDS,
  ALL_RECORDS,
} from '../../constants/index';
import { http } from '../common/http.service';
import { AssignVendorToImportedTasks } from '../service/service.state';
import { Task } from '../../models';

export const getEventsCountApi = async (
  year: string = YEAR,
  month: string | number = MONTH,
  param: string = DEFAULT_PARAM,
  query?: number,
  searchType?: string,
  keywords?: string
) =>
  await http.get(
    `${EVENTS_BASE_URL}/count?param=${param}&year=${year}${
      month ? `&month=${month}` : ``
    }${query ? `&query=${query}` : ''}${
      searchType ? `&search_type=${searchType}` : ''
    }${keywords ? `&keywords=${keywords}` : ''}`,
    {
      errorHandler: true,
    }
  );

export const getEventsApi = async (
  monthly_view: string = MONTHLY_VIEW,
  yearly_view: string = YEARLY_VIEW,
  year: string = YEAR,
  param: string = EVENTS_PARAM,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  per_day: string = EVENTS_PER_DAY,
  searchType: string = EVENT_SEARCH_TYPE,
  keywords: string = EVENTS_KEYWORDS,
  all: number
): Promise<PaginatedResponse<Event>> =>
  await http
    .get<PaginatedResponse<Event>>(
      `${EVENTS_BASE_URL}/?${yearly_view ? `yearly_view=${yearly_view}&` : ``}${
        monthly_view ? `monthly_view=${monthly_view}&` : ''
      }year=${year}&param=${param}&cmp=${RECORDS_CMP}&limit=${RECORDS_LIMIT}&page=${page}${
        query ? `&query=${query}&search_type=${searchType}` : ``
      }${per_day ? `&filter_type=${per_day}` : ``}${
        keywords ? `&keywords=${keywords}` : ''
      }${all ? `&all=${all}` : ''}`,
      { errorHandler: true }
    )
    .then((response: AxiosResponse<PaginatedResponse<Event>>) => {
      return response.data;
    });

export const getCategoryEventsApi = async (
  monthly_view: string = MONTHLY_VIEW,
  param: string = EVENTS_PARAM,
  page: number = RECORDS_PAGE,
  region: number[] | string,
  status: string = ''
): Promise<PaginatedResponse<Event>> =>
  await http
    .get<PaginatedResponse<Event>>(
      `${EVENTS_BASE_URL}/category-search/?${
        monthly_view ? `monthly_view=${monthly_view}` : ''
      }&param=${param}&cmp=${RECORDS_CMP}&limit=${RECORDS_LIMIT}&page=${page}${
        region ? `&regions=[${region}]` : `&regions=[]`
      }${status ? `&status=${status}` : ''}`,
      { errorHandler: true }
    )
    .then((response: AxiosResponse<PaginatedResponse<Event>>) => {
      return response.data;
    });

export const getFilteredServiceListApi = async (
  categoryId: number,
  virtual: number,
  isRecurring?: number
) =>
  await http.get(
    `${EVENTS_BASE_URL}/service-list?category_id=${categoryId}&virtual=${virtual}&isRecurring=${isRecurring}`,
    {
      errorHandler: true,
    }
  );

export const eventSetupApi = async (data: object, recurringCheck: boolean) =>
  await http.post(
    `${recurringCheck ? EVENTS_BASE_RECURRING_URL : EVENTS_BASE_URL + '/'}`,
    data,
    {
      errorHandler: true,
    }
  );

export const getEventDetailApi = async (eventId: number) =>
  await http.get(`${EVENTS_BASE_URL}/details/${eventId}`, {
    errorHandler: true,
  });

export const updateEventSetupApi = async (
  eventId: number,
  data: object,
  update?: boolean,
  publish?: boolean,
) =>
  await http.put(
    `${EVENTS_BASE_URL}/details/${eventId}${update ? `?update=1` : ``}${publish ? `?publish=1` : ``}`,
    data,
    {
      errorHandler: true,
    }
  );

export const updateRecurringSetupApi = async (eventId: number, data: object) =>
  await http.put(
    `${EVENTS_BASE_URL}/recurring/edit/${eventId}/?cloneEdit=1`,
    data,
    {
      errorHandler: true,
    }
  );

export const getAllBuildingsByTimezoneApi = async (
  eventId: number,
  timezone?: string,
  isSchedule?: boolean
) =>
  await http.get(
    `${EVENTS_BASE_URL}/event-buildings/${eventId}/?timezone=${timezone}${
      isSchedule ? `&schedule=1` : ``
    }`,
    {
      errorHandler: true,
    }
  );

export const getClusterBuildingsApi = async (
  eventId: number,
  clusterId: number,
  isRecurring?: boolean,
  inPerson?: boolean,
  eventTimeZone?: string
) =>
  await http.get(
    `${EVENTS_BASE_URL}/event-buildings/${eventId}/?clusterId=${clusterId}${
      inPerson ? `&timezone=${eventTimeZone}` : ``
    }${isRecurring ? `&schedule=1` : ``}`,
    {
      errorHandler: true,
    }
  );

export const getAllBuildingsApi = async (
  eventId: number
): Promise<PaginatedResponse<Event>> =>
  await http
    .get<PaginatedResponse<Event>>(
      `${EVENTS_BASE_URL}/event-buildings/${eventId}/`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Event>>) => {
      return response.data;
    });

export const vendorManualyAssignedApi = async (
  data: object,
  eventId: number,
  isRecurring?: boolean,
  timeZone?: string,
  elementId?: number | null
) =>
  await http.post(
    `${EVENTS_BASE_URL}/vendors/${eventId}/${
      isRecurring
        ? `?schedule=1&regionTimezone=${timeZone}`
        : `?elementId=${elementId}`
    }`,
    data,
    {
      errorHandler: true,
    }
  );

export const cancelAssignmentApi = async (
  eventId: number,
  data: object = {},
  isRecurring?: boolean,
  weekday?: number | null
) =>
  await http.put(
    `${EVENTS_BASE_URL}/vendors/${eventId}/${
      isRecurring ? '?schedule=1' : `?elementId=${weekday}`
    }`,
    data,
    {
      errorHandler: true,
    }
  );

export const sendEamilToVendorApi = async (data: object, eventId: number) =>
  await http.post(`${EVENTS_BASE_URL}/vendors/${eventId}/invite`, data, {
    errorHandler: true,
  });

export const getVendorAssignmentApi = async (
  eventId: number,
  isRecurring: boolean
) =>
  await http.get(
    `${EVENTS_BASE_URL}/vendors/${eventId}/?get_info=1${
      isRecurring ? `&schedule=1` : ``
    }`,
    {
      errorHandler: true,
    }
  );
export const publishEventApi = async (
  publishState: boolean,
  eventId: number,
  isRecurring: boolean
) =>
  await http.post(
    `${
      isRecurring
        ? `${EVENTS_BASE_RECURRING_URL}/edit/${eventId}/`
        : `${EVENTS_BASE_URL}/event-status/${eventId}/`
    }`,
    { publish: publishState },
    {
      errorHandler: true,
    }
  );

export const deleteEventApi = async (eventId: number, isRecurring: boolean) =>
  await http.delete(
    `${
      isRecurring
        ? `${EVENTS_BASE_RECURRING_URL}/${eventId}`
        : `${EVENTS_BASE_URL}/details/${eventId}`
    }`,
    {
      errorHandler: true,
    }
  );

export const cancelEventApi = async (
  eventId: number,
  isCancel?: number,
  isRecurring?: boolean
) =>
  await http.delete(
    `${EVENTS_BASE_URL}/details/${eventId}?cancel=${isCancel}${
      isRecurring ? `&schedule=1` : ``
    }`,
    {
      errorHandler: true,
    }
  );

export const cloneEventApi = async (eventId: number, isRecurring?: boolean) =>
  await http.post(
    `${EVENTS_BASE_URL}/clone/${eventId}/${isRecurring ? `?schedule=1` : ``}`,
    {
      errorHandler: true,
    }
  );

export const getDaysOfWeekApi = async () =>
  await http.get(`${EVENTS_BASE_URL}/recurring?dow=1`, {
    errorHandler: true,
  });

export const getRecurringEventDetailApi = async (scheduleId: number) =>
  await http.get(`${EVENTS_BASE_RECURRING_URL}/${scheduleId}/`, {
    errorHandler: true,
  });

export const updateRecurringEventSetupApi = async (
  scheduleId: number,
  data: object
) => {
  await http.put(
    `${EVENTS_BASE_RECURRING_URL}/${scheduleId}/${
      data?.['timezone']?.id
        ? `?regionTimezone=${data?.['timezone']?.zoneValue}`
        : ''
    }`,
    data,
    {
      errorHandler: true,
    }
  );
};

export const getEventTimelineApi = async (
  eventId: number,
  recurringCheck: boolean,
  timeZone?: string
) =>
  await http.get(
    `${EVENTS_BASE_URL}/event-status/${eventId}/${
      recurringCheck ? `?schedule=1&regionTimezone=${timeZone}` : ``
    }`,
    {
      errorHandler: true,
    }
  );

export const getVendorsWithRegionsApi = async (
  eventId: number,
  isRecurring: boolean,
  weekday: number,
  timeZone?: string
) =>
  await http.get(
    `${EVENTS_BASE_URL}/vendors/${eventId}/${
      isRecurring
        ? `?schedule=1&weekday=${weekday}&regionTimezone=${timeZone}`
        : `?elementId=${weekday}&regionTimezone=${timeZone}`
    }`
  );

export const getUninvitedRegionsVendorsApi = async (
  eventId: number,
  isRecurring: boolean,
  weekday: number,
  timeZone?: string
) =>
  await http.get(
    `${EVENTS_BASE_URL}/vendors/${eventId}/?uninvitedList=1${
      isRecurring
        ? `&schedule=1&weekday=${weekday}&regionTimezone=${timeZone}`
        : `&elementId=${weekday}&regionTimezone=${timeZone}`
    }`
  );

export const getEventTasksApi = async (
  eventId: number,
  isSchedule: boolean,
  timeZone?: string
) =>
  await http.get(
    `${EVENTS_BASE_URL}/tasks/${eventId}${
      isSchedule ? `?schedule=1&regionTimezone=${timeZone}` : ``
    }`,
    {
      errorHandler: true,
    }
  );

export const addSingleEventTaskApi = async (
  eventId: number,
  data: object,
  isSchedule?: boolean,
  isEdit?: boolean,
  timeZone?: string
) =>
  await http.put(
    `${EVENTS_BASE_URL}/tasks/${eventId}${isEdit ? '/edit' : ``}${
      isSchedule ? `?schedule=1&regionTimezone=${timeZone}` : ``
    }`,
    data,
    {
      errorHandler: true,
    }
  );

export const addEventTasksApi = async (
  eventId: number,
  data: object,
  isSchedule?: boolean,
  timeZone?: string
) =>
  await http.post(
    `${EVENTS_BASE_URL}/tasks/${eventId}${
      isSchedule ? `?schedule=1&regionTimezone=${timeZone}` : ``
    }`,
    data,
    {
      errorHandler: true,
    }
  );

export const removeWeekdayFromScheduleApi = async (
  scheduleId: number,
  data: object
) =>
  await http.put(`${EVENTS_BASE_RECURRING_URL}/edit/${scheduleId}/`, data, {
    errorHandler: true,
  });

export const inviteVendorsApi = async (
  eventId: number,
  data: object,
  isRecurring: boolean,
  timeZone?: string,
  elementId?: number | null
) =>
  await http.post(
    `${EVENTS_BASE_URL}/vendors/${eventId}/invite/${
      isRecurring
        ? `?schedule=1&regionTimezone=${timeZone}`
        : `?elementId=${elementId}&regionTimezone=${timeZone}`
    }`,
    data,
    {
      errorHandler: true,
    }
  );

export const getEventUsersApi = async (
  eventId: number,
  rsvp: number,
  page: number = RECORDS_PAGE
) =>
  await http.get(
    `${EVENTS_BASE_URL}/report/${eventId}?rsvp=${rsvp}&limit=${RECORDS_LIMIT}&page=${page}`,
    {
      errorHandler: true,
    }
  );

export const getTaskDetailApi = async (
  eventId: number,
  taskId: number,
  schedule?: boolean,
  timeZone?: string
) =>
  await http.get(
    `${EVENTS_BASE_URL}/tasks/${eventId}/details/${taskId}${
      schedule ? `?schedule=1&regionTimezone=${timeZone}` : ''
    }`,
    {
      errorHandler: true,
    }
  );

export const getChatScreenDetailApi = async (
  eventId: number
): Promise<ChatDetail> =>
  http
    .get(`${EVENTS_BASE_URL}/planner-chat/${eventId}`, { errorHandler: true })
    .then((response: AxiosResponse<ChatDetail>) => {
      return response.data;
    });

export const getBuildingsRelatedToEventApi = async (
  eventId: number
): Promise<BroadcastRelatedDetail> =>
  http
    .get(`${EVENTS_BASE_URL.concat(BROADCAST_URL)}/${eventId}/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<BroadcastRelatedDetail>) => {
      return response.data;
    });

export const sendBroadcastMessageApi = async (
  eventId: number,
  data: BroadcastMessageTo
): Promise<BroadcastMessageTo> =>
  http
    .post(`${EVENTS_BASE_URL.concat(BROADCAST_URL)}/${eventId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<BroadcastMessageTo>) => {
      return response.data;
    });

export const sendTaskListApi = async (
  eventId: number,
  schedule: boolean,
  weekday?: number
) =>
  await http
    .get(
      `${EVENTS_BASE_URL}/tasks/send/${eventId}/?${
        schedule && `schedule=1&weekday=${weekday}`
      }`,
      {
        errorHandler: true,
      }
    )
    .then((response) => {
      return response;
    });

export const getEventsInventoryApi = (eventId: number): Promise<any> =>
  http
    .get(`${EVENTS_BASE_URL}/${eventId}/inventory/`, { errorHandler: true })
    .then((response: AxiosResponse<any>) => response.data);

export const addInventoryToEventApi = async (
  data: object,
  eventId: number
): Promise<EventInventory> =>
  http
    .post<EventInventory>(`${EVENTS_BASE_URL}/${eventId}/inventory/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<EventInventory>) => {
      return response.data;
    });

export const deleteInventoryItemApi = async (itemId: number) =>
  await http.delete(`${EVENTS_BASE_URL}/inventory/${itemId}/`, {
    errorHandler: true,
  });

export const eventInventoryUpadteApi = async (data, itemId: number) =>
  await http.put(`${EVENTS_BASE_URL}/inventory/${itemId}/`, data, {
    errorHandler: true,
  });

export const deleteEventTaskApi = async (taskId: number) =>
  await http.delete(`${EVENTS_BASE_URL}/tasks/${taskId}`, {
    errorHandler: true,
  });

export const sendReminderToVendorApi = async (
  eventId: number,
  schedule: boolean,
  elementId: number,
  data: object
) =>
  await http.post(
    `${EVENTS_BASE_URL}/vendors/${eventId}/invite/?reminder=1${
      schedule ? `&schedule=1` : `&elementId=${elementId}`
    }`,
    data,
    {
      errorHandler: true,
    }
  );

export const getVendorRequirmentApi = (
  eventId: number,
  isRecurring: boolean
): Promise<IVendorRequirments> =>
  http
    .get(
      `${EVENTS_BASE_URL}/vendor-requirements/${eventId}/${
        isRecurring ? `?schedule=1` : ``
      }`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<IVendorRequirments>) => response.data);

export const sendVendorRequirmentApi = async (
  data: object,
  eventId: number,
  elementId: number | null,
  isRecurring: boolean,
  weekday?: number
) =>
  await http.post(
    `${EVENTS_BASE_URL}/vendor-requirements/${eventId}/${
      isRecurring ? `?schedule=1&weekday=${weekday}` : `?elementId=${elementId}`
    }`,
    data,
    {
      errorHandler: true,
    }
  );

export const generateClientReportApi = async (
  data: IClientReport,
  buildingId: number
) =>
  await http.post(`/events/report/${buildingId}/`, data, {
    errorHandler: true,
  });

export const getHeroVendorsApi = async (
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  cmp: string = RECORDS_CMP,
  all: number = ALL_RECORDS,
  monthly_view: string = MONTHLY_VIEW,
  per_day: string = EVENTS_PER_DAY
): Promise<PaginatedResponse<SuperHero>> =>
  await http
    .get<PaginatedResponse<SuperHero>>(
      `${EVENTS_BASE_URL}/superhero-sub/?cmp=${cmp}&limit=${limit}&page=${page}${
        all ? `&all=${all}` : ''
      }${monthly_view ? `&startDate=${monthly_view}` : ''}${
        per_day ? `&filter_type=${per_day}` : ``
      }`,
      { errorHandler: true }
    )
    .then((response: AxiosResponse<PaginatedResponse<SuperHero>>) => {
      return response.data;
    });

export const addSuperHeroApi = async (data: object, recurringCheck: boolean) =>
  await http.post(
    `${EVENTS_BASE_URL}/superhero-sub/${recurringCheck ? '?recurring=1' : ''}`,
    data,
    {
      errorHandler: true,
    }
  );

export const assignVendorToImportedTasksForEventApi = async (
  data: object,
  eventId: number
): Promise<AssignVendorToImportedTasks> =>
  http
    .post<AssignVendorToImportedTasks>(
      `${EVENTS_BASE_URL}/tasks/import/${eventId}/`,
      data,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<AssignVendorToImportedTasks>) => {
      return response.data;
    });

export const removeTaskTemplateFromEventTaskApi = async (
  taskId: number
): Promise<Task> =>
  http
    .put<Task>(
      `${EVENTS_BASE_URL}/tasks/${taskId}/remove`,
      {},
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<Task>) => {
      return response.data;
    });
export const getElementsByServiceApi = async (
  serviceId: number,
  productionTeam: number
) =>
  await http.get(
    `${EVENTS_BASE_URL}/service-list?productionTeam=${productionTeam}&serviceId=${serviceId}`,
    {
      errorHandler: true,
    }
  );

export const getEventsElementslistApi = async (
  eventId: number,
  recurring: boolean
) =>
  await http.get(
    `${EVENTS_BASE_URL}/tasks/elements/${eventId}/${
      recurring ? `?schedule=1` : ``
    }`,
    {
      errorHandler: true,
    }
  );
