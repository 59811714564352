import React from 'react';
import { Link } from 'react-router-dom';
import { SidebarNodeProps } from '../../interfaces';
import './Node.scss';
const Node: React.FC<SidebarNodeProps> = ({
  route,
  routeLabel,
  selectMenu,
  indexValue,
  parentIndex,
  subMenu,
  isSelected,
}) => {
  const selectedClass = (menu, selected) => {
    if (menu && selected) {
      return 'select-sub-menu';
    } else if (selected) {
      return 'select-menu';
    } else {
      return '';
    }
  };
  return (
    <Link to={route}>
      <li
        className={`navlinks ${
          subMenu ? 'subMenuPadding' : 'menuPadding'
        } ${selectedClass(subMenu, isSelected)} `}
        onClick={() => selectMenu(indexValue, parentIndex, subMenu)}
      >
        {routeLabel}
      </li>
    </Link>
  );
};
export default Node;
