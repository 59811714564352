import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dropdown,
  Input,
  PrimaryLabel,
  TextArea,
} from '@alfredclub/hom-planner-theme';
import CommonLoader from '../../Common/CommonLoader';
import { timeOfTaskList, taskStartTime } from '../../../constants/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTaskList,
  getServiceTaskList,
  getPackageElementList,
  editSingleServiceTask,
  getSingleServiceTask,
  removeTaskTemplate,
} from '../../../redux/service/services.action';
import { TASK_TEMPLATE } from '../../../constants';
import { IServiceState } from '../../../redux/service';
import { clearServiceTaskListForm } from '../../../redux/form/form.action';
import { ILoaderState } from '../../../redux/loader';
import { IFormState } from '../../../redux/form';
import { AddServiceTaskProps, Task } from '../../../models';
import DeletePopup from '../../TaskTemplates/DeletePopup';
import './TaskList.scss';

const AddTask: React.FC<AddServiceTaskProps> = ({
  closePane,
  closeTemplatetitle,
  serviceId,
  serviceType,
  isEdit,
  taskId,
  isTaskTemplate,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { register, handleSubmit, setValue, reset, errors } = useForm<Task>();
  const { serviceData, packageElementList } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { serviceTaskDetail } = useSelector(
    (state: { services: IServiceState }) => state.services
  );

  const [showPackageElementList, setPackageElementList] =
    useState(packageElementList);
  const [closeTemplateCheck, seClosetTemplateCheck] = useState<boolean>(false);
  const [loader, setLoading] = useState<boolean>(false);
  const [deleteGroupState, setDeleteGroup] = useState<boolean>(false);
  const [selectedTimeOfTask, setSelectedTimeOfTask] = useState<object[]>([]);
  const [taskTimeLabel, setTaskTimeLabel] = useState(null);
  const [isProdTeamVendor] = useState<boolean>(false);

  const { packageElementForm, serviceSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const elementsAssociated = packageElementForm?.['packageElements'].map(
    (item: any) => ({
      id: item.id,
    })
  );
  const [isPackageCheck] = useState(
    serviceSetupForm?.isPackage
      ? serviceSetupForm?.isPackage
      : serviceData?.['isPackage']
  );
  const [elementName] = useState(
    serviceSetupForm?.name ? serviceSetupForm?.name : serviceData?.['name']
  );

  useEffect(() => {
    if (packageElementList?.length) {
      setPackageElementList([]);
      const packageArray = [...packageElementList];
      if (
        serviceSetupForm?.['productionTeams']?.length > 0 &&
        serviceSetupForm?.['productionTeamRequired']
      ) {
        serviceSetupForm?.['productionTeams']?.forEach((item) => {
          packageArray.push({
            id: item.vendorType.id,
            name: item.vendorType.name,
            productionTeamTask: true,
          });
        });
      }
      setPackageElementList([...showPackageElementList, ...packageArray]);
    } else {
      setPackageElementList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageElementList]);

  const TaskTimeObjValue = taskStartTime.filter(
    (data) => data.value === taskTimeLabel
  );

  // *********** Single element with production team vendors **********
  const [elementList, setElementList] = useState([]);
  useEffect(() => {
    if (
      serviceSetupForm?.['productionTeams']?.length > 0 &&
      serviceSetupForm?.['productionTeamRequired']
    ) {
      setElementList([]);
      elementList.push({
        id: serviceId,
        name: elementName,
        productionTeamTask: false,
      });
      serviceSetupForm?.['productionTeams']?.forEach((item) => {
        elementList.push({
          id: item.vendorType.id,
          name: item.vendorType.name,
          productionTeamTask: true,
        });
      });
    } else {
      elementList.push({
        id: serviceId,
        name: elementName,
        productionTeamTask: false,
      });
    }
    setElementList([...elementList]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedValue =
    serviceTaskDetail?.['timeValue'] &&
    TaskTimeObjValue.length > 0 &&
    TaskTimeObjValue[0].timeValues.filter(
      (data) => data.value === serviceTaskDetail?.['timeValue']
    );

  const addEditTask = async (data: Task) => {
    data.productionTeamTask = isProdTeamVendor;
    if (!isEdit) {
      await dispatch(addTaskList(serviceId, { taskList: [data] }));
    } else {
      data['elementId'] = data?.['element']?.['id'];
      delete data['vendorName'];
      await dispatch(editSingleServiceTask(serviceTaskDetail?.['id'], data));
    }
  };

  const getTaskList = async () => {
    if (serviceType > 1) {
      if (elementsAssociated?.length > 0) {
        await dispatch(getServiceTaskList(serviceId));
      }
    } else {
      await dispatch(getServiceTaskList(Number(serviceId)));
    }
  };

  const onSubmit = async (data: Task) => {
    data['eventLead'] = false;
    setLoading(true);
    await addEditTask(data);
    await dispatch(clearServiceTaskListForm());
    await getTaskList();
    setLoading(false);
    reset();
    seClosetTemplateCheck(false);
    closePane();
  };

  const onCancelDeleteClick = () => {
    setDeleteGroup(!deleteGroupState);
  };

  const onDeleteGroup = async () => {
    setLoading(true);
    setDeleteGroup(!deleteGroupState);
    await dispatch(removeTaskTemplate(taskId));
    seClosetTemplateCheck(true);
    closeTemplatetitle();
    setLoading(false);
  };

  useEffect(() => {
    if (isEdit) {
      setSelectedTimeOfTask([
        {
          label:
            Number(serviceTaskDetail?.['timeOfTask']) === 0
              ? 'Day before event'
              : Number(serviceTaskDetail?.['timeValue']) < 1
              ? 'Event day - before event starts'
              : 'Event day - After event starts',
          value: Number(serviceTaskDetail?.['timeOfTask']),
        },
      ]);
      setTaskTimeLabel(
        serviceTaskDetail?.['timeOfTask'] === 0
          ? 0
          : serviceTaskDetail?.['timeValue'] < 1
          ? 1
          : 2
      );
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTaskDetail]);

  useEffect(() => {
    async function getTaskDetailMethod() {
      if (isEdit) {
        reset({
          ...serviceTaskDetail,
          timeOfTask: serviceTaskDetail?.['timeOfTask'],
          timeValue: serviceTaskDetail?.['timeValue'],
        });
        return;
      }
    }
    getTaskDetailMethod();
    return () => {
      reset({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, serviceTaskDetail]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (taskId) {
        await dispatch(getSingleServiceTask(taskId));
        setLoading(false);
        return;
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, taskId]);

  return (
    <>
      {loader ? (
        <CommonLoader loading={loader} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-12 mt-10 grid grid-cols-2 gap-4">
            {isTaskTemplate && !closeTemplateCheck && (
              <div className="col-span-2 w-full inline-flex justify-between remove-template-status">
                <PrimaryLabel labelText="Templated tasks are not editable. To enable edit, this task would need to be converted to non-template status." />
                <span
                  className="remove-template-text cursor-pointer"
                  onClick={() => setDeleteGroup(!deleteGroupState)}
                >
                  Remove template status and edit
                </span>
              </div>
            )}
            <div className="col-span-2">
              <PrimaryLabel labelText="Assign to Vendor of" />
              <PrimaryLabel labelText="*" className="text-red-500" />
              <Dropdown
                options={isPackageCheck ? showPackageElementList : elementList}
                loading={loading}
                label="Element"
                labelKey="name"
                isMulti={true}
                valueKey="id"
                ref={() => register({ name: 'element' }, { required: true })}
                placeholder="Select elements"
                classNamePrefix="allservice-elements"
                onMenuOpen={async () => {
                  if (isPackageCheck && showPackageElementList?.length <= 0) {
                    await dispatch(getPackageElementList(Number(serviceId)));
                  }
                }}
                onOptionChange={(e) => {
                  setValue('element', e);
                }}
                resetValue={loading || !isEdit}
                value={(isEdit && serviceTaskDetail?.['element']) ?? []}
                isError={errors['element'] ? true : false}
              />
            </div>
            <div>
              <PrimaryLabel labelText="Schedule Task" />
              <Dropdown
                options={timeOfTaskList}
                label="Time of task"
                ref={() => register({ name: 'timeOfTask' })}
                labelKey="label"
                valueKey="value"
                placeholder="Select time"
                onOptionChange={(e) => {
                  const timeOfTask = Number(e['headerValue']);
                  setTaskTimeLabel(Number(e['value']));
                  setValue('timeOfTask', timeOfTask);
                }}
                resetValue={loading || !isEdit}
                value={isEdit && selectedTimeOfTask}
                disabled={isTaskTemplate && !closeTemplateCheck}
              />
            </div>
            <div>
              <PrimaryLabel
                labelText={`${
                  TaskTimeObjValue.length > 0
                    ? TaskTimeObjValue[0].label
                    : 'Task time'
                }`}
              />
              <Dropdown
                options={
                  TaskTimeObjValue.length > 0 && TaskTimeObjValue[0].timeValues
                }
                label="Task time"
                ref={() => register({ name: 'timeValue' })}
                placeholder="Select when"
                labelKey="title"
                valueKey="value"
                onOptionChange={(e) => {
                  const timeValue = Number(e['value']);
                  setValue('timeValue', timeValue);
                }}
                resetValue={loading || !isEdit}
                disabled={isTaskTemplate && !closeTemplateCheck}
                value={
                  isEdit && [
                    {
                      title: selectedValue?.[0]?.['title'],
                      value: selectedValue?.[0]?.['value'],
                    },
                  ]
                }
              />
            </div>
            <div className="col-span-2">
              {errors.title && (
                <div className="error title_error">Title is required</div>
              )}
              <PrimaryLabel labelText="Task Title" />
              <PrimaryLabel labelText="*" className="text-red-500" />
              <Input
                label="Task Title"
                name="title"
                placeholder="Type here"
                ref={register({ required: true })}
                value={isEdit ? serviceTaskDetail?.['title'] : ''}
                className={
                  isTaskTemplate &&
                  !closeTemplateCheck &&
                  'disable-input-fields pointer-events-none text-Color_4'
                }
              />
            </div>
            <div className="col-span-2">
              <PrimaryLabel labelText="Task Description" />
              <TextArea
                name="description"
                label="Task Description"
                ref={register}
                placeholder="Type here.."
                defaultValue={isEdit ? serviceTaskDetail?.['description'] : ''}
                className={
                  isTaskTemplate &&
                  !closeTemplateCheck &&
                  'disable-input-fields pointer-events-none text-Color_4'
                }
              />
            </div>
            <div className="col-span-2">
              <PrimaryLabel labelText="Add Link" />
              <Input
                label="Add Link"
                name="documentLink"
                placeholder="Paste link here"
                ref={register}
                value={isEdit ? serviceTaskDetail?.['documentLink'] : ''}
                className={
                  isTaskTemplate &&
                  !closeTemplateCheck &&
                  'disable-input-fields pointer-events-none text-Color_4'
                }
              />
            </div>
          </div>
          <Button
            label="Save changes"
            className={`${
              loading ? 'add_to_list button_disable' : 'add_to_list'
            }`}
            disabled={loading}
          />
        </form>
      )}
      {deleteGroupState && (
        <DeletePopup
          modalState={deleteGroupState}
          modalClose={() => onCancelDeleteClick()}
          onDelete={onDeleteGroup}
          headerText={TASK_TEMPLATE.removeTemplateTask}
          subHeader="Are you sure you want to continue?"
          buttonLabel="Yes, continue"
          cancelButtonLabel="No, cancel"
        />
      )}
    </>
  );
};

export default AddTask;
