import { InventoryItemsState } from './../inventory/inventory.state';
import {
  BroadcastMessageTo,
  ICategoryFilters,
  IClientReport,
  IEventDateType,
  ISearchEventBy,
  IMonthInfo,
} from './../../models/event';
import { TOAST_MESSAGES } from '../../constants';
import * as loadingTypes from '../loader/loader.constant';
import * as eventTypes from './event.constant';
import * as formTypes from '../form/form.constant';
import * as toastTypes from '../toast/toast.constant';
import * as autosaveTypes from '../autosave/autosave.constant';
import { apiStatusCheck } from '../../utils';
import { Event, PaginatedResponse } from '../../models';
import {
  getEventsCountApi,
  getEventsApi,
  getFilteredServiceListApi,
  eventSetupApi,
  getEventDetailApi,
  updateEventSetupApi,
  getAllBuildingsByTimezoneApi,
  getAllBuildingsApi,
  vendorManualyAssignedApi,
  cancelAssignmentApi,
  sendEamilToVendorApi,
  getVendorAssignmentApi,
  publishEventApi,
  deleteEventApi,
  getDaysOfWeekApi,
  getRecurringEventDetailApi,
  updateRecurringEventSetupApi,
  getEventTimelineApi,
  getVendorsWithRegionsApi,
  getEventTasksApi,
  addSingleEventTaskApi,
  addEventTasksApi,
  removeWeekdayFromScheduleApi,
  inviteVendorsApi,
  cancelEventApi,
  getEventUsersApi,
  getTaskDetailApi,
  getChatScreenDetailApi,
  getClusterBuildingsApi,
  getBuildingsRelatedToEventApi,
  sendBroadcastMessageApi,
  sendTaskListApi,
  getEventsInventoryApi,
  addInventoryToEventApi,
  deleteInventoryItemApi,
  eventInventoryUpadteApi,
  deleteEventTaskApi,
  getUninvitedRegionsVendorsApi,
  sendReminderToVendorApi,
  getVendorRequirmentApi,
  sendVendorRequirmentApi,
  cloneEventApi,
  generateClientReportApi,
  updateRecurringSetupApi,
  getHeroVendorsApi,
  addSuperHeroApi,
  getCategoryEventsApi,
  assignVendorToImportedTasksForEventApi,
  removeTaskTemplateFromEventTaskApi,
  getElementsByServiceApi,
  getEventsElementslistApi,
} from './event.service';
import { startLoading, stopLoading } from '../common';
import { store } from '../store';

const timeout = null;

export const getEventsCount =
  (
    year?: string,
    month?: string | number,
    param?: string,
    query?: number,
    searchType?: string,
    keywords?: string
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const eventsCount = await getEventsCountApi(
      year,
      month,
      param,
      query,
      searchType,
      keywords
    );
    if (apiStatusCheck(eventsCount)) {
      dispatch({
        type: eventTypes.GET_EVENTS_COUNT,
        payload: eventsCount.data.data,
      });
    }
  };

export const getEvents =
  (
    param,
    page?,
    per_day?,
    monthly_view?,
    yearly_view?,
    year?,
    query?,
    searchType?,
    keywords?,
    all?: number,
    updateReducer = true
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse: PaginatedResponse<Event> = await getEventsApi(
      monthly_view,
      yearly_view,
      year,
      param,
      page,
      query,
      per_day,
      searchType,
      keywords,
      all
    );
    if (paginatedResponse.data && updateReducer) {
      dispatch({
        type: eventTypes.GET_EVENTS,
        payload: paginatedResponse.data,
        total: paginatedResponse.total,
        page: page ?? 0,
        param,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    } else if (paginatedResponse.data && !updateReducer) {
      return paginatedResponse.data;
    }
  };

export const getCategoryFilteredEvents =
  (param, page?, monthly_view?, region?, status?) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse: PaginatedResponse<Event> =
      await getCategoryEventsApi(monthly_view, param, page, region, status);
    if (paginatedResponse.data) {
      dispatch({
        type: eventTypes.GET_EVENTS,
        payload: paginatedResponse.data,
        total: paginatedResponse.total,
        page: page ?? 0,
        param,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const searchEvents =
  (data, query, page, param, monthlyView, yearlyView, year, searchType) =>
  async (dispatch) => {
    if (data.length > 0 && query !== '') {
      dispatch({
        type: eventTypes.SEARCH_EVENTS,
        payload: data,
        total: data.length,
        page,
        param,
      });
    } else {
      if (query === '') {
        searchType = 'buildings';
      }
      await getEvents(
        param,
        page,
        '',
        monthlyView,
        yearlyView,
        year,
        query,
        searchType
      )(dispatch);
    }
  };

export const getEventServiceList =
  (categoryId: number, virtual: number, isRecurring?: number) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const apiResponse = await getFilteredServiceListApi(
      categoryId,
      virtual,
      isRecurring
    );
    if (apiStatusCheck(apiResponse)) {
      dispatch({
        type: eventTypes.GET_EVENT_SERVICES,
        payload: apiResponse.data.data,
        isRecurring,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const clearEvents = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_ALL_EVENTS,
  });
};

export const getClusterBuildings =
  (
    eventId: number,
    clusterId: number,
    isRecurring: boolean = false,
    clearClusterBuildings: boolean = false,
    inPerson?: boolean,
    eventTimeZone?: string
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    if (!clearClusterBuildings) {
      const apiResponse = await getClusterBuildingsApi(
        eventId,
        clusterId,
        isRecurring,
        inPerson,
        eventTimeZone
      );
      if (apiStatusCheck(apiResponse)) {
        if (isRecurring) {
          dispatch({
            type: formTypes.GET_CLUSTER_BUILDINGS_ISRECURRING,
            payload: apiResponse.data.data,
          });
        } else {
          dispatch({
            type: formTypes.GET_CLUSTER_BUILDINGS_SINGLE,
            payload: apiResponse.data.data,
          });
        }
        dispatch({
          type: loadingTypes.LOADING_STOPED,
        });
      }
    }
    if (clearClusterBuildings && isRecurring) {
      dispatch({
        type: formTypes.CLEAR_CLUSTER_BUILDINGS_ISRECURRING,
        clusterId,
      });
    } else if (clearClusterBuildings) {
      dispatch({
        type: formTypes.CLEAR_CLUSTER_BUILDINGS_SINGLE,
        clusterId,
      });
    }
  };

export const saveDropdownData = (options, isRecurring) => async (dispatch) => {
  if (isRecurring) {
    dispatch({
      type: formTypes.GET_CLUSTER_BUILDINGS_SINGLE_OPTION_ISRECURRING,
      payload: options,
    });
  } else {
    dispatch({
      type: formTypes.GET_CLUSTER_BUILDINGS_SINGLE_OPTION,
      payload: options,
    });
  }
};

export const clearFilterServiceList = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_SERVICE_LIST,
  });
};

export const eventCreation =
  (data: Event, recurringCheck: boolean) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const setupData = await eventSetupApi(data, recurringCheck);
    if (apiStatusCheck(setupData)) {
      if (!recurringCheck) {
        dispatch({
          type: formTypes.UPDATE_EVENT_SETUP_FORM,
          payload: setupData.data,
        });
      } else {
        dispatch({
          type: formTypes.UPDATE_RECURRING_SETUP_FORM,
          payload: setupData.data,
        });
      }
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      return setupData?.['data']?.['id'];
    }
  };

export const getEventDetail = (eventId: number) => async (dispatch) => {
  store.dispatch(startLoading());
  const eventData = await getEventDetailApi(eventId);
  if (apiStatusCheck(eventData)) {
    dispatch({
      type: formTypes.UPDATE_EVENT_SETUP_FORM,
      payload: eventData.data,
    });
    store.dispatch(stopLoading());
  }
};
export const updateEvent = (data) => (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_EVENT_SETUP_FORM,
    payload: data,
  });
};

export const disableAutoSave = () => async (dispatch) => {
  dispatch({
    type: autosaveTypes.STOPED,
  });
  clearTimeout(timeout);
};

export const updateEventSetup =
  (eventId: number, data: object, update?: boolean, publish?: boolean) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updatedEventData = await updateEventSetupApi(eventId, data, update, publish);
    if (apiStatusCheck(updatedEventData)) {
      await getEventDetail(eventId)(dispatch);
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const updateRecurringSetup =
  (eventId: number, data: object) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updatedEventData = await updateRecurringSetupApi(eventId, data);

    if (apiStatusCheck(updatedEventData)) {
      await getDayOfWeeks()(dispatch);
      await getRecurringEventDetail(eventId)(dispatch);
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const saveEventInvitees =
  (data: object, isRecurring: boolean) => async (dispatch) => {
    dispatch({
      type: eventTypes.SAVE_EVENT_DATA,
      payload: data,
    });
    if (isRecurring) {
      dispatch({
        type: formTypes.UPDATE_RECURRING_FORM_SCHEDULES,
        payload: data,
      });
    } else {
      dispatch({
        type: formTypes.UPDATE_EVENT_VISIBILITY,
        payload: data,
      });
    }
  };

export const clearEventInviteesForm = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_EVENT_DATA,
  });
};

export const saveEventBuildings =
  (data: Event, isRecurring: boolean) => async (dispatch) => {
    dispatch({
      type: eventTypes.SAVE_EVENT_DATA_BUILDINGS,
      payload: data.buildings,
    });
    if (isRecurring) {
      dispatch({
        type: formTypes.UPDATE_RECURRING_FORM_SCHEDULES,
        payload: data,
      });
    } else {
      dispatch({
        type: formTypes.UPDATE_EVENT_VISIBILITY,
        payload: data,
      });
    }
  };

export const clearEventBuildingForm = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_EVENT_DATA,
  });
};

export const allBuildingsByTimezone =
  (eventId: number, timezone?: string, isSchedule?: boolean) =>
  async (dispatch) => {
    const buildingData = await getAllBuildingsByTimezoneApi(
      eventId,
      timezone,
      isSchedule
    );
    dispatch({
      type: eventTypes.GET_ALL_BUILDINGS_BY_TIMEZONE,
      payload: buildingData.data.data,
    });
  };

export const clearBuildingsByTimezone = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_ALL_BUILDINGS_BY_TIMEZONE,
  });
};

export const getAllBuildings = (eventId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const buildingData = await getAllBuildingsApi(eventId);
  dispatch({
    type: eventTypes.GET_ALL_BUILDINGS,
    payload: buildingData.data,
  });
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const vendorManuallyAssigned =
  (
    data: object,
    eventId: number,
    isRecurring?: boolean,
    timeZone?: string,
    elementId?: number | null
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const manuallyData = await vendorManualyAssignedApi(
      data,
      eventId,
      isRecurring,
      timeZone,
      elementId
    );
    if (apiStatusCheck(manuallyData)) {
      dispatch({
        type: eventTypes.MANUALLY_ASSIGNED,
        payload: manuallyData.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const cancelVendorAssignment =
  (
    eventId: number,
    data?: object,
    isRecurring?: boolean,
    weekday?: number | null
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const cancelAssignment = await cancelAssignmentApi(
      eventId,
      data,
      isRecurring,
      weekday
    );
    if (apiStatusCheck(cancelAssignment)) {
      dispatch({
        type: toastTypes.UDPATE_TOAST,
        toastMessage: TOAST_MESSAGES.eventVendor.cancelled,
      });
      dispatch({
        type: eventTypes.CLEAR_EVENT_VENDOR_ASSIGNMENT,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const sendEmailToVendor =
  (data: object, eventId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const sendEmail = await sendEamilToVendorApi(data, eventId);
    if (apiStatusCheck(sendEmail)) {
      dispatch({
        type: toastTypes.UDPATE_TOAST,
        toastMessage: TOAST_MESSAGES.eventVendor.emailSent,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getVendorAssignmentInformation =
  (eventId: number, isRecurring?: boolean, index: number = null) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    dispatch({
      type: eventTypes.RESET_GETINFO_STATUS,
    });
    const vendorData = await getVendorAssignmentApi(eventId, isRecurring);
    if (vendorData) {
      dispatch({
        type: eventTypes.CLEAR_EVENT_VENDOR_ASSIGNMENT,
      });
      dispatch({
        type: eventTypes.GET_ASSIGNED_VENDOR_INFO,
        payload: vendorData.data.data,
        index,
        isRecurring,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      return vendorData.data.data;
    }
  };

export const clearGetInfoStatus = () => async (dispatch) => {
  dispatch({
    type: eventTypes.RESET_GETINFO_STATUS,
  });
};

export const clearAssignedVendor = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_VENDOR_ASSIGNED,
  });
};

export const clearVendorInviteState = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_VENDOR_ASSIGNMENT_STATE,
  });
};

export const publishEvent =
  (publishState: boolean, eventId: number, isRecurring: boolean) =>
  async (dispatch) => {
    const publishStatus = await publishEventApi(
      publishState,
      eventId,
      isRecurring
    );
    if (apiStatusCheck(publishStatus)) {
      if (publishState) {
        dispatch({
          type: toastTypes.SUCCESS_TOAST,
          toastMessage: TOAST_MESSAGES.events.published,
        });
        return;
      }
      dispatch({
        type: toastTypes.UDPATE_TOAST,
        toastMessage: TOAST_MESSAGES.events.unpublished,
      });
    }
  };

export const pastEventPublish = () => async (dispatch) => {
  dispatch({
    type: toastTypes.UDPATE_TOAST,
    toastMessage: TOAST_MESSAGES.events.pastEvent,
  });
};

export const deleteEvent =
  (eventId: number, isRecurring: boolean) => async (dispatch) => {
    const deleteEventStatus = await deleteEventApi(eventId, isRecurring);
    if (apiStatusCheck(deleteEventStatus)) {
      dispatch({
        type: toastTypes.DELETE_TOAST,
        toastMessage: isRecurring
          ? TOAST_MESSAGES.events.scheduleDeleted
          : TOAST_MESSAGES.events.deleted,
      });
    }
  };

export const cancelEvent =
  (eventId: number, isCancel: number, isRecurring: boolean) =>
  async (dispatch) => {
    const cancelEventStatus = await cancelEventApi(
      eventId,
      isCancel,
      isRecurring
    );
    if (apiStatusCheck(cancelEventStatus)) {
      dispatch({
        type: toastTypes.UDPATE_TOAST,
        toastMessage: isRecurring
          ? TOAST_MESSAGES.events.scheduleCancelled
          : TOAST_MESSAGES.events.cancelled,
      });
    }
  };

export const cloneEvent =
  (eventId: number, isRecurring: boolean) => async (dispatch) => {
    const cloneEventStatus = await cloneEventApi(eventId, isRecurring);
    if (apiStatusCheck(cloneEventStatus)) {
      dispatch({
        type: toastTypes.SUCCESS_TOAST,
        toastMessage: isRecurring
          ? TOAST_MESSAGES.events.scheduledCloned
          : TOAST_MESSAGES.events.cloned,
      });
      return cloneEventStatus.data?.['data']?.['id'];
    }
  };

export const getDayOfWeeks = () => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const apiResponse = await getDaysOfWeekApi();
  if (apiStatusCheck(apiResponse)) {
    dispatch({
      type: eventTypes.GET_WEEK_DAYS,
      payload: apiResponse.data.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getRecurringEventDetail =
  (scheduleId: number) => async (dispatch) => {
    const eventData = await getRecurringEventDetailApi(scheduleId);
    if (apiStatusCheck(eventData)) {
      dispatch({
        type: formTypes.UPDATE_RECURRING_SETUP_FORM,
        payload: eventData.data,
      });
    }
  };

export const updateRecurringEventSetup =
  (scheduleId: number, data: object) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updatedEventData = await updateRecurringEventSetupApi(
      scheduleId,
      data
    );
    if (apiStatusCheck(updatedEventData)) {
      dispatch({
        type: autosaveTypes.STARTED,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getEventTimeline =
  (eventId: number, recurringCheck: boolean, timeZone?: string) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const eventTimelineData = await getEventTimelineApi(
      eventId,
      recurringCheck,
      timeZone
    );
    if (apiStatusCheck(eventTimelineData)) {
      dispatch({
        type: eventTypes.GET_EVENT_TIMELINE,
        payload: eventTimelineData.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getVendorsWithRegion =
  (eventId: number, isRecurring: boolean, weekday: number, timeZone?: string) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const vendors = await getVendorsWithRegionsApi(
      eventId,
      isRecurring,
      weekday,
      timeZone
    );
    if (apiStatusCheck(vendors)) {
      dispatch({
        type: eventTypes.GET_VENDOR_WITH_REGIONS,
        payload: vendors.data.data,
        total: vendors.data.total,
        index: weekday,
        isRecurring,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getUninvitedRegionsVendors =
  (
    eventId: number,
    isRecurring: boolean,
    weekday: number,
    timeZone?: string,
    fieldIndex?: number
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const vendors = await getUninvitedRegionsVendorsApi(
      eventId,
      isRecurring,
      weekday,
      timeZone
    );
    if (apiStatusCheck(vendors)) {
      dispatch({
        type: eventTypes.GET_VENDOR_WITH_REGIONS,
        payload: vendors.data.data,
        total: vendors.data.total,
        index: fieldIndex,
        isRecurring,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const removeVendorWithRegions =
  (vendorId, regionIndex, fieldIndex) => async (dispatch) => {
    dispatch({
      type: eventTypes.REMOVE_VENDOR_WITH_REGIONS,
      payload: { regionIndex, vendorId, fieldIndex },
    });
  };

export const resetVendorArray = (data, index) => async (dispatch) => {
  dispatch({
    type: eventTypes.RESET_VENDOR_WITH_REGIONS,
    payload: data,
    index,
  });
};

export const getEventTask =
  (eventId: number, isSchedule: boolean, timeZone?: string) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const tasks = await getEventTasksApi(eventId, isSchedule, timeZone);
    if (apiStatusCheck(tasks)) {
      dispatch({
        type: eventTypes.GET_EVENT_TASKS,
        payload: tasks.data,
      });
      dispatch({
        type: formTypes.EVENT_TASK_LIST,
        payload: tasks.data,
      });
      dispatch({
        type: eventTypes.UPDATE_EVENT_TASK_GROUP_SELECTED,
        payload: tasks?.data?.data?.[0].groups,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getTaskDetail =
  (eventId: number, taskId: number, schedule?: boolean, timeZone?: string) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const taskDetail = await getTaskDetailApi(
      eventId,
      taskId,
      schedule,
      timeZone
    );
    if (apiStatusCheck(taskDetail)) {
      dispatch({
        type: eventTypes.GET_TASK_DETAIL,
        payload: taskDetail.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };
export const addSingleEventTask =
  (
    eventId: number,
    data: object,
    isSchedule?: boolean,
    isEdit?: boolean,
    timeZone?: string
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const addSingleEventTaskData = await addSingleEventTaskApi(
      eventId,
      data,
      isSchedule,
      isEdit,
      timeZone
    );
    if (apiStatusCheck(addSingleEventTaskData)) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };
export const addEventTasks =
  (eventId: number, data: object, isSchedule?: boolean, timeZone?: string) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const addEventTaskData = await addEventTasksApi(
      eventId,
      data,
      isSchedule,
      timeZone
    );
    if (apiStatusCheck(addEventTaskData)) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const removeWeekdayFromSchedule =
  (scheduleId: number, data: object) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const scheduleData = await removeWeekdayFromScheduleApi(scheduleId, data);
    dispatch({
      type: eventTypes.REMOVE_WEEKDAY,
      payload: scheduleData.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  };

export const inviteVendors =
  (
    eventId: number,
    data: object,
    isRecurring: boolean,
    timeZone?: string,
    index?: number,
    elementId?: number | null
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const invitedVendors = await inviteVendorsApi(
      eventId,
      data,
      isRecurring,
      timeZone,
      elementId
    );
    if (invitedVendors.data) {
      dispatch({
        type: eventTypes.GET_INVITED_VENDORS,
        payload: invitedVendors.data,
        index,
      });
    }
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  };

export const getEventUsers =
  (eventId: number, rsvp: number, page?: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const eventData = await getEventUsersApi(eventId, rsvp, page);
    if (eventData) {
      dispatch({
        type: eventTypes.GET_EVENT_USERS,
        payload: eventData.data.data,
        total: eventData.data.total,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const setEventDateType = (data: IEventDateType) => async (dispatch) => {
  dispatch({
    type: eventTypes.SET_EVENT_DATE_TYPE,
    payload: data,
  });
};

export const getChatDetails = (eventId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const getChatScreenDetail = await getChatScreenDetailApi(eventId);
  if (getChatScreenDetail) {
    dispatch({
      type: eventTypes.GET_CHAT_SCREEN_DETAIL,
      payload: getChatScreenDetail,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const checkRequiredField = (message: string) => (dispatch) => {
  dispatch({
    type: toastTypes.ERROR_TOAST,
    toastMessage: message,
  });
};

export const getBuildingsRelatedToEvent =
  (eventId: number) => async (dispatch) => {
    const broadcastRelatedEventBuildngs = await getBuildingsRelatedToEventApi(
      eventId
    );
    if (broadcastRelatedEventBuildngs) {
      dispatch({
        type: eventTypes.EVENT_BROADCAST_REALTED_DETAIL,
        payload: broadcastRelatedEventBuildngs,
      });
    }
  };

export const sendBroadcastMessage =
  (eventId: number, data: BroadcastMessageTo) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const broadcastMessage = await sendBroadcastMessageApi(eventId, data);
    if (broadcastMessage) {
      dispatch({
        type: eventTypes.SEND_BROADCAST_MESSAGE,
        payload: broadcastMessage,
      });
      dispatch({
        type: toastTypes.SUCCESS_TOAST,
        toastMessage: TOAST_MESSAGES.events.broadcastMessage,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };
export const setSearchEvent = (data: ISearchEventBy) => async (dispatch) => {
  dispatch({
    type: eventTypes.SEARCH_EVENT_BY,
    payload: data,
  });
};
export const setSearchFor = (data: object) => async (dispatch) => {
  dispatch({
    type: eventTypes.SEARCH_EVENT_FOR,
    payload: data,
  });
};
export const setSelectedDate = (data: string) => async (dispatch) => {
  dispatch({
    type: eventTypes.SET_SELECTED_DATE,
    payload: data,
  });
};
export const clearSearchFor = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_SEARCH_EVENT_FOR,
  });
};
export const clearSearchBy = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_SEARCH_EVENT_BY,
  });
};
export const sendTaskList =
  (eventId: number, schedule: boolean, weekday?: number) =>
  async (dispatch) => {
    const sentMessage = await sendTaskListApi(eventId, schedule, weekday);
    if (apiStatusCheck(sentMessage)) {
      dispatch({
        type: toastTypes.SUCCESS_TOAST,
        toastMessage: sentMessage.data.data.message,
      });
    }
  };

export const getEventsInventory = (eventId: number) => async (dispatch) => {
  const eventsInventory = await getEventsInventoryApi(eventId);
  if (eventsInventory.data) {
    dispatch({
      type: eventTypes.GET_EVENTS_INVENTORY,
      payload: eventsInventory.data,
    });
  }
};

export const addEventsInventoryGroups =
  (data: InventoryItemsState[]) => async (dispatch) => {
    dispatch({
      type: eventTypes.UPDATE_EVENTS_INVENTORY_GROUPS,
      payload: data,
    });
  };

export const addEventsInventoryItems = (data: object[]) => async (dispatch) => {
  dispatch({
    type: eventTypes.UPDATE_EVENTS_INVENTORY_ITEMS,
    payload: data,
  });
};

export const addInventoryToEvent =
  (data: object, eventId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    const eventInventoryData = await addInventoryToEventApi(data, eventId);
    if (eventInventoryData) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const clearEventItems = () => (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_ALL_EVENT_INVENTORY,
  });
};

export const deleteEventInventoryItems =
  (itemId: number, rowIndex: number, isGroup: number | null) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const eventsInventory = await deleteInventoryItemApi(itemId);
    if (apiStatusCheck(eventsInventory)) {
      dispatch({
        type: toastTypes.SUCCESS_TOAST,
        toastMessage: TOAST_MESSAGES.inventory.deleted,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      dispatch({
        type: eventTypes.DELETE_EVENTS_INVENTORY_ITEM,
        rowIndex,
        itemId,
        isGroup: isGroup ? true : false,
      });
    }
  };

export const eventInventoryUpadte =
  (
    data,
    itemId?: number,
    rowIndex?: number,
    itemIndex?: number,
    isGroup?: number | null
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updatedEventInventory = await eventInventoryUpadteApi(data, itemId);
    if (updatedEventInventory.data) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      dispatch({
        type: eventTypes.UPDATE_EVENTS_INVENTORY_ITEMS_GROUPS,
        payload: data,
        rowIndex,
        itemIndex,
        isGroup: isGroup ? true : false,
      });
    }
  };

export const deleteEventTask = (taskId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const eventTask = await deleteEventTaskApi(taskId);
  if (apiStatusCheck(eventTask)) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.events.eventTaskDeleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const sendReminderToVendor =
  (eventId: number, schedule: boolean, elementId: number, data: object) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const sendEmail = await sendReminderToVendorApi(
      eventId,
      schedule,
      elementId,
      data
    );
    if (apiStatusCheck(sendEmail)) {
      dispatch({
        type: toastTypes.UDPATE_TOAST,
        toastMessage: TOAST_MESSAGES.eventVendor.reminderSent,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const clearVendorsWithRegions = () => (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_VENDORS_WITH_REGION,
  });
};

export const getVendorRequirements =
  (eventId: number, isRecurring: boolean) => async (dispatch) => {
    const vendorRequirment = await getVendorRequirmentApi(eventId, isRecurring);
    if (vendorRequirment) {
      dispatch({
        type: eventTypes.GET_VENDOR_REQUIRMENTS,
        payload: vendorRequirment['data'],
      });
    }
  };

export const sendVendorRequirements =
  (
    data: object,
    eventId: number,
    elementId: number | null,
    isRecurring: boolean,
    weekday?: number
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updateReq = await sendVendorRequirmentApi(
      data,
      eventId,
      elementId,
      isRecurring,
      weekday
    );
    if (updateReq.data) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const clearVendorRequirements = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_VENDOR_REQUIRMENTS,
  });
};

export const generateClientReport =
  (data: IClientReport, buildingId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const reportData = await generateClientReportApi(data, buildingId);
    if (apiStatusCheck(reportData)) {
      if (reportData?.['data'].length > 0) {
        dispatch({
          type: eventTypes.GENERATE_CLIENT_REPORT,
          payload: reportData?.['data'],
        });
        dispatch({
          type: loadingTypes.LOADING_STOPED,
        });
        return true;
      } else {
        dispatch({
          type: toastTypes.UDPATE_TOAST,
          toastMessage: TOAST_MESSAGES.report.noRecord,
        });
        dispatch({
          type: loadingTypes.LOADING_STOPED,
        });
      }
    }
  };

export const clearClientReport = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_CLIENT_REPORT,
  });
};

export const clearEventTaskDetail = () => async (dispacth) => {
  dispacth({
    type: eventTypes.CLEAR_EVENT_TASK,
  });
};

export const getHeroVendors =
  (
    limit?: number,
    page?: number,
    cmp?: string,
    all?: number,
    monthly_view?: string,
    per_day?
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse: PaginatedResponse<Event> = await getHeroVendorsApi(
      limit,
      page,
      cmp,
      all,
      monthly_view,
      per_day
    );
    if (paginatedResponse.data) {
      dispatch({
        type: eventTypes.GET_HERO_VENDORS,
        payload: paginatedResponse.data,
        total: paginatedResponse.total,
        page: page ?? 0,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const addSuperHero =
  (data: Event, recurringCheck: boolean) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const response = await addSuperHeroApi(data, recurringCheck);
    if (response) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const setCategoryFilters =
  (data: ICategoryFilters) => async (dispatch) => {
    dispatch({
      type: eventTypes.SET_CATEGORY_FILTERS,
      payload: data,
    });
  };

export const importTaskTemplateToEvent = (data: object) => async (dispatch) => {
  dispatch({
    type: eventTypes.IMPORT_TASK_TO_EVENT,
    payload: data,
  });
};

export const assignVendorToImportedTasksForEvents =
  (data: object, eventId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    const serviceTaskData = await assignVendorToImportedTasksForEventApi(
      data,
      eventId
    );
    if (serviceTaskData) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const clearEventImportedTaskState = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_EVENT_IMPORTED_TASK_STATE,
    payload: [],
  });
};

export const removeTaskTemplateFromEventTask =
  (taskId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    await removeTaskTemplateFromEventTaskApi(taskId);
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.taskTemplate.taskRemoved,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  };
export const getElementsByService =
  (serviceId: number, productionTeam: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const apiResponse = await getElementsByServiceApi(
      serviceId,
      productionTeam
    );
    if (apiStatusCheck(apiResponse)) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      return apiResponse.data.data;
    }
  };

export const geteventsElementslist =
  (eventId: number, recurring: boolean, serviceId?: number) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const apiResponse = await getEventsElementslistApi(eventId, recurring);
    if (apiStatusCheck(apiResponse)) {
      dispatch({
        type: eventTypes.GET_EVENT_ELEMENTS_LIST,
        payload: apiResponse.data.element,
        param: serviceId,
        isRecurring: recurring,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const clearGeteventsElementslist = () => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  dispatch({
    type: eventTypes.GET_EVENT_ELEMENTS_LIST,
    payload: [],
  });
};

export const clearEventTaskGroupList = () => async (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_EVENT_TASK_GROUP_LIST,
    payload: [],
  });
};

export const setMonthInfo = (data: IMonthInfo) => async (dispatch) => {
  dispatch({
    type: eventTypes.SET_MONTH_CHANGE,
    payload: data,
  });
};
