import React, { useState, useRef } from 'react';
import { Table, Button, Input } from '@alfredclub/hom-planner-theme';
import {
  findIndexFromArrayOfObject,
  getImageUrl,
  getSerialNumber,
} from '../../utils';
import ReactTooltip from 'react-tooltip';
import { usePagination } from '../../hooks';
import { IInventoryState } from '../../redux/inventory';
import {
  getInventoryItems,
  updateGroupItemLocation,
  updateInventoryItem,
  updateItemLocation,
} from '../../redux/inventory/inventory.action';
import { useDispatch, useSelector } from 'react-redux';
import { TOOLTIP_TEXT } from '../../constants';
import { addEventsInventoryItems } from '../../redux/event';
import { addServiceInventoryItems } from '../../redux/service';
const IMAGE_BASE = 'data-resources/inventory';

const InventoryTable: React.FC<{
  data?: object;
  groupIndex?: number;
  setItemId?: (id: number) => void;
  setGroupItem?: (item: object) => void;
  modalStateToggle?: (state: boolean) => void;
  setPage?: (page: number) => void;
  pageNo?: number;
  showCheckBox?: boolean;
  showPagination?: boolean;
  showingGroups?: boolean;
  groupId?: number;
  eventsInventory?: boolean;
  groupItem?: object;
  setItem?: (data: any) => void;
  itemArray?: object[];
  serviceInventory?: boolean;
}> = ({
  setGroupItem,
  groupItem,
  data,
  setItem,
  itemArray,
  setItemId,
  modalStateToggle,
  pageNo,
  setPage,
  showCheckBox = true,
  showPagination = true,
  showingGroups = false,
  groupIndex,
  groupId,
  eventsInventory = false,
  serviceInventory = false,
}) => {
  const inputEl = useRef(null);
  const renderEditLocation = (value: string, rowId: number) => {
    return (
      <div className="grid grid-cols-2 gap-x-0 gap-y-0">
        <div className="col-span-1 tbl_col_width">
          <Input
            label="testing"
            value={value === '' ? 'Add Item Location' : value}
            placeholder="Add Item Location"
            isLabelShow={false}
            ref={inputEl}
            className="edit_desc_input"
          />
        </div>
        <div className="location-btn-container">
          <Button
            label="Save"
            type="button"
            onClick={() => onLocationSubmit(rowId)}
            className="btn-float-none"
          />
          <Button
            label="Cancel"
            type="button"
            onClick={() => toggleEditLocState({ ...editLoc, show: false })}
            className="btn-float-none"
          />
        </div>
      </div>
    );
  };
  const dispatch = useDispatch();
  const { pages, totalCount } = useSelector(
    (state: { inventory: IInventoryState }) => state.inventory
  );
  const [selectedItem, setSelectedItem] = useState([]);
  const [editLoc, toggleEditLocState] = useState({ show: false, rowIndex: 0 });
  const removeGroupItem = (group: number, id: number, index: number) => {
    groupItem['itemId'] = id;
    groupItem['index'] = index;
    groupItem['groupId'] = group;
    setGroupItem(() => groupItem);
    modalStateToggle(true);
  };
  const removeItem = (id: number) => {
    setItemId(id);
    modalStateToggle(true);
  };

  const onLocationSubmit = async (id: number) => {
    if (showingGroups) {
      await dispatch(
        updateGroupItemLocation(
          groupIndex,
          editLoc.rowIndex,
          inputEl.current.value
        )
      );
    } else {
      await dispatch(
        updateItemLocation(pageNo, editLoc.rowIndex, inputEl.current.value)
      );
    }
    await dispatch(
      updateInventoryItem(id, { location: inputEl.current.value })
    );
    toggleEditLocState({ ...editLoc, show: false });
  };
  const pagination = usePagination();
  const onPageClick = async (page: number) => {
    await pagination(pages, page - 1, getInventoryItems);
    setPage(page - 1);
  };
  const editLocation = (rowIndex: number) => {
    toggleEditLocState({ rowIndex, show: true });
  };
  const handleOnChange = async (rowData: any) => {
    const newData = Object.assign({}, rowData);
    const items = eventsInventory ? [...selectedItem] : [...itemArray];
    const itemIdIndex = findIndexFromArrayOfObject(items, 'id', rowData);
    if (itemIdIndex !== -1) {
      items.splice(itemIdIndex, 1);
    } else {
      if (eventsInventory) {
        items.push(
          serviceInventory
            ? {
                ...newData,
                itemCount: 0,
                element: { id: null },
                manuallyAssign: false,
              }
            : {
                ...newData,
                itemCount: 0,
                vendor: { id: null },
                manuallyAssign: false,
              }
        );
      } else {
        items.push({ ...rowData });
        setSelectedItem(() => [...items]);
      }
    }
    if (eventsInventory) {
      if (serviceInventory) {
        await dispatch(addServiceInventoryItems(items));
      } else {
        await dispatch(addEventsInventoryItems(items));
      }
      setSelectedItem(() => [...items]);
      return;
    }
    // setItem(() => [...selectedItem]);
    setItem(() => [...items]);
  };
  return (
    <>
      <Table
        tableColumns={[
          {
            Header: 'Sno',
            Cell: (row: any) => {
              return showCheckBox ? (
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={() => handleOnChange(row.row.original)}
                  checked={
                    findIndexFromArrayOfObject(
                      itemArray,
                      'id',
                      row.row.original
                    ) !== -1
                      ? true
                      : false
                  }
                />
              ) : (
                <div>
                  {getSerialNumber(showingGroups ? 0 : pageNo, row.row.index)}
                </div>
              );
            },
          },
          {
            Header: 'Item name',
            accessor: 'name',
            className: 'table-cell item_name',
          },
          {
            Header: 'Item Location',
            Cell: ({ row }) => {
              return (
                <>
                  {editLoc.show && editLoc.rowIndex === row.index ? (
                    renderEditLocation(row.original.location, row.original.id)
                  ) : (
                    <p
                      onClick={() => editLocation(row.index)}
                      className="p_desc"
                      placeholder="Add Item Location"
                    >
                      {row.original.location === ''
                        ? 'Add Item Location'
                        : row.original.location}
                    </p>
                  )}
                </>
              );
            },
            className: 'edit_description table-cell',
          },
          {
            Header: ' ',
            Cell: ({ row }) => {
              return (
                <img
                  data-tip={TOOLTIP_TEXT.inventory.item.delete}
                  className="tableAnchor"
                  onClick={() =>
                    showingGroups
                      ? removeGroupItem(groupId, row.original.id, groupIndex)
                      : removeItem(row.original.id)
                  }
                  src={getImageUrl(`${IMAGE_BASE}/remove.svg`)}
                  alt={row.original.name}
                />
              );
            },
            className: 'delete_item',
          },
        ]}
        showPagination={showPagination ? !(totalCount < 10) && true : false}
        totalRecordsCount={totalCount}
        onPageChange={(pageNumber) => onPageClick(pageNumber)}
        tableData={showingGroups ? data : pages?.get(pageNo)?.data}
        onCellClick={() => {}}
        onCellMouseEnter={() => {}}
      />
      <ReactTooltip />
    </>
  );
};

export default InventoryTable;
