import { Cluster } from 'cluster';
import { Map } from 'immutable';
import { Pagination } from '../../models';

export interface IClusterState extends Pagination {
  clusters: Cluster[];
  addClusterData: object;
  clusterCount: number;
  clusterData: object;
}

export const initialClusterState: IClusterState = {
  clusters: [],
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  clusterCount: 0,
  addClusterData: {},
  clusterData: {},
};
