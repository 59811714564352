import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  Loader,
  PrimaryLabel,
  Table,
  Topnav,
  CompanyTable,
} from '@alfredclub/hom-planner-theme';
import { useHistory, useLocation } from 'react-router-dom';
import AddNewCompany from '../Companies/AddNewCompany/AddNewCompany';
import {
  getBuildingBlocks,
  getBuildingBlockDetail,
} from '../../../redux/buildingblocks/buildingblocks.action';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBuildings,
  getBuildingDetail,
  saveBuildingId,
} from '../../../redux/building/buildings.action';
import {
  addBuildingBlocks,
  deleteBuildingBlock,
} from '../../../redux/buildingblocks/buildingblocks.action';
import { usePagination } from '../../../hooks';
import { TOOLTIP_TEXT } from '../../../constants/index';
import { getSerialNumber } from '../../../utils';
import BuildingAddCompanies from '../../Clients/Companies/AddNewCompany/BuildingAddCompanies';
import DeleteCompanyPopup from '../../Clients/Companies/CompanyPopup/DeleteCompanyPopup';
import { IBuildingState } from '../../../redux/building';
import { IBlocksState } from '../../../redux/buildingblocks';

const CompaniesListing = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [status, setStatus] = useState('idle');
  const dispatch = useDispatch();
  const { allBuildings } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const { pages, blockCount, isCommercial } = useSelector(
    (state: { company: IBlocksState }) => state.company
  );
  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);
  const [showLoading, setLoading] = useState(false);
  const query = new URLSearchParams(search);
  const [addBuilding] = useState(query.get('addBuilding'));
  const [building_name] = useState(query.get('building_name'));
  const [building_id] = useState(query.get('buildingId'));
  const [
    showBuildingAddCompaniesPopup,
    setAddBuildingCompaniesPopupState,
  ] = useState(false);
  let indexNumber = 0;
  const [getSelectedBuildingId, setSelectedBuildingId] = useState<number>(
    allBuildings?.length && allBuildings?.[indexNumber]?.['id']
  );
  const [currentBuildingName, setBuildingName] = useState(
    allBuildings?.[indexNumber]?.['name']
  );
  const [currentBuildingType, setBuildingType] = useState(
    allBuildings?.[indexNumber]?.['buildingType']
  );
  const [openAddUnitModal, setAddUnitModal] = useState(false);
  const [openDeleteUnitModal, setDeleteUnitModal] = useState(false);
  const [blockLoader, setBlockLoader] = useState(false);
  const [getBlockId, setBlockId] = useState(0);
  const [getUnitArray, setUnitArray] = useState([]);
  const [buildingId] = useState(query.get('buildingId'));
  const [pageNo, setPage] = useState(0);
  const pagination = usePagination();
  const array = [];
  const size = 14;
  const onAddBuildingCompanies = (e) => {
    e.preventDefault();
    setAddBuildingCompaniesPopupState(true);
  };
  const onCloseCompany = () => {
    setAddBuildingCompaniesPopupState(false);
  };
  if (buildingId && allBuildings?.length) {
    const buildingIdInt = parseInt(buildingId, 10);
    indexNumber = allBuildings.findIndex((x) => x.id === buildingIdInt);
  }
  useEffect(() => {
    const fetchBuildingData = async () => {
      if (!allBuildings?.length) {
        setStatus('fetching');
        await dispatch(getBuildings(0, 0, 'short', '', 1));
        setStatus('fetched');
      }
    };
    const fetchData = async (Id: string | number) => {
      if (Id || allBuildings?.length > 0) {
        setSelectedBuildingId(
          Id ? Number(Id) : Number(allBuildings?.[indexNumber]?.['id'])
        );
        setBlockLoader(true);
        await dispatch(
          isCommercial
            ? getBuildingBlocks(
                Id ? Number(Id) : Number(allBuildings?.[indexNumber]?.['id'])
              )
            : getBuildingBlocks(
                Id ? Number(Id) : Number(allBuildings?.[indexNumber]?.['id']),
                0,
                0,
                'short',
                '',
                1
              )
        );
        setBlockLoader(false);
      }
    };
    fetchBuildingData();
    fetchData(buildingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, allBuildings, buildingId]);

  useEffect(() => {
    if (!isCommercial && pages) {
      const unitArray = pages?.get(pageNo)?.data;
      if (unitArray?.length) {
        while (unitArray.length > 0) {
          array.push(unitArray.splice(0, size));
        }
      }
      setUnitArray(() => [...array]);
    }
    setBuildingType(isCommercial ? 'C' : 'R');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages, isCommercial]);

  // Building blocks click handler
  const handleOnCellClick = async (e: any, row: any) => {
    e.preventDefault();
    setLoadingState(true);
    setRowState(row.id);
    await dispatch(
      getBuildingBlockDetail(parseInt(row.id, 10), getSelectedBuildingId)
    );
    setLoadingState(false);
    history.push(`/data-resources/clients/companies-units/add-companies`);
  };

  const onPageClick = async (page: number) => {
    await pagination(
      pages,
      page - 1,
      getBuildingBlocks(getSelectedBuildingId, 10, page - 1),
      true
    );
    setPage(page - 1);
  };

  const buildingData = {
    building: {
      id: getSelectedBuildingId,
      name: currentBuildingName,
      buildingType: currentBuildingType,
      buildingMode: addBuilding,
    },
    buildingType: currentBuildingType,
  };

  const onClickUnit = () => {
    setAddUnitModal(true);
  };
  const onCloseUnitPopup = async (value) => {
    setAddUnitModal(false);
    if (value) {
      setBlockLoader(true);
      await dispatch(addBuildingBlocks(getSelectedBuildingId, value));
      await dispatch(
        getBuildingBlocks(getSelectedBuildingId, 0, 0, 'short', '', 1)
      );
      setBlockLoader(false);
    }
  };
  const onCloseDeleteUnitPopup = () => {
    setDeleteUnitModal(false);
  };
  const onClickDeleteIcon = (values: any) => {
    setBlockId(values.id);
    setDeleteUnitModal(true);
  };
  const deleteblocks = async () => {
    setDeleteUnitModal(false);
    setBlockLoader(true);
    await dispatch(deleteBuildingBlock(getBlockId));
    await dispatch(
      getBuildingBlocks(getSelectedBuildingId, 0, 0, 'short', '', 1)
    );
    setBlockLoader(false);
  };
  const goToBuilding = async () => {
    if (building_id) {
      setStatus('fetching');
      await dispatch(saveBuildingId(parseInt(building_id, 10)));
      await dispatch(getBuildingDetail(parseInt(building_id, 10)));
      setStatus('fetched');
      history.push(`/data-resources/clients/buildings/${building_id}`);
    } else {
      history.push(
        `/data-resources/clients/buildings/add-building?buildingName=${building_name}`
      );
    }
  };
  let timer = null;
  const onSearch = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getBuildingBlocks(
              getSelectedBuildingId,
              10,
              0,
              'short',
              inputValue,
              1,
              false
            )
          );
          res(data);
        }, 1000))
    );
  };
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return (
    <div>
      {showBuildingAddCompaniesPopup && (
        <AddNewCompany
          modalState={showBuildingAddCompaniesPopup}
          onCloseCompany={onCloseCompany}
        />
      )}
      {openAddUnitModal && (
        <BuildingAddCompanies
          modalState={openAddUnitModal}
          onClosePopup={onCloseUnitPopup}
          buildingBlockData={buildingData}
          addUnitForm={false}
          CommonLayoutCheck={true}
        />
      )}
      {openDeleteUnitModal && (
        <DeleteCompanyPopup
          modalState={openDeleteUnitModal}
          modalClose={onCloseDeleteUnitPopup}
          deleteblock={deleteblocks}
        />
      )}
      <Topnav
        headerTitle="Companies/Units"
        showIcon={true}
        showSearchBar={isCommercial}
        placeholder="Search company"
        onClick={(e) => onAddBuildingCompanies(e)}
        promiseOptions={onSearch}
        optionLabel="name"
        optionKey="id"
        onSearchOptionSelect={async (option) => {
          if (option) {
            setLoadingState(true);
            setRowState(option.id);
            await dispatch(
              getBuildingBlockDetail(option.id, getSelectedBuildingId)
            );
            setLoadingState(false);
            history.push(
              `/data-resources/clients/companies-units/add-companies`
            );
          }
        }}
        toolTipText={TOOLTIP_TEXT.companyUnits.new}
      />
      <>
        {status === 'fetching' ? (
          <Loader
            color="#e89a89"
            position="center"
            loading={true}
            size="0.75rem"
          />
        ) : (
          <div className="mx-10 user_section pt-8">
            <form>
              <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-4 ddl_left_margin">
                <div>
                  {addBuilding && (
                    <div className={`navigation-section mb-8`}>
                      <span
                        className="navigation-link float-none cursor-pointer"
                        onClick={goToBuilding}
                      >
                        Edit Building
                      </span>
                      <span>&gt;</span>
                      <p className="sub-navigation-header">Companies/Units</p>
                    </div>
                  )}
                  <div>
                    <PrimaryLabel labelText="Choose building" />
                    <Dropdown
                      asyncDropdown={false}
                      labelKey="name"
                      valueKey="id"
                      isLabelShow={false}
                      label="Building"
                      options={allBuildings}
                      disabled={addBuilding ? true : false}
                      onMenuOpen={async () => {
                        setLoading(true);
                        if (allBuildings?.length === 0) {
                          await dispatch(getBuildings(0, 0, 'short', '', 1));
                        }
                        setLoading(false);
                      }}
                      loading={showLoading}
                      value={{
                        name: allBuildings?.[indexNumber]?.['name'],
                        id: allBuildings?.[indexNumber]?.['id'],
                      }}
                      onOptionChange={async (e) => {
                        if (buildingId) {
                          history.push(
                            `/data-resources/clients/companies-units`
                          );
                        }
                        const selectedBuildingId = e['id'];
                        const selectedBuildingName = e['name'];
                        const selectedBuildingType = e['buildingType'];
                        if (selectedBuildingId) {
                          setSelectedBuildingId(selectedBuildingId);
                          setBuildingName(selectedBuildingName);
                          setBuildingType(selectedBuildingType);
                          setBlockLoader(true);
                          await dispatch(
                            selectedBuildingType === 'R'
                              ? getBuildingBlocks(
                                  selectedBuildingId,
                                  0,
                                  0,
                                  'short',
                                  '',
                                  1
                                )
                              : getBuildingBlocks(selectedBuildingId)
                          );
                          setBlockLoader(false);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {!blockLoader && (
                <>
                  {' '}
                  <PrimaryLabel
                    labelText={`${
                      isCommercial
                        ? 'Commercial property'
                        : 'Residential property'
                    }`}
                  />
                  <br />
                  <PrimaryLabel
                    className="block-count"
                    labelText={
                      blockCount + (isCommercial ? ' Companies' : ' Units')
                    }
                  />
                </>
              )}
              {isCommercial && !blockLoader && (
                <div className="company-header">
                  <span className="addunits" onClick={() => onClickUnit()}>
                    +Add company
                  </span>
                </div>
              )}
              <div>
                {blockLoader ? (
                  <Loader
                    color="#e89a89"
                    position="center"
                    loading={true}
                    size="0.75rem"
                  />
                ) : isCommercial ? (
                  <Table
                    tableColumns={[
                      {
                        Header: 'Sno',
                        Cell: (row: any) => (
                          <div>{getSerialNumber(pageNo, row.row.index)}</div>
                        ),
                      },
                      {
                        Header: 'Company name',
                        className: 'tableAnchor',
                        accessor: (row: any) => (
                          <>
                            <button
                              onClick={async (e) => {
                                handleOnCellClick(e, row);
                              }}
                            >
                              {row.name}
                            </button>
                            <Loader
                              color="#E89A89"
                              position="center"
                              loading={showLoader && setRowClick === row.id}
                              size="0.75rem"
                              className={`${
                                showLoader &&
                                setRowClick === row.id &&
                                'row_loader'
                              }`}
                            />
                          </>
                        ),
                      },
                      {
                        Header: 'HR manager',
                        accessor: 'hrManagerName',
                      },
                      {
                        Header: 'Contact',
                        accessor: 'contact',
                      },
                      {
                        Header: 'No. of Employees',
                        accessor: 'noOfEmployees',
                      },
                    ]}
                    tableData={pages?.get(pageNo)?.data}
                    showPagination={!(blockCount < 11) && true}
                    totalRecordsCount={blockCount}
                    onPageChange={(pageNumber) => onPageClick(pageNumber)}
                    onCellClick={() => {}}
                    onCellMouseEnter={() => {}}
                  />
                ) : (
                  <CompanyTable
                    buildingTableData={getUnitArray}
                    buildingTableType="Units"
                    className="mt-3"
                    addUnits={() => {
                      onClickUnit();
                    }}
                    deleteUnit={(blockData) => {
                      onClickDeleteIcon(blockData);
                    }}
                  />
                )}
              </div>
            </form>
          </div>
        )}
      </>
    </div>
  );
};

export default CompaniesListing;
