import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import CommonTabCompponent from '../../Common/CommonTabComponent';
import ManuallyAssigned from './ManuallyAssigned';
import VendorInvite from './VendorInvite';
import VendorSettings from './VendorSettings';
import '../Events.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  clearVendorRequirements,
  getVendorAssignmentInformation,
  getVendorRequirements,
  inviteVendors,
  sendVendorRequirements,
  vendorManuallyAssigned,
} from '../../../redux/event';
import { defaultTimezone } from '../../../constants';
import { EventVendor } from '../../../models';
import Footer from '../../Common/Footer';
import { getImageUrl } from '../../../utils';
import { ButtonType } from '../../../enums';
import { IEventsState } from '../../../redux/event';
import { TitleBar } from '@alfredclub/hom-planner-theme';
import CommonLoader from '../../Common/CommonLoader';
import { IFormState } from '../../../redux/form';
import { useFetch } from '../../../hooks';
interface EventData {
  vendorAssign: EventVendor[];
}

const VendorAssignmentForm = () => {
  const {
    handleSubmit,
    register,
    setValue,
    errors,
    control,
    reset,
  } = useForm();

  const {
    recurringSetupForm: eventData,
    eventSetupForm: singleEventData,
  } = useSelector((state: { forms: IFormState }) => state.forms);
  const dispatch = useDispatch();
  const [isVendor, setVendor] = useState(true);
  const [showLoader, setLoaderState] = useState(false);
  const [isUpdateReq, setVendorRequirment] = useState<object>({});
  const params = useParams();
  const { push } = useHistory();
  const eventId = parseInt(params['eventId'], 10);
  const [isAssigned, setAssigned] = useState({});
  useFetch(clearVendorRequirements);
  useFetch(getVendorRequirements, [
    eventId,
    localStorage.getItem('isRecurring') ? true : false,
  ]);

  const onBackButton = () => {
    if (localStorage.getItem('isRecurring')) {
      push(`/events/${eventId}/create?step=recurring&edit=true`);
    } else {
      push(`/events/${eventId}/create?step=setup&edit=true`);
    }
  };

  const onNextButton = () => {
    push(
      `/events/${eventId}/create?step=task-management&edit=true&tab=task-list`
    );
  };

  const { fields, append } = useFieldArray({
    control,
    name: 'vendorAssign',
  });
  const { eventVendorAssigned, vendorRequirements } = useSelector(
    (state: { events: IEventsState }) => state.events
  );

  const updateVendorDocs = async (data, index) => {
    let vendorArray = {};
    if (data?.['vendorAssign']?.length > 0) {
      if (data?.['vendorAssign']?.[index]?.docList) {
        if (eventData?.['recurring'] || singleEventData?.['recurring']) {
          vendorArray = {
            note: data?.['vendorAssign']?.[index]?.note,
            documents: data?.['vendorAssign']?.[index]?.docList,
          };
        } else {
          vendorArray = {
            note: data?.['vendorAssign']?.[index]?.note,
            documents: data?.['vendorAssign']?.[index]?.docList,
            vendorPay: data?.['vendorAssign']?.[index]?.vendorPay,
          };
        }
      } else {
        const docObject = getFilteredDocsNotes(
          localStorage.getItem('isRecurring')
            ? eventData?.['weekDays']?.[index]?.id
            : singleEventData?.['serviceElements']?.[index]?.id
        );
        if (eventData?.['recurring'] || singleEventData?.['recurring']) {
          vendorArray = {
            note: data?.['vendorAssign']?.[index]?.note,
            documents: docObject?.['serviceRelatedData']?.docList ?? [],
          };
        } else {
          vendorArray = {
            note: data?.['vendorAssign']?.[index]?.note,
            documents: docObject?.['serviceRelatedData']?.docList ?? [],
            vendorPay: data?.['vendorAssign']?.[index]?.vendorPay,
          };
        }
      }
    }
    await dispatch(
      sendVendorRequirements(
        vendorArray,
        eventId,
        Number(data?.['vendorAssign']?.[index]?.elementId),
        localStorage.getItem('isRecurring') ? true : false,
        eventData?.['weekDays']?.[index]?.id
      )
    );
    setVendorRequirment({
      ...isUpdateReq,
      [index]: false,
    });
    return;
  };

  const onSubmit = async (e, index) => {
    e.preventDefault();
    setLoaderState(true);
    handleSubmit(async (data: EventData) => {
      // Manually assigned
      if (!isVendor) {
        // If something change in vendor docs, notes, price
        if (isUpdateReq[index]) {
          updateVendorDocs(data, index);
          return;
        }
        if (!isAssigned[index]) {
          data.vendorAssign[index].stream = { id: null };
        }
        if (!data.vendorAssign[index].docList) {
          data.vendorAssign[index].docList = [];
        }
        data.vendorAssign[index].weekday = localStorage.getItem('isRecurring')
          ? eventData?.['weekDays']?.[index].id
          : null;
        await dispatch(
          vendorManuallyAssigned(
            { vendorAssign: [data.vendorAssign[index]] },
            eventId,
            localStorage.getItem('isRecurring') ? true : false,
            eventData?.timezone?.zoneValue ?? defaultTimezone,
            Number(singleEventData?.['serviceElements']?.[index]?.id)
          )
        );
        await dispatch(
          getVendorAssignmentInformation(
            eventId,
            localStorage.getItem('isRecurring') ? true : false,
            index
          )
        );
        setAssigned({
          ...isAssigned,
          [localStorage.getItem('isRecurring')
            ? eventData?.['weekDays']?.[index]?.id
            : singleEventData?.['serviceElements']?.[index]?.id]: true,
        });
        return;
      } else {
        // Vendor invite
        if (isUpdateReq[index]) {
          updateVendorDocs(data, index);
          return;
        }
        await dispatch(
          inviteVendors(
            eventId,
            {
              inviteList: JSON.parse(
                data.vendorAssign[index].inviteList.length > 0
                  ? data.vendorAssign[index].inviteList[0] !== '['
                    ? data.vendorAssign[index].inviteList[0]
                    : data.vendorAssign[index].inviteList
                  : data.vendorAssign[index].inviteList
              ),
            },
            localStorage.getItem('isRecurring') ? true : false,
            eventData?.timezone?.zoneValue ?? defaultTimezone,
            index,
            singleEventData?.['serviceElements']?.[index]?.id
          )
        );
        await dispatch(
          getVendorAssignmentInformation(
            eventId,
            localStorage.getItem('isRecurring') ? true : false,
            index
          )
        );
      }
    })(e);
    setLoaderState(false);
  };

  useEffect(() => {
    async function getVendorAssigned() {
      const vendorAssinment = await dispatch(
        getVendorAssignmentInformation(
          eventId,
          localStorage.getItem('isRecurring') ? true : false
        )
      );
      let assigned = {};
      if (vendorAssinment?.['vendorAssign']?.length > 0) {
        vendorAssinment?.['vendorAssign'].forEach((item) => {
          assigned = {
            ...assigned,
            [localStorage.getItem('isRecurring')
              ? item?.['weekday']
              : item?.['element']?.['id']]: true,
          };
        });
        setAssigned(assigned);
      }
    }
    getVendorAssigned();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newArray = [];
    if (localStorage.getItem('isRecurring')) {
      if (eventData?.['weekDays']?.length > 0) {
        eventData?.['weekDays'].forEach(() => {
          newArray.push({});
        });
        append(newArray);
      } else {
        append([{}]);
      }
    } else {
      if (singleEventData?.['serviceElements']?.length > 0) {
        singleEventData?.['serviceElements'].forEach(() => {
          newArray.push({});
        });
        append(newArray);
      } else {
        append([{}]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData?.['weekDays'], singleEventData?.['serviceElements'], reset]);

  const getFilteredDocsNotes = (elementId: number) => {
    if (vendorRequirements?.length > 0) {
      const vendorRequiremntData = vendorRequirements.filter((data) =>
        localStorage.getItem('isRecurring')
          ? data.weekday === elementId
          : data.elementId === elementId
      );
      const vendorDataObj = {
        serviceRelatedData: {
          docList: vendorRequiremntData?.[0]?.documents,
          note: vendorRequiremntData?.[0]?.note,
          vendorPay: vendorRequiremntData?.[0]?.vendorPay,
        },
      };
      return vendorDataObj;
    }
  };
  return (
    <div className="vendor_assignment">
      {fields.map((field, index) => {
        return (
          <form onSubmit={(e) => onSubmit(e, index)} key={field.id}>
            <div className="col-span-3">
              {eventData?.['weekDays'].length > 0 ? (
                <TitleBar
                  headerTitle={eventData?.['weekDays']?.[index]?.['fullName']}
                  showSubHeader={true}
                  subHeaderClass="no-vendor-assigned-label"
                  subHeader={`${
                    eventVendorAssigned?.[
                      eventData?.['weekDays']?.[index]?.['id']
                    ]?.['vendorAssign']?.[0]?.['vendor']?.['vendorName'] ??
                    'No Vendor Assigned'
                  }`}
                  className="recurring_title col-span-3"
                />
              ) : (
                <TitleBar
                  headerTitle={
                    singleEventData?.['serviceElements']?.[index]?.['name']
                  }
                  showSubHeader={true}
                  subHeaderClass="no-vendor-assigned-label"
                  subHeader={`${
                    eventVendorAssigned?.[
                      singleEventData?.['serviceElements']?.[index]?.id
                    ]?.['vendorAssign']?.[0]?.['vendor']?.['vendorName'] ??
                    'No Vendor Assigned'
                  }`}
                  className="recurring_title col-span-3"
                />
              )}
              <div className="grid grid-cols-3 gap-x-1 gap-y-0">
                <div className="vendorsettings">
                  <VendorSettings
                    formErrors={errors}
                    register={register}
                    setValue={setValue}
                    fieldIndex={index}
                    eventData={getFilteredDocsNotes(
                      localStorage.getItem('isRecurring')
                        ? eventData?.['weekDays']?.[index]?.id
                        : singleEventData?.['serviceElements']?.[index]?.id
                    )}
                    weekday={
                      localStorage.getItem('isRecurring')
                        ? eventData?.['weekDays']?.[index]?.id
                        : singleEventData?.['serviceElements']?.[index]?.id
                    }
                    onEditRequirment={() =>
                      setVendorRequirment({
                        ...isUpdateReq,
                        [index]: true,
                      })
                    }
                    isUpdateReq={isUpdateReq}
                    isRecurring={
                      eventData?.['recurring'] || singleEventData?.['recurring']
                    }
                  />
                </div>
                <div className="col-span-2 h-16">
                  <CommonTabCompponent
                    onTabClick={(tabName) => {
                      if (tabName === 'vendor-invite') {
                        setVendor(true);
                        return;
                      }
                      setVendor(false);
                    }}
                  >
                    <div
                      data-label="Vendor-Invite"
                      data-icon={true}
                      data-click={true}
                    >
                      <VendorInvite
                        fieldIndex={index}
                        register={register}
                        isRecurring={
                          localStorage.getItem('isRecurring') ? true : false
                        }
                        eventTimeZone={
                          eventData?.timezone?.zoneValue ?? defaultTimezone
                        }
                        eventVendorAssigned={eventVendorAssigned}
                        weekday={
                          localStorage.getItem('isRecurring')
                            ? eventData?.['weekDays']?.[index]?.id
                            : singleEventData?.['serviceElements']?.[index]?.id
                        }
                        onCancel={() =>
                          setAssigned({
                            ...isAssigned,
                            [localStorage.getItem('isRecurring')
                              ? eventData?.['weekDays']?.[index]?.id
                              : singleEventData?.['serviceElements']?.[index]
                                  ?.id]: false,
                          })
                        }
                      />
                    </div>
                    <div
                      data-label="Manually-Assign"
                      data-icon={true}
                      data-click={true}
                    >
                      <ManuallyAssigned
                        register={register}
                        setValue={setValue}
                        eventId={eventId}
                        fieldIndex={index}
                        isAssigned={isAssigned}
                        onSubmit={onSubmit}
                        onCancel={() =>
                          setAssigned({
                            ...isAssigned,
                            [localStorage.getItem('isRecurring')
                              ? eventData?.['weekDays']?.[index]?.id
                              : singleEventData?.['serviceElements']?.[index]
                                  ?.id]: false,
                          })
                        }
                        isRecurring={
                          localStorage.getItem('isRecurring') ? true : false
                        }
                        reset={reset}
                        weekday={
                          localStorage.getItem('isRecurring')
                            ? eventData?.['weekDays']?.[index]?.id
                            : singleEventData?.['serviceElements']?.[index]?.id
                        }
                      />
                    </div>
                  </CommonTabCompponent>
                </div>
              </div>
            </div>
          </form>
        );
      })}
      <Footer
        buttonText="Save and Next"
        isArrow={false}
        buttonClass="recurring_continue"
        isRightIcon={true}
        rightIcon={getImageUrl('Events/continuearrow.svg')}
        rightIconClass="pl-4"
        type={ButtonType.BUTTON}
        onClick={() => onNextButton()}
        isLeftButton={true}
        leftButtonClass="footer_left_button"
        leftButtonType={ButtonType.BUTTON}
        onLeftButtonClick={() => onBackButton()}
        leftButtonText="Back"
        className="recurring_footer"
      />
      <CommonLoader loading={showLoader} />
    </div>
  );
};

export default VendorAssignmentForm;
