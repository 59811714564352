import { convertTimeToMMDDYYYY } from './../../utils/index';
import {
  Cluster,
  CompanyVendor,
  Event,
  RecurringSchedule,
  Service,
  SpaceArray,
  Staff,
  User,
  Vendor,
  EventTaskRequest,
} from '../../models';
import { Building } from '../../models/building';
import { Management } from '../../models/management';
import { PricingRequest } from '../../models/pricing';

export interface ElementArrayState {
  packageElements: any[];
}

export interface VendorsArrayState {
  vendorRequirements: any[];
}

export interface IFormState {
  buildingForm: Building;
  managementForm: Management;
  clusterForm: Cluster;
  buildingStaffForm: Staff;
  spaceForm: SpaceArray;
  userForm: User;
  vendorCompanyForm: CompanyVendor;
  vendorIndividualForm: Vendor;
  serviceSetupForm: Service;
  packageElementForm: ElementArrayState;
  serviceVendorForm: VendorsArrayState;
  pricingForm: PricingRequest;
  serviceTaskList: Service[];
  eventSetupForm: Event;
  contractLapse: string;
  propertyType: string;
  recurringSetupForm: RecurringSchedule;
  taskList: EventTaskRequest;
}

export const inititalFormState: IFormState = {
  buildingForm: {
    name: '',
    frontDeskNumber: '',
    region: { id: null },
    timezone: { id: null },
    address: '',
    cluster: { id: null },
    contract: false,
    pointOfContact: '',
    contractFile: { id: null },
    contractLapse: convertTimeToMMDDYYYY(new Date()),
    propertyType: 'C',
    adminNotes: '',
    reminders: '',
    passCode: '',
    managementCompany: { id: null },
    buildingCode: '',
    buildingWhiteLabel: {
      topBar: '',
      topBarText: '',
      buttonColor: '',
      buttonText: '',
      logo: {
        id: null,
      },
    },
    active: true,
    marketingEmails: true,
    amenityService: true,
    userRegistration: true,
    whitelabelActive: false,
    buildingSpaces: [],
    buildingStaff: [],
    blockCount: 0,
    buildingBlocks: [],
  },
  managementForm: {
    companyName: '',
    frontDeskNumber: '',
    companyWebsite: '',
    address: '',
    primaryContactName: '',
    primaryContactDesignation: '',
    primaryContactNumber: '',
    primaryEmail: '',
  },
  clusterForm: {
    companyName: '',
    clusterUrl: '',
    buildings: [],
    management: {},
  },
  buildingStaffForm: {
    buildingAccess: [],
    name: '',
    designation: '',
    contact: '',
    email: '',
    birthday: '',
    management: {},
  },
  spaceForm: {
    building: {},
    buildingName: '',
    spaces: [],
  },
  userForm: {
    firstName: '',
    lastName: '',
    registrationDate: '',
    block: {},
    email: '',
    cellPhone: '',
    buildings: [],
    isActive: true,
  },
  vendorCompanyForm: {
    companyName: '',
    hqPhone: '',
    hqAddress: '',
    vendorWebsite: '',
    hqContactName: '',
    status: true,
    vendorContacts: [],
    id: 0,
  },
  vendorIndividualForm: {
    businessPhone: '',
    fitnessVendor: false,
    address: '',
    vendorBio: '',
    jobTitle: '',
    dateOfSubHire: '',
    dateOfFtHire: '',
    slackContactLink: '',
    name: '',
    cellPhone: '',
    birthday: '',
    email: '',
    certification: { id: null },
    vendorPortrait: { id: null },
    vendorAgreement: { id: null },
    vendorInsurance: { id: null },
    otherImages: { id: null },
    venueServices: {
      buildings: [],
      serviceRegions: [],
      services: [],
    },
    onlineServices: {
      buildings: [],
      serviceRegions: [],
      services: [],
    },
    company: {},
    isActive: true,
    notes: '',
    vendorCostRange: 0,
    isCertification: false,
  },
  serviceSetupForm: {
    name: '',
    displayName: '',
    status: false,
    virtualMode: false,
    certificateRequired: [],
    eventLeadRequired: false,
    recurringBooking: false,
    duration: null,
    maxAttendees: null,
    cancellationPolicy: '',
    cosDescription: '',
    userAppDescription: '',
    isPackage: false,
    elementCategory: {},
    eventImage: [],
    eventPoster: {},
    cosImage: {},
    elementsAssociated: [],
    trunk: { id: null },
    inventoryByBuilding: {},
    inventoryByHom: {},
    noteToVendor: '',
    productionTeamRequired: false,
    productionTeams: [],
  },
  packageElementForm: {
    packageElements: [],
  },
  serviceVendorForm: {
    vendorRequirements: [],
  },
  pricingForm: {
    pricing: [],
  },
  serviceTaskList: [],

  eventSetupForm: {
    visibility: 1,
    buildings: [],
    service: { id: null },
    serviceCategory: { id: null },
    startDatetime: '',
    endDatetime: '',
    displayName: '',
    description: '',
    maxCapacity: 0,
    cancelled: false,
    recurring: false,
    bookedInfo: 0,
    virtual: true,
    venue: { id: null },
    visibleTo: {},
    parentSchedule: null,
    isPastEvent: false,
    preRecorded: false,
    marketingLinks: '',
    isPackage: 0,
    elements: [],
    timezone: { id: null },
    sourceDate: '',
    isRecurring: false,
    productionTeamExist: false,
    productionTeamRequired: false,
    productionTeams: [],
    eventImage: { id: null, file: null },
    serviceElements: [],
    isPaid: false,
    price: null
  },
  contractLapse: '',
  propertyType: '',

  recurringSetupForm: {
    visibility: 1,
    buildings: [],
    scheduleEvents: [],
    recurring: false,
    weekDays: [],
    visibleTo: {},
    isPastEvent: false,
    timezone: { id: null },
  },
  taskList: {
    data: [],
    recurring: false,
  },
};
