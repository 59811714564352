import React, { useState, useRef } from 'react';
import Chevron from './Chevron';
import './accordion.scss';

const Accordion = (props) => {
  const [setActive, setActiveState] = useState(props.active ?? '');
  const [setHeight, setHeightState] = useState(props.height ?? '0px');
  const [setRotate, setRotateState] = useState(
    `accordion__icon ${props.className}`
  );
  const content = useRef(null);
  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(
      setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === 'active'
        ? `accordion__icon ${props.className}`
        : `accordion__icon rotate ${props.className}`
    );
  };

  return (
    <div className={`accordion__section ${props.accClass}`}>
      <p className={`accordion ${setActive} ${props.invClass}`}>
        {props.title}
        <button onClick={toggleAccordion} type="button">
          <Chevron className={`${setRotate}`} width={10} fill={'#777'} />
        </button>
      </p>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        {props.content}
      </div>
    </div>
  );
};

export default Accordion;
