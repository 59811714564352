import * as clusterTypes from './cluster.constants';
import { IClusterState, initialClusterState } from './cluster.state';

export const clusterReducer = (
  state: IClusterState = initialClusterState,
  action: {
    type: string;
    page: number;
    payload: any;
    clusterCount: number;
  }
): IClusterState => {
  switch (action.type) {
    case clusterTypes.GET_CLUSTERS:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        clusterCount: action.clusterCount,
        clusters: action.payload,
      };
    case clusterTypes.SEARCH_CLUSTERS_DATA:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        clusterCount: action.clusterCount,
      };
    case clusterTypes.ADD_CLUSTER:
      return {
        ...state,
        addClusterData: action.payload,
      };
    case clusterTypes.GET_CLUSTER_DETAIL:
      return {
        ...state,
        clusterData: action.payload,
      };
    default:
      return state;
  }
};
