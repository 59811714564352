import React, { useState, useEffect } from 'react';
import CommonLayout from '../../CommonLayout/CommonLayout';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '@alfredclub/hom-planner-theme';
import { getSpaces } from '../../../redux/space/space.action';
import { useHistory } from 'react-router-dom';
import { useFetch, usePagination } from '../../../hooks';
import AddNewSpace from './AddNewSpace';
import { TOOLTIP_TEXT } from '../../../constants/index';
import { clearSpaceForm } from '../../../redux/form/form.action';
import { getSerialNumber } from '../../../utils';
import { ISpaceState } from '../../../redux/space/space.state';
import { getBuildings } from '../../../redux/building/buildings.action';

const SpaceListing = () => {
  const { status } = useFetch(getSpaces);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAddSpace, setAddSpace] = useState(false);
  const { pages, spacesCount } = useSelector(
    (state: { spaces: ISpaceState }) => state.spaces
  );
  const [pageNo, setPage] = useState(0);
  const pagination = usePagination();
  const [showLoader] = useState(false);
  const [setRowClick, setRowState] = useState(null);
  const [searchLoading, setSearchState] = useState(false);
  const onPageClick = async (page: number) => {
    await pagination(pages, page - 1, getSpaces);
    setPage(page - 1);
  };
  const [searchValue, setValue] = useState<string | object>('');
  const [isCancelIcon, setCancelICon] = useState<boolean>(false);
  let timer = null;
  const onSearch = async (query: string) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getBuildings(10, 0, 'short', query, 1, false)
          );
          res(data);
        }, 1000))
    );
  };

  const handleOnCellClick = async (e: any) => {
    // setLoadingState(true);
    // setRowState(e.row.values.id);
    // await dispatch(getSpaceDetail(e.row.values.id));
    history.push(`/data-resources/clients/spaces/${e.row.values.id}`);
  };

  const onAddNewSpace = async (e: any) => {
    e.preventDefault();
    await dispatch(clearSpaceForm());
    setAddSpace(true);
  };
  const onCloseAddNewSpace = (e: Event) => {
    e.preventDefault();
    setAddSpace(false);
  };
  const onClickOfCancel = async () => {
    setValue('');
    setCancelICon(false);
    setSearchState(true);
    await dispatch(getSpaces());
    setSearchState(false);
  };
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  return (
    <div>
      {showAddSpace && (
        <AddNewSpace
          modalState={showAddSpace}
          onCloseAddNewSpace={onCloseAddNewSpace}
        />
      )}
      {status === 'fetched' ? (
        <CommonLayout
          headerTitle="Spaces"
          showIcon={true}
          showSearchBar={true}
          showTable={true}
          showSortButton={true}
          navIconToolTipText={TOOLTIP_TEXT.spaces.new}
          tableColumnsData={pages?.get(pageNo)?.data}
          tableColumnsHeaders={[
            {
              Header: 'Sno',
              accessor: 'id',
              Cell: (row: any) => {
                return <div>{getSerialNumber(pageNo, row.row.index)}</div>;
              },
            },
            {
              Header: 'Spaces',
              accessor: (row: any) => (
                <>
                  {row.name}
                  <Loader
                    color="#e89a89"
                    position="center"
                    loading={showLoader && setRowClick === row.id}
                    size="0.75rem"
                    className={`${
                      showLoader && setRowClick === row.id && 'row_loader'
                    }`}
                  />
                </>
              ),
              className: 'tableAnchor', // use this class for highlight the column value
            },
            {
              Header: 'Building',
              accessor: 'buildingName',
            },
            {
              Header: 'Management',
              accessor: 'management',
            },
            {
              Header: 'Max Capacity',
              accessor: 'maxCapacity',
            },
            {
              Header: 'Goal Capacity',
              accessor: 'goalCapacity',
            },
          ]}
          showPagination={!(spacesCount < 10) && true}
          recordsCount={spacesCount}
          onSearchOptionSelect={async (option) => {
            if (option) {
              setRowState(option.id);
              setValue(option);
              setCancelICon(true);
              setSearchState(true);
              await dispatch(getSpaces(10, 0, 'short', option.id, 1, true));
              setSearchState(false);
            }
          }}
          onPageChange={(pageNumber) => onPageClick(pageNumber)}
          onCellClick={(e) => handleOnCellClick(e)}
          onClick={(e) => onAddNewSpace(e)}
          promiseOptions={onSearch}
          optionLabel="name"
          optionKey="id"
          placeholder="Search space by building"
          showCancelIcon={isCancelIcon}
          defaultValue={[searchValue]}
          onClickOfCancel={onClickOfCancel}
          refreshTable={true}
          isLoading={searchLoading}
        />
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
    </div>
  );
};
export default SpaceListing;
