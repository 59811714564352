import React from 'react';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import ModalPopup from '../ReactModal/Modal';
import '../Clients/Building/BuildingPopup/BuildingPopup.scss';

const DeletePopup: React.FC<any> = ({
  modalState,
  isDelete,
  modalClose,
  onDelete,
  headerText,
  subHeader,
  buttonLabel,
  cancelButtonLabel,
}: any) => {
  const onSubmit = async () => {
    if (isDelete) {
      await onDelete();
    }
  };
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={modalClose}
      className="delete_modal task_list_model"
    >
      <PrimaryLabel
        className="sub-header header_style"
        labelText={headerText}
      />
      <br />
      <PrimaryLabel
        className={`sub_header_child ${isDelete && 'delete_building'}`}
        labelText={subHeader}
      />
      <div className="button_div">
        <Button
          label={cancelButtonLabel}
          className="close_button"
          type="button"
          onClick={modalClose}
        />
        <Button label={buttonLabel} className="yes_delete" onClick={onSubmit} />
      </div>
    </ModalPopup>
  );
};

export default DeletePopup;
