import {
  Button,
  Dropdown,
  PrimaryLabel,
  TextArea,
} from '@alfredclub/hom-planner-theme';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../hooks';
import { BroadcastBuildings, BroadcastMessageTo } from '../../../models';
import {
  checkRequiredField,
  getBuildingsRelatedToEvent,
  IEventsState,
  sendBroadcastMessage,
} from '../../../redux/event';
import {
  closeModal,
  resetModalValues,
} from '../../../redux/modal/modal.action';
import { IModalState } from '../../../redux/modal/modal.state';
import { findObjectFromArray, getImageUrl, recepients } from '../../../utils';
import { broadcastMessageSchema } from '../../../validations/broadcastMessage';
const BroadcastForm: React.FC<{
  closePane: () => void;
}> = ({ closePane }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const eventId = parseInt(params['eventId'], 10);
  const [rsvps, setRsvps] = useState<number>(0);
  const [attendees, setAttendees] = useState<number>(0);
  const [allUsers, setAllUsers] = useState<number>(0);
  const [buildings, setbuildings] = useState<BroadcastBuildings[]>([]);
  const { broadcastEventDetail } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const { resetModal, openBroadcast } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );
  const {
    handleSubmit,
    errors,
    register,
    setValue,
    reset,
  } = useForm<BroadcastMessageTo>();
  useFetch(getBuildingsRelatedToEvent, [eventId]);
  const calculateCount = (dataArray: BroadcastBuildings[]) => {
    let rsvpCount = 0;
    let attendeeCount = 0;
    let allUsersCount = 0;
    const buildingIds = [];
    if (dataArray && dataArray.length > 0) {
      dataArray.forEach((item: BroadcastBuildings) => {
        buildingIds.push({ id: item.buildingId });
        const rsvp = findObjectFromArray(
          broadcastEventDetail?.['rsvpsByBuilding'],
          'buildingId',
          item
        );
        const attendee = findObjectFromArray(
          broadcastEventDetail?.['attendeeByBuilding'],
          'buildingId',
          item
        );
        rsvpCount += rsvp ? rsvp?.['rsvpCount'] : 0;
        attendeeCount += attendee ? attendee?.['attendeeCount'] : 0;
        allUsersCount += item?.['userCount'] ? item?.['userCount'] : 0;
      });
      setRsvps(rsvpCount);
      setAttendees(attendeeCount);
      setAllUsers(allUsersCount);
    } else {
      setRsvps(0);
      setAttendees(0);
      setAllUsers(0);
    }
    setValue('buildings', buildingIds);
  };
  useEffect(() => {
    async function removeData() {
      if (!openBroadcast) {
        setbuildings([]);
        reset();
      }
    }
    removeData();
  }, [openBroadcast, reset]);
  const selectAllBuildings = async () => {
    await dispatch(resetModalValues(false));
    calculateCount(broadcastEventDetail?.['buildings']);
    setbuildings([...broadcastEventDetail?.['buildings']]);
    await dispatch(resetModalValues(true));
  };
  const onBuildingSelect = (buildingData) => {
    calculateCount(buildingData);
    setbuildings(buildingData);
  };
  const onSubmit = async (data: BroadcastMessageTo) => {
    try {
      let validateMessage;
      validateMessage = await broadcastMessageSchema.validate({
        ...data,
      });
      await dispatch(sendBroadcastMessage(eventId, validateMessage));
      await dispatch(closeModal());
      setbuildings([]);
      reset();
    } catch (error) {
      dispatch(checkRequiredField(error?.['message']));
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="h1_custom">
        Broadcast to Users
        <img
          src={getImageUrl('data-resources/cancel.svg')}
          alt="close"
          className="inline-flex float-right cursor-pointer"
          onClick={closePane}
        />
      </h1>
      <div className="mx-12">
        <div className="w-full inline-flex justify-between">
          <div>
            <PrimaryLabel labelText="Select building" />
            <PrimaryLabel labelText="*" className="text-red-500" />
          </div>
          <PrimaryLabel
            className="select-all-buildings"
            labelText="Select all buildings"
            onClick={selectAllBuildings}
          />
        </div>
        <Dropdown
          options={
            broadcastEventDetail?.['buildings'] &&
            broadcastEventDetail?.['buildings']
          }
          isMulti={true}
          labelKey="name"
          valueKey="buildingId"
          label="Select building"
          ref={() => register({ name: 'buildings' })}
          onOptionChange={async (e) => {
            onBuildingSelect(e);
            await dispatch(resetModalValues(false));
          }}
          sectionClass="pb-8"
          resetValue={resetModal && openBroadcast}
          value={
            resetModal && buildings && buildings.length > 0 ? buildings : []
          }
          classNamePrefix="broadcast-building-field"
        />
        <PrimaryLabel labelText="Select Recipients" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Dropdown
          options={recepients(allUsers, attendees, rsvps)}
          labelKey="recepients"
          valueKey="recipientType"
          label="Select Recipients"
          ref={() => register({ name: 'recipientType' })}
          onOptionChange={(e) => {
            setValue('recipientType', e?.['recipientType']);
          }}
          sectionClass="pb-8 recepient-select"
          classNamePrefix="recepient-select-field"
          resetValue={resetModal && openBroadcast}
        />
        <PrimaryLabel labelText="Type message to broadcast" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <TextArea
          isLabelShow={false}
          label="Type message to broadcast"
          name="message"
          placeholder="Type message here"
          ref={register}
          isError={errors?.['message'] ? true : false}
          className="mb-8 broadcast-textarea"
        />
        <Button className="float-left" label="Send Message" />
      </div>
    </form>
  );
};

export default BroadcastForm;
