import * as inventoryTypes from './inventory.constant';
import { IInventoryState, initialInventoryState } from './inventory.state';

export const inventoryReducer = (
  state: IInventoryState = initialInventoryState,
  action: {
    [x: string]: string | number;
    type: string;
    page: number;
    payload: any;
    count: number;
    pageNo: number;
    groupIndex: number;
  }
): IInventoryState => {
  switch (action.type) {
    case inventoryTypes.GET_INVENTORY_ITEMS_LIST:
    case inventoryTypes.SEARCH_INVENTORY_ITEMS:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        totalCount: action.count,
      };
    case inventoryTypes.UPDATE_INVENTORY_ITEM_LOCATION:
      state.pages.get(action.pageNo).data[action['index']].location =
        action['location'];
      return {
        ...state,
        pages: state.pages.set(action.pageNo, {
          data: [...state.pages.get(action.pageNo).data],
          fetched: true,
        }),
      };
    case inventoryTypes.UPDATE_INVENTORY_GROUP_ITEM_LOCATION:
      state.inventoryGroups[action.groupIndex]['items'][
        action['index']
      ].location = action['location'];
      const updatedInventoryGroupsItems = [...state.inventoryGroups];
      return {
        ...state,
        inventoryGroups: updatedInventoryGroupsItems,
      };
    case inventoryTypes.DELETE_INVENTORY_GROUP:
      const updatedInventory = state.inventoryGroups.filter(
        (item) => item.id !== action['id']
      );
      return {
        ...state,
        inventoryGroups: [...updatedInventory],
      };
    case inventoryTypes.GET_INVENTORY_GROUPS:
    case inventoryTypes.SEARCH_INVENTORY_GROUPS:
      return {
        ...state,
        inventoryGroups: action['payload'],
        totalCount: action['count'],
      };
    case inventoryTypes.DELETE_INVENTORY_GROUPS_ITEMS:
      const updatedInventoryGroups = state.inventoryGroups[action['index']][
        'items'
      ].filter((item: { id: string | number }) => item.id !== action['id']);
      state.inventoryGroups[action['index']].items = updatedInventoryGroups;
      return {
        ...state,
        inventoryGroups: [...state.inventoryGroups],
      };
    case inventoryTypes.DELETE_INVENTORY_ITEMS:
      const updatedInventoryItems = state.pages
        .get(action.page)
        .data.filter((item) => item?.['id'] !== action.id);
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: updatedInventoryItems,
          fetched: true,
        }),
      };
    default:
      return state;
  }
};
