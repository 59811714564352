import {
  Button,
  Dropdown,
  Input,
  PrimaryLabel,
  TextArea,
} from '@alfredclub/hom-planner-theme';
import React, { useEffect, useState } from 'react';
import { EventVisibilityOptions } from '../../../constants/index';
import { getImageUrl } from '../../../utils';
import SlideDrawer from '../../Common/SliderPane';
import Backdrop from '../../Common/SliderPane/Backdrop';
import EventInvitiees from './EventInvities';
import EventBuildingsDrawer from './EventBuildingsDrawer';
import LabelWithIcon from './LabelWithIcon';
import { useSelector, useDispatch } from 'react-redux';
import EventCompanyOnly from './EventCompanyOnly';
import {
  clearEventBuildingForm,
  clearEventInviteesForm,
  IEventsState,
} from '../../../redux/event';
import { getBuildingDetail, saveBuildingId } from '../../../redux/building';
import {
  clearEventSpaces,
  getEventSpaceList,
  getSpaceDetail,
  ISpaceState,
} from '../../../redux/space';
import { TinyObject } from '../../../models';

const BuildingConfiguration: React.FC<{
  formErrors: object;
  register: any;
  setValue: any;
  reset: any;
  eventId: number;
  eventData: object;
  isEdit: boolean;
  className?: string;
  isSchedule?: boolean;
  isSingle?: boolean;
  onFieldInputChange?: (
    key: string,
    value: number | string | TinyObject | boolean
  ) => void;
  virtual?: boolean;
  isPast?: boolean;
}> = ({
  formErrors,
  register,
  setValue,
  eventId,
  eventData,
  isEdit,
  className,
  isSchedule,
  isSingle,
  onFieldInputChange,
  virtual,
  isPast,
}) => {
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [visibility, setVisibilityState] = useState(
    eventData?.['visibility'] ?? 1
  );
  const [selectedBuildingId, setBuildingId] = useState<number | null>(
    eventData?.['venue']?.['building']?.['id'] ?? null
  );
  const [spaceId, setSpaceId] = useState<number | null>(
    eventData?.['venue']?.['id'] ?? null
  );
  const [selectedBuilding] = useState<number | null>(
    eventData?.['venue']?.['building']?.['id'] ?? null
  );
  const [spaces, setSpaces] = useState<boolean>(true);
  const [apiStatus, apiCallStatus] = useState<boolean>(false);
  const [selectedVenue, setEventVenue] = useState(null);
  const [paidStatus, setPaidStatus] = useState<boolean>(false);

  const { eventInviteesData, eventBuildingData } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const { eventSpaces } = useSelector(
    (state: { spaces: ISpaceState }) => state.spaces
  );

  const eventVisibility = EventVisibilityOptions.filter(
    (data) => data.value === (eventData?.['visibility'] ?? 1)
  );

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  let backdrop;
  if (drawerOpen) {
    backdrop = <Backdrop close={() => toggleDrawer()} />;
  }

  const getEventBuilding = () => {
    if (eventInviteesData?.['buildings']) {
      return eventInviteesData?.['buildings']?.length;
    } else if (Object.keys(eventBuildingData).length > 0) {
      return eventBuildingData?.length;
    } else {
      return eventData?.['buildings']?.length;
    }
  };

  const buildingList = () => {
    if (eventInviteesData?.['buildings']) {
      return eventInviteesData?.['buildings'];
    } else if (Object.keys(eventBuildingData).length > 0) {
      return eventBuildingData;
    } else {
      return eventData?.['buildings'];
    }
  };
  const onPaidOptionChange = (check: boolean) => {
    setValue('isPaid', check);
    setPaidStatus(!paidStatus);
    onFieldInputChange('isPaid', !paidStatus);
  };
  const buildingCount = getEventBuilding();

  const renderDrawer = (dropdownOption: number) => {
    switch (dropdownOption) {
      case 1:
        return (
          drawerOpen && (
            <EventBuildingsDrawer
              closePane={toggleDrawer}
              register={register}
              eventId={eventId}
            />
          )
        );
      case 2:
        return (
          drawerOpen && (
            <EventInvitiees
              closePane={toggleDrawer}
              register={register}
              eventId={eventId}
              eventData={eventData}
              isSchedule={isSchedule}
            />
          )
        );
      case 3:
        return (
          drawerOpen && (
            <EventCompanyOnly
              closePane={toggleDrawer}
              register={register}
              eventId={eventId}
              eventData={eventData}
              isSchedule={isSchedule}
            />
          )
        );
    }
  };

  const selectBuilding = async () => {
    await dispatch(saveBuildingId(selectedBuildingId));
    await dispatch(getBuildingDetail(selectedBuildingId));
    window.open(
      `/data-resources/clients/buildings/${selectedBuildingId}`,
      '_blank'
    );
  };
  const selectSpace = async () => {
    await dispatch(getSpaceDetail(spaceId));
    window.open(`/data-resources/clients/spaces/${spaceId}`, '_blank');
  };
  useEffect(() => {
    async function getPaidStatus() {
      if (isEdit) {
        setPaidStatus(eventData?.['isPaid']);
        return;
      }
    }
    getPaidStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, eventData]);
  return (
    <div className={`building_configuration ${className}`}>
      <>
        <LabelWithIcon
          imgSrc={getImageUrl('Events/buildingconfig.svg')}
          labelText="Building configuration"
          className="img_label"
        />
        <div className="mt-4">
          <PrimaryLabel labelText="Event Visibility" />
          <Dropdown
            labelKey="label"
            valueKey="value"
            options={EventVisibilityOptions}
            label="visibility"
            ref={() => register({ name: 'visibility' })}
            placeholder="Select"
            onOptionChange={async (e) => {
              setValue('visibility', Number(e['value']));
              setVisibilityState(Number(e['value']));
              if (isSingle) {
                onFieldInputChange('visibility', Number(e['value']));
              }
              await dispatch(clearEventBuildingForm());
              await dispatch(clearEventInviteesForm());
            }}
            isCustomDropIcon={true}
            customDropIcon={getImageUrl('Events/visibility.svg')}
            value={
              eventVisibility && {
                label: eventVisibility?.[0]?.['label'],
                value: eventVisibility?.[0]?.['value'],
              }
            }
            disabled={isEdit ? true : false}
            classNamePrefix="visibility-select"
          />
        </div>
        <Button
          label={`${
            eventData?.['buildings']?.length ||
            Object.keys(eventInviteesData).length > 0 ||
            Object.keys(eventBuildingData).length > 0
              ? `${buildingCount} Buildings Added`
              : 'Add Buildings'
          }`}
          className={`mt-4 add_building_button justify-between pr-4 ${
            !isSchedule && 'mb-16'
          }`}
          isRightIcon={true}
          rightIcon={getImageUrl('Events/addtag.svg')}
          onClick={() => setDrawerOpen(!drawerOpen)}
          type="button"
          disabled={!visibility}
        />
        <SlideDrawer
          show={drawerOpen}
          slideClass="side-drawer side-drawer-event-width open"
          showCloseIcon={true}
          closePane={toggleDrawer}
        >
          {renderDrawer(visibility)}
        </SlideDrawer>
        {backdrop}
      </>

      {/* Space and streaming settings */}
      {!isSchedule && (
        <div>
          <LabelWithIcon
            imgSrc={getImageUrl(
              `Events/${!virtual ? `spacesettings` : `streaming`}.svg`
            )}
            labelText={`${!virtual ? `Space Settings` : `Streaming Settings`}`}
            className="img_label"
          />
          {!virtual ? (
            <>
              <div className="grid grid-cols-1 gap-x-2 gap-y-0">
                <div className="mt-4">
                  <div className="flex justify-between dropdown_class">
                    <div>
                      <span className="label_text">Select Building</span>
                      <span className="text-red-500">*</span>
                    </div>
                    {selectedBuildingId && (
                      <img
                        src={getImageUrl('Events/activeLink.svg')}
                        alt="icon"
                        onClick={selectBuilding}
                        className="cursor-pointer"
                      />
                    )}
                  </div>
                  <Dropdown
                    asyncDropdown={false}
                    labelKey="name"
                    valueKey="id"
                    isLabelShow={false}
                    label="Select Building"
                    options={buildingList()}
                    ref={() => register({ name: 'buildings' })}
                    onOptionChange={async (e) => {
                      setSpaces(false);
                      if (e) {
                        setBuildingId(e['id']);
                        await dispatch(clearEventSpaces());
                        await dispatch(getEventSpaceList(e['id']));
                        onFieldInputChange('venue', {
                          id: null,
                        });
                        setValue('venue.id', null);
                      }
                    }}
                    placeholder=""
                    value={
                      eventData && {
                        name: eventData?.['venue']?.['building']?.['name'],
                        id: eventData?.['venue']?.['building']?.['id'],
                      }
                    }
                    disabled={isPast}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-2 gap-y-0">
                <div className="mt-4">
                  <div className="flex justify-between dropdown_class">
                    <div>
                      <span className="label_text">Select Space</span>
                      <span className="text-red-500">*</span>
                    </div>
                    {spaceId && (
                      <img
                        src={getImageUrl('Events/activeLink.svg')}
                        alt="icon"
                        onClick={selectSpace}
                        className="cursor-pointer"
                      />
                    )}
                  </div>
                  <Dropdown
                    asyncDropdown={false}
                    labelKey="name"
                    valueKey="id"
                    isLabelShow={false}
                    label="Event Service"
                    onMenuOpen={async () => {
                      apiCallStatus(true);
                      if (
                        eventSpaces &&
                        Object.keys(eventSpaces).length <= 0 &&
                        selectedBuilding
                      ) {
                        await dispatch(getEventSpaceList(selectedBuilding));
                      }
                      apiCallStatus(false);
                    }}
                    loading={apiStatus}
                    options={eventSpaces}
                    ref={register({ name: 'venue.id' }, { required: true })}
                    isError={formErrors?.['venue'] ? true : false}
                    onOptionChange={async (e) => {
                      const eventService = {
                        id: e['id'],
                      };
                      setSpaceId(e['id']);
                      onFieldInputChange('venue', eventService);
                      setEventVenue(e);
                      setValue('venue.id', eventService.id);
                      setValue('spaceCapacity', e['maxCapacity']);
                      setValue('venue.maxCapacity', e['maxCapacity']);
                    }}
                    placeholder=""
                    value={{
                      name: eventData?.['venue']?.['name'],
                      id: eventData?.['venue']?.['id'],
                    }}
                    disabled={isEdit ? isPast : spaces}
                    resetValue={selectedBuildingId ? true : false}
                  />
                </div>
                <PrimaryLabel
                  labelText={`Space Maximum Capacity: ${
                    selectedVenue?.['maxCapacity'] ??
                    eventData?.['venue']?.['maxCapacity'] ??
                    ''
                  }`}
                />
                <div className="mt-4">
                  <PrimaryLabel labelText="Maximum Attendees" />
                  <PrimaryLabel labelText="*" className="text-red-500" />
                  <Input
                    label=""
                    placeholder=""
                    name="maxCapacity"
                    ref={register({
                      valueAsNumber: true,
                      validate: {
                        positive: (value) => parseInt(value, 10) > 0,
                      },
                    })}
                    inputType="number"
                    isError={formErrors?.['maxCapacity'] ? true : false}
                    value={eventData?.['maxCapacity'] ?? null}
                    onInputChange={(e) => {
                      onFieldInputChange(
                        'maxCapacity',
                        parseInt(e.target.value, 10)
                      );
                      setValue('maxCapacity', parseInt(e.target.value, 10));
                    }}
                    disabled={isPast}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 gap-x-2 gap-y-0">
                <div className="py-4 prerecorded">
                  <input
                    type="checkbox"
                    className="checkbox pre-recorded-checkbox"
                    ref={register()}
                    name="preRecorded"
                    onChange={() => {
                      const isChecked = !eventData?.['preRecorded'];
                      onFieldInputChange('preRecorded', isChecked);
                    }}
                  />

                  <PrimaryLabel
                    className="pre-recorded-label"
                    labelText="This is a pre-recorded event"
                  />
                </div>
                <div className="mt-4">
                  <PrimaryLabel labelText="Tenant URL" className="block" />
                  <TextArea
                    label=""
                    placeholder="URL will auto-populate after vendor is assigned"
                    isLabelShow={false}
                    ref={register}
                    name="tenantUrl"
                    inputSectionClass="inline-flex"
                    onTextAreaChange={(e) => {
                      onFieldInputChange('tenantUrl', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-x-2 gap-y-0">
                <div className="mt-4">
                  <PrimaryLabel labelText="Event Maximum Capacity" />
                  <PrimaryLabel labelText="*" className="text-red-500" />
                  <Input
                    label=""
                    placeholder=""
                    name="maxCapacity"
                    ref={register({
                      valueAsNumber: true,
                      validate: {
                        positive: (value) => parseInt(value, 10) > 0,
                      },
                    })}
                    inputType="number"
                    isError={formErrors?.['maxCapacity'] ? true : false}
                    value={eventData?.['maxCapacity'] ?? null}
                    onInputChange={(e) => {
                      onFieldInputChange('maxCapacity', Number(e.target.value));
                      setValue('maxCapacity', Number(e.target.value));
                    }}
                    disabled={isPast}
                  />
                </div>
              </div>
            </>
          )}
          <div className="pt-12">
            <LabelWithIcon
              imgSrc={getImageUrl(`Events/pricingmodelicon.svg`)}
              labelText="Pricing Model"
              className="img_label"
            />
            <div className="col-span-2 pt-4">
              <div className="flex mt-2">
                <div className="flex text-center justify-center justify-items-center content-center">
                  <input
                    type="radio"
                    className="priceradio cursor-pointer mr-1 mt-1"
                    name="isPaid"
                    onChange={(e) => {
                      onPaidOptionChange(e.target.checked);
                      onFieldInputChange('price', 0);
                    }}
                    checked={!paidStatus}
                    ref={register}
                  />
                  <PrimaryLabel labelText="Free event" />
                </div>
                <div className="flex ml-8">
                  <input
                    type="radio"
                    name="isPaid"
                    className="priceradio cursor-pointer mr-1 mt-1"
                    onChange={(e) => onPaidOptionChange(e.target.checked)}
                    checked={paidStatus}
                    ref={register}
                  />
                  <PrimaryLabel labelText="Paid event" />
                </div>
              </div>
              {paidStatus && (
                <div className="pt-4">
                  <Input
                    label="Price per person"
                    name="price"
                    inputType="number"
                    inputSectionClass="price_input"
                    ref={register({
                      valueAsNumber: true,
                      validate: {
                        positive: (value) => parseInt(value, 10) > 0,
                      },
                    })}
                    isRightLabel={true}
                    rightLabel="USD"
                    onInputChange={(e) =>
                      onFieldInputChange('price', Number(e.target.value))
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildingConfiguration;
