import {
  Button,
  CustomDropdown,
  PrimaryLabel,
  TagBox,
} from '@alfredclub/hom-planner-theme';
import React, { useState } from 'react';
import {
  findIndexFromArrayOfObject,
  getImageUrl,
  loadState,
} from '../../../utils';
import LabelWithIcon from './LabelWithIcon';
import { useDispatch, useSelector } from 'react-redux';
import './EventSetupForm.scss';
import {
  getAllBuildings,
  saveEventInvitees,
} from '../../../redux/event/event.action';
import {
  clearUnitsList,
  getBuildingBlocks,
} from '../../../redux/buildingblocks/buildingblocks.action';
import moment from 'moment';
import { IEventsState } from '../../../redux/event';
import { TIME_FORMAT } from '../../../constants';
import { ILoaderState } from '../../../redux/loader';

const EventCompanyOnly: React.FC<{
  closePane: any;
  register: any;
  eventId: number;
  eventData: object;
  isSchedule?: boolean;
}> = ({ closePane, eventId, eventData, isSchedule }) => {
  const dispatch = useDispatch();
  const recurringData = loadState('recurringSetupForm');
  const isRecurring = recurringData?.['recurring'] ?? false;

  const { allBuildings } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const [selectedBuilding, setBuilding] = useState(
    eventData?.['buildings'] ?? []
  );
  const [invitessList, setInvitees] = useState(
    Object.keys(eventData?.['visibleTo']).length > 0
      ? eventData?.['visibleTo']
      : null
  );
  const { allBuildingBlock } = useSelector((state) => state.company);
  allBuildingBlock?.sort((a, b) => a.name.localeCompare(b.name));

  const commercialBuildings =
    allBuildings?.length > 0
      ? allBuildings.filter((item) => item.propertyType)
      : [];
  commercialBuildings?.sort((a, b) => a.name.localeCompare(b.name));

  const onDeleteBuilding = async (id: number) => {
    const index = selectedBuilding?.findIndex((item) => item['id'] === id);
    if (index !== -1) {
      selectedBuilding.splice(index, 1);
    }
    setBuilding([]);
    setInvitees(null);
    await dispatch(clearUnitsList());
  };

  const removeInvitees = async (e: any) => {
    const index = findIndexFromArrayOfObject(invitessList, 'id', e);
    if (index !== -1) {
      invitessList.splice(index, 1);
    } else {
      invitessList.push({ ...e });
    }
    setInvitees(() => [...invitessList]);
  };

  const onSaveClick = async () => {
    const invitessValue = [];
    if (invitessList) {
      invitessList.map((item) => {
        return invitessValue.push({ id: item.id });
      });
    }
    const eventObj = {
      buildings: selectedBuilding,
      visibleTo: invitessValue,
      visibility: 3,
    };
    await dispatch(saveEventInvitees(eventObj, isRecurring));
    closePane();
  };

  return (
    <div className="pd-wrap h-full overflow-y-scroll">
      <>
        <div className="md:flex md:items-center md:justify-between md:pb-1">
          <h1 className="heading_label">
            Add Buildings
            <PrimaryLabel
              labelText="Search & add single building"
              className="block"
            />
          </h1>
          <CustomDropdown
            labelKey="name"
            valueKey="id"
            ddlClass="select_field_width"
            menuIsOpen={false}
            onMenuOpen={async () => {
              if (allBuildings?.length <= 0) {
                // Pass 0 for recurring schedule to get all building list from same api
                await dispatch(getAllBuildings(isSchedule ? 0 : eventId));
              }
            }}
            onOptionChange={async (e) => {
              setBuilding([e]);
              await dispatch(clearUnitsList());
              setInvitees(null);
            }}
            placeholder="Search Building"
            options={commercialBuildings}
            isMulti={false}
            closeMenuOnSelect={true}
            hideSelectedOptions={true}
            value={
              selectedBuilding && {
                name: selectedBuilding?.['buildings']?.[0]?.['name'],
                id: selectedBuilding?.['buildings']?.[0]?.['id'],
              }
            }
            resetValue={selectedBuilding?.length <= 0 ? true : false}
            loading={loading}
          />
        </div>

        <div className="pt-8">
          <LabelWithIcon
            imgSrc={getImageUrl('Events/buildingconfig.svg')}
            labelText="Selected Buildings"
            className="img_label"
          />
          <br />
          {selectedBuilding?.length <= 0 ? (
            <PrimaryLabel
              labelText="No building selected yet"
              className="no_buildings_selected"
            />
          ) : (
            selectedBuilding?.length > 0 &&
            selectedBuilding.map((item) => {
              return (
                <div className="pt-4" key={item.id}>
                  <div className="flex flex-row">
                    <PrimaryLabel
                      labelText={`${item.regionName} ${
                        item.startDatetime
                          ? moment(item.startDatetime).format(TIME_FORMAT)
                          : ''
                      }`}
                    />
                  </div>
                  <TagBox
                    tagLabel={item.name}
                    key={item.id}
                    onClick={() => onDeleteBuilding(item.id)}
                    className="tagbox_position_selected_building"
                  />
                </div>
              );
            })
          )}
        </div>
      </>
      <hr className="horizontal" />
      <>
        <div className="md:flex md:items-center md:justify-between md:pb-1">
          <h1 className="heading_label">
            Add Company
            <PrimaryLabel
              labelText="Email invites go out once the event is published"
              className="block"
            />
          </h1>
          <CustomDropdown
            labelKey="name"
            valueKey="id"
            ddlClass="select_field_width"
            menuIsOpen={false}
            options={allBuildingBlock}
            onMenuOpen={async () => {
              if (allBuildingBlock?.length <= 0) {
                await dispatch(clearUnitsList());
                await dispatch(
                  getBuildingBlocks(
                    selectedBuilding?.[0]?.['id'],
                    0,
                    0,
                    'short',
                    '',
                    1
                  )
                );
              }
            }}
            onOptionChange={(e) => {
              setInvitees(e);
            }}
            placeholder="Search Invitees"
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            isMulti={true}
            value={invitessList}
            disabled={selectedBuilding?.length <= 0}
            resetValue={selectedBuilding?.length <= 0 ? true : false}
          />
        </div>
        <div className="pt-8 pb-24">
          <LabelWithIcon
            imgSrc={getImageUrl('Events/buildingconfig.svg')}
            labelText="Selected Company"
            className="img_label"
          />
          <br />
          {invitessList?.length > 0 &&
            invitessList.map((item) => {
              return (
                <TagBox
                  tagLabel={`${item.name}`}
                  key={item.id}
                  onClick={() => removeInvitees(item)}
                  className="tagbox_position"
                />
              );
            })}
        </div>
      </>
      <Button
        label="Save and Close"
        className="float-none"
        type="button"
        onClick={() => onSaveClick()}
        disabled={!invitessList || invitessList?.length <= 0}
      />
    </div>
  );
};

export default EventCompanyOnly;
