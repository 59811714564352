import React, { useState } from 'react';
import { Dropdown, Input, TextArea } from '@alfredclub/hom-planner-theme';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import { Register, SetValue } from '../../../models';
// import { TrunkOptions } from '../../../constants';

const ServiceDetail: React.FC<{
  register: Register;
  serviceData: object;
  serviceId?: number;
  setValue: SetValue;
  trunkRequirement: any[];
  inventoryByHom: any[];
  formErrors: object;
  isEdit: boolean;
}> = ({
  register,
  serviceData,
  setValue,
  trunkRequirement,
  inventoryByHom,
  formErrors,
  isEdit,
}) => {
  const [selectedInventory] = useState(
    isEdit ? serviceData?.['inventoryByBuilding'] : []
  );

  return (
    <div className={`mt-12`}>
      <FormTitle title="Service Details" />
      <div className="grid grid-cols-2 gap-x-2 gap-y-0">
        <div>
          <Input
            isLabelShow={true}
            label="Duration"
            name="duration"
            ref={register({
              validate: {
                positive: (value) => parseInt(value, 10) > 0,
              },
            })}
            inputType="number"
            isError={formErrors?.['duration'] ? true : false}
          />
        </div>
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={true}
            label="Trunk Requirement"
            options={trunkRequirement}
            ref={register({ name: 'trunk' }, { required: false })}
            onOptionChange={(e) => {
              const trunk = {
                id: e['id'],
                name: e['name'],
              };
              setValue('trunk', trunk);
            }}
            placeholder=""
            className="drop_input_label"
            value={{
              name: serviceData?.['trunk']?.['id']
                ? serviceData?.['trunk']?.['name']
                : 'No Trunk Required',
              id: serviceData?.['trunk']?.['id'],
            }}
          />
        </div>
        <div className="col-span-2">
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={true}
            label="Inventory by Building"
            options={inventoryByHom}
            ref={register({ name: 'inventoryByBuilding' }, { required: false })}
            onOptionChange={(e) => {
              setValue('inventoryByBuilding', e);
            }}
            isMulti={true}
            placeholder="Select Inventory group"
            className="w-48"
            value={selectedInventory}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-2 gap-y-0">
        <div>
          <TextArea
            isLabelShow={true}
            label="Cancellation Policy"
            name="cancellationPolicy"
            placeholder="Enter Cancellation Policy for this service"
            ref={register}
            labelClass="w-48"
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
