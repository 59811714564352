import { Button, Dropdown, Loader } from '@alfredclub/hom-planner-theme';
import { FooterProps } from '../../interfaces';
import React from 'react';

const Footer: React.FC<FooterProps> = ({
  className,
  buttonText,
  onClick,
  isArrow,
  buttonClass,
  disabled,
  showCount = false,
  showDropDown = false,
  dropDownText,
  type = 'submit',
  showDropdownText = false,
  emailDropDownOptions,
  onOptionChange,
  count = 0,
  tab,
  isRightIcon = false,
  rightIcon = '',
  rightIconClass = '',
  isLeftButton = false,
  leftButtonText,
  onLeftButtonClick,
  leftButtonClass,
  leftDisabled,
  leftButtonType,
  isRightButton = true,
  showLoader = false,
}) => {
  return (
    <div className={`footer ${className}`}>
      {showCount && (
        <div className="users_count">
          <p className="max-content">
            {count} {tab === 'building-staff' ? 'staff' : tab} selected
          </p>
        </div>
      )}
      {showDropDown && (
        <div className="sendlink">
          {showDropdownText && (
            <span className="span_text">{dropDownText}</span>
          )}
          <Dropdown
            label="testing"
            options={emailDropDownOptions}
            onOptionChange={(e) => onOptionChange(e)}
            ddlClass="dropdown"
            sectionClass="dropdown_section"
            menuPlacement="top"
          />
        </div>
      )}
      {isLeftButton && (
        <Button
          label={leftButtonText}
          onClick={onLeftButtonClick}
          isArrowShow={false}
          className={leftButtonClass}
          disabled={leftDisabled}
          type={leftButtonType}
        />
      )}
      {isRightButton &&
        (showLoader ? (
          <Loader
            color="#e89a89"
            position="right"
            loading={showLoader}
            size="0.75rem"
            className="footer-loader"
          />
        ) : (
          <Button
            label={buttonText}
            onClick={onClick}
            isArrowShow={isArrow}
            className={buttonClass}
            disabled={disabled}
            type={type}
            isRightIcon={isRightIcon}
            rightIcon={rightIcon}
            rightIconClass={rightIconClass}
          />
        ))}
    </div>
  );
};

export default Footer;
