import { http } from '../common/http.service';
import {
  RECORDS_QUERY,
  RECORDS_PAGE,
  RECORDS_LIMIT,
  RECORDS_CMP,
  SPACE_BASE_URL,
} from '../../constants/index';
import {
  Space,
  DeleteResponse,
  PaginatedResponse,
  SpaceArray,
} from '../../models';
import { AxiosResponse } from 'axios';

export const getSpacesApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = 0
): Promise<PaginatedResponse<Space>> =>
  http
    .get<PaginatedResponse<Space>>(
      `${SPACE_BASE_URL}?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Space>>) => {
      return response.data;
    });

export const addSpacesApi = async (data: SpaceArray): Promise<Space> =>
  http
    .post<Space>(`${SPACE_BASE_URL}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Space>) => {
      return response.data;
    });

export const getSpaceDetailApi = async (spaceId: number): Promise<Space> =>
  http
    .get<Space>(`${SPACE_BASE_URL}/${spaceId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Space>) => {
      return response.data;
    });

export const updateSpaceApi = async (
  spaceId: number,
  data: Space
): Promise<Space> =>
  http
    .put<Space>(`${SPACE_BASE_URL}/${spaceId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Space>) => {
      return response.data;
    });

export const deleteSpaceApi = async (
  spaceId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`${SPACE_BASE_URL}/${spaceId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });

export const getEventSpacesListApi = async (
  buildingId: number
): Promise<PaginatedResponse<Space>> =>
  http
    .get<PaginatedResponse<Space>>(
      `${SPACE_BASE_URL}?building_id=${buildingId}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Space>>) => {
      return response.data;
    });
