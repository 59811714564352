import React, { useState, useEffect, cloneElement } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DraggableList: React.FC<{
  dragList: any[];
  children: JSX.Element;
  isDragDisabled?: boolean;
  className?: string;
}> = ({ dragList, children, isDragDisabled, className }) => {
  const [draggableList, setDraggableList] = useState(dragList);

  useEffect(() => {
    setDraggableList(() => [...dragList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragList.length]);
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'grey' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = () => ({
    background: 'white',
    padding: grid,
    width: 'auto',
    marginLeft: '2rem',
    marginRight: '2rem',
  });
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      draggableList,
      result.source.index,
      result.destination.index
    );

    setDraggableList(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}
            className={className}
          >
            {dragList.map((item, index) => {
              item['dragKey'] = `${item.id + 1}`;
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.dragKey}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provide, snapshot) => (
                    <div
                      ref={provide.innerRef}
                      {...provide.draggableProps}
                      {...provide.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provide.draggableProps.style
                      )}
                    >
                      {cloneElement(children, { item })}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
