import * as Yup from 'yup';

export const recurringSchema = Yup.object().shape({
  scheduleEvents: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string(),
        maxCapacity: Yup.number()
          .required()
          .typeError('Please enter max capacity'),
        displayName: Yup.string().required('Please enter display name'),
        service: Yup.object()
          .shape({
            id: Yup.number().required().typeError('Please select event'),
          })
          .required(),
        endTime: Yup.string().required('Please select end time'),
        startTime: Yup.string().required('Please select start time'),
        tenantUrl: Yup.string(),
        venue: Yup.object(),
        eventImage: Yup.object(),
      })
    )
    .min(1, 'Please select the weekday')
    .required(),
  buildings: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string(),
      })
    )
    .min(1, 'Please select the building')
    .required(),
});

export const recurringInPersonSchema = Yup.object().shape({
  scheduleEvents: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string(),
        maxCapacity: Yup.number()
          .required()
          .typeError('Please enter max capacity'),
        displayName: Yup.string().required('Please enter display name'),
        service: Yup.object()
          .shape({
            id: Yup.number().required().typeError('Please select event'),
          })
          .required(),
        endTime: Yup.string().required('Please select end time'),
        startTime: Yup.string().required('Please select start time'),
        venue: Yup.object()
          .shape({
            id: Yup.number()
              .required()
              .typeError('Select building and space in Setup Event'),
          })
          .required(),
      })
    )
    .min(1, 'Please select the weekday')
    .required(),
  buildings: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string(),
      })
    )
    .min(1, 'Please select the building')
    .required(),
});

export const singleInPersonSchema = Yup.object().shape({
  maxCapacity: Yup.number().required().typeError('Please enter max capacity'),
  venue: Yup.object()
    .shape({
      id: Yup.number()
        .required()
        .typeError('Select building and space in space settings'),
    })
    .required(),
  description: Yup.string(),
  displayName: Yup.string().required('Please enter display name'),
  service: Yup.object()
    .shape({
      id: Yup.number().required().typeError('Please select event'),
    })
    .required(),
  buildings: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string(),
      })
    )
    .min(1, 'Please select the building')
    .required(),
});

export const singleVirtualSchema = Yup.object().shape({
  maxCapacity: Yup.number().required().typeError('Please enter max capacity'),
  description: Yup.string(),
  displayName: Yup.string().required('Please enter display name'),
  service: Yup.object()
    .shape({
      id: Yup.number().required().typeError('Please select event'),
    })
    .required(),
  buildings: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string(),
      })
    )
    .min(1, 'Please select the building')
    .required(),
});

export const addEventTaskSchema = Yup.object()
  .shape({
    title: Yup.string().required('Please enter task title'),
    timeOfTask: Yup.number().required('Please enter mandatory fields'),
    timeValue: Yup.number().required('Please enter mandatory fields'),
    vendor: Yup.object().shape({
      vendorId: Yup.number(),
      vendorName: Yup.string(),
    }),
    description: Yup.string(),
  })
  .required();

export const editEventTaskSchema = Yup.object()
  .shape({
    title: Yup.string().required('Please enter task title'),
    taskDate: Yup.string().required('Please enter date of task'),
    taskTime: Yup.string().required('Please enter time of task'),
    description: Yup.string(),
  })
  .required();

export const productionTeamSchema = Yup.object().shape({
  productionTeams: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required().typeError('Please select vendor type'),
      })
    )
    .required(),
});
export const singleEventSchema = Yup.object().shape({
  buildings: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string(),
      })
    )
    .min(1, 'Please select the building')
    .required(),
});
