import { Whitelabel } from '../../models';

export interface IWhitelabelState {
  whiteLabel: Whitelabel;
}

export const initialwhitelabelState: IWhitelabelState = {
  whiteLabel: {
    topBar: '',
    topBarText: '',
    buttonColor: '',
    buttonText: '',
    logo: {
      id: 0,
    },
  },
};
