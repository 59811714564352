import React, { useState } from 'react';
import {
  Button,
  PrimaryLabel,
  Input,
  Dropdown,
  RadioGroup,
} from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ModalPopup from '../ReactModal/Modal';
import './Services.scss';
import { getImageUrl } from '../../utils';
import { DeliveryOptions } from '../../constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { getServiceCategories } from '../../redux/service/services.action';
import { clearServiceVendorForm } from '../../redux/form/form.action';
import { IServiceState } from '../../redux/service/service.state';

interface IFormInput {
  name: string;
  elementCategory: object;
  virtualMode: boolean;
}
const AddServicePopup: React.FC<any> = ({ modalState, onClosePopup }: any) => {
  const {
    register,
    errors,
    handleSubmit,
    getValues,
    setValue,
  } = useForm<IFormInput>();
  const { categories } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [serviceType, setTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState(DeliveryOptions);
  const onSubmit = async () => {
    push(
      `/data-resources/services/${
        serviceType === 1 ? 'add-service' : 'add-package'
      }?serviceType=${serviceType}&tab=${
        serviceType === 1 ? 'setup' : 'elements'
      }&service=${btoa(
        JSON.stringify(getValues(['name', 'elementCategory', 'virtualMode']))
      )}`
    );
    await dispatch(clearServiceVendorForm());
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedOptions = deliveryOptions.filter((item, itemIndex) => {
      if (itemIndex !== index) {
        item.checked = false;
      } else {
        deliveryOptions[index].checked = e.currentTarget.checked;
      }
      return deliveryOptions;
    });
    setValue('virtualMode', deliveryOptions[index].value);
    setDeliveryOptions([...updatedOptions]);
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClosePopup}
      className="add-service-modal"
      shouldCloseOnOverlayClick={false}
    >
      <label className="label">Create new service</label>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errors.name && <div className="staff-error">Please fill the name</div>}
        <div className="grid grid-cols-2 gap-x-2 gap-y-0">
          <div>
            <PrimaryLabel labelText="Service Name" />
            <PrimaryLabel labelText="*" className="text-red-500" />
            <Input
              name="name"
              label=""
              placeholder=""
              className="input-control-space"
              ref={register({ required: true })}
            />
          </div>
          <div>
            <PrimaryLabel labelText="Category" />
            <Dropdown
              asyncDropdown={false}
              labelKey="name"
              valueKey="id"
              isLabelShow={false}
              label="Category"
              options={categories}
              onMenuOpen={async () => {
                setLoading(true);
                if (categories.length === 0) {
                  await dispatch(getServiceCategories());
                }
                setLoading(false);
              }}
              loading={loading}
              ref={() =>
                register({ name: 'elementCategory' }, { required: false })
              }
              onOptionChange={(e) => {
                setValue('elementCategory', e);
              }}
              sectionClass="control-space"
              placeholder=""
            />
          </div>
          <div>
            <PrimaryLabel labelText="Configuration" />
            <div className="grid grid-cols-2 gap-x-2 gap-y-0 mb-6">
              <div
                tabIndex={1}
                className={`individual_vendor service_element ${
                  serviceType === 1 && 'active'
                }`}
                onClick={() => setTab(1)}
              >
                <img
                  src={getImageUrl('data-resources/single.svg')}
                  alt="icon"
                />
                <p className="p_service">Element</p>
              </div>

              <div
                tabIndex={2}
                className={`individual_vendor service_element ${
                  serviceType === 2 && 'active'
                }`}
                onClick={() => setTab(2)}
              >
                <img
                  src={getImageUrl('data-resources/multiple.svg')}
                  alt="icon"
                />
                <p className="p_service">Package</p>
              </div>
            </div>
          </div>
          <div>
            <PrimaryLabel labelText="Mode of Delivery" />
            <RadioGroup
              options={deliveryOptions}
              label=""
              isLabelShow={false}
              ref={() => register({ name: 'virtualMode' })}
              onChange={(e, index) => onChange(e, index)}
            />
          </div>
        </div>

        <Button label="Start Creating" isArrowShow={true} />
      </form>
    </ModalPopup>
  );
};

export default AddServicePopup;
