import React from 'react';
import { linkString, getImageUrl } from '../../utils';

const AnchorElement: React.FC<{ link: string }> = ({ link }) => {
  return (
    <div>
      <a
        href={linkString(link)}
        target="_blank"
        className="text-Color_2 text-F15 underline"
        rel="noreferrer"
      >
        <img
          src={getImageUrl(
            `Events/${link ? 'activeLink' : 'inactiveLink'}.svg`
          )}
          alt="icon"
          className={`${link ? 'cursor-pointer' : ''}`}
        />
      </a>
    </div>
  );
};
export default AnchorElement;
