import React from 'react';
import { Input } from '@alfredclub/hom-planner-theme';
import { FileInputProps } from '../../interfaces';

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (props, ref) => (
    <Input {...props} ref={ref} inputType="file" className="file_upload" />
  )
);

export default FileInput;
