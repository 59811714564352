export const GET_BUILDINGS = 'GET_BUILDINGS';
export const SEARCH_BUILDINGS_DATA = 'SEARCH_BUILDINGS_DATA';
export const ADD_BUILDING = 'ADD_BUILDING';
export const GET_BUILDING_DETAIL = 'GET_BUILDING_DETAIL';
export const GET_ALL_BUILDINGS = 'GET_ALL_BUILDINGS';
export const GET_ALL_MANAGEMENT_BUILDINGS = 'GET_ALL_MANAGEMENT_BUILDINGS';
export const GET_ALL_BUILDINGS_MANAGEMENT = 'GET_ALL_BUILDINGS_MANAGEMENT';
export const CHECK_BUILDING_CODE = 'CHECK_BUILDING_CODE';
export const SAVE_BUILDING_ID = 'SAVE_BUILDING_ID';
export const FETCHED_BUILDING_DETAIL = 'FETCHED_BUILDING_DETAIL';
