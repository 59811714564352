import React from 'react';
import { getImageUrl } from '../../utils';
import '../Vendors/AddVendor/AddVendorForm.scss';

const TabComponent = ({ tabType, onTabChange, tabFirst, tabSecond }) => {
  return (
    <div className="ml-8 mr-8">
      <input
        id="tab1"
        name="tab"
        type="radio"
        className="tab"
        checked={tabType === 1 && true}
        onChange={(e) => onTabChange(e)}
      />
      <input
        id="tab2"
        name="tab"
        type="radio"
        className="tab"
        checked={tabType === 2 && true}
        onChange={(e) => onTabChange(e)}
      />

      <div className="label-container">
        <label htmlFor="tab1" className="label tab_label ml-0">
          {tabFirst}
          {tabType === 1 && (
            <img src={getImageUrl('data-resources/line-tab.svg')} alt="icon" />
          )}
        </label>
        <label htmlFor="tab2" className="label tab_label">
          {tabSecond}
          {tabType === 2 && (
            <img src={getImageUrl('data-resources/line-tab.svg')} alt="icon" />
          )}
        </label>
      </div>
    </div>
  );
};

export default TabComponent;
