import React from 'react';
import { useSelector } from 'react-redux';
import { IEventsState } from '../../../../redux/event';
import { getImageUrl } from '../../../../utils';

import EventsInventoryTable from './EventsInventoryTable';
const IMAGE_BASE = 'data-resources/inventory';

const EventsInventory: React.FC<{
  serviceInventory?: boolean;
  serviceId?: number;
}> = ({ serviceInventory, serviceId }) => {
  const { eventsInvetoryItems, eventsInvetoryGroups } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  return (
    <>
      {eventsInvetoryGroups.length > 0 &&
        eventsInvetoryGroups.map(
          (
            group: {
              name?: any;
              id?: number;
              trunk?: any;
              items?: any;
              index?: any;
              groupId?: number;
            },
            index: number
          ) => {
            return (
              <div key={index} className={`m-0 mb-8 ${index <= 0 && 'mt-5'}`}>
                <p className="flex mb-2">
                  {group.name}
                  {group.trunk && (
                    <img
                      className="ml-2"
                      src={getImageUrl(`${IMAGE_BASE}/trunk-label.svg`)}
                      alt={group.name}
                    />
                  )}
                </p>
                <EventsInventoryTable
                  data={group.items}
                  parentIndex={index}
                  groupId={group.groupId}
                  serviceInventory={serviceInventory}
                  serviceId={serviceId}
                />
              </div>
            );
          }
        )}
      {eventsInvetoryItems.length > 0 && (
        <>
          <p>Individual Items</p>
          {eventsInvetoryItems.map((group, index: number) => {
            return (
              <div key={index} className={`m-0 mb-8 ${index <= 0 && 'mt-5'}`}>
                <EventsInventoryTable
                  data={group.items}
                  parentIndex={index}
                  groupId={group.groupId}
                  serviceInventory={serviceInventory}
                  serviceId={serviceId}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default EventsInventory;
