import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import BuildingConfiguration from './BuildingConfiguration';
import SetupStreaming from './SetupStreaming';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import './EventSetupForm.scss';
import '../Events.scss';
import { getImageUrl, getUTCTimeFromDateTime } from '../../../utils';
import {
  checkRequiredField,
  IEventsState,
  updateEventSetup,
} from '../../../redux/event';
import { Event, TinyObject } from '../../../models';
import Footer from '../../Common/Footer';
import { EVENT_TIME_FORMAT } from '../../../constants/index';
import {
  IFormState,
  updateEventSetupForm,
  updateServiceSetupForm,
} from '../../../redux/form';
import {
  productionTeamSchema,
  singleEventSchema,
} from '../../../validations/eventCreation';
import { ILoaderState } from '../../../redux/loader';

const EventSetupForm = () => {
  // eventData is an alias to eventSetupform
  const { eventSetupForm: eventData } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );

  const { register, setValue, errors, reset, handleSubmit, unregister } =
    useForm<Event>({
      defaultValues: { ...eventData },
    });

  const { push } = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const eventId = parseInt(params['eventId'], 10);
  const { eventInviteesData, eventBuildingData } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const virtual = query.get('virtual') === 'true';
  const isEdit = Boolean(query.get('edit'));
  const [fields, setFieldArray] = useState(eventData?.['productionTeams']);

  const getEventBuilding = () => {
    if (eventInviteesData?.['buildings']) {
      return eventInviteesData?.['buildings'];
    } else if (Object.keys(eventBuildingData).length > 0) {
      return eventBuildingData;
    } else {
      return eventData?.['buildings'];
    }
  };

  const onSubmit = async (data: Event) => {
    try {
      // Check if production team requirements toggle is on then
      // vendor type must be selected by admin
      if (data.productionTeamRequired) {
        data = {
          ...data,
          ...(await productionTeamSchema.validate({
            productionTeams: fields,
          })),
        };
      } else {
        data.productionTeams = [];
      }
      data = {
        ...data,
        ...(await singleEventSchema.validate({
          buildings: getEventBuilding(),
        })),
      };
      data.visibleTo =
        eventInviteesData?.['visibleTo'] ?? eventData?.['visibleTo'];
      data.maxCapacity = Number(
        data['maxCapacity'] ?? eventData?.['maxCapacity']
      );
      data.bookedInfo = 0;
      data.cancelled = eventData?.['cancelled'];
      data.service = data['service'] ?? eventData?.['service'];
      data.displayName = data['displayName'] ?? eventData?.['displayName'];
      data.description = data['description'] ?? eventData?.['description'];
      data.endDatetime = getUTCTimeFromDateTime(
        eventData?.['endDatetime'],
        EVENT_TIME_FORMAT
      );
      data.startDatetime = getUTCTimeFromDateTime(
        eventData?.['startDatetime'],
        EVENT_TIME_FORMAT
      );
      data.serviceCategory = eventData?.['serviceCategory'];
      data.venue = data['venue'] ?? eventData?.['venue'];
      data.recurring = eventData?.['recurring'];
      data.virtual = eventData?.['virtual'] ?? virtual;
      data.timezone = eventData?.['timezone'];
      data.sourceDate = eventData?.['sourceDate'];
      data.eventImage = eventData?.['eventImage'];
      data.serviceElements = eventData?.['serviceElements'];
      data.isPaid = eventData?.['isPaid']
      if(!eventData?.['isPaid']){
        data.price = 0
      }
      await dispatch(updateEventSetup(eventId, data));
      push(
        `/events/${eventId}/create?step=vendor-assignment&tab=vendor-invite&edit=true`
      );
    } catch (error) {
      dispatch(checkRequiredField(error?.['message']));
    }
  };

  const buildingList = () => {
    if (eventInviteesData?.['buildings']) {
      return eventInviteesData?.['buildings'];
    } else if (Object.keys(eventBuildingData).length > 0) {
      return eventBuildingData;
    } else {
      return eventData?.['buildings'];
    }
  };
  const onFieldInputChange = async (
    keyName: string,
    keyValue: string | number | TinyObject | boolean
  ) => {
    const updatedItem = eventData;
    updatedItem[`${keyName}`] = keyValue;
    updatedItem[`buildings`] = buildingList();
    updatedItem[`virtual`] = eventData?.['virtual'] ?? virtual;
    updatedItem[`visibleTo`] =
      eventInviteesData?.['visibleTo'] ?? eventData?.['visibleTo'];
    await dispatch(updateEventSetupForm(eventData));
  };

  // On Production team requirements toggle ON/OFF
  const onAddProductionTeam = async (
    isToggle: boolean,
    addNewItem: boolean
  ) => {
    if (isToggle) {
      if (addNewItem) {
        setFieldArray((prevFields) => {
          return [
            ...prevFields,
            {
              id: null,
            },
          ];
        });
      } else {
        setFieldArray(() => eventData?.['productionTeams']);
      }
    } else {
      setFieldArray([]);
      dispatch(
        updateEventSetupForm({
          ...eventData,
          productionTeams: [],
        })
      );
    }
  };

  // Remove added production team from event
  const onDeleteProdTeam = async (id: number) => {
    setFieldArray(() => {
      return fields.filter((item) => item.id !== id);
    });
    await dispatch(
      updateServiceSetupForm({
        ...eventData,
        productionTeams: fields.filter((item) => item.id !== id),
      })
    );
  };

  // Vendor type on change events handler
  const onChange = (
    e: HTMLInputElement | { value: string | object },
    index: number
  ) => {
    setFieldArray((prevFields) => {
      prevFields[index] = e.value;
      dispatch(
        updateEventSetupForm({
          ...eventData,
          productionTeams: [...prevFields],
        })
      );
      return [...prevFields];
    });
  };

  return (
    <div className="event_setup event-setup-right">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-x-2 gap-y-0">
          <BuildingConfiguration
            formErrors={errors}
            register={register}
            setValue={setValue}
            reset={reset}
            eventId={eventId}
            eventData={eventData}
            isEdit={isEdit}
            isSingle={true}
            onFieldInputChange={onFieldInputChange}
            virtual={eventData?.['virtual'] ?? virtual}
            isPast={eventData?.['isPastEvent']}
          />
          <SetupStreaming
            formErrors={errors}
            register={register}
            setValue={setValue}
            buildingId={
              Object.keys(eventInviteesData).length > 0
                ? eventInviteesData?.['buildings']?.[0]['id']
                : eventData?.['buildings']?.[0]?.['id']
            }
            eventData={eventData}
            virtual={eventData?.['virtual'] ?? virtual}
            isEdit={isEdit}
            isPast={eventData?.['isPastEvent']}
            onFieldInputChange={onFieldInputChange}
            reset={reset}
            onAddProductionTeam={onAddProductionTeam}
            fields={fields}
            unregister={unregister}
            onDeleteProdTeam={onDeleteProdTeam}
            setFieldArray={setFieldArray}
            onChange={onChange}
          />
        </div>
        <Footer
          buttonText="Save and Next"
          isArrow={false}
          buttonClass={`recurring_continue ${loading && 'cancel_publish'}`}
          isRightIcon={true}
          rightIcon={getImageUrl('Events/continuearrow.svg')}
          rightIconClass="pl-4"
          type="submit"
        />
      </form>
    </div>
  );
};

export default EventSetupForm;
