import {
  IUserLogin,
  IUserLoginResponse,
  IUserRefreshResponse,
  IUserRefreshBody,
} from './../../models/user';
import { authHttp } from '../common/http.service';
import { AUTH_BASE_URL } from './../../constants';
import { AxiosResponse } from 'axios';

export const loginApi = async (data: IUserLogin): Promise<IUserLoginResponse> =>
  authHttp
    .post(`${AUTH_BASE_URL}/`, data, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<IUserLoginResponse>) => {
      return response.data;
    });

export const refreshTokenApi = async (
  data: IUserRefreshBody
): Promise<IUserRefreshResponse> =>
  authHttp
    .post(`/refresh/`, data, {
      errorHandler: false,
    })
    .then((response: AxiosResponse<IUserRefreshResponse>) => {
      return response.data;
    });
