import React, {useState} from 'react';
import { Topnav } from '@alfredclub/hom-planner-theme';
import './taskTemplate.scss';
import TaskGroups from './TaskGroups';
import { useHistory } from 'react-router-dom';
import Footer from '../Common/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { IServiceState } from '../../redux/service';
import { IEventsState } from '../../redux/event';
import { getTaskGroups } from '../../redux/taskTemplate';

const TaskTemplate = () => {
  const { location, push } = useHistory();
  const dispatch = useDispatch();
  const serviceId = location.state?.['serviceId'] ?? null;
  const serviceTask = location.state?.['serviceTask'];
  const isEdit = location.state?.['isEdit'];
  const serviceType = location.state?.['serviceType'];
  const eventId = location.state?.['eventId'];
  const [showIcon, setIcon] = useState<boolean>(false);
  const [defaultValue, setSearchvalue] = useState<object[]>(null);
  const { importedServiceTask } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { importedEventTask } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  let timer = null;
  const onSearch = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(getTaskGroups(inputValue, null));
          res(data);
        }, 1000))
    );
  };
  const clearSearchValue = async () => {
    setIcon(false);
    setSearchvalue(null);
    return await dispatch(getTaskGroups('', null));
  };

  return (
    <>
      <Topnav
        headerTitle="Task Templates"
        showSearchBar={true}
        placeholder="Search task group"
        cacheOptions={false}
        promiseOptions={onSearch}
        optionLabel="name"
        optionKey="id"
        onSearchOptionSelect={async (option) => {
          if (option) {
            setSearchvalue([option]);
            setIcon(true);
            await dispatch(
              getTaskGroups('', option.id)
            );
          }
        }}
        showCancelIcon={showIcon}
        defaultSearchValue={defaultValue}
        onClickOfCloseIcon={() => clearSearchValue()}
      />
      <TaskGroups
        serviceTask={serviceTask}
        serviceId={serviceId}
        serviceType={serviceType}
        isEdit={isEdit}
        eventId={eventId}
      />
      {(serviceTask || eventId) && (
        <Footer
          buttonText="Import Tasks"
          onClick={async () => {
            push(
              serviceId
                ? !isEdit
                  ? `/data-resources/services/add-service?tab=task-list&serviceType=${serviceType}&id=${serviceId}`
                  : `/data-resources/services/edit-service/${serviceId}?tab=task-list&serviceType=${serviceType}`
                : `/events/${eventId}/create?step=task-management&edit=true&tab=task-list`,
              serviceId
                ? {
                    taskImported:
                      importedServiceTask?.length > 0 ? true : false,
                    serviceId,
                  }
                : {
                    taskImported: importedEventTask?.length > 0 ? true : false,
                    eventId,
                  }
            );
          }}
        />
      )}
    </>
  );
};

export default TaskTemplate;
