import React, { useState, useEffect } from 'react';
import CommonLayout from '../CommonLayout/CommonLayout';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from '@alfredclub/hom-planner-theme';
import {
  getServiceDetail,
  getServices,
} from '../../redux/service/services.action';
import { usePagination, useFetch } from '../../hooks';
import { useHistory } from 'react-router-dom';
import AddServicePopup from './AddServicePopup';
import {
  clearPackageElementsForm,
  clearServicePriceForm,
  clearServiceSetupForm,
  clearServiceTaskListForm,
  clearServiceVendorForm,
} from '../../redux/form/form.action';
import { TOOLTIP_TEXT } from '../../constants/index';
import { getSerialNumber } from '../../utils';
import { clearServiceTaskGroupList, IServiceState } from '../../redux/service';

const ServiceListing = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);
  const [pageNo, setPage] = useState(0);
  const [addServicePopup, setServicePopup] = useState(false);

  const { status } = useFetch(getServices);
  const { pages, serviceCount } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const pagination = usePagination();

  const onPageClick = async (page: number) => {
    await pagination(pages, page - 1, getServices);
    setPage(page - 1);
  };

  const handleOnCellClick = async (e: any) => {
    setLoadingState(true);
    setRowState(e.row.original.id);
    clearServiceForms();
    await dispatch(clearServiceTaskGroupList());
    await dispatch(getServiceDetail(e.row.original.id));
    if (e.row.original.isPackage) {
      push(
        `services/edit-package/${e.row.original.id}?tab=elements&serviceType=2`
      );
      return;
    }
    push(`services/edit-service/${e.row.original.id}?tab=setup&serviceType=1`);
  };

  const onAddService = async (e: any) => {
    e.preventDefault();
    clearServiceForms();
    setServicePopup(true);
  };

  const clearServiceForms = async () => {
    await dispatch(clearServiceSetupForm());
    await dispatch(clearPackageElementsForm());
    await dispatch(clearServicePriceForm());
    await dispatch(clearServiceVendorForm());
    await dispatch(clearServiceTaskListForm());
    await dispatch(clearServiceTaskGroupList());
  };

  const onClosePopup = (e: Event) => {
    e.preventDefault();
    setServicePopup(false);
  };
  let timer = null;
  const onSearch = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getServices(10, 0, 'short', inputValue, 1, false)
          );
          res(data);
        }, 1000))
    );
  };
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  return (
    <div>
      {addServicePopup && (
        <AddServicePopup
          modalState={addServicePopup}
          onClosePopup={onClosePopup}
        />
      )}

      {status === 'fetched' ? (
        <CommonLayout
          headerTitle="All Services"
          showTable={true}
          navIconToolTipText={TOOLTIP_TEXT.services.new}
          tableColumnsData={pages?.get(pageNo)?.data}
          tableColumnsHeaders={[
            {
              Header: 'Sno',
              accessor: 'id',
              Cell: (row: any) => {
                return <div>{getSerialNumber(pageNo, row.row.index)}</div>;
              },
            },
            {
              Header: 'Service name',
              className: 'tableAnchor',
              accessor: (row: any) => (
                <>
                  {row.name}
                  <Loader
                    color="#e89a89"
                    position="center"
                    loading={showLoader && setRowClick === row.id}
                    size="0.75rem"
                    className={`${
                      showLoader && setRowClick === row.id && 'row_loader'
                    }`}
                  />
                </>
              ),
            },
            {
              Header: 'Category',
              accessor: 'category',
            },
            {
              Header: 'Configuration',
              accessor: (row: any) => (!row.isPackage ? 'Element' : 'Package'),
            },
            {
              Header: 'Mode of Delivery',
              accessor: (row: any) =>
                !row.virtualMode ? 'In-person' : 'Virtual',
            },
            {
              Header: 'Service Status',
              accessor: (row: any) => (!row.status ? 'Inactive' : 'Active'),
              className: 'table-cell listing_col_last',
            },
          ]}
          showSearchBar={true}
          showIcon={true}
          showPagination={!(serviceCount < 10) && true}
          recordsCount={serviceCount}
          onPageChange={(pageNumber) => onPageClick(pageNumber)}
          promiseOptions={onSearch}
          optionLabel="name"
          optionKey="id"
          onSearchOptionSelect={async (option) => {
            if (option) {
              setLoadingState(true);
              setRowState(option.id);
              clearServiceForms();
              await dispatch(getServiceDetail(option.id));
              if (option.isPackage) {
                push(
                  `services/edit-package/${option.id}?tab=elements&serviceType=2`
                );
                return;
              }
              push(
                `services/edit-service/${option.id}?tab=setup&serviceType=1`
              );
            }
          }}
          onCellClick={(e) => handleOnCellClick(e)}
          onClick={(e) => onAddService(e)}
          placeholder="Search service"
        />
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
    </div>
  );
};
export default ServiceListing;
