import moment from 'moment';
import { v4 as uuid4 } from 'uuid';
import Config from '../config/environment';

export const sidenavMenu = [
  {
    name: 'Events',
    route: '/events?tab=published&pageNo=0',
    label: 'events',
    showSelected: true,
    img: 'events1',
    showSideBar: false,
    toolTipText: 'Events Planner',
    nodes: [
      {
        name: 'Events',
        route: '/events?tab=published&pageNo=0',
        label: 'events',
        showSelected: true,
        img: '',
        nodes: [],
      },
    ],
  },
  {
    name: 'Data-resources',
    route: '/data-resources/clients/buildings',
    label: 'data-resources',
    showSelected: false,
    img: 'datasources1',
    showSideBar: true,
    toolTipText: 'Data Sources',
    nodes: [
      {
        name: 'CLIENTS',
        route: '/data-resources/clients/buildings',
        label: 'clients',
        showSelected: true,
        img: '',
        nodes: [
          {
            name: 'Buildings',
            route: '/data-resources/clients/buildings',
            label: 'buildings',
            showSelected: true,
            img: '',
            nodes: [],
          },
          {
            name: 'Spaces',
            route: '/data-resources/clients/spaces',
            label: 'spaces',
            showSelected: false,
            img: '',
            nodes: [],
          },
          {
            name: 'Management',
            route: '/data-resources/clients/management',
            label: 'management',
            showSelected: false,
            img: '',
            nodes: [],
          },
          {
            name: 'Clusters',
            route: '/data-resources/clients/clusters',
            label: 'clusters',
            showSelected: false,
            img: '',
            nodes: [],
          },
          {
            name: 'Building Staff',
            route: '/data-resources/clients/staff',
            label: 'staff',
            showSelected: false,
            img: '',
            nodes: [],
          },
          {
            name: 'Companies/Units',
            route: '/data-resources/clients/companies-units',
            label: 'companies-units',
            showSelected: false,
            img: '',
            nodes: [],
          },
        ],
      },
      {
        name: 'USERS',
        route: '/data-resources/users',
        label: 'users',
        showSelected: false,
        img: '',
        nodes: [],
      },
      {
        name: 'VENDORS',
        route: '/data-resources/vendors?type=individual',
        label: 'vendors',
        showSelected: false,
        img: '',
        nodes: [],
      },
      {
        name: 'SERVICES',
        route: '/data-resources/services',
        label: 'services',
        showSelected: false,
        img: '',
        nodes: [],
      },
      {
        name: 'INVENTORY',
        route: '/data-resources/inventory?tab=groups',
        label: 'inventory',
        showSelected: false,
        img: '',
        nodes: [],
      },
      {
        name: 'TASK TEMPLATES',
        route: '/data-resources/task-template',
        label: 'task-template',
        showSelected: false,
        img: '',
        nodes: [],
      },
      {
        name: 'ACCOUNTS',
        route: '/data-resources/accounts?tab=users',
        label: 'accounts',
        showSelected: false,
        img: '',
        nodes: [],
      },
    ],
  },
  {
    name: 'Ops',
    route: `${Config.OPS_DASH}`,
    label: 'opsdash1',
    showSelected: true,
    img: 'asc1',
    showSideBar: false,
    toolTipText: 'Ops Dash',
    port: 3001,
    nodes: [],
  },
  {
    name: 'Asc',
    route: `${Config.ASC_URL}`,
    label: 'asc',
    showSelected: true,
    img: 'opsdash1',
    showSideBar: false,
    toolTipText: 'Alfred Service Catalog',
    port: 4111,
    nodes: [],
  },
];

export const tableData = () => [
  {
    col1: 'Hello',
    col2: 'World',
    index: 1,
  },
  {
    col1: 'react-table',
    col2: 'rocks',
    index: 2,
  },
  {
    col1: 'whatever',
    col2: 'you want',
    index: 3,
  },
];

export const tableColumns = () => [
  { Header: 'Sno', accessor: 'index' },
  {
    Header: 'Building',
    accessor: 'col1', // accessor is the "key" in the data
  },
  {
    Header: 'Management Company',
    accessor: 'col2',
  },
];

export const timeOfTaskList = [
  {
    id: uuid4(),
    label: 'Day before the event',
    headerValue: 0,
    value: 0,
  },
  {
    id: uuid4(),
    label: 'Event day - before event starts',
    headerValue: 1,
    value: 1,
  },
  {
    id: uuid4(),
    label: 'Event day - After event starts',
    headerValue: 1,
    value: 2,
  },
];
export const timeOfTaskListEvent = [
  {
    id: uuid4(),
    label: 'Day before the event',
    headerValue: 0,
    value: 0,
  },
  {
    id: uuid4(),
    label: 'Event day - before event starts',
    headerValue: 1,
    value: 1,
  },
  {
    id: uuid4(),
    label: 'Event day - After event starts',
    headerValue: 1,
    value: 2,
  },
];
export const taskStartTime = [
  {
    label: 'Task time',
    value: 0,
    timeValues: [{ id: uuid4(), title: '10 am', value: 10 }],
  },
  {
    label: 'Before start time',
    value: 1,
    timeValues: [
      { id: uuid4(), title: '1 hour prior', value: -1 },
      { id: uuid4(), title: '2 hours prior', value: -2 },
      { id: uuid4(), title: '3 hours prior', value: -3 },
      { id: uuid4(), title: '4 hours prior', value: -4 },
      { id: uuid4(), title: '5 hours prior', value: -5 },
      { id: uuid4(), title: '6 hours prior', value: -6 },
      { id: uuid4(), title: '7 hours prior', value: -7 },
      { id: uuid4(), title: '8 hours prior', value: -8 },
      { id: uuid4(), title: '9 hours prior', value: -9 },
      { id: uuid4(), title: '10 hours prior', value: -10 },
      { id: uuid4(), title: '11 hours prior', value: -11 },
      { id: uuid4(), title: '12 hours prior', value: -12 },
    ],
  },
  {
    label: 'After start time',
    value: 2,
    timeValues: [
      { id: uuid4(), title: '1 hour after', value: 1 },
      { id: uuid4(), title: '2 hours after', value: 2 },
      { id: uuid4(), title: '3 hours after', value: 3 },
      { id: uuid4(), title: '4 hours after', value: 4 },
      { id: uuid4(), title: '5 hours after', value: 5 },
      { id: uuid4(), title: '6 hours after', value: 6 },
      { id: uuid4(), title: '7 hours after', value: 7 },
      { id: uuid4(), title: '8 hours after', value: 8 },
      { id: uuid4(), title: '9 hours after', value: 9 },
      { id: uuid4(), title: '10 hours after', value: 10 },
      { id: uuid4(), title: '11 hours after', value: 11 },
      { id: uuid4(), title: '12 hours after', value: 12 },
    ],
  },
];

export const ASWHContractOptions = [
  {
    id: uuid4(),
    title: 'Yes',
    value: true,
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
  {
    id: uuid4(),
    title: 'No',
    value: false,
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
];

export const ASWHfirstPoc = [
  {
    id: uuid4(),
    title: 'Sales',
    value: 'sales',
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
  {
    id: uuid4(),
    title: 'COS',
    value: 'cos',
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
  {
    id: uuid4(),
    title: 'Existing client added building to portfolio',
    value: 'existing',
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
  {
    id: uuid4(),
    title: 'Referral',
    value: 'referral',
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
];

export const typesOfProperty = [
  {
    id: uuid4(),
    title: 'Commercial',
    value: 'C',
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
  {
    id: uuid4(),
    title: 'Residential',
    value: 'R',
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
];

export const workAssociation = [
  {
    id: uuid4(),
    title: 'Full time',
    value: 1,
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
  {
    id: uuid4(),
    title: 'Part time',
    value: 0,
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
  {
    id: uuid4(),
    title: 'Freelancer',
    value: 2,
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
];

export const DeliveryOptions = [
  {
    id: uuid4(),
    title: 'In person',
    value: false,
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
  {
    id: uuid4(),
    title: 'Virtual',
    value: true,
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
];

export const EventLeadRequired = [
  {
    id: uuid4(),
    title: 'Yes',
    value: true,
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
  },
  {
    id: uuid4(),
    title: 'No',
    value: false,
    labelClass: 'inline-flex mt-1 mr-8',
    checked: true,
  },
];

export const pricingTypeOptions = [
  {
    id: '1',
    label: 'Per Hour',
    value: 1,
  },
  {
    id: '2',
    label: 'Per Session',
    value: 2,
  },
  {
    id: '0',
    label: 'Per Attendee Range',
    value: 0,
  },
];
export const pricingLabelOptions = [
  {
    id: '1',
    label: 'Per Hour',
    value: 1,
  },
  {
    id: '2',
    label: 'Flat Price',
    value: 2,
  },
  {
    id: '0',
    label: 'Flat Price',
    value: 0,
  },
];
export const perAttendeeRange = [
  {
    id: 1,
    range: 'Upto 50',
    value: 50,
  },
  {
    id: 2,
    range: '50 - 100',
    value: 100,
  },
  {
    id: 3,
    range: '100 - 200',
    value: 200,
  },
  {
    id: 4,
    range: '200 - 350',
    value: 350,
  },
];

export const EventVisibilityOptions = [
  {
    id: '1',
    label: 'Visible to selected buildings',
    value: 1,
  },
  {
    id: '2',
    label: 'Invitees Only',
    value: 2,
  },
  {
    id: '3',
    label: 'Specific Company Only',
    value: 3,
  },
];

export const serviceTypeOptions = [
  {
    id: '1',
    label: 'Vimeo',
    value: 1,
  },
  {
    id: '2',
    label: 'Whereby',
    value: 2,
  },
  {
    id: '3',
    label: 'Zoom',
    value: 3,
  },
  {
    id: '4',
    label: 'YouTube',
    value: 4,
  },
];

export const eventActions = [
  {
    id: uuid4(),
    label: 'Broadcast to users',
    value: '4',
    icon: 'Events/broadcast.svg',
  },
  {
    id: uuid4(),
    label: 'Delete Event',
    value: '1',
    icon: 'Events/delete.svg',
  },
  {
    id: uuid4(),
    label: 'Cancel Event',
    value: '2',
    icon: 'Events/cancel.svg',
  },
  {
    id: uuid4(),
    label: 'Unpublish Event',
    value: '3',
    icon: 'Events/unpublish.svg',
  },
  {
    id: uuid4(),
    label: 'Clone Event',
    value: '5',
    icon: 'Events/clone.svg',
  },
];

export const scheduleActions = [
  {
    id: uuid4(),
    label: 'Delete Schedule',
    value: '1',
    icon: 'Events/delete.svg',
  },
  {
    id: uuid4(),
    label: 'Cancel Schedule',
    value: '2',
    icon: 'Events/cancel.svg',
  },
  {
    id: uuid4(),
    label: 'Unpublish Schedule',
    value: '3',
    icon: 'Events/unpublish.svg',
  },
  {
    id: uuid4(),
    label: 'Clone Schedule',
    value: '5',
    icon: 'Events/clone.svg',
  },
];

export const eventsNavigation = [
  {
    id: 1,
    title: 'Event Setup',
    step: 'setup',
  },
  {
    id: 2,
    title: 'Vendor Assignment',
    step: 'vendor-assignment',
  },
  {
    id: 3,
    title: 'Tasks and Inventory',
    step: 'task-management',
  },
  {
    id: 4,
    title: 'Timeline Feed',
    step: 'timeline',
  },
  {
    id: 5,
    title: 'Users',
    step: 'users',
  },
];

export const TIME_FORMAT = 'LT';
export const EVENT_SEARCH_TYPE = 'buildings';
export const EVENT_DATE_FORMAT = 'DD MMM';
export const EVENT_DAY_FORMAT = 'dddd, MMM D';
export const EVENT_YEAR_FORMAT = 'YYYY';
export const EVENTS_PER_DAY = '';
export const EVENTS_KEYWORDS = '';
export const RECORDS_LIMIT = 10;
export const RECORDS_CMP = 'short';
export const RECORDS_PAGE = 0;
export const RECORDS_QUERY = '';
export const ALL_RECORDS = 0;
export const YEAR = new Date().getFullYear().toString();
export const CURRENT_MONTH = (new Date().getMonth() + 1).toString();
export const MONTH = '';
export const EVENTS_PARAM = 'upcoming';
export const DEFAULT_PARAM = 'published';
export const MONTHLY_VIEW = `${YEAR}-0${CURRENT_MONTH}-01`;
export const YEARLY_VIEW = '';
export const PROPERTY_BASE_URL = 'property/building-check';
export const BUILDING_BASE_URL = 'property/building';
export const MANAGEMENT_BASE_URL = 'property/management';
export const CLUSTER_BASE_URL = 'property/cluster';
export const WHITELABEL_BASE_URL = 'property/white-label';
export const STAFF_BASE_URL = 'profile/staff';
export const SPACE_BASE_URL = 'property/space';
export const TOAST_POSITION = 'bottomCenter';
export const BUILDING_BLOCKS_BASE_URL = 'property/block';
export const TOAST_PROGRESS_BAR = false;
export const USER_BASE_URL = 'profile/tenant';
export const USER_DETAILS_URL = '/profile/tenant/details';
export const ADD_BUILDING_BLOCKS_BASE_URL = '/profile/block/add';
export const DELETE_BUILDING_BLOCKS_BASE_URL = 'property/block/delete';
export const GET_BUILDING_BLOCK_DETAIL__URL = 'property/block/details';
export const UPDATE_BLOCK = 'profile/block';
export const VENDOR_BASE_URL = '/vendor/list';
export const SERVICE_BASE_URL = '/service';
export const INVENTORY_BASE_URL = '/service/inventory';
export const ACCOUNTS_BASE_URL = '/profile/accounts';
export const EVENTS_BASE_URL = '/events';
export const EVENTS_BASE_RECURRING_URL = '/events/recurring';
export const AUTH_BASE_URL = '/auth';
export const BROADCAST_URL = '/planner-broadcast';
export const SERVICE_TASKS_URL = 'service-tasks/details';
export const TASKS_BASE_URL = '/service/task-templates';

export const TOAST_MESSAGES = {
  building: {
    added: 'Building added successfully',
    updated: 'Building updated successfully',
    whiteLabel: 'Building whiteLabeled successfully',
    buildingCode: 'HOM URL already exists',
  },
  space: {
    added: 'Space added successfully',
    updated: 'Space updated successfully',
    deleted: 'Space deleted successfully',
  },
  cluster: {
    added: 'Cluster added successfully',
    updated: 'Cluster updated successfully',
    deleted: 'Cluster deleted successfully',
  },
  management: {
    added: 'Management added successfully',
    updated: 'Management updated successfully',
    deleted: 'Management deleted successfully',
  },
  staff: {
    added: 'Staff added successfully',
    updated: 'Staff updated successfully',
    deleted: 'Staff deleted successfully',
  },
  user: {
    added: 'User added successfully',
    updated: 'User updated successfully',
    deleted: 'User deleted successfully',
  },
  blocks: {
    added: 'Blocks added successfully',
    updated: 'Block updated successfully',
    deleted: 'Block deleted successfully',
  },
  vendor: {
    added: 'Vendor added successfully',
    updated: 'Vendor updated successfully',
    deleted: 'Vendor deleted successfully',
  },
  element: {
    added: 'Element added successfully',
    updated: 'Element updated successfully',
    deleted: 'Element deleted successfully',
  },
  service: {
    updated: 'Service updated successfully',
    deleted: 'Price deleted successfully',
  },
  inventory: {
    deleted: 'Item deleted successfully',
    itemLocationUpdate: 'Item location updated successfully',
    added: 'Item(s) added successfully',
    groupAdded: 'Group added successfully',
    groupUpdated: 'Group updated successfully',
    groupDeleted: 'Group deleted successfully',
  },
  account: {
    updated: 'Email updated successfully',
    emailSent: 'Email sent successfully',
  },
  eventVendor: {
    cancelled: 'Vendor Assignment Cancelled!!',
    emailSent: 'Email sent successfully',
    reminderSent: 'Reminder sent via email!',
  },
  report: {
    noRecord: 'Report unavailable, due to empty records',
  },
  events: {
    published: 'Event published',
    unpublished: 'Event unpublished',
    deleted: 'Event deleted',
    scheduleDeleted: 'Schedule deleted',
    cancelled: 'Event cancelled',
    scheduleCancelled: 'Schedule cancelled',
    cloned: 'Event cloned',
    scheduledCloned: 'Schedule cloned',
    pastEvent: 'Sorry! Past Event is not publishing',
    broadcastMessage: 'Broadcast message sent',
    eventTaskDeleted: 'Task deleted successfully',
  },
  login: {
    noAccess: 'Sorry, You do not have admin permissions',
  },
  taskTemplate: {
    deleted: 'Task deleted successfully',
    taskUpdate: 'Task updated successfully',
    added: 'Task(s) added successfully',
    groupAdded: 'Group added successfully',
    groupUpdated: 'Group updated successfully',
    groupDeleted: 'Group deleted successfully',
    taskRemoved: 'Task removed successfully form task template',
  },
};

export const TOOLTIP_TEXT = {
  buildings: {
    new: 'Add new building',
    residential: 'Residential Building',
    commercial: 'Commercial Building',
  },
  spaces: {
    new: 'Add new space',
  },
  clusters: {
    new: 'Add new cluster',
  },
  managements: {
    new: 'Add new management',
  },
  buildingStaff: {
    new: 'Add new building staff',
  },
  companyUnits: {
    new: 'Add new company/unit',
  },
  services: {
    new: 'Add new service',
    task: {
      description: 'Task description',
      vendor: 'Vendor assigned',
      element: 'Element name',
    },
  },
  users: {
    new: 'Add new user',
  },
  vendors: {
    new: 'Add new vendor',
    detailSaveMessage: 'Save company details first to add a new contact',
  },
  events: {
    createEvent: 'Create new event',
    virtual: 'Virtual',
    inPerson: 'In person',
    regionalTimeMessage:
      'Please set time and save ‘Event Setup’ to view regional times',
    taskList: {
      downloadPdf: 'Download task list',
      copyCallSheetLink: 'Copy callsheet link',
    },
  },
  inventory: {
    group: {
      new: 'Add new group',
      edit: 'Edit group name',
      delete: 'Delete group',
      deleteItem: 'Remove item from group',
    },
    item: {
      delete: 'Delete item',
      add: 'Add item',
    },
  },
  taskTemplate: {
    serviceToggle:
      'Disable ‘Auto-add’ for future services and events. You will still be able to add the template manually from service templates and events',
  },
};

export const emailDropDownOptions = [
  { id: 1, label: 'Forgot password', value: 'forgot-password' },
  { id: 2, label: 'Log in', value: 'login' },
];

export const EVENT_FORMAT = 'YYYY-MM-DD';
export const EVENT_TIME_FORMAT = `${EVENT_FORMAT} HH:mm`;
export const EVENT_MIN_TIME = '12:00 AM';
export const DAY_BEFORE_EVENT_TIME = '10:00 AM';
export const eventStandardFormat = 'h:mm a';
export const eventCustomDateTimeFormat = 'h:mma, MMM DD YYYY';
export const eventCustomTimeFormat = 'h:mma';
export const eventTimeDateFormat = 'MMM DD YYYY';
export const datepickerformat = 'MM-DD-YYYY';
export const dateAndTimeFormat = 'MM-DD-YYYY h:mm A';
export const utcTimeFormat = 'HH:mm';
export const USEasternTimezone = 'US/Eastern';
export const tooltipTimeFormat = 'ddd h:mma';
export const timeLineProgressIcons = [
  { id: 1, path: 'Events/timeline1.svg' },
  { id: 2, path: 'Events/timeline2.svg' },
  { id: 3, path: 'Events/timeline3.svg' },
  { id: 4, path: 'Events/timeline4.svg' },
];

export const timeLineArray = [
  {
    id: 1,
    datetime: '2021-02-10 07:43:47.928832+00:00',
    text: 'Event Created',
    subText: '',
  },
  {
    id: 2,
    datetime: '2021-02-10 07:43:47.928832+00:00',
    text: 'Event Booked and 3 Vendor Requests sent',
    subText: 'Hillary Helming, Cassie Coulas, Evelyn Invited',
  },
  {
    id: 3,
    datetime: '2021-02-10 07:43:47.928832+00:00',
    text: 'Glam Squad Accepted',
    subText: '',
  },
  {
    id: 4,
    datetime: '2021-02-10 07:43:47.928832+00:00',
    text: 'Event Published',
    subText: '',
  },
];

export const eventSearchOptions = [
  { id: uuid4(), label: 'Buildings', value: 'buildings' },
  { id: uuid4(), label: 'Users', value: 'users' },
  { id: uuid4(), label: 'Vendors', value: 'vendors' },
];
export const timeFormat = 'h:mm A';
export const timelineDateFormat = 'D MMM YY';
export const timelineTimeFormat = 'h:mm a';
export const commonTimeFormat = 'MM-DD-YYYY';
export const vendorFormat = 'DD MMM YYYY';

export const weekDaysArray = [
  {
    id: 1,
    name: 'Mon',
    fullName: 'Monday',
  },
  {
    id: 2,
    name: 'Tue',
    fullName: 'Tuesday',
  },
  {
    id: 3,
    name: 'Wed',
    fullName: 'Wednesday',
  },
  {
    id: 4,
    name: 'Thu',
    fullName: 'Thursday',
  },
  {
    id: 5,
    name: 'Fri',
    fullName: 'Friday',
  },
  {
    id: 6,
    name: 'Sat',
    fullName: 'Saturday',
  },
  {
    id: 7,
    name: 'Sun',
    fullName: 'Sunday',
  },
];

export const vendorTypeDropdown = [
  { id: 1, label: 'Invited Vendors' },
  { id: 2, label: 'Uninvited Vendors' },
];

export const MIN_DATE_FORMAT = 'YYYY/MM/DD';

export const profileOptions = [
  {
    value: '1',
    id: uuid4(),
    label: 'Generate event report',
  },
  {
    value: '2',
    id: uuid4(),
    label: 'Logout',
  },
];

export const currentYear = parseInt(moment().format('YYYY'), 10);
export const currentMonth = parseInt(moment().format('M'), 10);

export const MonthOptions = [
  {
    id: 1,
    name: `January`,
  },
  {
    id: 2,
    name: `February`,
  },
  {
    id: 3,
    name: `March`,
  },
  {
    id: 4,
    name: `April`,
  },
  {
    id: 5,
    name: `May`,
  },
  {
    id: 6,
    name: `June`,
  },
  {
    id: 7,
    name: `July`,
  },
  {
    id: 8,
    name: `August`,
  },
  {
    id: 9,
    name: `September`,
  },
  {
    id: 10,
    name: `October`,
  },
  {
    id: 11,
    name: `November`,
  },
  {
    id: 12,
    name: `December`,
  },
];
export const callSheetPermissions = {
  showPrice: false,
  showAllTask: true,
  showMaps: true,
  showNavbar: true,
  showVendorNotes: false,
  ctaDisabled: true,
};
export const yearOptions = [
  {
    id: Number(`${currentYear - 1}`),
    name: `${currentYear - 1}`,
  },
  {
    id: Number(`${currentYear}`),
    name: `${currentYear}`,
  },
  {
    id: Number(`${currentYear + 1}`),
    name: `${currentYear + 1}`,
  },
];

export const vendorCostRangeFilter = [
  { costRange: '$', value: 1 },
  { costRange: '$$', value: 2 },
  { costRange: '$$$', value: 3 },
];

export const productionDefaultRegions = [
  { regionName: 'Los Angeles, CA', id: 61, regionTimezone: 'US/Pacific' },
  { regionName: 'New York, NY', id: 27, regionTimezone: 'US/Eastern' },
  { regionName: 'Houston, TX', id: 86, regionTimezone: 'US/Central' },
];
export const qaDefaultRegions = [
  { regionName: 'New York, NY', id: 27, regionTimezone: 'US/Eastern' },
  { regionName: 'Los Angeles, CA', id: 61, regionTimezone: 'US/Eastern' },
  { regionName: 'Houston', id: 86, regionTimezone: 'US/Central' },
];
export const defaultTimezone = 'US/Eastern';
export const defaultCancellationPolicy = `Cancellations by Client shall entitle HOMH to an administrative fee of: one hundred (100%) of the budget for the subject Event as established in the Event Proposal if the Event is cancelled within forty-eight (48) hours of the scheduled start date of the Event. This agreement can be cancelled with 30 days' written notice by either party. Changes to Event Proposals by Client shall entitle HOMH to an administrative fee of thirty-five percent (35%) of any dollar-value difference between the budget of the Event as established in the initial Event Proposal and the revised budget of the Event as established in a mutually-agreed upon change order. Client agrees that any postponements within a 48 hour notice will be subject to an additional 50% fee of the budget for the subject event.`;
export const defaultMaxAttendees = '350';

export const email = 'homteam@iamhom.com';

export const eventPageUrl = `/events?tab=published&pageNo=0`;
export const eventRelatedMessage = `Task list not saved yet to event, Click Save and Next`;
export const editTaskAlertRecurring =
  'Tasks can be edited only within individual events of the schedule';

export const selectedTagColor = '#fcedca';
export const selectedTagTextColor = '#5d5d5d';

export const taskTemplateGroupOptions = [
  {
    id: 1,
    title: 'By Category tag',
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
    name: 'category',
    placeholderText: 'Select category tag',
    addAllCheck: false,
    type: '',
    isInPersonService: false,
  },
  {
    id: 2,
    title: 'Add to Inperson elements',
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
    name: 'inpersonServices',
    placeholderText: 'Select element',
    addAllCheck: true,
    type: 'in-person',
    isInPersonService: true,
  },
  {
    id: 3,
    title: 'Add to Virtual elements',
    labelClass: 'inline-flex mt-1 mr-8',
    checked: false,
    name: 'virtualServices',
    placeholderText: 'Select element',
    addAllCheck: true,
    type: 'virtual',
    isInPersonService: false,
  },
];

export const TASK_TEMPLATE = {
  removeTemplateTask:
    'This will remove this task from the influence of any changes made at the task template level.',
  deleteGroup:
    'This will remove the group task from all services templates and events permanently.',
  toggleService:
    'This will hide the task group in all services templates and events.',
};
export const MULTIPLE_BUILDINGS = 'Multiple Buildings';
export const MULTIPLE_REGIONS = 'Multiple Regions';
export const LEARN_MORE_ABOUT =
  'https://docs.google.com/document/d/1UyDhCbJEAVrwY_QCnmdt7z9v_kERH9a4IF82-YwU3bM/edit';
