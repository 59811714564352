import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Input,
  PrimaryLabel,
  TagBox,
} from '@alfredclub/hom-planner-theme';
import {
  findIndexFromArrayOfObject,
  getESTTime,
  getImageUrl,
  getRegionTime,
  loadState,
} from '../../../utils';
import { useFetch } from '../../../hooks';
import { useDispatch } from 'react-redux';
import {
  cancelVendorAssignment,
  clearVendorsWithRegions,
  getUninvitedRegionsVendors,
  getVendorAssignmentInformation,
  getVendorsWithRegion,
  IEventsState,
  removeVendorWithRegions,
  resetVendorArray,
  sendReminderToVendor,
  vendorManuallyAssigned,
} from '../../../redux/event';
import { useSelector } from 'react-redux';
import {
  EventVendorsWithRegions,
  IEventVendors,
  Register,
} from '../../../models';
import { useParams } from 'react-router-dom';
import {
  defaultTimezone,
  timelineTimeFormat,
  vendorFormat,
  vendorTypeDropdown,
} from '../../../constants';
import { getVendorIndividualDetail } from '../../../redux/vendor/vendors.action';
import ReactTooltip from 'react-tooltip';
import { ButtonType } from '../../../enums';
import Accordion from '../../Common/Accordian/Accordion';
import LabelWithIcon from '../EventSetup/LabelWithIcon';

const VendorInvite: React.FC<{
  fieldIndex: number;
  register: Register;
  isRecurring?: boolean;
  weekday: number;
  eventTimeZone?: string;
  eventVendorAssigned?: object;
  onCancel: () => void;
}> = ({
  fieldIndex,
  register,
  isRecurring,
  weekday,
  eventTimeZone,
  eventVendorAssigned,
  onCancel,
}) => {
  const [itemArray, setItem] = useState([]);
  const [resetArray, setResetArray] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState(0);
  const [eventData] = useState(loadState('recurringSetupForm'));
  const [singleEventData] = useState(loadState('eventSetupForm'));

  const dispatch = useDispatch();
  const [invited, setInvited] = useState({});
  const { eventId } = useParams<{ eventId: string }>();
  useFetch(clearVendorsWithRegions);

  const handleOnChange = async (rowData: IEventVendors) => {
    const selectedItem = [...itemArray];
    const itemIdIndex = findIndexFromArrayOfObject(selectedItem, 'id', rowData);
    if (itemIdIndex !== -1) {
      selectedItem.splice(itemIdIndex, 1);
      setSelectedVendors((prevData) => prevData - rowData.vendors.length);
    } else {
      selectedItem.push({
        ...rowData,
        selectedRegions: [{ id: rowData['id'] }],
        weekday: isRecurring ? weekday : null,
      });
      setSelectedVendors((prevData) => prevData + rowData.vendors.length);
    }
    setItem(() => [...selectedItem]);
  };

  const {
    vendorWithRegions,
    invitedVendors,
    eventInvitedVendors,
    selectedRegionsVendor,
    getInfoApiStatus,
  } = useSelector((state: { events: IEventsState }) => state.events);
  const resetVendorsArray = async () => {
    if (resetArray.length > 0) {
      await dispatch(resetVendorArray(resetArray, weekday));
      setSelectedVendors((prevCount) =>
        prevCount > 0 ? prevCount + resetArray.length : prevCount
      );
      setResetArray([]);
    }
  };

  const onCancelAssignment = async () => {
    let data = {};
    if (isRecurring) {
      data = {
        weekday: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.weekday,
      };
    }
    await dispatch(
      cancelVendorAssignment(Number(eventId), data, isRecurring, weekday)
    );
    await dispatch(
      getVendorAssignmentInformation(
        Number(eventId),
        eventData?.['recurring'],
        fieldIndex
      )
    );
    onCancel();
  };

  useEffect(() => {
    if (Object.keys(invitedVendors).length > 0) {
      setInvited({ ...invited, [fieldIndex]: 1 });
      setItem([]);
      setSelectedVendors(0);
    } else if (weekday && getInfoApiStatus) {
      (async () =>
        await dispatch(
          getVendorsWithRegion(
            Number(eventId),
            isRecurring,
            weekday,
            eventTimeZone
          )
        ))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitedVendors.length, getInfoApiStatus]);

  useEffect(() => {
    setInvited({ ...invited, [fieldIndex]: 2 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldIndex]);

  useEffect(() => {
    if (
      Object.keys(selectedRegionsVendor)?.length > 0 ||
      selectedRegionsVendor.length > 0
    ) {
      if (
        invitedVendors[weekday]?.['selectedRegions'].length <= 0 &&
        getInfoApiStatus
      ) {
        (async () =>
          await dispatch(
            getVendorsWithRegion(
              Number(eventId),
              isRecurring,
              weekday,
              eventTimeZone
            )
          ))();
        setInvited({ ...invited, [fieldIndex]: 2 });
      } else if (weekday && getInfoApiStatus) {
        (async () => await dispatch(clearVendorsWithRegions()))();
        (async () =>
          await dispatch(
            getUninvitedRegionsVendors(
              Number(eventId),
              isRecurring,
              weekday,
              eventTimeZone,
              weekday
            )
          ))();
        setResetArray([]);
        setInvited({ ...invited, [fieldIndex]: 1 });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedRegionsVendor?.[weekday]?.selectedRegions]);

  const onSendReminder = async () => {
    const reminderVendor = [];
    if (Object.keys(invitedVendors)?.length > 0 && !isRecurring) {
      invitedVendors?.[weekday]?.['inviteeList']?.map((item: IEventVendors) => {
        return item.vendors.map((data) => {
          if (
            reminderVendor.filter(({ id }) => id === data.vendorId).length > 0
          ) {
            // Do nothing
          } else {
            reminderVendor.push({ id: data.vendorId });
          }
          return reminderVendor;
        });
      });
    }
    if (reminderVendor.length > 0) {
      await dispatch(
        sendReminderToVendor(Number(eventId), isRecurring, weekday, {
          vendors: reminderVendor,
        })
      );
    } else {
      await dispatch(
        sendReminderToVendor(Number(eventId), isRecurring, weekday, {
          weekday: invitedVendors?.[weekday]?.weekday,
        })
      );
    }
  };

  const isVirtualEvent = (recurring) => {
    if (recurring) {
      return eventData?.['virtual'];
    } else {
      return singleEventData?.['virtual'];
    }
  };

  const onVendorUrlChange = async (e) => {
    const vendorObj = [
      {
        docList: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.docList,
        note: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.note,
        vendor: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.vendor,
        customStreamUrl: e.target.value,
        weekday: eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.weekday,
        manuallyAssigned: false,
      },
    ];
    await dispatch(
      vendorManuallyAssigned(
        { vendorAssign: [vendorObj[0]] },
        Number(eventId),
        eventData?.['recurring'],
        eventData?.timezone?.zoneValue ?? defaultTimezone,
        weekday
      )
    );
  };

  return (
    <>
      {Object.keys(eventVendorAssigned).length > 0 &&
      eventVendorAssigned?.[weekday]?.['vendorAssign'].length > 0 &&
      eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.['weekday'] ===
        (isRecurring
          ? weekday
          : eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.[
              'weekday'
            ]) &&
      !eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.[
        'manuallyAssigned'
      ] ? (
        <div className="manually_assignment p-6">
          {isRecurring && (
            <>
              <PrimaryLabel
                labelText="Please note:"
                className="block font-medium font-brandonMedium"
              />
              <PrimaryLabel
                labelText="Changes in vendor assignment in individual events of schedule are visible only at event level"
                className="block pb-8"
              />
            </>
          )}
          <img
            src={getImageUrl('Events/done.svg')}
            alt="icon"
            className="inline-flex pr-2"
          />
          <span
            onClick={async () => {
              await dispatch(
                getVendorIndividualDetail(
                  eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.[
                    'vendor'
                  ]?.['id']
                )
              );
              window.open(
                `/data-resources/vendors/${eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.['vendor']?.['id']}`,
                '_blank'
              );
            }}
            className="sub-header img_label cursor-pointer sub-text-color underline"
          >
            {`${eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.['vendor']?.['vendorName']}`}
          </span>{' '}
          <span className="sub-header img_label">Assigned</span>
          <a
            href={`mailto:${eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]?.['vendor']?.['email']}`}
          >
            <img
              src={getImageUrl('Events/email-icon.svg')}
              alt="email"
              className="block float-right"
            />
          </a>
          <div onClick={onCancelAssignment}>
            <PrimaryLabel
              labelText="Cancel Assignment"
              className="block cancel_assign"
            />
          </div>
          <div className={`pt-8 ${!isVirtualEvent(isRecurring) && 'hidden'}`}>
            <LabelWithIcon
              imgSrc={getImageUrl('Events/streaming.svg')}
              labelText="Streaming Settings"
              className="img_label"
            />
            <div className="grid grid-cols-1 gap-x-2 gap-y-0">
              <div className="mt-4">
                <PrimaryLabel labelText="Vendor URL" />
                <Input
                  label=""
                  placeholder="vimeo.com"
                  name={`vendorAssign[${fieldIndex}].streamLink`}
                  ref={register()}
                  value={
                    eventVendorAssigned?.[weekday]?.['vendorAssign']?.[0]
                      ?.customStreamUrl
                  }
                  onInputBlur={(e) => {
                    onVendorUrlChange(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="vendor_invite p-6">
          {Object.keys(eventVendorAssigned).length > 0 &&
            eventVendorAssigned?.[weekday]?.['vendorAssign'].length > 0 &&
            invitedVendors[weekday] && (
              <PrimaryLabel
                labelText="Please cancel existing vendor assignment to send new invites."
                className={`block manually-assigned-vendor ${
                  invited[fieldIndex] === 2 ? 'hidden' : ''
                }`}
              />
            )}
          {invitedVendors[weekday]?.['invitationCount'] > 0 && (
            <Dropdown
              options={vendorTypeDropdown}
              labelKey="label"
              valueKey="id"
              label=""
              value={vendorTypeDropdown[0]}
              onOptionChange={(e) => {
                setSelectedVendors(0);
                setInvited({ ...invited, [fieldIndex]: e['id'] });
              }}
              ddlClass="invite-dropdown"
            />
          )}
          {Object.keys(eventInvitedVendors)?.length > 0 && (
            <div className="mb-4">
              <PrimaryLabel
                labelText={`${
                  eventInvitedVendors?.[weekday]?.['invitationCount'] ?? '0'
                } vendors invited ${
                  eventInvitedVendors?.[weekday]?.['invitedOn']
                    ? ` on ${getESTTime(
                        eventInvitedVendors?.[weekday]?.['invitedOn'],
                        vendorFormat,
                        eventTimeZone
                      )} `
                    : ''
                }`}
                className={`sub-header ${
                  invited[fieldIndex] === 2 ? 'hidden' : ''
                }`}
              />

              <PrimaryLabel
                labelText="Not accepted yet."
                className={`block ${
                  invited[fieldIndex] === 2 ? 'hidden' : ''
                } ${
                  eventInvitedVendors?.[weekday]?.['invitationCount'] > 0
                    ? ''
                    : 'hidden'
                }`}
              />
              <div
                data-tip={`${
                  Object.keys(eventVendorAssigned).length > 0
                    ? eventVendorAssigned?.[weekday]?.['vendorAssign'].length >
                      0
                      ? 'The vendor is already assigned.'
                      : ''
                    : ''
                }`}
              >
                <Button
                  label="Send Reminder"
                  type={ButtonType.BUTTON}
                  className={`px-simple-button ${
                    invited[fieldIndex] === 2 ? 'hidden' : ''
                  } ${
                    eventInvitedVendors?.[weekday]?.['invitationCount'] > 0
                      ? ''
                      : 'hidden'
                  } ${
                    Object.keys(eventVendorAssigned).length > 0 &&
                    eventVendorAssigned?.[weekday]?.['vendorAssign'].length >
                      0 &&
                    'cursor-not-allowed opacity-50'
                  }`}
                  disabled={
                    Object.keys(eventVendorAssigned).length > 0 &&
                    eventVendorAssigned?.[weekday]?.['vendorAssign'].length > 0
                  }
                  onClick={() => onSendReminder()}
                />
              </div>
            </div>
          )}
          {Object.keys(eventVendorAssigned).length > 0 &&
            eventVendorAssigned?.[weekday]?.['vendorAssign'].length > 0 && (
              <PrimaryLabel
                labelText="Please cancel existing vendor assignment to send new invites."
                className={`block manually-assigned-vendor ${
                  invited[fieldIndex] === 1 ? 'hidden' : ''
                } ${
                  invitedVendors[weekday]?.['invitationCount'] > 0 && 'hidden'
                }`}
              />
            )}

          {/* Future use */}
          {/* <PrimaryLabel
            labelText="No vendors found for this service"
            className={`sub-header block ${
              invited[fieldIndex] === 1 ? 'hidden' : ''
            } ${
              vendorRegionCount.length > 0 &&
              vendorRegionCount[0]?.[weekday] > 0 &&
              'hidden'
            } ${invitedVendors[weekday]?.['invitationCount'] > 0 && 'hidden'}`}
          /> */}
          <PrimaryLabel
            labelText={`${selectedVendors} vendors selected to invite`}
            className={`sub-header ${
              invited[fieldIndex] === 1 ? 'hidden' : ''
            }`}
            // ${
            //   invitedVendors[weekday]?.['invitationCount'] > 0 && 'hidden'
            // }
          />
          {Object.keys(eventInvitedVendors)?.length > 0 &&
            eventInvitedVendors?.[weekday]?.['invitationCount'] > 0 && (
              <PrimaryLabel
                labelText={`${
                  Object.keys(eventInvitedVendors)?.length > 0 &&
                  eventInvitedVendors?.[weekday]?.['invitationCount']
                } vendors invited previously`}
                className={`block ${
                  invited[fieldIndex] === 1 || invitedVendors.length <= 0
                    ? 'hidden'
                    : ''
                }`}
              />
            )}
          <div
            data-tip={`${
              Object.keys(eventVendorAssigned).length > 0
                ? eventVendorAssigned?.[weekday]?.['vendorAssign'].length > 0
                  ? 'Please cancel existing vendor assignment to assign new vendor'
                  : ''
                : ''
            }`}
            data-for="invite"
          >
            <Button
              label="Send Invite"
              isLeftIcon={true}
              leftIcon={getImageUrl('Events/sendinvite.svg')}
              disabled={itemArray.length <= 0 || selectedVendors <= 0}
              className={`${
                (itemArray.length <= 0 || selectedVendors <= 0) &&
                'invite_disable'
              } ${invited[fieldIndex] === 1 ? 'hidden' : ''}`}
              type={ButtonType.SUBMIT}
            />
          </div>
          <ReactTooltip
            className="invite-tooltip"
            place="bottom"
            effect="float"
            id="invite"
          />
          {invited[fieldIndex] === 2 ? (
            <div className="vendor_assignments mt-12">
              {vendorWithRegions.length > 0 &&
                vendorWithRegions[0]?.[weekday]?.length > 0 && (
                  <button
                    onClick={resetVendorsArray}
                    type="button"
                    className={`text-Color_5 text-Small refresh-btn ${
                      Object.keys(eventVendorAssigned).length > 0 &&
                      eventVendorAssigned?.[weekday]?.['vendorAssign'].length >
                        0
                        ? 'pointer-events-none invite_disable'
                        : ''
                    }`}
                  >
                    <img
                      src={getImageUrl('Events/refresh.svg')}
                      alt="icon"
                      className={`inline-flex pr-1`}
                    />
                    Refresh
                  </button>
                )}
              {vendorWithRegions.length > 0 &&
                vendorWithRegions[0]?.[weekday]?.length > 0 &&
                vendorWithRegions[0]?.[weekday]?.map(
                  (item: IEventVendors, index: number) => {
                    return (
                      <>
                        {item.vendors.length > 0 && (
                          <div key={item.id.toString()}>
                            <Accordion
                              active="active"
                              height="auto"
                              accClass="pb-4"
                              invClass={`p-0 pb-4 ${
                                Object.keys(eventVendorAssigned).length > 0 &&
                                eventVendorAssigned?.[weekday]?.['vendorAssign']
                                  .length > 0
                                  ? 'pointer-events-none invite_disable'
                                  : ''
                              }`}
                              title={
                                <div className="flex mb-2">
                                  <input
                                    type="checkbox"
                                    className={`checkbox mr-4`}
                                    key={index}
                                    id={index.toString()}
                                    name={`vendorAssign[${fieldIndex}].inviteList`}
                                    ref={register()}
                                    value={JSON.stringify(itemArray)}
                                    onChange={() => {
                                      handleOnChange(item);
                                    }}
                                    checked={
                                      findIndexFromArrayOfObject(
                                        itemArray,
                                        'id',
                                        item
                                      ) !== -1
                                        ? true
                                        : false
                                    }
                                  />
                                  <PrimaryLabel
                                    labelText={item.regionName}
                                    className="region_label"
                                  />
                                  &nbsp;
                                  <PrimaryLabel
                                    labelText={`${
                                      item?.regionTimezone
                                        ? getRegionTime(
                                            item.regionTime,
                                            timelineTimeFormat,
                                            item.regionTimezone
                                          )
                                        : ''
                                    }`}
                                    className="region_label"
                                  />
                                  &nbsp;
                                  <PrimaryLabel
                                    labelText={`- ${item.vendors.length.toString()} vendors`}
                                    className="region_label"
                                  />
                                </div>
                              }
                              content={
                                <div
                                  className="tag_max_width"
                                  id={index.toString()}
                                >
                                  {item.vendors?.length > 0 &&
                                    item.vendors.map(
                                      (data: EventVendorsWithRegions) => {
                                        return (
                                          <TagBox
                                            tagLabel={`${data.vendorName}`}
                                            id={data.vendorId.toString()}
                                            key={data.vendorId}
                                            className={`tag_blocks ${
                                              Object.keys(eventVendorAssigned)
                                                .length > 0 &&
                                              eventVendorAssigned?.[weekday]?.[
                                                'vendorAssign'
                                              ].length > 0
                                                ? 'pointer-events-none invite_disable'
                                                : ''
                                            }`}
                                            onClick={async () => {
                                              const removedObjects = item.vendors.filter(
                                                (vendor) =>
                                                  vendor.vendorId ===
                                                  data.vendorId
                                              );
                                              setResetArray(() => [
                                                ...resetArray,
                                                {
                                                  index,
                                                  value: {
                                                    ...removedObjects[0],
                                                  },
                                                },
                                              ]);
                                              if (selectedVendors >= 1) {
                                                setSelectedVendors(
                                                  (prevCount) => prevCount - 1
                                                );
                                              }

                                              await dispatch(
                                                removeVendorWithRegions(
                                                  data.vendorId,
                                                  index,
                                                  weekday
                                                )
                                              );
                                            }}
                                            labelClassName={
                                              data?.vendorId &&
                                              'hover:underline'
                                            }
                                            onLabelClick={async () => {
                                              if (!data?.vendorId) {
                                                return;
                                              }
                                              await dispatch(
                                                getVendorIndividualDetail(
                                                  data?.vendorId
                                                )
                                              );
                                              window.open(
                                                `/data-resources/vendors/${data?.vendorId}`,
                                                '_blank'
                                              );
                                            }}
                                          />
                                        );
                                      }
                                    )}
                                </div>
                              }
                            />
                          </div>
                        )}
                      </>
                    );
                  }
                )}
            </div>
          ) : (
            <div>
              {Object.keys(invitedVendors).length > 0 &&
                invitedVendors?.[weekday]?.['inviteeList']?.map(
                  (item: IEventVendors, index: number) => {
                    return (
                      <>
                        {item.vendors.length > 0 && (
                          <div key={item.id.toString()}>
                            <Accordion
                              active="active"
                              height="auto"
                              invClass="p-0"
                              accClass="pb-4"
                              title={
                                <div className="flex mb-2">
                                  <PrimaryLabel
                                    labelText={item.regionName}
                                    className="region_label"
                                  />
                                  &nbsp;
                                  <PrimaryLabel
                                    labelText={`${
                                      item?.regionTimezone
                                        ? getRegionTime(
                                            item.regionTime,
                                            timelineTimeFormat,
                                            item.regionTimezone
                                          )
                                        : ''
                                    }`}
                                    className="region_label"
                                  />
                                  &nbsp;
                                  <PrimaryLabel
                                    labelText={`- ${item.vendors.length.toString()} vendors`}
                                    className="region_label"
                                  />
                                </div>
                              }
                              content={
                                <div
                                  className="tag_max_width"
                                  id={index.toString()}
                                >
                                  {item.vendors?.length > 0 &&
                                    item.vendors.map(
                                      (data: EventVendorsWithRegions) => {
                                        return (
                                          <TagBox
                                            tagLabel={`${data.vendorName}`}
                                            id={data.vendorId.toString()}
                                            key={data.vendorId}
                                            className="tag_blocks"
                                            onClick={async () => {}}
                                            labelClassName={
                                              data?.vendorId &&
                                              'hover:underline'
                                            }
                                            onLabelClick={async () => {
                                              if (!data?.vendorId) {
                                                return;
                                              }
                                              await dispatch(
                                                getVendorIndividualDetail(
                                                  data?.vendorId
                                                )
                                              );
                                              window.open(
                                                `/data-resources/vendors/${data?.vendorId}`,
                                                '_blank'
                                              );
                                            }}
                                          />
                                        );
                                      }
                                    )}
                                </div>
                              }
                            />
                          </div>
                        )}
                      </>
                    );
                  }
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default VendorInvite;
