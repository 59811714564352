import * as managementTypes from './management.constant';
import { IManagementState, initialManagementState } from './mangement.state';

export const managementReducer = (
  state: IManagementState = initialManagementState,
  action: { type: string; page: number; payload: any; managementCount: number }
): IManagementState => {
  switch (action.type) {
    case managementTypes.GET_MANAGEMENTS:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        managementCount: action.managementCount,
      };
    case managementTypes.SEARCH_MANAGEMENT_DATA:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        managementCount: action.managementCount,
      };
    case managementTypes.GET_MANAGEMENT_DETAIL:
      return Object.assign({}, state, {
        managementData: action.payload,
      });
    case managementTypes.GET_ALL_MANAGEMENTS:
      return {
        ...state,
        allManagements: [...action.payload],
        managementCount: action.managementCount,
      };
    default:
      return state;
  }
};
