import React, { useEffect, useState } from 'react';
import {
  Table,
  PrimaryLabel,
  TagBox,
  Loader,
} from '@alfredclub/hom-planner-theme';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getImageUrl } from '../../../utils';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import Footer from '../../Common/Footer';
import {
  serviceVendors,
  getPackageServicePrice,
  getSimpleServicePrice,
  updateAssociateVendors,
} from '../../../redux/service/services.action';
import VendorSettings from '../../../components/Events/VendorAssignment/VendorSettings';
import { clearServiceVendorForm, IFormState } from '../../../redux/form';
import { ILoaderState } from '../../../redux/loader';
import CommonLoader from '../../Common/CommonLoader';
import { getVendorIndividualDetail } from '../../../redux/vendor';

const ElementServiceVendors = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const query = new URLSearchParams(search);
  const { handleSubmit, register, setValue, errors } = useForm();
  // Decrypt the value of popup fields
  const [serviceType] = useState(query.get('serviceType'));
  const [setRowClick, setRowState] = useState(null);
  const [indexNumber, setIndexNumber] = useState(null);
  const [loader, setLoader] = useState(false);
  const [latestServiceId] = useState(parseInt(query.get('id'), 10));
  const serviceId = parseInt(params['serviceId'], 10);
  const [editServiceId] = useState(parseInt(query.get('id'), 10));
  const [isEdit] = useState(serviceId ? true : false);
  const [showLoader, setLoadingState] = useState<boolean>(false);
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { packageElementForm, serviceVendorForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [passServiceId] = useState(
    serviceId ? serviceId : parseInt(query.get('id'), 10)
  );
  const elementsAssociated = packageElementForm?.['packageElements'].map(
    (data: any) => ({
      id: data.id,
    })
  );
  const elementIds =
    elementsAssociated?.length > 0
      ? elementsAssociated.map(({ id }) => id)
      : [];

  // Code added for Edit case (Tab click)
  useEffect(() => {
    async function getElementVendor() {
      if (serviceId && serviceVendorForm?.vendorRequirements <= 0) {
        if (passServiceId) {
          await dispatch(clearServiceVendorForm());
          setLoader(true);
          await dispatch(serviceVendors(Number(passServiceId)));
          setLoader(false);
        }
      }
    }
    getElementVendor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, dispatch]);

  const onSubmit = async (data) => {
    data?.vendorAssign?.forEach((item, i: number) => {
      serviceVendorForm['vendorRequirements'][i]['requirements'][
        'certificatesRequired'
      ] = item?.docList
        ? item?.docList
        : serviceVendorForm['vendorRequirements'][i]['requirements'][
            'certificatesRequired'
          ] ?? [];
      serviceVendorForm['vendorRequirements'][i]['requirements'][
        'vendorNotes'
      ] = item?.note;
      serviceVendorForm['vendorRequirements'][i]['isPackage'] = true;
      if (!serviceVendorForm['vendorRequirements'][i]['elementId']) {
        serviceVendorForm['vendorRequirements'][i]['elementId'] = editServiceId
          ? editServiceId
          : serviceId;
      }
    });
    await dispatch(
      updateAssociateVendors(
        editServiceId ? editServiceId : serviceId,
        serviceVendorForm
      )
    );
    if (!serviceId) {
      if (elementIds?.length > 0) {
        await dispatch(getPackageServicePrice(elementIds));
      }
      await dispatch(clearServiceVendorForm());
      push(
        `/data-resources/services/add-service?tab=pricing&serviceType=${serviceType}&serviceId=${latestServiceId}`
      );
    } else {
      if (elementIds?.length > 0) {
        await dispatch(getPackageServicePrice(elementIds));
      } else {
        await dispatch(getSimpleServicePrice(Number(latestServiceId)));
      }
      await dispatch(clearServiceVendorForm());
      push(
        `/data-resources/services/edit-service/${serviceId}/?tab=pricing&serviceType=${serviceType}`
      );
    }
  };
  const onTagIconClick = (index: number) => {
    if (isEdit) {
      push(
        `/data-resources/vendors/tag-vendors/${passServiceId}?serviceType=${serviceType}&elementIndex=${index}`
      );
    } else {
      push(
        `/data-resources/vendors/tag-vendors?serviceType=${serviceType}&id=${passServiceId}&elementIndex=${index}`
      );
    }
  };
  const getEventData = (requirements) => {
    const eventData = {
      serviceRelatedData: {
        docList: requirements?.certificatesRequired,
        note: requirements?.vendorNotes,
      },
    };
    return eventData;
  };
  const getCategories = (categories: number[]) => {
    return categories?.map((data: number, i: number) => {
      return (
        <span key={i}>{` ${data}${
          i + 1 !== categories.length ? ',' : ''
        }`}</span>
      );
    });
  };
  const selectCategory = async (e, index: number) => {
    setRowState(e.row.index);
    setIndexNumber(index);
  };

  const handleOnCellClick = async (e: any) => {
    setLoadingState(true);
    setRowState(e.row.original.vendorId);
    await dispatch(getVendorIndividualDetail(e.row.original.vendorId));
    setLoadingState(false);
    setRowState(null);
    window.open(`/data-resources/vendors/${e.row.original.vendorId}`, '_blank');
  };

  return (
    <>
      {loader ? (
        <CommonLoader loading={loader} />
      ) : (
        serviceVendorForm?.vendorRequirements?.length > 0 &&
        serviceVendorForm['vendorRequirements'].map((data: any, i: number) => {
          return (
            <div key={i} className="multi_package grid">
              <div className="text-lg font-medium multi-element-heading">
                <span className="inline-flex items-center ml-6">
                  <PrimaryLabel
                    className="service-title "
                    labelText={data.elementName}
                  />
                </span>
              </div>
              <div className="multi-element-box">
                <div className="vendorsettings mt-6">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <VendorSettings
                      formErrors={errors}
                      register={register}
                      setValue={setValue}
                      fieldIndex={i}
                      eventData={getEventData(data?.requirements)}
                      hideTitle={true}
                    />
                  </form>
                </div>
                <span className="inline-flex items-center mt-5">
                  <PrimaryLabel
                    className="service-title "
                    labelText="Tag Vendors"
                  />
                  <img
                    data-tip="Tag vendor to element"
                    src={getImageUrl('data-resources/tag vendors.svg')}
                    className={`cursor-pointer`}
                    alt="icon"
                    onClick={() => onTagIconClick(i)}
                  />
                  <ReactTooltip />
                </span>
                <Table
                  tableColumns={[
                    {
                      Header: 'Sno',
                      accessor: 'id',
                      Cell: (row: any) => {
                        return <div>{row.row.index + 1}</div>;
                      },
                    },
                    {
                      Header: 'Vendor name',
                      accessor: (row: any) => (
                        <>
                          {row.name ?? row.vendorName}
                          <Loader
                            color="#e89a89"
                            position="center"
                            loading={showLoader && setRowClick === row.vendorId}
                            size="0.75rem"
                            className={`${
                              showLoader &&
                              setRowClick === row.vendorId &&
                              'row_loader'
                            }`}
                          />
                        </>
                      ),
                      className: 'tableAnchor w-54',
                    },
                    {
                      Header: 'Category tag',
                      accessor: 'categoryTags',
                      Cell: (row: any) => {
                        return (
                          <>
                            <div className="flex">
                              {row.row.original?.categoryTags?.length > 0 &&
                                row.row.original?.categoryTags.map(
                                  (category: string, index: number) => {
                                    if (index > 1 || category === null) {
                                      return <></>;
                                    }
                                    return (
                                      <div key={index}>
                                        <TagBox
                                          tagLabel={category}
                                          className="tagbox_position_selected_building pointer-events-none"
                                          hideRemoveTagIcon={true}
                                          imgClass="hidden"
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              {row.row.original?.categoryTags?.length > 2 && (
                                <div
                                  className="mt-3"
                                  data-tip=""
                                  data-for="categories"
                                >
                                  <span>
                                    +
                                    {row.row.original?.categoryTags?.length - 2}
                                  </span>
                                </div>
                              )}
                            </div>
                            {row.row.original?.categoryTags?.length > 0 &&
                              setRowClick === row.row.index &&
                              i === indexNumber && (
                                <ReactTooltip
                                  id="categories"
                                  getContent={() => {
                                    return getCategories(
                                      row.row.original?.categoryTags
                                    );
                                  }}
                                />
                              )}
                          </>
                        );
                      },
                      className: 'table-cell category-tags',
                    },
                    {
                      Header: 'Contact',
                      accessor: 'contact',
                    },
                    {
                      Header: 'Region',
                      accessor: 'region',
                    },
                    {
                      Header: 'Status',
                      Cell: (row: any) =>
                        !row?.row?.original?.vendorStatus
                          ? 'Inactive'
                          : 'Active',
                    },
                  ]}
                  tableData={data?.vendors}
                  showPagination={false}
                  onPageChange={() => {}}
                  className="mt-3"
                  onCellMouseEnter={(e) => selectCategory(e, i)}
                  onCellClick={(e) => handleOnCellClick(e)}
                />
              </div>
              <Footer
                buttonText="Continue"
                isArrow={true}
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
                buttonClass={`${loading ? 'button_disable' : ''}`}
                showLoader={loading}
              />
            </div>
          );
        })
      )}
    </>
  );
};
export default ElementServiceVendors;
