const localhost = {
  API: 'http://localhost:8000',
  URL: 'https://kadbu.com',
  AUTH_API: 'http://localhost:8002',
  VENDOR_URL: 'http://localhost:3000',
  OPS_DASH: 'http://localhost:3001',
  ASC_URL: 'http://localhost:4111',
};

const development = {
  API: 'https://planner-api.kadbu.com',
  URL: 'https://kadbu.com',
  AUTH_API: 'https://dev-auth.hom.events',
  VENDOR_URL: 'https://vendor.kadbu.com',
  OPS_DASH: 'http://localhost:3001/',
  ASC_URL: 'http://localhost:4111/',
};

const production = {
  API: 'https://planner-api.helloalfred.com/',
  URL: 'https://planner.helloalfred.com',
  AUTH_API: 'https://auth-api.helloalfred.com/',
  VENDOR_URL: 'https://vendor.helloalfred.com',
  OPS_DASH: 'ops.helloalfred.com',
  ASC_URL: 'asc.helloalfred.com',
};

const staging = {
  API: 'https://planner-staging-api.hom.events/',
  URL: 'https://planner-staging.hom.events',
  AUTH_API: 'https://staging-auth.hom.events',
  VENDOR_URL: 'https://vendor-staging.hom.events',
  OPS_DASH: 'ops.staging.helloalfred.com',
  ASC_URL: 'asc.staging.helloalfred.com',
};

const qaserver = {
  API: 'https://planner-api.kadbu.com',
  URL: 'https://planner.kadbu.com',
  AUTH_API: 'https://dev-auth.hom.events',
  VENDOR_URL: 'https://vendor.kadbu.com',
  OPS_DASH: 'ops.staging7.helloalfred.com',
  ASC_URL: 'asc.staging7.helloalfred.com',
};

// eslint-disable-next-line no-undef
const Config = (env = process.env.REACT_APP_ENV) => {
  if (env === 'production') {
    return production;
  } else if (env === 'staging') {
    return staging;
  } else if (env === 'qaserver') {
    return qaserver;
  } else if (env === 'localhost') {
    return localhost;
  } else {
    return development;
  }
};
export default Config();
