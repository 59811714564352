import {
  createVendorIndividualApi,
  getFilteredVendorsApi,
  getIndividualVendorDetailApi,
} from './vendor.service';
import {
  createVendorCompanyApi,
  getVendorDetailApi,
  getVendorsApi,
  updateCompanyVendorApi,
  updateIndividualVendorApi,
  deleteVendorApi,
  getAllVendorsApi,
  getVendorsForSuperHeroApi,
} from './vendor.service';
import * as vendorTypes from './vendor.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as formTypes from '../form/form.constant';
import * as toastTypes from '../toast/toast.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import { PaginatedResponse, VendorCompany } from '../../models';
import { Vendor } from '../../models/vendor';
import { DeleteResponse } from '../../models/deleteResponse';

export const getVendors =
  (
    limit?: number,
    page?: number,
    cmp?: string,
    query?: string,
    all?: number,
    vendorType?: number,
    updateReducer = true,
    searchByVendorId?: number
  ) =>
  async (dispatch) => {
    const paginatedResponse: PaginatedResponse<Vendor> = await getVendorsApi(
      cmp,
      limit,
      page,
      query,
      all,
      vendorType,
      searchByVendorId
    );
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    if (paginatedResponse.data && updateReducer) {
      dispatch({
        type: vendorTypes.GET_VENDORS,
        payload: paginatedResponse.data,
        vendorCount: paginatedResponse.total,
        page: page ?? 0,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    } else if (paginatedResponse.data && !updateReducer) {
      return paginatedResponse.data;
    }
  };

export const getAllVendors =
  (
    limit?: number,
    page?: number,
    cmp?: string,
    query?: string,
    allVendors?: number,
    all?: number
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse: PaginatedResponse<Vendor> = await getAllVendorsApi(
      cmp,
      limit,
      page,
      query,
      allVendors,
      all
    );
    if (paginatedResponse.data) {
      dispatch({
        type: vendorTypes.GET_ALL_VENDORS,
        payload: paginatedResponse.data,
        vendorCount: paginatedResponse.total,
        page: page ?? 0,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const searchVendors =
  (data, query, page, vendorType) => async (dispatch) => {
    if (data.length > 0 && query !== '') {
      dispatch({
        type: vendorTypes.SEARCH_VENDORS_DATA,
        payload: data,
        vendorCount: data.length,
        page,
      });
    } else {
      await getVendors(10, page, 'short', query, 0, vendorType)(dispatch);
    }
  };

export const createVendorCompany =
  (data: VendorCompany) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const vendor: Vendor = await createVendorCompanyApi(data);
    if (vendor) {
      dispatch({
        type: vendorTypes.ADD_VENDOR_COMPANY,
        payload: vendor,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      return vendor?.['id'];
    }
  };

export const createIndividualVendor = (data: Vendor) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const vendor: Vendor = await createVendorIndividualApi(data);
  if (vendor) {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return vendor?.['id'];
  }
};

export const getCompanyVendorDetail =
  (vendorId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const vendor: Vendor = await getVendorDetailApi(vendorId);
    if (vendor) {
      dispatch({
        type: formTypes.UPDATE_COMPANY_VENDOR_FORM,
        payload: vendor,
      });
      dispatch({
        type: vendorTypes.FETCHED_VENDOR_DETAIL,
        payload: vendor ? true : false,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getVendorIndividualDetail =
  (vendorId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const vendor: Vendor = await getIndividualVendorDetailApi(vendorId);
    if (vendor) {
      dispatch({
        type: formTypes.UPDATE_COMPANY_INDIVIDUAL_FORM,
        payload: vendor,
      });
      dispatch({
        type: vendorTypes.FETCHED_VENDOR_DETAIL,
        payload: true,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      return vendor;
    }
  };

export const setVendorFetchState = () => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  dispatch({
    type: vendorTypes.FETCHED_VENDOR_DETAIL,
    payload: true,
  });
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const updateCompanyVendor =
  (vendorId: number, data: VendorCompany) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updatedVendor: Vendor = await updateCompanyVendorApi(vendorId, data);
    if (updatedVendor) {
      dispatch({
        type: toastTypes.UDPATE_TOAST,
        toastMessage: TOAST_MESSAGES.vendor.updated,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const updateIndividualVendor =
  (vendorId: number, data: Vendor) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updatedVendor: Vendor = await updateIndividualVendorApi(
      vendorId,
      data
    );
    if (updatedVendor) {
      dispatch({
        type: toastTypes.UDPATE_TOAST,
        toastMessage: TOAST_MESSAGES.vendor.updated,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const deleteVendor = (vendorId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const deleteResponse: DeleteResponse = await deleteVendorApi(vendorId);
  if (deleteResponse) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.vendor.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getVendorsForSuperHero =
  (regionValue: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse = await getVendorsForSuperHeroApi(regionValue);
    if (paginatedResponse.data) {
      dispatch({
        type: vendorTypes.GET_ALL_VENDORS_FOR_SUPER_HERO,
        payload: paginatedResponse.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getFilteredVendors =
  (
    limit?: number,
    page?: number,
    cmp?: string,
    regions?: number[],
    tags?: number[]
  ) =>
  async (dispatch) => {
    const paginatedResponse: PaginatedResponse<Vendor> =
      await getFilteredVendorsApi(cmp, limit, page, regions, tags);
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    if (paginatedResponse.data) {
      dispatch({
        type: vendorTypes.GET_VENDORS,
        payload: paginatedResponse.data,
        vendorCount: paginatedResponse.total,
        page: page ?? 0,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const setFilteredVendors = (data) => async (dispatch) => {
  dispatch({
    type: vendorTypes.SET_FILTERED_VENDORS,
    payload: data,
  });
};

export const setVendorTable = (data: boolean) => async (dispatch) => {
  dispatch({
    type: vendorTypes.SET_VENDOR_TABLE,
    payload: data,
  });
};
