import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { Table, Dropdown, Input } from '@alfredclub/hom-planner-theme';
import { getImageUrl, loadState } from '../../../../utils';
import { TOOLTIP_TEXT } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteEventInventoryItems,
  eventInventoryUpadte,
  IEventsState,
} from '../../../../redux/event';
import EditableField from '../../../Common/EditableField';
import { getAllVendors, IVendorState } from '../../../../redux/vendor';
import InventoryDeleteItemPopup from '../../../Inventory/DeleteItemPopup';
import {
  deleteServiceInventoryItems,
  serviceInventoryUpdate,
  getPackageElementList,
} from '../../../../redux/service';
import { IFormState } from '../../../../redux/form';
import { IServiceState } from '../../../../redux/service';
import '../../Events.scss';

const IMAGE_BASE = 'data-resources/inventory';

const EventsInventoryTable: React.FC<{
  data?: object;
  parentIndex?: number;
  groupId?: number | null;
  serviceInventory?: boolean;
  serviceId?: number;
}> = ({ data, parentIndex, groupId, serviceInventory, serviceId }) => {
  const eventData = loadState('eventSetupForm');
  const dispatch = useDispatch();
  const { serviceData, packageElementList } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { inventoryPackageCheck, packageElements } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const { serviceSetupForm, eventSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [elementName] = useState(
    serviceSetupForm?.name ? serviceSetupForm?.name : serviceData?.name
  );
  const [isPackageCheck] = useState(
    serviceSetupForm?.isPackage
      ? serviceSetupForm?.isPackage
      : serviceData?.isPackage
  );

  const [showPackageElementList, setPackageElementList] = useState(
    packageElementList
  );

  // Bind assign to vendor DDL on Service side Inventory
  useEffect(() => {
    if (serviceInventory && packageElementList?.length) {
      setPackageElementList([]);
      const packageArray = [...packageElementList];
      if (
        serviceSetupForm?.['productionTeams']?.length > 0 &&
        serviceSetupForm?.['productionTeamRequired']
      ) {
        serviceSetupForm?.['productionTeams']?.forEach((item) => {
          packageArray.push({
            id: item.vendorType.id,
            name: item.vendorType.name,
          });
        });
      }
      packageArray.push({ id: null, name: 'Organiser (manually assign)' });
      setPackageElementList([...showPackageElementList, ...packageArray]);
    } else {
      setPackageElementList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageElementList]);

  // Bind assign to vendor DDL on Event side inventory
  useEffect(() => {
    if (!serviceInventory && packageElements?.length) {
      setPackageElementList([]);
      // const packageArray = [...packageElements];
      let packageArray = [];
      if (eventSetupForm?.['serviceElements']?.length > 0) {
        eventSetupForm?.['serviceElements']?.forEach((item) => {
          packageArray.push({
            id: item.id,
            name: item.name,
          });
        });
      } else {
        packageArray = [...packageElements];
      }
      packageArray.push({ id: null, name: 'Organiser (manually assign)' });
      setPackageElementList([...packageArray]);
    } else {
      setPackageElementList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageElements]);

  const { allVendors } = useSelector(
    (state: { vendors: IVendorState }) => state.vendors
  );

  // *********** Single element with production team vendors **********
  const [elementList, setElementList] = useState([
    {
      id: null,
      name: 'Organiser (manually assign)',
    },
    {
      id: serviceInventory ? serviceId : 1,
      name: serviceInventory ? elementName : eventData?.['displayName'],
    },
  ]);
  useEffect(() => {
    if (
      serviceSetupForm?.['productionTeams']?.length > 0 &&
      serviceSetupForm?.['productionTeamRequired']
    ) {
      setElementList([]);
      const elementArray = [];
      elementArray.push({ id: null, name: 'Organiser (manually assign)' });
      elementArray.push({
        id: serviceInventory ? serviceId : 1,
        name: serviceInventory ? elementName : eventData?.['displayName'],
      });
      serviceSetupForm?.['productionTeams']?.forEach((item) => {
        elementArray.push({
          id: item.vendorType.id,
          name: item.vendorType.name,
        });
      });
      setElementList([...elementArray]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceSetupForm?.['productionTeams']]);

  // Event side single element options
  useEffect(() => {
    if (eventSetupForm?.['serviceElements']?.length > 0) {
      setElementList([]);
      const elementArray = [];
      elementArray.push({ id: null, name: 'Organiser (manually assign)' });
      eventSetupForm?.['serviceElements']?.forEach((item) => {
        elementArray.push({
          id: item.id,
          name: item.name,
        });
      });
      setElementList([...elementArray]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSetupForm?.['serviceElements']]);
  // *********** Single element with production team vendors **********

  const [modalState, modalStateToggle] = useState(false);
  const [rowData, setRowData] = useState({ rowId: null, rowIndex: null });

  const updateInventoryItems = async (row, updatedData, rowId, rowIndex) => {
    const newObject = Object.assign({}, row);
    delete newObject.id;
    delete newObject.name;
    delete newObject.groupId;
    const dataToBeUpdate = { ...newObject, ...updatedData };
    await dispatch(
      serviceInventory
        ? serviceInventoryUpdate(
            dataToBeUpdate,
            rowId,
            parentIndex,
            rowIndex,
            groupId,
            serviceId
          )
        : eventInventoryUpadte(
            dataToBeUpdate,
            rowId,
            parentIndex,
            rowIndex,
            groupId
          )
    );
  };
  const deleteItem = async () => {
    modalStateToggle(!modalState);
    await dispatch(
      serviceInventory
        ? deleteServiceInventoryItems(
            rowData.rowId,
            parentIndex,
            groupId,
            serviceId
          )
        : deleteEventInventoryItems(rowData.rowId, parentIndex, groupId)
    );
    setRowData({ rowId: null, rowIndex: null });
  };
  return (
    <>
      {modalState && (
        <InventoryDeleteItemPopup
          modalClose={() => {
            modalStateToggle(!modalState);
          }}
          modalState={modalState}
          headerText={'Are you sure you want to remove this item?'}
          subHeader={''}
          buttonLabel={'Yes, remove item'}
          cancelButtonLabel="No, cancel"
          onDelete={deleteItem}
          isDelete={true}
        />
      )}
      <Table
        tableColumns={[
          {
            Header: 'Item name',
            accessor: 'name',
            className: 'table-cell item-name',
          },
          {
            Header: 'Item Location',
            Cell: ({ row }) => {
              return (
                <EditableField
                  value={row.original.location}
                  placeholder="Add Item location"
                  updateKey="location"
                  data={
                    serviceInventory
                      ? {
                          itemCount: row.original.itemCount,
                          manuallyAssign: row.original.manuallyAssign,
                          element: {
                            id: row?.original?.element?.id,
                            name: row?.original?.element?.name,
                          },
                        }
                      : {
                          itemCount: row.original.itemCount,
                          manuallyAssign: row.original.manuallyAssign,
                          vendor: row.original.vendor,
                        }
                  }
                  updateAction={async (updateData) =>
                    await dispatch(
                      serviceInventory
                        ? serviceInventoryUpdate(
                            updateData,
                            row.original.id,
                            parentIndex,
                            row.index,
                            groupId,
                            serviceId
                          )
                        : eventInventoryUpadte(
                            updateData,
                            row.original.id,
                            parentIndex,
                            row.index,
                            groupId
                          )
                    )
                  }
                />
              );
            },
            className: 'edit_description table-cell item-location',
          },

          {
            Header: 'Assign to vendor of',
            Cell: ({ row }) => {
              return (
                <Dropdown
                  asyncDropdown={false}
                  label="Assign to Vendor"
                  valueKey="id"
                  labelKey="name"
                  isLabelShow={false}
                  placeholder=""
                  options={
                    isPackageCheck || inventoryPackageCheck
                      ? showPackageElementList
                      : elementList
                  }
                  onMenuOpen={async () => {
                    if (
                      serviceInventory &&
                      isPackageCheck &&
                      showPackageElementList?.length <= 0
                    ) {
                      await dispatch(getPackageElementList(serviceId));
                    }
                  }}
                  onOptionChange={async (e) => {
                    await updateInventoryItems(
                      row.original,
                      serviceInventory ||
                        inventoryPackageCheck ||
                        eventSetupForm?.productionTeamExist
                        ? {
                            manuallyAssign: e['id'] === null ? true : false,
                            element: {
                              id: e['id'],
                              name: e['name'],
                            },
                          }
                        : {
                            manuallyAssign: e['id'] === null ? true : false,
                          },
                      row.original.id,
                      row.index
                    );
                  }}
                  value={{
                    name:
                      inventoryPackageCheck ||
                      (serviceInventory && isPackageCheck)
                        ? row.original.manuallyAssign
                          ? 'Organiser (manually assign)'
                          : row.original.element.name
                        : row.original.manuallyAssign
                        ? elementList[0].name
                        : row.original.element.name ?? elementList[1].name,
                    // ? elementList[0].name
                    // : elementList[1].name,
                  }}
                  classNamePrefix="assignToVendor"
                  ddlClass="pl-2"
                />
              );
            },
            className: 'item-name',
          },
          {
            Header: 'Vendor name',
            Cell: ({ row }) => {
              return (
                <>
                  {serviceInventory || !row.original.manuallyAssign ? (
                    <div>
                      <Input
                        label="Task Title"
                        name="vendorName"
                        placeholder="Type here"
                        value={
                          serviceInventory
                            ? row.original.manuallyAssign
                              ? 'Assigned during event creation'
                              : row?.original?.element?.name
                            : row.original?.vendor?.id
                            ? row.original?.vendor?.name
                            : 'Unassigned'
                        }
                        className="pointer-events-none border-none"
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <div>
                      <Dropdown
                        asyncDropdown={false}
                        label="Assign to Vendor"
                        valueKey="vendorId"
                        labelKey="vendorName"
                        isLabelShow={false}
                        placeholder=""
                        options={allVendors}
                        onMenuOpen={async () => {
                          if (allVendors?.length <= 0) {
                            await dispatch(
                              getAllVendors(0, 0, 'short', '', 1, 1)
                            );
                          }
                        }}
                        onOptionChange={async (e) => {
                          const vendor = {
                            id: Number(e['vendorId']),
                            name: e['vendorName'],
                          };
                          await updateInventoryItems(
                            row.original,
                            {
                              vendor,
                            },
                            row.original.id,
                            row.index
                          );
                        }}
                        value={{
                          vendorId: row.original.vendor.id,
                          vendorName: row.original.vendor.name,
                        }}
                        ddlClass="pl-2"
                        classNamePrefix="assignToVendor"
                      />
                    </div>
                  )}
                </>
              );
            },
            className: 'table-cell item-dropdown',
          },
          {
            Header: 'Quantity',
            Cell: ({ row }) => {
              return (
                <EditableField
                  value={row.original.itemCount}
                  placeholder="add"
                  inputType="number"
                  updateKey="itemCount"
                  data={
                    serviceInventory ||
                    (eventSetupForm?.['productionTeamExist'] &&
                      !row.original.manuallyAssign)
                      ? {
                          location: row.original.location,
                          manuallyAssign: row.original.manuallyAssign,
                          element: {
                            id: row?.original?.element?.id,
                            name: row?.original?.element?.name,
                          },
                        }
                      : {
                          location: row.original.location,
                          manuallyAssign: row.original.manuallyAssign,
                          vendor: row.original.vendor,
                        }
                  }
                  updateAction={async (updateData) => {
                    await dispatch(
                      serviceInventory
                        ? serviceInventoryUpdate(
                            updateData,
                            row.original.id,
                            parentIndex,
                            row.index,
                            groupId,
                            serviceId
                          )
                        : eventInventoryUpadte(
                            updateData,
                            row.original.id,
                            parentIndex,
                            row.index,
                            groupId
                          )
                    );
                  }}
                />
              );
            },
            className: 'edit_description table-cell item-quantity',
          },
          {
            Header: ' ',
            Cell: ({ row }) => {
              return (
                <img
                  data-tip={TOOLTIP_TEXT.inventory.item.delete}
                  className="tableAnchor"
                  onClick={async () => {
                    modalStateToggle(true);
                    setRowData({ rowId: row.original.id, rowIndex: row.index });
                  }}
                  src={getImageUrl(`${IMAGE_BASE}/remove.svg`)}
                  alt={row.original.name}
                />
              );
            },
            className: 'delete_item',
          },
        ]}
        showPagination={false}
        tableData={data}
        onCellClick={() => {}}
        className="inventory-table-wrapper"
        onCellMouseEnter={() => {}}
      />
      <ReactTooltip />
    </>
  );
};

export default EventsInventoryTable;
