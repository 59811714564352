export const UPDATE_BUILDING_FORM = 'UPDATE_BUILDING_FORM';
export const EMPTY_BUILDING_FORM = 'EMPTY_BUILDING_FORM';

export const UPDATE_BUILDING_SPACE_FORM = 'UPDATE_BUILDING_SPACE_FORM';
export const UPDATE_BUILDING_WHITELABEL_FORM =
  'UPDATE_BUILDING_WHITELABEL_FORM';
export const DELETE_SPACE_FROM_BUILDING = 'DELETE_SPACE_FROM_BUILDING';

export const UPDATE_MANAGEMENT_FORM = 'UPDATE_MANAGEMENT_FORM';
export const EMPTY_MANAGEMENT_FORM = 'EMPTY_MANAGEMENT_FORM';

export const UPDATE_CLUSTER_FORM = 'UPDATE_CLUSTER_FORM';
export const EMPTY_CLUSTER_FORM = 'UPDATE_CLUSTER_FORM';

export const UPDATE_BUILDING_STAFF = 'UPDATE_BUILDING_STAFF';
export const EMPTY_BUILDING_STAFF = 'EMPTY_BUILDING_STAFF';

export const UPDATE_BUILDING_FORM_STAFF = 'UPDATE_BUILDING_FORM_STAFF';
export const EMPTY_BUILDING_FORM_STAFF = 'EMPTY_BUILDING_FORM_STAFF';

export const UPDATE_SPACE_FORM = 'UPDATE_SPACE_FORM';
export const EMPTY_SPACE_FORM = 'EMPTY_SPACE_FORM';

export const UPDATE_USER_FORM = 'UPDATE_USER_FORM';
export const EMPTY_USER_FORM = 'EMPTY_USER_FORM';

export const UPDATE_COMPANY_VENDOR_FORM = 'UPDATE_COMPANY_VENDOR_FORM';
export const EMPTY_COMPANY_VENDOR_FORM = 'EMPTY_COMPANY_VENDOR_FORM';
export const UPDATE_COMPANY_VENDOR_CONTACTS = 'UPDATE_COMPANY_VENDOR_CONTACTS';
export const UPDATE_COMPANY_INDIVIDUAL_FORM = 'UPDATE_COMPANY_INDIVIDUAL_FORM';
export const UPDATE_COMPANY_INDIVIDUAL_CERTIFICATE_IMAGES =
  'UPDATE_COMPANY_INDIVIDUAL_CERTIFICATE_IMAGES';
export const EMPTY_INDIVIDUAL_FORM = 'EMPTY_INDIVIDUAL_FORM';

export const UPDATE_INDIVIDUAL_FORM = 'UPDATE_INDIVIDUAL_FORM';

export const UPDATE_SERVICE_SETUP_FORM = 'UPDATE_SERVICE_SETUP_FORM';
export const EMPTY_SERVICE_SETUP_FORM = 'EMPTY_SERVICE_SETUP_FORM';
export const UPDATE_COMPANY_INDIVIDUAL_BUILDINGS =
  'UPDATE_COMPANY_INDIVIDUAL_BUILDINGS';
export const UPDATE_INDIVIDUAL_SERVICES = 'UPDATE_INDIVIDUAL_SERVICES';

export const UPDATE_PACKAGE_ELEMENTS_FORM = 'UPDATE_PACKAGE_ELEMENTS_FORM';
export const EMPTY_PACKAGE_ELEMENTS_FORM = 'EMPTY_PACKAGE_ELEMENTS_FORM';

export const UPDATE_SERVICE_VENDOR_FORM = 'UPDATE_SERVICE_VENDOR_FORM';
export const EMPTY_SERVICE_VENDOR_FORM = 'EMPTY_SERVICE_VENDOR_FORM';

export const UPDATE_SERVICE_PRICE_FORM = 'UPDATE_SERVICE_PRICE_FORM';
export const EMPTY_SERVICE_PRICE_FORM = 'EMPTY_SERVICE_PRICE_FORM';

export const UPDATE_TASK_LIST = 'UPDATE_TASK_LIST';
export const UPDATE_TASK_LIST_EDIT = 'UPDATE_TASK_LIST_EDIT';
export const REMOVE_TASK = 'REMOVE_TASK';
export const EMPTY_TASK_LIST = 'EMPTY_TASK_LIST';

export const UPDATE_SERVICE_ELEMENTS_VENDOR_FORM =
  'UPDATE_SERVICE_ELEMENTS_VENDOR_FORM';
export const UPDATE_UNIT_COUNT = 'UPDATE_UNIT_COUNT';
export const UPDATE_COMPANY_UNIT_FORM = 'UPDATE_COMPANY_UNIT_FORM';

export const UPDATE_EVENT_SETUP_FORM = 'UPDATE_EVENT_SETUP_FORM';
export const EMPTY_EVENT_SETUP_FORM = 'EMPTY_EVENT_SETUP_FORM';
export const UPDATE_EVENT_VISIBILITY = 'UPDATE_EVENT_VISIBILITY';

export const UPDATE_RECURRING_SETUP_FORM = 'UPDATE_RECURRING_SETUP_FORM';
export const EMPTY_CLONE_RECURRING_SETUP_FORM =
  'EMPTY_CLONE_RECURRING_SETUP_FORM';
export const EMPTY_RECURRING_SETUP_FORM = 'EMPTY_RECURRING_SETUP_FORM';
export const EVENT_TASK_LIST = 'EVENT_TASK_LIST';
export const UPDATE_EVENT_TASK_LIST = 'UPDATE_EVENT_TASK_LIST';
export const UPDATE_RECURRING_FORM_SCHEDULES =
  'UPDATE_RECURRING_FORM_SCHEDULES';
export const GET_CLUSTER_BUILDINGS_ISRECURRING =
  'GET_CLUSTER_BUILDINGS_ISRECURRING';
export const GET_CLUSTER_BUILDINGS_SINGLE = 'GET_CLUSTER_BUILDINGS_SINGLE';
export const CLEAR_CLUSTER_BUILDINGS_ISRECURRING =
  'CLEAR_CLUSTER_BUILDINGS_ISRECURRING';
export const CLEAR_CLUSTER_BUILDINGS_SINGLE = 'CLEAR_CLUSTER_BUILDINGS_SINIGLE';
export const GET_CLUSTER_BUILDINGS_SINGLE_OPTION_ISRECURRING =
  'GET_CLUSTER_BUILDINGS_SINGLE_OPTION_ISRECURRING';
export const GET_CLUSTER_BUILDINGS_SINGLE_OPTION =
  'GET_CLUSTER_BUILDINGS_SINGLE_OPTION';
export const EMPTY_AUXILIARY_VENDORS_FIELDS = 'EMPTY_AUXILIARY_VENDORS_FIELDS';
