import * as serviceTypes from './service.constant';
import { initialServiceState, IServiceState } from './service.state';

export const serviceReducer = (
  state: IServiceState = initialServiceState,
  action: { type: string; page: number; payload: any; serviceCount: number }
): IServiceState => {
  switch (action.type) {
    case serviceTypes.GET_SERVICES:
    case serviceTypes.SEARCH_SERVICES_DATA:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        serviceCount: action.serviceCount,
      };
    case serviceTypes.GET_CATEGORIES:
      return {
        ...state,
        categories: [...action.payload],
      };
    case serviceTypes.SETUP_SERVICES:
      return {
        ...state,
        serviceData: action.payload,
      };
    case serviceTypes.GET_ALL_SERVICES:
      return {
        ...state,
        allServices: [...action.payload],
      };
    case serviceTypes.GET_SERVICE_DETAIL:
      return {
        ...state,
        serviceData: action.payload,
      };
    case serviceTypes.GET_SERVICE_VENDORS:
      return {
        ...state,
        serviceVendorsData: action.payload,
      };
    case serviceTypes.GET_SERVICE_VENDORS_LIST:
      return {
        ...state,
        serviceVendors: [...action.payload],
      };
    case serviceTypes.SET_SERVICES_PRICE:
      return {
        ...state,
        servicePriceData: action.payload,
      };
    case serviceTypes.GET_MULTIPLE_SERVICE_VENDORS_LIST:
      return {
        ...state,
        elementServiceVendors: action.payload,
      };
    case serviceTypes.GET_SERVICE_DOCUMENTS:
      return {
        ...state,
        serviceDocuments: action.payload,
      };
    case serviceTypes.PACKAGE_ELEMENT_LIST:
      return {
        ...state,
        packageElementList: action.payload,
      };
    case serviceTypes.CLEAR_SERVICE_SINGLE_TASK:
      return {
        ...state,
        serviceTaskDetail: initialServiceState.serviceTaskDetail,
      };
    case serviceTypes.GET_SERVICE_SINGLE_TASK:
      return {
        ...state,
        serviceTaskDetail: action.payload.data,
      };
    case serviceTypes.GET_SINGLE_PRICE_DETAIL:
      return {
        ...state,
        singlePricingDetail: action.payload,
      };
    case serviceTypes.CLEAR_SINGLE_PRICE_DETAIL:
      return {
        ...state,
        singlePricingDetail: initialServiceState.singlePricingDetail,
      };
    case serviceTypes.GET_CATEGORY_TAGS:
      return {
        ...state,
        categoryTagsList: action.payload,
      };
    case serviceTypes.GET_SERVICE_CATEGORY_TAGS:
      return {
        ...state,
        serviceCategoryTagsList: action.payload,
      };
    case serviceTypes.CLEAR_CATEGORY_TAGS:
      return {
        ...state,
        categoryTagsList: initialServiceState.categoryTagsList,
      };
    case serviceTypes.CLEAR_SERVICE_CATEGORY_TAGS:
      return {
        ...state,
        serviceCategoryTagsList: initialServiceState.serviceCategoryTagsList,
      };
    case serviceTypes.GET_PRODUCTION_TEAM:
      return {
        ...state,
        productionTeamList: action.payload,
      };
    case serviceTypes.IMPORT_TASK_TO_SERVICE:
      const importedTask = action?.['payload']?.filter((item) =>
        item.hasOwnProperty('isImportedTask')
      );
      return {
        ...state,
        importedServiceTask: importedTask,
      };
    case serviceTypes.SERVICE_VENDOR_LIST:
      return {
        ...state,
        serviceVendorList: action.payload,
      };
    case serviceTypes.CLEAR_SERVICE_TASK_GROUP_LIST:
      return {
        ...state,
        serviceTaskGroupList: [],
      };
    case serviceTypes.UPDATE_SERVICE_TASK_GROUP_SELECTED:
      return {
        ...state,
        serviceTaskGroupList: action?.['payload'],
      };
    case serviceTypes.CLEAR_SERVICE_IMPORTED_TASK_STATE:
      return {
        ...state,
        importedServiceTask: [],
      };
    case serviceTypes.CLEAR_ALL_SERVICES:
      return {
        ...state,
        allServices: [],
      };
    case serviceTypes.FETCH_SERVICE_DETAIL:
      return {
        ...state,
        fetchedService: action.payload,
      };
    default:
      return state;
  }
};
