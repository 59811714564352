import React, { useEffect, useState } from 'react';
import ModalPopup from '../ReactModal/Modal';
import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InventoryDeleteItemPopup from './DeleteItemPopup';
import {
  getInventoryGroups,
  updateInventoryGroup,
  deleteInventoryGroup,
} from '../../redux/inventory/inventory.action';
import { IGroupItem } from '../../redux/inventory';
interface IFormInput {
  name: string;
  trunk: string;
}
const CreateEditGroupPopup: React.FC<any> = ({
  modalState,
  onClosePopup,
  edit = false,
  groupItem = null,
}: any) => {
  const [groupModalState, modalStateToggle] = useState<boolean>(false);
  const [item, setItem] = useState<IGroupItem>({
    groupId: 0,
    itemId: 0,
    index: 0,
  });
  const { register, errors, handleSubmit, reset } = useForm<IFormInput>();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const onSubmit = async (data) => {
    if (!edit) {
      const groupData = window.btoa(JSON.stringify(data));
      push(
        `/data-resources/inventory?tab=item-list&pageNo=0&groupData=${groupData}`
      );
      onClosePopup();
      return;
    }
    await dispatch(
      updateInventoryGroup(groupItem['id'], data, groupItem['index'])
    );
    await dispatch(getInventoryGroups());
    onClosePopup();
  };

  useEffect(() => {
    if (groupItem) {
      reset(groupItem);
    } else {
      reset({ name: '' });
    }
  }, [groupItem, reset]);

  const deleteItem = async () => {
    await dispatch(deleteInventoryGroup(item['groupId']));
    modalStateToggle(!groupModalState);
    onClosePopup();
  };

  const onDeleteGroup = (groupId) => {
    item.groupId = groupId;
    setItem(() => item);
    modalStateToggle(!groupModalState);
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClosePopup}
      className="group_modal"
    >
      <h1 className="header_group">{edit ? 'Manage Group' : 'Create Group'}</h1>
      {errors.name && (
        <div className="error group_error">Group name is required</div>
      )}
      <PrimaryLabel labelText="Group Name" />
      <PrimaryLabel labelText="*" className="text-red-500" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Hello testing"
          placeholder="Type here"
          name="name"
          ref={register({ required: true })}
        />
        <div className="mark_trunk">
          <input
            type="checkbox"
            className="checkbox"
            name="trunk"
            ref={register}
          />
          <span>Mark as a trunk</span>
        </div>
        {groupItem && (
          <PrimaryLabel
            labelText="Delete group"
            onClick={() => onDeleteGroup(groupItem['id'])}
            className="my-2 cursor-pointer text-Color_19 text-F16 mt-4 inline-flex"
          />
        )}
        <InventoryDeleteItemPopup
          modalClose={() => {
            modalStateToggle(!modalState);
          }}
          modalState={groupModalState}
          headerText={'Are you sure you want to delete this group?'}
          subHeader={'You will not lose any items in the group'}
          buttonLabel={'Yes, delete'}
          cancelButtonLabel="No, cancel"
          onDelete={deleteItem}
          isDelete={true}
        />
        <Button
          label={!edit ? 'Start Creating' : 'Save changes'}
          isArrowShow={!edit}
        />
      </form>
    </ModalPopup>
  );
};

export default CreateEditGroupPopup;
