import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  PrimaryLabel,
  Dropdown,
  Input,
  TextArea,
  Timepicker,
} from '@alfredclub/hom-planner-theme';
import moment from 'moment';
import { EventTask, EventElementList } from '../../../models';
import {
  datepickerformat,
  timeFormat,
  defaultTimezone,
} from '../../../constants';
import { ILoaderState, ILoadingStatusState } from '../../../redux/loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventTask,
  addSingleEventTask,
  getTaskDetail,
  IEventsState,
  checkRequiredField,
  removeTaskTemplateFromEventTask,
  geteventsElementslist,
} from '../../../redux/event';
import { getUTCTime, getImageUrl, linkString } from '../../../utils';
import CommonLoader from '../../Common/CommonLoader';
import CreateTaskTimeFields from './CreateTaskTimeFields';
import {
  addEventTaskSchema,
  editEventTaskSchema,
} from '../../../validations/eventCreation';
import DeletePopup from '../../TaskTemplates/DeletePopup';
import { IFormState } from '../../../redux/form';
import { TASK_TEMPLATE } from '../../../constants';

const AddEventTask: React.FC<{
  closePane: () => void;
  weekDay: number;
  recurring: boolean;
  editTaskCheck: boolean;
  eventId: number;
  taskId?: number;
  eventTimeZone?: string;
  elementName?: string;
  packageElements?: EventElementList[];
  isPackage?: boolean;
  taskTemplate?: boolean;
  serviceId?: number;
}> = ({
  closePane,
  weekDay,
  recurring,
  editTaskCheck,
  eventId,
  taskId,
  eventTimeZone,
  elementName,
  isPackage = false,
  packageElements = [],
  taskTemplate,
  serviceId,
}) => {
  const dispatch = useDispatch();
  const [showLoader, setLoaderSate] = useState(false);

  const [getStatus, setStatus] = useState<ILoadingStatusState>({
    status: 'idle',
  });
  const { eventTaskDetail } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { eventSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );

  const { eventElementsList } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const [deleteGroupState, setDeleteGroup] = useState<boolean>(false);
  const [closeTemplateCheck, seClosetTemplateCheck] = useState<boolean>(false);
  const [loader, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (packageElements?.length) {
      // setPackageElementList([]);
      // const packageArray = [...packageElements];
      let packageArray = [];
      if (eventSetupForm?.['serviceElements']?.length > 0) {
        eventSetupForm?.['serviceElements']?.forEach((item) => {
          packageArray.push({
            id: item.id,
            name: item.name,
          });
        });
      } else {
        packageArray = [...packageElements];
      }
      //  packageArray.push({ id: null, name: 'Organiser (manually assign)' });
      // setPackageElementList([...packageArray]);
    } else {
      // setPackageElementList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [elementList, setElementList] = useState([]);
  useEffect(() => {
    if (eventSetupForm?.['serviceElements']?.length > 0) {
      setElementList([]);
      elementList.push({ id: 1, name: elementName });
      eventSetupForm?.['serviceElements']?.forEach((item) => {
        elementList.push({
          id: item.id,
          name: item.name,
        });
      });
    } else {
      elementList.push({ id: 1, name: elementName });
    }
    setElementList([...elementList]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSetupForm?.['serviceElements']]);

  const currentTimeZone = eventTimeZone ?? defaultTimezone;

  const selectDateMethod = (value: string) => {
    setValue('taskDate', value);
  };
  const selectedDate = eventTaskDetail?.['startDatetime']
    ? moment(eventTaskDetail?.['startDatetime'])
        .tz(currentTimeZone)
        .format(datepickerformat)
    : '';
  const selectedTime = eventTaskDetail?.['startDatetime']
    ? moment(eventTaskDetail?.['startDatetime'])
        .tz(currentTimeZone)
        .format(timeFormat)
    : '';
  const selectTimeMethod = (value: string) => {
    setValue('taskTime', value);
  };

  const onCancelDeleteClick = () => {
    setDeleteGroup(!deleteGroupState);
  };

  const onDeleteGroup = async () => {
    setLoading(true);
    setDeleteGroup(!deleteGroupState);
    await dispatch(removeTaskTemplateFromEventTask(taskId));
    seClosetTemplateCheck(true);
    setLoading(false);
  };

  const { handleSubmit, errors, register, setValue, reset } =
    useForm<EventTask>();
  useEffect(() => {
    const fetchData = async () => {
      setStatus({ status: 'fetching' });
      if (editTaskCheck && getStatus?.status !== 'fetched') {
        await dispatch(
          getTaskDetail(eventId, taskId, recurring && true, eventTimeZone)
        );
        setStatus({ status: 'fetched' });
        return;
      }
      setStatus({ status: 'fetched' });
    };
    async function getTaskDetailMethod() {
      if (editTaskCheck) {
        reset({
          ...eventTaskDetail,
          taskDate: selectedDate,
          taskTime: selectedTime,
        });
      }
    }
    fetchData();
    getTaskDetailMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, eventTaskDetail, reset]);

  const addSingleTask = async (validateTask: EventTask) => {
    if (editTaskCheck) {
      validateTask.id = taskId;
      validateTask.time = getUTCTime(
        validateTask.taskDate,
        validateTask.taskTime,
        eventTimeZone
      );
      delete validateTask.taskDate;
      delete validateTask.taskTime;
      delete validateTask.weekday;
      // in case of edit task id will be passed in param
      await dispatch(
        addSingleEventTask(
          validateTask.id,
          validateTask,
          false,
          true,
          eventTimeZone
        )
      );
    } else {
      if (validateTask.timeOfTask === 0) {
        validateTask.timeValue = '10:00';
      }
      await dispatch(
        addSingleEventTask(
          eventId,
          validateTask,
          recurring ? true : false,
          false,
          eventTimeZone
        )
      );
    }
  };
  // Submitting task details
  const onSubmit = async (data: EventTask) => {
    data.eventLead = false;
    data.weekday = weekDay;
    data.manuallyAssign = false;
    try {
      let validateTask;
      if (editTaskCheck) {
        validateTask = await editEventTaskSchema.validate({
          ...data,
        });
      } else {
        validateTask = await addEventTaskSchema.validate({
          ...data,
        });
      }
      setLoaderSate(true);
      await addSingleTask(validateTask);
      await dispatch(
        getEventTask(eventId, recurring ? true : false, eventTimeZone)
      );
      setLoaderSate(false);
      closePane();
      reset();
    } catch (error) {
      dispatch(checkRequiredField(error?.['message']));
    }
  };

  return getStatus?.status === 'fetched' || loader ? (
    <>
      <form
        className="h-full overflow-y-scroll"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex items-center task-pop-header">
          <h1 className="task-heading">
            {`${!editTaskCheck ? 'Add Task' : 'Edit Task'}`}
            {taskTemplate && !closeTemplateCheck && (
              <PrimaryLabel
                labelText={`Template: ${eventTaskDetail?.['groupName']}`}
                className="float-right"
              />
            )}
          </h1>
        </div>

        <div className="mx-18 grid grid-cols-2 gap-4">
          {taskTemplate && !closeTemplateCheck && (
            <div className="col-span-2 w-full inline-flex justify-between remove-template-status">
              <PrimaryLabel labelText="Templated tasks are not editable. To enable edit, this task would need to be converted to non-template status." />
              <span
                className="remove-template-text cursor-pointer"
                onClick={() => setDeleteGroup(!deleteGroupState)}
              >
                Remove template status and edit
              </span>
            </div>
          )}
          <div className="col-span-2">
            <PrimaryLabel labelText="Assign to Vendor of" />
            <PrimaryLabel labelText="*" className="text-red-500" />
            <Dropdown
              asyncDropdown={false}
              label="Assign to Vendor"
              valueKey="id"
              labelKey="name"
              isMulti={true}
              isLabelShow={false}
              ref={() => register({ name: 'element' }, { required: true })}
              placeholder=""
              options={eventElementsList}
              classNamePrefix="allservice-elements"
              onMenuOpen={async () => {
                // This functionality will be resume after event lead addition
                if (eventElementsList?.length <= 0) {
                  await dispatch(
                    geteventsElementslist(eventId, recurring, serviceId)
                  );
                }
              }}
              isError={errors['element'] ? true : false}
              onOptionChange={(e) => {
                setValue('element', e);
              }}
              resetValue={loading || !editTaskCheck}
              value={editTaskCheck && eventTaskDetail?.['element']}
            />
          </div>

          {!editTaskCheck ? (
            <CreateTaskTimeFields register={register} setValue={setValue} />
          ) : (
            <>
              <div>
                <PrimaryLabel labelText="Date of Task" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  isLabelShow={false}
                  label=""
                  ref={() => register({ name: 'taskDate' })}
                  onDateChange={(date) => selectDateMethod(date)}
                  showDatepicker={true}
                  placeholder={datepickerformat}
                  labelClass="date_label_width"
                  isError={errors['taskDate'] ? true : false}
                  selectedDate={selectedDate && new Date(selectedDate)}
                  className={`${
                    taskTemplate &&
                    !closeTemplateCheck &&
                    'disable-input-fields pointer-events-none text-Color_4'
                  }`}
                />
              </div>
              <div>
                <PrimaryLabel labelText="Time of Task" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <span
                  className={`${
                    taskTemplate && !closeTemplateCheck
                      ? ' pointer-events-none text-Color_4'
                      : 'cursor-pointer'
                  } `}
                >
                  <Timepicker
                    labelName="Start time"
                    onTimeChange={(e) => selectTimeMethod(e)}
                    isError={errors['taskTime'] ? true : false}
                    ref={() => register({ name: 'taskTime' })}
                    showImage={false}
                    selectedTime={selectedTime && selectedTime}
                  />
                </span>
              </div>
            </>
          )}

          <div className="col-span-2">
            {errors.title && (
              <div className="error title_error">Title is required</div>
            )}
            <PrimaryLabel labelText="Task Title" />
            <PrimaryLabel labelText="*" className="text-red-500" />
            <Input
              inputType="text"
              label="Task Title"
              value={editTaskCheck ? eventTaskDetail?.['title'] : ''}
              name="title"
              placeholder="Type here"
              ref={register}
              className={`${
                taskTemplate &&
                !closeTemplateCheck &&
                'disable-input-fields pointer-events-none text-Color_4'
              }`}
            />
          </div>
          <div className="col-span-2">
            <PrimaryLabel labelText="Task Description" />
            <TextArea
              name="description"
              label="Task Description"
              // value={editTaskCheck ? eventTaskDetail?.['description'] : ''}
              ref={register}
              placeholder="Type here.."
              className={`${
                taskTemplate &&
                !closeTemplateCheck &&
                'disable-input-fields pointer-events-none text-Color_4'
              }`}
            />
          </div>
          <div className="col-span-2">
            <div className="flex justify-between dropdown_class">
              <PrimaryLabel labelText="Add Link" />
              <div>
                <a
                  href={linkString(eventTaskDetail?.['documentLink'] ?? '')}
                  target="_blank"
                  className="text-Color_2 text-F15 underline"
                  rel="noreferrer"
                >
                  <img
                    src={getImageUrl(
                      `Events/${
                        eventTaskDetail?.['documentLink']
                          ? 'activeLink'
                          : 'inactiveLink'
                      }.svg`
                    )}
                    alt="icon"
                    className={`${
                      eventTaskDetail?.['documentLink'] ? 'cursor-pointer' : ''
                    }`}
                  />
                </a>
              </div>
            </div>
            <Input
              label="Add Link"
              name="documentLink"
              placeholder="Paste link here"
              ref={register}
              value={editTaskCheck ? eventTaskDetail?.['documentLink'] : ''}
              className={`${
                taskTemplate &&
                !closeTemplateCheck &&
                'disable-input-fields pointer-events-none text-Color_4'
              }`}
            />
          </div>
        </div>
        <CommonLoader loading={showLoader} />
        <Button
          label="Save changes"
          className={`${loading ? 'save_task button_disable' : 'save_task'}`}
          disabled={loading}
        />
      </form>
      {deleteGroupState && (
        <DeletePopup
          modalState={deleteGroupState}
          modalClose={() => onCancelDeleteClick()}
          onDelete={onDeleteGroup}
          headerText={TASK_TEMPLATE.removeTemplateTask}
          subHeader="Are you sure you want to continue?"
          buttonLabel="Yes, continue"
          cancelButtonLabel="No, cancel"
        />
      )}
    </>
  ) : (
    <CommonLoader loading={getStatus?.status !== 'fetched'} />
  );
};
export default AddEventTask;
