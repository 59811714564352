import React, { useState } from 'react';
import { Button, Dropdown, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { getImageUrl } from '../../../utils';
import { loadState } from '../../../utils';

const ManageEvent: React.FC<{ eventId: number }> = ({ eventId }) => {
  const [menuIsOpen, setMenuOpen] = useState<boolean>(false);
  const eventData = loadState('eventSetupForm');
  const options = [
    // Code required later
    // {
    //   id: '1',
    //   label: (
    //     <div className="inline-flex">
    //       <img
    //         src={getImageUrl('Events/gotoevents.svg')}
    //         className="pr-2"
    //         alt="goToEvents"
    //       />
    //       <PrimaryLabel
    //         labelText="View Event Page"
    //         className="text-MobileFont"
    //       />
    //     </div>
    //   ),
    // },
    {
      id: '2',
      label: (
        <div className="inline-flex">
          <img
            src={getImageUrl('Events/chaticon.svg')}
            className="pr-2"
            alt="chatIcon"
          />
          <PrimaryLabel labelText="Begin Chat" className="text-MobileFont" />
        </div>
      ),
    },
  ];
  const sendAdminToChatLink = () => {
    const location = window.open(`/planner-chat?eventId=${eventId}`, '_blank');
    location.focus();
  };
  const onEventAction = (id: number) => {
    switch (id) {
      case 1:
        return 'Manage Event';
      case 2:
        return sendAdminToChatLink();
      default:
        return null;
    }
  };
  return (
    <div>
      <Dropdown
        label=""
        ddlClass="float-left md:float-right relative top-6 md:top-0"
        placeholder=""
        options={options}
        labelKey="label"
        valueKey="id"
        ref={() => ({})}
        onOptionChange={(e) => {
          onEventAction(Number(e?.['id']));
          setMenuOpen(!menuIsOpen);
        }}
        classNamePrefix="event-action"
        sectionClass="eventaction-wrapper float-left md:float-right"
        isCustomDropIcon={true}
        menuIsOpen={menuIsOpen}
      />
      <Button
        className="px-simple-button md:flex pl-6 pr-6 relative md:left-15 md:top-1 z-0 float-left md:float-right bottom-5 manage-event-btn-disabled"
        label="Manage Event"
        onClick={() => setMenuOpen(!menuIsOpen)}
        btnBorderColor="#f36b7f"
        buttonText="#f36b7f"
        type="button"
        disabled={!eventData?.['virtual'] || eventData?.['isPastEvent']}
      />
    </div>
  );
};

export default ManageEvent;
