import React from 'react';
import {
  Button,
  Input,
  PrimaryLabel,
  Loader,
} from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { IUserLogin, IUserLoginResponse } from '../../models';
import { getImageUrl } from '../../utils';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/authentication';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ILoaderState } from '../../redux/loader';
import './Login.scss';
import { LEARN_MORE_ABOUT } from '../../constants';

const LoginForm = () => {
  const { register, errors, handleSubmit } = useForm<IUserLogin>();

  const dispatch = useDispatch();
  const { push } = useHistory();
  const { access } = useSelector(
    (state: { authentication: IUserLoginResponse }) => state.authentication
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const onSubmit = async (data: IUserLogin) => {
    data.timezonePreference = true;
    await dispatch(login(data));
    if (access) {
      push('/');
    }
  };

  return (
    <>
      {access ? (
        <Redirect to="/" />
      ) : (
        <div className="grid grid-cols-3 gap-x-2 gap-y-0 login_wrapper">
          <div className="leftside">
            <img src={getImageUrl('Login/loginback.png')} alt="icon" />
          </div>
          <div className="rightside">
            <form onSubmit={handleSubmit(onSubmit)}>
              <img
                src={getImageUrl('Login/homlogo.svg')}
                alt="logo"
                className="header_logo"
              />
              <PrimaryLabel
                labelText="Log in to your account"
                className="sub-header header_text"
              />
              <div className="pt-4">
                <PrimaryLabel
                  labelText="Email address"
                  className="field_label"
                />
                <Input
                  label=""
                  placeholder=""
                  name="username"
                  inputType="email"
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  isError={errors.username ? true : false}
                />
              </div>
              <div className="pt-4">
                <PrimaryLabel labelText="Password" className="field_label" />
                <Input
                  label=""
                  placeholder=""
                  name="password"
                  inputType="password"
                  ref={register({ required: true })}
                  isError={errors.password ? true : false}
                />
              </div>
              <Loader
                color="#e89a89"
                position="center"
                loading={loading}
                size="1.5rem"
              />
              <div className="pt-12">
                {Object.keys(errors).length > 0 && (
                  <div className="event-error-message">
                    <span>Please fill fields marked above</span>
                  </div>
                )}
                <Button
                  label="LOG IN"
                  className="login_btn"
                  disabled={loading}
                />
              </div>
              <div>
                <a
                  href={LEARN_MORE_ABOUT}
                  target="_blank"
                  className="learn-about block text-Color_1 text-F15 underline pointer text-center"
                  rel="noreferrer"
                >
                  Learn more about Planner App
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginForm;
