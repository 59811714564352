import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import SlideDrawer from '../../Common/SliderPane';
import Backdrop from '../../Common/SliderPane/Backdrop';
import { Button, PrimaryLabel, TitleBar } from '@alfredclub/hom-planner-theme';
import AddEventTask from './AddEventTask';
import {
  getImageUrl,
  getCustomDateTime,
  linkString,
  downloadRecurringDocument,
} from '../../../utils';
import {
  editTaskAlertRecurring,
  TOOLTIP_TEXT,
  weekDaysArray,
} from '../../../constants/index';
import {
  sendTaskList,
  clearEventTaskDetail,
  clearGeteventsElementslist,
} from '../../../redux/event';
import { EventTaskList } from '../../../models';
import ReactTooltip from 'react-tooltip';
import PDFTaskListHeader from '../../Common/PDFTaskListHeader';

const RecurringEventTasklist: React.FC<{
  taskData: any;
  recurring: boolean;
  eventId?: number;
  eventTimeZone?: string;
}> = ({ taskData, recurring, eventId, eventTimeZone }) => {
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [weekDayValue, setweekDayValue] = useState(null);
  const [elementName, setElementName] = useState<string>('');
  const [serviceId, setServiceId] = useState<number>(null);
  const toggleDrawer = async () => {
    setDrawerOpen(!drawerOpen);
    await dispatch(clearEventTaskDetail());
    await dispatch(clearGeteventsElementslist());
  };
  const taskListRef = useRef(null);
  let backdrop: {};
  if (drawerOpen) {
    backdrop = <Backdrop close={toggleDrawer} />;
  }
  const getDay = (dayNumber) => {
    const dayObject = weekDaysArray.filter((item) => item.id === dayNumber);
    return dayObject[0]['fullName'];
  };
  const sendMessage = async (taskEventId: number, weekday: number) => {
    await dispatch(sendTaskList(taskEventId, true, weekday));
  };

  return (
    <>
      <div className="h-tasklist pb-24 overflow-auto">
        <button
          onClick={() =>
            downloadRecurringDocument(
              taskListRef,
              'tasklist',
              'taskListPdfHeading',
              'taskActionButtons',
              'warningRecurringText'
            )
          }
          style={{ position: 'absolute', top: '97px', right: '50px' }}
          data-tip={TOOLTIP_TEXT.events.taskList.downloadPdf}
          className="btn-blue bg-transparent"
        >
          <img
            src={getImageUrl('Events/downloadicon.svg')}
            alt="download pdf"
          />
        </button>
        <ReactTooltip />
        <div ref={taskListRef}>
          <div hidden={true} id="taskListPdfHeading" className="mb-8 ml-8">
            <PDFTaskListHeader hideLabelColor={true} />
          </div>
          {taskData &&
            taskData.length > 0 &&
            taskData.map((taskItem: EventTaskList) => {
              return (
                <>
                  <TitleBar
                    headerTitle={`${getDay(taskItem.weekday)} : ${
                      taskItem.title
                    }`}
                    headerClass="font-extrabold"
                    showSubHeader={taskItem.vendor != null ? true : false}
                    subHeader={`${
                      taskItem.vendor != null ? taskItem.vendor : ''
                    }`}
                    subHeaderClass="font-extrabold"
                  />
                  <div className="task-wrap">
                    <div className="h-16" id="taskActionButtons">
                      <Button
                        className="float-left mr-2 add-task-btn"
                        label="Add Task"
                        type="button"
                        onClick={() => {
                          toggleDrawer();
                          setElementName(taskItem?.title);
                          setServiceId(taskItem?.serviceId);
                          setweekDayValue(taskItem.weekday);
                        }}
                      />
                      <Button
                        className={` float-left ${'button_disable'}`}
                        type="button"
                        label="Send Task List"
                        isLeftIcon={true}
                        leftIcon={getImageUrl('Events/send-task-list.svg')}
                        disabled={true}
                        onClick={() => sendMessage(eventId, taskItem.weekday)}
                      />
                      {taskItem?.['tasks'].length > 0 && (
                        <div id="warningRecurringText">
                          <PrimaryLabel
                            className="text-base float-right"
                            labelColor="#bf2200"
                            labelText={editTaskAlertRecurring}
                          />
                        </div>
                      )}
                    </div>

                    {taskItem?.['tasks'].map((item: any) => {
                      return (
                        <>
                          <div
                            className="task-list-item mb-4"
                            key={item.id}
                            onClick={() => {
                              setElementName(taskItem?.title);
                              setServiceId(taskItem?.serviceId);
                            }}
                          >
                            <div className="flex justify-between">
                              <p className="p_title font-extrabold">
                                {item.title}
                              </p>
                              <p className="p_timeOfEventTask">
                                <img
                                  src={getImageUrl(`data-resources/time.svg`)}
                                  alt="time"
                                  className="pr-2 pb-2 inline-flex"
                                />
                                {getCustomDateTime(
                                  item.time,
                                  eventTimeZone,
                                  true
                                )}
                              </p>
                            </div>
                            <p className="p_vendor flex">
                              <img
                                src={getImageUrl(
                                  `data-resources/element+vendor.svg`
                                )}
                                alt="vendor"
                                className="pr-2 pb-2 inline-flex"
                              />
                              {/* {item?.manuallyAssign && !item?.vendor?.vendorId
                                ? `${taskItem.title}  (Vendor not assigned)`
                                : item?.['vendor']?.['vendorId']
                                ? `${item?.['vendor']?.['vendorName']} | ${item?.['vendor']?.['vendorContact']}, ${item?.['vendor']?.['vendorEmail']}`
                                : taskItem.title} */}
                              {item?.element && item?.element?.length > 0 ? (
                                <p>
                                  {item.element.map((data, i) => {
                                    return `${data.name}${
                                      i < item.element.length - 1 ? ', ' : ''
                                    }`;
                                  })}
                                </p>
                              ) : (
                                taskItem.title
                              )}
                            </p>
                            <p className="p_description">
                              <img
                                src={getImageUrl(
                                  `data-resources/description.svg`
                                )}
                                alt="description"
                                className="pr-2 pb-2 inline-flex"
                              />
                              {item.description}
                            </p>
                            {item['documentLink'] && (
                              <a
                                href={linkString(item['documentLink'])}
                                target="_blank"
                                className="cursor-pointer a_link"
                                rel="noreferrer"
                              >
                                <img
                                  src={getImageUrl(`data-resources/link.svg`)}
                                  alt="description"
                                  className="pr-2 inline-flex"
                                />
                                {item['documentLink']}
                              </a>
                            )}
                            {item['templateId'] && (
                              <div className="p_templateName flex items-center justify-center">
                                <p>Template task</p>
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              );
            })}
        </div>
        <SlideDrawer
          show={drawerOpen}
          slideClass="side-drawer side-drawer-event-mng-width open"
        >
          {drawerOpen && (
            <>
              <AddEventTask
                closePane={toggleDrawer}
                weekDay={weekDayValue}
                recurring={recurring}
                editTaskCheck={false}
                eventId={eventId}
                eventTimeZone={eventTimeZone}
                elementName={elementName}
                serviceId={serviceId}
              />
            </>
          )}
        </SlideDrawer>
        {backdrop}
      </div>
    </>
  );
};
export default RecurringEventTasklist;
