import { Whitelabel } from '../../models';
import * as whitelabelTypes from './whitelabel.constant';
import { IWhitelabelState, initialwhitelabelState } from './whitelabel.state';

export const whiteLabelReducer = (
  state: IWhitelabelState = initialwhitelabelState,
  action: { type: string; payload: Whitelabel }
): IWhitelabelState => {
  switch (action.type) {
    case whitelabelTypes.GET_WHITELABEL_DETAIL:
      return {
        ...state,
        whiteLabel: action.payload,
      };
    case whitelabelTypes.ADD_WHITELABEL:
      return {
        ...state,
        whiteLabel: action.payload,
      };
    default:
      return state;
  }
};
