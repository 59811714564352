import React, { useState } from 'react';
import moment from 'moment';
import {
  eventTimeDateFormat,
  eventStandardFormat,
  EVENT_DATE_FORMAT,
  EVENT_YEAR_FORMAT,
  defaultTimezone,
} from '../../../constants';
import { useSelector } from 'react-redux';
import { IEventsState } from '../../../redux/event';
import { getESTTime, getDateFormatted, loadState } from '../../../utils';
import { PrimaryLabel } from '@alfredclub/hom-planner-theme';

const EventMetaDetails: React.FC<{ hideLabelColor?: boolean }> = ({
  hideLabelColor = false,
}) => {
  const { eventInviteesData, eventBuildingData } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const eventData = loadState('eventSetupForm');
  const [recurringEventData] = useState(loadState('recurringSetupForm'));
  const isRecurring = recurringEventData?.['recurring'] ?? false;
  const eventBuilding = isRecurring
    ? recurringEventData?.['buildings']?.length
    : eventData?.['buildings']?.length;
  const [modalState, toggleModal] = useState(false);
  const toggleModalState = () => {
    toggleModal(!modalState);
  };
  const buildingCount =
    eventBuilding ??
    (eventInviteesData?.['buildings']?.length || eventBuildingData?.length);
  return (
    <>
      {isRecurring ? (
        <PrimaryLabel
          labelText={`${moment(recurringEventData?.['startDate']).format(
            EVENT_DATE_FORMAT
          )} -
                ${moment(recurringEventData?.['endDate']).format(
                  EVENT_DATE_FORMAT
                )} ${moment(recurringEventData?.['endDate']).format(
            EVENT_YEAR_FORMAT
          )}`}
          className={`sub-header block leading-tight ${
            !hideLabelColor ? 'label_color' : ''
          } h-auto`}
        />
      ) : (
        <PrimaryLabel
          labelText={getDateFormatted(
            eventData?.sourceDate,
            eventTimeDateFormat
          )}
          className={`sub-header block leading-tight ${
            !hideLabelColor ? 'label_color' : ''
          }`}
        />
      )}
      <p
        className={`block leading-loose ${
          !hideLabelColor ? 'label_color' : ''
        } ${
          isRecurring || eventData?.recurring
            ? 'pointer-events-none'
            : 'cursor-pointer ontime_hover'
        }`}
        onClick={toggleModalState}
      >
        {!isRecurring
          ? `${getESTTime(
              eventData.startDatetime,
              eventStandardFormat,
              eventData?.timezone?.zoneValue
            )} - ${getESTTime(
              eventData.endDatetime,
              eventStandardFormat,
              eventData?.timezone?.zoneValue
            )}
             ${eventData?.['timezone']?.['shortName'] ?? defaultTimezone}`
          : 'Recurring Schedule'}
      </p>
      <PrimaryLabel
        labelText={`${buildingCount} Buildings`}
        className="block leading-loose lbl_count"
      />
    </>
  );
};

export default EventMetaDetails;
