import React, { useState } from 'react';
import { Loader, PrimaryLabel, Table } from '@alfredclub/hom-planner-theme';
import { usePagination, useSetPageNo } from '../../../hooks';
import { useSelector } from 'react-redux';
import { getEventUsers } from '../../../redux/event/event.action';
import { IEventsState } from '../../../redux/event';
import ManageEvent from './ManageEvent';

const EventRsvps: React.FC<{
  status: string;
  columns: any[];
  eventId: number;
}> = ({ status, columns, eventId }) => {
  const [pageNo, setPage] = useState(0);
  const pagination = usePagination();
  const { eventUsers } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const onPageClick = async (page: number) => {
    await pagination(
      eventUsers?.pages,
      page - 1,
      getEventUsers(eventId, 1, page - 1),
      true
    );
    setPage(page - 1);
  };
  useSetPageNo(pageNo);
  return (
    <>
      {status === 'fetched' ? (
        <div className="grid">
          <ManageEvent eventId={eventId} />
          <div className="mt-2">
            {eventUsers?.notRsvp > 0 && (
              <PrimaryLabel
                labelText={`${eventUsers?.notRsvp} Absentees`}
                className="rsvp_not_attended"
              />
            )}
            <Table
              tableColumns={columns}
              tableData={eventUsers?.['userList']}
              showPagination={!(eventUsers?.['rsvp'] < 10) && true}
              totalRecordsCount={eventUsers?.['rsvp']}
              onPageChange={(pageNumber) => onPageClick(pageNumber)}
              onCellClick={() => {}}
              className="mt-2"
              onCellMouseEnter={() => {}}
            />
          </div>
        </div>
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
    </>
  );
};

export default EventRsvps;
