import { AxiosResponse } from 'axios';
import {
  RECORDS_CMP,
  RECORDS_LIMIT,
  RECORDS_PAGE,
  RECORDS_QUERY,
  ALL_RECORDS,
  ACCOUNTS_BASE_URL,
} from '../../constants/index';
import { AccountsEmailData } from '../../interfaces';
import { PaginatedResponse, User, Staff, Vendor } from '../../models';
import { http } from '../common/http.service';

export const getUsersApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  userId: number = null,
  all: number = ALL_RECORDS
): Promise<PaginatedResponse<User>> =>
  http
    .get(
      `${ACCOUNTS_BASE_URL}/users?cmp=${cmp}&limit=${limit}&page=${page}${
        query ? `&query=${query}` : ``
      }${userId ? `&user_id=${userId}` : ``}&all=${all}`,
      { errorHandler: true }
    )
    .then((response: AxiosResponse<PaginatedResponse<User>>) => {
      return response.data;
    });

export const getStaffUsersApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS,
  userId: number = null
): Promise<PaginatedResponse<Staff>> =>
  http
    .get<PaginatedResponse<Staff>>(
      `${ACCOUNTS_BASE_URL}/staff?cmp=${cmp}&limit=${limit}&page=${page}${
        query ? `&query=${query}` : ``
      }${userId ? `&user_id=${userId}` : ``}&all=${all}`,
      { errorHandler: true }
    )
    .then((response: AxiosResponse<PaginatedResponse<Staff>>) => {
      return response.data;
    });

export const getVendorUsersApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS,
  userId: number = null
): Promise<PaginatedResponse<Vendor>> =>
  http
    .get<PaginatedResponse<Vendor>>(
      `/vendor/accounts?cmp=${cmp}&limit=${limit}&page=${page}${
        query ? `&query=${query}` : ``
      }${userId ? `&user_id=${userId}` : ``}&all=${all}`,
      { errorHandler: true }
    )
    .then((response: AxiosResponse<PaginatedResponse<Vendor>>) => {
      return response.data;
    });

export const updateAccountApi = async (accountId: number, data: object) =>
  http
    .put(`${ACCOUNTS_BASE_URL}/update/${accountId}`, data, {
      errorHandler: false,
    })
    .then((response) => {
      return response;
    });

export const mergeAccountApi = async (accountId: number, data: object) =>
  http
    .put(`${ACCOUNTS_BASE_URL}/update/${accountId}/merge`, data, {
      errorHandler: true,
    })
    .then((response) => {
      return response;
    });

export const sendEmailApi = async (data: AccountsEmailData) =>
  http.post(`${ACCOUNTS_BASE_URL}/send-email`, data, {
    errorHandler: true,
  });
