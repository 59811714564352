import { TOAST_MESSAGES } from '../../constants/index';
import * as accountTypes from './account.constant';
import { apiStatusCheck } from '../../utils';
import * as toastTypes from '../toast/toast.constant';
import * as loadingTypes from '../loader/loader.constant';
import { AccountsEmailData } from '../../interfaces';
import { Staff, User, Vendor, PaginatedResponse } from '../../models';
import {
  getUsersApi,
  getVendorUsersApi,
  getStaffUsersApi,
  updateAccountApi,
  mergeAccountApi,
  sendEmailApi,
} from './accounts.service';
export const getUsers = (
  offset?,
  page?,
  cmp?,
  query?,
  all?,
  userId?,
  updateReducer = true
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const paginatedResponse: PaginatedResponse<User> = await getUsersApi(
    cmp,
    offset,
    page,
    query,
    userId,
    all
  );
  if (paginatedResponse.data && updateReducer) {
    dispatch({
      type: accountTypes.GET_ACCOUNTS_USERS,
      payload: paginatedResponse.data,
      usersCount: paginatedResponse.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return paginatedResponse.data;
  } else if (!updateReducer && paginatedResponse.data) {
    return paginatedResponse.data;
  }
};

export const searchAccountsUsers = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: accountTypes.SEARCH_ACCOUNTS_USERS,
      payload: data,
      usersCount: data.length,
      page,
    });
  } else {
    await getUsers(10, page, 'short', query)(dispatch);
  }
};

export const getVendorUsers = (
  offset?,
  page?,
  cmp?,
  query?,
  all?,
  userId?,
  updateReducer = true
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const paginatedResponse: PaginatedResponse<Vendor> = await getVendorUsersApi(
    cmp,
    offset,
    page,
    query,
    all,
    userId
  );
  if (paginatedResponse.data && updateReducer) {
    dispatch({
      type: accountTypes.GET_ACCOUNTS_VENDORS,
      payload: paginatedResponse.data,
      vendorUsersCount: paginatedResponse.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return paginatedResponse.data;
  } else if (!updateReducer && paginatedResponse.data) {
    return paginatedResponse.data;
  }
};
export const searchVendorUsers = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: accountTypes.SEARCH_ACCOUNTS_VENDORS,
      payload: data,
      vendorUsersCount: data.length,
      page,
    });
  } else {
    await getVendorUsers(10, page, 'short', query)(dispatch);
  }
};
export const getStaffUsers = (
  offset?,
  page?,
  cmp?,
  query?,
  all?,
  userId?,
  updateReducer = true
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const paginatedResponse: PaginatedResponse<Staff> = await getStaffUsersApi(
    cmp,
    offset,
    page,
    query,
    all,
    userId
  );
  if (paginatedResponse.data && updateReducer) {
    dispatch({
      type: accountTypes.GET_ACCOUNTS_STAFF,
      payload: paginatedResponse.data,
      staffUsersCounts: paginatedResponse.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return paginatedResponse.data;
  } else if (!updateReducer && paginatedResponse.data) {
    return paginatedResponse.data;
  }
};

export const searchStaffUsers = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: accountTypes.SEARCH_ACCOUNTS_STAFF,
      payload: data,
      staffUsersCount: data.length,
      page,
    });
  } else {
    await getStaffUsers(10, page, 'short', query)(dispatch);
  }
};

export const updateAccount = (accountId: number, data) => async (dispatch) => {
  const updatedAccount = await updateAccountApi(accountId, data);
  if (apiStatusCheck(updatedAccount)) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.account.updated,
    });
    dispatch({
      type: accountTypes.EMAIL_ERROR,
      payload: false,
    });
  } else {
    dispatch({
      type: accountTypes.EMAIL_ERROR,
      payload: true,
    });
  }
  return updatedAccount;
};

export const mergeAccount = (accountId: number, data) => async (dispatch) => {
  const updatedAccount = await mergeAccountApi(accountId, data);
  if (apiStatusCheck(updatedAccount)) {
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.account.updated,
    });
    dispatch({
      type: accountTypes.RESET_EMAIL_ERROR,
      payload: false,
    });
  }
};

export const resetErrorState = () => async (dispatch) => {
  dispatch({
    type: accountTypes.RESET_EMAIL_ERROR,
    payload: false,
  });
};

export const getUserIds = (id: number) => async (dispatch) => {
  dispatch({
    type: accountTypes.SET_USER_IDS,
    data: id,
  });
};

export const emptyUserIds = () => async (dispatch) => {
  dispatch({
    type: accountTypes.EMPTY_SET_USER_IDS,
  });
};

export const sendEmail = (data: AccountsEmailData) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const emailResponse = await sendEmailApi(data);
  if (apiStatusCheck(emailResponse)) {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.account.emailSent,
    });
  }
};
