import { useDispatch } from 'react-redux';

export const usePagination = () => {
  const dispatch = useDispatch();
  const pagination = async (pages, page, action, called?) => {
    if (pages?.get(page)?.fetched) {
      return pages?.get(page)?.data;
    } else {
      if (called) {
        await dispatch(action);
        return;
      }
      await dispatch(action(10, page, 'short'));
    }
  };
  return pagination;
};
