import React, { useState } from 'react';
import CommonTabComponent from '../../Common/CommonTabComponent';
import EventInventory from './Inventory';
import TaskManagementForm from './TaskManagement';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getImageUrl } from '../../../utils';
import { defaultTimezone } from '../../../constants';
import Footer from '../../Common/Footer';
import CommonLoader from '../../Common/CommonLoader';
import { getEventTask } from '../../../redux/event';
import { useFetch } from '../../../hooks';
import { IFormState } from '../../../redux/form';

const EventTaskInventory = () => {
  const { recurringSetupForm: recurringEventData } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const params = useParams();
  const { push } = useHistory();
  const [showLoader, setLoaderState] = useState(false);
  const eventId = parseInt(params['eventId'], 10);
  const onBackButton = () => {
    if (localStorage.getItem('isRecurring')) {
      push(
        `/events/${eventId}/create?step=vendor-assignment&tab=vendor-invite&edit=true`
      );
    } else {
      push(
        `/events/${eventId}/create?step=vendor-assignment&tab=vendor-invite&edit=true`
      );
    }
  };
  const { status } = useFetch(getEventTask, [
    [Number(eventId)],
    localStorage.getItem('isRecurring'),
    recurringEventData?.timezone?.zoneValue ?? defaultTimezone,
  ]);
  const onNextButton = async () => {
    setLoaderState(true);
    push(`/events/${eventId}/create?step=timeline&edit=true`);
    setLoaderState(false);
  };
  return (
    <>
      <CommonTabComponent className="ml-4">
        <div data-label="Task-List" data-click={true}>
          <TaskManagementForm status={status} />
        </div>
        {!localStorage.getItem('isRecurring') && (
          <div data-label="Inventory" data-click={true}>
            <EventInventory />
          </div>
        )}
      </CommonTabComponent>

      <Footer
        buttonText="Save and Next"
        isArrow={false}
        buttonClass="recurring_continue"
        isRightIcon={true}
        rightIcon={getImageUrl('Events/continuearrow.svg')}
        rightIconClass="pl-4"
        onClick={() => onNextButton()}
        type="button"
        isLeftButton={true}
        leftButtonClass="footer_left_button"
        leftButtonType="button"
        onLeftButtonClick={() => onBackButton()}
        leftButtonText="Back"
        className="recurring_footer"
      />
      {showLoader && <CommonLoader loading={showLoader} />}
    </>
  );
};

export default EventTaskInventory;
