import {
  addManagementApi,
  getManagementsApi,
  getManagementDetailApi,
  updateManagementApi,
  deleteManagementApi,
} from './management.service';
import * as managementTypes from '../management/management.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as formTypes from '../form/form.constant';
import * as toastTypes from '../toast/toast.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import { PaginatedResponse, Management, DeleteResponse } from '../../models';
export const getManagements = (
  limit?: number,
  page?: number,
  cmp?: string,
  query?: string,
  allRecords?: number,
  updateReducer = true
) => async (dispatch) => {
  const managementResponse: PaginatedResponse<Management> = await getManagementsApi(
    cmp,
    limit,
    page,
    query,
    allRecords
  );
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (managementResponse.data && updateReducer) {
    dispatch({
      type:
        allRecords === 1
          ? managementTypes.GET_ALL_MANAGEMENTS
          : managementTypes.GET_MANAGEMENTS,
      payload: managementResponse.data,
      managementCount: managementResponse.total,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  } else if (managementResponse.data && !updateReducer) {
    return managementResponse.data;
  }
};

export const searchManagement = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: managementTypes.SEARCH_MANAGEMENT_DATA,
      payload: data,
      managementCount: data.length,
      page,
    });
  } else {
    await getManagements(10, page, 'short', query)(dispatch);
  }
};

export const addManagement = (data: Management) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const management: Management = await addManagementApi(data);

  if (management) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.management.added,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getManagementDetail = (managementId: number) => async (
  dispatch
) => {
  const management: Management = await getManagementDetailApi(managementId);
  if (management) {
    dispatch({
      type: formTypes.UPDATE_MANAGEMENT_FORM,
      payload: management,
    });
  }
};

export const updateManagement = (
  managementId: number,
  data: Management
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedManagement: Management = await updateManagementApi(
    managementId,
    data
  );

  if (updatedManagement) {
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.management.updated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteManagement = (managementId: number) => async (dispatch) => {
  const deleteResponse: DeleteResponse = await deleteManagementApi(
    managementId
  );
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (deleteResponse.delete) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.management.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};
