import { http } from '../common/http.service';
import { Vendor, VendorsforSuperHero } from '../../models/vendor';
import {
  RECORDS_LIMIT,
  RECORDS_CMP,
  RECORDS_PAGE,
  RECORDS_QUERY,
  ALL_RECORDS,
  VENDOR_BASE_URL,
  EVENTS_BASE_URL,
} from '../../constants/index';
import { DeleteResponse, PaginatedResponse, VendorCompany } from '../../models';
import { AxiosResponse } from 'axios';

export const getVendorsApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS,
  vendorType: number = 1,
  searchByVendorId: number = null
): Promise<PaginatedResponse<Vendor>> =>
  http
    .get<PaginatedResponse<Vendor>>(
      `${VENDOR_BASE_URL}${
        vendorType === 1 ? '/vendor' : '/company'
      }?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}${
        searchByVendorId ? `&search_vendor=${searchByVendorId}` : ``
      }&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Vendor>>) => {
      return response.data;
    });

export const createVendorCompanyApi = async (
  data: VendorCompany
): Promise<Vendor> =>
  http
    .post<Vendor>(`/vendor/add/company`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Vendor>) => {
      return response.data;
    });

export const createVendorIndividualApi = async (
  data: Vendor
): Promise<Vendor> =>
  http
    .post<Vendor>(`/vendor/add/vendor`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Vendor>) => {
      return response.data;
    });

export const getVendorDetailApi = async (vendorId: number): Promise<Vendor> =>
  http
    .get<Vendor>(`/vendor/details/company/${vendorId}`, { errorHandler: true })
    .then((response: AxiosResponse<Vendor>) => {
      return response.data;
    });

export const getIndividualVendorDetailApi = async (
  vendorId: number
): Promise<Vendor> =>
  http
    .get<Vendor>(`/vendor/details/vendor/${vendorId}`, { errorHandler: true })
    .then((response: AxiosResponse<Vendor>) => {
      return response.data;
    });

export const updateCompanyVendorApi = async (
  vendorId: number,
  data: VendorCompany
): Promise<Vendor> =>
  http
    .put<Vendor>(`/vendor/details/company/${vendorId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Vendor>) => {
      return response.data;
    });

export const getAllVendorsApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  allVendors: number = 1,
  all: number = 1
): Promise<PaginatedResponse<Vendor>> =>
  await http
    .get<PaginatedResponse<Vendor>>(
      `${VENDOR_BASE_URL}/vendor?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all_vendors=${allVendors}&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Vendor>>) => {
      return response.data;
    });

export const updateIndividualVendorApi = async (
  vendorId: number,
  data: Vendor
): Promise<Vendor> =>
  http
    .put<Vendor>(`/vendor/details/vendor/${vendorId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Vendor>) => {
      return response.data;
    });

export const deleteVendorApi = async (
  vendorId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`/vendor/delete/${vendorId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });

// export const getVendorWithRegionsAPI = async (
//   eventId: number
// ): Promise<PaginatedResponse> =>
//   http.get<PaginatedResponse>(`${EVENTS_BASE_URL}/vendors/${eventId}`);

export const getVendorsForSuperHeroApi = async (
  regionValue: number
): Promise<PaginatedResponse<Vendor>> =>
  await http
    .get<VendorsforSuperHero>(
      `${EVENTS_BASE_URL}/superhero-sub/vendors-list/${
        regionValue ? `?regionId=${regionValue}` : ''
      }`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<VendorsforSuperHero>) => {
      return response.data;
    });

export const getFilteredVendorsApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  regions: number[] = [],
  tags: number[] = []
): Promise<PaginatedResponse<Vendor>> =>
  http
    .get<PaginatedResponse<Vendor>>(
      `vendor/category-filter/?cmp=${cmp}&limit=${limit}&page=${page}&regions=[${regions}]&tags=[${tags}]`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Vendor>>) => {
      return response.data;
    });
