import { initialUserState, IUserState } from './user.state';
import * as userTypes from './user.constant';

export const userReducer = (
  state: IUserState = initialUserState,
  action: { type: string; page: number; payload: any; userCount: number }
): IUserState => {
  switch (action.type) {
    case userTypes.GET_BUILDING_USERS:
      return {
        ...state,
        users: action.payload,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        usersCount: action.userCount,
        allUsers: [...action.payload],
      };
    case userTypes.SEARCH_USERS_DATA:
      return {
        ...state,
        users: action.payload,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        usersCount: action.userCount,
      };
    case userTypes.ADD_USER:
      return {
        ...state,
        usersData: action.payload,
      };
    case userTypes.GET_USER_DETAIL:
      return {
        ...state,
        usersData: action.payload,
      };
    case userTypes.SET_SELECTED_BUILDING:
      return {
        ...state,
        selectedBuilding: action.payload,
      };
    case userTypes.EMPTY_USERS_LIST:
      return {
        ...state,
        allUsers: [],
      };
    default:
      return state;
  }
};
