import React, { useState } from 'react';
import { Dropdown, Input, ToggleButton } from '@alfredclub/hom-planner-theme';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from '../../../redux/region/regions.action';
import { useHistory } from 'react-router-dom';
import { getServices } from '../../../redux/service/services.action';
import { Register, SetValue, Vendor } from '../../../models';
import { ILoaderState } from '../../../redux/loader/loader.state';
import { IRegionState } from '../../../redux/region/region.state';
import { IServiceState } from '../../../redux/service/service.state';
import { updateVendorServices } from '../../../redux/form';

const VenueServices: React.FC<{
  register: Register;
  setValue: SetValue;
  vendorData: Vendor;
  vendorName: string;
  isEdit: boolean;
  vendorId: number;
  isVendorContact: boolean;
  vendorContact: string;
  decrypedVendorContact?: object;
}> = ({
  register,
  setValue,
  vendorData,
  vendorName,
  isEdit,
  vendorId,
  isVendorContact,
  vendorContact,
  decrypedVendorContact,
}) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { regions } = useSelector(
    (state: { regions: IRegionState }) => state.regions
  );
  const { allServices } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const [checked, setChecked] = useState(
    isEdit ? vendorData?.['venueServices']?.['atVenue'] : false
  );
  const addAssociatedBuilding = (e) => {
    e.preventDefault();
    if (isEdit) {
      push(
        `/data-resources/vendors/add-building?service=venu&vendorName=${vendorName}&edit=true&vendorId=${vendorId}`
      );
      return;
    }
    push(
      `/data-resources/vendors/add-building?service=venu&vendorName=${vendorName}${
        isVendorContact
          ? `&isContact=${isVendorContact}&vendorContact=${vendorContact}`
          : ``
      }`
    );
  };
  return (
    <>
      <FormTitle title="At Venue Services" />
      <div className="grid grid-cols-1 gap-x-2 gap-y-0">
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={true}
            isMulti={true}
            label="Services"
            options={allServices}
            onMenuOpen={async () => {
              if (allServices.length === 0) {
                await dispatch(
                  getServices(0, 0, 'short', '', 1, true, '', true)
                );
              }
            }}
            loading={loading}
            ref={() => register({ name: 'venueServices.services' })}
            onOptionChange={async (e) => {
              setValue('venueServices.services', e);
              await dispatch(
                updateVendorServices(e, 'venueServices', 'services')
              );
            }}
            placeholder="Select"
            value={vendorData && vendorData?.['venueServices']?.['services']}
            className="service_input_width"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          <div>
            <ToggleButton
              label="At Venue Services"
              onChange={(e) => setChecked(e.currentTarget.checked)}
              isLabelShow={checked}
              checked={checked}
              checkedText="Active"
              name="venueServices.availability"
              ref={register}
            />
            <div>
              <Input
                isLabelShow={true}
                label="Associated Buildings"
                isButton={true}
                buttonType="button"
                inputSectionClass="my-2"
                onButtonClick={(e) => addAssociatedBuilding(e)}
                labelClass="associated_building"
                value={`${
                  vendorData &&
                  Object.keys(vendorData?.['venueServices']).length > 0
                    ? vendorData?.['venueServices']?.['buildings']?.length
                    : 0
                } buildings`}
              />
            </div>
          </div>
        </div>
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="regionName"
            valueKey="id"
            isLabelShow={true}
            label="Market"
            options={regions}
            sectionClass="region-field"
            onMenuOpen={async () => {
              if (regions.length === 0) {
                await dispatch(getRegions(0, 0, 'short', 1));
              }
            }}
            loading={loading}
            isMulti={true}
            ref={() => register({ name: 'venueServices.serviceRegions' })}
            onOptionChange={async (e) => {
              setValue('venueServices.serviceRegions', e);
              await dispatch(
                updateVendorServices(e, 'venueServices', 'serviceRegions')
              );
            }}
            placeholder="Select"
            value={
              decrypedVendorContact
                ? decrypedVendorContact?.['vendorData'] && {
                    regionName:
                      decrypedVendorContact?.['vendorData']?.['region']?.[
                        'regionName'
                      ],
                    id: decrypedVendorContact?.['vendorData']?.['region']?.[
                      'id'
                    ],
                  }
                : vendorData &&
                  vendorData?.['venueServices']?.['serviceRegions']
            }
          />
        </div>
      </div>
    </>
  );
};

export default VenueServices;
