import { http } from '../common/http.service';
import { EventInventory, ProductionTeam } from '../../models';
import {
  RECORDS_LIMIT,
  RECORDS_CMP,
  RECORDS_PAGE,
  RECORDS_QUERY,
  ALL_RECORDS,
  SERVICE_BASE_URL,
  SERVICE_TASKS_URL,
} from '../../constants/index';
import {
  DeleteResponse,
  PaginatedResponse,
  Pricing,
  Service,
  Task,
  Vendor,
  Element,
  Category,
  TaskRequest,
  ServiceVendorRequest,
  TaskResponse,
  PackageResponse,
  CategoryTags,
} from '../../models';
import { AxiosResponse } from 'axios';
import { PricingRequest } from '../../models/pricing';
import { AssignVendorToImportedTasks } from '.';

export const getServicesApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS,
  serviceType: string = '',
  isVendor?: boolean
): Promise<PaginatedResponse<Service>> =>
  http
    .get<PaginatedResponse<Service>>(
      `${SERVICE_BASE_URL}/?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all=${all}${
        serviceType ? `&mode=${serviceType}` : ''
      }${isVendor ? '&production_team=1' : ''}
      `,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Service>>) => {
      return response.data;
    });

export const getServiceCategoriesApi = async (): Promise<Category[]> =>
  http
    .get<Category[]>(`${SERVICE_BASE_URL}/service-categories`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Category[]>) => {
      return response.data;
    });

export const setUpServiceApi = async (
  data: Service,
  isPackage: boolean
): Promise<Service> =>
  http
    .post<Service>(
      `${SERVICE_BASE_URL}/add/${!isPackage ? `element` : `package`}`,
      data,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<Service>) => {
      return response.data;
    });

export const getServiceDetailApi = async (
  serviceId: number
): Promise<Service> =>
  http
    .get<Service>(`${SERVICE_BASE_URL}/details/${serviceId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Service>) => {
      return response.data;
    });

export const updateServiceSetupApi = async (
  serviceId: number,
  data: Service
): Promise<Service> =>
  http
    .put<Service>(`${SERVICE_BASE_URL}/details/${serviceId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Service>) => {
      return response.data;
    });

export const deleteSetupApi = async (
  serviceId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`${SERVICE_BASE_URL}/details/${serviceId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });

export const getServiceVendorsApi = async (
  serviceId: number,
  all: number = 0
): Promise<PaginatedResponse<Vendor>> =>
  http
    .get<PaginatedResponse<Vendor>>(
      `${SERVICE_BASE_URL}/service-vendors/${serviceId}/?all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Vendor>>) => {
      return response.data;
    });

export const getServiceElementApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS,
  elementId: number = null
): Promise<PaginatedResponse<Element>> =>
  http
    .get<PaginatedResponse<Element>>(
      `${SERVICE_BASE_URL}/?type=element&cmp=${cmp}&limit=${limit}&page=${page}${
        query && `&query=${query}`
      }${elementId ? `&element_id=${elementId}` : ''}&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Element>>) => {
      return response.data;
    });

export const addVendorsToServiceApi = async (
  serviceId: number,
  data: ServiceVendorRequest
): Promise<Vendor> =>
  http
    .post<Vendor>(`${SERVICE_BASE_URL}/service-vendors/${serviceId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Vendor>) => {
      return response.data;
    });

export const addServicePriceApi = async (
  data: PricingRequest,
  serviceId: number
): Promise<PaginatedResponse<Pricing>> =>
  http
    .post<PaginatedResponse<Pricing>>(
      `${SERVICE_BASE_URL}/service-pricing/${serviceId}/`,
      data,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Pricing>>) => {
      return response.data;
    });

export const getSimpleServicePriceApi = async (
  serviceId: number
): Promise<Pricing> =>
  http
    .get<Pricing>(`${SERVICE_BASE_URL}/service-pricing/${serviceId}/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Pricing>) => {
      return response.data;
    });

export const updateSimpleServicePriceApi = async (
  serviceId: number,
  data: PricingRequest
): Promise<Pricing> =>
  http
    .put<Pricing>(`${SERVICE_BASE_URL}/service-pricing/${serviceId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Pricing>) => {
      return response.data;
    });

export const getPackageServicePriceApi = async (
  serviceIds: number[]
): Promise<Pricing> =>
  http
    .get<Pricing>(`${SERVICE_BASE_URL}/service-pricing/?ids=[${serviceIds}]`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Pricing>) => {
      return response.data;
    });

export const addServiceTaskListApi = async (
  data: TaskRequest,
  serviceId: number
): Promise<Task[]> =>
  http
    .post<Task[]>(`/service/service-tasks/${serviceId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Task[]>) => {
      return response.data;
    });

export const getMultipleServiceVendorsApi = async (
  serviceIds: number[]
): Promise<PaginatedResponse<Vendor>> =>
  http
    .get<PaginatedResponse<Vendor>>(
      `${SERVICE_BASE_URL}/service-vendors/?ids=[${serviceIds}]`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Vendor>>) => {
      return response.data;
    });

export const getServiceTaskListApi = async (
  serviceId: number
): Promise<TaskResponse> =>
  http
    .get<TaskResponse>(`/service/service-tasks/${serviceId}/?taskList=1`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<TaskResponse>) => {
      return response.data;
    });

export const deleteServiceTaskApi = async (
  taskId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(
      `${SERVICE_BASE_URL}/${SERVICE_TASKS_URL}/${taskId}/`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });

export const deleteServicePriceApi = async (
  priceId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`/service/service-pricing/${priceId}/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });

export const getServiceDocumentApi = async () =>
  http.get(`${SERVICE_BASE_URL}/service-documents/all`, {
    errorHandler: true,
  });

export const getPackageElementListApi = async (
  serviceId: number
): Promise<PackageResponse> =>
  http
    .get<PackageResponse>(`/service/service-tasks/${serviceId}/?package=1`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<PackageResponse>) => {
      return response.data;
    });

export const editServiceTaskApi = async (
  taskId: number,
  data: Task
): Promise<Task> =>
  http
    .put<Task>(`${SERVICE_BASE_URL}/${SERVICE_TASKS_URL}/${taskId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Task>) => {
      return response.data;
    });

export const getSingleServiceTaskApi = async (taskId: number): Promise<Task> =>
  http
    .get<Task>(
      `${SERVICE_BASE_URL}/${SERVICE_TASKS_URL}/${taskId}/?getTask=1`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<Task>) => {
      return response.data;
    });

export const getServiceInventoryApi = (serviceId: number): Promise<any> =>
  http
    .get(`${SERVICE_BASE_URL}/service-inventory/${serviceId}/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<any>) => response.data);

export const addInventoryToServiceApi = async (
  data: object,
  serviceId: number
): Promise<EventInventory> =>
  http
    .post<EventInventory>(
      `${SERVICE_BASE_URL}/service-inventory/${serviceId}/`,
      data,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<EventInventory>) => {
      return response.data;
    });

export const deleteInventoryItemApi = async (
  itemId: number,
  serviceId: number
) =>
  await http.delete(
    `${SERVICE_BASE_URL}/service-inventory/${serviceId}/item/${itemId}/`,
    {
      errorHandler: true,
    }
  );

export const serviceInventoryUpdateApi = async (
  data,
  itemId: number,
  serviceId: number
) =>
  await http.put(
    `${SERVICE_BASE_URL}/service-inventory/${serviceId}/item/${itemId}/`,
    data,
    {
      errorHandler: true,
    }
  );

export const getSinglePriceDetailApi = async (
  pricingId: number
): Promise<Pricing> =>
  http
    .get<Pricing>(`${SERVICE_BASE_URL}/service-pricing/details/${pricingId}/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Pricing>) => {
      return response.data;
    });

export const editSingleServicePricingApi = async (
  serviceId: number,
  data: PricingRequest
): Promise<Pricing> =>
  http
    .put<Pricing>(`${SERVICE_BASE_URL}/service-pricing/${serviceId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Pricing>) => {
      return response.data;
    });

export const updateVendorsToServiceApi = async (
  serviceId: number,
  data: ServiceVendorRequest
): Promise<Vendor> =>
  http
    .put<Vendor>(`${SERVICE_BASE_URL}/service-vendors/${serviceId}/`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Vendor>) => {
      return response.data;
    });

export const getCategoryTagsApi = async (): Promise<
  PaginatedResponse<CategoryTags>
> =>
  await http
    .get<PaginatedResponse<CategoryTags>>(
      `${SERVICE_BASE_URL}/category-tags/`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<CategoryTags>>) => {
      return response.data;
    });

export const getServiceCategoryTagsApi = async (
  elementsIds: number[]
): Promise<PaginatedResponse<CategoryTags>> =>
  await http
    .get<PaginatedResponse<CategoryTags>>(
      `${SERVICE_BASE_URL}/category-tags/?byService=${elementsIds}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<CategoryTags>>) => {
      return response.data;
    });

export const getProductionTeamApi = async (
  elementsIds?: number[]
): Promise<PaginatedResponse<ProductionTeam>> =>
  await http
    .get<PaginatedResponse<ProductionTeam>>(
      `${SERVICE_BASE_URL}/production-teams/${
        elementsIds ? `?byService=${elementsIds}` : ''
      }`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<ProductionTeam>>) => {
      return response.data;
    });

export const assignVendorToImportedTasksApi = async (
  data: object,
  serviceId: number
): Promise<AssignVendorToImportedTasks> =>
  http
    .post<AssignVendorToImportedTasks>(
      `${SERVICE_BASE_URL}/service-tasks/import/${serviceId}/`,
      data,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<AssignVendorToImportedTasks>) => {
      return response.data;
    });

export const removeTaskTemplateApi = async (taskId: number): Promise<Task> =>
  http
    .put<Task>(
      `${SERVICE_BASE_URL}/${SERVICE_TASKS_URL}/${taskId}/?remove=1`,
      {},
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<Task>) => {
      return response.data;
    });
