import React, { useState, useEffect } from 'react';
import {
  Button,
  PrimaryLabel,
  Dropdown,
  Input,
  Timepicker,
  Loader,
} from '@alfredclub/hom-planner-theme';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import ModalPopup from '../ReactModal/Modal';
import {
  getImageUrl,
  loadState,
  getUTCTime,
  getESTTime,
  getUSEasternTimeZone,
  getDateFormatted,
} from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import EventCreationTabs from '../Common/EventCreationTabs';
import { useHistory } from 'react-router-dom';
import {
  datepickerformat,
  timeFormat,
  MIN_DATE_FORMAT,
  EVENT_FORMAT,
} from '../../constants';
import {
  clearAssignedVendor,
  eventCreation,
  updateEventSetup,
  updateRecurringSetup,
  getRecurringEventDetail,
  getEventDetail,
} from '../../redux/event/event.action';
import { getServiceCategories, IServiceState } from '../../redux/service';
import { Event, EventModalProps } from '../../models';
import './Events.scss';
import { getTimezone, IRegionState } from '../../redux/region';
import {
  clearCloneRecurringSetupForm,
  clearEventSetupForm,
  IFormState,
} from '../../redux/form';

const CreateEventPopup: React.FC<EventModalProps> = ({
  modalState,
  toggleModal,
  editEventId,
  clonedEvent,
  toggleClonedEvent,
  setEventId,
  toggleEvent,
  newEvent,
}) => {
  const { eventSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const { register, handleSubmit, setValue, errors, reset } = useForm<Event>();
  const { categories } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { timezones } = useSelector(
    (state: { regions: IRegionState }) => state.regions
  );
  const [zoneLoading, setZoneLoading] = useState<boolean>(false);
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showLoader, setLoadingState] = useState(false);
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [getStartDate, setStartDate] = useState('');
  const [eventDetailsData] = useState(
    editEventId ? loadState('eventSetupForm') : eventSetupForm
  );

  const [recurring, setRecurring] = useState(
    eventDetailsData?.isRecurring ? true : false
  );
  const recurringEventData = eventDetailsData?.isRecurring
    ? loadState('recurringSetupForm')
    : null;
  const [virtual, setVirtual] = useState(
    eventDetailsData
      ? !recurring
        ? eventDetailsData?.virtual
          ? true
          : false
        : recurringEventData?.['virtual']
        ? true
        : false
      : true
  );
  const [isPackage, setPackage] = useState(
    eventDetailsData ? (eventDetailsData?.isPackage ? true : false) : false
  );
  const [editEventCheck] = useState<boolean>(editEventId ? true : false);

  const editSelectedDate =
    eventDetailsData?.startDatetime &&
    getESTTime(
      eventDetailsData.startDatetime,
      datepickerformat,
      eventDetailsData?.timezone?.zoneValue
    );
  useEffect(() => {
    async function getEventDetailMethod() {
      if (editEventId) {
        if (!recurring) {
          reset({
            ...eventDetailsData,
            selectedDate: editSelectedDate || '',
          });
        } else {
          reset({
            ...recurringEventData,
            startDate: recurringEventData?.['startDate'] || '',
            endDate: recurringEventData?.['endDate'] || '',
          });
        }
      }
    }
    getEventDetailMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetailsData, reset]);
  const onSubmit = async (props) => {
    setLoadingState(true);
    const {
      selectedDate,
      serviceCategory,
      startDate,
      endDate,
      startDatetime,
      endDatetime,
      timezone,
    } = props;
    let eventObj: Event = {
      timezone: {
        id: timezone.id,
        shortName: timezone?.shortName,
        zoneValue: timezone?.zoneValue,
        fullName: timezone?.fullName,
      },
    };
    if (editEventId && !recurring) {
      eventObj = { ...eventDetailsData, timezone: { ...eventObj.timezone } };

      const startTime = startDateTime
        ? startDateTime
        : getUSEasternTimeZone(
            eventDetailsData.startDatetime,
            timeFormat,
            eventDetailsData?.timezone?.zoneValue
          );
      const endTime = endDateTime
        ? endDateTime
        : getUSEasternTimeZone(
            eventDetailsData.endDatetime,
            timeFormat,
            eventDetailsData?.timezone?.zoneValue
          );
      eventObj.startDatetime = getUTCTime(
        selectedDate,
        startTime,
        timezone?.zoneValue
      );
      eventObj.endDatetime = getUTCTime(
        selectedDate,
        endTime,
        timezone?.zoneValue
      );
      eventObj['tenantUrl'] = '';
      eventObj['preRecorded'] = false;
      eventObj['sourceDate'] = getDateFormatted(selectedDate, EVENT_FORMAT);
    } else if (recurring) {
      eventObj['startDate'] = getDateFormatted(startDate, EVENT_FORMAT);
      eventObj['endDate'] = getDateFormatted(endDate, EVENT_FORMAT);
      eventObj['serviceCategory'] = serviceCategory;
      eventObj['virtual'] = virtual;
    } else {
      eventObj['startDatetime'] = getUTCTime(
        selectedDate,
        startDatetime,
        timezone?.zoneValue
      );
      eventObj['endDatetime'] = getUTCTime(
        selectedDate,
        endDatetime,
        timezone?.zoneValue
      );
      eventObj['displayName'] = '';
      eventObj['description'] = '';
      eventObj['service'] = { id: null };
      eventObj['visibility'] = 1;
      eventObj['cancelled'] = false;
      eventObj['bookedInfo'] = 0;
      eventObj['buildings'] = [];
      eventObj['maxCapacity'] = 0;
      eventObj['venue'] = { id: null };
      eventObj['recurring'] = recurring;
      eventObj['serviceCategory'] = serviceCategory;
      eventObj['virtual'] = virtual;
      eventObj['tenantUrl'] = '';
      eventObj['marketingLinks'] = '';
      eventObj['isPackage'] = Number(isPackage);
      eventObj['sourceDate'] = getDateFormatted(selectedDate, EVENT_FORMAT);
      eventObj['eventImage'] = { id: null, file: null };
      eventObj['isPaid'] = false;
      eventObj['price'] = 0
    }
    if (clonedEvent) {
      eventObj['visibility'] = !recurring
        ? eventDetailsData?.['visibility']
        : recurringEventData?.['visibility'];
      eventObj['buildings'] = [];
      eventObj['published'] = false;
      if (recurring) {
        await dispatch(clearCloneRecurringSetupForm());
      } else {
        eventObj['isCloned'] = true;
        // await dispatch(clearEventSetupForm());
      }
    }
    if (recurring) {
      localStorage.setItem('isRecurring', '1');
    }
    const eventId = editEventId
      ? recurring
        ? await dispatch(updateRecurringSetup(editEventId, eventObj))
        : await dispatch(updateEventSetup(editEventId, eventObj, true))
      : await dispatch(eventCreation(eventObj, recurring));
    await dispatch(clearAssignedVendor());
    setLoadingState(false);
    if (!clonedEvent) {
      push(
        `/events/${eventId || editEventId}/create?step=${
          !recurring ? 'setup' : 'recurring'
        }&virtual=${virtual}`
      );
      if (toggleEvent) {
        toggleEvent();
      }
    }
    toggleStates();
  };
  const toggleStates = async () => {
    if (clonedEvent) {
      push(
        `/events/${editEventId}/create?step=${
          !recurring ? 'setup' : 'recurring'
        }&virtual=${virtual}&edit=true`
      );
      setEventId(editEventId.toString());
      toggleClonedEvent();
    }
    toggleModal();
  };
  const setEventTypeMethod = (value: boolean) => {
    if (!editEventId) {
      setRecurring(value);
      reset();
    }
  };
  const setEventModeMethod = (value: boolean) => {
    if (!editEventId) {
      setVirtual(value);
    }
  };

  const setPackageMethod = (value: boolean) => {
    if (!editEventId) {
      setPackage(value);
    }
  };

  const selectTimeMethod = (value: string, check: number) => {
    if (check === 1) {
      setValue('startDatetime', value);
      setStartDateTime(value);
    } else {
      setValue('endDatetime', value);
      setEndDateTime(value);
    }
  };
  const minSelectedDate =
    getStartDate && moment(getStartDate).format(MIN_DATE_FORMAT);

  const selectDateMethod = (value: string, check: number) => {
    if (check === 1) {
      setValue('startDate', value);
      setStartDate(value);
    } else {
      setValue('endDate', value);
    }
  };
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={async () => {
        if (clonedEvent) {
          if (recurring) {
            await dispatch(clearCloneRecurringSetupForm());
            await dispatch(getRecurringEventDetail(editEventId));
          } else {
            await dispatch(clearEventSetupForm());
            await dispatch(getEventDetail(editEventId));
          }
          push(
            `/events/${editEventId}/create?step=${
              !recurring ? 'setup' : 'recurring'
            }&virtual=${virtual}&edit=true`
          );
        }
        toggleStates();
      }}
      className="add_event_modal"
      shouldCloseOnOverlayClick={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {showLoader ? (
          <Loader
            color="#e89a89"
            position="center"
            loading={true}
            size="0.75rem"
          />
        ) : (
          <div className="main_section grid grid-cols-1 gap-x-2 gap-y-0">
            <label className="header_label">
              {editEventId
                ? clonedEvent
                  ? 'Edit clone event'
                  : 'Edit event'
                : 'Create a new event'}
            </label>
            <PrimaryLabel
              labelText="Select event parameters"
              className="subheader_label"
            />
            <>
              <div
                className={`grid grid-cols-2 gap-x-2 gap-y-0 mb-6 ${
                  editEventId && 'disable-eventType'
                }`}
              >
                <div className="flex justify-end">
                  <EventCreationTabs
                    tabType={false}
                    eventType={recurring}
                    onTabClick={() => setEventTypeMethod(false)}
                    imgSrc={getImageUrl(
                      !recurring
                        ? 'Events/singleselected.svg'
                        : 'Events/singleunselected.svg'
                    )}
                    tabName="Single event"
                    editEventCheck={editEventCheck}
                  />
                </div>
                <div className="flex justify-start">
                  <EventCreationTabs
                    tabType={true}
                    eventType={recurring}
                    onTabClick={() => setEventTypeMethod(true)}
                    imgSrc={getImageUrl(
                      recurring === true
                        ? 'Events/recurring-select.svg'
                        : 'Events/recurring-unselect.svg'
                    )}
                    imgClass="img-recurring"
                    tabName="Recurring"
                    editEventCheck={editEventCheck}
                  />
                </div>
              </div>
            </>
            <>
              <div
                className={`grid grid-cols-2 gap-x-2 gap-y-0 mb-6 ${
                  editEventId && 'disable-eventType'
                }`}
              >
                <div className="flex justify-end">
                  <EventCreationTabs
                    tabType={true}
                    eventType={virtual}
                    onTabClick={() => setEventModeMethod(true)}
                    imgSrc={
                      virtual
                        ? getImageUrl('Events/virtual-unselect.svg')
                        : getImageUrl('Events/virtual-select.svg')
                    }
                    imgClass="img-recurring"
                    tabName="Virtual"
                    editEventCheck={editEventCheck}
                  />
                </div>
                <div className="flex justify-start">
                  <EventCreationTabs
                    tabType={false}
                    eventType={virtual}
                    onTabClick={() => setEventModeMethod(false)}
                    imgSrc={
                      virtual
                        ? getImageUrl('Events/inperson-unselect.svg')
                        : getImageUrl('Events/inperson-select.svg')
                    }
                    imgClass="img-recurring"
                    tabName="In-person"
                    editEventCheck={editEventCheck}
                  />
                </div>
              </div>
            </>
            <div
              className={`flex mb-6 justify-center ${!recurring && 'hidden'}`}
            >
              <div>
                <PrimaryLabel labelText="Start Date" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  className="date-picker-width"
                  isLabelShow={false}
                  label=""
                  ref={() =>
                    register({ name: 'startDate' }, { required: recurring })
                  }
                  onDateChange={(date) => selectDateMethod(date, 1)}
                  showDatepicker={true}
                  selectedDate={
                    recurringEventData &&
                    new Date(recurringEventData?.['startDate'])
                  }
                  placeholder={datepickerformat}
                  labelClass="date_label_width"
                  isError={errors['startDate'] ? true : false}
                  minDate={new Date()}
                />
              </div>
              <div className="justify-center pt-12 px-2">
                <img src={getImageUrl('Events/Arrow.svg')} alt="icon" />
              </div>
              <div>
                <PrimaryLabel labelText="End Date" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  className="date-picker-width"
                  isLabelShow={false}
                  label=""
                  ref={() =>
                    register({ name: 'endDate' }, { required: recurring })
                  }
                  onDateChange={(date) => selectDateMethod(date, 2)}
                  showDatepicker={true}
                  selectedDate={
                    recurringEventData &&
                    new Date(recurringEventData?.['endDate'])
                  }
                  placeholder={datepickerformat}
                  labelClass="date_label_width"
                  isError={errors['endDate'] ? true : false}
                  minDate={
                    minSelectedDate ? new Date(minSelectedDate) : new Date()
                  }
                />
              </div>
            </div>

            <div className={`${recurring && 'hidden'} grid w-max my-0 mx-auto`}>
              <div className="mb-6">
                <PrimaryLabel labelText="Selected Date" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  isLabelShow={false}
                  label=""
                  ref={() =>
                    register(
                      { name: 'selectedDate' },
                      { required: !recurring ? true : false }
                    )
                  }
                  onDateChange={(date) => setValue('selectedDate', date)}
                  showDatepicker={true}
                  minDate={new Date()}
                  placeholder={datepickerformat}
                  labelClass="date_label_width"
                  isError={errors['selectedDate'] ? true : false}
                  selectedDate={editSelectedDate && new Date(editSelectedDate)}
                  disabled={
                    eventDetailsData?.['isPastEvent']
                      ? clonedEvent
                        ? false
                        : true
                      : false
                  }
                />
              </div>
              <div className="mb-3 flex">
                <div>
                  <Timepicker
                    className="cursor-pointer"
                    labelName="Start time"
                    onTimeChange={(e) => selectTimeMethod(e, 1)}
                    minTime={''}
                    ref={() =>
                      register(
                        { name: 'startDatetime' },
                        { required: !recurring ? true : false }
                      )
                    }
                    isError={errors['startDatetime'] ? true : false}
                    selectedTime={
                      eventDetailsData?.startDatetime
                        ? getUSEasternTimeZone(
                            eventDetailsData?.startDatetime,
                            timeFormat,
                            eventDetailsData?.timezone?.zoneValue
                          )
                        : ''
                    }
                  />
                </div>
                <div className="px-2 justify-center items-center flex">
                  <img src={getImageUrl('Events/Arrow.svg')} alt="icon" />
                </div>
                <div className="float-right">
                  <Timepicker
                    className="cursor-pointer"
                    labelName="End time"
                    onTimeChange={(e) => selectTimeMethod(e, 2)}
                    minTime={startDateTime}
                    ref={() =>
                      register(
                        { name: 'endDatetime' },
                        { required: !recurring ? true : false }
                      )
                    }
                    isError={errors['endDatetime'] ? true : false}
                    selectedTime={
                      eventDetailsData?.endDatetime
                        ? getUSEasternTimeZone(
                            eventDetailsData?.endDatetime,
                            timeFormat,
                            eventDetailsData?.timezone?.zoneValue
                          )
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
            <div className="grid justify-center">
              <div className="mb-2 input-w">
                <PrimaryLabel labelText="Event Timezone" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Dropdown
                  asyncDropdown={false}
                  labelKey="fullName"
                  valueKey="id"
                  isLabelShow={false}
                  label="Timezone"
                  options={timezones}
                  ref={() => register({ name: 'timezone' }, { required: true })}
                  onMenuOpen={async () => {
                    setZoneLoading(true);
                    if (timezones.length === 0) {
                      await dispatch(getTimezone());
                    }
                    setZoneLoading(false);
                  }}
                  loading={zoneLoading}
                  onOptionChange={(e) => {
                    setValue('timezone', e);
                  }}
                  value={
                    !newEvent &&
                    (!recurring
                      ? {
                          fullName:
                            eventDetailsData?.['timezone']?.['fullName'],
                          id: eventDetailsData?.['timezone']?.['id'],
                        }
                      : {
                          fullName:
                            recurringEventData?.['timezone']?.['fullName'],
                          id: recurringEventData?.['timezone']?.['id'],
                        })
                  }
                  placeholder="Select"
                  ddlClass="mb-0"
                  isError={errors['timezone'] ? true : false}
                  disabled={editEventId ? (clonedEvent ? false : true) : false}
                />
              </div>
              <div>
                <PrimaryLabel labelText="Event Category" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Dropdown
                  asyncDropdown={false}
                  labelKey="name"
                  valueKey="id"
                  isLabelShow={false}
                  label="Category"
                  options={categories}
                  onMenuOpen={async () => {
                    setLoading(true);
                    if (categories.length === 0) {
                      await dispatch(getServiceCategories());
                    }
                    setLoading(false);
                  }}
                  loading={loading}
                  ref={() =>
                    register({ name: 'serviceCategory' }, { required: true })
                  }
                  onOptionChange={(e) => {
                    setValue('serviceCategory', e);
                  }}
                  sectionClass={
                    Object.keys(errors).length > 0
                      ? ' event-category-space'
                      : 'event-category-without-error'
                  }
                  value={
                    !newEvent &&
                    (!recurring
                      ? {
                          name: eventDetailsData?.serviceCategory?.name,
                          id: eventDetailsData?.serviceCategory?.id,
                        }
                      : {
                          name: recurringEventData?.serviceCategory?.name,
                          id: recurringEventData?.serviceCategory?.id,
                        })
                  }
                  placeholder="Select categories"
                  ddlClass="mb-0"
                  isError={errors['serviceCategory'] ? true : false}
                  disabled={editEventId ? true : false}
                />
              </div>
              <>
                <div
                  className={`grid grid-cols-2 gap-x-2 gap-y-0 mb-6 ${
                    editEventId && 'disable-eventType'
                  } ${!recurring && 'hidden'}`}
                >
                  <div className="flex justify-end">
                    <EventCreationTabs
                      tabType={false}
                      eventType={isPackage}
                      onTabClick={() => setPackageMethod(false)}
                      tabName="Elements schedule"
                      editEventCheck={editEventCheck}
                      className="text-center auto-margin"
                    />
                  </div>
                  <div className="flex justify-start">
                    <EventCreationTabs
                      tabType={true}
                      eventType={isPackage}
                      onTabClick={() => setPackageMethod(true)}
                      tabName="Package schedule"
                      editEventCheck={editEventCheck}
                      className={`text-center auto-margin`}
                      isRecurring={recurring}
                    />
                  </div>
                </div>
              </>
              <div className="pb-12">
                {Object.keys(errors).length > 0 && (
                  <div className="event-error-message">
                    <span>Please fill fields marked above*</span>
                  </div>
                )}
                <Button
                  className="create-event-button w-full"
                  label={editEventId ? 'Save Event' : 'Start Creating'}
                  disabled={
                    eventDetailsData?.['isPastEvent']
                      ? clonedEvent
                        ? false
                        : true
                      : false
                  }
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </ModalPopup>
  );
};

export default CreateEventPopup;
