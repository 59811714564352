import React, { useState, useEffect } from 'react';
import { useFetch, usePagination } from '../../hooks';
import { emptyUserIds, getUsers } from '../../redux/account/account.action';
import { Loader, Table } from '@alfredclub/hom-planner-theme';
import { useSelector, useDispatch } from 'react-redux';
import ChangeEmailPopup from './ChangeEmailPopup';
import { IAccountsState } from '../../redux/account';

const Users: React.FC<{
  onRowCheck: (id: number) => void;
  isLoading: boolean;
}> = ({ onRowCheck, isLoading }) => {
  const { status } = useFetch(getUsers);
  const pagination = usePagination();
  const [pageNo, setPage] = useState(0);
  const { pages, usersCount, emailError, userIds } = useSelector(
    (state: { accounts: IAccountsState }) => state.accounts
  );
  const dispatch = useDispatch();
  const [showEmailChange, setEmailChange] = useState({
    showModal: false,
    email: null,
    accountId: 0,
  });

  const onPageClick = async (page: number) => {
    await pagination(pages, page - 1, getUsers);
    setPage(page - 1);
  };

  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    query.set('pageNo', pageNo.toString());
    window.history.replaceState(null, null, '?' + query.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  const updateEmail = (email, accountId) => {
    setEmailChange({ showModal: true, email, accountId });
  };
  useEffect(() => {
    (async () => await dispatch(emptyUserIds()))();
  }, [dispatch]);
  return status === 'fetched' ? (
    <section className="group_wrapper ml-8 mr-8">
      <ChangeEmailPopup
        modalState={showEmailChange.showModal}
        modalClose={async () => {
          await dispatch(getUsers());
          setEmailChange({ showModal: false, email: null, accountId: 0 });
        }}
        headerText="Change Email Address"
        subHeader={`Current Email: ${showEmailChange.email}`}
        buttonLabel={`${
          emailError ? 'Yes, merge accounts' : 'Update Email Address'
        }`}
        emailExistText="This email is already registered"
        isEmailExist={emailError}
        accountId={showEmailChange.accountId}
      />
      {isLoading ? (
        <Loader
          color="#e89a89"
          position="center"
          loading={isLoading}
          size="0.75rem"
        />
      ) : (
        <Table
          tableColumns={[
            {
              Header: 'Sno',
              accessor: 'id',
              Cell: ({ row }) => {
                return (
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={() => {
                      onRowCheck(row.original.accountId);
                    }}
                    checked={userIds.indexOf(row.original.accountId) !== -1}
                  />
                );
              },
            },
            {
              Header: 'User',
              Cell: ({ row }) => {
                return (
                  <span>
                    {row.original.firstName}
                    &nbsp;
                    {row.original.lastName}
                  </span>
                );
              },
            },
            {
              Header: 'Email Address',
              Cell: ({ row }) => {
                return (
                  <div>
                    <span>{row.original.email}</span>
                    &nbsp;
                    <button
                      className="custom_label float-none"
                      onClick={() =>
                        updateEmail(row.original.email, row.original.accountId)
                      }
                    >
                      Change
                    </button>
                  </div>
                );
              },
            },
          ]}
          showPagination={!(usersCount < 10) && true}
          totalRecordsCount={usersCount}
          onPageChange={(pageNumber) => onPageClick(pageNumber)}
          tableData={pages?.get(pageNo)?.data}
          onCellMouseEnter={() => {}}
        />
      )}
    </section>
  ) : (
    <Loader
      color="#e89a89"
      position="center"
      loading={status !== 'fetched'}
      size="0.75rem"
    />
  );
};

export default Users;
