import { loginApi } from './authentication.service';
import * as authTypes from './authentication.constant';
import * as loadingTypes from '../loader/loader.constant';
import { IUserLogin } from '../../models';
import * as toastTypes from '../toast/toast.constant';
import { TOAST_MESSAGES } from '../../constants';

export const login = (data: IUserLogin) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const authResponse = await loginApi(data);
  if (authResponse?.['data']) {
    const access = authResponse?.['data']?.['access'];
    const accessObj = access && JSON.parse(atob(access.split('.')[1]));
    if (accessObj?.['is_superuser']) {
      dispatch({
        type: authTypes.AUTH_LOGIN,
        payload: authResponse?.['data'],
      });
    } else {
      dispatch({
        type: toastTypes.ERROR_TOAST,
        toastMessage: TOAST_MESSAGES.login.noAccess,
      });
    }
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const clearAuth = () => async (dispatch) => {
  dispatch({
    type: authTypes.CLEAR_TOKEN,
  });
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const refreshToken = (data) => {
  return {
    type: authTypes.UPDATE_REFRESH_TOKEN,
    payload: data,
  };
};

export const updateTimeZone = (data) => {
  return {
    type: authTypes.UPDATE_USER_TIME_ZONE,
    payload: data,
  };
};
