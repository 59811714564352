import React, { useEffect, useState } from 'react';
import { Button, Topnav } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  addBuilding,
  clearBuildingStatusCheck,
  updateBuilding,
  getBuildingDetail,
  setFetchBuildingState,
} from '../../../../redux/building/buildings.action';
import AssociationWithHom from './AssociationWithHom';
import BasicDetails from './BasicDetails';
import ManagementDetails from './ManagementDetails';
import TypesOfProperty from './TypeOfProperty';
import VendorDetails from './VendorDetails';
import WebsiteDetails from './WebisteDetails';
import BuildingStaff from './BuildingStaff';
import AddSpace from './AddSpace';
import BuildingDeletePopup from '../BuildingPopup/BuildingDeletePopup';
import {
  clearBuildingForm,
  updateBuildingForm,
} from '../../../../redux/form/form.action';
import { usePageRefresh, useFormDebounce } from '../../../../hooks';
import './AddBuildingForm.scss';
import { imageUpload } from '../../../../redux/common/common.action';
import { Building } from '../../../../models';
import { IFormState } from '../../../../redux/form';
import { ILoaderState } from '../../../../redux/loader';
import UserSection from './UserSection';
import { IBuildingState } from '../../../../redux/building';
import CommonLoader from '../../../Common/CommonLoader';
import { loadState } from '../../../../utils';

const AddBuildingForm = () => {
  const { push, replace, location } = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [buildingName, setBuildingName] = useState(query.get('buildingName'));
  const [showBuildingDelete, setShowBuildingDelete] = useState({
    showModal: false,
    isDelete: false,
  });
  const buildingId = parseInt(params['buildingId'], 10);
  const { buildingForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [isEdit] = useState(buildingId ? true : false);
  const [buildingData, refreshBuildingForm] = useState(
    isEdit ? loadState('buildingForm') : buildingForm
  );
  const { buildingCodeStatus, isBuildingDetailFetched } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const { name, ...buildingDataWithoutName } = buildingData;
  usePageRefresh(true, buildingId ? false : true);
  const { register, errors, handleSubmit, setValue, watch, getValues, reset } =
    useForm<Building>({
      defaultValues: {
        name: buildingName ?? '',
        ...buildingDataWithoutName,
      },
    });
  const onChangeBuildingName = (value: string) => {
    setBuildingName(value);
    if (!isEdit) {
      replace(`${location.pathname}?buildingName=${value}`);
    }
  };

  useFormDebounce(updateBuildingForm, 100, getValues);
  const managementCompany = watch('managementCompany');
  const onSubmit = async (data: Building) => {
    if (
      buildingData?.['contractFile']['id']?.['length'] >= 0 ||
      !buildingData?.['contractFile']['id']
    ) {
      const contractFile = new FormData();
      contractFile.append('file', data['contractFile']['id'][0]);
      data['contractFile'] = data['contractFile']['id'][0]
        ? await dispatch(imageUpload(contractFile))
        : { id: null, file: null };
    } else {
      data.contractFile = buildingData?.['contractFile'];
    }

    data.active = true;
    data.buildingStaff = buildingData['buildingStaff'];
    data.buildingCode = data?.buildingCode.toLowerCase();
    data.passCode = data?.passCodeEnabled
      ? data?.passCode
      : buildingData?.['passCode'];
    if (isEdit) {
      await dispatch(
        updateBuilding(
          buildingId,
          data,
          buildingData['buildingBlocks'],
          buildingData['buildingWhiteLabel']
        )
      );
    } else {
      data.whitelabelActive = false;
      await dispatch(
        addBuilding(
          data,
          buildingData['buildingSpaces'],
          buildingData['buildingBlocks'],
          null
        )
      );
    }
    if (!buildingCodeStatus) {
      push('/data-resources/clients/buildings');
      await dispatch(clearBuildingForm());
      await dispatch(clearBuildingStatusCheck());
    }
  };
  const onArchive = async () => {
    buildingData.active = !buildingData.active;
    await dispatch(updateBuilding(buildingId, buildingData));
  };

  // Handle Refresh the building profile page
  useEffect(() => {
    async function getBuildingInfo() {
      if (buildingId && !isBuildingDetailFetched) {
        await dispatch(getBuildingDetail(buildingId));
      } else {
        await dispatch(setFetchBuildingState());
      }
    }
    getBuildingInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingId]);

  // Reset the building profile page state
  useEffect(() => {
    if (isEdit) {
      reset(buildingData);
      refreshBuildingForm(buildingForm);
      setBuildingName(buildingData['name']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingForm?.name, isBuildingDetailFetched]);

  return (
    <>
      <Topnav
        headerTitle={`${isEdit ? 'Edit' : 'Add'} Building: ${buildingName}`}
      />
      {showBuildingDelete.showModal && (
        <BuildingDeletePopup
          isDelete={showBuildingDelete.isDelete}
          modalState={showBuildingDelete.showModal}
          onArchive={onArchive}
          modalClose={() =>
            setShowBuildingDelete({ showModal: false, isDelete: false })
          }
          buildingId={buildingId}
          isActive={buildingData.active}
        />
      )}

      {isBuildingDetailFetched ? (
        <div className="mx-10 building-section">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              label="Save changes"
              className={`${
                loading ? 'button_disable save-changes' : 'save-changes'
              }`}
              disabled={loading}
            />
            <BasicDetails
              formErrors={errors}
              register={register}
              setValue={setValue}
              setBuildingName={onChangeBuildingName}
              buildingData={buildingData}
              getValues={getValues}
            />
            <WebsiteDetails
              buildingId={buildingId}
              buildingData={buildingData}
              register={register}
              isEdit={isEdit}
              reset={reset}
              refreshBuildingForm={refreshBuildingForm}
              formErrors={errors}
            />
            <TypesOfProperty
              formErrors={errors}
              register={register}
              setValue={setValue}
              buildingData={buildingData}
              isEdit={isEdit}
              buildingId={buildingId}
              buildingName={buildingName}
            />
            <ManagementDetails
              register={register}
              setValue={setValue}
              managementCompany={managementCompany}
              buildingData={buildingData}
              buildingName={buildingName}
              isEdit={isEdit}
              buildingId={buildingId}
              getValues={getValues}
            />
            <BuildingStaff
              buildingName={buildingName}
              buildingData={buildingData}
              isEdit={isEdit}
              buildingId={buildingId}
              managementCompany={managementCompany}
            />
            <AddSpace
              buildingName={buildingName}
              buildingData={buildingData}
              isEdit={isEdit}
              buildingId={buildingId}
            />
            <UserSection register={register} buildingData={buildingData} />
            <VendorDetails register={register} />
            <AssociationWithHom
              register={register}
              setValue={setValue}
              buildingData={buildingData}
              isEdit={isEdit}
              reset={reset}
              refreshBuildingForm={refreshBuildingForm}
            />
          </form>
          {isEdit && (
            <>
              <div className="de-activate">
                <label
                  onClick={() => {
                    setShowBuildingDelete({ showModal: true, isDelete: false });
                  }}
                >
                  {buildingData.active ? 'Deactivate' : 'Activate'} Building
                </label>
                <span>You can reactivate this building anytime.</span>
              </div>
              <div className="delete_building_text">
                <label
                  onClick={() => {
                    setShowBuildingDelete({ showModal: true, isDelete: true });
                  }}
                >
                  Delete Building
                </label>
                <span>
                  Buildings are deactivated for 30 days before permanent
                  deletion
                </span>
              </div>
            </>
          )}
        </div>
      ) : (
        <CommonLoader loading={!isBuildingDetailFetched} />
      )}
    </>
  );
};

export default AddBuildingForm;
