import { IUserLoginResponse } from './../../models/user';
import { initialAuthState } from './authentication.state';
import * as authTypes from './authentication.constant';

export const authReducer = (
  state: IUserLoginResponse = initialAuthState,
  action: { type: string; payload: any }
): IUserLoginResponse => {
  switch (action.type) {
    case authTypes.AUTH_LOGIN:
      localStorage.setItem('access', action.payload.access);
      localStorage.setItem('refresh', action.payload.refresh);
      localStorage.setItem('userTimezone', action.payload.timezonePreference);
      return {
        ...state,
        refresh: action.payload.refresh,
        access: action.payload.access,
        userTimezone: action.payload.timezonePreference,
      };
    case authTypes.CLEAR_TOKEN:
      return {
        ...state,
        refresh: null,
        access: null,
        userTimezone: null,
      };
    case authTypes.UPDATE_REFRESH_TOKEN:
      localStorage.setItem('access', action.payload.access);
      return {
        ...state,
        access: action.payload.access,
      };
    case authTypes.UPDATE_USER_TIME_ZONE:
      localStorage.setItem('userTimezone', action.payload.timezonePreference);
      return {
        ...state,
        userTimezone: action.payload.timezonePreference,
      };
    default:
      return state;
  }
};
