import {
  getBuildingBlocksApi,
  addBuildingBlocksApi,
  deleteBuildingBlocksApi,
  getBuildingBlockDetailApi,
  updateBlockApi,
} from './buildingblocks.service';
import * as blockTypes from './buildingblocks.constant';
import * as loadingTypes from '../loader/loader.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import * as toastTypes from '../toast/toast.constant';
import { BuildingBlock } from '../../models/buildingblock';
import { PaginatedResponse, DeleteResponse } from '../../models';

export const getBuildingBlocks = (
  buildingId: number,
  limit?: number,
  page?: number,
  cmp?: string,
  query?: string,
  all?: number,
  updateReducer = true
) => async (dispatch) => {
  const paginatedResponse: PaginatedResponse<BuildingBlock> = await getBuildingBlocksApi(
    buildingId,
    cmp,
    limit,
    page,
    query,
    all
  );
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (paginatedResponse.data && updateReducer) {
    dispatch({
      type: blockTypes.GET_BLOCKS,
      payload: paginatedResponse.data,
      blockCount: paginatedResponse.total,
      isCommercial: paginatedResponse.commercial,
      page: page ?? 0,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return paginatedResponse.data;
  }
};

export const saveBuildingBlocksLocaly = (data) => async (dispatch) => {
  dispatch({
    type: blockTypes.BUILDING_BLOCK_ARRAY,
    payload: data,
  });
};

export const addBuildingBlocks = (buildingId: number, blocks: any) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const buildingBlock: BuildingBlock = await addBuildingBlocksApi(
    buildingId,
    blocks
  );

  if (buildingBlock) {
    dispatch({
      type: blockTypes.ADD_BUILDING_BLOCKS,
      payload: buildingBlock,
    });
    dispatch({
      type: toastTypes.SUCCESS_TOAST,
      toastMessage: TOAST_MESSAGES.blocks.added,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteBuildingBlock = (blockId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const deleteResponse: DeleteResponse = await deleteBuildingBlocksApi(blockId);
  if (deleteResponse.delete) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.blocks.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const searchBuildingBlocks = (
  data,
  query,
  page,
  buildingId,
  Commercial
) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: blockTypes.SEARCH_BLOCKS_DATA,
      payload: data,
      blockCount: data.length,
      page,
      isCommercial: Commercial,
    });
  } else {
    await getBuildingBlocks(buildingId, 10, page, 'short', query)(dispatch);
  }
};

export const getBuildingBlockDetail = (
  blockId: number,
  buildingId: number
) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const buildingBlock = await getBuildingBlockDetailApi(blockId);
  if (buildingBlock) {
    const newBlockArray = [buildingBlock];
    const buildingBlockLocalObj = {
      newBlockArray: { newBlockArray },
      buildingId,
      editBlock: true,
      blockId: buildingBlock?.id,
    };
    dispatch({
      type: blockTypes.BUILDING_BLOCK_ARRAY,
      payload: buildingBlockLocalObj,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const updateBuildingBlock = (blockId: number, data: object) => async (
  dispatch
) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const updatedBuldingBlock: BuildingBlock = await updateBlockApi(
    blockId,
    data
  );

  if (updatedBuldingBlock) {
    dispatch({
      type: blockTypes.UPDATE_BUILDING_BLOCK,
      toastMessage: updatedBuldingBlock,
    });
    dispatch({
      type: toastTypes.UDPATE_TOAST,
      toastMessage: TOAST_MESSAGES.blocks.updated,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const clearUnitsList = () => async (dispatch) => {
  dispatch({
    type: blockTypes.EMPTY_UNITS_LIST,
  });
};
