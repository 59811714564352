import * as eventTypes from './event.constant';
import { IEventsState, initialEventState } from './event.state';

export const eventsReducer = (
  state: IEventsState = initialEventState,
  action: {
    type: string;
    payload: any;
    page: number;
    param: string;
    total: number;
    index: number;
    rowIndex: number;
    itemIndex: number;
    isGroup: boolean;
    isRecurring: boolean;
  }
): IEventsState => {
  switch (action.type) {
    case eventTypes.GET_EVENTS_COUNT:
      return {
        ...state,
        eventCount: { ...action.payload },
      };
    case eventTypes.GET_EVENT_SERVICES:
      const filterNonPackage = action.payload?.filter(
        ({ isPackage }) => !isPackage
      );
      return {
        ...state,
        eventServices: [...action.payload],
        eventElements: filterNonPackage,
      };
    case eventTypes.CLEAR_SERVICE_LIST:
      return {
        ...state,
        eventServices: [],
      };
    case eventTypes.GET_EVENTS:
    case eventTypes.SEARCH_EVENTS:
      return {
        ...state,
        [`${action.param}Events`]: {
          ...state[`${action.param}Events`],
          pages: state[`${action.param}Events`].pages.set(action.page, {
            data: action.payload,
            fetched: true,
          }),
        },
        [`${action.param}Count`]: action.total,
      };

    case eventTypes.SAVE_EVENT_DATA:
      return {
        ...state,
        eventData: action.payload,
        eventInviteesData: action.payload,
        eventBuildingData: action.payload,
      };
    case eventTypes.SAVE_EVENT_DATA_BUILDINGS:
      return {
        ...state,
        eventData: action.payload,
        eventBuildingData: action.payload,
      };
    case eventTypes.GET_ALL_BUILDINGS_BY_TIMEZONE:
      return {
        ...state,
        allRegionBuildings: [...action.payload],
      };

    case eventTypes.CLEAR_ALL_EVENTS:
      return {
        ...state,
        publishedEvents: initialEventState.publishedEvents,
        cancelledEvents: initialEventState.cancelledEvents,
        draftsEvents: initialEventState.draftsEvents,
        publishedCount: initialEventState.publishedCount,
        cancelledCount: initialEventState.cancelledCount,
        draftsCount: initialEventState.draftsCount,
        heroVendors: initialEventState.heroVendors,
        heroVendorsCount: initialEventState.heroVendorsCount,
      };
    case eventTypes.CLEAR_ALL_BUILDINGS_BY_TIMEZONE:
      return {
        ...state,
        allRegionBuildings: [],
        allBuildings: [],
      };

    case eventTypes.GET_ALL_BUILDINGS:
      return {
        ...state,
        allBuildings: [...action.payload],
      };

    case eventTypes.GET_ASSIGNED_VENDOR_INFO:
      let updatedVendorAssigned = {};
      let updatedInviteesList = [];
      // Vendor invite case
      if (!action.index) {
        if (action.payload?.['vendorAssign'].length > 0) {
          action.payload?.['vendorAssign']?.forEach((_, index) => {
            updatedVendorAssigned = {
              ...updatedVendorAssigned,
              [action.isRecurring
                ? action.payload?.['vendorAssign']?.[index]?.['weekday']
                : action.payload?.['vendorAssign']?.[index]?.['element']?.[
                    'id'
                  ]]: {
                recurring: action.payload?.['recurring'],
                vendorAssign: action.payload?.['vendorAssign'].filter((d) =>
                  action.isRecurring
                    ? d.weekday ===
                      action.payload?.['vendorAssign']?.[index]?.['weekday']
                    : d.element.id ===
                      action.payload?.['vendorAssign']?.[index]?.['element']?.[
                        'id'
                      ]
                ),
              },
            };
          });
          if (action.payload?.['vendorInvite'].length > 0) {
            action.payload?.['vendorInvite']?.forEach((_, index) => {
              updatedInviteesList = {
                ...updatedInviteesList,
                [action.isRecurring
                  ? action.payload?.['vendorInvite']?.[index]?.['weekday']
                  : action.payload?.['vendorInvite']?.[index]?.['element']?.[
                      'id'
                    ]]: {
                  ...action.payload?.['vendorInvite'][index],
                },
              };
            });
          }
        } else {
          updatedVendorAssigned = {
            ...state.eventVendorAssigned,
            0: action.payload,
          };
          if (action.payload?.['vendorInvite'].length > 0) {
            action.payload?.['vendorInvite']?.forEach((_, index) => {
              updatedInviteesList = {
                ...updatedInviteesList,
                [action.isRecurring
                  ? action.payload?.['vendorInvite']?.[index]?.['weekday']
                  : action.payload?.['vendorInvite']?.[index]?.['element']?.[
                      'id'
                    ]]: {
                  ...action.payload?.['vendorInvite'][index],
                },
              };
            });
          }
        }
      } else {
        // Manually assigned vendor case
        if (action.payload?.['vendorAssign'].length > 0) {
          action.payload?.['vendorAssign']?.forEach((_, index) => {
            updatedVendorAssigned = {
              ...updatedVendorAssigned,
              [action.isRecurring
                ? action.payload?.['vendorAssign']?.[index]?.['weekday']
                : action.payload?.['vendorAssign']?.[index]?.['element']?.[
                    'id'
                  ]]: {
                recurring: action.payload?.['recurring'],
                vendorAssign: action.payload?.['vendorAssign'].filter((d) =>
                  action.isRecurring
                    ? d.weekday ===
                      action.payload?.['vendorAssign']?.[index]?.['weekday']
                    : d.element.id ===
                      action.payload?.['vendorAssign']?.[index]?.['element']?.[
                        'id'
                      ]
                ),
              },
            };
          });
        }
        if (action.payload?.['vendorInvite'].length > 0) {
          action.payload?.['vendorInvite']?.forEach((_, index) => {
            updatedInviteesList = {
              ...updatedInviteesList,
              [action.isRecurring
                ? action.payload?.['vendorInvite']?.[index]?.['weekday']
                : action.payload?.['vendorInvite']?.[index]?.['element']?.[
                    'id'
                  ]]: {
                ...action.payload?.['vendorInvite'][index],
              },
            };
          });
        }
      }

      return {
        ...state,
        eventVendorAssigned: updatedVendorAssigned,
        eventInvitedVendors: updatedInviteesList,
        invitedVendors: updatedInviteesList,
        selectedRegionsVendor: updatedInviteesList,
        getInfoApiStatus: true,
      };

    case eventTypes.RESET_GETINFO_STATUS:
      return {
        ...state,
        getInfoApiStatus: false,
      };
    case eventTypes.CLEAR_EVENT_VENDOR_ASSIGNMENT:
      return {
        ...state,
        eventVendorAssigned: {},
      };

    case eventTypes.GET_WEEK_DAYS:
      return {
        ...state,
        weekDays: action.payload,
      };

    case eventTypes.GET_VENDOR_WITH_REGIONS:
      let filteredVendorsWithRegions = [...action.payload];
      // Keep it commented for future refrence this code is for not showing vendors of same region in uninvited vendors
      // if (Object.keys(state.eventInvitedVendors).length > 0) {
      //   if (
      //     state.eventInvitedVendors?.[action.index]?.['selectedRegions']
      //       ?.length > 0
      //   ) {
      //     const selectedRegionsIds = new Set(
      //       state.eventInvitedVendors[action.index]['selectedRegions'].map(
      //         ({ id }) => id
      //       )
      //     );
      //     filteredVendorsWithRegions = action.payload.filter(
      //       (region) => !selectedRegionsIds.has(region.id)
      //     );
      //   }
      // }
      const newArray = [];
      if (state.recurringVendorWithRegions.length > 0) {
        newArray.push({
          ...state.recurringVendorWithRegions[0],
          [action.index]: filteredVendorsWithRegions,
        });
      } else {
        newArray.push({
          [action.index]: filteredVendorsWithRegions,
        });
      }
      const vendorCount = [];
      if (filteredVendorsWithRegions.length > 0) {
        const vendorsArray = filteredVendorsWithRegions.filter(
          (d) => d.vendors.length > 0
        );
        if (state.vendorRegionCount?.length > 0) {
          vendorCount.push({
            ...state.vendorRegionCount[0],
            [action.index]: vendorsArray.length,
          });
        } else {
          vendorCount.push({
            [action.index]: vendorsArray.length,
          });
        }
      }
      return {
        ...state,
        vendorWithRegions: newArray,
        vendorWithRegionsCount: action.total,
        recurringVendorWithRegions: newArray,
        vendorRegionCount: vendorCount,
      };

    case eventTypes.GET_INVITED_VENDORS:
      // Code is commented for future reference

      // let filterVendor = [];
      // if (action.payload['inviteList'].length > 0) {
      // const invitedIds = new Set(
      //   action.payload['inviteList'].map(({ id }) => id)
      // );
      // filterVendor = state.vendorWithRegions[action.index].filter(
      //   (vendor) => !invitedIds.has(vendor.id)
      // );
      // }
      return {
        ...state,
        // invitedVendors: [state.invitedVendors, action.payload],
        // vendorWithRegions: [...filterVendor],
      };

    case eventTypes.REMOVE_VENDOR_WITH_REGIONS:
      const filteredVendors = state.vendorWithRegions[0][
        action.payload.fieldIndex
      ][action.payload.regionIndex].vendors.filter(
        (item) => item.vendorId !== action.payload.vendorId
      );
      state.vendorWithRegions[0][action.payload.fieldIndex][
        action.payload.regionIndex
      ].vendors = filteredVendors;
      return {
        ...state,
        vendorWithRegions: [...state.vendorWithRegions],
      };

    case eventTypes.RESET_VENDOR_WITH_REGIONS:
      action.payload.forEach((resetVendor) => {
        state.vendorWithRegions[0][action.index][
          resetVendor.index
        ].vendors.push(resetVendor.value);
      });
      return {
        ...state,
      };

    case eventTypes.CLEAR_VENDORS_WITH_REGION:
      return {
        ...state,
        vendorWithRegions: [],
        recurringVendorWithRegions: [],
      };

    case eventTypes.CLEAR_VENDOR_ASSIGNED:
      return {
        ...state,
        eventVendorAssigned: [
          {
            vendorAssign: [],
            vendorInvite: [],
            recurring: false,
            manuallyAssigned: false,
          },
        ],
      };

    case eventTypes.GET_EVENT_TIMELINE:
      return {
        ...state,
        eventTimeLine: action.payload,
      };

    case eventTypes.GET_EVENT_TASKS:
      return {
        ...state,
        eventTasks: action.payload,
      };
    case eventTypes.CLEAR_EVENT_DATA:
      return {
        ...state,
        eventInviteesData: {},
        eventBuildingData: {},
      };
    case eventTypes.GET_EVENT_USERS:
      return {
        ...state,
        eventUsers: action.payload,
        eventUsersCount: action.total,
      };

    case eventTypes.GET_TASK_DETAIL:
      return {
        ...state,
        eventTaskDetail: action.payload,
      };
    case eventTypes.SET_EVENT_DATE_TYPE:
      return {
        ...state,
        eventDateType: action.payload,
      };
    case eventTypes.GET_CHAT_SCREEN_DETAIL:
      return {
        ...state,
        chatScreenDetail: action.payload,
      };
    case eventTypes.EVENT_BROADCAST_REALTED_DETAIL:
      return {
        ...state,
        broadcastEventDetail: action.payload.data,
      };
    case eventTypes.SEND_BROADCAST_MESSAGE:
      return {
        ...state,
        broadcastMessageTo: action.payload,
      };
    case eventTypes.SEARCH_EVENT_BY:
      return {
        ...state,
        eventSearchBy: action.payload,
      };
    case eventTypes.SEARCH_EVENT_FOR:
      return {
        ...state,
        eventSearchFor: action.payload,
      };
    case eventTypes.CLEAR_SEARCH_EVENT_FOR:
      return {
        ...state,
        eventSearchFor: {},
      };
    case eventTypes.SET_SELECTED_DATE:
      return {
        ...state,
        searchSelectedDate: action.payload,
      };
    case eventTypes.CLEAR_SEARCH_EVENT_BY:
      return {
        ...state,
        eventSearchBy: initialEventState.eventSearchBy,
      };
    case eventTypes.GET_EVENTS_INVENTORY:
      return {
        ...state,
        eventsInvetoryGroups: [...action.payload['groups']],
        eventsInvetoryItems: [...action.payload['individualItems']],
        packageElements: action?.payload?.packageElements?.length
          ? [...action?.payload?.['packageElements']]
          : [],
        inventoryPackageCheck: action?.payload?.isPackage,
      };
    case eventTypes.UPDATE_EVENTS_INVENTORY_GROUPS:
      return {
        ...state,
        eventsInvetoryGroups: [...action.payload],
      };
    case eventTypes.UPDATE_EVENTS_INVENTORY_ITEMS:
      return {
        ...state,
        eventsInvetoryItems: [...action.payload],
      };
    case eventTypes.UPDATE_EVENTS_INVENTORY_ITEMS_GROUPS:
      const inventory = action.isGroup
        ? state.eventsInvetoryGroups
        : state.eventsInvetoryItems;
      inventory[action.rowIndex]['items'][action.itemIndex] = {
        ...inventory[action.rowIndex]['items'][action.itemIndex],
        ...action.payload,
      };
      return {
        ...state,
      };
    case eventTypes.DELETE_EVENTS_INVENTORY_ITEM:
      const inventoryGroup = action.isGroup
        ? [...state.eventsInvetoryGroups]
        : [...state.eventsInvetoryItems];
      const newInventory = inventoryGroup[action.rowIndex]['items'].filter(
        (item) => item.id !== action?.['itemId']
      );
      const updatedInventory = {
        eventsInvetoryGroups: [...state.eventsInvetoryGroups],
        eventsInvetoryItems: [...state.eventsInvetoryItems],
      };
      if (action.isGroup) {
        updatedInventory.eventsInvetoryGroups[action.rowIndex]['items'] = [
          ...newInventory,
        ];
      } else {
        updatedInventory.eventsInvetoryItems[action.rowIndex]['items'] = [
          ...newInventory,
        ];
      }
      return {
        ...state,
        ...updatedInventory,
      };
    case eventTypes.CLEAR_ALL_EVENT_INVENTORY:
      return {
        ...state,
        eventsInvetoryGroups: [],
        eventsInvetoryItems: [],
      };
    case eventTypes.CLEAR_VENDOR_ASSIGNMENT_STATE:
      return {
        ...state,
        selectedRegionsVendor: [],
        vendorWithRegions: [],
        eventVendorAssigned: [],
        eventInvitedVendors: [],
        vendorRequirements: [],
      };
    case eventTypes.GET_VENDOR_REQUIRMENTS:
      return {
        ...state,
        vendorRequirements: [...action.payload],
      };
    case eventTypes.CLEAR_VENDOR_REQUIRMENTS:
      return {
        ...state,
        vendorRequirements: [],
      };
    case eventTypes.GENERATE_CLIENT_REPORT:
      return {
        ...state,
        clientReport: action.payload,
      };
    case eventTypes.CLEAR_CLIENT_REPORT:
      return {
        ...state,
        clientReport: {},
      };
    case eventTypes.CLEAR_EVENT_TASK:
      return {
        ...state,
        eventTaskDetail: initialEventState.eventTaskDetail,
      };
    case eventTypes.GET_HERO_VENDORS:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        heroVendorsCount: action.total,
        heroVendors: action.payload,
      };
    case eventTypes.SET_CATEGORY_FILTERS:
      return {
        ...state,
        categoryFilters: action.payload,
      };
    case eventTypes.IMPORT_TASK_TO_EVENT:
      const importedTask = action?.['payload']?.filter((item) =>
        item.hasOwnProperty('isImportedTask')
      );
      return {
        ...state,
        importedEventTask: importedTask,
      };
    case eventTypes.CLEAR_EVENT_IMPORTED_TASK_STATE:
      return {
        ...state,
        importedEventTask: [],
      };
    case eventTypes.GET_EVENT_ELEMENTS_LIST:
      let elementslist = action.payload;
      if (action.isRecurring) {
        elementslist = action?.['payload']?.filter(
          (item) => item.id === action.param
        );
      }
      return {
        ...state,
        eventElementsList: elementslist,
      };
    case eventTypes.UPDATE_EVENT_TASK_GROUP_SELECTED:
      return {
        ...state,
        eventTaskGroupList: action?.['payload'],
      };
    case eventTypes.CLEAR_EVENT_TASK_GROUP_LIST:
      return {
        ...state,
        eventTaskGroupList: [],
      };
    case eventTypes.SET_MONTH_CHANGE:
      return {
        ...state,
        monthInfo: action.payload,
      };
    default:
      return state;
  }
};
