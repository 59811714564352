import { http } from '../common/http.service';
import {
  RECORDS_LIMIT,
  RECORDS_CMP,
  RECORDS_PAGE,
  RECORDS_QUERY,
  BUILDING_BASE_URL,
  ALL_RECORDS,
  PROPERTY_BASE_URL,
} from '../../constants/index';
import { AxiosResponse } from 'axios';
import {
  PaginatedResponse,
  DeleteResponse,
  Building,
  CheckStatusResponse,
} from '../../models';

export const getBuildingsApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS,
  searchBuilding: number = null
): Promise<PaginatedResponse<Building>> =>
  http
    .get<PaginatedResponse<Building>>(
      `${BUILDING_BASE_URL}?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}${
        searchBuilding ? `&search_building=${searchBuilding}` : ``
      }&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Building>>) => {
      return response.data;
    });

export const getBuildingsByManagementApi = async (
  filterId: number,
  all: number = ALL_RECORDS
): Promise<PaginatedResponse<Building>> =>
  http
    .get<PaginatedResponse<Building>>(
      `${BUILDING_BASE_URL}?filter=by_management&all=${all}${
        !!filterId ? `&filter_value=${filterId}` : ''
      }`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Building>>) => {
      return response.data;
    });

export const addBuildingsApi = async (building: Building): Promise<Building> =>
  http
    .post<Building>(`${BUILDING_BASE_URL}`, building, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Building>) => {
      return response.data;
    });

export const getBuildingDetailApi = async (
  buildingId: number
): Promise<Building> =>
  http
    .get<Building>(`${BUILDING_BASE_URL}/${buildingId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Building>) => {
      return response.data;
    });

export const updateBuildingApi = async (
  buildingId: number,
  data: Building
): Promise<Building> =>
  http
    .put<Building>(`${BUILDING_BASE_URL}/${buildingId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Building>) => {
      return response.data;
    });

export const deleteBuildingApi = async (
  buildingId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`${BUILDING_BASE_URL}/${buildingId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });

export const checkBuildingCodeApi = async (
  buildingCode: string
): Promise<CheckStatusResponse> =>
  http
    .get<CheckStatusResponse>(`${PROPERTY_BASE_URL}/${buildingCode}/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<CheckStatusResponse>) => {
      return response.data;
    });
