import React from 'react';
import ModalPopup from '../../../ReactModal/Modal';
import { Input, Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveBuildingBlocksLocaly } from '../../../../redux/buildingblocks/buildingblocks.action';
import './BuildingPopup.scss';

interface IFormInput {
  buildingName: string;
}
const BuildingPopup: React.FC<{
  modalState: boolean;
  onClosePopup: any;
}> = ({ modalState, onClosePopup }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm<IFormInput>();
  const onSubmit = (data: IFormInput) => {
    dispatch(saveBuildingBlocksLocaly({}));
    history.push(
      `/data-resources/clients/buildings/add-building?buildingName=${data.buildingName}`
    );
  };
  return (
    <ModalPopup isOpen={modalState} onRequestClose={onClosePopup}>
      <PrimaryLabel labelText="Enter Building name" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Hello testing"
          placeholder="Type here"
          name="buildingName"
          ref={register({ required: true })}
          isMandatory={true}
        />
        {errors.buildingName && (
          <div className="error">Building name is required</div>
        )}
        <Button
          label="Continue"
          className="continue-button"
          isArrowShow={true}
        />
      </form>
    </ModalPopup>
  );
};

export default BuildingPopup;
