import React, { useEffect, useState } from 'react';
import { Topnav } from '@alfredclub/hom-planner-theme';
import CommonTabCompponent from '../Common/CommonTabComponent';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ServiceVendors from './ServiceVendors/ServiceVendors';
import AddElementSetupForm from './Setup/AddElementSetupForm';
// import { loadState } from '../../utils';
import ElementListing from './Elements/ElementListing';
import TaskList from './TaskList/TaskList';
import PricingIndex from './Pricing/PricingIndex';
import {
  getServiceElements,
  getServices,
  getServiceDetail,
} from '../../redux/service/services.action';
import { IFormState } from '../../redux/form';
import EventInventory from '../../components/Events/TaskManagement/Inventory/index';
import { IServiceState } from '../../redux/service';
import CommonLoader from '../Common/CommonLoader';

const AddServiceForm = () => {
  const params = useParams();
  const serviceId = parseInt(params['serviceId'], 10);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(search);
  const [serviceType] = useState(query.get('serviceType'));
  const [isCancelIcon, setCancelState] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | object>('');

  const [servicePopupData] = useState(
    query.get('service') ? JSON.parse(atob(query.get('service'))) : null
  );
  const { serviceSetupForm: serviceSetupData } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const { fetchedService } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const [isEdit] = useState(serviceId ? true : false);
  // const [serviceSetupData] = useState(
  //   isEdit ? loadState('serviceSetupForm') : serviceSetupForm
  // );

  const [serviceName, setServiceName] = useState<string>(
    servicePopupData ? servicePopupData?.['name'] : serviceSetupData?.['name']
  );

  const tab = query.get('tab');
  let timer = null;
  const onSearch = async (querySearch: string) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getServiceElements(10, 0, 'short', querySearch, 1, false)
          );
          res(data);
        }, 1000))
    );
  };
  const onClickCancel = async () => {
    await dispatch(getServices());
    setSearchValue('');
    setCancelState(false);
  };

  const onChangeServiceName = (value: string) => {
    setServiceName(value);
  };

  useEffect(() => {
    async function getServiceInfo() {
      if (serviceId) {
        await dispatch(getServiceDetail(serviceId));
      }
    }
    getServiceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  return (
    <>
      <Topnav
        headerTitle={`${
          serviceId
            ? serviceSetupData.name
            : `Add ${
                Number(serviceType) > 1 ? 'Package' : 'Service'
              }: ${serviceName}`
        }`}
        showSearchBar={tab === 'elements' ? true : false}
        placeholder="Search Elements"
        promiseOptions={onSearch}
        showCancelIcon={isCancelIcon}
        onSearchOptionSelect={async (option) => {
          if (option) {
            setSearchValue(option);
            await dispatch(
              getServiceElements(10, 0, 'short', '', 1, true, option.id)
            );
            setCancelState(true);
          }
        }}
        optionLabel="name"
        optionKey="id"
        onClickOfCloseIcon={onClickCancel}
        defaultSearchValue={[searchValue]}
      />
      {fetchedService || servicePopupData ? (
        <CommonTabCompponent className="ml-4">
          {serviceType === '2' && (
            <div data-label="Elements" data-click={isEdit}>
              <ElementListing />
            </div>
          )}
          <div data-label="Setup" data-click={isEdit}>
            <AddElementSetupForm onChangeServiceName={onChangeServiceName} />
          </div>
          <div data-label="Task-List" data-click={isEdit}>
            <TaskList />
          </div>
          <div data-label="Inventory" data-click={isEdit}>
            <EventInventory serviceInventory={true} />
          </div>
          <div data-label="Vendors" data-click={isEdit}>
            <ServiceVendors />
          </div>
          <div data-label="Pricing" data-click={isEdit}>
            <PricingIndex />
          </div>
        </CommonTabCompponent>
      ) : (
        <CommonLoader loading={!fetchedService} />
      )}
    </>
  );
};

export default AddServiceForm;
