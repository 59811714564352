import { Task } from '../../models';
import * as formTypes from './form.constant';
import * as loadingTypes from '../loader/loader.constant'

export const updateBuildingForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_BUILDING_FORM,
    payload: data,
  });
};

export const updateSpaceForminBuilding = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_BUILDING_SPACE_FORM,
    payload: data,
  });
};

export const updateWhiteLabelForminBuilding = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_BUILDING_WHITELABEL_FORM,
    payload: data,
  });
};

export const updatecompanyForminUnits = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_COMPANY_UNIT_FORM,
    payload: data,
  });
};

export const updateUnitCount = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_UNIT_COUNT,
    payload: data,
  });
};

export const deleteSpaceFromBuilding = (index: number) => async (dispatch) => {
  dispatch({
    type: formTypes.DELETE_SPACE_FROM_BUILDING,
    index,
  });
};

export const clearBuildingForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_BUILDING_FORM,
  });
};

export const updateManagementForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_MANAGEMENT_FORM,
    payload: data,
  });
};

export const clearManagementForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_MANAGEMENT_FORM,
  });
};

export const updateClusterForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_CLUSTER_FORM,
    payload: data,
  });
};

export const clearClusterForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_CLUSTER_FORM,
  });
};

export const updateStaffForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_BUILDING_STAFF,
    payload: data,
  });
};

export const clearStaffForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_BUILDING_STAFF,
  });
};

export const updateUserForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_USER_FORM,
    payload: data,
  });
};

export const clearUserForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_USER_FORM,
  });
};

export const clearSpaceForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_SPACE_FORM,
  });
};

export const updateCompanyVendorForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_COMPANY_VENDOR_FORM,
    payload: data,
  });
};

export const clearCompanyVendorForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_COMPANY_VENDOR_FORM,
  });
};

export const updateCompanyIndividualForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_COMPANY_INDIVIDUAL_FORM,
    payload: data,
  });
};

export const updateVendorIndividualForm = (data, keyName) => async (
  dispatch
) => {
  dispatch({
    type: formTypes.UPDATE_INDIVIDUAL_FORM,
    payload: data,
    keyName,
  });
};
export const clearCompanyIndividualForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_INDIVIDUAL_FORM,
  });
};

export const updateContactVendorInCompanyVendor = (data) => async (
  dispatch
) => {
  dispatch({
    type: formTypes.UPDATE_COMPANY_VENDOR_CONTACTS,
    payload: data,
  });
};

export const updateServiceSetupForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_SERVICE_SETUP_FORM,
    payload: data,
  });
};

export const clearServiceSetupForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_SERVICE_SETUP_FORM,
  });
};

export const updateServicesBuildings = (data, serviceType) => async (
  dispatch
) => {
  dispatch({
    type: formTypes.UPDATE_COMPANY_INDIVIDUAL_BUILDINGS,
    payload: data,
    service: serviceType === 'venu' ? 'venueServices' : 'onlineServices',
    pendingService: serviceType === 'venu' ? 'onlineServices' : 'venueServices',
  });
};

export const updateVendorServices = (data, serviceType, serviceChild) => async (
  dispatch
) => {
  dispatch({
    type: formTypes.UPDATE_INDIVIDUAL_SERVICES,
    payload: data,
    service: serviceType,
    serviceChild,
  });
};

export const updateBuildingStaff = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_BUILDING_FORM_STAFF,
    payload: data,
  });
};

export const updatePackageElements = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_PACKAGE_ELEMENTS_FORM,
    payload: data,
  });
};

export const clearPackageElementsForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_PACKAGE_ELEMENTS_FORM,
  });
};

export const updateServiceVendorsForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_SERVICE_VENDOR_FORM,
    payload: data?.data?.vendorRequirements,
  });
};

export const clearServiceVendorForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_SERVICE_VENDOR_FORM,
  });
};

export const updateServicePriceForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_SERVICE_PRICE_FORM,
    payload: data,
  });
};

export const clearServicePriceForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_SERVICE_PRICE_FORM,
  });
};
export const updateTaskList = (data: Task) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_TASK_LIST_EDIT,
    payload: data,
  });
};

export const removeTask = (id: string) => async (dispatch) => {
  dispatch({
    type: formTypes.REMOVE_TASK,
    payload: id,
  });
};

export const clearServiceTaskListForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_TASK_LIST,
  });
};

export const updateServiceElementsVendors = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_SERVICE_ELEMENTS_VENDOR_FORM,
    payload: data,
  });
};

export const updateEventSetupForm = (data) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
  dispatch({
    type: formTypes.UPDATE_EVENT_SETUP_FORM,
    payload: data,
  });
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const clearEventSetupForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_EVENT_SETUP_FORM,
  });
};

export const updateRecurringSetupForm = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_RECURRING_SETUP_FORM,
    payload: data,
  });
};

export const updateRecurringFormSchedules = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_RECURRING_FORM_SCHEDULES,
    payload: data,
  });
};

export const clearRecurringSetupForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_RECURRING_SETUP_FORM,
  });
};

export const clearCloneRecurringSetupForm = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_CLONE_RECURRING_SETUP_FORM,
  });
};

export const updateEventTaskList = (data) => async (dispatch) => {
  dispatch({
    type: formTypes.UPDATE_EVENT_TASK_LIST,
    payload: data,
  });
};

export const clearAuxiliaryVendorField = () => async (dispatch) => {
  dispatch({
    type: formTypes.EMPTY_AUXILIARY_VENDORS_FIELDS,
  });
};
