import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ManagementListing from '../components/Clients/Management/ManagementListing';
import BuildingListing from '../components/Clients/Building/BuildingListing';
import ClusterListing from '../components/Clients/Cluster/ClusterListing';
import AddSpace from '../components/Clients/Spaces/AddSpace';
import SpaceListing from '../components/Clients/Spaces/SpaceListing';
import AddNewManagement from '../components/Clients/Management/AddNewManagement';
import StaffListing from '../components/Clients/Staff/StaffListing';
import CompaniesListing from '../components/Clients/Companies/CompaniesListing';
import AddClusterProfile from '../components/Clients/Cluster/AddClusterProfile';
import AddCompanyForm from '../components/Clients/Companies/AddNewCompany/AddCompanyForm';
import AddBuildingStaff from '../components/Clients/Staff/AddBuildingStaff';
import UserDashboard from '../components/Users/UserDashboard';
import UserProfile from '../components/Users/UserProfile';
import VendorListing from '../components/Vendors/VendorListing';
import AddCompanyVendor from '../components/Vendors/AddCompanyVendor';
import AddBlockForm from '../components/Clients/Companies/AddNewCompany/AddBlockForm';
import AddAssociatedBuildings from '../components/Vendors/AddAssociatedBuildings';
import ServiceListing from '../components/Services/ServiceListing';
import TagVendors from '../components/Vendors/TagVendors';
import BuildingStaffSelection from '../components/Clients/Staff/BuildingStaffSelection';
import AddServiceForm from '../components/Services/AddServiceForm';
import AddVendorForm from '../components/Vendors/AddVendor/AddVendorForm';
import AddBuildingForm from '../components/Clients/Building/AddBuilding/AddBuildingForm';
import ServiceVendors from '../components/Services/ServiceVendors/ServiceVendors';
import Inventory from '../components/Inventory/Inventory';
import Accounts from '../components/Accounts/Accounts';
import EventsListing from '../components/EventListing/EventsListing';
import EventsLayout from '../components/Events/EventsLayout/EventsLayout';
import PrivateRoute from './ProtectedRoute';
import LoginForm from '../components/Login/LoginForm';
import AdminChat from '../components/Events/AdminChat/AdminChat';
import TaskTemplate from '../components/TaskTemplates/TaskTemplate';

const Routes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path="/login">
          <LoginForm />
        </Route>
        <Redirect exact={true} from="/" to="/events?tab=published&pageNo=0" />
        {/* <Route exact={true} path="/" render={() => <EventsListing />} /> */}
        {/* *********************** Building ********************  */}
        <PrivateRoute exact={true} path="/data-resources/clients/buildings">
          <BuildingListing />
        </PrivateRoute>
        <PrivateRoute
          exact={true}
          path="/data-resources/clients/buildings/add-building"
        >
          <AddBuildingForm />
        </PrivateRoute>
        <PrivateRoute
          exact={true}
          path="/data-resources/clients/buildings/add-staff"
        >
          <BuildingStaffSelection />
        </PrivateRoute>
        <PrivateRoute
          exact={true}
          path="/data-resources/clients/buildings/:buildingId"
        >
          <AddBuildingForm />
        </PrivateRoute>

        {/* *********************** Space ********************  */}
        <PrivateRoute path="/data-resources/clients/spaces/add-space">
          <AddSpace />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/clients/spaces/:spaceId">
          <AddSpace />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/data-resources/clients/spaces">
          <SpaceListing />
        </PrivateRoute>

        {/* *********************** Management ********************  */}
        <PrivateRoute path="/data-resources/clients/management/add-management">
          <AddNewManagement />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/clients/management/:managementId">
          <AddNewManagement />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/data-resources/clients/management">
          <ManagementListing />
        </PrivateRoute>

        {/* *********************** Cluster ********************  */}
        <PrivateRoute path="/data-resources/clients/clusters/add-cluster">
          <AddClusterProfile />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/clients/clusters/:clusterId">
          <AddClusterProfile />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/data-resources/clients/clusters">
          <ClusterListing />
        </PrivateRoute>

        {/* *********************** Staff ********************  */}
        <PrivateRoute path="/data-resources/clients/staff/add-staff">
          <AddBuildingStaff />
        </PrivateRoute>

        <PrivateRoute path="/data-resources/clients/staff/:staffId">
          <AddBuildingStaff />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/data-resources/clients/staff">
          <StaffListing />
        </PrivateRoute>
        <PrivateRoute
          exact={true}
          path="/data-resources/clients/companies-units"
        >
          <CompaniesListing />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/clients/companies-units/add-companies">
          <AddCompanyForm />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/clients/companies-units/add-blocks">
          <AddBlockForm />
        </PrivateRoute>

        {/* *********************** User Profile ********************  */}
        <PrivateRoute path="/data-resources/users/add-user">
          <UserProfile />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/users/:userId">
          <UserProfile />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/data-resources/users">
          <UserDashboard />
        </PrivateRoute>

        {/* *********************** Vendors ********************  */}
        <PrivateRoute exact={true} path="/data-resources/vendors/add-building">
          <AddAssociatedBuildings />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/data-resources/vendors/add-indvendor">
          <AddVendorForm />
        </PrivateRoute>
        {/* Note: Do not shuffle tag vendor route from this position */}
        <PrivateRoute exact={true} path="/data-resources/vendors/tag-vendors">
          <TagVendors />
        </PrivateRoute>
        <PrivateRoute
          exact={true}
          path="/data-resources/vendors/tag-vendors/:serviceId"
        >
          <TagVendors />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/vendors/add-comvendor">
          <AddCompanyVendor />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/data-resources/vendors/:vendorId">
          <AddVendorForm />
        </PrivateRoute>
        <PrivateRoute
          exact={true}
          path="/data-resources/vendors/company/:companyVendorId"
        >
          <AddCompanyVendor />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/data-resources/vendors">
          <VendorListing />
        </PrivateRoute>

        {/* *********************** Services ********************  */}
        <PrivateRoute path="/data-resources/services/add-service">
          <AddServiceForm />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/services/add-package">
          <AddServiceForm />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/services/edit-service/:serviceId">
          <AddServiceForm />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/services/edit-package/:serviceId">
          <AddServiceForm />
        </PrivateRoute>
        <PrivateRoute exact={true} path="/data-resources/services">
          <ServiceListing />
        </PrivateRoute>
        <PrivateRoute path="/data-resources/services/add-services/vendors">
          <ServiceVendors />
        </PrivateRoute>

        {/* *********************** Inventory ********************  */}
        <PrivateRoute path="/data-resources/inventory">
          <Inventory />
        </PrivateRoute>

        {/* *********************** Task Templates ********************  */}
        <PrivateRoute path="/data-resources/task-template">
          <TaskTemplate />
        </PrivateRoute>

        {/* *********************** Accounts ********************  */}
        <PrivateRoute path="/data-resources/accounts">
          <Accounts />
        </PrivateRoute>

        {/* *********************** Event ********************  */}
        <PrivateRoute exact={true} path="/events/:eventId/create">
          <EventsLayout />
        </PrivateRoute>
        <PrivateRoute path="/events">
          <EventsListing />
        </PrivateRoute>
        <PrivateRoute path="/planner-chat" exact={true}>
          <AdminChat />
        </PrivateRoute>
      </Switch>
    </>
  );
};

export default Routes;
