import React, { useEffect, useState } from 'react';
import { Button, Input, Topnav } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import FormTitle from '../Building/AddBuilding/FormTitle';
import CommonNavigation from '../../Common/CommonNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  addManagement,
  deleteManagement,
  getManagements,
  updateManagement,
} from '../../../redux/management/management.action';
import '../client.scss';
import { useFormDebounce } from '../../../hooks';
import {
  clearManagementForm,
  updateManagementForm,
} from '../../../redux/form/form.action';
import { loadState } from '../../../utils';
import DeleteElementPopup from '../../Common/DeleteElementPopup';
import { IFormState } from '../../../redux/form/form.state';
import { ILoaderState } from '../../../redux/loader/loader.state';
import { Management } from '../../../models';

const AddNewManagement: React.FC = () => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
  } = useForm<Management>();
  const dispatch = useDispatch();
  const params = useParams();
  const { push } = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [buildingName] = useState(query.get('buildingName'));
  const [buildingId] = useState(query.get('buildingId'));
  const managementId = parseInt(params['managementId'], 10);
  const { managementForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  //   const { managementForm } = useSelector((state) => state.forms);
  //   const { loading } = useSelector((state) => state.loading);
  const [managementData] = useState(
    managementId ? loadState('managementForm') : managementForm
  );
  const [showManagementDelete, setShowManagementDelete] = useState({
    showModal: false,
    isDelete: false,
  });

  const onSubmit = async (data: Management) => {
    if (buildingName) {
      await dispatch(addManagement(data));
      await dispatch(getManagements(0, 0, 'short', '', 1));
      push(
        buildingId
          ? `/data-resources/clients/buildings/${buildingId}`
          : `/data-resources/clients/buildings/add-building?buildingName=${buildingName}`
      );
      return;
    }
    if (!managementId) {
      await dispatch(addManagement(data));
    } else {
      await dispatch(updateManagement(managementId, data));
    }
    push('/data-resources/clients/management');
  };

  useFormDebounce(updateManagementForm, 1000, getValues, clearManagementForm);
  useEffect(() => {
    if (managementId) {
      reset(managementData);
    }
  }, [reset, managementId, managementData]);

  const onDeleteManagement = async () => {
    await dispatch(deleteManagement(managementId));
  };

  return (
    <>
      <Topnav headerTitle={`${managementId ? 'Edit' : 'Add New'} Management`} />
      <div className="mx-10 management-section mt-3">
        {buildingName && (
          <CommonNavigation
            linkTo={
              buildingId
                ? `/data-resources/clients/buildings/${buildingId}`
                : `/data-resources/clients/buildings/add-building?buildingName=${buildingName}`
            }
            header={`${buildingId ? 'Edit' : 'Add new'} building`}
            subHeader="Add new management"
          />
        )}

        {showManagementDelete.showModal && (
          <DeleteElementPopup
            isDelete={showManagementDelete.isDelete}
            modalState={showManagementDelete.showModal}
            modalClose={() =>
              setShowManagementDelete({ showModal: false, isDelete: false })
            }
            onDelete={onDeleteManagement}
            headerText="Are you sure you want to delete this Management?"
            subHeader="Management will be deactivated for 30 days before permanent deletion"
            buttonLabel="Delete Management"
            redirectValue="/data-resources/clients/management"
            modalClass="space_modal"
          />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Button
            label={`${buildingName ? 'Save and go back' : 'Save changes'}`}
            className={`${
              loading ? 'button_disable save-changes' : 'save-changes'
            }`}
            disabled={loading}
          />
          <div className={`mr-4 inline-flex ${!buildingName && 'pt-12'}`}>
            <FormTitle title="Basic Details" />
          </div>
          {errors?.['companyName'] && (
            <span className="error_management">
              Please fill required fields
            </span>
          )}
          <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
            <div>
              <Input
                isLabelShow={true}
                label="Management name"
                name="companyName"
                ref={register({ required: true })}
                isError={errors['companyName'] ? true : false}
                isMandatory={true}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Front desk number"
                name="frontDeskNumber"
                ref={register}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Company Website"
                name="companyWebsite"
                ref={register}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Address"
                name="address"
                ref={register}
              />
            </div>
          </div>

          <FormTitle title="Primary Contact" />
          <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
            <div>
              <Input
                isLabelShow={true}
                label="Name"
                name="primaryContactName"
                ref={register}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Designation"
                name="primaryContactDesignation"
                ref={register}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Phone Number"
                name="primaryContactNumber"
                ref={register}
              />
            </div>
            <div>
              <Input
                isLabelShow={true}
                label="Email Address"
                name="primaryEmail"
                isError={errors?.['primaryEmail']?.['message'] ? true : false}
                ref={register({
                  required: false,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'invalid email address',
                  },
                })}
              />
              {errors.primaryEmail && errors.primaryEmail.message}
            </div>
          </div>
        </form>
        {managementId > 0 && (
          <div className="delete_space">
            <label
              onClick={() => {
                setShowManagementDelete({ showModal: true, isDelete: true });
              }}
            >
              Delete Management
            </label>
            <span>
              Management will be deactivated for 30 days before permanent
              deletion
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default AddNewManagement;
