import React from 'react';
import { TextArea } from '@alfredclub/hom-planner-theme';
import FormTitle from './FormTitle';
import { Register } from '../../../../models/commonTypes';

const VendorDetails: React.FC<{
  register: Register;
}> = ({ register }) => {
  return (
    <>
      <FormTitle title="Vendor notes" />
      <div className="grid grid-cols-1 gap-x-2 gap-y-0 pb-8">
        <div>
          <TextArea
            isLabelShow={true}
            label="Admin notes"
            name="adminNotes"
            placeholder="Type a note to vendors assigned to this building."
            ref={register}
            labelClass="w-48"
          />
        </div>
        <div>
          <TextArea
            isLabelShow={true}
            label="Reminders"
            name="reminders"
            placeholder="Type any reminders to vendors assigned to this building."
            ref={register}
            labelClass="w-48"
          />
        </div>
      </div>
    </>
  );
};

export default VendorDetails;
