import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import ModalPopup from '../ReactModal/Modal';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { closeRegionModal } from '../../redux/modal/modal.action';
import { IRegionState } from '../../redux/region/region.state';
import { getImageUrl } from '../../utils';
import Cookies from 'universal-cookie';
import {
  clearEvents,
  getCategoryFilteredEvents,
  setCategoryFilters,
} from '../../redux/event';
import {
  getFilteredVendors,
  IVendorState,
  setFilteredVendors,
  setVendorTable,
} from '../../redux/vendor';
interface IFormInput {
  region: object[];
}
const FilterRegion: React.FC<any> = ({
  modalState,
  selectedRegion,
  setRegion,
  vendorStatus,
  date,
  tabName,
  setPage,
  isVendorFilter,
  setReset,
}: any) => {
  const { handleSubmit } = useForm<IFormInput>();
  const dispatch = useDispatch();
  const { regions } = useSelector(
    (state: { regions: IRegionState }) => state.regions
  );
  const { vendorFilters } = useSelector(
    (state: { vendors: IVendorState }) => state.vendors
  );
  const [regionList, setList] = useState([]);
  const onRegionClick = async (regionObj: object) => {
    setRegion([regionObj?.['region']]);
  };
  const cookies = new Cookies();
  const addUpdateCookies = () => {
    const regionArray = !cookies.get('regions')
      ? []
      : [...cookies.get('regions')];
    const regionExists = regionArray.filter(
      (item) => item.id === selectedRegion?.[0]?.['id']
    );
    if (selectedRegion.length > 0 && regionExists && regionExists.length <= 0) {
      regionArray.push(selectedRegion?.[0]);
    }
    if (regionArray.length > 5) {
      const index = regionArray?.[0];
      regionArray.splice(index, 1);
    }
    cookies.set('regions', regionArray);
  };
  const setTable = async (cState: boolean) => {
    await dispatch(setVendorTable(cState));
  };
  const onSubmit = async () => {
    setReset(true);
    addUpdateCookies();
    // for event filters
    if (!isVendorFilter) {
      await dispatch(
        setCategoryFilters({
          regionSelected:
            selectedRegion && selectedRegion.length > 0
              ? selectedRegion?.[0]?.['id']
              : [],
          vendorFiltered: vendorStatus ? vendorStatus : '',
        })
      );
      await dispatch(clearEvents());
      await dispatch(
        getCategoryFilteredEvents(
          tabName,
          0,
          date,
          selectedRegion && selectedRegion.length > 0
            ? selectedRegion?.[0]?.['id']
            : '',
          vendorStatus ? vendorStatus : ''
        )
      );
    } else {
      await dispatch(
        setFilteredVendors({
          regions:
            selectedRegion && selectedRegion.length > 0 ? selectedRegion : [],
          categoryTags:
            Object.keys(vendorFilters).length > 0 &&
            vendorFilters?.['categoryTags'] &&
            vendorFilters?.['categoryTags'].length > 0
              ? vendorFilters?.['categoryTags']
              : [],
        })
      );
      await setTable(false);
      await dispatch(
        getFilteredVendors(
          10,
          0,
          'short',
          selectedRegion && selectedRegion.length > 0
            ? selectedRegion?.[0]?.['id']
            : [],
          vendorFilters?.['categoryTags']?.[0]?.['id']
        )
      );
      await setTable(true);
    }
    await dispatch(closeRegionModal());
    setReset(false);
    setPage(0);
  };
  const clearSelectedRegion = async () => {
    setRegion([]);
    vendorStatus
      ? await dispatch(
          setCategoryFilters({
            regionSelected: [],
            vendorFiltered: vendorStatus ? vendorStatus : '',
          })
        )
      : await dispatch(
          setFilteredVendors({
            regions: [],
            categoryTags: vendorFilters?.['categoryTags'],
          })
        );
  };
  const onKeyUp = (e) => {
    // filter region list
    const post = regions.filter((item) =>
      item.regionName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setList([...post]);
  };
  useEffect(() => {
    setList(() => [...regions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line
    regions.length,
  ]);
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={async () => {
        await dispatch(closeRegionModal());
      }}
      className="region-modal pb-2"
      shouldCloseOnOverlayClick={false}
    >
      <label className="label">Select Market</label>
      <hr className="hr-border" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Search"
          isLabelShow={false}
          onInputChange={(e) => onKeyUp(e)}
          className="region-search-input"
          placeholder="Search market"
        />
        <ul className="block overflow-auto overflow-x-scroll h-60 region-items">
          {regionList.map((region, index) => {
            return (
              <div
                key={index}
                className="flex justify-between py-3"
                onClick={() => onRegionClick({ region })}
              >
                <PrimaryLabel
                  labelText={region?.['regionName']}
                  className="text-lg"
                />
                {selectedRegion?.[0]?.['id'] === region?.['id'] && (
                  <img
                    alt="region"
                    src={getImageUrl('Events/searchbuildingselected.svg')}
                  />
                )}
              </div>
            );
          })}
        </ul>
        <hr className="hr-border" />
        <div className="flex justify-between select-region-popup">
          <Button
            label="Clear"
            isArrowShow={false}
            className="underline clear-btn"
            onClick={clearSelectedRegion}
          />
          <Button label="Save" isArrowShow={false} className="save-btn" />
        </div>
      </form>
    </ModalPopup>
  );
};

export default FilterRegion;
