import React from 'react';

const FormTitle: React.FC<{
  title: string;
  subTitle?: string;
  subheaderClass?: string;
}> = ({ title, subTitle, subheaderClass }) => (
  <p>
    {title}
    <span className={subheaderClass}>{subTitle}</span>
  </p>
);

export default FormTitle;
