import React, { useState } from 'react';
import { getImageUrl } from '../../../utils';
import { useDispatch } from 'react-redux';
import {
  deleteServicePrice,
  getPackageServicePrice,
  getSimpleServicePrice,
  getSinglePriceDetail,
} from '../../../redux/service/services.action';
import DeletePopup from '../DeletePopup';
import { perAttendeeRange, pricingTypeOptions } from '../../../constants/index';
import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { PriceDetail } from '../../../models';
/*
  1. PriceListItem is cloned in Draggable list component
  2. Item is comming from Draggable list component
*/
const PriceListItem: React.FC<{
  item?: object;
  serviceId: number;
  serviceType: number;
  elementIds: any[];
  modalAction: (id: null) => void;
  setEdit: (isPriceEdit: boolean) => void;
}> = ({ item, serviceId, serviceType, elementIds, modalAction, setEdit }) => {
  const [showSetupDelete, setSetupDelete] = useState({
    showModal: false,
    isDelete: false,
  });

  const [deleteItem, setDeleteItem] = useState(0);
  const dispatch = useDispatch();

  const deletePrice = async () => {
    await dispatch(deleteServicePrice(deleteItem));
    if (serviceType > 1) {
      await dispatch(getPackageServicePrice(elementIds));
    } else {
      await dispatch(getSimpleServicePrice(Number(serviceId)));
    }
  };

  const regionsName =
    item['regions']?.length > 0
      ? item['regions'].map(({ regionName }) => regionName)
      : [];

  const pricingType = pricingTypeOptions.filter(
    (data) => data.value === item['pricingType']
  );

  const priceObj = pricingType ? pricingType[0]?.['label'].toLowerCase() : '';
  const filterRangeType = (obj) => {
    const rangeType = perAttendeeRange.filter(
      (data) => data.value === obj.memberRange
    );
    return rangeType?.[0]?.['range'];
  };
  const getPriceDetail = async (pricingId) => {
    await dispatch(getSinglePriceDetail(pricingId));
    modalAction(pricingId);
    setEdit(true);
  };
  return (
    <>
      {showSetupDelete.showModal && (
        <DeletePopup
          isDelete={showSetupDelete.isDelete}
          modalState={showSetupDelete.showModal}
          modalClose={() =>
            setSetupDelete({ showModal: false, isDelete: false })
          }
          onDelete={deletePrice}
          headerText="Are you sure you want to delete this pricing?"
          buttonLabel="Yes, delete"
          cancelButtonLabel="No, cancel"
        />
      )}
      <div className="price-list-item">
        <div className="flex justify-between">
          <p className="p_title">{regionsName.join(', ')}</p>
          <img
            onClick={() => {
              setDeleteItem(item['id']);
              setSetupDelete({
                showModal: true,
                isDelete: true,
              });
            }}
            src={getImageUrl(`data-resources/delete.svg`)}
            alt="delete"
            className="cursor-pointer"
          />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => getPriceDetail(item['id'])}
        >
          <div className="grid grid-cols-1 gap-x-2 gap-y-0">
            <p className="p_vendor">
              Type of pricing: <b>{priceObj}</b>
            </p>
          </div>
          {item['details'].map((priceItem: PriceDetail, index) => {
            return (
              <div key={index}>
                <PrimaryLabel
                  className="range-text"
                  labelText={filterRangeType(priceItem)}
                />
                <div className="grid grid-cols-3 gap-x-2 gap-y-0 mt-1">
                  <p className="p_description">
                    <img
                      src={getImageUrl(`data-resources/building-cost.svg`)}
                      alt="description"
                      className="pr-2 inline-flex"
                    />
                    Total cost to building: ${priceItem?.['buildingCost']}{' '}
                    {priceObj}
                  </p>
                  <p className="p_description">
                    <img
                      src={getImageUrl(`data-resources/vendor-cut.svg`)}
                      alt="description"
                      className="pr-2 inline-flex"
                    />
                    Vendor cut:{' '}
                    <b>
                      ${priceItem?.['vendorCut']} {priceObj}
                    </b>
                  </p>
                  <p className="p_description">
                    <img
                      src={getImageUrl(`data-resources/profit.svg`)}
                      alt="description"
                      className="pr-2 inline-flex"
                    />
                    HOM profit: ${priceItem?.['profit']} {priceObj}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PriceListItem;
