import { PrimaryLabel } from '@alfredclub/hom-planner-theme';
import React from 'react';
import { EVENT_DAY_FORMAT, eventStandardFormat } from '../../../constants';
import { ChatDetail } from '../../../models';
import { getDayAndTime, getImageUrl } from '../../../utils';

const EventDescription: React.FC<{
  isAdminChat?: boolean;
  eventDetail: ChatDetail;
}> = ({ isAdminChat, eventDetail }) => {
  return (
    <>
      <div
        className={`description-wrapper sm:container md:container lg:container xl:container md:mx-auto md:mt-${
          isAdminChat ? '4' : '12'
        } px-8 pt-4 md:px-8 lg:px-${isAdminChat ? '8' : '32'} lg:py-0`}
      >
        <PrimaryLabel labelText={'Online Event'} />

        <PrimaryLabel
          labelText={eventDetail.displayName}
          className="text-EventHeader md:text-HeaderFont custom-text-label block pb-4"
        />
        <PrimaryLabel
          labelText={`${eventDetail.rsvps} RSVPs, ${eventDetail.attendees} Attendees`}
          className="text-lg custom-text-label font-bold block pb-10"
        />
        <div className="event-info inline-grid gap-y-4 text-Color_5">
          <div className="inline-flex">
            <img src={getImageUrl('Vendor/Day.svg')} alt="day" />
            <span className="pl-4">
              {getDayAndTime(
                eventDetail.startDatetime,
                EVENT_DAY_FORMAT,
                eventDetail?.timezone?.zoneValue
              )}
            </span>
          </div>
          <div className="inline-flex">
            <img src={getImageUrl('Vendor/Time.svg')} alt="time" />
            <span className="pl-4">
              {getDayAndTime(
                eventDetail.startDatetime,
                eventStandardFormat,
                eventDetail?.timezone?.zoneValue
              )}{' '}
              -{' '}
              {getDayAndTime(
                eventDetail.endDatetime,
                eventStandardFormat,
                eventDetail?.timezone?.zoneValue
              )}{' '}
              {`(${eventDetail?.timezone?.shortName})`}
            </span>
          </div>
          <div className="inline-flex">
            <img src={getImageUrl('Vendor/Location.svg')} alt="venue" />
            <span className="pl-4">{` ${eventDetail.buildings}${
              eventDetail.buildings <= 1 ? ' Building' : ' Buildings'
            }`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDescription;
