import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadState } from '../../../utils';
import RecurringEventTasklist from './RecurringEventTaskList';
import SimpleEventTasklist from './SimpleEvent';
import { defaultTimezone } from '../../../constants';
import CommonLoader from '../../Common/CommonLoader';
import { addEventTasks, getEventTask } from '../../../redux/event';

const TaskManagementForm: React.FC<{ status: string }> = ({ status }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [showLoader] = useState(false);
  const recurringEventData = loadState('recurringSetupForm');
  const servicetaskListData = loadState('taskList');
  const eventId = parseInt(params['eventId'], 10);
  const [isRecurring] = useState(
    recurringEventData?.['recurring'] ? true : false
  );

  const { eventTasks } = useSelector((state) => state.events);
  useEffect(() => {
    const addMultipleTasksMethod = async () => {
      if (
        status === 'fetched' &&
        !servicetaskListData?.['data']?.[0]?.['eventRelated']
      ) {
        isRecurring
          ? await dispatch(
              addEventTasks(
                eventId,
                {
                  eventTasks: servicetaskListData?.['data'],
                },
                true,
                recurringEventData?.timezone?.zoneValue ?? defaultTimezone
              )
            )
          : await dispatch(
              addEventTasks(
                eventId,
                {
                  eventTasks: servicetaskListData?.['data'],
                },
                false,
                recurringEventData?.timezone?.zoneValue ?? defaultTimezone
              )
            );
        await dispatch(
          getEventTask(
            Number(eventId),
            isRecurring,
            recurringEventData?.timezone?.zoneValue ?? defaultTimezone
          )
        );
        return;
      }
    };
    addMultipleTasksMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  return (
    <>
      {status === 'fetched' ? (
        <>
          {isRecurring ? (
            <>
              <RecurringEventTasklist
                taskData={eventTasks?.data}
                recurring={isRecurring}
                eventId={eventId}
                eventTimeZone={
                  recurringEventData?.timezone?.zoneValue ?? defaultTimezone
                }
              />
            </>
          ) : (
            <SimpleEventTasklist
              taskData={eventTasks?.data?.[0]}
              recurring={isRecurring}
              eventId={eventId}
            />
          )}
          <CommonLoader loading={showLoader} />
        </>
      ) : (
        <CommonLoader loading={true} />
      )}
    </>
  );
};
export default TaskManagementForm;
