import * as vendorTypes from './vendor.constant';
import { IVendorState, initialVendorState } from './vendor.state';

export const vendorReducer = (
  state: IVendorState = initialVendorState,
  action: { type: string; page: number; payload: any; vendorCount: number }
): IVendorState => {
  switch (action.type) {
    case vendorTypes.GET_VENDORS:
      return {
        ...state,
        // vendors: {
        //   ...state.vendors,
        //   [action.page]: { data: [...action.payload], fetched: true },
        // },
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        vendorCount: action.vendorCount,
      };
    case vendorTypes.GET_ALL_VENDORS:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        allVendors: [...action.payload],
        vendorCount: action.vendorCount,
      };
    case vendorTypes.SEARCH_VENDORS_DATA:
      return {
        ...state,
        // vendors: {
        //   [action.page]: { data: [...action.payload], fetched: true },
        // },
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        vendorCount: action.vendorCount,
      };
    case vendorTypes.ADD_VENDOR_COMPANY:
      return {
        ...state,
        vendorData: action.payload,
      };
    case vendorTypes.GET_VENDOR_DETAIL:
      return {
        ...state,
        vendorData: action.payload,
      };
    case vendorTypes.GET_ALL_VENDORS_FOR_SUPER_HERO:
      return {
        ...state,
        vendorsForSuperHero: action.payload,
      };
    case vendorTypes.SET_FILTERED_VENDORS:
      return {
        ...state,
        vendorFilters: action.payload,
      };
    case vendorTypes.SET_VENDOR_TABLE:
      return {
        ...state,
        isTable: action.payload,
      };
    case vendorTypes.FETCHED_VENDOR_DETAIL:
      return {
        ...state,
        fetchedVendor: action.payload,
      };
    default:
      return state;
  }
};
