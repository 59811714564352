import React from 'react';
import {
  Button,
  Dropdown,
  Input,
  PrimaryLabel,
  TextArea,
} from '@alfredclub/hom-planner-theme';
import { getImageUrl } from '../../../utils';
import LabelWithIcon from '../EventSetup/LabelWithIcon';
import { useSelector, useDispatch } from 'react-redux';
import { getServiceDocuments } from '../../../redux/service/services.action';
import { Register, SetValue } from '../../../models';
import { ButtonType } from '../../../enums';

const VendorSettings: React.FC<{
  formErrors: object;
  register: Register;
  setValue: SetValue;
  fieldIndex: number;
  eventData: object;
  hideTitle?: boolean;
  weekday?: number;
  onEditRequirment?: () => void;
  isUpdateReq?: object;
  isRecurring?: boolean;
}> = ({
  register,
  setValue,
  fieldIndex,
  eventData,
  hideTitle,
  weekday,
  onEditRequirment,
  isUpdateReq,
  isRecurring,
}) => {
  const dispatch = useDispatch();
  const { serviceDocuments } = useSelector((state) => state.services);

  const onVendorDocumentChange = (arr) => {
    const newArray = [];
    arr.forEach((element) => {
      const index = newArray?.findIndex(
        (item) => item['id'] === element['value']
      );
      if (index !== -1) {
        newArray.splice(index, 1);
      } else if (element['__isNew__']) {
      } else {
        newArray.push({ id: element['value'], name: element['label'] });
      }
    });
    return newArray;
  };

  const vendorDocumentValue = (arr) => {
    const newArray = [];
    if (arr?.length > 0) {
      arr?.forEach((element) => {
        newArray.push({ value: element['id'], label: element['name'] });
      });
    }
    return newArray;
  };
  return (
    <>
      {!hideTitle && (
        <LabelWithIcon
          imgSrc={getImageUrl('Events/setupevent-1.svg')}
          labelText="Vendor Settings"
          className="img_label"
        />
      )}

      <div className={`${!hideTitle && 'mt-4'}`}>
        <PrimaryLabel
          className={hideTitle && 'text-lg font-medium'}
          labelText={!hideTitle ? 'Document required' : 'Vendor Requirements '}
        />
        <Dropdown
          asyncDropdown={false}
          isCreatable={true}
          isMulti={true}
          isLabelShow={hideTitle}
          labelKey="label"
          valueKey="value"
          label={!hideTitle ? 'Document Required' : 'Certificates Required'}
          options={serviceDocuments}
          onMenuOpen={async () => {
            if (serviceDocuments.length <= 0) {
              await dispatch(getServiceDocuments());
            }
          }}
          ref={() =>
            register(
              { name: `vendorAssign[${fieldIndex}].docList` },
              { required: false }
            )
          }
          onOptionChange={(e) => {
            if (e) {
              setValue(
                `vendorAssign[${fieldIndex}].docList`,
                onVendorDocumentChange(e)
              );
              if (!hideTitle) {
                onEditRequirment();
              }
            }
          }}
          placeholder=""
          value={
            eventData &&
            vendorDocumentValue(eventData?.['serviceRelatedData']['docList'])
          }
          resetValue={eventData ? true : false}
        />
      </div>

      <div className="mt-4">
        {!hideTitle && <PrimaryLabel labelText="Note to Vendor" />}
        <TextArea
          isLabelShow={hideTitle}
          label="Note to Vendor"
          name={`vendorAssign[${fieldIndex}].note`}
          placeholder=""
          ref={register}
          defaultValue={eventData && eventData?.['serviceRelatedData']['note']}
          onTextAreaChange={!hideTitle ? () => onEditRequirment() : () => {}}
        />
      </div>
      {!hideTitle && !isRecurring && (
        <div className="mt-4">
          <PrimaryLabel labelText="Vendor pay (in $)" />
          <Input
            label="Vendor pay"
            name={`vendorAssign[${fieldIndex}].vendorPay`}
            ref={register}
            onInputChange={(e) => {
              setValue(`vendorAssign[${fieldIndex}].vendorPay`, e.target.value);
              if (!hideTitle) {
                onEditRequirment();
              }
            }}
            value={eventData && eventData?.['serviceRelatedData']['vendorPay']}
          />
        </div>
      )}
      {!hideTitle && (
        <>
          <div className="hidden">
            <Input
              label=""
              name={`vendorAssign[${fieldIndex}].elementId`}
              ref={register({ required: false })}
              value={weekday ? weekday.toString() : null}
            />
          </div>
          <div className="mt-4">
            <Button
              label="Update to Vendor"
              className={`${
                isUpdateReq[fieldIndex] ? '' : 'update-vendor-disable'
              }`}
              type={ButtonType.SUBMIT}
            />
          </div>
        </>
      )}
    </>
  );
};

export default VendorSettings;
