export const GET_VENDORS = 'GET_VENDORS';
export const SEARCH_VENDORS_DATA = 'SEARCH_VENDORS_DATA';
export const ADD_VENDOR_COMPANY = 'ADD_VENDOR_COMPANY';
export const ADD_VENDOR_INDIVIDUAL = 'ADD_VENDOR_INDIVIDUAL';
export const GET_VENDOR_DETAIL = 'GET_VENDOR_DETAIL';
export const DELETE_VENDOR = 'DELETE_VENDOR';
export const GET_ALL_VENDORS = 'GET_ALL_VENDORS';
export const GET_ALL_VENDORS_FOR_SUPER_HERO = 'GET_ALL_VENDORS_FOR_SUPER_HERO';
export const SET_FILTERED_VENDORS = 'SET_FILTERED_VENDORS';
export const SET_VENDOR_TABLE = 'SET_VENDOR_TABLE';
export const FETCHED_VENDOR_DETAIL = 'FETCHED_VENDOR_DETAIL';
