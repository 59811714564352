import { IUpdateMessage } from './../../models/chat';
import { IChatToken, IChatTokenResponse } from './../../models';
import { AxiosResponse } from 'axios';
import { http } from '../common/http.service';
import { EVENTS_BASE_URL } from '../../constants/index';

export const getChatTokenApi = (): Promise<IChatToken> => {
  return http
    .get(`${EVENTS_BASE_URL}/planner-chat`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<IChatTokenResponse>) => {
      return response.data.data;
    });
};

export const updateChatMessageApi = (data: IUpdateMessage) => {
  return http
    .put(`${EVENTS_BASE_URL}/planner-chat`, data, { errorHandler: true })
    .then((response) => {
      return response.data?.['data'];
    });
};
