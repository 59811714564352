import React, { useState } from 'react';
import ModalPopup from '../../../ReactModal/Modal';
import { Button, PrimaryLabel, TextArea } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import '../AddNewCompany/Companies.scss';
import { saveBuildingBlocksLocaly } from '../../../../redux/buildingblocks/buildingblocks.action';
import { useDispatch } from 'react-redux';

interface IFormInput {
  companyName: string;
}
const BuildingAddCompanies: React.FC<{
  modalState: boolean;
  onClosePopup: (value: []) => any;
  buildingBlockData: any;
  addUnitForm: boolean;
  CommonLayoutCheck: boolean;
}> = ({
  modalState,
  onClosePopup,
  buildingBlockData,
  addUnitForm,
  CommonLayoutCheck,
}: any) => {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm<IFormInput>();
  const [isOpenPop, setOpenPopup] = useState(modalState);
  const errorMessage =
    buildingBlockData?.buildingType === 'C'
      ? 'Please add company'
      : 'Please add units';
  const history = useHistory();
  const onSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleSubmit(async (data: IFormInput) => {
      const blocksArray = data.companyName.split(',');
      const newBlockArray = [];
      for (const row of blocksArray) {
        newBlockArray.push({ name: row, dashboardAccess: false });
      }
      if (
        addUnitForm ||
        (CommonLayoutCheck && buildingBlockData?.buildingType === 'R')
      ) {
        setOpenPopup(false);
        onClosePopup(newBlockArray);
        return;
      }
      const buildingBlockLocalObj = {
        newBlockArray: { newBlockArray },
        buildingType: buildingBlockData?.buildingType,
        buildingId: buildingBlockData?.building?.id,
        buildingName: buildingBlockData?.building?.name,
        editBlock: false,
        blockId: null,
      };
      dispatch(saveBuildingBlocksLocaly(buildingBlockLocalObj));
      setOpenPopup(false);
      onClosePopup();
      history.push(
        `/data-resources/clients/companies-units/${
          buildingBlockData?.buildingType === 'C'
            ? 'add-companies'
            : 'add-blocks'
        }?isEdit=${
          buildingBlockData?.building?.buildingMode ? true : false
        }${`${
          buildingBlockData?.building.id
            ? `&buildingId=${buildingBlockData?.building.id}`
            : ''
        }`}`
      );
    })(e);
  };
  const closeModalPopup = () => {
    setOpenPopup(false);
    onClosePopup();
  };

  return (
    <ModalPopup
      isOpen={isOpenPop}
      onRequestClose={() => closeModalPopup()}
      className="modal-height transform-modal"
      shouldCloseOnOverlayClick={false}
    >
      <PrimaryLabel
        labelText={`${
          buildingBlockData?.buildingType === 'C'
            ? 'Add Companies (Comma-separated)'
            : 'Add Units (Comma-separated)'
        }`}
      />
      <PrimaryLabel labelText="*" className="text-red-500" />
      <form onSubmit={onSubmit}>
        <TextArea
          label=""
          placeholder={`${
            buildingBlockData?.buildingType === 'C'
              ? 'Enter company name'
              : 'Add Units'
          }`}
          name="companyName"
          ref={register({ required: true })}
          className="textarea-popup"
        />
        {errors.companyName && <div className="error">{errorMessage}</div>}
        <Button label="Continue" className="save-button" />
      </form>
    </ModalPopup>
  );
};

export default BuildingAddCompanies;
