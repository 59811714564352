import { Pagination } from '../../models';
import { Map } from 'immutable';

export interface IStaffState extends Pagination {
  staff: object;
  staffCount: number;
  staffData: object;
}

export const initialStaffState: IStaffState = {
  staff: {},
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  staffCount: 0,
  staffData: null,
};
