import {
  Dropdown,
  Input,
  PrimaryLabel,
  TextArea,
} from '@alfredclub/hom-planner-theme';
import React, { useState } from 'react';
import {
  getImageUrl,
  getUploadedImageUrl,
  splitImageName,
} from '../../../utils';
import LabelWithIcon from './LabelWithIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEventServiceList,
  getElementsByService,
} from '../../../redux/event/event.action';
import {
  getServiceDetail,
  getPackageElementList,
  clearPackageElementList,
} from '../../../redux/service/services.action';
import {
  IProductionTeamProps,
  Register,
  SetValue,
  TinyObject,
} from '../../../models';
import { IEventsState } from '../../../redux/event';
import AnchorElement from '../../Common/AnchorElement';
import FileInput from '../../Common/FileInput';
import ElementProdTeam from './ElementProdTeam';
import { updateEventSetupForm } from '../../../redux/form';
import { imageUpload } from '../../../redux/common';

const SetupStreaming: React.FC<{
  formErrors: object;
  register: Register;
  setValue: SetValue;
  buildingId: number;
  eventData: object;
  virtual: boolean;
  isEdit: boolean;
  isPast: boolean;
  onFieldInputChange: (
    key: string,
    value: string | TinyObject | number | boolean
  ) => void;
  onAddProductionTeam: any;
  reset: (
    values?: Record<string, any>,
    omitResetState?: Record<string, boolean>
  ) => void;
  fields: IProductionTeamProps[];
  unregister: Register;
  onDeleteProdTeam: (index: number) => void;
  setFieldArray: any;
  onChange: (
    e: HTMLInputElement | { value: string | object },
    index: number
  ) => void;
}> = ({
  register,
  setValue,
  formErrors,
  eventData,
  virtual,
  isPast,
  onFieldInputChange,
  reset,
  onAddProductionTeam,
  fields,
  unregister,
  isEdit,
  onDeleteProdTeam,
  setFieldArray,
  onChange,
}) => {
  const dispatch = useDispatch();
  const [selectedEvent, setEventType] = useState(null);
  const [apiStatus, apiCallStatus] = useState<boolean>(false);
  const [serviceId, setServiceId] = useState<number | null>(
    eventData?.['service']?.['id'] ?? null
  );
  const [selectedElements, setSelectedElements] = useState(
    eventData?.['serviceElements']
      ? eventData?.['serviceElements']?.filter(
          ({ isVendorType }) => !isVendorType
        )
      : []
  );

  const [toggleState, setToggleState] = useState<boolean>(
    eventData?.['productionTeamExist'] ?? eventData?.['productionTeamRequired']
  );

  const { eventServices, eventElements } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  eventServices?.sort((a, b) => a.name.localeCompare(b.name));
  eventElements?.sort((a, b) => a.name.localeCompare(b.name));

  const selectEvent = async () => {
    const serviceDetails = await dispatch(getServiceDetail(serviceId));
    window.open(
      `/data-resources/services/edit-${
        serviceDetails ? 'package' : 'service'
      }/${serviceId}?tab=${serviceDetails ? 'elements' : 'setup'}&serviceType=${
        serviceDetails ? '2' : '1'
      }`,
      '_blank'
    );
  };

  // Remove image from control
  const onRemoveImage = async (name: string) => {
    onFieldInputChange(name, { id: null });
    reset(eventData); // File list error resolved
  };

  // Upload event image on image selected
  const handleFileUpload = async (e) => {
    e.preventDefault();
    const eventImage = new FormData();
    eventImage.append('file', e.target.files[0]);
    const fileData = await dispatch(imageUpload(eventImage));
    await dispatch(
      updateEventSetupForm({
        ...eventData,
        eventImage: { id: fileData?.['id'], file: fileData?.['file'] },
      })
    );
  };

  return (
    <div className="col-span-2">
      <div className="setup_event">
        <LabelWithIcon
          imgSrc={getImageUrl('Events/setupevent-1.svg')}
          labelText="Event Details"
          className="img_label"
        />
        <div className="grid grid-cols-2 gap-x-2 gap-y-0">
          <div className="mt-4">
            <div className="flex justify-between dropdown_class">
              <div>
                <span className="label_text">Select Event</span>
                <span className="text-red-500">*</span>
              </div>
              {serviceId && (
                <img
                  src={getImageUrl('Events/activeLink.svg')}
                  alt="icon"
                  onClick={selectEvent}
                  className="cursor-pointer"
                />
              )}
            </div>
            <Dropdown
              asyncDropdown={false}
              labelKey="name"
              valueKey="id"
              isLabelShow={false}
              label="Event Service"
              options={eventServices}
              onMenuOpen={async () => {
                apiCallStatus(true);
                if (eventServices?.length <= 0) {
                  await dispatch(
                    getEventServiceList(
                      eventData?.['serviceCategory']['id'],
                      Number(virtual),
                      Number(eventData?.['recurring'])
                    )
                  );
                }
                apiCallStatus(false);
              }}
              loading={apiStatus}
              ref={() => register({ name: 'service.id' }, { required: true })}
              isError={formErrors['service']}
              onOptionChange={async (e) => {
                setSelectedElements([]);
                const event = {
                  id: e['id'],
                  name: e['name'],
                };
                setServiceId(e['id']);
                onFieldInputChange('service', event);
                onFieldInputChange(
                  'displayName',
                  e['displayName'] ?? 'Event Planning'
                );
                onFieldInputChange('description', e['description']);
                setEventType(e);
                setValue('service.id', event.id);
                setValue('displayName', e['displayName']);
                setValue('description', e['description']);
                setValue('maxCapacity', e['maxAttendees']);
                setValue('productionTeamRequired', e['productionTeamRequired']);
                setToggleState(e['productionTeamRequired']);
                setFieldArray([]);
                await dispatch(
                  updateEventSetupForm({
                    ...eventData,
                    productionTeamRequired: e['productionTeamRequired'],
                    productionTeams: [],
                  })
                );
                if (e['productionTeamRequired']) {
                  const returnValue = await dispatch(
                    getElementsByService(
                      e['id'],
                      Number(e['productionTeamRequired'])
                    )
                  );
                  const elementsAssociated = returnValue?.map((data: any) => ({
                    id: data?.['vendorType']?.['id'],
                    name: data?.['vendorType']?.['name'],
                  }));
                  setFieldArray(elementsAssociated);
                  onFieldInputChange(
                    'productionTeamExist',
                    returnValue.length > 0 ? true : false
                  );
                  onFieldInputChange(
                    'productionTeamRequired',
                    returnValue.length > 0 ? true : false
                  );
                  onFieldInputChange('productionTeams', elementsAssociated);
                  setValue(`productionTeams`, e);
                }
                if (e['isPackage']) {
                  const packageElement = await dispatch(
                    getPackageElementList(e['id'])
                  );
                  setSelectedElements(packageElement);
                  await dispatch(
                    updateEventSetupForm({
                      ...eventData,
                      serviceElements: packageElement,
                    })
                  );
                  setValue('serviceElements', packageElement);
                } else {
                  await dispatch(clearPackageElementList());
                  setSelectedElements([event]);
                  await dispatch(
                    updateEventSetupForm({
                      ...eventData,
                      serviceElements: [event],
                    })
                  );
                }
              }}
              placeholder=""
              value={
                eventData && eventData?.['service']?.['id']
                  ? {
                      name: eventData?.['service']?.['name'],
                      id: eventData?.['service']?.['id'],
                    }
                  : {
                      name: 'No template selected',
                      id: null,
                    }
              }
              disabled={isPast}
              classNamePrefix="select-title"
            />
          </div>
          <div className="mt-4">
            <PrimaryLabel labelText="Event Display Title" />
            <PrimaryLabel labelText="*" className="text-red-500" />
            <Input
              label=""
              placeholder="Type here"
              name="displayName"
              ref={register({ required: true })}
              isError={formErrors['displayName']}
              onInputBlur={(e) =>
                onFieldInputChange('displayName', e.target.value)
              }
              value={
                selectedEvent?.['displayName'] ?? eventData?.['displayName']
              }
              disabled={isPast}
            />
          </div>
        </div>
        <div className="mt-4">
          <PrimaryLabel labelText="Event Description" />
          <TextArea
            label=""
            placeholder="Add description"
            isLabelShow={false}
            ref={register}
            name="description"
            onTextAreaChange={(e) =>
              onFieldInputChange('description', e.target.value)
            }
          />
        </div>
        <div className="mt-4">
          <PrimaryLabel labelText="Elements" />
          <Dropdown
            asyncDropdown={false}
            labelKey="name"
            valueKey="id"
            isLabelShow={false}
            label="Elements"
            options={eventElements}
            onMenuOpen={async () => {
              apiCallStatus(true);
              if (eventElements?.length <= 0) {
                await dispatch(
                  getEventServiceList(
                    eventData?.['serviceCategory']['id'],
                    Number(virtual),
                    Number(eventData?.['recurring'])
                  )
                );
              }
              apiCallStatus(false);
            }}
            loading={apiStatus}
            ref={() =>
              register({ name: 'serviceElements' }, { required: false })
            }
            onOptionChange={async (e) => {
              setSelectedElements(e);
              setValue('serviceElements', e);
              await dispatch(
                updateEventSetupForm({
                  ...eventData,
                  serviceElements: e,
                })
              );
            }}
            placeholder=""
            sectionClass="control-space"
            isMulti={true}
            value={selectedElements}
            resetValue={selectedElements?.length ? true : false}
            disabled={isPast}
          />
        </div>
        <div className="grid grid-cols-2 gap-x-2 gap-y-0">
          <div className="mt-3">
            <div className="flex justify-between">
              <PrimaryLabel labelText="Poster link" className="block" />
              <AnchorElement link={eventData?.['marketingLinks']} />
            </div>
            <Input
              label=""
              placeholder="Paste link here"
              name="marketingLinks"
              ref={register}
              onInputBlur={(e) =>
                onFieldInputChange('marketingLinks', e.target.value)
              }
            />
          </div>
          <div className="mt-2">
            <PrimaryLabel labelText="Event Card Image" />
            {!eventData?.['eventImage']?.['file'] ? (
              <FileInput
                label="Event Card Image"
                isLabelShow={false}
                inputSectionClass="m-0"
                ref={register}
                onInputChange={(e) => {
                  handleFileUpload(e);
                }}
              />
            ) : (
              <Input
                isLabelShow={false}
                isAnchor={true}
                anchorHref={getUploadedImageUrl(
                  eventData?.['eventImage']?.['file']
                )}
                label=""
                inputSectionClass="fileUpload"
                anchorText={`${splitImageName(
                  eventData?.['eventImage']?.['file']
                )}`}
                onRemoveImage={() => onRemoveImage('eventImage')}
              />
            )}
          </div>
        </div>
        <ElementProdTeam
          formErrors={formErrors}
          register={register}
          setValue={setValue}
          onAddProductionTeam={onAddProductionTeam}
          fields={fields}
          unregister={unregister}
          isEdit={isEdit}
          onDeleteProdTeam={onDeleteProdTeam}
          onFieldInputChange={onFieldInputChange}
          setToggleState={setToggleState}
          toggleState={toggleState}
          onChange={onChange}
          isPast={isPast}
        />
      </div>
    </div>
  );
};

export default SetupStreaming;
