import React from 'react';
import { Input } from '@alfredclub/hom-planner-theme';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import FileInput from '../../Common/FileInput';
import { removeImage } from '../../../redux/common/common.action';
import { useDispatch } from 'react-redux';
import { getUploadedImageUrl, loadState, splitImageName } from '../../../utils';
import { Register } from '../../../models';

const ServiceDescription: React.FC<{
  register: Register;
  serviceData: object;
  refreshServiceSetup: any;
  reset: (
    values?: Record<string, any>,
    omitResetState?: Record<string, boolean>
  ) => void;
}> = ({ register, serviceData, refreshServiceSetup, reset }) => {
  const dispatch = useDispatch();

  const onRemoveImage = async (name: string) => {
    await dispatch(removeImage({ [name]: {} }, 'setupForm'));
    reset(loadState('serviceSetupForm')); // File list error resolved
    refreshServiceSetup(loadState('serviceSetupForm')); // Refresh New state
  };

  return (
    <div className={`mt-12`}>
      <FormTitle title="Description" />
      <div className="grid grid-cols-1 gap-x-2 gap-y-0">
        <div>
          <Input
            isLabelShow={true}
            label="COS description"
            name="cosDescription"
            ref={register}
            placeholder="Client-facing description"
            labelClass="text_area_label"
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="User App description"
            name="userAppDescription"
            ref={register}
            placeholder="Tenant-facing description"
            labelClass="text_area_label"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          {!serviceData?.['eventImage']?.['file'] ? (
            <FileInput
              label="Event Card Image"
              isLabelShow={true}
              inputSectionClass="m-0"
              ref={register}
              name="eventImage.id"
            />
          ) : (
            <Input
              isLabelShow={true}
              isAnchor={true}
              anchorHref={getUploadedImageUrl(
                serviceData?.['eventImage']?.['file']
              )}
              label="Event Card Image"
              labelClass=""
              anchorText={`${splitImageName(
                serviceData?.['eventImage']?.['file']
              )}`}
              onRemoveImage={() => onRemoveImage('eventImage')}
            />
          )}
        </div>
        <div>
          {!serviceData?.['cosImage']?.['file'] ? (
            <FileInput
              label="COS Image"
              isLabelShow={true}
              inputSectionClass="m-0"
              ref={register}
              name="cosImage.id"
            />
          ) : (
            <Input
              isLabelShow={true}
              isAnchor={true}
              anchorHref={getUploadedImageUrl(
                serviceData?.['cosImage']?.['file']
              )}
              label="COS Image"
              labelClass=""
              anchorText={`${splitImageName(
                serviceData?.['cosImage']?.['file']
              )}`}
              onRemoveImage={() => onRemoveImage('cosImage')}
            />
          )}
        </div>
        <div>
          {!serviceData?.['eventPoster']?.['file'] ? (
            <FileInput
              label="Event Poster"
              isLabelShow={true}
              inputSectionClass="m-0"
              ref={register}
              name="eventPoster.id"
            />
          ) : (
            <Input
              isLabelShow={true}
              isAnchor={true}
              anchorHref={getUploadedImageUrl(
                serviceData?.['eventPoster']?.['file']
              )}
              label="Event Poster"
              labelClass=""
              anchorText={`${splitImageName(
                serviceData?.['eventPoster']?.['file']
              )}`}
              onRemoveImage={() => onRemoveImage('eventPoster')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceDescription;
