import React, { useState } from 'react';
import { Button, Input, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import ModalPopup from '../ReactModal/Modal';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  updateAccount,
  resetErrorState,
  mergeAccount,
} from '../../redux/account/account.action';
import './ChangeEmailPopup.scss';
import { apiStatusCheck } from '../../utils';

interface IFormInput {
  email: string;
}

const ChangeEmailPopup: React.FC<{
  modalState: boolean;
  modalClose: () => void;
  headerText: string;
  subHeader: string;
  buttonLabel: string;
  emailExistText: string;
  isEmailExist: boolean;
  accountId: number;
}> = ({
  modalState,
  modalClose,
  headerText,
  subHeader,
  buttonLabel,
  emailExistText,
  isEmailExist,
  accountId,
}) => {
  const { register, errors, handleSubmit } = useForm<IFormInput>({
    defaultValues: {
      email: '',
    },
  });
  const dispatch = useDispatch();
  const [newEmail, setNewEmail] = useState({ email: '', accountId: 0 });
  const onSubmit = async (data: object) => {
    setNewEmail({ email: data['email'], accountId });
    if (!isEmailExist) {
      const updatedAccount = await dispatch(updateAccount(accountId, data));
      if (apiStatusCheck(updatedAccount)) {
        modalClose();
        setNewEmail({ email: '', accountId: 0 });
      }
      return;
    }
    await dispatch(mergeAccount(newEmail.accountId, { email: newEmail.email }));
    await modalClose();
  };
  const onClose = async () => {
    modalClose();
    await dispatch(resetErrorState());
  };
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClose}
      className="account_email"
    >
      {isEmailExist && (
        <>
          <PrimaryLabel
            className=""
            labelText={`New Email: ${newEmail.email}`}
          />
          <br />
        </>
      )}
      <PrimaryLabel
        className={`email_header ${isEmailExist && 'color_red'}`}
        labelText={`${isEmailExist ? emailExistText : headerText}`}
      />
      <br />
      {isEmailExist ? (
        <>
          <PrimaryLabel
            className={`email_exist_header_1`}
            labelText={`The user will be able to use ${newEmail.email} to login.`}
          />
          <PrimaryLabel
            className={`email_exist_header_2`}
            labelText={`Would you like to continue?`}
          />
        </>
      ) : (
        <PrimaryLabel className={`email_sub_header`} labelText={subHeader} />
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${!isEmailExist && 'form_class'}`}
      >
        {!isEmailExist && (
          <div>
            <PrimaryLabel labelText="Enter New Email Address" />
            <Input
              label=""
              placeholder="Type here"
              name="email"
              inputType="email"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
            />
          </div>
        )}
        {!isEmailExist && errors.email && (
          <div className="error">Please enter email</div>
        )}
        <Button
          label={buttonLabel}
          className={`update_button ${
            isEmailExist && 'yes_merge'
          } cursor-pointer`}
          isArrowShow={false}
        />
      </form>
    </ModalPopup>
  );
};

export default ChangeEmailPopup;
