import React, { useState } from 'react';
import { Input, Dropdown } from '@alfredclub/hom-planner-theme';
import { useDispatch, useSelector } from 'react-redux';
import FormTitle from './FormTitle';
import {
  getRegions,
  getTimezone,
} from '../../../../redux/region/regions.action';
import { updateBuildingForm } from '../../../../redux/form/form.action';
import { Register, SetValue } from '../../../../models';
import { Building } from '../../../../models/building';
import { IRegionState } from '../../../../redux/region';

const BasicDetails: React.FC<{
  formErrors: Record<string, object>;
  register: Register;
  setValue: SetValue;
  setBuildingName: (value: string) => void;
  buildingData: Building;
  getValues: (payload?: string | string[]) => object;
}> = ({
  formErrors,
  register,
  setValue,
  setBuildingName,
  buildingData,
  getValues,
}) => {
  const dispatch = useDispatch();
  const { regions, timezones } = useSelector(
    (state: { regions: IRegionState }) => state.regions
  );
  const [regionLoading, setLoading] = useState<boolean>(false);
  const [zoneLoading, setZoneLoading] = useState<boolean>(false);

  return (
    <div className="mt-20">
      <FormTitle title="Basic Details" />
      {Object.keys(formErrors).length > 0 && (
        <span className="error-message">Please fill required fields</span>
      )}
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          <Input
            isLabelShow={true}
            label="Building name"
            name="name"
            ref={register({ required: true })}
            onInputChange={(e) => {
              setBuildingName(e.currentTarget.value);
            }}
            isError={formErrors['name'] ? true : false}
            isMandatory={true}
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Office Number"
            name="frontDeskNumber"
            ref={register({ required: true })}
            isError={formErrors['frontDeskNumber'] ? true : false}
            isMandatory={true}
          />
        </div>
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="regionName"
            valueKey="id"
            isLabelShow={true}
            label="Market"
            options={regions}
            onMenuOpen={async () => {
              setLoading(true);
              if (regions.length === 0) {
                await dispatch(getRegions(0, 0, 'short', 1));
              }
              setLoading(false);
            }}
            loading={regionLoading}
            ref={register({ name: 'region' })}
            onOptionChange={async (e) => {
              const region = {
                id: e['id'],
                regionName: e['regionName'],
              };
              await dispatch(updateBuildingForm(getValues()));
              setValue('region', region);
            }}
            placeholder="Select"
            value={
              buildingData && {
                regionName: buildingData?.['region']?.['regionName'],
                id: buildingData?.['region']?.['id'],
              }
            }
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Address"
            name="address"
            ref={register({ required: true })}
            isError={formErrors['address'] ? true : false}
            isMandatory={true}
          />
        </div>
        <div>
          <Dropdown
            asyncDropdown={false}
            labelKey="fullName"
            valueKey="id"
            isLabelShow={true}
            label="Timezone"
            options={timezones}
            onMenuOpen={async () => {
              setZoneLoading(true);
              if (timezones.length === 0) {
                await dispatch(getTimezone());
              }
              setZoneLoading(false);
            }}
            loading={zoneLoading}
            ref={register({ name: 'timezone' })}
            onOptionChange={async (e) => {
              const timezone = {
                id: e['id'],
                shortName: e['shortName'],
              };
              await dispatch(updateBuildingForm(getValues()));
              setValue('timezone', timezone);
            }}
            placeholder="Select"
            value={
              buildingData && {
                fullName: buildingData?.['timezone']?.['fullName'],
                id: buildingData?.['timezone']?.['id'],
              }
            }
          />
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Parking details"
            name="parkingDetails"
            ref={register({ required: false })}
            isError={formErrors['parkingDetails'] ? true : false}
            isMandatory={false}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
