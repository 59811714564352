import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FormTitle from './FormTitle';
import { Table, Loader } from '@alfredclub/hom-planner-theme';
import { useDispatch } from 'react-redux';
import { getSpaceDetail } from '../../../../redux/space/space.action';

const AddSpace: React.FC<{
  buildingName: string;
  buildingData: object;
  isEdit: boolean;
  buildingId: number;
}> = ({ buildingName, buildingData, isEdit, buildingId }) => {
  const dispatch = useDispatch();
  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);
  const handleOnCellClick = async (e: any) => {
    if (e.row?.values?.id) {
      setLoadingState(true);
      setRowState(e.row.values.id);
      await dispatch(getSpaceDetail(e.row.values.id));
      setLoadingState(false);
      setRowState(null);
      window.open(
        `/data-resources/clients/spaces/${e.row.values.id}`,
        '_blank'
      );
    }
  };

  return (
    <>
      <FormTitle title={`Spaces`} />
      <div className="grid grid-cols-1 gap-x-2 gap-y-0 pb-8">
        <div>
          {buildingData['buildingSpaces'].length > 0 && (
            <Table
              tableColumns={[
                {
                  Header: 'Sno',
                  accessor: 'id',
                  Cell: (row: any) => {
                    return <div>{row.row.index + 1}</div>;
                  },
                },
                {
                  Header: 'Spaces',
                  accessor: (row: any) => (
                    <>
                      {row.name}
                      <Loader
                        color="#e89a89"
                        position="center"
                        loading={showLoader && setRowClick === row.id}
                        size="0.75rem"
                        className={`${
                          showLoader && setRowClick === row.id && 'row_loader'
                        }`}
                      />
                    </>
                  ),
                  className: 'tableAnchor removeCursor', // use this class for highlight the column value
                },
                {
                  Header: 'Max Capacity',
                  accessor: 'maxCapacity',
                },
                {
                  Header: 'Goal Capacity',
                  accessor: 'goalCapacity',
                },
                {
                  Header: 'Location',
                  accessor: 'location',
                },
              ]}
              onCellClick={(e) => handleOnCellClick(e)}
              tableData={buildingData['buildingSpaces']}
              showPagination={false}
              totalRecordsCount={buildingData['buildingSpaces'].length}
              onCellMouseEnter={() => {}}
            />
          )}
          <Link
            className="custom_label float-none"
            to={
              isEdit
                ? `/data-resources/clients/spaces/add-space?buildingName=${buildingName}&buildingId=${buildingId}`
                : `/data-resources/clients/spaces/add-space?buildingName=${buildingName}`
            }
          >
            +Add Space
          </Link>
        </div>
      </div>
    </>
  );
};

export default AddSpace;
