import React, { useState } from 'react';
import { CompanyTable } from '@alfredclub/hom-planner-theme';
import {
  Button,
  Dropdown,
  PrimaryLabel,
  Loader,
} from '@alfredclub/hom-planner-theme';
import '../../../Clients/Companies/AddNewCompany/Companies.scss';
import BuildingAddCompanies from './BuildingAddCompanies';
import DeleteCompanyPopup from '../CompanyPopup/DeleteCompanyPopup';
import {
  addBuildingBlocks,
  saveBuildingBlocksLocaly,
} from '../../../../redux/buildingblocks/buildingblocks.action';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  updateUnitCount,
  updatecompanyForminUnits,
} from '../../../../redux/form/form.action';
import CommonNavigation from '../../../Common/CommonNavigation';

const AddUnitForm = (props: {
  unitsSlicedArray: any;
  buildingId: number;
  blockCount: number;
  buildingName: string;
  buildingType: string;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openAddUnitModal, setAddUnitModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteUnitModal, setDeleteUnitModal] = useState(false);
  const [getDeleteUnitObj, setDeleteUnitObj] = useState({
    value: {},
    parentIndex: 0,
    index: 0,
  });
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [isEdit] = useState(query.get('isEdit'));
  const [building] = useState(query.get('buildingId'));
  const [unitsBlockArray, setUnitsBlockArray] = useState({
    buildingBlockObj: props.unitsSlicedArray,
  });
  const onClickDeleteIcon = (
    values: any,
    parentIndexs: number,
    indexs: number
  ) => {
    setDeleteUnitObj({
      value: values,
      parentIndex: parentIndexs,
      index: indexs,
    });
    setDeleteUnitModal(true);
  };
  const onCloseUnitPopup = (newarray) => {
    if (newarray) {
      const unitsArray = [...unitsBlockArray.buildingBlockObj];
      const newUnitArray = newarray;
      if (newUnitArray?.length) {
        for (const row of newUnitArray) {
          unitsArray[unitsArray.length - 1].push(row);
        }
      }
      setUnitsBlockArray({ ...unitsBlockArray, buildingBlockObj: unitsArray });
    }
    setAddUnitModal(false);
  };
  const onCloseDeleteUnitPopup = () => {
    setDeleteUnitModal(false);
    setDeleteUnitObj({ value: {}, parentIndex: 0, index: 0 });
  };
  const deleteblocks = () => {
    setDeleteUnitModal(false);
    const parentIndex = getDeleteUnitObj.parentIndex;
    const index = getDeleteUnitObj.index;
    const newBlockArray = [...unitsBlockArray.buildingBlockObj];
    newBlockArray[parentIndex].splice(index, 1);
    setUnitsBlockArray({ ...unitsBlockArray, buildingBlockObj: newBlockArray });
  };
  const onSubmitBlocks = async () => {
    const finalUnitsArray = [].concat.apply(
      [],
      unitsBlockArray.buildingBlockObj
    );
    if (props?.buildingId) {
      setLoading(true);
      await dispatch(addBuildingBlocks(props.buildingId, finalUnitsArray));
      setLoading(false);
      history.push(
        `/data-resources/clients/companies-units/?buildingId=${props.buildingId}`
      );
    } else {
      const buildingBlockLocalObj = {
        newBlockArray: { finalUnitsArray },
        buildingType: props.buildingType,
        buildingName: props.buildingName,
        editBlock: false,
        blockId: null,
      };
      setLoading(true);
      await dispatch(updatecompanyForminUnits(finalUnitsArray));
      setLoading(false);
      dispatch(updateUnitCount(finalUnitsArray.length));
      dispatch(saveBuildingBlocksLocaly(buildingBlockLocalObj));
      if (building) {
        history.push(`/data-resources/clients/buildings/${building}`);
      } else {
        history.push(
          `/data-resources/clients/buildings/add-building/?buildingName=${props.buildingName}`
        );
      }
    }
  };
  const buildingBlockData = {};
  return (
    <>
      {!props?.buildingId && (
        <CommonNavigation
          linkTo={`/data-resources/clients/buildings/add-building?buildingName=${props.buildingName}`}
          header={`${isEdit === 'true' ? 'Edit Building' : 'Add new building'}`}
          subHeader="Companies/Units"
        />
      )}
      <Button
        label="Save and go back"
        className={`${
          loading ? 'button_disable save-changes' : 'save-changes'
        }`}
        onClick={onSubmitBlocks}
        disabled={loading}
      />
      {loading && (
        <Loader
          color="#e89a89"
          position="center"
          loading={true}
          size="0.75rem"
        />
      )}
      <PrimaryLabel className="flex" labelText="Building name" />
      <Dropdown
        asyncDropdown={false}
        labelKey="name"
        options={[]}
        valueKey="id"
        sectionClass="building-dropdown"
        isLabelShow={false}
        label="Building"
        disabled={true}
        ddlClass="ddl-space"
        value={{
          name: props.buildingName,
        }}
      />
      <PrimaryLabel labelText=" Residential property" />
      <br />
      <PrimaryLabel
        className="block-count"
        labelText={props.blockCount + ' Units'}
      />
      <CompanyTable
        buildingTableData={unitsBlockArray.buildingBlockObj}
        buildingTableType="Units"
        className="mt-3"
        addUnits={() => {
          setAddUnitModal(true);
        }}
        deleteUnit={(blockData, parentIndex, index) => {
          onClickDeleteIcon(blockData, parentIndex, index);
        }}
      />

      {openAddUnitModal && (
        <BuildingAddCompanies
          modalState={openAddUnitModal}
          onClosePopup={onCloseUnitPopup}
          buildingBlockData={buildingBlockData}
          addUnitForm={true}
          CommonLayoutCheck={false}
        />
      )}
      {openDeleteUnitModal && (
        <DeleteCompanyPopup
          modalState={openDeleteUnitModal}
          modalClose={onCloseDeleteUnitPopup}
          dataName={openDeleteUnitModal}
          deleteblock={deleteblocks}
        />
      )}
    </>
  );
};

export default AddUnitForm;
