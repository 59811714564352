import React, { useState } from 'react';
import { PrimaryLabel, Dropdown } from '@alfredclub/hom-planner-theme';
import { Register, SetValue } from '../../../models';
import { timeOfTaskListEvent, taskStartTime } from '../../../constants';

const CreateTaskTimeFields: React.FC<{
  register: Register;
  setValue: SetValue;
}> = ({ register, setValue }) => {
  const [taskTimeLabel, setTaskTimeLabel] = useState({});
  const TaskTimeObjValue = taskStartTime.filter(
    (data) => data.value === taskTimeLabel
  );
  return (
    <>
      <div>
        <PrimaryLabel labelText="Time of task" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Dropdown
          options={timeOfTaskListEvent}
          label="Time of task"
          ref={() => register({ name: 'timeOfTask' })}
          placeholder="Select when"
          onOptionChange={(e) => {
            const timeOfTask = Number(e['headerValue']);
            setTaskTimeLabel(Number(e['value']));
            setValue('timeOfTask', timeOfTask);
          }}
        />
      </div>
      <div>
        <PrimaryLabel
          labelText={`${
            TaskTimeObjValue.length > 0
              ? TaskTimeObjValue[0].label
              : 'Task time'
          }`}
        />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Dropdown
          options={
            TaskTimeObjValue.length > 0 && TaskTimeObjValue[0]?.['timeValues']
          }
          label="Task time"
          ref={() => register({ name: 'timeValue' })}
          placeholder="Select when"
          labelKey="title"
          valueKey="id"
          onOptionChange={(e) => {
            const timeValue = Number(e['value']);
            setValue('timeValue', timeValue);
          }}
        />
      </div>
    </>
  );
};

export default CreateTaskTimeFields;
