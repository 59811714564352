import React, { useRef } from 'react';
import { Input } from '@alfredclub/hom-planner-theme';

const EditableField = (props) => {
  const inputEl = useRef(null);

  return (
    <Input
      {...props}
      label="testing"
      onInputBlur={(e) => {
        if (props.value.toString() !== e.currentTarget.value.toString()) {
          props.updateAction({
            [props.updateKey]: e.currentTarget.value,
            ...props.data,
          });
        }
      }}
      isLabelShow={false}
      ref={inputEl}
      className="edit_desc_input"
    />
  );
};

export default EditableField;
