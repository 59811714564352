import React from 'react';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import ModalPopup from '../ReactModal/Modal';
import '../Clients/Building/BuildingPopup/BuildingPopup.scss';
import './taskTemplate.scss';
import { IDeletePopup } from '../../models/taskTemplates';

const DeletePopup: React.FC<IDeletePopup> = ({
  modalState,
  modalClose,
  onDelete,
  headerText,
  subHeader,
  buttonLabel,
  cancelButtonLabel,
}: IDeletePopup) => {
  const onSubmit = async () => {
    await onDelete();
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={modalClose}
      className="delete-modal"
    >
      <PrimaryLabel
        className="sub-header text-Color_4"
        labelText={headerText}
      />
      <br />
      <br />
      <PrimaryLabel className="sub-header text-Color_4" labelText={subHeader} />
      <div className="button_div">
        <Button
          label={cancelButtonLabel}
          className="close-button block mr-8 mt-8"
          type="button"
          onClick={modalClose}
          backgroundColor="#ffffff"
          btnBorderColor="#e89a89"
        />
        <Button
          label={buttonLabel}
          className="continue-button block px-4"
          onClick={onSubmit}
          backgroundColor="#e89a89"
        />
      </div>
    </ModalPopup>
  );
};

export default DeletePopup;
