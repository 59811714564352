import React, { useEffect } from 'react';
import Chat from '../../Common/TwilioChat';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './AdminChat.scss';
import { TenantTopnav, PrimaryLabel } from '@alfredclub/hom-planner-theme';

import { IEventsState } from '../../../redux/event/event.state';
import { getQueryParameters, getUserToken } from '../../../utils';
import EventDescription from './EventDescription';
import { getChatDetails } from '../../../redux/event';

const AdminChat = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const eventId = query.get('eventId');
  const { chatScreenDetail } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  useEffect(() => {
    (async () => await dispatch(getChatDetails(Number(eventId))))();
  }, [dispatch, eventId]);
  return (
    <>
      <TenantTopnav
        isWhiteLabel={false}
        isVendor={false}
        isLoggedIn={false}
        showRightSideIcons={false}
      />
      <div className="container md:container lg:container md:mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-0 vendor-chat">
        <EventDescription isAdminChat={true} eventDetail={chatScreenDetail} />
        <div className="m-4 col-span-2">
          <div className="text-right mb-2">
            <PrimaryLabel
              className="text-F16"
              labelText="Please refresh the page if you are not able to see your chat"
            />
          </div>
          {chatScreenDetail?.['livestreamId'] && (
            <Chat
              channelName={`${chatScreenDetail?.[
                'livestreamId'
              ]?.toString()}${getQueryParameters('eventId')}`}
              btnText="Send Message"
              teacher={true}
              userToken={getUserToken()}
              sectionStyleClass="adminChat w-auto md:w-full"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AdminChat;
