import React from 'react';
import {
  Button,
  PrimaryLabel,
  Input,
  Dropdown,
} from '@alfredclub/hom-planner-theme';
import ModalPopup from '../../ReactModal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getManagements } from '../../../redux/management/management.action';
import { getBuildingsByManagement } from '../../../redux/building/buildings.action';
import { useHistory } from 'react-router-dom';
import './Staff.scss';
import { IBuildingState } from '../../../redux/building';
import { IManagementState } from '../../../redux/management';

interface IFormInput {
  name: string;
  management: object;
  building: object;
}
const AddNewStaff: React.FC<any> = ({ modalState, onCloseStaff }: any) => {
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<IFormInput>();
  const dispatch = useDispatch();
  const { allManagements } = useSelector(
    (state: { managements: IManagementState }) => state.managements
  );
  const { allBuildingsByManagement } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const history = useHistory();
  // Encrypt the value of popup fields
  const onSubmit = () => {
    history.push(
      `/data-resources/clients/staff/add-staff?building=${btoa(
        JSON.stringify(getValues(['building', 'name', 'management']))
      )}`
    );
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onCloseStaff}
      className="add-cluster-modal"
      shouldCloseOnOverlayClick={false}
    >
      <label className="label">Add New Building Staff</label>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errors.name && (
          <div className="staff-error">Please fill the all fields</div>
        )}
        <PrimaryLabel labelText="Building Staff Name" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Input
          name="name"
          label=""
          placeholder="Type here"
          className="input-control-space"
          ref={register({ required: true })}
          isError={errors['name'] ? true : false}
        />

        <PrimaryLabel labelText="Management Company" />
        <Dropdown
          asyncDropdown={false}
          labelKey="companyName"
          valueKey="id"
          isLabelShow={false}
          label="Management Company"
          options={allManagements}
          onMenuOpen={async () => {
            if (
              allManagements.length <= 0 ||
              Object.keys(allManagements).length <= 0
            ) {
              await dispatch(getManagements(0, 0, 'short', '', 1));
            }
          }}
          ref={() => register({ name: 'management' }, { required: false })}
          onOptionChange={async (e) => {
            const management = { id: e['id'], companyName: e['companyName'] };
            setValue('management', management);
            await dispatch(getBuildingsByManagement(e['id'], 1));
          }}
          sectionClass="control-space"
        />

        <PrimaryLabel labelText="Select Buildings" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Dropdown
          asyncDropdown={false}
          labelKey="name"
          valueKey="id"
          isLabelShow={false}
          label="Building"
          options={allBuildingsByManagement}
          ref={() => register({ name: 'building' }, { required: false })}
          onOptionChange={(e) => {
            setValue('building', e);
          }}
          placeholder=""
          sectionClass="control-space"
          isMulti={true}
        />

        <Button label="Continue" isArrowShow={true} />
      </form>
    </ModalPopup>
  );
};

export default AddNewStaff;
