import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Table,
  Topnav,
  Loader,
} from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { TOOLTIP_TEXT } from '../../constants';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FormTitle from '../Clients/Building/AddBuilding/FormTitle';
import ReactTooltip from 'react-tooltip';
import VendorContactPopup from './VendorContactPopup';
import {
  createVendorCompany,
  updateCompanyVendor,
  getVendorIndividualDetail,
  getCompanyVendorDetail,
} from '../../redux/vendor/vendors.action';
import { useFormDebounce, usePageRefresh } from '../../hooks';
import {
  clearCompanyVendorForm,
  updateCompanyVendorForm,
  clearCompanyIndividualForm,
} from '../../redux/form/form.action';
import { loadState } from '../../utils';
import './AddVendor/AddVendorForm.scss';
import { IFormState } from '../../redux/form/form.state';
import { ILoaderState } from '../../redux/loader/loader.state';
import { CompanyVendor, VendorCompany } from '../../models';
import { IVendorState } from '../../redux/vendor/vendor.state';
import CommonLoader from '../Common/CommonLoader';

const AddCompanyVendor: React.FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const query = new URLSearchParams(search);
  const [showLoader, setLoadingState] = useState<boolean>(false);
  const [setRowClick, setRowState] = useState<string | null>(null);
  const { vendorCompanyForm: vendorData } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const { fetchedVendor } = useSelector(
    (state: { vendors: IVendorState }) => state.vendors
  );
  // const [vendorName] = useState(query.get('vendorName'));

  const vendorId = params['companyVendorId']
    ? parseInt(params['companyVendorId'], 10)
    : loadState('vendorCompanyForm')?.['id'] ?? 0;
  // const [vendorData] = useState(
  //   vendorId > 0 ? loadState('vendorCompanyForm') : vendorCompanyForm
  // );
  const { register, handleSubmit, getValues, reset } = useForm<CompanyVendor>({
    defaultValues: vendorData,
  });
  const [showOpenContact, setOpenContactPopup] = useState(false);
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const [vendorName] = useState(
    vendorId > 0 ? vendorData?.['companyName'] : query.get('vendorName')
  );

  usePageRefresh(true, vendorId ? false : true);
  useFormDebounce(updateCompanyVendorForm, 500, getValues);

  const onSubmit = async (data: VendorCompany) => {
    data.companyName = vendorName;
    data.status = true;
    if (vendorId <= 0) {
      await dispatch(createVendorCompany(data));
    } else {
      await dispatch(updateCompanyVendor(vendorId, data));
    }
    await dispatch(clearCompanyVendorForm());
    push(`/data-resources/vendors?type=company`);
  };

  const onAddContact = async () => {
    if (vendorId > 0) {
      await dispatch(clearCompanyIndividualForm());
      setOpenContactPopup(true);
    }
  };

  const onCloseContact = (e: Event) => {
    e.preventDefault();
    setOpenContactPopup(false);
  };
  const handleOnCellClick = async (e: any) => {
    if (e.row.original.vendorId) {
      setLoadingState(true);
      setRowState(e.row.original.vendorId);
      await dispatch(getVendorIndividualDetail(e.row.original.vendorId));
      push(`/data-resources/vendors/${e.row.original.vendorId}`);
    }
  };

  useEffect(() => {
    async function getCompVendorInfo() {
      if (vendorId) {
        await dispatch(getCompanyVendorDetail(vendorId));
      }
    }
    getCompVendorInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId]);

  useEffect(() => {
    if (fetchedVendor) {
      reset(vendorData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, fetchedVendor]);

  return (
    <>
      <Topnav
        headerTitle={`${
          !vendorId
            ? `Add Vendor Company: ${vendorName}`
            : vendorData.companyName ?? vendorName
        }`}
      />
      {fetchedVendor ? (
        <div className="mx-10 staff-section mt-16">
          <VendorContactPopup
            modalState={showOpenContact}
            onClosePopup={onCloseContact}
            vendorId={vendorId}
            vendorName={vendorName}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              label="Save changes"
              className={`${
                loading ? 'button_disable staff-add' : 'staff-add'
              }`}
              disabled={loading}
            />
            <FormTitle title="Headquarter details" />
            <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-10">
              <div>
                <Input
                  isLabelShow={true}
                  label="HQ Phone number"
                  name="hqPhone"
                  ref={register}
                />
              </div>
              <div>
                <Input
                  isLabelShow={true}
                  label="HQ Address"
                  name="hqAddress"
                  ref={register}
                />
              </div>
              <div>
                <Input
                  isLabelShow={true}
                  label="Vendor Website"
                  name="vendorWebsite"
                  ref={register}
                />
              </div>
              <div>
                <Input
                  isLabelShow={true}
                  label="HQ Contact name"
                  name="hqContactName"
                  ref={register}
                />
              </div>
            </div>

            <FormTitle title="Regional contacts" />
            <>
              <Button
                backgroundColor="#ffffff"
                className="px-sort-button mt-2"
                label="Sort by"
                buttonText="#5d5d5d"
                onClick={() => {}}
                type="button"
              />
              <span
                data-tip={
                  vendorId <= 0 ? TOOLTIP_TEXT.vendors.detailSaveMessage : ''
                }
                className="custom_label float-right pt-8"
                onClick={onAddContact}
              >
                +Add Contact
              </span>
            </>
            <div>
              <Table
                tableColumns={[
                  {
                    Header: 'Sno',
                    accessor: 'id',
                    Cell: (row: any) => {
                      return <div>{row.row.index + 1}</div>;
                    },
                  },
                  {
                    Header: 'Vendor name',
                    className: 'tableAnchor',
                    accessor: (row: any) => (
                      <>
                        {row.name}
                        <Loader
                          color="#e89a89"
                          position="center"
                          loading={showLoader && setRowClick === row.vendorId}
                          size="0.75rem"
                          className={`${
                            showLoader &&
                            setRowClick === row.vendorId &&
                            'row_loader'
                          }`}
                        />
                      </>
                    ),
                  },
                  {
                    Header: 'Email',
                    accessor: 'email',
                  },
                  {
                    Header: 'Contact',
                    accessor: 'cellPhone',
                  },
                  {
                    Header: 'Status',
                    accessor: (row: any) =>
                      !row.status ? 'Inactive' : 'Active',
                  },
                ]}
                tableData={vendorData?.['vendorContacts']}
                showPagination={false}
                className="mt-16"
                onCellClick={(e) => {
                  handleOnCellClick(e);
                }}
                onCellMouseEnter={() => {}}
              />
            </div>
          </form>
          <ReactTooltip />
        </div>
      ) : (
        <CommonLoader loading={!fetchedVendor} />
      )}
    </>
  );
};

export default AddCompanyVendor;
