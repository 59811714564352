import {
  Dropdown,
  Input,
  PrimaryLabel,
  TextArea,
  Timepicker,
  TitleBar,
} from '@alfredclub/hom-planner-theme';
import React, { useState, Fragment } from 'react';
import {
  IEventWeekDays,
  Event,
  IRecurringEventForm,
  IRecurringServiceDropdown,
} from '../../../models';
import {
  getESTTime,
  getImageUrl,
  getUploadedImageUrl,
  splitImageName,
} from '../../../utils';
import LabelWithIcon from './LabelWithIcon';
import { useSelector, useDispatch } from 'react-redux';
import { getEventServiceList } from '../../../redux/event';
import { EVENT_MIN_TIME, utcTimeFormat } from '../../../constants/index';
import {
  clearEventSpaces,
  getEventSpaceList,
  ISpaceState,
} from '../../../redux/space';
import { getServiceDetail } from '../../../redux/service/services.action';
import { getSpaceDetail } from '../../../redux/space/space.action';
import {
  getBuildingDetail,
  saveBuildingId,
} from '../../../redux/building/buildings.action';
import AnchorElement from '../../Common/AnchorElement';
import FileInput from '../../Common/FileInput';
import { imageUpload } from '../../../redux/common';

export const RecurringInPersonEvent: React.FC<{
  eventData: Event;
  virtual: boolean;
  fields: IRecurringEventForm[];
  weekDaysList: IEventWeekDays[];
  isEdit: boolean;
  buildingObj: object;
  onChange: (
    e: HTMLInputElement | { value: string | object; name: string },
    index: number,
    data?: object,
    key?: string
  ) => void;
}> = ({
  eventData,
  virtual,
  fields,
  weekDaysList,
  isEdit,
  onChange,
  buildingObj,
}) => {
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState('');
  const [apiStatus, apiCallStatus] = useState(false);
  const { eventServices } = useSelector((state) => state.events);
  eventServices?.sort((a, b) => a.name.localeCompare(b.name));
  const onServiceChange = (
    services: IRecurringServiceDropdown | React.ChangeEvent<HTMLElement>,
    index: number
  ) => {
    delete services?.['isPackage'];
    for (const key of Object.keys(services)) {
      if (key === 'id') {
        const event = {
          value: { [key]: services[key] },
          name: 'service',
        };
        onChange(event, index);
      } else {
        if (key !== 'name') {
          const event = {
            value:
              key === 'maxAttendees' ? Number(services[key]) : services[key],
            name: key === 'maxAttendees' ? 'maxCapacity' : key,
          };
          onChange(event, index);
        }
      }
    }
  };
  const { eventSpaces } = useSelector(
    (state: { spaces: ISpaceState }) => state.spaces
  );

  const selectEvent = async (id: number | null) => {
    const serviceDetails = await dispatch(getServiceDetail(id));
    window.open(
      `/data-resources/services/edit-${
        serviceDetails ? 'package' : 'service'
      }/${id}?tab=${serviceDetails ? 'elements' : 'setup'}&serviceType=${
        serviceDetails ? '2' : '1'
      }`,
      '_blank'
    );
  };
  const selectBuilding = async (id: number | null) => {
    await dispatch(saveBuildingId(id));
    await dispatch(getBuildingDetail(id));
    window.open(`/data-resources/clients/buildings/${id}`, '_blank');
  };
  const selectSpace = async (id: number | null) => {
    await dispatch(getSpaceDetail(id));
    window.open(`/data-resources/clients/spaces/${id}`, '_blank');
  };
  // Upload event image on image selected
  const handleFileUpload = async (e) => {
    const eventImage = new FormData();
    eventImage.append('file', e.target.files[0]);
    const fileData = await dispatch(imageUpload(eventImage));
    return fileData;
  };

  return (
    <div className="col-span-3 weekday_section">
      {fields.map((_, index) => {
        return (
          <Fragment key={index}>
            <TitleBar
              headerTitle={weekDaysList?.[index]?.['fullName']}
              className="recurring_title"
            />
            <div className="grid grid-cols-3 gap-x-2 gap-y-0">
              <div className="col-span-1 recurring_ls">
                <LabelWithIcon
                  imgSrc={getImageUrl('Events/buildingconfig.svg')}
                  labelText="Set Time"
                  className="img_label"
                />
                <div className="mb-1 flex pt-4">
                  <Timepicker
                    labelName="Start time"
                    onTimeChange={(e) => {
                      const event = {
                        name: 'startTime',
                        value: e,
                      };
                      onChange(event, index);
                      setStartTime(e);
                    }}
                    minTime={EVENT_MIN_TIME}
                    name="startTime"
                    isError={!fields?.[index]?.startTime}
                    selectedTime={
                      fields?.[index]?.startTime
                        ? getESTTime(
                            fields?.[index]?.startTime,
                            utcTimeFormat,
                            eventData?.['timezone']?.zoneValue
                          )
                        : ''
                    }
                  />
                  <div className="px-2 justify-center items-center flex">
                    <img src={getImageUrl('Events/Arrow.svg')} alt="icon" />
                  </div>
                  <Timepicker
                    labelName="End time"
                    onTimeChange={(e) => {
                      const event = {
                        name: 'endTime',
                        value: e,
                      };
                      onChange(event, index);
                    }}
                    minTime={startTime}
                    name="endTime"
                    isError={!fields?.[index]?.['endTime']}
                    selectedTime={
                      fields?.[index]?.endTime
                        ? getESTTime(
                            fields?.[index]?.endTime,
                            utcTimeFormat,
                            eventData?.['timezone']?.zoneValue
                          )
                        : ''
                    }
                  />
                </div>
                <div className="mt-12">
                  <LabelWithIcon
                    imgSrc={getImageUrl('Events/spacesettings.svg')}
                    labelText="Space Settings"
                    className="img_label"
                  />
                  <div className="mt-4">
                    <div className="flex justify-between dropdown_class">
                      <div>
                        <span className="label_text">Select Building</span>
                        <span className="text-red-500">*</span>
                      </div>
                      {fields?.length &&
                        fields?.[index]?.['venue']?.['building']?.id && (
                          <img
                            src={getImageUrl('Events/activeLink.svg')}
                            alt="icon"
                            onClick={() =>
                              selectBuilding(
                                fields?.[index]?.['venue']?.['building']?.['id']
                              )
                            }
                            className="cursor-pointer"
                          />
                        )}
                    </div>
                    <Dropdown
                      asyncDropdown={false}
                      labelKey="name"
                      valueKey="id"
                      isLabelShow={false}
                      label="Select Building"
                      options={buildingObj}
                      isError={
                        !fields?.[index]?.[`venue`]?.['building']?.['id']
                      }
                      onOptionChange={async (e) => {
                        let event;
                        if (e) {
                          event = {
                            value: {
                              building: { id: e['id'], name: e['name'] },
                            },
                            name: 'venue',
                          };
                          await dispatch(clearEventSpaces());
                          await dispatch(getEventSpaceList(e['id']));
                        }
                        onChange(event, index);
                      }}
                      placeholder=""
                      value={{
                        name: fields?.[index]?.venue?.['building']?.['name'],
                        id: fields?.[index]?.venue?.['building']?.['id'],
                      }}
                      // disabled={isPast}
                    />
                  </div>
                  <div className="mt-4">
                    <div className="flex justify-between dropdown_class">
                      <div>
                        <span className="label_text">Select Space</span>
                        <span className="text-red-500">*</span>
                      </div>
                      {fields?.length && fields?.[index]?.['venue']?.id && (
                        <img
                          src={getImageUrl('Events/activeLink.svg')}
                          alt="icon"
                          onClick={() =>
                            selectSpace(fields?.[index]?.['venue']?.['id'])
                          }
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                    <Dropdown
                      asyncDropdown={false}
                      labelKey="name"
                      valueKey="id"
                      isLabelShow={false}
                      label="Space"
                      onMenuOpen={async () => {
                        if (isEdit) {
                          apiCallStatus(true);
                          await dispatch(clearEventSpaces());
                          await dispatch(
                            getEventSpaceList(
                              fields?.[index]?.venue?.['building']?.['id']
                            )
                          );
                          apiCallStatus(false);
                        }
                      }}
                      loading={apiStatus}
                      options={eventSpaces}
                      isError={!fields?.[index]?.['venue']?.['id']}
                      onOptionChange={async (e) => {
                        const event = {
                          value: {
                            ...fields?.[index]?.['venue'],
                            maxCapacity: e['maxCapacity'],
                            id: e['id'],
                            name: e['name'],
                          },
                          name: 'venue',
                        };
                        onChange(event, index);
                      }}
                      placeholder=""
                      value={{
                        name: fields?.[index]?.venue?.['name'],
                        id: fields?.[index]?.venue?.['id'],
                      }}
                    />
                  </div>
                </div>
                <PrimaryLabel
                  labelText={`Space Maximum Capacity : ${fields?.[index]?.venue?.['maxCapacity']}`}
                />
                <div className="grid grid-cols-1 gap-x-2 gap-y-0">
                  <div className="mt-4">
                    <PrimaryLabel labelText="Maximum Attendees" />
                    <PrimaryLabel labelText="*" className="text-red-500" />
                    <Input
                      label=""
                      placeholder=""
                      name="maxCapacity"
                      inputType="number"
                      isError={!fields?.[index]?.maxCapacity}
                      value={fields?.[index]?.maxCapacity ?? null}
                      onInputBlur={(e) => onChange(e.currentTarget, index)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-2">
                <div className="setup_event">
                  <LabelWithIcon
                    imgSrc={getImageUrl('Events/setupevent-1.svg')}
                    labelText="Setup Event"
                    className="img_label"
                  />
                  <div className="grid grid-cols-2 gap-x-2 gap-y-0">
                    <div className="mt-4">
                      <div className="flex justify-between dropdown_class">
                        <div>
                          <span className="label_text">Select Event</span>
                          <span className="text-red-500">*</span>
                        </div>
                        {fields?.length && fields?.[index]?.service?.id && (
                          <img
                            src={getImageUrl('Events/activeLink.svg')}
                            alt="icon"
                            onClick={() =>
                              selectEvent(fields?.[index]?.service?.id)
                            }
                            className="cursor-pointer"
                          />
                        )}
                      </div>
                      <Dropdown
                        asyncDropdown={false}
                        labelKey="name"
                        valueKey="id"
                        isLabelShow={false}
                        label="Event Service"
                        options={eventServices}
                        onMenuOpen={async () => {
                          apiCallStatus(true);
                          if (eventServices?.length <= 0) {
                            await dispatch(
                              getEventServiceList(
                                eventData?.['serviceCategory']['id'],
                                Number(virtual),
                                Number(eventData?.['recurring'])
                              )
                            );
                          }
                          apiCallStatus(false);
                        }}
                        loading={apiStatus}
                        name="service"
                        isError={!fields?.[index]?.service}
                        onOptionChange={(e) => onServiceChange(e, index)}
                        placeholder=""
                        value={{
                          name: fields?.[index]?.service?.name,
                          id: fields?.[index]?.service?.id,
                        }}
                      />
                    </div>
                    <div className="mt-4">
                      <PrimaryLabel labelText="Event Display Title" />
                      <PrimaryLabel labelText="*" className="text-red-500" />
                      <Input
                        label=""
                        placeholder="Type here"
                        name="displayName"
                        isError={!fields?.[index]?.displayName}
                        value={fields?.[index]?.displayName ?? null}
                        onInputBlur={(e) => onChange(e.currentTarget, index)}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-2 gap-y-0">
                    <div className="mt-4">
                      <PrimaryLabel labelText="Event Description" />
                      <TextArea
                        label=""
                        placeholder="Add description"
                        isLabelShow={false}
                        name="description"
                        value={fields?.[index]?.description ?? null}
                        onTextAreaChange={(e) =>
                          onChange(e.currentTarget, index)
                        }
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-2 gap-y-0">
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <PrimaryLabel
                          labelText="Poster link"
                          className="block"
                        />
                        <AnchorElement link={fields?.[index]?.marketingLinks} />
                      </div>
                      <Input
                        label=""
                        placeholder="Paste link here"
                        name="marketingLinks"
                        onInputBlur={(e) => onChange(e.currentTarget, index)}
                        value={fields?.[index]?.marketingLinks ?? null}
                      />
                    </div>
                    <div className="mt-3">
                      <PrimaryLabel labelText="Event Card Image" />
                      {!fields?.[index]?.['eventImage']?.['file'] ? (
                        <FileInput
                          label="Event Card Image"
                          isLabelShow={false}
                          inputSectionClass="m-0"
                          name="eventImage"
                          onInputChange={async (e) => {
                            const data = await handleFileUpload(e);
                            if (data) {
                              onChange(e, index, data, 'eventImage');
                            }
                          }}
                        />
                      ) : (
                        <Input
                          isLabelShow={false}
                          isAnchor={true}
                          anchorHref={getUploadedImageUrl(
                            fields?.[index]?.['eventImage']?.['file']
                          )}
                          label=""
                          inputSectionClass="fileUpload"
                          anchorText={`${splitImageName(
                            fields?.[index]?.['eventImage']?.['file']
                          )}`}
                          onRemoveImage={(e) => {
                            onChange(e, index, { id: null }, 'eventImage');
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default RecurringInPersonEvent;
