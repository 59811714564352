import React, { useEffect, useState } from 'react';
import { Button, PrimaryLabel, Dropdown } from '@alfredclub/hom-planner-theme';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getBuildings, IBuildingState } from '../../redux/building';
import {
  currentMonth,
  currentYear,
  MonthOptions,
  yearOptions,
} from '../../constants';
import ModalPopup from '../ReactModal/Modal';
import './Common.scss';
import { TinyObject } from '../../models';
import {
  clearClientReport,
  generateClientReport,
  IEventsState,
} from '../../redux/event';
import { ILoaderState } from '../../redux/loader';

interface IFormInput {
  buildingId: number;
  month: number;
  year: number;
}
const GenerateReport: React.FC<any> = ({ modalState, onClosePopup }: any) => {
  const { register, errors, handleSubmit, setValue } = useForm<IFormInput>();
  const dispatch = useDispatch();
  const [currentMonthValue, setCurrentMonth] = useState<TinyObject>();
  const [currentYearValue, setCurrentYear] = useState<TinyObject>();
  const [buildingName, setBuildingName] = useState<string>();
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const { allBuildings } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );

  const { clientReport } = useSelector(
    (state: { events: IEventsState }) => state.events
  );

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const onSubmit = async (data: IFormInput) => {
    const params = {
      month: data.month ?? currentMonthValue?.id,
      year: data.year ?? currentYearValue?.id,
    };
    const response = await dispatch(
      generateClientReport(params, data.buildingId)
    );
    if (response) {
      onClosePopup();
    }
  };

  // Set month and year dropdown value on load
  useEffect(() => {
    const monthObj = MonthOptions.filter((d) => d.id === currentMonth);
    const yearObj = yearOptions.filter((d) => d.id === currentYear);
    setCurrentMonth({
      id: monthObj[0]['id'],
      name: monthObj[0]['name'],
    });
    setCurrentYear({
      id: yearObj[0]['id'],
      name: yearObj[0]['name'],
    });
  }, []);

  // Download file after api response
  useEffect(() => {
    if (Object.keys(clientReport).length > 0) {
      const fileDownload = require('js-file-download');
      fileDownload(
        clientReport,
        `${buildingName}-${currentMonthValue?.['name']}-${currentYearValue?.['name']}.csv`
      );
      (async () => await dispatch(clearClientReport()))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientReport]);

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClosePopup}
      className="report-modal"
      shouldCloseOnOverlayClick={false}
    >
      <div className="grid grid-cols-1 gap-x-2 gap-y-0 report-wrapper">
        <label className="report-title">Generate event report</label>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-x-2 gap-y-0">
            <div>
              <PrimaryLabel labelText="Select Building" />
              <Dropdown
                asyncDropdown={false}
                labelKey="name"
                valueKey="id"
                isLabelShow={false}
                label="building"
                options={allBuildings}
                onMenuOpen={async () => {
                  setApiLoading(true);
                  if (allBuildings?.length === 0) {
                    await dispatch(getBuildings(0, 0, 'short', '', 1));
                  }
                  setApiLoading(false);
                }}
                loading={apiLoading}
                ref={() => register({ name: 'buildingId' }, { required: true })}
                onOptionChange={async (e) => {
                  setValue('buildingId', e['id']);
                  setBuildingName(e['name']);
                }}
                isError={errors['buildingId'] ? true : false}
                sectionClass="control-space"
                placeholder="Select"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-2 gap-y-0">
            <div>
              <PrimaryLabel labelText="Select Month" />
              <Dropdown
                asyncDropdown={false}
                labelKey="name"
                valueKey="id"
                isLabelShow={false}
                label="month"
                options={MonthOptions}
                ref={() => register({ name: 'month' }, { required: false })}
                onOptionChange={(e) => {
                  setValue('month', e['id']);
                  setCurrentMonth({
                    id: e['id'],
                    name: e['name'],
                  });
                }}
                placeholder=""
                sectionClass="control-space"
                value={currentMonthValue}
              />
            </div>
            <div>
              <PrimaryLabel labelText="Select Year" />
              <Dropdown
                asyncDropdown={false}
                labelKey="name"
                valueKey="id"
                isLabelShow={false}
                label="month"
                options={yearOptions}
                ref={() => register({ name: 'year' }, { required: false })}
                onOptionChange={(e) => {
                  setValue('year', e['id']);
                }}
                placeholder=""
                sectionClass="control-space"
                value={currentYearValue}
              />
            </div>
          </div>

          <Button
            label="Download Report"
            backgroundColor="#529092"
            disabled={loading}
          />
        </form>
      </div>
    </ModalPopup>
  );
};

export default GenerateReport;
