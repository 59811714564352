import React, { useState, useEffect } from 'react';
import { Sidenav } from '@alfredclub/hom-planner-theme';
import Sidebar from '../Sidebar/Sidebar';
import Routes from '../../Routes/app.routes';
import { sidenavMenu } from '../../constants/index';
import { LayoutProps } from '../../interfaces';
import { useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IUserLoginResponse } from '../../models';
import { clearSearchBy, clearSearchFor } from '../../redux/event/event.action';
import { setFilteredVendors } from '../../redux/vendor';
import { getImageUrl, redirectPortWise } from '../../utils';

const Layout: React.FC<LayoutProps> = ({ history }) => {
  const dispatch = useDispatch();
  const paramName = history?.location?.pathname;
  const splitParam = paramName.split('/');
  const finalName =
    splitParam?.length && splitParam.length >= 2 ? splitParam[1] : '';
  const [navImages, toggleNavImage] = useState(sidenavMenu);
  const indexValue = sidenavMenu.findIndex((x) => x.label === finalName);
  const routeLabel = indexValue > 0 ? indexValue : 0;
  const clearSearchState = async () => {
    await dispatch(clearSearchBy());
    await dispatch(clearSearchFor());
    await dispatch(setFilteredVendors({}));
  };

  const toggleImages = (
    index: number,
    clickCheck: boolean,
    navRoute: string
  ): void => {
    clearSearchState();
    const newNavImagesArray = navImages.filter((item, itemIndex) => {
      if (itemIndex !== index) {
        item.showSelected = false;
      } else {
        navImages[index].showSelected = true;
      }
      return navImages;
    });
    toggleNavImage([...newNavImagesArray]);
    if (clickCheck) {
      // For running on localhost
      if (process.env.NODE_ENV === 'development') {
        redirectPortWise(
          navImages[index].route,
          navImages[index].port,
          history
        );
      } else {
        const splitRoute = navImages[index].route.split('.');
        switch (splitRoute[0]) {
          case 'ops':
          case 'asc':
            window.location.href = `https://${navImages[index].route}`;
            break;
          default:
            history.push(navImages[index].route);
            break;
        }
      }
    }
  };

  useEffect(() => {
    toggleImages(routeLabel, false, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeLabel]);

  const sideBarCheck = (route) => {
    if (sidenavMenu?.length && sidenavMenu[route]?.['showSideBar']) {
      clearSearchState();
      return true;
    }
  };
  const { access } = useSelector(
    (state: { authentication: IUserLoginResponse }) => state.authentication
  );
  return (
    <div className="flex overflow-hidden">
      {!useRouteMatch('/events/:eventId/create') && access && (
        <div className="w-1/1">
          <Sidenav
            icons={navImages}
            onIconClick={(index: number) =>
              toggleImages(index, true, navImages[index]?.route)
            }
            showLogout={false}
            imgLogout={getImageUrl('data-resources/logout.svg')}
            logoutClass="left-2 cursor-pointer"
          />
        </div>
      )}
      {sideBarCheck(routeLabel) && access && (
        <div className="w-40 z-0">
          <Sidebar routeLabel={routeLabel} history={history} />
        </div>
      )}
      <div className="w-full">
        <Routes />
      </div>
    </div>
  );
};

export default Layout;
