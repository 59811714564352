import { getArrayOfIds } from '../../utils';
import * as accountTypes from './account.constant';
import { IAccountsState, initialAccountState } from './account.state';

export const accountsReducer = (
  state: IAccountsState = initialAccountState,
  action: {
    [x: string]: number;
    type: any;
    page: number;
    payload: any;
    usersCount: number;
    staffUsersCounts: number;
    vendorUsersCount: number;
  }
): IAccountsState => {
  switch (action.type) {
    case accountTypes.GET_ACCOUNTS_USERS:
    case accountTypes.SEARCH_ACCOUNTS_USERS:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        usersCount: action.usersCount,
      };
    case accountTypes.GET_ACCOUNTS_STAFF:
    case accountTypes.SEARCH_ACCOUNTS_STAFF:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        staffUsersCounts: action.staffUsersCounts,
      };
    case accountTypes.GET_ACCOUNTS_VENDORS:
    case accountTypes.SEARCH_ACCOUNTS_VENDORS:
      return {
        ...state,
        pages: state.pages.set(action.page, {
          data: action.payload,
          fetched: true,
        }),
        vendorUsersCount: action.vendorUsersCount,
      };
    case accountTypes.EMAIL_ERROR:
      return {
        ...state,
        emailError: action.payload,
      };
    case accountTypes.RESET_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.payload,
      };
    case accountTypes.SET_USER_IDS:
      const arrayOfIds = getArrayOfIds(action['data']);
      return {
        ...state,
        userIds: [...arrayOfIds],
      };
    case accountTypes.EMPTY_SET_USER_IDS:
      const emptyArrayOfIds = getArrayOfIds(0, true);
      return {
        ...state,
        userIds: emptyArrayOfIds,
      };
    default:
      return state;
  }
};
