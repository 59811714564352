import React from 'react';
import { Calendar, Loader, Table } from '@alfredclub/hom-planner-theme';
import { useSetPageNo } from '../../hooks';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCategoryFilteredEvents,
  getEvents,
} from '../../redux/event/event.action';
import { IEventsState } from '../../redux/event';
import { EventDashboardProps } from '../../models';
import { setQueryParameters } from '../../utils';
import EventCategoryFilters from './EventCategoryFilters';

const DraftEvents: React.FC<EventDashboardProps> = ({
  status,
  onDateChange,
  onMonthChange,
  clearMonthSelection,
  yearly,
  setYearly,
  searchQuery,
  calendarSelectedDate,
  pageNo,
  setPage,
  columns,
  date
}) => {
  const dispatch = useDispatch();
  const {
    draftsEvents,
    draftsCount,
    eventDateType,
    eventCount,
    eventSearchBy,
    categoryFilters,
    monthInfo
  } = useSelector((state: { events: IEventsState }) => state.events);

  const onPageClick = async (page: number) => {
    if (
      categoryFilters?.['regionSelected'] > 0 ||
      categoryFilters?.['vendorFiltered']
    ) {
      await dispatch(
        getCategoryFilteredEvents(
          'drafts',
          page,
          monthInfo?.date ?? eventDateType.date,
          categoryFilters?.['regionSelected'],
          categoryFilters?.['vendorFiltered']
        )
      );
    } else {
      await dispatch(
        getEvents(
          'drafts',
          page,
          eventDateType.per_day,
          monthInfo?.date ?? eventDateType.date,
          eventDateType.yearly,
          eventDateType.year,
          searchQuery,
          eventSearchBy?.['value']
        )
      );
    }
    setPage(page);
  };
  useSetPageNo(pageNo);

  return (
    <>
      <div className="my-5">
        <Calendar
          setCalendarDate={async (selectedDate) => {
            await onDateChange(selectedDate);
            setPage(0);
          }}
          isToggleCalendar={false}
          clearDate={status === 'fetched' ? calendarSelectedDate : false}
          eventCount={eventCount}
          onMonthOrYearChange={onMonthChange}
          showDots={false}
          onViewChange={() => {
            setQueryParameters('0', 'pageNo');
            setPage(0);
            clearMonthSelection();
            setYearly(!yearly);
          }}
          // selectedCalendarDate={monthInfo?.date ?? eventDateType.date}
          selectedCalendarDate={String(monthInfo?.utcDate)}
        />
      </div>
      {status === 'fetched' ? (
        <>
          <EventCategoryFilters
            tabName="drafts"
            date={date}
            setPage={setPage}
          />
          <Table
            tableColumns={columns}
            tableData={draftsEvents?.['pages']?.get(pageNo)?.data}
            showPagination={!(draftsCount < 10) && true}
            totalRecordsCount={draftsCount}
            onPageChange={(pageNumber) => onPageClick(pageNumber - 1)}
            className="mt-4"
            onCellClick={() => { }}
            headerClass="table-booking"
            trHoverClass="hover:bg-Color_46"
            onCellMouseEnter={() => { }}
          />
        </>
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
    </>
  );
};

export default DraftEvents;
