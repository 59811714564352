import {
  commonTimeFormat,
  eventCustomTimeFormat,
  productionDefaultRegions,
  qaDefaultRegions,
} from './../constants/index';
import moment from 'moment-timezone';
import ct from 'countries-and-timezones';
import { useEffect } from 'react';
import {
  EVENT_TIME_FORMAT,
  dateAndTimeFormat,
  RECORDS_LIMIT,
  EVENT_FORMAT,
  eventCustomDateTimeFormat,
  defaultTimezone,
  datepickerformat,
  utcTimeFormat,
} from '../constants/index';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
export const getImageUrl = (path: string) => {
  return `${process.env.REACT_APP_CLOUDFRONT_URL}/${path}`;
};

export const apiStatusCheck = (response) => {
  if (response?.['status'] === 200 || response?.['status'] === 201) {
    return true;
  } else {
    return false;
  }
};

export const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('formData', serializedState);
  } catch (error) {
    console.error(error);
  }
};

export const loadState = (formName: string) => {
  try {
    const serializedState = localStorage.getItem('formData');
    if (!serializedState) {
      return {};
    }
    return JSON.parse(serializedState)?.['formData']?.[formName];
  } catch (error) {
    console.error(error);
  }
};

export const clearFormStorage = () => {
  try {
    localStorage.removeItem('formData');
  } catch (error) {
    console.error(error);
  }
};

export const findIndexFromArrayOfObject = (array, key, data) => {
  return array.findIndex((item) => item[key] === data[key]);
};

export const getUploadedImageUrl = (path: string) => {
  return `${process.env.REACT_APP_MEDIA_URL}${path}`;
};

export const splitImageName = (fileName: string) => {
  let uploadedImage;
  if (fileName) {
    uploadedImage = fileName.split('--hom-media--');
  }
  return uploadedImage[1];
};

export const getSerialNumber = (pageNo: number, index: number) => {
  return Number(pageNo * RECORDS_LIMIT) + 1 + index;
};

let idArray = [];
export const getArrayOfIds = (id: number, emptyArray: boolean = false) => {
  const idIndex = idArray.indexOf(id);
  if (emptyArray) {
    idArray = [];
    return idArray;
  }
  if (idIndex !== -1) {
    idArray.splice(idIndex, 1);
    return idArray;
  }
  idArray.push(id);
  return idArray;
};

export const filterCommonbuildingsOfRegion = (dataArray) => {
  const filteredArrayList = [];
  if (dataArray && dataArray.length > 0) {
    let itemIndex = -1;
    dataArray.forEach((item) => {
      if (filteredArrayList.length > 0) {
        filteredArrayList.forEach((bChild) => {
          if (bChild.hasOwnProperty('regionName')) {
            itemIndex = filteredArrayList.findIndex(
              (child) => child.regionName === item.regionName
            );
          }
        });
        if (itemIndex !== -1) {
          filteredArrayList[itemIndex]['buildings']?.push({ ...item });
        } else {
          filteredArrayList.push({
            regionName: item.regionName,
            startDatetime: item.startDatetime,
            buildings: [{ ...item }],
          });
        }
      } else {
        filteredArrayList.push({
          regionName: item.regionName,
          startDatetime: item.startDatetime,
          buildings: [{ ...item }],
        });
      }
    });
  }
  return filteredArrayList;
};

export const getUTCTime = (
  date: string,
  time: string,
  eventTimeZone: string
) => {
  const finalDate = date && time ? date + ' ' + time : date;
  const timeFormat = date && time ? dateAndTimeFormat : datepickerformat;
  // construct a moment object
  const momentObj = moment.tz(
    finalDate,
    timeFormat,
    eventTimeZone ?? defaultTimezone
  );

  // convert it to utc
  momentObj.utc();

  // format it for output
  const startEventTime = momentObj.format(
    date && time ? EVENT_TIME_FORMAT : EVENT_FORMAT
  );
  return startEventTime;
};

export const getSepratedTime = (
  datetime: string,
  timeZone: string,
  format: string
) => {
  if (datetime) {
    const startEventTime = moment(datetime)
      .tz(timeZone ?? defaultTimezone)
      .format(format);
    return startEventTime;
  }
};
// Date time for in h:mma, MMM DD YYYY e.g, 1:00am,Jan 1 2021
export const getCustomDateTime = (
  datetime: string,
  eventTimeZone?: string,
  isScheduleTaskTime: boolean = false
) => {
  const timeZone = eventTimeZone ?? defaultTimezone;
  const startEventTime = moment(datetime)
    .tz(timeZone)
    .format(
      isScheduleTaskTime ? eventCustomTimeFormat : eventCustomDateTimeFormat
    );
  return startEventTime;
};

export const getESTTime = (
  date: string,
  timeFormat: string,
  eventTimeZone?: string
) => {
  if (date) {
    const timeZone = eventTimeZone ?? defaultTimezone;
    const startEventTime = moment(date).tz(timeZone).format(timeFormat);
    return startEventTime;
  }
};

export const convertTimeToMMDDYYYY = (date: string | Date): string => {
  return moment(date).format(commonTimeFormat).toString();
};

export const useOutsideAlerter = (ref, menuOutsideClick) => {
  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (ref.current && !ref.current.contains(event.target)) {
        menuOutsideClick(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, menuOutsideClick]);
};

export const formattedDate = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const firstDay = new Date(currentYear, currentMonth, 1);
  return moment(firstDay).format(EVENT_FORMAT);
};

export const getUserToken = () => {
  return localStorage.getItem('access');
};
export const checkPastEvent = (eventTime: string) => {
  return moment().utc().isSameOrAfter(eventTime);
};
export const getDayAndTime = (
  date: string,
  dateFormat: string,
  eventTimeZone: string
) => {
  if (date) {
    const startEventTime = moment(date)
      .tz(eventTimeZone ?? defaultTimezone)
      .format(dateFormat);
    return startEventTime;
  }
};
export const getQueryParameters = (key: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key);
};

// Function will be use in future
// export const defaultTaskUTCTime = (eventTimeZone?: string) => {
//   const dayBeforeEventTime = `${moment().format(
//     datepickerformat
//   )} ${DAY_BEFORE_EVENT_TIME}`;
//   const dateFormat = moment.tz(
//     dayBeforeEventTime,
//     dateAndTimeFormat,
//     eventTimeZone ?? USEasternTimezone
//   );
//   return dateFormat.utc().format(utcTimeFormat);
// };

export const setQueryParameters = (parameter: string, key: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, parameter.toString());
  window.history.replaceState(null, null, '?' + queryParams.toString());
};
export const getTimeZoneForCountry = (countryName: string) => {
  return ct.getTimezonesForCountry(countryName);
};
export const timeZones = () => {
  const timeZone = [
    ...getTimeZoneForCountry('US'),
    ...getTimeZoneForCountry('CA'),
    ...getTimeZoneForCountry('DE'),
  ];
  timeZone.forEach((item, i) => {
    item.id = i + 1;
  });
  return timeZone;
};

// Get time in HH:mm format only
export const getRecurringUTCTime = (
  date: string,
  time: string,
  timezone?: string
) => {
  const finalDate = date && time ? date + ' ' + time : date;
  const timeFormat = date && time ? dateAndTimeFormat : datepickerformat;
  // construct a moment object
  const momentObj = moment.tz(finalDate, timeFormat, timezone);

  // convert it to utc
  momentObj.utc();

  // format it for output
  const startEventTime = momentObj.format(
    date && time ? utcTimeFormat : EVENT_FORMAT
  );
  return startEventTime;
};

export const getUSEasternTimeZone = (
  date: string,
  timeFormat: string,
  timeZone?: string
) => {
  if (date) {
    const startEventTime = moment(date)
      .tz(timeZone ?? defaultTimezone)
      .format(timeFormat);
    return startEventTime;
  }
};

export const getUTCTimeFromDateTime = (date: string, format: string) => {
  return moment(date).utc().format(format);
};

export const findObjectFromArray = (array, key, data) => {
  return array.find((item) => item[key] === data[key]);
};

export const recepients = (
  allUsers: number,
  attendees: number,
  rsvps: number
) => {
  return [
    {
      recepients: `All Users ${allUsers > 0 ? `(${allUsers})` : ''}`,
      recipientType: 1,
      isDisabled: allUsers > 0 ? false : true,
    },
    {
      recepients: `All RSVPed ${rsvps > 0 ? `(${rsvps})` : ''}`,
      recipientType: 2,
      isDisabled: rsvps > 0 ? false : true,
    },
    {
      recepients: `All Attendees ${attendees > 0 ? `(${attendees})` : ''}`,
      recipientType: 3,
      isDisabled: attendees > 0 ? false : true,
    },
  ];
};

export const linkString = (link: string) => {
  if (!link) {
    return;
  }
  if (link.includes('http')) {
    return link;
  }
  return `https://${link}`;
};

export const getDateFormatted = (date: string, format: string) => {
  return moment(date).format(format);
};

export const getRegionTime = (
  date: string,
  format: string,
  timezone: string
) => {
  const convertedDate = moment.utc(date).tz(timezone).format(format);
  return convertedDate;
};

const downloadPdf = (ref, isRecurring = false, domId, ...args) => {
  const [buttonIds, textId, fileName] = args;
  html2canvas(ref, {
    allowTaint: true,
    useCORS: true,
    onclone: (document) => {
      if (domId) {
        document.getElementById(domId).style.display = 'block';
      }
      if (isRecurring) {
        document.querySelectorAll('#taskActionButtons').forEach((item) => {
          item['style']['display'] = 'none';
        });
      }
    },
  }).then((canvas) => {
    const imgData = canvas.toDataURL('image/jpeg');
    const HTML_Height = canvas.height;
    const HTML_Width = canvas.width;
    const top_left_margin = 15;
    const PDF_Width = HTML_Width + top_left_margin * 2;
    const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    const canvas_image_width = HTML_Width;
    const canvas_image_height = HTML_Height;
    const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
    pdf.addImage(
      imgData,
      'JPG',
      top_left_margin,
      top_left_margin,
      canvas_image_width,
      canvas_image_height
    );

    for (let i = 1; i <= totalPDFPages; i++) {
      if (isRecurring) {
        document.getElementById(buttonIds).style.display = 'none';
        document.getElementById(textId).style.display = 'none';
      }
      pdf.addPage();
      pdf.addImage(
        imgData,
        'JPG',
        top_left_margin,
        -(PDF_Height * i) + top_left_margin * 4,
        canvas_image_width,
        canvas_image_height
      );
    }
    pdf.save(`${fileName ?? 'tasklist'}.pdf`);
  });
};

export const printDocument = (inputRef, fileName, domId) => {
  downloadPdf(inputRef.current, false, domId, fileName);
};

export const downloadRecurringDocument = (
  inputRef,
  fileName,
  domId,
  buttonIds,
  textId
) => {
  downloadPdf(inputRef.current, true, domId, buttonIds, textId, fileName);
};

export const getMonthStartDay = (date: string, year: number) => {
  const month = new Date(date).getMonth();
  const firstDay = new Date(year, month, 1);
  const initialDate = moment(firstDay).format(EVENT_FORMAT);
  return initialDate;
};
export const CloseDropdownOnOutsideClick = (
  ref,
  menuOutsideClick,
  menuIsOpen
) => {
  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (ref.current && !ref.current.contains(event.target) && menuIsOpen) {
        menuOutsideClick(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, menuOutsideClick, menuIsOpen]);
};

export const defaultRegions = (env = process.env.REACT_APP_ENV) => {
  if (env === 'production') {
    return productionDefaultRegions;
  } else if (env === 'staging') {
    return productionDefaultRegions;
  } else if (env === 'qaserver') {
    return qaDefaultRegions;
  } else {
    return qaDefaultRegions;
  }
};

export const redirectPortWise = (
  routeUrl: string,
  runningPort: number,
  history
) => {
  switch (runningPort) {
    case 4111:
    case 3001:
      window.location.href = `${routeUrl}`;
      break;
    default:
      history.push(routeUrl);
  }
};
