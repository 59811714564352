import { http } from '../common/http.service';
import {
  RECORDS_LIMIT,
  RECORDS_CMP,
  RECORDS_PAGE,
  RECORDS_QUERY,
  ALL_RECORDS,
  STAFF_BASE_URL,
} from '../../constants/index';
import { Staff, PaginatedResponse, DeleteResponse } from '../../models';
import { AxiosResponse } from 'axios';

export const getStaffApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS
): Promise<PaginatedResponse<Staff>> =>
  http
    .get<PaginatedResponse<Staff>>(
      `${STAFF_BASE_URL}?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Staff>>) => {
      return response.data;
    });

export const getStaffWithMngIdApi = async (
  managementId: number,
  buildingId: number,
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS
): Promise<PaginatedResponse<Staff>> =>
  http
    .get<PaginatedResponse<Staff>>(
      `${STAFF_BASE_URL}/by-management/${managementId}?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all=${all}&building_id=${buildingId}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Staff>>) => {
      return response.data;
    });

export const addStaffApi = async (data: Staff): Promise<Staff> =>
  http
    .post<Staff>(`${STAFF_BASE_URL}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Staff>) => {
      return response.data;
    });

export const getStaffDetailApi = async (staffId: number): Promise<Staff> =>
  http
    .get<Staff>(`${STAFF_BASE_URL}/${staffId}`, { errorHandler: true })
    .then((response: AxiosResponse<Staff>) => {
      return response.data;
    });

export const updateStaffApi = async (
  staffId: number,
  data: Staff
): Promise<Staff> =>
  http
    .put<Staff>(`${STAFF_BASE_URL}/${staffId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Staff>) => {
      return response.data;
    });

export const deleteStaffApi = async (
  staffId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`${STAFF_BASE_URL}/${staffId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });
