import * as autosaveTypes from './autosave.constant';
import { IAutoSaveState, initialAutoSaveState } from './autosave.state';

export const autoSave = (
  state: IAutoSaveState = initialAutoSaveState,
  action
): IAutoSaveState => {
  switch (action.type) {
    case autosaveTypes.STARTED:
      return {
        autoSaved: true,
      };
    case autosaveTypes.STOPED:
      return {
        autoSaved: false,
      };
    default:
      return state;
  }
};
