import { apiStatusCheck } from '../../utils/index';
import { uploadImageApi } from './common.service';
import * as loadingTypes from '../loader/loader.constant';
import * as formTypes from '../form/form.constant';

export const imageUpload = (file) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const imageData = await uploadImageApi(file);

  if (apiStatusCheck(imageData)) {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    return imageData?.['data']?.['data'];
  }
};

export const removeImage = (data: any, formType: string) => async (
  dispatch
) => {
  dispatch({
    type:
      formType === 'setupForm'
        ? formTypes.UPDATE_SERVICE_SETUP_FORM
        : formType === 'buildingForm'
        ? formTypes.UPDATE_BUILDING_FORM
        : formType === 'spaceForm'
        ? formTypes.UPDATE_SPACE_FORM
        : formType === 'whitelabel'
        ? formTypes.UPDATE_BUILDING_WHITELABEL_FORM
        : formTypes.UPDATE_COMPANY_INDIVIDUAL_CERTIFICATE_IMAGES,
    payload: data,
  });
};

export const stopLoading = () => {
  return {
    type: loadingTypes.LOADING_STOPED,
  };
};
export const startLoading = () => {
  return {
    type: loadingTypes.LOADING_STARTED,
  };
};
