import React from 'react';
import { PrimaryLabel } from '@alfredclub/hom-planner-theme';

const LabelWithIcon: React.FC<{
  imgSrc?: string;
  labelText: string;
  className?: string;
  imgClass?: string;
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
}> = ({ imgSrc, labelText, className, imgClass, onClick }) => {
  return (
    <>
      {imgSrc && (
        <img
          src={imgSrc}
          alt="icon"
          className={`inline-flex pr-4 ${imgClass}`}
          onClick={onClick}
        />
      )}
      <PrimaryLabel
        className={`sub-header ${className}`}
        labelText={labelText}
      />
    </>
  );
};

export default LabelWithIcon;
