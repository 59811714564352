import React, { useState } from 'react';
import FormTitle from './FormTitle';
import { ASWHContractOptions, ASWHfirstPoc } from '../../../../constants/index';
import { Input, RadioGroup } from '@alfredclub/hom-planner-theme';
import { useDefaultValueRadioGroup } from '../../../../hooks';
import {
  getUploadedImageUrl,
  loadState,
  // loadState,
  splitImageName,
} from '../../../../utils';
import { useDispatch } from 'react-redux';
import { removeImage } from '../../../../redux/common/common.action';
import FileInput from '../../../Common/FileInput';
import { Register, SetValue } from '../../../../models';
import { Building } from '../../../../models';

const AssociationWithHom: React.FC<{
  register: Register;
  setValue: SetValue;
  buildingData: Building;
  isEdit: boolean;
  reset: (
    values?: Record<string, any>,
    omitResetState?: Record<string, boolean>
  ) => void;
  refreshBuildingForm: (buildiingForm: Building) => void;
}> = ({
  register,
  setValue,
  buildingData,
  isEdit,
  reset,
  refreshBuildingForm,
}) => {
  const [firstPocOptions, setFirstPocOptions] = useState(ASWHfirstPoc);
  const [contractOptions, setContractOptions] = useState(ASWHContractOptions);
  useDefaultValueRadioGroup(
    buildingData,
    'contract',
    contractOptions,
    setContractOptions,
    isEdit
  );
  useDefaultValueRadioGroup(
    buildingData,
    'pointOfContact',
    firstPocOptions,
    setFirstPocOptions,
    isEdit
  );
  const onChange = (e, index, arrayKey, name, setAction) => {
    const updatedOptions = arrayKey.filter((item, itemIndex) => {
      if (itemIndex !== index) {
        item.checked = false;
      } else {
        arrayKey[index].checked = e.currentTarget.checked;
      }
      return arrayKey;
    });
    setValue(
      name,
      name !== 'pointOfContact'
        ? arrayKey[index].value === false
          ? false
          : true
        : arrayKey[index].value
    );
    setAction([...updatedOptions]);
  };

  const dispatch = useDispatch();
  const onRemoveImage = async (name: string) => {
    await dispatch(removeImage({ [name]: { id: null } }, 'buildingForm'));
    reset(loadState('buildingForm')); // File list error resolved
    refreshBuildingForm(loadState('buildingForm')); // Refresh New state
  };

  return (
    <>
      <FormTitle title="Association with HOM" />
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div className="col-span-2">
          <RadioGroup
            className="w-48"
            options={firstPocOptions}
            isLabelShow={true}
            label="First point of contact"
            onChange={(_, index) =>
              onChange(
                _,
                index,
                firstPocOptions,
                'pointOfContact',
                setFirstPocOptions
              )
            }
            ref={register({ name: 'pointOfContact' })}
          />
        </div>
        <div>
          <RadioGroup
            options={contractOptions}
            label="Contract"
            onChange={(_, index) =>
              onChange(
                _,
                index,
                contractOptions,
                'contract',
                setContractOptions
              )
            }
            isLabelShow={true}
            ref={register({ name: 'contract' })}
          />
        </div>
        <div>
          {!buildingData?.['contractFile']?.['file'] ? (
            <FileInput
              label="Contract document"
              isLabelShow={true}
              inputSectionClass="m-0"
              name="contractFile.id"
              ref={register}
            />
          ) : (
            <Input
              isLabelShow={true}
              isAnchor={true}
              anchorHref={getUploadedImageUrl(
                buildingData?.['contractFile']?.['file']
              )}
              label="Contract document"
              anchorText={`${splitImageName(
                buildingData?.['contractFile']?.['file']
              )}`}
              onRemoveImage={() => onRemoveImage('contractFile')}
            />
          )}
        </div>
        <div>
          <Input
            isLabelShow={true}
            label="Contract lapses on"
            ref={register({ name: 'contractLapse' })}
            onDateChange={(date) => setValue('contractLapse', date)}
            showDatepicker={true}
            placeholder="Select Date"
            selectedDate={new Date()}
            // selectedDate={
            //   buildingData?.['contractLapse'] !== ''
            //     ? new Date(buildingData?.['contractLapse'])
            //     : new Date()
            // }
          />
        </div>
      </div>
    </>
  );
};

export default AssociationWithHom;
