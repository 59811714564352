import React, { Fragment, useEffect, useState } from 'react';
import {
  Dropdown,
  PrimaryLabel,
  ToggleButton,
} from '@alfredclub/hom-planner-theme';
import FormTitle from '../../Clients/Building/AddBuilding/FormTitle';
import { Register, SetValue } from '../../../models/commonTypes';
import { selectedTagColor, selectedTagTextColor } from '../../../constants';
import {
  getCategoryTags,
  getProductionTeam,
  IServiceState,
} from '../../../redux/service';
import { useSelector, useDispatch } from 'react-redux';
import { IProductionTeamProps } from '../../../models';
import { IFormState, updateServiceSetupForm } from '../../../redux/form';

const ProductionTeam: React.FC<{
  formErrors: Record<string, object>;
  register: Register;
  setValue: SetValue;
  onAddProductionTeam: any;
  fields: IProductionTeamProps[];
  unregister: Register;
  setFieldChanges: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  fieldChange: boolean;
  isEdit: boolean;
  onDeleteProdTeam: (index: number) => void;
  setFieldDelete: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  fieldDelete: boolean;
}> = ({
  register,
  setValue,
  onAddProductionTeam,
  fields,
  formErrors,
  unregister,
  setFieldChanges,
  fieldChange,
  isEdit,
  onDeleteProdTeam,
  setFieldDelete,
  fieldDelete,
}) => {
  const dispatch = useDispatch();
  const { categoryTagsList, productionTeamList } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const { serviceSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [checked, setChecked] = useState(
    serviceSetupForm?.['productionTeamRequired']
  );
  const [deletedIndex, setDeletedIndex] = useState<number | null>(null);
  const [vendorTypeLoading, setVendorTypeLoading] = useState<boolean>(false);

  const onToggleChange = (e) => {
    setChecked(e.currentTarget.checked);
    onAddProductionTeam(
      e.currentTarget.checked,
      isEdit
        ? serviceSetupForm?.['productionTeams']?.length > 0
          ? false
          : true
        : true
    );
  };

  // Used this method to un-register the production team vendors fields on toggle OFF
  useEffect(() => {
    if (
      !checked &&
      Object.keys(formErrors).length > 0 &&
      Object.keys(formErrors?.['productionTeams']).length > 0
    ) {
      Object.keys(formErrors?.['productionTeams']).forEach((_, index) => {
        unregister(`productionTeams[${index}].vendorType`);
        unregister(`productionTeams[${index}].categoryTag`);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, formErrors?.['productionTeams']]);

  // Used this method to un-register the production team vendors fields on delete
  useEffect(() => {
    const deleteProdTeam = async () => {
      if (
        Object.keys(formErrors).length > 0 &&
        Object.keys(formErrors?.['productionTeams']).length > 0
      ) {
        unregister(`productionTeams[${deletedIndex}].vendorType`);
        unregister(`productionTeams[${deletedIndex}].categoryTag`);
        await dispatch(
          updateServiceSetupForm({ productionTeams: fields ?? [] })
        );
      }
    };
    deleteProdTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedIndex, formErrors?.['productionTeams']]);

  const bindProductionCategory = async (item, index: number) => {
    const catTag = categoryTagsList?.filter(({ name }) => name === item.name);
    setValue(`productionTeams[${index}].categoryTag`, catTag[0]);
    fields[index]['vendorType'] = item;
    fields[index]['categoryTag'] = catTag[0];
    setFieldChanges(true);
    await dispatch(updateServiceSetupForm({ productionTeams: fields }));
  };

  return (
    <div className={`mt-16`}>
      <FormTitle title="Production Team" />
      <div className="grid grid-cols-2 gap-x-2 gap-y-0">
        <div>
          <ToggleButton
            label="Requirement"
            onChange={(e) => onToggleChange(e)}
            isLabelShow={checked}
            checked={checked}
            uncheckedText="No"
            checkedText="Yes"
            name="productionTeamRequired"
            ref={register}
          />
        </div>
      </div>
      {checked &&
        fields?.map((_, index) => {
          return (
            <Fragment key={index}>
              <div className="grid grid-cols-2 gap-x-2 gap-y-0">
                <div className="relative inline-grid">
                  <Dropdown
                    asyncDropdown={false}
                    labelKey="name"
                    valueKey="id"
                    isLabelShow={true}
                    label="Vendor type"
                    options={productionTeamList}
                    onMenuOpen={async () => {
                      setVendorTypeLoading(true);
                      if (productionTeamList.length === 0) {
                        await dispatch(getProductionTeam());
                      }
                      if (categoryTagsList.length === 0) {
                        await dispatch(getCategoryTags());
                      }
                      setVendorTypeLoading(false);
                    }}
                    ref={() =>
                      register(
                        { name: `productionTeams[${index}].vendorType` },
                        { required: true }
                      )
                    }
                    isError={
                      formErrors?.['productionTeams']?.[`${index}`]?.[
                        `vendorType`
                      ]
                        ? true
                        : false
                    }
                    onOptionChange={(e) => {
                      setValue(`productionTeams[${index}].vendorType`, e);
                      bindProductionCategory(e, index);
                    }}
                    placeholder=""
                    className="drop_input_label"
                    value={fields?.[index]?.vendorType ?? null}
                    loading={vendorTypeLoading}
                    resetValue={fieldDelete ? true : false}
                  />
                  <div className="delete-prod-team">
                    <label
                      onClick={() => {
                        setFieldDelete(true);
                        setDeletedIndex(index);
                        onDeleteProdTeam(index);
                      }}
                    >
                      Delete
                    </label>
                  </div>
                </div>
                <div>
                  <Dropdown
                    asyncDropdown={false}
                    labelKey="name"
                    valueKey="id"
                    isLabelShow={true}
                    isMulti={true}
                    disabled={true}
                    label="Category Tag"
                    options={categoryTagsList}
                    ref={() =>
                      register(
                        {
                          name: `productionTeams[${index}].categoryTag`,
                        },
                        { required: true }
                      )
                    }
                    isError={
                      formErrors?.['productionTeams']?.[`${index}`]?.[
                        `categoryTag`
                      ]
                        ? true
                        : false
                    }
                    placeholder=""
                    value={
                      fields?.[index]?.['categoryTag']?.['id']
                        ? fields?.[index]?.['categoryTag']
                        : null
                    }
                    className="service_input_width categoryTag"
                    backgroundColor={selectedTagColor}
                    textColor={selectedTagTextColor}
                    resetValue={fieldChange ? true : false}
                  />
                </div>
              </div>
            </Fragment>
          );
        })}
      {checked && (
        <div>
          <PrimaryLabel
            labelText="+Add another"
            onClick={(e) => onAddProductionTeam(e, true)}
            labelColor="#e89a89"
            className="cursor-pointer underline"
          />
        </div>
      )}
    </div>
  );
};

export default ProductionTeam;
