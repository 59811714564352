import React from 'react';
import { Loader } from '@alfredclub/hom-planner-theme';

const CommonLoader: React.FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <Loader
      color="#e89a89"
      position="center"
      loading={loading}
      size="0.75rem"
    />
  );
};
export default CommonLoader;
