export interface ILoaderState {
  loading: boolean;
}

export interface ILoadingStatusState {
  status: string;
}

export const initialLoaderState: ILoaderState = {
  loading: false,
};
