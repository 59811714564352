import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader, Table } from '@alfredclub/hom-planner-theme';
import {
  getServiceElements,
  getServiceCategoryTags,
  getProductionTeam,
} from '../../../redux/service/services.action';
import { usePagination, useFetch } from '../../../hooks';
import { useForm } from 'react-hook-form';
import Footer from '../../Common/Footer';
import { findIndexFromArrayOfObject, loadState } from '../../../utils';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  clearPackageElementsForm,
  updatePackageElements,
} from '../../../redux/form/form.action';
import { ILoaderState } from '../../../redux/loader';
import { IFormState } from '../../../redux/form';
import { IServiceState } from '../../../redux/service';

const ElementListing = () => {
  const { handleSubmit } = useForm();
  const { push } = useHistory();
  const params = useParams();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [pageNo, setPage] = useState(0);

  const serviceId = parseInt(params['serviceId'], 10);
  const { serviceSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [isEdit] = useState(serviceId ? true : false);
  const [editServiceData] = useState(
    isEdit ? loadState('serviceSetupForm') : serviceSetupForm
  );

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const [elementArray, setPackageElement] = useState(
    serviceId ? editServiceData?.['elementsAssociated'] : []
  );

  const { status } = useFetch(getServiceElements);
  const { pages, serviceCount } = useSelector(
    (state: { services: IServiceState }) => state.services
  );
  const pagination = usePagination();

  const onPageClick = async (page: number) => {
    await pagination(pages, page - 1, getServiceElements);
    setPage(page - 1);
  };
  const query = new URLSearchParams(search);
  // Decrypt the value of popup fields
  const [serviceType] = useState(query.get('serviceType'));
  const [servicePopupData] = useState(
    query.get('service') ? JSON.parse(atob(query.get('service'))) : null
  );

  // Code added for Edit case (Tab click)
  useEffect(() => {
    async function getElements() {
      if (isEdit) {
        await dispatch(clearPackageElementsForm());
        await dispatch(updatePackageElements(elementArray));
      }
    }
    getElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, dispatch]);

  const onSubmit = async () => {
    const elementsAssociated = elementArray.map((data: any) => ({
      id: data.id,
    }));
    const elementIds =
      elementsAssociated?.length > 0
        ? elementsAssociated.map(({ id }) => id)
        : [];
    await dispatch(getServiceCategoryTags(elementIds));
    await dispatch(getProductionTeam(elementIds));
    await dispatch(updatePackageElements(elementArray));
    if (!serviceId) {
      push(
        `/data-resources/services/add-service?serviceType=${serviceType}&tab=setup&service=${btoa(
          JSON.stringify(servicePopupData)
        )}`
      );
    } else {
      push(
        `/data-resources/services/edit-service/${serviceId}?tab=setup&serviceType=${serviceType}`
      );
    }
  };

  const handleOnChange = async (rowData?: any) => {
    const selectedElement = elementArray;
    const elementIdIndex = findIndexFromArrayOfObject(
      selectedElement,
      'id',
      rowData
    );
    if (elementIdIndex !== -1) {
      selectedElement.splice(elementIdIndex, 1);
    } else {
      selectedElement.push({ ...rowData });
    }
    setPackageElement(() => [...selectedElement]);
  };

  useEffect(() => {
    query.set('pageNo', pageNo.toString());
    window.history.replaceState(null, null, '?' + query.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  return (
    <div>
      {status === 'fetched' ? (
        <div className="element_section mx-10 mt-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Table
              tableColumns={[
                {
                  id: 'checkbox',
                  Header: 'Sno',
                  accessor: 'id',
                  Cell: (row: any) => {
                    return (
                      <div className="table_checkbox">
                        <input
                          type="checkbox"
                          className="checkbox"
                          onChange={() => {
                            handleOnChange(row.row.original);
                          }}
                          checked={
                            elementArray.length > 0
                              ? findIndexFromArrayOfObject(
                                  elementArray,
                                  'id',
                                  row.row.original
                                ) !== -1
                                ? true
                                : false
                              : false
                          }
                        />
                      </div>
                    );
                  },
                },
                {
                  Header: 'Service name',
                  className: 'tableAnchor',
                  accessor: (row: any) => <>{row.name}</>,
                },
                {
                  Header: 'Category',
                  accessor: 'category',
                },
                {
                  Header: 'Configuration',
                  accessor: (row: any) =>
                    !row.isPackage ? 'Element' : 'Package',
                },
                {
                  Header: 'Mode of Delivery',
                  accessor: (row: any) =>
                    !row.virtualMode ? 'In-person' : 'Virtual',
                },
                {
                  Header: 'Service Status',
                  accessor: (row: any) => (!row.status ? 'Inactive' : 'Active'),
                  className: 'element_last_column',
                },
              ]}
              tableData={pages?.get(pageNo)?.data}
              showPagination={!(serviceCount < 10) && true}
              totalRecordsCount={serviceCount}
              onPageChange={(pageNumber) => onPageClick(pageNumber)}
              className="mt-2"
              onCellMouseEnter={() => {}}
            />
            <Footer buttonText="Continue" isArrow={true} showLoader={loading} />
          </form>
        </div>
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
    </div>
  );
};
export default ElementListing;
