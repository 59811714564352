import * as regionTypes from './region.constant';
import { initialRegionState, IRegionState } from './region.state';

export const regionsReducer = (
  state: IRegionState = initialRegionState,
  action: { type: string; payload: any; regionCount: number }
): IRegionState => {
  switch (action.type) {
    case regionTypes.GET_REGIONS:
      return {
        ...state,
        regions: [...action.payload],
        regionCount: action.regionCount,
      };
    case regionTypes.GET_TIMEZONE:
      return {
        ...state,
        timezones: [...action.payload],
      };
    default:
      return state;
  }
};
