import React, { useEffect, useState } from 'react';
import CommonTabComponent from '../Common/CommonTabComponent';
import CancelledEvents from './CancelledEvents';
import DraftEvents from './DraftEvents';
import PublishedEvents from './PublishedEvents';
import SuperHeroVendors from './SuperHeroVendors';
import { Topnav, Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  clearBuildingsByTimezone,
  clearEventBuildingForm,
  clearEventInviteesForm,
  clearEvents,
  clearFilterServiceList,
  clearSearchFor,
  clearVendorInviteState,
  getEvents,
  getEventsCount,
  publishEvent,
  setEventDateType,
  setSearchEvent,
  setSearchFor,
  setSelectedDate,
  getHeroVendors,
  clearEventTaskGroupList,
  setMonthInfo,
  setCategoryFilters,
} from '../../redux/event/event.action';
import {
  EVENT_FORMAT,
  EVENT_DATE_FORMAT,
  TOOLTIP_TEXT,
  profileOptions,
  eventStandardFormat,
  MULTIPLE_REGIONS,
  MULTIPLE_BUILDINGS,
} from '../../constants';
import {
  formattedDate,
  getImageUrl,
  getQueryParameters,
  getESTTime,
  setQueryParameters,
  getDateFormatted,
} from '../../utils';
import ReactTooltip from 'react-tooltip';
import { clearEventSetupForm, clearRecurringSetupForm } from '../../redux/form';
import { IEventsState } from '../../redux/event';
import { eventSearchOptions } from '../../constants/index';
import CreateEventPopup from '../Events/CreateEventPopup';
import { clearAuth } from '../../redux/authentication';
import './EventListing.scss';
import { MenuType, SearchType } from '../../enums';
import { clearEventSpaces } from '../../redux/space';
import { getBuildings } from '../../redux/building/buildings.action';
import { getVendors } from '../../redux/vendor/vendors.action';
import { getBuildingUsers } from '../../redux/user/users.action';
import GenerateReport from '../Common/GenerateReport';

const EventsListing = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const {
    eventDateType,
    eventSearchBy,
    eventSearchFor,
    searchSelectedDate,
    monthInfo,
  } = useSelector((state: { events: IEventsState }) => state.events);
  const tab = getQueryParameters('tab');
  const [newEvent, toggleNewEvent] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('idle');
  const [pastEvent, setPasEvent] = useState<boolean>(false);
  const [selectedCalendarDate, setDate] = useState<string>(eventDateType.date);
  const [eventMonth] = useState({});
  const [calendarSelectedDate, clearcalendarSelectedDate] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [blockEffect, setblockEffect] = useState<boolean>(false);
  const [universalSearch, setUniversalSearch] = useState<string>('');
  const [pageNo, setPage] = useState(() =>
    Number(getQueryParameters('pageNo'))
  );
  const [openReport, setOpenReport] = useState<boolean>(false);

  const isSearchActive = () => {
    if (Object.keys(eventSearchFor).length > 0) {
      return true;
    }
    return false;
  };
  const columns = [
    {
      Header: ' ',
      Cell: ({ row }) => {
        return (
          <span
            data-tip={
              row.original.virtual
                ? TOOLTIP_TEXT.events.virtual
                : TOOLTIP_TEXT.events.inPerson
            }
          >
            <img
              src={
                row.original.virtual
                  ? getImageUrl('Events/virtual.svg')
                  : getImageUrl('Events/inperson.svg')
              }
              alt="virtual"
            />
            <ReactTooltip />
          </span>
        );
      },
      className: `table-cell ${tab === 'drafts' ? 'table-img-draft' : 'table-img'
        }`,
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return (
          <span>
            {getDateFormatted(row.original.sourceDate, EVENT_DATE_FORMAT)}
          </span>
        );
      },
      className: `table-cell table-date-col ${tab === 'drafts' && 'table-booking'
        }`,
    },
    {
      Header: 'Time',
      Cell: ({ row }) => {
        return (
          <span>
            {getESTTime(
              row.original.startDatetime,
              eventStandardFormat,
              row.original.eventZoneValue
            )}{' '}
            {row.original.eventTimezone && `${row.original.eventTimezone}`}
          </span>
        );
      },
      className: `table-cell table-time-col ${tab === 'drafts' && 'table-booking'
        }`,
    },
    {
      Header: 'Event Name',
      Cell: ({ row }) => {
        return (
          <>
            <span
              onClick={() => handleCellClick(row.original)}
              data-tip={row.original.displayName}
            >
              {row.original.displayName !== ''
                ? row.original.displayName
                : '-----'}
              <ReactTooltip />
            </span>

            {/* <Loader
              color="#E89A89"
              position="left"
              loading={showLoader && setRowClick === row.original.id}
              size="0.75rem"
              className={`${
                showLoader &&
                setRowClick === row.original.id &&
                'events_row_loader'
              }`}
            /> */}
          </>
        );
      },
      className: `tableAnchor w-40 custom-event-name ${tab === 'drafts' && 'table-booking'
        }`,
    },
    {
      Header: 'Building name',
      Cell: ({ row }) => {
        return (
          <span>{`${isSearchActive() && eventSearchBy?.['value'] === 'buildings'
            ? eventSearchFor?.['name']
            : row?.original?.buildingList?.length > 1
              ? MULTIPLE_BUILDINGS
              : row?.original?.buildingList[0]
            }`}</span>
        );
      },
      className: `table-cell ${tab === 'drafts' && 'table-booking'}`,
    },
    {
      Header: 'Market',
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.regionList?.length > 1
              ? MULTIPLE_REGIONS
              : row.original.regionList?.[0]}
          </span>
        );
      },
      className: `table-cell ${tab === 'drafts' && 'table-booking'}`,
    },
    {
      Header: 'Booking type',
      Cell: ({ row }) => {
        return <span>{row.original.recurring ? 'Recurring' : 'Single'}</span>;
      },
    },
    {
      Header: `${isSearchActive() && eventSearchBy?.['value'] === 'vendors'
        ? 'VendorName'
        : 'Vendor status'
        }`,
      Cell: ({ row }) => {
        return !isSearchActive() ? (
          <span className={`${row.original.vendorStatus && `pending_color`}`}>
            {!row.original.vendorStatus ? 'Assigned' : 'Pending'}
          </span>
        ) : (
          <span>{`${eventSearchBy?.['value'] === 'vendors'
            ? row.original?.vendors
              ? row.original?.vendors
              : 'Not assigned'
            : !row.original.vendorStatus
              ? 'Assigned'
              : 'Pending'
            }`}</span>
        );
      },
      className: 'custom_vendor_status_cell',
    },
    {
      Header: '  ',
      Cell: ({ row }) => {
        return (
          <Button
            label="Publish"
            onClick={() => eventPublish(row.original)}
            className={!pastEvent ? 'btn-publish' : 'btn-publish-disabled '}
            disabled={pastEvent}
          />
        );
      },
      className: `table-cell last-cell ${tab !== 'drafts' && 'hidden'}`,
    },
  ];
  // This code is kept for future reference
  // const showSearchName = (value) => {
  //   if (!isSearchActive()) {
  //     switch (eventSearchBy?.['value']) {
  //       case SearchType.BUILDINGS:
  //         return value?.buildingList;
  //       case SearchType.VENDORS:
  //         return value?.vendors ? value?.vendors : 'Not assigned';
  //       case SearchType.USERS:
  //         return 'Multiple Users';
  //     }
  //   } else {
  //     return value?.buildingList;
  //   }
  // };
  const getIdKey = () => {
    switch (eventSearchBy?.['value']) {
      case SearchType.VENDORS:
        return 'vendorId';
      default:
        return 'id';
    }
  };

  const { push } = useHistory();
  const dispatch = useDispatch();
  const [modalState, toggleModal] = useState<boolean>(false);
  const [yearly, setYearly] = useState<boolean>(false);
  let isDateSearchActive = searchSelectedDate ? true : false;

  // Used in top level search
  const commonSetEventDateType = async (
    Date: string,
    Year: number,
    Yearly: string,
    tabName: string,
    perDay: number,
    Query?: string | number,
    type?: string,
  ) => {
    await dispatch(
      setEventDateType({
        date: Date,
        year: Year,
        yearly: Yearly,
        tab: tabName,
        per_day: perDay,
        query: Query,
        searchType: type,
      })
    );
  };

  // Check past date
  const checkForPast = (date: string) => {
    const dateSelected = moment(date).format(EVENT_FORMAT);
    const currentDate = moment().format(EVENT_FORMAT);
    setPasEvent(moment(currentDate).isAfter(dateSelected));
  };

  // Get initial date of selected/current month
  const getMonthFirstDay = () => {
    const firstDay = new Date(currentYear, currentMonth, 1);
    const initialDate = moment(firstDay).format(EVENT_FORMAT);
    return initialDate;
  };

  // common method for fetching event count
  const fetchEventCounts = async (
    month = null,
    year = null,
    query?,
    searchType?,
    keywords?
  ) => {
    if (yearly) {
      await dispatch(
        getEventsCount(
          String(year ?? currentYear),
          '',
          getQueryParameters('tab'),
          query,
          searchType,
          keywords
        )
      );
    } else {
      await dispatch(
        getEventsCount(
          String(year ?? currentYear),
          // month === 0 ? month + 1 : month > 0 ? month + 1 : currentMonth + 1,
          month ?? currentMonth + 1,
          getQueryParameters('tab'),
          query,
          searchType,
          keywords
        )
      );
    }
  };

  const onTabClick = async (tabName: string, onMount = false) => {
    setStatus('fetching');
    setPage(0);
    await dispatch(clearEvents());
    if (isSearchActive()) {
      const updatedItem = eventDateType;
      updatedItem['query'] = eventSearchFor?.[getIdKey()];
      updatedItem['searchType'] = eventSearchBy?.['value'];
      await dispatch(setEventDateType(eventDateType));
      await preventUseEffect('');
      if (!onMount) {
        await dispatch(setSelectedDate(''));
        isDateSearchActive = false;
      }
    }
    if (tabName !== 'superhero') {
      await dispatch(
        getEvents(
          tabName,
          0,
          eventDateType.per_day,
          eventDateType.date,
          eventDateType.yearly,
          eventDateType.year,
          eventDateType.query,
          eventDateType.searchType
        )
      );
    } else {
      await dispatch(
        getHeroVendors(
          10,
          0,
          'short',
          0,
          isSearchActive() && isDateSearchActive
            ? searchSelectedDate
            : eventDateType.date,
          eventDateType.per_day
        )
      );
    }
    if (!onMount) {
      await commonSetEventDateType(
        monthInfo?.date ?? getMonthFirstDay(),
        eventDateType.year,
        eventDateType.yearly,
        tabName,
        0,
        eventDateType.query,
        eventDateType.searchType,
      );
    }
    await dispatch(
      setCategoryFilters({
        regionSelected: 0,
        vendorFiltered: '',
      })
    );
    setStatus('fetched');
    checkForPast(monthInfo?.date ?? eventDateType.date);
  };

  const clearMonthSelection = () => {
    commonSetEventDateType(
      moment(eventMonth).format(EVENT_FORMAT),
      currentYear,
      '',
      getQueryParameters('tab'),
      0,
      eventDateType.query,
      eventDateType.searchType,
    );
  };

  const onMonthChange = async (e) => {
    const preventMount = '';
    const firstDay = new Date(e.year, e.month, 1);
    const initialDate = moment(firstDay).format(EVENT_FORMAT);
    setDate(initialDate);
    checkForPast(initialDate);
    setStatus('fetching');
    setPage(0);
    const monthData = {
      date: initialDate,
      month: e.month + 1,
      year: e.year,
      utcDate: moment(initialDate).toISOString()
    };
    await dispatch(setMonthInfo(monthData));
    await dispatch(
      setCategoryFilters({
        regionSelected: 0,
        vendorFiltered: '',
      })
    );
    if (getQueryParameters('tab') !== 'superhero') {
      await fetchEventCounts(
        e.month + 1,
        e.year,
        searchValue,
        eventDateType.searchType
      );
      await dispatch(
        getEvents(
          getQueryParameters('tab'),
          0,
          0,
          initialDate,
          '',
          e.year,
          searchValue,
          eventDateType.searchType
        )
      );
    } else {
      await fetchEventCounts(e.month + 1, e.year);
      await dispatch(
        getHeroVendors(10, 0, 'short', 0, initialDate, eventDateType.per_day)
      );
    }
    if (!preventMount) {
      setblockEffect(true);
      await commonSetEventDateType(
        initialDate,
        e.year,
        '',
        getQueryParameters('tab'),
        0,
        searchValue,
        // eventSearchBy?.['value'] ?? eventDateType.searchType,
        eventDateType.searchType,
      );
    }
    setblockEffect(false);
    setStatus('fetched');
  };

  const onDateChange = async (date) => {
    checkForPast(date);
    setQueryParameters('0', 'pageNo');
    setDate(() => moment(date).format(EVENT_FORMAT));
    clearcalendarSelectedDate(false);
    // using equal check because !date.value is also true as !undefined = true
    if (!date || date.value === null) {
      commonSetEventDateType(
        // moment(eventMonth).format(EVENT_FORMAT),
        monthInfo?.date,
        currentYear,
        '',
        getQueryParameters('tab'),
        0,
        eventDateType.query,
        eventDateType.searchType,
      );
      return;
    }

    const dateLength = Object.keys(date).length;
    if (dateLength > 0) {
      commonSetEventDateType(
        '',
        date?.year,
        date?.value?.split('q')[1],
        getQueryParameters('tab'),
        0,
        eventDateType.query,
        eventDateType.searchType,
      );
      return;
    }

    if (isSearchActive()) {
      await dispatch(setSelectedDate(moment(date).format(EVENT_FORMAT)));
      commonSetEventDateType(
        moment(date).format(EVENT_FORMAT),
        currentYear,
        '',
        getQueryParameters('tab'),
        1,
        // eventSearchFor?.['vendorId'],
        // eventSearchBy?.['value']
        eventDateType.query,
        eventDateType.searchType,
      );
      return;
    }

    commonSetEventDateType(
      moment(date).format(EVENT_FORMAT),
      currentYear,
      '',
      getQueryParameters('tab'),
      1,
      eventDateType.query,
      eventDateType.searchType,
    );
  };
  // required for reference will remove later
  // const setParams = (searchQuery) => {
  //   clearcalendarSelectedDate(true);
  //   const tType = getQueryParameters('tab');
  //   const page = 0;
  //   setUniversalSearch(searchQuery);
  //   if (!searchQuery) {
  //     setQueryParameters(page.toString(), 'pageNo');
  //     push(`/events?tab=${tType}&pageNo=0`);
  //   }
  //   setQueryParameters(page.toString(), 'pageNo');
  //   setPage(0);
  // };
  let timer = null;
  const promiseOptions = async (searchQuery) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
      (timer = setTimeout(async () => {
        const data = await dispatch(
          eventSearchBy?.['value'] === 'buildings'
            ? getBuildings(10, 0, 'short', searchQuery, 1, false)
            : eventSearchBy?.['value'] === 'vendors'
              ? getVendors(10, 0, 'short', searchQuery, 1, 1, false)
              : getBuildingUsers(0, 10, 0, 'short', searchQuery, 1)
        );
        res(data);
        setUniversalSearch(searchQuery);
      }, 1000))
    );
  };

  const clearEventFormReducer = async () => {
    // await dispatch(clearEventSetupForm());
    await dispatch(clearEventInviteesForm());
    await dispatch(clearRecurringSetupForm());
    await dispatch(clearEventBuildingForm());
    await dispatch(clearFilterServiceList());
    await dispatch(clearBuildingsByTimezone());
    await dispatch(clearEventSpaces());
    await dispatch(clearEventTaskGroupList());
  };

  const handleCellClick = async (e: any) => {
    await clearEventFormReducer();
    push(`/events/${e.id}/create?step=setup&edit=true`);
  };

  const eventPublish = async (row: any) => {
    const page = Number(getQueryParameters('pageNo'));
    await dispatch(publishEvent(true, Number(row.id), false));
    await dispatch(
      getEvents(
        'drafts',
        page,
        eventDateType.per_day,
        selectedCalendarDate,
        eventDateType.yearly,
        eventDateType.year
      )
    );
    fetchEventCounts();
  };

  const addNewEvent = async () => {
    await clearEventFormReducer();
    await dispatch(clearEventSetupForm());
    await dispatch(clearVendorInviteState());
    toggleNewEvent(true);
    toggleModalState();
  };

  const onMenuChange = async (id: number) => {
    switch (id) {
      case MenuType.Report:
        setOpenReport(true);
        return;
      case MenuType.Logout:
        onLogout();
        break;
    }
  };

  const onLogout = async () => {
    localStorage.clear();
    await dispatch(clearAuth());
    await commonSetEventDateType(
      formattedDate(),
      new Date().getFullYear(),
      '',
      'published',
      0,
    );
    const monthData = {
      date: formattedDate(),
      month: currentMonth + 1,
      year: new Date().getFullYear(),
      utcDate: moment(formattedDate()).toISOString()
    };
    await dispatch(setMonthInfo(monthData));
    push('/login');
  };

  const toggleModalState = () => {
    toggleModal(!modalState);
  };

  const preventUseEffect = async (
    preventMount: string,
    date?: string,
    clearSearch?: boolean
  ) => {
    if (!preventMount) {
      setblockEffect(true);
      await commonSetEventDateType(
        !date ? getMonthFirstDay() : date,
        eventDateType.year,
        '',
        getQueryParameters('tab'),
        0,
        clearSearch ? null : eventDateType.query,
        clearSearch ? '' : eventDateType.searchType,
      );
    }
    setblockEffect(false);
  };

  const clearSearch = async (withoutSearch?: boolean) => {
    const preventMount = '';
    await dispatch(clearSearchFor());
    clearcalendarSelectedDate(true);
    setSearchValue('');
    await dispatch(
      setCategoryFilters({
        regionSelected: 0,
        vendorFiltered: '',
      })
    );
    if (withoutSearch) {
      setStatus('fetching');
      await preventUseEffect(preventMount, monthInfo?.date, true);
      await fetchEventCounts(monthInfo?.month ?? currentMonth + 1);
      await dispatch(
        getEvents(
          getQueryParameters('tab'),
          0,
          0,
          monthInfo?.date ?? getMonthFirstDay(),
          eventDateType.yearly,
          eventDateType.year
        )
      );
      setStatus('fetched');
    }
  };

  const onOptionClick = async (option: object) => {
    const preventMount = '';
    if (option) {
      const query =
        eventSearchBy?.['value'] === 'vendors'
          ? option?.['vendorId']
          : option?.['id'];
      clearcalendarSelectedDate(true);
      setDate(monthInfo?.date ?? getMonthFirstDay());
      await dispatch(setSearchFor(option));
      const updatedItem = eventDateType;
      updatedItem['query'] = query;
      updatedItem['searchType'] = eventSearchBy?.['value'];
      // await preventUseEffect(preventMount, selectedCalendarDate);
      await preventUseEffect(preventMount, monthInfo?.date);
      setStatus('fetching');
      await dispatch(setEventDateType(eventDateType));
      await fetchEventCounts(
        monthInfo?.month ?? currentMonth + 1,
        currentYear,
        query,
        eventSearchBy?.['value']
      );
      await dispatch(
        getEvents(
          getQueryParameters('tab'),
          0,
          0,
          monthInfo?.date ?? getMonthFirstDay(),
          eventDateType.yearly,
          eventDateType.year,
          eventDateType.query,
          eventDateType.searchType
        )
      );
      setSearchValue(query);
      setStatus('fetched');
    }
  };
  const getEventsBySearchKeywords = async () => {
    await fetchEventCounts(
      monthInfo?.month ?? currentMonth + 1,
      currentYear,
      universalSearch,
      eventSearchBy?.['value'],
      1
    );
    await dispatch(
      getEvents(
        getQueryParameters('tab'),
        0,
        eventDateType.per_day,
        monthInfo?.date ?? getMonthFirstDay(),
        eventDateType.yearly,
        eventDateType.year,
        universalSearch,
        eventSearchBy?.['value'],
        1
      )
    );
  };
  const closeReportModal = () => {
    setOpenReport(!openReport);
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  useEffect(() => {
    if (!blockEffect) {
      onTabClick(getQueryParameters('tab'), true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDateType.date]);

  useEffect(() => {
    async function fetchCount() {
      if (isSearchActive()) {
        await fetchEventCounts(
          currentMonth,
          currentYear,
          eventSearchFor?.[getIdKey()],
          eventSearchBy?.['value']
        );
        return;
      }
      await fetchEventCounts();
    }
    fetchCount();
    // This code is required for reference
    // return () =>
    //   commonSetEventDateType(
    //     moment(eventMonth).format(EVENT_FORMAT),
    //     currentYear,
    //     '',
    //     getQueryParameters('tab'),
    //     0
    //   );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show this when searching something from Topnav
  const searchDescription = () => {
    return (
      <div className="search-context">
        <PrimaryLabel
          className="searching-in-message"
          labelText={`${eventSearchBy?.['value'] === 'buildings'
            ? 'Showing events in'
            : eventSearchBy?.['value'] === 'vendors'
              ? 'Showing events hosted by'
              : 'Showing events attended by'
            }`}
        />
        <div className="w-max">
          <PrimaryLabel
            className="searching-for"
            labelText={
              eventSearchFor?.['name'] || eventSearchFor?.['vendorName']
            }
          />
          <PrimaryLabel
            className="underline cancel-search px-2 cursor-pointer"
            labelText="Cancel"
            onClick={() => clearSearch(true)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Topnav
        headerTitle="Events Dashboard"
        showIcon={true}
        onClick={() => addNewEvent()}
        showEventDropdown={true}
        showSearchBar={true}
        placeholder={`Search ${eventSearchBy?.['value']}`}
        eventSearchOptions={eventSearchOptions}
        promiseOptions={promiseOptions}
        toolTipText={TOOLTIP_TEXT.events.createEvent}
        labelKey="label"
        valueKey="value"
        optionLabel={`${eventSearchBy?.['value'] === 'vendors' ? 'vendorName' : 'name'
          }`}
        optionKey={`${eventSearchBy?.['value'] === 'vendors' ? 'vendorId' : 'id'
          }`}
        dropdownClass="pl-4"
        defaultValue={eventSearchBy}
        profileOptions={profileOptions}
        onProfileChange={(e) => {
          onMenuChange(Number(e['value']));
        }}
        cacheOptions={false}
        onDropdownChange={async (e) => {
          await dispatch(
            setSearchEvent({ label: e?.['label'], value: e?.['value'] })
          );
          clearSearch();
        }}
        defaultSearchValue={isSearchActive() ? [eventSearchFor] : null}
        onSearchOptionSelect={(option) => onOptionClick(option)}
        onClickOfCloseIcon={() => clearSearch(true)}
        onClickOfSearchIcon={() => getEventsBySearchKeywords()}
        onEnterKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            getEventsBySearchKeywords();
          }
        }}
        headerTitleClass="pl-10"
      />
      {modalState && (
        <CreateEventPopup
          newEvent={newEvent}
          toggleEvent={() => toggleNewEvent(false)}
          toggleModal={toggleModalState}
          modalState={modalState}
        />
      )}
      {openReport && (
        <GenerateReport
          modalState={openReport}
          onClosePopup={closeReportModal}
        />
      )}
      {isSearchActive() && searchDescription()}
      <div
        className={`px-10 event-section ${isSearchActive() && 'event-section-aftersearch'
          }`}
      >
        <div className="listing-wrapper">
          <CommonTabComponent
            onTabClick={async (tabName) => {
              setStatus('fetching');
              setPage(0);
              await dispatch(
                getEventsCount(
                  monthInfo?.year?.toString() ?? String(currentYear),
                  monthInfo?.month ?? currentMonth + 1,
                  tabName,
                  !isSearchActive() ? null : eventSearchFor?.[getIdKey()],
                  !isSearchActive() ? '' : eventSearchBy?.['value']
                )
              );
              onTabClick(tabName);
            }}
            className="event-listing-tab"
          >
            <div data-label="Published" data-click={true}>
              <PublishedEvents
                status={status}
                columns={columns}
                onDateChange={onDateChange}
                onMonthChange={onMonthChange}
                setYearly={setYearly}
                date={selectedCalendarDate}
                calendarSelectedDate={calendarSelectedDate}
                clearcalendarSelectedDate={clearcalendarSelectedDate}
                yearly={yearly}
                clearMonthSelection={clearMonthSelection}
                searchQuery={searchValue}
                pageNo={pageNo}
                setPage={setPage}
                searchActive={isSearchActive()}
              />
            </div>
            <div data-label="Drafts" data-click={true}>
              <DraftEvents
                status={status}
                columns={columns}
                onMonthChange={onMonthChange}
                onDateChange={onDateChange}
                calendarSelectedDate={calendarSelectedDate}
                clearcalendarSelectedDate={clearcalendarSelectedDate}
                setYearly={setYearly}
                yearly={yearly}
                clearMonthSelection={clearMonthSelection}
                searchQuery={searchValue}
                pageNo={pageNo}
                setPage={setPage}
                date={selectedCalendarDate}
              />
            </div>
            <div data-label="Cancelled" data-click={true}>
              <CancelledEvents
                status={status}
                columns={columns}
                onDateChange={onDateChange}
                onMonthChange={onMonthChange}
                calendarSelectedDate={calendarSelectedDate}
                clearcalendarSelectedDate={clearcalendarSelectedDate}
                setYearly={setYearly}
                yearly={yearly}
                clearMonthSelection={clearMonthSelection}
                searchQuery={searchValue}
                pageNo={pageNo}
                setPage={setPage}
                date={selectedCalendarDate}
              />
            </div>
            <div data-label="Superhero" data-click={true}>
              <SuperHeroVendors
                status={status}
                onDateChange={onDateChange}
                onMonthChange={onMonthChange}
                calendarSelectedDate={calendarSelectedDate}
                clearcalendarSelectedDate={clearcalendarSelectedDate}
                setYearly={setYearly}
                yearly={yearly}
                clearMonthSelection={clearMonthSelection}
                searchQuery={searchValue}
                pageNo={pageNo}
                setPage={setPage}
              />
            </div>
          </CommonTabComponent>
        </div>
      </div>
    </>
  );
};
export default EventsListing;
