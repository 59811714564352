import React from 'react';
import ModalPopup from '../ReactModal/Modal';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';

const InventoryDeleteItemPopup: React.FC<any> = ({
  modalState,
  isDelete,
  modalClose,
  onDelete,
  headerText,
  subHeader,
  buttonLabel,
  cancelButtonLabel,
}: any) => {
  const onSubmit = async () => {
    if (isDelete) {
      await onDelete();
    }
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={modalClose}
      className="delete_modal itemlist_model"
    >
      <PrimaryLabel
        className="sub-header header_style"
        labelText={headerText}
      />
      <br />
      <PrimaryLabel
        className={`sub_header_child item_sub_header ${
          isDelete && 'delete_building'
        }`}
        labelText={subHeader}
      />
      <div className="button_div">
        <Button
          label={cancelButtonLabel}
          className="close_button item_close_button"
          type="button"
          onClick={modalClose}
        />
        <Button
          label={buttonLabel}
          className="yes_delete item_close_button"
          onClick={onSubmit}
        />
      </div>
    </ModalPopup>
  );
};

export default InventoryDeleteItemPopup;
