import {
  IEventVendors,
  IEventDateType,
  IRegionalBuilding,
  ChatDetail,
  BroadcastRelatedDetail,
  BroadcastMessageTo,
  ISearchEventBy,
  IVendorRequirments,
  ICategoryFilters,
} from './../../models/event';
import {
  Event,
  IEventBuilding,
  IEventWeekDays,
  Pagination,
  TimeLineData,
  TinyObject,
  IMonthInfo,
} from '../../models';
import { Map } from 'immutable';
import { formattedDate } from '../../utils';
import { InventoryGroupsState } from '../inventory';
import { IGroupTasks } from '../taskTemplate';

export interface TaskGroupsState {
  id?: number;
  name?: string;
  tasks?: IGroupTasks[];
}
export interface IEventsState extends Pagination {
  eventCount: object;
  eventServices: object;
  publishedEvents: object;
  publishedCount: number;
  cancelledEvents: object;
  cancelledCount: number;
  pastEvents: object;
  pastCount: number;
  draftsEvents: object;
  draftsCount: number;
  eventData: Event;
  eventInviteesData: object;
  eventBuildingData: object;
  allBuildings: IEventBuilding[];
  allRegionBuildings: IRegionalBuilding[];
  eventVendorAssigned: object;
  weekDays: IEventWeekDays[];
  vendorWithRegions?: any[];
  vendorWithRegionsCount: number;
  eventTasks: object;
  eventTimeLine: TimeLineData;
  invitedVendors?: IEventVendors[];
  eventUsers?: object;
  eventUsersCount?: number;
  eventTaskDetail?: object;
  eventDateType?: IEventDateType;
  chatScreenDetail: ChatDetail;
  clusterBuildings: object[];
  broadcastEventDetail: BroadcastRelatedDetail;
  broadcastMessageTo: BroadcastMessageTo;
  eventSearchBy?: ISearchEventBy;
  eventsInvetoryGroups: InventoryGroupsState[];
  eventsInvetoryItems: object[];
  eventSearchFor: object;
  eventInvitedVendors?: IEventVendors[];
  selectedRegionsVendor?: IEventVendors[];
  recurringVendorWithRegions?: IEventVendors[];
  vendorRequirements?: IVendorRequirments[];
  inventoryPackageCheck?: boolean;
  packageElements?: TinyObject[];
  searchSelectedDate?: string;
  clientReport?: object;
  heroVendors: object;
  heroVendorsCount: number;
  categoryFilters: ICategoryFilters;
  importedEventTask: TaskGroupsState[];
  vendorRegionCount?: any[];
  getInfoApiStatus: boolean;
  eventElements: any[];
  eventElementsList: any[];
  eventTaskGroupList: any[];
  monthInfo: IMonthInfo;
}

export const initialEventState: IEventsState = {
  eventCount: {},
  eventServices: [],
  publishedEvents: {
    pages: Map().set(0, {
      data: [],
      fetched: false,
    }),
  },
  publishedCount: 0,
  cancelledEvents: {
    pages: Map().set(0, {
      data: [],
      fetched: false,
    }),
  },
  cancelledCount: 0,
  pastEvents: {
    pages: Map().set(0, {
      data: [],
      fetched: false,
    }),
  },
  pastCount: 0,
  draftsEvents: {
    pages: Map().set(0, {
      data: [],
      fetched: false,
    }),
  },
  vendorWithRegions: [],
  vendorWithRegionsCount: 0,
  draftsCount: 0,
  eventData: {},
  eventInviteesData: {},
  eventBuildingData: [],
  allBuildings: [],
  allRegionBuildings: [],
  eventVendorAssigned: {},
  weekDays: [],
  clusterBuildings: [],
  eventTimeLine: {},
  eventTasks: {},
  invitedVendors: [],
  eventInvitedVendors: [],
  eventUsers: {
    pages: Map().set(0, {
      data: [],
      fetched: false,
    }),
  },
  eventDateType: {
    date: formattedDate(),
    year: new Date().getFullYear(),
    yearly: '',
    tab: 'published',
    per_day: 0,
    query: '' || null,
    searchType: '',
  },
  eventUsersCount: 0,
  eventTaskDetail: {},
  chatScreenDetail: {
    virtual: true,
    rsvps: 0,
    attendees: 0,
    displayName: '',
    startDatetime: '',
    endDatetime: '',
    livestreamId: null,
    buildings: 0,
  },
  broadcastEventDetail: {
    buildings: [],
    rsvpsByBuilding: [],
    attendeeByBuilding: [],
  },
  broadcastMessageTo: {
    buildings: [],
    message: '',
    recipientType: 0,
  },
  eventSearchBy: { label: 'Buildings', value: 'buildings' },
  eventsInvetoryGroups: [],
  eventsInvetoryItems: [],
  eventSearchFor: {},
  recurringVendorWithRegions: [],
  selectedRegionsVendor: [],
  vendorRequirements: [],
  inventoryPackageCheck: false,
  packageElements: [],
  searchSelectedDate: '',
  clientReport: {},
  heroVendors: [],
  heroVendorsCount: 0,
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  getInfoApiStatus: false,
  categoryFilters: {
    regionSelected: 0,
    vendorFiltered: null,
  },
  importedEventTask: [],
  vendorRegionCount: [],
  eventElements: [],
  eventElementsList: [],
  eventTaskGroupList: [],
  monthInfo: {
    date: formattedDate(),
    year: new Date().getFullYear(),
    utcDate: new Date()
  },
};
