import { combineReducers, Reducer } from 'redux';
import { clusterReducer } from './cluster/cluster.reducer';
import { managementReducer } from './management/management.reducer';
import { buildingReducer } from './building/building.reducer';
import { spaceReducer } from './space/space.reducer';
import { toastReducer } from './toast/toast.reducer';
import { staffReducer } from './staff/staff.reducer';
import { blockReducer } from './buildingblocks/buildingblocks.reducer';
import { userReducer } from './user/users.reducer';
import { formReducer } from './form/form.reducer';
import { vendorReducer } from './vendor/vendor.reducer';
import { whiteLabelReducer } from './whitelabel/whitelabel.reducer';
import { serviceReducer } from './service/service.reducer';
import { IAppState } from './app.state';
import { inventoryReducer } from './inventory/inventory.reducer';
import { accountsReducer } from './account/account.reducer';
import { loadingReducer } from './loader/loader.reducer';
import { regionsReducer } from './region/regions.reducer';
import { eventsReducer } from './event/event.reducer';
import { autoSave } from './autosave/autosave.reducer';
import { authReducer } from './authentication/authentication.reducer';
import { modalReducer } from './modal/modal.reducer';
import { taskReducer } from './taskTemplate/task.reducer';

export const rootReducer: Reducer<IAppState> = combineReducers({
  clusters: clusterReducer,
  buildings: buildingReducer,
  managements: managementReducer,
  spaces: spaceReducer,
  loading: loadingReducer,
  regions: regionsReducer,
  toast: toastReducer,
  buildingStaff: staffReducer,
  company: blockReducer,
  users: userReducer,
  forms: formReducer,
  vendors: vendorReducer,
  whitelabel: whiteLabelReducer,
  services: serviceReducer,
  inventory: inventoryReducer,
  accounts: accountsReducer,
  events: eventsReducer,
  authentication: authReducer,
  modal: modalReducer,
  autoSave,
  taskTemplate: taskReducer,
});
