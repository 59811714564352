import { TinyObject } from '../../models/tiny';
import { Pagination } from '../../models';
import { Map } from 'immutable';
export interface InventoryItemsState {
  id: number;
  name: string;
  location: string;
  itemCount?: number;
  vendor?: TinyObject;
}

export interface InventoryGroupsState {
  id: number;
  name: string;
  trunk?: boolean;
  items: InventoryItemsState[];
  itemCount?: number;
  vendor?: TinyObject;
  manuallyAssign?: boolean;
}

export interface ITaskGroupDetail {
  id?: number | null;
  name?: string;
  addToService?: boolean;
  allServices?: boolean;
  virtualServices?: boolean;
  categoryTags?: TinyObject[];
  tasks?: IGroupTasks[];
}

export interface IGroupTasks {
  id?: number;
  name?: string;
  description?: string;
  timeOfTask?: number;
  timeValue?: number;
  documentLink?: string;
  title?: string;
  groupId?: number;
}

export interface ITaskTemplateState extends Pagination {
  taskGroups: TinyObject[];
  taskGroupDetail: ITaskGroupDetail;
  taskGroupTasks: IGroupTasks[];
  groupTaskDetail: IGroupTasks;
  totalCount: number;
}

export const initialTaskTemplateState: ITaskTemplateState = {
  taskGroups: [],
  taskGroupDetail: {},
  taskGroupTasks: [],
  groupTaskDetail: {},
  totalCount: 0,
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
};
export interface IGroupItem {
  groupId: number;
  itemId: number;
  index: number;
}
