import { useEffect } from 'react';

export const usePageRefresh = (preventDefault, isEditMode) => {
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };
  useEffect(() => {
    if (!preventDefault || !isEditMode) {
      return;
    }
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [preventDefault, isEditMode]);
};
