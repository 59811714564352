import { http } from '../common/http.service';
import {
  ALL_RECORDS,
  RECORDS_CMP,
  RECORDS_LIMIT,
  RECORDS_PAGE,
  RECORDS_QUERY,
  MANAGEMENT_BASE_URL,
} from '../../constants/index';
import { PaginatedResponse, Management } from '../../models';
import { AxiosResponse } from 'axios';
import { DeleteResponse } from '../../models/deleteResponse';

export const getManagementsApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  query: string = RECORDS_QUERY,
  all: number = ALL_RECORDS
): Promise<PaginatedResponse<Management>> =>
  http
    .get<PaginatedResponse<Management>>(
      `${MANAGEMENT_BASE_URL}?cmp=${cmp}&limit=${limit}&page=${page}&query=${query}&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Management>>) => {
      return response.data;
    });

export const addManagementApi = async (data: Management): Promise<Management> =>
  http
    .post<Management>(`${MANAGEMENT_BASE_URL}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Management>) => {
      return response.data;
    });

export const getManagementDetailApi = async (
  managementId: number
): Promise<Management> =>
  http
    .get<Management>(`${MANAGEMENT_BASE_URL}/${managementId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Management>) => {
      return response.data;
    });

export const updateManagementApi = async (
  managementId: number,
  data: Management
): Promise<Management> =>
  http
    .put<Management>(`${MANAGEMENT_BASE_URL}/${managementId}`, data, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<Management>) => {
      return response.data;
    });

export const deleteManagementApi = async (
  managementId: number
): Promise<DeleteResponse> =>
  http
    .delete<DeleteResponse>(`${MANAGEMENT_BASE_URL}/${managementId}`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<DeleteResponse>) => {
      return response.data;
    });
