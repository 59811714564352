import React from 'react';
import { TimelineArray } from '../../../models';
import './TimeLineFeed.scss';
interface ProgressBarProps {
  timeline: TimelineArray;
  cancelled: boolean;
}

const TimelineHeadings: React.FC<ProgressBarProps> = ({
  timeline,
  cancelled,
}) => {
  const feedStatusCheck = (num: number) => {
    return timeline?.feedStatus === num ? true : false;
  };
  const assignedTimelineContent = () => {
    if (
      (!timeline?.manuallyAssigned?.show && !timeline?.eventInvite?.show) ||
      (timeline?.manuallyAssigned?.show && timeline?.eventInvite?.show) ||
      (!timeline?.manuallyAssigned?.show && timeline?.eventInvite?.show)
    ) {
      return (
        <div
          className={`line-text text-center ${
            timeline?.eventInvite?.show ||
            feedStatusCheck(4) ||
            feedStatusCheck(2)
              ? 'line-sub-heading-complete'
              : 'line-sub-heading-not-complete'
          }`}
        >
          <span>Vendor Invite Sent</span>
          {timeline?.eventInvite?.show && (
            <div className="text-sm font-medium">
              <span>{timeline?.eventInvite?.text} Vendors invited</span>
            </div>
          )}
        </div>
      );
    }
  };
  return (
    <div
      className={`progress-line-headings flex mb-4 ml-6 ${
        cancelled && 'cancel_event_overlay'
      }`}
    >
      {assignedTimelineContent()}
      <div
        className={`line-text text-center ${
          timeline?.inviteAccepted?.show ||
          timeline?.manuallyAssigned?.show ||
          feedStatusCheck(4)
            ? 'line-sub-heading-complete'
            : 'line-sub-heading-not-complete'
        }`}
      >
        <span>Vendor Assigned</span>
        {(timeline?.manuallyAssigned?.show ||
          timeline?.inviteAccepted?.show) && (
          <div className="text-sm font-medium">
            <span>
              {timeline?.manuallyAssigned?.show
                ? timeline?.manuallyAssigned?.subText?.['name']
                : timeline?.inviteAccepted?.subText?.['name']}
            </span>
          </div>
        )}
      </div>
      <div
        className={`line-text text-center ${
          feedStatusCheck(4)
            ? 'line-sub-heading-complete'
            : 'line-sub-heading-not-complete'
        }`}
      >
        <span>Documents Uploaded</span>
      </div>
      <div
        className={`line-text text-center ${
          feedStatusCheck(4)
            ? 'line-sub-heading-complete'
            : 'line-sub-heading-not-complete'
        }`}
      >
        <span>Event Complete</span>
      </div>
    </div>
  );
};

export default TimelineHeadings;
