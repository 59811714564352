import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SlideDrawer from '../../Common/SliderPane';
import Backdrop from '../../Common/SliderPane/Backdrop';
import { Button } from '@alfredclub/hom-planner-theme';
import AddEventTask from './AddEventTask';
import ImportTaskTemplatePopup from './ImportTaskTemplatePopup';
import Config from '../../../config/environment';
import {
  getCustomDateTime,
  getImageUrl,
  linkString,
  loadState,
  printDocument,
  useOutsideAlerter,
} from '../../../utils';
import {
  checkRequiredField,
  deleteEventTask,
  getEventTask,
  sendTaskList,
  clearEventTaskDetail,
  clearGeteventsElementslist,
} from '../../../redux/event';
import {
  callSheetPermissions,
  defaultTimezone,
  eventRelatedMessage,
  TOOLTIP_TEXT,
} from '../../../constants';
import DeletePopup from '../../Services/DeletePopup';
import PDFTaskListHeader from '../../Common/PDFTaskListHeader';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router';

const SimpleEventTasklist: React.FC<{
  taskData: any;
  recurring: boolean;
  eventId?: number;
}> = ({ taskData, recurring, eventId }) => {
  const dispatch = useDispatch();
  const { push, location } = useHistory();
  const [addTaskBtnState, setAddTaskBtnState] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [textCopied, setCopied] = useState(false);
  const [eventTaskId, setTaskId] = useState(null);
  const [editTaskCheck, setEditTaskCheck] = useState(false);
  const [deleteItem, setDeleteItem] = useState(0);
  const [showSetupDelete, setSetupDelete] = useState({
    showModal: false,
    isDelete: false,
  });
  const importedEventId = location.state?.['eventId'] ?? false;
  const taskImported = location.state?.['taskImported'] ?? false;
  const [assignVendorModal, setAssignVendorModal] = useState<boolean>(
    taskImported ?? false
  );
  const [taskTemplateCheck, setTaskTemplateCheck] = useState<boolean>(false);
  const taskListRef = useRef(null);
  const addTaskRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(addTaskRef, setAddTaskBtnState);

  const copyCallSheet = () => {
    navigator.clipboard.writeText(
      `${Config.VENDOR_URL}/callsheet/${eventId}/?tab=tasks&callsheet=${btoa(
        JSON.stringify(callSheetPermissions)
      )}`
    );
    setCopied(true);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [textCopied]);
  const copyCallSheetRef = useRef(null);
  const eventData = loadState('eventSetupForm');
  const toggleDrawer = async () => {
    setDrawerOpen(!drawerOpen);
    if (drawerOpen) {
      setEditTaskCheck(false);
    }
    await dispatch(clearEventTaskDetail());
    await dispatch(clearGeteventsElementslist());
    setAddTaskBtnState(false);
  };
  let backdrop: {};
  if (drawerOpen) {
    backdrop = <Backdrop close={toggleDrawer} />;
  }
  const onTaskClick = (taskId) => {
    setTaskId(taskId);
    setEditTaskCheck(true);
    toggleDrawer();
  };
  const deleteTask = async () => {
    await dispatch(deleteEventTask(deleteItem));
    await dispatch(getEventTask(eventId, false));
    setSetupDelete({
      showModal: false,
      isDelete: false,
    });
  };
  const sendMessage = async (taskEventId: number, isEventRelated: boolean) => {
    if (isEventRelated) {
      await dispatch(sendTaskList(taskEventId, false));
    } else {
      await dispatch(checkRequiredField(eventRelatedMessage));
    }
  };

  const onImportTemplate = () => {
    push(`/data-resources/task-template`, {
      eventId,
    });
  };

  return (
    <>
      {showSetupDelete.showModal && (
        <DeletePopup
          isDelete={showSetupDelete.isDelete}
          modalState={showSetupDelete.showModal}
          modalClose={() =>
            setSetupDelete({ showModal: false, isDelete: false })
          }
          onDelete={deleteTask}
          headerText="Are you sure you want to delete this task?"
          buttonLabel="Yes, delete"
          cancelButtonLabel="No, cancel"
        />
      )}
      <div className="task-wrap h-tasklist pb-24 overflow-auto">
        <div className="h-16">
          <div className="relative inline-block float-left" ref={addTaskRef}>
            <Button
              className={`dropbtn float-left mr-2  ${
                eventData?.['isPastEvent'] ? 'add-task-disable' : 'add-task-btn'
              }`}
              label="Add Task"
              type="button"
              onClick={() => setAddTaskBtnState(!addTaskBtnState)}
              disabled={eventData?.['isPastEvent']}
            />
            <div
              className={`addtask-content ${
                addTaskBtnState ? 'block' : 'hidden'
              }`}
            >
              <ul className="cursor-pointer">
                <li onClick={toggleDrawer}>
                  <img
                    src={getImageUrl('Events/add-new-task.svg')}
                    alt="error"
                    className="inline-flex pr-2"
                  />
                  Add new task
                </li>
                <li onClick={onImportTemplate}>
                  <img
                    src={getImageUrl('Events/import-task.svg')}
                    alt="error"
                    className="inline-flex pr-2"
                  />
                  Import task template
                </li>
              </ul>
            </div>
          </div>

          <button
            onClick={() =>
              printDocument(taskListRef, 'tasklist', 'taskListPdfHeading')
            }
            data-tip={TOOLTIP_TEXT.events.taskList.downloadPdf}
            className="btn-blue bg-transparent"
          >
            <img
              src={getImageUrl('Events/download_task_list.svg')}
              alt="download pdf"
            />
          </button>
          <button
            data-for="callSheet"
            ref={copyCallSheetRef}
            onClick={copyCallSheet}
            data-tip={TOOLTIP_TEXT.events.taskList.copyCallSheetLink}
            className="btn-blue bg-transparent mr-4"
          >
            <img
              src={getImageUrl('Events/copy_callsheet_link.svg')}
              alt="copy callsheet"
            />
          </button>
          <ReactTooltip
            id="callSheet"
            getContent={() =>
              textCopied
                ? 'Copied!'
                : TOOLTIP_TEXT.events.taskList.copyCallSheetLink
            }
          />
          <ReactTooltip />
          <Button
            className={`float-left ${
              (taskData?.['tasks'].length <= 0 || eventData?.['isPastEvent']) &&
              'button_disable'
            }`}
            type="button"
            label="Send Task List"
            isLeftIcon={true}
            leftIcon={getImageUrl('Events/send-task-list.svg')}
            disabled={
              taskData?.['tasks'].length <= 0 || eventData?.['isPastEvent']
                ? true
                : false
            }
            onClick={() => sendMessage(eventId, taskData?.['eventRelated'])}
          />
        </div>
        <div ref={taskListRef}>
          <div hidden={true} id="taskListPdfHeading" className="mb-8">
            <PDFTaskListHeader hideLabelColor={true} />
          </div>
          {taskData?.['tasks'].map((item: any) => {
            return (
              <div className="task-list-item mb-4" key={item.id}>
                <div
                  onClick={() => {
                    onTaskClick(item.id);
                    setTaskTemplateCheck(
                      item.templateId !== null ? true : false
                    );
                  }}
                >
                  <div className="flex justify-between">
                    <p className="p_title font-extrabold">{item.title}</p>
                    {item?.time && (
                      <p className="p_timeOfEventTask">
                        <img
                          src={getImageUrl(`data-resources/time.svg`)}
                          alt="time"
                          className="pr-2 pb-2 inline-flex"
                        />
                        {getCustomDateTime(
                          item.time,
                          eventData?.timezone?.zoneValue
                        )}
                      </p>
                    )}
                  </div>
                  <p className="p_vendor flex">
                    <img
                      src={getImageUrl(`data-resources/element+vendor.svg`)}
                      alt="vendor"
                      className="pr-2 pb-2 inline-flex"
                    />
                    {item?.element && item?.element?.length > 0 ? (
                      <p>
                        {item.element.map((data, i) => {
                          return `${data.name}${
                            i < item.element.length - 1 ? ', ' : ''
                          }`;
                        })}
                      </p>
                    ) : (
                      eventData?.['displayName']
                    )}
                  </p>
                  <p className="p_description">
                    <img
                      src={getImageUrl(`data-resources/description.svg`)}
                      alt="description"
                      className="pr-2 pb-2 inline-flex"
                    />
                    {item.description}
                  </p>
                  {item['documentLink'] && (
                    <a
                      href={linkString(item['documentLink'])}
                      target="_blank"
                      className="cursor-pointer a_link"
                      rel="noreferrer"
                    >
                      <img
                        src={getImageUrl(`data-resources/link.svg`)}
                        alt="description"
                        className="pr-2 inline-flex"
                      />
                      {item['documentLink']}
                    </a>
                  )}
                  {item['templateId'] && (
                    <div className="p_templateName flex items-center justify-center">
                      <p>Template task</p>
                    </div>
                  )}
                </div>
                {taskData?.['eventRelated'] && (
                  <img
                    onClick={() => {
                      setDeleteItem(item['id']);
                      setSetupDelete({
                        showModal: true,
                        isDelete: true,
                      });
                    }}
                    src={getImageUrl(`data-resources/delete.svg`)}
                    alt="delete"
                    className="delete_task inline-flex"
                  />
                )}
              </div>
            );
          })}
        </div>
        <SlideDrawer
          show={drawerOpen}
          slideClass="side-drawer side-drawer-event-mng-width open"
        >
          {drawerOpen && (
            <>
              <AddEventTask
                eventId={eventId}
                taskId={eventTaskId}
                closePane={toggleDrawer}
                weekDay={taskData?.['weekday']}
                recurring={recurring}
                editTaskCheck={editTaskCheck}
                elementName={eventData?.['displayName']}
                packageElements={taskData?.packageElements}
                isPackage={taskData?.isPackage}
                eventTimeZone={
                  eventData?.timezone?.zoneValue ?? defaultTimezone
                }
                taskTemplate={taskTemplateCheck}
              />
            </>
          )}
        </SlideDrawer>
        {backdrop}
        {assignVendorModal && (
          <ImportTaskTemplatePopup
            modalState={assignVendorModal}
            modalClose={() => setAssignVendorModal(!assignVendorModal)}
            eventId={importedEventId}
          />
        )}
      </div>
    </>
  );
};
export default SimpleEventTasklist;
