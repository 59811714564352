export const GET_EVENTS_COUNT = 'GET_EVENTS_COUNT';
export const GET_EVENT_SERVICES = 'GET_EVENT_SERVICES';
export const GET_EVENTS = 'GET_EVENTS';
export const EVENTS_SETUP = 'EVENTS_SETUP';
export const SAVE_EVENT_DATA = 'SAVE_EVENT_DATA';
export const SAVE_EVENT_DATA_BUILDINGS = 'SAVE_EVENT_DATA_BUILDINGS';
export const GET_ALL_BUILDINGS_BY_TIMEZONE = 'GET_ALL_BUILDINGS_BY_TIMEZONE ';
export const CLEAR_ALL_BUILDINGS_BY_TIMEZONE =
  'CLEAR_ALL_BUILDINGS_BY_TIMEZONE';
export const GET_ALL_BUILDINGS = 'GET_ALL_BUILDINGS ';
export const SEARCH_EVENTS = 'SEARCH_EVENTS';
export const MANUALLY_ASSIGNED = 'MANUALLY_ASSIGNED';
export const GET_ASSIGNED_VENDOR_INFO = 'GET_ASSIGNED_VENDOR_INFO';
export const GET_WEEK_DAYS = 'GET_WEEK_DAYS';
export const GET_EVENT_TIMELINE = 'GET_EVENT_TIMELINE';
export const GET_VENDOR_WITH_REGIONS = 'GET_VENDOR_WITH_REGIONS';
export const REMOVE_VENDOR_WITH_REGIONS = 'REMOVE_VENDOR_WITH_REGIONS';
export const GET_EVENT_TASKS = 'GET_EVENT_TASKS';
export const ADD_EVENT_TASK = 'ADD_EVENT_TASK';
export const REMOVE_WEEKDAY = 'REMOVE_WEEKDAY';
export const CLEAR_EVENT_DATA = 'CLEAR_EVENT_DATA';
export const GET_INVITED_VENDORS = 'GET_INVITED_VENDORS';
export const GET_EVENT_USERS = 'GET_EVENT_USERS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const CLEAR_VENDOR_ASSIGNED = 'CLEAR_VENDOR_ASSIGNED';
export const CLEAR_SERVICE_LIST = 'CLEAR_SERVICE_LIST';
export const SET_EVENT_DATE_TYPE = 'SET_EVENT_DATE_TYPE';
export const GET_CHAT_SCREEN_DETAIL = 'GET_CHAT_SCREEN_DETAIL';
export const CLEAR_ALL_EVENTS = 'CLEAR_ALL_EVENTS';
export const EVENT_BROADCAST_REALTED_DETAIL = 'EVENT_BROADCAST_REALTED_DETAIL';
export const SEND_BROADCAST_MESSAGE = 'SEND_BROADCAST_MESSAGE';
export const SEARCH_EVENT_BY = 'SEARCH_EVENT_BY';
export const GET_EVENTS_INVENTORY = 'GET_EVENTS_INVENTORY';
export const UPDATE_EVENTS_INVENTORY_GROUPS = 'UPDATE_EVENTS_INVENTORY_GROUPS';
export const UPDATE_EVENTS_INVENTORY_ITEMS = 'UPDATE_EVENTS_INVENTORY_ITEMS';
export const UPDATE_EVENTS_INVENTORY_ITEMS_GROUPS =
  'UPDATE_EVENTS_INVENTORY_ITEMS_GROUPS';
export const DELETE_EVENTS_INVENTORY_ITEM = 'DELETE_EVENTS_INVENTORY_ITEM';
export const CLEAR_ALL_EVENT_INVENTORY = 'CLEAR_ALL_EVENT_INVENTORY';
export const SEARCH_EVENT_FOR = 'SEARCH_EVENT_FOR';
export const CLEAR_SEARCH_EVENT_FOR = 'CLEAR_SEARCH_EVENT_FOR';
export const RESET_VENDOR_WITH_REGIONS = 'RESET_VENDOR_WITH_REGIONS ';
export const CLEAR_VENDORS_WITH_REGION = 'CLEAR_VENDORS_WITH_REGION';
export const CLEAR_EVENT_VENDOR_ASSIGNMENT = 'CLEAR_EVENT_VENDOR_ASSIGNMENT';
export const CLEAR_VENDOR_ASSIGNMENT_STATE = 'CLEAR_VENDOR_ASSIGNMENT_STATE';
export const CLEAR_SEARCH_EVENT_BY = 'CLEAR_SEARCH_EVENT_BY';
export const GET_VENDOR_REQUIRMENTS = 'GET_VENDOR_REQUIRMENTS';
export const CLEAR_VENDOR_REQUIRMENTS = 'CLEAR_VENDOR_REQUIRMENTS';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const GENERATE_CLIENT_REPORT = 'GENERATE_CLIENT_REPORT';
export const CLEAR_CLIENT_REPORT = 'CLEAR_CLIENT_REPORT';
export const CLEAR_EVENT_TASK = 'CLEAR_EVENT_TASK';
export const GET_HERO_VENDORS = 'GET_HERO_VENDORS';
export const SET_CATEGORY_FILTERS = 'SET_CATEGORY_FILTERS';
export const IMPORT_TASK_TO_EVENT = 'IMPORT_TASK_TO_EVENT';
export const CLEAR_EVENT_IMPORTED_TASK_STATE =
  'CLEAR_EVENT_IMPORTED_TASK_STATE';
export const RESET_GETINFO_STATUS = 'RESET_GETINFO_STATUS';
export const GET_EVENT_ELEMENTS_LIST = 'GET_EVENT_ELEMENTS_LIST';
export const UPDATE_EVENT_TASK_GROUP_SELECTED =
  'UPDATE_EVENT_TASK_GROUP_SELECTED';
export const CLEAR_EVENT_TASK_GROUP_LIST = 'CLEAR_EVENT_TASK_GROUP_LIST';
export const SET_MONTH_CHANGE = 'SET_MONTH_CHANGE';
