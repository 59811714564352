import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterRegion from '../Common/RegionModal';
import { CategorySearchDropdown } from '@alfredclub/hom-planner-theme';
import { openRegionModal } from '../../redux/modal/modal.action';
import { IModalState } from '../../redux/modal/modal.state';
import { getRegions } from '../../redux/region';
import Cookies from 'universal-cookie';
import {
  clearEvents,
  getCategoryFilteredEvents,
  getEvents,
  IEventsState,
  setCategoryFilters,
} from '../../redux/event';
import { CloseDropdownOnOutsideClick, defaultRegions } from '../../utils';
const EventCategoryFilters: React.FC<{
  tabName: string;
  date: string;
  setPage?: (page: number) => void;
}> = ({ tabName, date, setPage }) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperRefRegion = useRef<HTMLDivElement>(null);
  const [regionSelected, setRegionSelected] = useState([]);
  const [resetValue, reset] = useState<boolean>(false);
  const [vendorStatus, setVendorStatus] = useState<string>('');
  const [openStatusMenu, setStatusMenuOpen] = useState<boolean>(false);
  const [openRegionMenu, setRegionMenuOpen] = useState<boolean>(false);
  const cookies = new Cookies();
  const { openRegion } = useSelector(
    (state: { modal: IModalState }) => state.modal
  );
  const { categoryFilters, eventDateType, monthInfo } = useSelector(
    (state: { events: IEventsState }) => state.events
  );

  const selectAnotherRegion = async () => {
    await dispatch(openRegionModal());
    await dispatch(getRegions(0, 10, 'short', 1));
    setRegionMenuOpen(false);
  };

  const fetchCategoryData = async (
    clearRegion?: number,
    clearStatus?: string
  ) => {
    setPage(0);
    await dispatch(clearEvents());
    // Future Use only
    // if (
    //   categoryFilters?.['regionSelected'] > 0 ||
    //   categoryFilters?.['vendorFiltered']
    // ) {
    //   await dispatch(
    //     getCategoryFilteredEvents(
    //       tabName,
    //       0,
    //       date,
    //       clearRegion >= 0 ? 0 : categoryFilters?.['regionSelected'],
    //       clearStatus === '' ? '' : categoryFilters?.['vendorFiltered']
    //     )
    //   );
    // }
    if (clearRegion > 0 || clearStatus !== '') {
      await dispatch(
        getCategoryFilteredEvents(tabName, 0, date, clearRegion, clearStatus)
      );
    } else {
      await dispatch(
        getEvents(
          tabName,
          0,
          eventDateType.per_day,
          monthInfo?.date ?? eventDateType.date,
          eventDateType.yearly,
          eventDateType.year,
          eventDateType.query,
          eventDateType.searchType
        )
      );
    }
    setStatusMenuOpen(false);
    setRegionMenuOpen(false);
  };
  const clearSelection = async (isRegion?: boolean, isStatus?: boolean) => {
    isRegion ? setRegionSelected([]) : setVendorStatus('');
    setStatusMenuOpen(false);
    setRegionMenuOpen(false);
    await dispatch(
      setCategoryFilters({
        regionSelected: isRegion ? 0 : categoryFilters?.['regionSelected'],
        vendorFiltered: isStatus ? '' : categoryFilters?.['vendorFiltered'],
      })
    );
    // await dispatch(clearEvents());
    // await fetchCategoryData(isRegion, isStatus);
    await fetchCategoryData(
      isRegion ? 0 : categoryFilters?.['regionSelected'],
      isStatus ? '' : categoryFilters?.['vendorFiltered']
    );
  };
  CloseDropdownOnOutsideClick(
    wrapperRefRegion,
    setRegionMenuOpen,
    openRegionMenu
  );
  CloseDropdownOnOutsideClick(wrapperRef, setStatusMenuOpen, openStatusMenu);
  
  return (
    <div className="inline-flex space-x-2">
      <div ref={wrapperRefRegion}>
        <CategorySearchDropdown
          classNamePrefix="category-select-ddl"
          labelKey="regionName"
          valueKey="id"
          options={
            cookies.get('regions')
              ? cookies.get('regions').reverse()
              : defaultRegions()
          }
          categoryLabel={
            resetValue ? regionSelected?.[0]?.['regionName'] : 'Market'
          }
          onOptionChange={async (e) => {
            await dispatch(
              setCategoryFilters({
                regionSelected: e?.['id'],
                vendorFiltered: categoryFilters?.['vendorFiltered'],
              })
            );
          }}
          chooseAnotherOption={selectAnotherRegion}
          onClearIconClick={() => clearSelection(true, false)}
          isMulti={false}
          recentSearchLabelText="Recently searched market"
          isSimpleDropdown={false}
          onMenuOpen={() => {
            setRegionMenuOpen(true);
          }}
          menuIsOpen={openRegionMenu}
          onSaveBtnClick={() => {
            // fetchCategoryData();
            fetchCategoryData(
              categoryFilters?.regionSelected,
              categoryFilters?.vendorFiltered
            );
          }}
          resetValue={resetValue}
          value={regionSelected?.[0]}
        />
      </div>
      <FilterRegion
        modalState={openRegion}
        selectedRegion={regionSelected}
        setRegion={setRegionSelected}
        vendorStatus={vendorStatus}
        date={date}
        tabName={tabName}
        setPage={setPage}
        setReset={reset}
      />
      <div ref={wrapperRef}>
        <CategorySearchDropdown
          classNamePrefix="category-select-ddl"
          dropdownDivClass="status-filter-ddl"
          labelKey="status"
          valueKey="value"
          options={[
            { status: 'Assigned', value: 1 },
            { status: 'Pending', value: 2 },
          ]}
          onOptionChange={async (e) => {
            await dispatch(
              setCategoryFilters({
                // regionSelected:
                //   categoryFilters?.['regionSelected'].length > 0
                //     ? categoryFilters?.['regionSelected']
                //     : [],
                regionSelected: categoryFilters?.['regionSelected'],
                vendorFiltered: e?.['status'],
              })
            );
            setVendorStatus(e?.['status']);
          }}
          categoryLabel="Vendor Status"
          onSaveBtnClick={() => {
            // fetchCategoryData();
            fetchCategoryData(
              categoryFilters?.regionSelected,
              categoryFilters?.vendorFiltered
            );
          }}
          isMulti={false}
          isSimpleDropdown={true}
          onMenuOpen={() => {
            setStatusMenuOpen(true);
          }}
          menuIsOpen={openStatusMenu}
          onClearIconClick={() => {
            clearSelection(false, true);
          }}
        />
      </div>
    </div>
  );
};
export default EventCategoryFilters;
