import { InventoryItemsState } from './../inventory/inventory.state';
import {
  addServiceTaskListApi,
  deleteServicePriceApi,
  getServiceTaskListApi,
  getServiceDocumentApi,
  getPackageElementListApi,
  editServiceTaskApi,
  getSingleServiceTaskApi,
  getSinglePriceDetailApi,
  editSingleServicePricingApi,
  getProductionTeamApi,
  assignVendorToImportedTasksApi,
} from './service.service';
import {
  getServicesApi,
  setUpServiceApi,
  getServiceCategoriesApi,
  getServiceDetailApi,
  updateServiceSetupApi,
  deleteSetupApi,
  getServiceVendorsApi,
  getServiceElementApi,
  addVendorsToServiceApi,
  addServicePriceApi,
  getSimpleServicePriceApi,
  updateSimpleServicePriceApi,
  getPackageServicePriceApi,
  deleteServiceTaskApi,
  getMultipleServiceVendorsApi,
  getServiceInventoryApi,
  addInventoryToServiceApi,
  serviceInventoryUpdateApi,
  deleteInventoryItemApi,
  updateVendorsToServiceApi,
  getCategoryTagsApi,
  getServiceCategoryTagsApi,
  removeTaskTemplateApi,
} from './service.service';
import { apiStatusCheck } from '../../utils';
import * as serviceTypes from './service.constant';
import * as loadingTypes from '../loader/loader.constant';
import * as formTypes from '../form/form.constant';
import * as toastTypes from '../toast/toast.constant';
import * as eventTypes from '../event/event.constant';
import { TOAST_MESSAGES } from '../../constants/index';
import { Pricing, PricingRequest } from '../../models/pricing';
import { Task, TaskRequest } from '../../models/task';
import {
  Category,
  DeleteResponse,
  Element,
  PaginatedResponse,
  Service,
  ServiceVendorRequest,
  Vendor,
} from '../../models';

export const getServices =
  (
    limit?: number,
    page?: number,
    cmp?: string,
    query?: string,
    all?: number,
    updateReducer = true,
    serviceType = '',
    isVendor?: boolean
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse: PaginatedResponse<Service> = await getServicesApi(
      cmp,
      limit,
      page,
      query,
      all,
      serviceType,
      isVendor
    );
    if (paginatedResponse.data && updateReducer) {
      dispatch({
        type:
          all === 1 ? serviceTypes.GET_ALL_SERVICES : serviceTypes.GET_SERVICES,
        payload: paginatedResponse.data,
        serviceCount: paginatedResponse.total,
        page: page ?? 0,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      return paginatedResponse.data;
    } else if (paginatedResponse.data && !updateReducer) {
      return paginatedResponse.data;
    }
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  };

export const searchServices = (data, query, page) => async (dispatch) => {
  if (data.length > 0 && query !== '') {
    dispatch({
      type: serviceTypes.SEARCH_SERVICES_DATA,
      payload: data,
      serviceCount: data.length,
      page,
    });
  } else {
    await getServices(10, page, 'short', query, 0, true)(dispatch);
  }
};

export const getServiceCategories = () => async (dispatch) => {
  const categoryResponse: Category[] = await getServiceCategoriesApi();
  if (categoryResponse) {
    dispatch({
      type: serviceTypes.GET_CATEGORIES,
      payload: categoryResponse,
    });
  }
};

export const getServiceDocuments = () => async (dispatch) => {
  const documentsData = await getServiceDocumentApi();
  if (apiStatusCheck(documentsData)) {
    dispatch({
      type: serviceTypes.GET_SERVICE_DOCUMENTS,
      payload: documentsData.data.data,
    });
  }
};

export const setupService =
  (data: Service, isPackage: boolean) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const service: Service = await setUpServiceApi(data, isPackage);
    if (service) {
      dispatch({
        type: serviceTypes.SETUP_SERVICES,
        payload: service,
      });
      dispatch({
        type: formTypes.UPDATE_SERVICE_SETUP_FORM,
        payload: service,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      return service?.['id'];
    }
  };

export const getServiceDetail = (serviceId: number) => async (dispatch) => {
  const service: Service = await getServiceDetailApi(serviceId);
  if (service) {
    dispatch({
      type: formTypes.UPDATE_SERVICE_SETUP_FORM,
      payload: service,
    });
    dispatch({
      type: serviceTypes.FETCH_SERVICE_DETAIL,
      payload: service ? true : false,
    });
    return service?.isPackage;
  }
};

export const updateServiceSetup =
  (serviceId: number, data: Service) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updatedService: Service = await updateServiceSetupApi(
      serviceId,
      data
    );
    if (updatedService) {
      dispatch({
        type: formTypes.UPDATE_SERVICE_SETUP_FORM,
        payload: updatedService,
      });
    }
  };

export const deleteSetup = (serviceId: number) => async (dispatch) => {
  const deleteResponse: DeleteResponse = await deleteSetupApi(serviceId);
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (deleteResponse) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.element.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const serviceVendors = (serviceId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const paginatedResponse: PaginatedResponse<Vendor> =
    await getServiceVendorsApi(serviceId);
  if (paginatedResponse.data) {
    dispatch({
      type: formTypes.UPDATE_SERVICE_VENDOR_FORM,
      payload: paginatedResponse.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const associateVendors =
  (serviceId: number, data: ServiceVendorRequest) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const vendor: Vendor = await addVendorsToServiceApi(serviceId, data);
    if (vendor) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getServiceElements =
  (
    limit?: number,
    page?: number,
    cmp?: string,
    query?: string,
    all?: number,
    updateReducer = true,
    elementId?: number
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse: PaginatedResponse<Element> =
      await getServiceElementApi(cmp, limit, page, query, all, elementId);
    if (paginatedResponse && updateReducer) {
      dispatch({
        type: serviceTypes.GET_SERVICES,
        payload: paginatedResponse.data,
        serviceCount: paginatedResponse.total,
        page: page ?? 0,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    } else if (paginatedResponse.data && !updateReducer) {
      return paginatedResponse.data;
    }
  };

export const searchServiceElements =
  (data, query, page) => async (dispatch) => {
    if (data.length > 0 && query !== '') {
      dispatch({
        type: serviceTypes.SEARCH_SERVICES_DATA,
        payload: data,
        serviceCount: data.length,
        page,
      });
    } else {
      await getServiceElements(10, page, 'short', query, 0)(dispatch);
    }
  };

export const addServicePrice =
  (data: PricingRequest, serviceId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse: PaginatedResponse<Pricing> =
      await addServicePriceApi(data, serviceId);
    if (paginatedResponse.data) {
      dispatch({
        type: serviceTypes.SET_SERVICES_PRICE,
        payload: paginatedResponse.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getSimpleServicePrice =
  (serviceId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const servicePrice: Pricing = await getSimpleServicePriceApi(serviceId);
    if (servicePrice) {
      dispatch({
        type: formTypes.UPDATE_SERVICE_PRICE_FORM,
        payload: servicePrice,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const updateSimpleServicePrice =
  (serviceId: number, data: PricingRequest) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updatedPrice: Pricing = await updateSimpleServicePriceApi(
      serviceId,
      data
    );

    if (updatedPrice) {
      dispatch({
        type: toastTypes.UDPATE_TOAST,
        toastMessage: TOAST_MESSAGES.element.updated,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getPackageServicePrice =
  (serviceIds: any[]) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const packageServicePrice: Pricing = await getPackageServicePriceApi(
      serviceIds
    );
    if (packageServicePrice) {
      dispatch({
        type: formTypes.UPDATE_SERVICE_PRICE_FORM,
        payload: packageServicePrice,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getServiceVendors =
  (serviceId: number, all?: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse: PaginatedResponse<Vendor> =
      await getServiceVendorsApi(serviceId, all);
    if (paginatedResponse.data) {
      dispatch({
        type: serviceTypes.GET_SERVICE_VENDORS_LIST,
        payload: paginatedResponse.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const addTaskList =
  (serviceId: number, data: TaskRequest) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const taskList: Task[] = await addServiceTaskListApi(data, serviceId);
    if (taskList.length > 0) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getMultipleServiceVendorsData =
  (serviceIds: any[]) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const multipleServiceVendors: PaginatedResponse<Vendor> =
      await getMultipleServiceVendorsApi(serviceIds);
    if (multipleServiceVendors) {
      dispatch({
        type: serviceTypes.GET_MULTIPLE_SERVICE_VENDORS_LIST,
        payload: multipleServiceVendors,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const deleteServiceTask = (taskId: number) => async (dispatch) => {
  const deleteResponse: DeleteResponse = await deleteServiceTaskApi(taskId);
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (deleteResponse.delete) {
    dispatch({
      type: formTypes.REMOVE_TASK,
      payload: taskId,
    });
  }
};
export const getServiceTaskList = (serviceId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const paginatedResponse = await getServiceTaskListApi(serviceId);
  if (paginatedResponse?.data) {
    dispatch({
      type: formTypes.UPDATE_TASK_LIST_EDIT,
      payload: paginatedResponse.data.taskList,
    });
    dispatch({
      type: serviceTypes.UPDATE_SERVICE_TASK_GROUP_SELECTED,
      payload: paginatedResponse.data?.['groups'],
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const deleteServicePrice = (priceId: number) => async (dispatch) => {
  const deleteResponse: DeleteResponse = await deleteServicePriceApi(priceId);
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  if (deleteResponse.delete) {
    dispatch({
      type: toastTypes.DELETE_TOAST,
      toastMessage: TOAST_MESSAGES.service.deleted,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const getPackageElementList =
  (serviceId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const paginatedResponse = await getPackageElementListApi(serviceId);
    if (paginatedResponse?.data) {
      dispatch({
        type: serviceTypes.PACKAGE_ELEMENT_LIST,
        payload: paginatedResponse.data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      return paginatedResponse.data;
    }
  };

export const clearPackageElementList = () => async (dispatch) => {
  dispatch({
    type: serviceTypes.PACKAGE_ELEMENT_LIST,
    payload: [],
  });
};

export const clearServiceTaskDetail = () => async (dispacth) => {
  dispacth({
    type: serviceTypes.CLEAR_SERVICE_SINGLE_TASK,
  });
};
export const editSingleServiceTask =
  (taskId: number, data: Task) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const editSerivceTask = await editServiceTaskApi(taskId, data);
    if (apiStatusCheck(editSerivceTask)) {
      dispatch({
        type: serviceTypes.EDIT_SERVICE_TASK,
        payload: data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getSingleServiceTask = (taskId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const getTask = await getSingleServiceTaskApi(taskId);
  dispatch({
    type: serviceTypes.GET_SERVICE_SINGLE_TASK,
    payload: getTask,
  });
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const getServiceInventory = (serviceId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const serviceInventory = await getServiceInventoryApi(serviceId);
  if (serviceInventory.data) {
    dispatch({
      type: eventTypes.GET_EVENTS_INVENTORY,
      payload: serviceInventory.data,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const clearServiceItems = () => (dispatch) => {
  dispatch({
    type: eventTypes.CLEAR_ALL_EVENT_INVENTORY,
  });
};

export const addServiceInventoryGroups =
  (data: InventoryItemsState[]) => async (dispatch) => {
    dispatch({
      type: eventTypes.UPDATE_EVENTS_INVENTORY_GROUPS,
      payload: data,
    });
  };

export const addServiceInventoryItems =
  (data: object[]) => async (dispatch) => {
    dispatch({
      type: eventTypes.UPDATE_EVENTS_INVENTORY_ITEMS,
      payload: data,
    });
  };

export const addInventoryToService =
  (data: object, serviceId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    const serviceInventoryData = await addInventoryToServiceApi(
      data,
      serviceId
    );
    if (serviceInventoryData) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const deleteServiceInventoryItems =
  (
    itemId: number,
    rowIndex: number,
    isGroup: number | null,
    serviceId: number
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const serviceInventory = await deleteInventoryItemApi(itemId, serviceId);
    if (apiStatusCheck(serviceInventory)) {
      dispatch({
        type: toastTypes.SUCCESS_TOAST,
        toastMessage: TOAST_MESSAGES.inventory.deleted,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      dispatch({
        type: eventTypes.DELETE_EVENTS_INVENTORY_ITEM,
        rowIndex,
        itemId,
        isGroup: isGroup ? true : false,
      });
    }
  };

export const serviceInventoryUpdate =
  (
    data,
    itemId?: number,
    rowIndex?: number,
    itemIndex?: number,
    isGroup?: number | null,
    serviceId?: number
  ) =>
  async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const updatedServiceInventory = await serviceInventoryUpdateApi(
      data,
      itemId,
      serviceId
    );
    if (updatedServiceInventory.data) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
      dispatch({
        type: eventTypes.UPDATE_EVENTS_INVENTORY_ITEMS_GROUPS,
        payload: data,
        rowIndex,
        itemIndex,
        isGroup: isGroup ? true : false,
      });
    }
  };

export const getSinglePriceDetail = (pricingId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  const servicePriceDetail = await getSinglePriceDetailApi(pricingId);
  if (servicePriceDetail) {
    dispatch({
      type: serviceTypes.GET_SINGLE_PRICE_DETAIL,
      payload: servicePriceDetail,
    });
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
  }
};

export const clearSinglePriceDetail = () => (dispatch) => {
  dispatch({
    type: serviceTypes.CLEAR_SINGLE_PRICE_DETAIL,
  });
};

export const editSingleServicePricing =
  (serviceId: number, data: PricingRequest) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const editSerivcePricing = await editSingleServicePricingApi(
      serviceId,
      data
    );
    if (apiStatusCheck(editSerivcePricing)) {
      dispatch({
        type: serviceTypes.EDIT_SERVICE_PRICING,
        payload: data,
      });
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const updateAssociateVendors =
  (serviceId: number, data: ServiceVendorRequest) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STARTED,
    });
    const vendor: Vendor = await updateVendorsToServiceApi(serviceId, data);
    if (vendor) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const getCategoryTags = () => async (dispatch) => {
  const categoryTags = await getCategoryTagsApi();
  if (categoryTags.data) {
    dispatch({
      type: serviceTypes.GET_CATEGORY_TAGS,
      payload: categoryTags.data,
    });
  }
};

export const getServiceCategoryTags =
  (elementsIds: number[]) => async (dispatch) => {
    const serviceCategoryTags = await getServiceCategoryTagsApi(elementsIds);
    if (serviceCategoryTags.data) {
      dispatch({
        type: serviceTypes.GET_SERVICE_CATEGORY_TAGS,
        payload: serviceCategoryTags.data,
      });
    }
  };

export const clearCategoryTags = () => async (dispatch) => {
  dispatch({
    type: serviceTypes.CLEAR_CATEGORY_TAGS,
  });
};

export const clearServiceCategoryTags = () => async (dispatch) => {
  dispatch({
    type: serviceTypes.CLEAR_SERVICE_CATEGORY_TAGS,
  });
};

export const getProductionTeam =
  (elementsIds?: number[]) => async (dispatch) => {
    const prodTeam = await getProductionTeamApi(elementsIds);
    if (prodTeam.data) {
      if (elementsIds) {
        dispatch({
          type: formTypes.UPDATE_SERVICE_SETUP_FORM,
          payload: prodTeam.data,
        });
      } else {
        dispatch({
          type: serviceTypes.GET_PRODUCTION_TEAM,
          payload: prodTeam.data,
        });
      }
    }
  };

export const importTaskTemplateToService =
  (data: object) => async (dispatch) => {
    dispatch({
      type: serviceTypes.IMPORT_TASK_TO_SERVICE,
      payload: data,
    });
  };

export const assignVendorToImportedTasks =
  (data: object, serviceId: number) => async (dispatch) => {
    dispatch({
      type: loadingTypes.LOADING_STOPED,
    });
    const serviceTaskData = await assignVendorToImportedTasksApi(
      data,
      serviceId
    );
    if (serviceTaskData) {
      dispatch({
        type: loadingTypes.LOADING_STOPED,
      });
    }
  };

export const serviceVendorList = (data: object) => async (dispatch) => {
  dispatch({
    type: serviceTypes.SERVICE_VENDOR_LIST,
    payload: data,
  });
};
export const clearServiceTaskGroupList = () => async (dispatch) => {
  dispatch({
    type: serviceTypes.CLEAR_SERVICE_TASK_GROUP_LIST,
    payload: [],
  });
};

export const clearServiceImportedTaskState = () => async (dispatch) => {
  dispatch({
    type: serviceTypes.CLEAR_SERVICE_IMPORTED_TASK_STATE,
    payload: [],
  });
};

export const removeTaskTemplate = (taskId: number) => async (dispatch) => {
  dispatch({
    type: loadingTypes.LOADING_STARTED,
  });
  await removeTaskTemplateApi(taskId);
  dispatch({
    type: toastTypes.DELETE_TOAST,
    toastMessage: TOAST_MESSAGES.taskTemplate.taskRemoved,
  });
  dispatch({
    type: loadingTypes.LOADING_STOPED,
  });
};

export const clearVendorServices = () => async (dispatch) => {
  dispatch({
    type: serviceTypes.CLEAR_ALL_SERVICES,
    payload: [],
  });
};
