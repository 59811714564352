import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import BuildingConfiguration from './BuildingConfiguration';
import { useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  getImageUrl,
  getRecurringUTCTime,
  getSepratedTime,
} from '../../../utils';
import {
  datepickerformat,
  defaultTimezone,
  EVENT_FORMAT,
  timeFormat,
} from '../../../constants';
import {
  checkRequiredField,
  getDayOfWeeks,
  getRecurringEventDetail,
  IEventsState,
  removeWeekdayFromSchedule,
  updateRecurringEventSetup,
} from '../../../redux/event';
import {
  IEventWeekDays,
  IRecurringEventForm,
  RecurringSchedule,
} from '../../../models';
import { useFetch } from '../../../hooks';
import LabelWithIcon from './LabelWithIcon';
import { Button } from '@alfredclub/hom-planner-theme';
import RecurringDayEventSetup from './RecurringDayEventSetup';
import Footer from '../../Common/Footer';
import { useDispatch } from 'react-redux';
import CommonLoader from '../../Common/CommonLoader';
import { ILoaderState } from '../../../redux/loader';
import { IFormState, updateRecurringFormSchedules } from '../../../redux/form';
import RecurringInPersonEvent from './RecurringInPersonEvent';
import './EventSetupForm.scss';
import '../Events.scss';
import {
  recurringInPersonSchema,
  recurringSchema,
} from '../../../validations/eventCreation';

const RecurringEventSetup = () => {
  const { recurringSetupForm: eventData } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );

  const [showLoader, setLoaderState] = useState(false);
  const { register, setValue, errors, reset, handleSubmit } =
    useForm<RecurringSchedule>();

  const [fields, setFields] = useState<IRecurringEventForm[]>([]);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const params = useParams();
  const scheduleId = parseInt(params['eventId'], 10);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const isEdit = Boolean(query.get('edit'));
  const virtual = query.get('virtual') === 'true';
  const isSchedule = query.get('step') === 'recurring' ? true : false;
  const virtualEvent = isEdit ? eventData?.['virtual'] : virtual;

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const { weekDays, eventInviteesData, eventBuildingData } = useSelector(
    (state: { events: IEventsState }) => state.events
  );

  useFetch(getDayOfWeeks);
  const [weekDaysList, setWeekDaysChecked] = useState(
    eventData?.['weekDays'] ?? []
  );
  const onChange = (
    e: HTMLInputElement | { value: string | object; name: string },
    index: number,
    data?: object,
    key?: string
  ) => {
    setFields((prevFields) => {
      if (e.name === 'startTime' || e.name === 'endTime') {
        // Get time in 16:00 format save in DB
        const utcTime = getRecurringUTCTime(
          moment().format(datepickerformat),
          String(e.value),
          eventData?.['timezone']?.zoneValue
        );
        e.value = `${moment().format(EVENT_FORMAT)}T${utcTime}:00Z`;
      }
      if (key === 'eventImage') {
        prevFields[index] = { ...prevFields[index], eventImage: data };
      }
      if (e.name === 'preRecorded') {
        prevFields[index] = {
          ...prevFields[index],
          preRecorded: data['preRecorded'],
        };
      } else {
        prevFields[index] = { ...prevFields[index], [e.name]: e.value };
      }
      dispatch(
        updateRecurringFormSchedules({ scheduleEvents: [...prevFields] })
      );
      return [...prevFields];
    });
  };
  const onWeekDaysSelect = async (e: IEventWeekDays) => {
    const index = weekDaysList.findIndex(
      (item: IEventWeekDays) => item.id === e.id
    );
    if (index !== -1) {
      setWeekDaysChecked(() => {
        return weekDaysList.filter((item) => item.id !== e.id);
      });
      setFields(() => {
        return fields.filter((item) => item.weekday !== e.id);
      });
      await dispatch(
        removeWeekdayFromSchedule(scheduleId, { weekday: [e.id] })
      );

      await dispatch(getRecurringEventDetail(scheduleId));
    } else {
      setWeekDaysChecked((prevWeekdays) => {
        return [...prevWeekdays, e];
      });
      setFields((prevFields) => {
        return [
          ...prevFields,
          {
            weekday: e.id,
            displayName: '',
            description: '',
            service: { id: null },
            maxCapacity: '',
            startTime: '',
            endTime: '',
            tenantUrl: '',
            venue: { id: null },
            marketingLinks: '',
            preRecorded: false,
            eventImage: { id: null },
          },
        ];
      });
    }
    await dispatch(
      updateRecurringFormSchedules({
        weekDays: weekDaysList,
      })
    );
  };

  useEffect(() => {
    setFields(() => eventData?.['scheduleEvents']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEventBuilding = () => {
    if (eventInviteesData?.['buildings']) {
      return eventInviteesData?.['buildings'];
    } else if (Object.keys(eventBuildingData).length > 0) {
      return eventBuildingData;
    } else {
      return eventData?.['buildings'];
    }
  };
  const onSubmit = async (data: RecurringSchedule) => {
    try {
      data.visibility = eventData?.['visibility'] ?? data.visibility;
      data.timezone = eventData?.['timezone'];
      if (virtualEvent) {
        data = {
          ...data,
          ...(await recurringSchema.validate({
            buildings: getEventBuilding(),
            scheduleEvents: fields,
          })),
        };
      } else {
        data = {
          ...data,
          ...(await recurringInPersonSchema.validate({
            buildings: getEventBuilding(),
            scheduleEvents: fields,
          })),
        };
      }
      data = {
        ...data,
        scheduleEvents: data.scheduleEvents.map((item) => {
          return {
            ...item,
            startTime: getSepratedTime(
              item.startTime,
              eventData?.timezone?.zoneValue ?? defaultTimezone,
              timeFormat
            ),
            endTime: getSepratedTime(
              item.endTime,
              eventData?.timezone?.zoneValue ?? defaultTimezone,
              timeFormat
            ),
          };
        }),
      };
      setLoaderState(true);
      if (!eventData?.['isPastEvent']) {
        await dispatch(updateRecurringEventSetup(scheduleId, data));
        await dispatch(getRecurringEventDetail(scheduleId));
      }
      push(
        `/events/${scheduleId}/create?step=vendor-assignment&tab=vendor-invite`
      );
      setLoaderState(false);
    } catch (error) {
      dispatch(checkRequiredField(error?.['message']));
    }
  };

  return (
    <div className="event_setup recurring_wrapper">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-3 gap-x-2 gap-y-0">
          <BuildingConfiguration
            formErrors={errors}
            register={register}
            setValue={setValue}
            reset={reset}
            eventId={scheduleId}
            eventData={eventData}
            isEdit={isEdit}
            className="recurring_building"
            isSchedule={isSchedule}
          />
          <div className="col-span-2">
            <div className="setup_event">
              <LabelWithIcon
                imgSrc={getImageUrl('Events/selectdaysofweek(Recurring).svg')}
                labelText="Select Days of the week"
              />
            </div>
            <div className="display: inline-flex ml-6 space-x-2">
              {weekDays?.map((item: IEventWeekDays) => {
                return (
                  <Button
                    label={item.name}
                    key={item.id}
                    type="button"
                    onClick={() => {
                      onWeekDaysSelect(item);
                    }}
                    className={`${
                      weekDaysList?.find((x) => x.id === item.id)
                        ? 'select_days'
                        : 'unselected_days'
                    } ${isEdit && 'pointer-events-none cursor-not-allowed'} `}
                  />
                );
              })}
            </div>
          </div>

          {virtualEvent ? (
            <RecurringDayEventSetup
              eventData={eventData}
              virtual={eventData?.['virtual'] ?? virtual}
              fields={fields}
              onChange={onChange}
              weekDaysList={weekDaysList}
              isEdit={isEdit}
            />
          ) : (
            <RecurringInPersonEvent
              eventData={eventData}
              virtual={eventData?.['virtual'] ?? virtual}
              fields={fields}
              onChange={onChange}
              weekDaysList={weekDaysList}
              isEdit={isEdit}
              buildingObj={getEventBuilding()}
            />
          )}
        </div>
        <Footer
          buttonText="Save and Next"
          isArrow={false}
          isRightIcon={true}
          rightIcon={getImageUrl('Events/continuearrow.svg')}
          rightIconClass="pl-4"
          type="submit"
          disabled={loading}
          buttonClass={`recurring_continue ${
            loading || weekDaysList.length <= 0 ? 'button_disable' : ''
          }`}
        />
        <CommonLoader loading={showLoader} />
      </form>
    </div>
  );
};

export default RecurringEventSetup;
