import { Pagination } from '../../models';
import { Map } from 'immutable';

export interface ManagementStateArray {
  id: number;
  companyName: string;
  clusterCount: number;
  buildingCount: number;
}

export interface IManagementState extends Pagination {
  managements: object;
  managementCount: number;
  allManagements: any[];
  managementData: object;
}

export const initialManagementState: IManagementState = {
  managements: {},
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  managementCount: 0,
  managementData: null,
  allManagements: [],
};
