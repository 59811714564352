import { IUserLoginResponse } from './../../models/user';

export const initialAuthState: IUserLoginResponse = {
  access: localStorage.getItem('access') ?? null,
  refresh: localStorage.getItem('refresh') ?? null,
  userTimezone: localStorage.getItem('userTimezone')
    ? localStorage.getItem('userTimezone') === 'EST'
      ? 'US/Eastern'
      : localStorage.getItem('userTimezone')
    : null,
};
