import { Pagination } from '../../models/pagniation';
import { Map } from 'immutable';

export interface RegionArrayState {
  regionName: string;
  id: number;
}

export interface TimezoneArrayState {
  zoneValue: string;
  shortName: string;
  id: number;
}
export interface IRegionState extends Pagination {
  regions: RegionArrayState[];
  regionCount: number;
  timezones: TimezoneArrayState[];
  timezoneCount: number;
}

export const initialRegionState: IRegionState = {
  regions: [],
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  regionCount: 0,
  timezones: [],
  timezoneCount: 0,
};
