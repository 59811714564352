import React from 'react';
import { Button, Input } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ColorPicker from './ColorPicker';
import FileInput from '../Common/FileInput';
import { imageUpload, removeImage } from '../../redux/common/common.action';
import ModalPopup from '../ReactModal/Modal';
import './BuildingWhitelabellingPopup.scss';
import { updateWhiteLabelForminBuilding } from '../../redux/form/form.action';
import { Whitelabel } from '../../models';
import { ButtonType } from '../../enums';
import { addWhitelabel } from '../../redux/whitelabel';
import { getUploadedImageUrl, loadState, splitImageName } from '../../utils';

const BuildingWhitelabellingPopup: React.FC<{
  buildingWhiteLabelData: Whitelabel;
  modalState: boolean;
  toggleModal: () => void;
  buildingId: number;
  reset: (
    values?: Record<string, any>,
    omitResetState?: Record<string, boolean>
  ) => void;
  refreshBuildingForm: (buildiingForm: Building) => void;
}> = ({
  buildingWhiteLabelData,
  modalState,
  toggleModal,
  buildingId,
  reset,
  refreshBuildingForm,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm<Whitelabel>();

  const onRemoveImage = async () => {
    const whiteLabelObj = {
      topBar: buildingWhiteLabelData?.topBar,
      topBarText: buildingWhiteLabelData?.buttonText,
      buttonColor: buildingWhiteLabelData?.buttonColor,
      buttonText: buildingWhiteLabelData?.buttonText,
      logo: {
        id: null,
      },
    };
    await dispatch(removeImage(whiteLabelObj, 'whitelabel'));
    reset(loadState('buildingForm')); // File list error resolved
    refreshBuildingForm(loadState('buildingForm')); // Refresh New state
  };

  const onSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    handleSubmit(async (data: Whitelabel) => {
      if (!buildingWhiteLabelData?.['logo']['id']) {
        const formData = new FormData();
        formData.append('file', data['logo']['id'][0]);
        data['logo'] = data['logo']['id'][0]
          ? await dispatch(imageUpload(formData))
          : { id: null, file: null };
      } else {
        data.logo = buildingWhiteLabelData?.['logo'];
      }
      await dispatch(addWhitelabel(buildingId, data));
      await dispatch(updateWhiteLabelForminBuilding(data));
      toggleModal();
    })(e);
  };

  return (
    <div>
      <ModalPopup
        className="whitelabel-model-width"
        isOpen={modalState}
        onRequestClose={toggleModal}
      >
        <form onSubmit={onSubmit}>
          <div className="px-10 py-4">
            <div className="flex items-start justify-between">
              <div>
                <h2 className="text-gray-600 text-2xl font-semibold tracking-wider">
                  Whitelabelling Configuration
                </h2>
              </div>
              <div>
                <Button
                  label="Save Customization"
                  className="font-semibold tracking-wider"
                />
              </div>
            </div>
            <div className="flex items-start justify grid gap-6 grid-cols-2 mt-12">
              <div>
                <div>
                  {!buildingWhiteLabelData?.['logo']?.['file'] ? (
                    <FileInput
                      label="Upload Logo"
                      isLabelShow={true}
                      inputSectionClass="m-0"
                      name={'logo.id'}
                      ref={register}
                    />
                  ) : (
                    <Input
                      isLabelShow={true}
                      isAnchor={true}
                      anchorHref={getUploadedImageUrl(
                        buildingWhiteLabelData?.['logo']?.['file']
                      )}
                      anchorClass="float-left"
                      removeImageClass="cross_icon"
                      label="Upload Logo"
                      labelClass=""
                      anchorText={`${splitImageName(
                        buildingWhiteLabelData?.['logo']?.['file']
                      )}`}
                      onRemoveImage={() => onRemoveImage()}
                    />
                  )}
                </div>
                <div className="mt-12">
                  <div className="flex">
                    <div>
                      <label className="block text-gray-600 tracking-wide">
                        Top bar
                      </label>
                      <ColorPicker
                        register={register}
                        name="topBar"
                        setValue={setValue}
                        color={buildingWhiteLabelData?.topBar}
                      />
                    </div>
                    <div className="ml-6">
                      <label className="block text-gray-600 tracking-wide">
                        Top bar text/icon
                      </label>
                      <ColorPicker
                        register={register}
                        name="topBarText"
                        setValue={setValue}
                        color={buildingWhiteLabelData?.topBarText}
                      />
                    </div>
                  </div>
                  <div className="flex mt-12">
                    <div>
                      <label className="block text-gray-600 tracking-wide">
                        Button colour
                      </label>
                      <ColorPicker
                        register={register}
                        name="buttonColor"
                        setValue={setValue}
                        color={buildingWhiteLabelData?.buttonColor}
                      />
                    </div>
                    <div className="ml-6">
                      <label className="block text-gray-600 tracking-wide">
                        Button text/icon
                      </label>
                      <ColorPicker
                        register={register}
                        name="buttonText"
                        setValue={setValue}
                        color={buildingWhiteLabelData?.buttonText}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-gray-500 text-lg tracking-wider h-full pl-6">
                <div>
                  <ul className="flex">
                    <li>
                      <button
                        className="focus:text-red-500"
                        type={ButtonType.BUTTON}
                      >
                        Desktop
                      </button>
                    </li>
                    <li>
                      <button
                        className="ml-5 focus:text-red-500"
                        type={ButtonType.BUTTON}
                      >
                        Mobile
                      </button>
                    </li>
                    <li>
                      <button
                        className="ml-5 focus:text-red-500"
                        type={ButtonType.BUTTON}
                      >
                        Mailer
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalPopup>
    </div>
  );
};

export default BuildingWhitelabellingPopup;
