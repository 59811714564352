import { Button, PrimaryLabel, TagBox } from '@alfredclub/hom-planner-theme';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  allBuildingsByTimezone,
  saveDropdownData,
  saveEventBuildings,
} from '../../../redux/event/event.action';
import LabelWithIcon from './LabelWithIcon';
import {
  getImageUrl,
  filterCommonbuildingsOfRegion,
  getSepratedTime,
} from '../../../utils';
import { TOOLTIP_TEXT } from '../../../constants/index';
import {
  tooltipTimeFormat,
  timelineTimeFormat,
} from '../../../constants/index';
import CommonLoader from '../../Common/CommonLoader';
import { useLocation } from 'react-router-dom';
import { IEventsState } from '../../../redux/event';
import StyledDropdown from './BuildingTimezoneDropdown';
import { ScheduleArray } from '../../../models';
import { IFormState } from '../../../redux/form';

const EventBuildingsDrawer: React.FC<{
  closePane: any;
  register: any;
  eventId: number;
}> = ({ closePane, eventId }) => {
  const dispatch = useDispatch();
  const { allRegionBuildings } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const { eventSetupForm: eventData, recurringSetupForm: recurringData } =
    useSelector((state: { forms: IFormState }) => state.forms);

  const [loading, setLoading] = useState(false);
  const [showLoader, setLoadingState] = useState(false);
  const [regionBuildingList, setregionBuildingList] = useState([]);

  const query = new URLSearchParams(useLocation().search);
  const isRecurring = localStorage.getItem('isRecurring') ? true : false;
  const isVirtual =
    (isRecurring ? recurringData?.['virtual'] : eventData?.['virtual']) ??
    query.get('virtual') === 'true';
  const [filteredByRegion, setfilteredByRegion] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(
    isRecurring
      ? recurringData?.timezone?.zoneValue
      : eventData?.timezone?.zoneValue
  );
  const [eventTimeZone] = useState<string>(
    isRecurring
      ? recurringData?.timezone?.zoneValue
      : eventData?.timezone?.zoneValue
  );
  useEffect(() => {
    if (isRecurring) {
      setfilteredByRegion(() => [...recurringData?.['buildings']]);
    } else {
      setfilteredByRegion(() => [...eventData?.['buildings']]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line
    eventData?.['buildings'].length,
    // eslint-disable-next-line
    recurringData?.['buildings'].length,
  ]);

  // on click add all buildings button
  const addAllBuildings = async () => {
    setLoadingState(true);
    setregionBuildingList([]);
    await dispatch(
      allBuildingsByTimezone(
        eventId,
        isVirtual ? 'all' : eventTimeZone,
        recurringData?.['recurring'] ? true : false
      )
    );
    allRegionBuildings?.forEach((item) => {
      item.buildings.forEach((regionBuildings) => {
        regionBuildingList.push({
          regionName: item.regionName,
          name: regionBuildings.name,
          id: regionBuildings.id,
          startDatetime: item.startDatetime,
          regionTimezone: item.regionTimezone,
        });
      });
    });
    setfilteredByRegion((prevData) => [...prevData, ...regionBuildingList]);
    setLoadingState(false);
  };
  // on click add EST buildings button
  const addEstBuildings = async () => {
    setLoadingState(true);
    setregionBuildingList([]);
    await dispatch(
      allBuildingsByTimezone(
        eventId,
        'US/Eastern',
        recurringData?.['recurring'] ? true : false
      )
    );
    allRegionBuildings?.forEach((item) => {
      item.buildings.forEach((regionBuildings) => {
        regionBuildingList.push({
          regionName: item.regionName,
          name: regionBuildings.name,
          id: regionBuildings.id,
          startDatetime: item.startDatetime,
          regionTimezone: item.regionTimezone,
        });
      });
    });
    setfilteredByRegion((prevData) => [...prevData, ...regionBuildingList]);
    setLoadingState(false);
  };

  // save buildings
  const onSaveClick = async (pastCheck: boolean) => {
    if (pastCheck) {
      closePane();
      return;
    }
    const buildingValue = [];
    if (filteredByRegion.length > 0) {
      filterCommonbuildingsOfRegion(filteredByRegion).forEach((item) => {
        item.buildings.forEach((buildingItem) => {
          buildingValue.push({
            regionName: item.regionName,
            name: buildingItem.name,
            id: buildingItem.id,
            startDatetime: item.startDatetime,
            regionTimezone: buildingItem.regionTimezone,
          });
        });
      });
    }
    const eventObj = {
      buildings: buildingValue,
      visibility: 1,
    };
    await dispatch(saveEventBuildings(eventObj, isRecurring));
    closePane();
  };

  useEffect(() => {
    if (allRegionBuildings?.length <= 0) {
      const fetchAllBuildings = async () => {
        await dispatch(
          allBuildingsByTimezone(
            eventId,
            isVirtual ? 'all' : eventTimeZone,
            recurringData?.['recurring'] ? true : eventData?.['recurring']
          )
        );
      };
      fetchAllBuildings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRegionBuildings?.length, dispatch]);

  const getTimeOfBuilding = () => {
    return recurringData?.scheduleEvents?.length &&
      recurringData?.scheduleEvents[0]?.startTime
      ? recurringData?.scheduleEvents.map((data: ScheduleArray, i: number) => {
          return (
            <ul key={i}>
              <li>
                {getSepratedTime(
                  data.startTime,
                  selectedTimeZone,
                  tooltipTimeFormat
                )}
              </li>
            </ul>
          );
        })
      : TOOLTIP_TEXT.events.regionalTimeMessage;
  };
  const isPastCheck = () => {
    return isRecurring
      ? recurringData?.['isPastEvent']
      : eventData?.['isPastEvent'];
  };
  return (
    <div className="pd-wrap h-full overflow-y-scroll">
      <div className="md:flex md:items-center md:justify-between md:pb-1">
        <h1 className="heading_label">Add Buildings</h1>
        <StyledDropdown
          onMenuOpen={async () => {
            setLoading(true);
            if (allRegionBuildings?.length <= 0) {
              await dispatch(
                allBuildingsByTimezone(
                  eventId,
                  isVirtual ? 'all' : eventTimeZone,
                  recurringData?.['recurring'] ? true : false
                )
              );
            }
            setLoading(false);
          }}
          loading={loading}
          isRecurring={isRecurring}
          eventData={isRecurring ? recurringData : eventData}
          placeholder="Type and search building"
          options={allRegionBuildings}
          data={filteredByRegion}
          value={filteredByRegion}
          eventId={eventId}
          onChange={async (e, { option }) => {
            if (e && option?.['singleClick']) {
              await dispatch(saveDropdownData(e, isRecurring));
              setfilteredByRegion(() => [...e]);
            }
          }}
        />
      </div>
      <div
        className={`md:flex md:items-left pb-btns ${!isVirtual && 'md:hidden'}`}
      >
        <Button
          label="All buildings"
          type="button"
          className={`mt-4 add_buildings_button mr-5 justify-between px-button ${
            isPastCheck() && 'cancel_publish'
          }`}
          isRightIcon={true}
          rightIcon={getImageUrl('Events/addtag.svg')}
          onClick={addAllBuildings}
        />
        <Button
          label="EST"
          type="button"
          className={`mt-4 add_buildings_button mr-5 justify-between px-button ${
            isPastCheck() && 'cancel_publish'
          }`}
          isRightIcon={true}
          rightIcon={getImageUrl('Events/addtag.svg')}
          onClick={addEstBuildings}
        />
      </div>
      <div className={`${!isVirtual && 'pt-8'}`}>
        <LabelWithIcon
          imgSrc={getImageUrl('Events/buildingconfig.svg')}
          labelText="Selected Buildings"
          className="img_label"
        />
        <br />
      </div>
      <div className="w-full selected_events_buildings_box">
        {filteredByRegion.length <= 0 && (
          <PrimaryLabel
            labelText="No building selected yet"
            className="no_buildings_selected"
          />
        )}
        {filteredByRegion?.length > 0 &&
          filterCommonbuildingsOfRegion(filteredByRegion).map(
            (item: any, index) => {
              return (
                item.buildings.length > 0 && (
                  <div
                    className="pt-8 flow-root break-words"
                    key={index.toString()}
                  >
                    <div className="flex flex-row">
                      <PrimaryLabel
                        className="region_name_time"
                        labelText={`${item.regionName}`}
                      />
                      {isRecurring ? (
                        <div
                          className="ml-2 pt-3"
                          data-for="recurringTime"
                          data-tip=""
                        >
                          <div
                            className="recurring-date"
                            onMouseEnter={() =>
                              setSelectedTimeZone(
                                item?.buildings[0]?.regionTimezone
                              )
                            }
                          >
                            <span>i</span>
                          </div>
                        </div>
                      ) : (
                        <PrimaryLabel
                          labelText={`${getSepratedTime(
                            eventData?.startDatetime,
                            item?.buildings[0]?.regionTimezone,
                            timelineTimeFormat
                          )}`}
                          className="region_name_time ml-1"
                        />
                      )}
                    </div>
                    {isRecurring && (
                      <ReactTooltip
                        id="recurringTime"
                        getContent={() => {
                          return getTimeOfBuilding();
                        }}
                      />
                    )}

                    {item.buildings.map((buildingItem: any) => {
                      return (
                        <TagBox
                          tagLabel={buildingItem.name}
                          key={buildingItem.id}
                          className="tagbox_building"
                          onClick={async () => {
                            const removedObjects = item.buildings.filter(
                              (data) => data.id === buildingItem.id
                            );
                            setfilteredByRegion((prevData) =>
                              prevData.filter(
                                ({ id }) => id !== removedObjects[0].id
                              )
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                )
              );
            }
          )}
        <CommonLoader loading={showLoader} />
      </div>
      <Button
        label={isPastCheck() ? 'Close' : 'Save and Close'}
        className="float-none"
        type="button"
        onClick={() => onSaveClick(isPastCheck())}
        disabled={!filteredByRegion || filteredByRegion?.length <= 0}
      />
    </div>
  );
};

export default EventBuildingsDrawer;
