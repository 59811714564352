import React, { useState } from 'react';
import { ToggleButton } from '@alfredclub/hom-planner-theme';
import FormTitle from './FormTitle';
import { Register } from '../../../../models';
import { Building } from '../../../../models/building';

const UserSection: React.FC<{
  register: Register;
  buildingData: Building;
}> = ({ register, buildingData }) => {
  const [selfRegistration, setSelfRegistration] = useState(
    buildingData?.['userRegistration']
  );
  const [amenityService, setAmenityService] = useState(
    buildingData?.['amenityService']
  );
  const [marketing, setMarketing] = useState(buildingData?.['marketingEmails']);
  const [weeklyDigest, setWeeklyDigest] = useState(
    buildingData?.['newsDigest']
  );

  return (
    <>
      <FormTitle title="Users" />
      <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-8">
        <div>
          <ToggleButton
            label="User Self-registration"
            onChange={(e) => setSelfRegistration(e.currentTarget.checked)}
            isLabelShow={selfRegistration}
            checked={selfRegistration}
            uncheckedText="No"
            checkedText="Yes"
            name="userRegistration"
            ref={register}
          />
        </div>
        <div>
          <ToggleButton
            label="Amenity service"
            onChange={(e) => setAmenityService(e.currentTarget.checked)}
            isLabelShow={amenityService}
            checked={amenityService}
            uncheckedText="No"
            checkedText="Yes"
            name="amenityService"
            ref={register}
          />
        </div>
        <div>
          <ToggleButton
            label="Marketing emails"
            onChange={(e) => setMarketing(e.currentTarget.checked)}
            isLabelShow={marketing}
            checked={marketing}
            uncheckedText="No"
            checkedText="Yes"
            name="marketingEmails"
            ref={register}
          />
        </div>
        <div>
          <ToggleButton
            label="Weekly Digest"
            onChange={(e) => setWeeklyDigest(e.currentTarget.checked)}
            isLabelShow={weeklyDigest}
            checked={weeklyDigest}
            uncheckedText="No"
            checkedText="Yes"
            name="newsDigest"
            ref={register}
          />
        </div>
      </div>
    </>
  );
};

export default UserSection;
