import React, { useRef, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { ButtonType } from '../../enums/button-type';
import { Input, Button } from '@alfredclub/hom-planner-theme';
import './ColorPicker.scss';
import { Register, SetValue } from '../../models';
import { useOutsideAlerter } from '../../hooks';

const ColorPicker: React.FC<{
  register: Register;
  name: string;
  setValue: SetValue;
  color: string;
}> = ({ register, name, setValue, color }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setColor] = useState(color ? color : '#FFFFFF');

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, setShowColorPicker);

  return (
    <div className="flex" ref={wrapperRef}>
      <Button
        label=""
        type={ButtonType.BUTTON}
        onClick={() => {
          setShowColorPicker(!showColorPicker);
        }}
        className="w-12 h-12 border rounded-r-lg border-gray-400 white-label-button"
        backgroundColor={selectedColor}
      />
      <Input
        label="color"
        isLabelShow={false}
        inputType="text"
        name={name}
        ref={register}
        onInputChange={(e) => setValue(name, e.target.value)}
        className="px-2 py-3 w-56 h-12 border rounded-l-lg border-gray-400 text-gray-800 white-label-input"
        value={color}
        inputSectionClass="w-48"
      />
      {showColorPicker && (
        <div className="absolute mt-16">
          <TwitterPicker
            color={color}
            onChange={(updatedColor: { hex: string }) => {
              setColor(updatedColor.hex);
              setValue(name, updatedColor.hex);
              setShowColorPicker(!showColorPicker);
            }}
            className="border rounded-lg border-gray-400"
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
