import React, { useEffect, useState } from 'react';
import {
  Button,
  PrimaryLabel,
  Input,
  Dropdown,
} from '@alfredclub/hom-planner-theme';
import ModalPopup from '../../ReactModal/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import './Cluster.scss';
import { getManagements } from '../../../redux/management/management.action';
import { useHistory } from 'react-router-dom';
import { addCluster, getClusterDetail } from '../../../redux/cluster';
import { IClusterState } from '../../../redux/cluster/cluster.state';
import { ILoaderState } from '../../../redux/loader/loader.state';
import { IManagementState } from '../../../redux/management/mangement.state';
import { Cluster } from '../../../models';

const AddClusterPopup: React.FC<{
  modalState: boolean;
  onClosePopup: any;
}> = ({ modalState, onClosePopup }) => {
  const { register, errors, handleSubmit, setValue } = useForm<Cluster>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { allManagements } = useSelector(
    (state: { managements: IManagementState }) => state.managements
  );
  const { addClusterData } = useSelector(
    (state: { clusters: IClusterState }) => state.clusters
  );
  const [loadingMgt, setLoading] = useState(false);
  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  // Handle Continue button click from popup
  const onSubmit = async (data: Cluster) => {
    await dispatch(addCluster(data));
  };

  // Get the latest created cluster id from addClusterData and get details of cluster
  useEffect(() => {
    async function getClusterById() {
      if (Object.keys(addClusterData).length > 0) {
        await dispatch(getClusterDetail(addClusterData?.['id']));
        history.push(
          `/data-resources/clients/clusters/${addClusterData?.['id']}`
        );
      }
    }
    getClusterById();
  }, [addClusterData, dispatch, history]);

  return (
    <ModalPopup
      isOpen={modalState}
      className="add-cluster-modal"
      shouldCloseOnOverlayClick={false}
      onRequestClose={onClosePopup}
    >
      <label className="label">Add New Cluster</label>
      <form onSubmit={handleSubmit(onSubmit)}>
        {(errors.clusterName || errors.clusterUrl) && (
          <div className="cluster-error">Please fill the all fields</div>
        )}
        <PrimaryLabel labelText="Choose Management" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Dropdown
          asyncDropdown={false}
          labelKey="companyName"
          valueKey="id"
          isLabelShow={false}
          label="Management Company"
          options={allManagements}
          onMenuOpen={async () => {
            setLoading(true);
            if (allManagements?.length === 0) {
              await dispatch(getManagements(0, 0, 'short', '', 1));
            }
            setLoading(false);
          }}
          loading={loadingMgt}
          ref={() => register({ name: 'management.id' })}
          onOptionChange={(e) => setValue('management.id', e['id'])}
          placeholder=""
          sectionClass="control-space"
        />

        <PrimaryLabel labelText="Enter Cluster Name" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Input
          name="clusterName"
          label=""
          placeholder="Type here"
          className="input-control-space"
          ref={register({ required: true })}
          isError={errors['clusterName'] ? true : false}
        />

        <PrimaryLabel labelText="Enter Cluster URL (You cannot change this later)" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Input
          name="clusterUrl"
          label=""
          rightLabel="hom.events"
          placeholder="Type here"
          className="input-control-space"
          isRightLabel={true}
          ref={register({ required: true })}
          isError={errors['clusterUrl'] ? true : false}
        />

        <Button
          label="Continue"
          isArrowShow={true}
          className={`${loading ? 'button_disable' : ''}`}
          disabled={loading}
        />
      </form>
    </ModalPopup>
  );
};

export default AddClusterPopup;
