import React, { useState, useEffect } from 'react';
import { Loader } from '@alfredclub/hom-planner-theme';
import { useFetch } from '../../hooks';
import {
  addItemsToInventoryGroups,
  createInventoryGroups,
  deleteInventoryItems,
  getInventoryItems,
} from '../../redux/inventory/inventory.action';
import { useSelector, useDispatch } from 'react-redux';
import { getImageUrl } from '../../utils';
import { useHistory } from 'react-router-dom';
import InventoryDeleteItemPopup from './DeleteItemPopup';
import AddNewItemPopup from './AddNewItemPopup';
import Footer from '../Common/Footer';
import ReactTooltip from 'react-tooltip';
import { TOOLTIP_TEXT } from '../../constants/index';
import { ILoaderState } from '../../redux/loader';
import InventoryTable from './InventoryTable';
import { IEventsState } from '../../redux/event';
const IMAGE_BASE = 'data-resources/inventory';

const InventoryItems: React.FC<{
  eventsInventory?: boolean;
  serviceInventory?: boolean;
  setTabState?: (tab: string) => void;
}> = ({ eventsInventory = false, serviceInventory = false, setTabState }) => {
  const dispatch = useDispatch();
  const { status } = useFetch(getInventoryItems);
  const [pageNo, setPage] = useState(0);

  const { push } = useHistory();
  const [modalState, modalStateToggle] = useState(false);
  const [addItemPopupState, toggleAddItemPopupState] = useState(false);
  const [itemId, setItemId] = useState(0);

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );
  const queryParams = new URLSearchParams(document.location.search);

  const deleteItem = async () => {
    await dispatch(deleteInventoryItems(pageNo, itemId));
    modalStateToggle(!modalState);
  };

  const addItem = () => {
    toggleAddItemPopupState(!addItemPopupState);
  };

  const query = new URLSearchParams(window.location.search);
  const [groupData] = useState(() =>
    query.get('groupData')
      ? JSON.parse(window.atob(query.get('groupData')))
      : null
  );
  const [itemArray, setItem] = useState(() => groupData?.['items'] ?? []);
  const { eventsInvetoryItems } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const onAddItem = async () => {
    groupData.items = itemArray;
    if (groupData.addItem) {
      await dispatch(
        addItemsToInventoryGroups(groupData.groupId, { items: groupData.items })
      );
    } else {
      await dispatch(createInventoryGroups(groupData));
    }
    push(`/data-resources/inventory?tab=groups`);
  };
  useEffect(() => {
    setTabState(queryParams.get('tab'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.get('tab')]);

  useEffect(() => {
    query.set('pageNo', pageNo.toString());
    window.history.replaceState(null, null, '?' + query.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  return status === 'fetched' ? (
    <section className="group_wrapper ml-8 mr-8">
      <InventoryDeleteItemPopup
        modalClose={() => modalStateToggle(!modalState)}
        modalState={modalState}
        headerText="Are you sure you want to delete this item?"
        subHeader="This will also remove the item from any groups it has been added to."
        buttonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
        onDelete={deleteItem}
        isDelete={true}
      />
      <AddNewItemPopup
        modalState={addItemPopupState}
        onClosePopup={() => toggleAddItemPopupState(!addItemPopupState)}
      />
      <img
        className="cursor-pointer"
        src={getImageUrl(`${IMAGE_BASE}/add-item.svg`)}
        alt="add-item"
        onClick={addItem}
        data-tip={TOOLTIP_TEXT.inventory.item.add}
      />
      <ReactTooltip />
      <InventoryTable
        data={groupData}
        setItemId={setItemId}
        modalStateToggle={modalStateToggle}
        pageNo={pageNo}
        eventsInventory={eventsInventory}
        setItem={setItem}
        itemArray={eventsInventory ? eventsInvetoryItems : itemArray}
        setPage={setPage}
        serviceInventory={serviceInventory}
      />
      {groupData && (
        <Footer
          buttonText="Add to group"
          onClick={onAddItem}
          className={`${loading ? 'button_disable' : ''}`}
          disabled={loading}
        />
      )}
    </section>
  ) : (
    <Loader
      color="#e89a89"
      position="center"
      loading={status !== 'fetched'}
      size="0.75rem"
    />
  );
};

export default InventoryItems;
