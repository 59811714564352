import React, { Fragment, useState, useEffect } from 'react';
import { sidenavMenu } from '../../constants/index';
import { SidebarProps } from '../../interfaces';
import Node from './Node';
import './Sidebar.scss';

const Sidebar: React.FC<SidebarProps> = ({ routeLabel, history }) => {
  const [sideNavMenuArray, setSideNavMenuArray] = useState(sidenavMenu);
  const paramName = history?.location?.pathname;
  const splitParam = paramName.split('/');
  const menuName =
    splitParam?.length && splitParam.length >= 3 ? splitParam[2] : '';
  const subMenuName =
    splitParam?.length && splitParam.length >= 4 ? splitParam[3] : '';
  const menuIndexValue = sidenavMenu[routeLabel].nodes.findIndex(
    (x) => x.label === menuName
  );
  const subMenuindexValue = sidenavMenu[routeLabel].nodes[
    menuIndexValue >= 0 ? menuIndexValue : 0
  ].nodes.findIndex((x) => x.label === subMenuName);
  const selectMenu = (
    index: number,
    parentIndex: number,
    subMenu: boolean
  ): void => {
    const newMenuArray = subMenu
      ? sideNavMenuArray[routeLabel].nodes[parentIndex].nodes
      : sideNavMenuArray[routeLabel].nodes;
    newMenuArray.filter((_, itemIndex) => {
      if (itemIndex !== index) {
        if (subMenu) {
          sideNavMenuArray[routeLabel].nodes[parentIndex].nodes[
            itemIndex
          ].showSelected = false;
        } else {
          if (index === 0) {
            // Just change itemIndex to index
            sideNavMenuArray[routeLabel].nodes[0].nodes[
              index
            ].showSelected = false;
          }
          sideNavMenuArray[routeLabel].nodes[itemIndex].showSelected = false;
        }
      } else {
        if (subMenu) {
          sideNavMenuArray[routeLabel].nodes[parentIndex].nodes[
            index
          ].showSelected = true;
          sideNavMenuArray[routeLabel].nodes.map((obj: any) => {
            return (obj.showSelected = false);
          });
        } else {
          if (index > 0) {
            sideNavMenuArray[routeLabel].nodes[index].showSelected = true;
          } else {
            sideNavMenuArray[routeLabel].nodes[index].showSelected = false;
          }
          if (index > 0) {
            sideNavMenuArray[routeLabel].nodes[0].nodes.map((obj: any) => {
              return (obj.showSelected = false);
            });
          } else {
            sideNavMenuArray[routeLabel].nodes[0].nodes[0].showSelected = true;
          }
        }
      }
      return sideNavMenuArray;
    });
    setSideNavMenuArray([...sideNavMenuArray]);
    // if (subMenu) {
    //   history.push(
    //     sideNavMenuArray[routeLabel].nodes[parentIndex].nodes[index].route
    //   );
    // }
  };
  useEffect(() => {
    selectMenu(
      subMenuName
        ? subMenuindexValue >= 0
          ? subMenuindexValue
          : 0
        : menuIndexValue >= 0
        ? menuIndexValue
        : 0,
      menuIndexValue >= 0 ? menuIndexValue : 0,
      subMenuName ? true : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subMenuindexValue]);
  return (
    <div className="nested-sidebar">
      <ul>
        {sideNavMenuArray[routeLabel].nodes.map((item: any, index: number) => (
          <Fragment key={index}>
            <Node
              route={item.route}
              routeLabel={item.name}
              key={index + 1}
              parentIndex={routeLabel}
              indexValue={index}
              selectMenu={selectMenu}
              subMenu={false}
              isSelected={item.showSelected}
            />
            <ul>
              {item.nodes.map((itemObj: any, count: number) => (
                <Node
                  route={itemObj.route}
                  routeLabel={itemObj.name}
                  key={count + 1}
                  parentIndex={index}
                  indexValue={count}
                  selectMenu={selectMenu}
                  subMenu={true}
                  isSelected={itemObj.showSelected}
                />
              ))}
            </ul>
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
