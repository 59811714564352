import React from 'react';
import ModalPopup from '../../../ReactModal/Modal';
import { Button, PrimaryLabel } from '@alfredclub/hom-planner-theme';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './BuildingPopup.scss';
import { deleteBuilding } from '../../../../redux/building/buildings.action';

const BuildingDeletePopup: React.FC<{
  isDelete: boolean;
  modalState: boolean;
  onArchive: () => void;
  modalClose: () => void;
  buildingId: number;
  isActive: boolean;
}> = ({
  isDelete,
  modalState,
  onArchive,
  modalClose,
  buildingId,
  isActive,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const onSubmit = async () => {
    if (isDelete) {
      await dispatch(deleteBuilding(buildingId));
    } else {
      await onArchive();
    }
    history.push(`/data-resources/clients/buildings`);
  };
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={modalClose}
      className={`delete_modal ${!isActive && 'h-16'}`}
    >
      <PrimaryLabel
        className="sub-header header_style"
        labelText={`${
          isDelete
            ? 'Are you sure you want to delete this building?'
            : `Are you sure you want to ${
                isActive ? 'deactivate' : 'activate'
              } this building?`
        }`}
      />
      <br />
      {isActive && (
        <>
          <PrimaryLabel
            labelText={`${
              isDelete
                ? 'The building would be deactivated for 30 days before permanent deletion'
                : 'This would unpublish all upcoming events that have been scheduled for this building'
            }`}
            className="sub_header_style"
          />
          <PrimaryLabel
            labelText={`${
              isDelete
                ? 'This would delete all upcoming events that have been scheduled for this building'
                : 'You can reactivate the building anytime.'
            }`}
            className={`sub_header_child ${isDelete && 'delete_building'}`}
          />
        </>
      )}
      <div className="button_div">
        <Button
          label={`${
            isDelete
              ? 'Delete Building'
              : `${isActive ? 'Deactivate' : 'Activate'} Building`
          }`}
          className={`delete_building_button ${!isActive && 'mt-16'}`}
          onClick={onSubmit}
        />
      </div>
    </ModalPopup>
  );
};

export default BuildingDeletePopup;
