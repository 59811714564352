import { Pagination, Pricing, Task, Tiny, TinyObject } from '../../models';
import { Map } from 'immutable';
import { IGroupTasks } from '../taskTemplate';

export interface CategoryArrayState {
  name: string;
  id: number;
}

export interface ServiceArrayState {
  category: string;
  id: number;
  isPackage: boolean;
  name: string;
  status: boolean;
  virtualMode: boolean;
}

export interface DocumentArrayState {
  name: string;
  id: number;
}

export interface ServiceVendorArrayState {
  vendorId: number;
  name: string;
  contact: string;
  status: boolean;
  association: number;
  designation: string;
}

export interface AssignVendorToImportedTasks {
  groups?: Tiny[];
  manuallyAssign?: boolean;
  element?: Tiny;
}

export interface TaskGroupsState {
  id?: number;
  name?: string;
  tasks?: IGroupTasks[];
}

export interface IServiceState extends Pagination {
  services: object;
  serviceCount: number;
  serviceData: object;
  categories: CategoryArrayState[];
  serviceVendorsData: object;
  servicePriceData: object;
  serviceVendors: ServiceVendorArrayState[];
  elementServiceVendors: ServiceVendorArrayState[];
  allServices: ServiceArrayState[];
  serviceDocuments: DocumentArrayState[];
  packageElementList: Tiny[];
  serviceTaskDetail: Task;
  singlePricingDetail: Pricing[];
  categoryTagsList: Tiny[];
  serviceCategoryTagsList: Tiny[];
  productionTeamList: Tiny[];
  importedServiceTask: TaskGroupsState[];
  serviceTaskGroupList: TaskGroupsState[];
  serviceVendorList: TinyObject[];
  fetchedService?: boolean;
}

export const initialServiceState: IServiceState = {
  services: {},
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  serviceCount: 0,
  serviceData: null,
  categories: [],
  allServices: [],
  serviceVendorsData: {},
  servicePriceData: {},
  serviceVendors: [],
  elementServiceVendors: [],
  serviceDocuments: [],
  packageElementList: [],
  serviceTaskDetail: {
    elementId: null,
    title: '',
    description: '',
    timeOfTask: null,
    timeValue: null,
    element: [],
    eventLead: false,
    id: null,
    manuallyAssign: false,
    elementName: '',
    documentLink: '',
  },
  singlePricingDetail: [],
  categoryTagsList: [],
  serviceCategoryTagsList: [],
  productionTeamList: [],
  importedServiceTask: [],
  serviceTaskGroupList: [],
  serviceVendorList: [],
  fetchedService: false,
};
