import React, { useState } from 'react';
import {
  Button,
  PrimaryLabel,
  Input,
  Dropdown,
} from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ModalPopup from '../ReactModal/Modal';
import './AddVendor/AddVendorForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getRegions } from '../../redux/region/regions.action';
import { IRegionState } from '../../redux/region/region.state';

interface IFormInput {
  vendorName: string;
  region: object;
}
const VendorContactPopup: React.FC<{
  vendorId: number;
  modalState: boolean;
  onClosePopup: any;
  vendorName: string;
}> = ({ modalState, onClosePopup, vendorId, vendorName }) => {
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
  } = useForm<IFormInput>();
  const dispatch = useDispatch();
  const { regions } = useSelector(
    (state: { regions: IRegionState }) => state.regions
  );
  const [loading, setLoading] = useState(false);

  const { push } = useHistory();

  const onSubmit = () => {
    push(
      `/data-resources/vendors/add-indvendor?vendorContact=${btoa(
        JSON.stringify({
          vendorData: getValues(['vendorName', 'region']),
          vendorId,
          companyVendorName: vendorName,
        })
      )}`
    );
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClosePopup}
      className="add-vendor-modal"
      shouldCloseOnOverlayClick={false}
    >
      <label className="label">Add New Vendor</label>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errors.vendorName && (
          <div className="staff-error">Please fill the name</div>
        )}
        <PrimaryLabel labelText="Enter Vendor name" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Input
          name="vendorName"
          label=""
          placeholder=""
          className="input-control-space"
          ref={register({ required: true })}
          isError={errors['vendorName'] ? true : false}
        />

        <PrimaryLabel labelText="Select Region" />
        <div className="mb-8">
          <Dropdown
            asyncDropdown={false}
            labelKey="regionName"
            valueKey="id"
            isLabelShow={false}
            label="Region"
            options={regions}
            onMenuOpen={async () => {
              setLoading(true);
              if (regions.length === 0) {
                await dispatch(getRegions(0, 0, 'short', 1));
              }
              setLoading(false);
            }}
            loading={loading}
            ref={() => register({ name: 'region' })}
            onOptionChange={(e) => {
              const region = {
                id: e['id'],
                regionName: e['regionName'],
              };
              setValue('region', region);
            }}
            placeholder="Select"
          />
        </div>

        <Button label="Continue" isArrowShow={true} />
      </form>
    </ModalPopup>
  );
};

export default VendorContactPopup;
