import React, { useState } from 'react';
import { Button, PrimaryLabel, Input } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ModalPopup from '../ReactModal/Modal';
import { getImageUrl } from '../../utils';
import { useDispatch } from 'react-redux';
import './AddVendor/AddVendorForm.scss';
import {
  clearCompanyIndividualForm,
  clearCompanyVendorForm,
} from '../../redux/form/form.action';

interface IFormInput {
  vendorName: string;
}
const AddVendorPopup: React.FC<{ modalState: boolean; onClosePopup: any }> = ({
  modalState,
  onClosePopup,
}) => {
  const { register, errors, handleSubmit } = useForm<IFormInput>();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [vendorType, setTab] = useState(1);

  const onSubmit = async (data: any) => {
    push(
      `/data-resources/vendors/${
        vendorType === 1 ? 'add-indvendor' : 'add-comvendor'
      }?vendorName=${data.vendorName}`
    );
    await dispatch(clearCompanyIndividualForm());
    await dispatch(clearCompanyVendorForm());
  };

  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onClosePopup}
      className="add-vendor-modal"
      shouldCloseOnOverlayClick={false}
    >
      <label className="label">Add New Vendor</label>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errors.vendorName && (
          <div className="staff-error">Please fill the name</div>
        )}
        <PrimaryLabel labelText="Enter Vendor name" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Input
          name="vendorName"
          label=""
          placeholder=""
          className="input-control-space"
          ref={register({ required: true })}
          isError={errors['vendorName'] ? true : false}
        />

        <PrimaryLabel labelText="Vendor Type" />
        <div className="grid grid-cols-2 gap-x-2 gap-y-0 mb-6">
          <div
            tabIndex={1}
            className={`individual_vendor ${vendorType === 1 && 'active'}`}
            onClick={() => setTab(1)}
          >
            <img src={getImageUrl('data-resources/single.svg')} alt="icon" />
            <p>Individual Vendor</p>
          </div>

          <div
            tabIndex={2}
            className={`individual_vendor ${vendorType === 2 && 'active'}`}
            onClick={() => setTab(2)}
          >
            <img src={getImageUrl('data-resources/multiple.svg')} alt="icon" />
            <p>Company Vendor</p>
          </div>
        </div>

        <Button label="Continue" isArrowShow={true} />
      </form>
    </ModalPopup>
  );
};

export default AddVendorPopup;
