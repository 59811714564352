import React, { useState } from 'react';
import {
  Button,
  PrimaryLabel,
  Dropdown,
  Input,
  Timepicker,
  Loader,
} from '@alfredclub/hom-planner-theme';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import ModalPopup from '../ReactModal/Modal';
import {
  getImageUrl,
  loadState,
  getUTCTime,
  getESTTime,
  getUSEasternTimeZone,
  getDateFormatted,
} from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import EventCreationTabs from '../Common/EventCreationTabs';
import {
  datepickerformat,
  timeFormat,
  MIN_DATE_FORMAT,
  EVENT_FORMAT,
} from '../../constants';
import {
  clearAssignedVendor,
  addSuperHero,
  getHeroVendors,
} from '../../redux/event/event.action';
import { Event, EventModalProps, SuperHero } from '../../models';
import '../Events/Events.scss';
import { getRegions, IRegionState } from '../../redux/region';
import { getVendorsForSuperHero } from '../../redux/vendor/vendors.action';
import { IFormState } from '../../redux/form';
import { getDayOfWeeks, IEventsState } from '../../redux/event';

const AddHeroVendorPopup: React.FC<EventModalProps> = ({
  toggleModal,
  modalState,
  editEventId,
  clonedEvent,
  toggleEvent,
}) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const { eventSetupForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const { register, handleSubmit, setValue, errors, reset } = useForm<Event>();
  const { vendorsForSuperHero } = useSelector((state) => state.vendors);
  const { regions } = useSelector(
    (state: { regions: IRegionState }) => state.regions
  );
  const { weekDays } = useSelector(
    (state: { events: IEventsState }) => state.events
  );
  const [regionLoading, setRegionLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [vendorLoading, setVendorLoading] = useState(false);
  const [showLoader, setLoadingState] = useState(false);
  const [dayLoading, setDayLoading] = useState(false);
  const [startDateTime, setStartDateTime] = useState('');
  const [getStartDate, setStartDate] = useState('');
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const [regionValue, setRegionValue] = useState(null);
  const [eventDetailsData] = useState(
    editEventId ? loadState('eventSetupForm') : eventSetupForm
  );
  const [recurring, setRecurring] = useState(
    eventDetailsData?.isRecurring ? true : false
  );
  const recurringEventData = eventDetailsData?.isRecurring
    ? loadState('recurringSetupForm')
    : null;
  const [virtual, setVirtual] = useState(
    eventDetailsData
      ? !recurring
        ? eventDetailsData?.virtual
          ? true
          : false
        : recurringEventData?.['virtual']
        ? true
        : false
      : true
  );
  const [editEventCheck] = useState<boolean>(editEventId ? true : false);

  const editSelectedDate =
    eventDetailsData?.startDatetime &&
    getESTTime(
      eventDetailsData.startDatetime,
      datepickerformat,
      eventDetailsData?.timezone?.zoneValue
    );
  const onSubmit = async (props) => {
    setLoadingState(true);
    const {
      selectedDate,
      region,
      startDate,
      endDate,
      startDatetime,
      endDatetime,
      vendor,
      dow,
    } = props;
    const eventObj: SuperHero = {
      vendor,
      region,
    };
    const firstDay = new Date(currentYear, currentMonth, 1);
    const initialDate = moment(firstDay).format(EVENT_FORMAT);
    if (recurring) {
      eventObj['startDate'] = getDateFormatted(startDate, EVENT_FORMAT);
      eventObj['endDate'] = getDateFormatted(endDate, EVENT_FORMAT);
      eventObj['startTime'] = startDatetime;
      eventObj['endTime'] = endDatetime;
      eventObj['virtual'] = virtual;
      eventObj['dow'] = dow;
      eventObj['recurring'] = recurring;
    } else {
      eventObj['startDatetime'] = getUTCTime(
        selectedDate,
        startDatetime,
        selectedTimeZone
      );
      eventObj['endDatetime'] = getUTCTime(
        selectedDate,
        endDatetime,
        selectedTimeZone
      );
      eventObj['sourceDate'] = getDateFormatted(selectedDate, EVENT_FORMAT);
      eventObj['recurring'] = recurring;
      eventObj['virtual'] = virtual;
    }
    await dispatch(clearAssignedVendor());
    await dispatch(addSuperHero(eventObj, recurring));
    await dispatch(getHeroVendors(10, 0, 'short', 0, initialDate));
    setLoadingState(false);
    if (toggleEvent) {
      toggleEvent();
    }
  };
  const setEventTypeMethod = (value: boolean) => {
    if (!editEventId) {
      setRecurring(value);
      reset();
    }
  };
  const setEventModeMethod = (value: boolean) => {
    if (!editEventId) {
      setVirtual(value);
    }
  };

  const selectTimeMethod = (value: string, check: number) => {
    if (check === 1) {
      setValue('startDatetime', value);
      setStartDateTime(value);
    } else {
      setValue('endDatetime', value);
    }
  };
  const minSelectedDate =
    getStartDate && moment(getStartDate).format(MIN_DATE_FORMAT);

  const selectDateMethod = (value: string, check: number) => {
    if (check === 1) {
      setValue('startDate', value);
      setStartDate(value);
    } else {
      setValue('endDate', value);
    }
  };

  return (
    <ModalPopup
      isOpen={modalState}
      className="add_event_modal"
      onRequestClose={async () => {
        toggleModal();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {showLoader ? (
          <Loader
            color="#e89a89"
            position="center"
            loading={true}
            size="0.75rem"
          />
        ) : (
          <div className="main_section grid grid-cols-1 gap-x-2 gap-y-0">
            <label className="header_label">
              {editEventId
                ? clonedEvent
                  ? 'Edit clone event'
                  : 'Edit event'
                : 'Superhero Sub Setup'}
            </label>
            <PrimaryLabel
              labelText="Select parameters"
              className="subheader_label"
            />
            <>
              <div
                className={`grid grid-cols-2 gap-x-2 gap-y-0 mb-6 ${
                  editEventId && 'disable-eventType'
                }`}
              >
                <div className="flex justify-end">
                  <EventCreationTabs
                    tabType={false}
                    eventType={recurring}
                    onTabClick={() => setEventTypeMethod(false)}
                    imgSrc={getImageUrl(
                      !recurring
                        ? 'Events/singleselected.svg'
                        : 'Events/singleunselected.svg'
                    )}
                    tabName="Single event"
                    editEventCheck={editEventCheck}
                  />
                </div>
                <div className="flex justify-start">
                  <EventCreationTabs
                    tabType={true}
                    eventType={recurring}
                    onTabClick={() => setEventTypeMethod(true)}
                    imgSrc={getImageUrl(
                      recurring === true
                        ? 'Events/recurring-select.svg'
                        : 'Events/recurring-unselect.svg'
                    )}
                    imgClass="img-recurring"
                    tabName="Recurring"
                    editEventCheck={editEventCheck}
                  />
                </div>
              </div>
            </>
            <>
              <div
                className={`grid grid-cols-2 gap-x-2 gap-y-0 mb-6 ${
                  editEventId && 'disable-eventType'
                }`}
              >
                <div className="flex justify-end">
                  <EventCreationTabs
                    tabType={true}
                    eventType={virtual}
                    onTabClick={() => setEventModeMethod(true)}
                    imgSrc={
                      virtual
                        ? getImageUrl('Events/virtual-unselect.svg')
                        : getImageUrl('Events/virtual-select.svg')
                    }
                    imgClass="img-recurring"
                    tabName="Virtual"
                    editEventCheck={editEventCheck}
                  />
                </div>
                <div className="flex justify-start">
                  <EventCreationTabs
                    tabType={false}
                    eventType={virtual}
                    onTabClick={() => setEventModeMethod(false)}
                    imgSrc={
                      virtual
                        ? getImageUrl('Events/inperson-unselect.svg')
                        : getImageUrl('Events/inperson-select.svg')
                    }
                    imgClass="img-recurring"
                    tabName="In-person"
                    editEventCheck={editEventCheck}
                  />
                </div>
              </div>
            </>
            <div
              className={`flex mb-6 justify-center ${!recurring && 'hidden'}`}
            >
              <div>
                <PrimaryLabel labelText="Start Date" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  className="date-picker-width"
                  isLabelShow={false}
                  label=""
                  ref={() =>
                    register({ name: 'startDate' }, { required: recurring })
                  }
                  onDateChange={(date) => selectDateMethod(date, 1)}
                  showDatepicker={true}
                  selectedDate={
                    recurringEventData &&
                    new Date(recurringEventData?.['startDate'])
                  }
                  placeholder={datepickerformat}
                  labelClass="date_label_width"
                  isError={errors['startDate'] ? true : false}
                  minDate={new Date()}
                />
              </div>
              <div className="justify-center pt-12 px-2">
                <img src={getImageUrl('Events/Arrow.svg')} alt="icon" />
              </div>
              <div>
                <PrimaryLabel labelText="End Date" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Input
                  className="date-picker-width"
                  isLabelShow={false}
                  label=""
                  ref={() =>
                    register({ name: 'endDate' }, { required: recurring })
                  }
                  onDateChange={(date) => selectDateMethod(date, 2)}
                  showDatepicker={true}
                  selectedDate={
                    recurringEventData &&
                    new Date(recurringEventData?.['endDate'])
                  }
                  placeholder={datepickerformat}
                  labelClass="date_label_width"
                  isError={errors['endDate'] ? true : false}
                  minDate={
                    minSelectedDate ? new Date(minSelectedDate) : new Date()
                  }
                />
              </div>
            </div>

            <div className={`grid w-max my-0 mx-auto`}>
              <div className={`mb-6 ${recurring && 'hidden'}`}>
                <PrimaryLabel labelText="Selected Date" />
                <Input
                  isLabelShow={false}
                  label=""
                  ref={() =>
                    register(
                      { name: 'selectedDate' },
                      { required: !recurring ? true : false }
                    )
                  }
                  onDateChange={(date) => setValue('selectedDate', date)}
                  showDatepicker={true}
                  minDate={new Date()}
                  placeholder={datepickerformat}
                  labelClass="date_label_width"
                  isError={errors['selectedDate'] ? true : false}
                  selectedDate={editSelectedDate && new Date(editSelectedDate)}
                  disabled={
                    eventDetailsData?.['isPastEvent']
                      ? clonedEvent
                        ? false
                        : true
                      : false
                  }
                />
              </div>
              <div className={`mb-4 input-w ${!recurring && 'hidden'}`}>
                <PrimaryLabel labelText="Select Day" />
                <Dropdown
                  asyncDropdown={false}
                  labelKey="fullName"
                  valueKey="id"
                  isLabelShow={false}
                  label="WeekDay"
                  options={weekDays}
                  onMenuOpen={async () => {
                    setDayLoading(true);
                    if (weekDays.length === 0) {
                      await dispatch(getDayOfWeeks());
                    }
                    setDayLoading(false);
                  }}
                  loading={dayLoading}
                  ref={() => register({ name: 'dow' }, { required: false })}
                  onOptionChange={async (e) => {
                    const dow = {
                      id: e['id'],
                      name: e['fullName'],
                    };
                    setValue('dow', dow);
                  }}
                  placeholder="Select"
                  value={''}
                  ddlClass="mb-0"
                />
              </div>
              <div className="mb-3 flex">
                <div>
                  <Timepicker
                    className="cursor-pointer"
                    labelName="Start time"
                    onTimeChange={(e) => selectTimeMethod(e, 1)}
                    minTime={''}
                    ref={() =>
                      register(
                        { name: 'startDatetime' },
                        { required: !recurring ? true : false }
                      )
                    }
                    isError={errors['startDatetime'] ? true : false}
                    selectedTime={
                      eventDetailsData?.startDatetime
                        ? getUSEasternTimeZone(
                            eventDetailsData?.startDatetime,
                            timeFormat,
                            eventDetailsData?.timezone?.zoneValue
                          )
                        : ''
                    }
                  />
                </div>
                <div className="px-2 justify-center items-center flex">
                  <img src={getImageUrl('Events/Arrow.svg')} alt="icon" />
                </div>
                <div className="float-right">
                  <Timepicker
                    className="cursor-pointer"
                    labelName="End time"
                    onTimeChange={(e) => selectTimeMethod(e, 2)}
                    minTime={startDateTime}
                    ref={() =>
                      register(
                        { name: 'endDatetime' },
                        { required: !recurring ? true : false }
                      )
                    }
                    isError={errors['endDatetime'] ? true : false}
                    selectedTime={
                      eventDetailsData?.endDatetime
                        ? getUSEasternTimeZone(
                            eventDetailsData?.endDatetime,
                            timeFormat,
                            eventDetailsData?.timezone?.zoneValue
                          )
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
            <div className="grid justify-center">
              <div className="mb-2 input-w">
                <PrimaryLabel labelText="Select Region" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Dropdown
                  asyncDropdown={false}
                  labelKey="regionName"
                  valueKey="id"
                  isLabelShow={false}
                  label="Region"
                  options={regions}
                  onMenuOpen={async () => {
                    setRegionLoading(true);
                    if (regions.length === 0) {
                      await dispatch(getRegions(0, 0, 'short', 1));
                    }
                    setRegionLoading(false);
                  }}
                  loading={regionLoading}
                  ref={() => register({ name: 'region' }, { required: true })}
                  onOptionChange={async (e) => {
                    const region = {
                      id: e['id'],
                      name: e['regionName'],
                    };
                    setValue('region', region);
                    setSelectedTimeZone(e['regionTimezone']);
                    setRegionValue(region.id);
                  }}
                  placeholder="Select"
                  value={''}
                  ddlClass="mb-0"
                  sectionClass={
                    Object.keys(errors).length > 0
                      ? ' event-category-space'
                      : 'event-category-without-error'
                  }
                />
              </div>
              <div className="mb-6">
                <PrimaryLabel labelText="Select Vendor" />
                <PrimaryLabel labelText="*" className="text-red-500" />
                <Dropdown
                  disabled={selectedTimeZone ? false : true}
                  asyncDropdown={false}
                  labelKey="name"
                  valueKey="id"
                  isLabelShow={false}
                  label="Vendor"
                  options={vendorsForSuperHero}
                  onMenuOpen={async () => {
                    setVendorLoading(true);
                    await dispatch(
                      getVendorsForSuperHero(virtual ? null : regionValue)
                    );
                    setVendorLoading(false);
                  }}
                  ref={() => register({ name: 'vendor' }, { required: true })}
                  onOptionChange={async (e) => {
                    const vendor = {
                      id: e['id'],
                      name: e['name'],
                    };
                    setValue('vendor', vendor);
                  }}
                  loading={vendorLoading}
                  placeholder="Select"
                  value={''}
                  ddlClass="mb-0"
                  sectionClass={
                    Object.keys(errors).length > 0
                      ? ' event-category-space'
                      : 'event-category-without-error'
                  }
                />
              </div>
              <div className="pb-12">
                {Object.keys(errors).length > 0 && (
                  <div className="event-error-message">
                    <span>Please fill fields marked above*</span>
                  </div>
                )}
                <Button
                  className="create-event-button w-full"
                  label={editEventId ? 'Save Event' : 'Mark Superhero'}
                  disabled={
                    eventDetailsData?.['isPastEvent']
                      ? clonedEvent
                        ? false
                        : true
                      : false
                  }
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </ModalPopup>
  );
};

export default AddHeroVendorPopup;
