import {
  Dropdown,
  Loader,
  PrimaryLabel,
  Table,
  Topnav,
} from '@alfredclub/hom-planner-theme';
import React, { useEffect, useState } from 'react';
import FormTitle from '../Clients/Building/AddBuilding/FormTitle';
import { useDispatch, useSelector } from 'react-redux';
import { getBuildings } from '../../redux/building/buildings.action';
import { useForm } from 'react-hook-form';
import './Users.scss';
import AddUserPopup from './AddUserPopup';
import {
  getBuildingUsers,
  getUserDetail,
  setSelectedBuilding,
} from '../../redux/user/users.action';
import { useHistory } from 'react-router-dom';
import { usePagination } from '../../hooks';
import { TOOLTIP_TEXT } from '../../constants/index';
import { IBuildingState } from '../../redux/building';
import { IUserState } from '../../redux/user';
import { getSerialNumber } from '../../utils';

const UserDashboard: React.FC = () => {
  const { register } = useForm();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [pageNo, setPage] = useState(0);
  const pagination = usePagination();

  const { allBuildings } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  const { pages, usersCount, selectedBuilding } = useSelector(
    (state: { users: IUserState }) => state.users
  );
  const [showPopup, setPopupState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('idle');
  const [buildingType, setBuildingType] = useState('');
  const [buildingId, setBuildingId] = useState(
    selectedBuilding ? selectedBuilding?.['id'] : 0
  );
  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);

  const handleOptionChange = async (e: any) => {
    setBuildingType(e.buildingType);
    setBuildingId(e.id);
    await dispatch(setSelectedBuilding(e));
  };

  const handleUserNameClick = async (e: any) => {
    setLoadingState(true);
    setRowState(e.row.values.id);
    await dispatch(getUserDetail(e.row.original.id));
    push(`/data-resources/users/${e.row.original.id}`);
  };

  const onAddUser = (e: any) => {
    e.preventDefault();
    setPopupState(true);
  };

  const onClosePopup = (e: Event) => {
    e.preventDefault();
    setPopupState(false);
  };

  const onPageClick = async (page) => {
    await pagination(
      pages,
      page - 1,
      getBuildingUsers(buildingId, 10, page - 1),
      true
    );
    setPage(page - 1);
  };

  let timer = null;
  const onSearch = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          setStatus('fetching');
          const data = await dispatch(
            getBuildingUsers(0, 10, 0, 'short', inputValue, 1)
          );
          setStatus('fetched');
          res(data);
        }, 1000))
    );
  };
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  useEffect(() => {
    async function fetchData() {
      if (buildingId > 0) {
        setStatus('fetching');
        await dispatch(getBuildingUsers(buildingId));
        setStatus('fetched');
        setPage(0);
      }
    }
    fetchData();
  }, [buildingId, dispatch]);

  return (
    <div className="main_wrapper">
      <Topnav
        headerTitle="Users"
        showIcon={true}
        showSearchBar={true}
        placeholder="Search user name"
        onClick={(e) => onAddUser(e)}
        toolTipText={TOOLTIP_TEXT.users.new}
        promiseOptions={onSearch}
        optionLabel="name"
        optionKey="id"
        cacheOptions={false}
        onSearchOptionSelect={async (option) => {
          if (option) {
            setLoadingState(true);
            setRowState(option.id);
            await dispatch(getUserDetail(option.id));
            push(`/data-resources/users/${option.id}`);
          }
        }}
      />
      {showPopup && (
        <AddUserPopup modalState={showPopup} onClosePopup={onClosePopup} />
      )}
      <div className="mx-10 user_section pt-8">
        <form>
          <div className="grid grid-cols-2 gap-x-2 gap-y-0 pb-4 ddl_left_margin">
            <div>
              <PrimaryLabel labelText="Building name" />
              <Dropdown
                asyncDropdown={false}
                labelKey="name"
                valueKey="id"
                isLabelShow={false}
                label="Building"
                options={allBuildings}
                onMenuOpen={async () => {
                  setLoading(true);
                  if (allBuildings?.length <= 0) {
                    await dispatch(getBuildings(0, 0, 'short', '', 1));
                  }
                  setLoading(false);
                }}
                loading={loading}
                ref={() => register({ name: 'building' }, { required: false })}
                onOptionChange={(e) => handleOptionChange(e)}
                placeholder="Select Building"
                value={{
                  name: selectedBuilding?.['name'],
                  id: selectedBuilding?.['id'],
                }}
              />
            </div>
          </div>
          <PrimaryLabel
            labelText={`${
              buildingType === 'R'
                ? 'Residential'
                : buildingType === ''
                ? ''
                : 'Commercial'
            } property`}
          />
          <FormTitle title={`${usersCount ? usersCount : 0} Tenants`} />
          <div>
            {(status === 'fetched' || usersCount > 0) && (
              <Table
                tableColumns={[
                  {
                    Header: 'Sno',
                    accessor: 'id',
                    Cell: (row: any) => {
                      return (
                        <div>{getSerialNumber(pageNo, row.row.index)}</div>
                      );
                    },
                  },
                  {
                    Header: 'User name',
                    accessor: 'firstName',
                    className: 'tableAnchor',
                    Cell: (row: any) => {
                      return (
                        <div>
                          {row.row.original.firstName}
                          {' ' + row.row.original.lastName}

                          <Loader
                            color="#e89a89"
                            position="center"
                            loading={
                              showLoader && setRowClick === row.row.original.id
                            }
                            size="0.75rem"
                            className={`${
                              showLoader &&
                              setRowClick === row.row.original.id &&
                              'users_row_loader'
                            }`}
                          />
                        </div>
                      );
                    },
                  },
                  {
                    Header: 'Email Address',
                    accessor: 'email',
                  },
                  {
                    Header: 'Unit',
                    accessor: 'blockName',
                  },
                ]}
                tableData={pages?.get(pageNo)?.data}
                className="mt-2"
                onCellClick={(e) => handleUserNameClick(e)}
                showPagination={!(usersCount < 10) && true}
                totalRecordsCount={usersCount}
                onPageChange={(pageNumber) => onPageClick(pageNumber)}
                onCellMouseEnter={() => {}}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default UserDashboard;
