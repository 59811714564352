import { http } from '../common/http.service';
import {
  ALL_RECORDS,
  RECORDS_CMP,
  RECORDS_LIMIT,
  RECORDS_PAGE,
} from '../../constants/index';
import { PaginatedResponse, Region, Timezone } from '../../models';
import { AxiosResponse } from 'axios';
export const getRegionsApi = async (
  cmp: string = RECORDS_CMP,
  limit: number = RECORDS_LIMIT,
  page: number = RECORDS_PAGE,
  all: number = ALL_RECORDS
): Promise<PaginatedResponse<Region>> =>
  await http
    .get<PaginatedResponse<Region>>(
      `base/region?cmp=${cmp}&limit=${limit}&page=${page}&all=${all}`,
      {
        errorHandler: true,
      }
    )
    .then((response: AxiosResponse<PaginatedResponse<Region>>) => {
      return response.data;
    });

export const getTimezoneApi = async (): Promise<PaginatedResponse<Timezone>> =>
  await http
    .get<PaginatedResponse<Timezone>>(`/base/zones/`, {
      errorHandler: true,
    })
    .then((response: AxiosResponse<PaginatedResponse<Timezone>>) => {
      return response.data;
    });
