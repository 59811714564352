import { Building, Pagination } from '../../models';
import { Map } from 'immutable';

export interface IBuildingState extends Pagination {
  buildings: object;
  buildingCount: number;
  buildingData: object;
  allBuildings: Building[];
  allBuildingsByManagement: any[];
  buildingCodeStatus: boolean;
  isBuildingId: number;
  isBuildingDetailFetched: boolean;
}

export const initialBuildingState: IBuildingState = {
  buildings: {},
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  buildingCount: 0,
  buildingData: {},
  allBuildings: [],
  allBuildingsByManagement: [],
  buildingCodeStatus: false,
  isBuildingId: null,
  isBuildingDetailFetched: false,
};
