import React, { useState, useEffect } from 'react';
import CommonLayout from '../../CommonLayout/CommonLayout';
import { useSelector } from 'react-redux';
import { Loader } from '@alfredclub/hom-planner-theme';
import AddClusterPopup from './AddClusterPopup';
import {
  getClusterDetail,
  getClusters,
} from '../../../redux/cluster/cluster.action';
import { useFetch, usePagination } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TOOLTIP_TEXT } from '../../../constants/index';
import { getSerialNumber } from '../../../utils';
import { IClusterState } from '../../../redux/cluster';

const ClusterListing = () => {
  const { status } = useFetch(getClusters);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showBuildingAddCompany, setBuildingAddCompany] = useState(false);
  const [pageNo, setPage] = useState(0);
  const { pages, clusterCount } = useSelector(
    (state: { clusters: IClusterState }) => state.clusters
  );
  const pagination = usePagination();
  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);

  const onPageClick = async (page) => {
    await pagination(pages, page - 1, getClusters);
    setPage(page - 1);
  };

  let timer = null;
  const onSearch = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getClusters(10, 0, 'short', inputValue, 1, false)
          );
          res(data);
        }, 1000))
    );
  };
  // Cluster name click handler
  const handleOnCellClick = async (e: any) => {
    setLoadingState(true);
    setRowState(e.row.values.id);
    await dispatch(getClusterDetail(e.row.values.id));
    history.push(`clusters/${e.row.values.id}`);
  };

  const onBuildingAddCompany = (e: any) => {
    e.preventDefault();
    setBuildingAddCompany(true);
  };

  const onClosePopup = (e: Event) => {
    e.preventDefault();
    setBuildingAddCompany(false);
  };
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  return (
    <div>
      {showBuildingAddCompany && (
        <AddClusterPopup
          modalState={showBuildingAddCompany}
          onClosePopup={onClosePopup}
        />
      )}
      {status === 'fetched' ? (
        <CommonLayout
          headerTitle="Clusters"
          showTable={true}
          promiseOptions={onSearch}
          optionLabel="clusterName"
          optionKey="id"
          navIconToolTipText={TOOLTIP_TEXT.clusters.new}
          showSortButton={true}
          tableColumnsData={pages?.get(pageNo)?.data}
          tableColumnsHeaders={[
            {
              Header: 'Sno',
              accessor: 'id',
              Cell: (row: any) => {
                return <div>{getSerialNumber(pageNo, row.row.index)}</div>;
              },
            },
            {
              Header: 'Cluster name',
              className: 'tableAnchor', // use this class for highlight the column value
              accessor: (row: any) => (
                <>
                  {row.clusterName}
                  <Loader
                    color="#e89a89"
                    position="center"
                    loading={showLoader && setRowClick === row.id}
                    size="0.75rem"
                    className={`${
                      showLoader && setRowClick === row.id && 'row_loader'
                    }`}
                  />
                </>
              ),
            },
            {
              Header: 'Management',
              accessor: 'managementName', // accessor is the "key" in the data
            },
            {
              Header: 'No. of Bldg',
              accessor: 'buildingCount',
              className: 'cluster_col_last',
            },
          ]}
          showSearchBar={true}
          showIcon={true}
          showPagination={!(clusterCount < 10) && true}
          recordsCount={clusterCount}
          onPageChange={(pageNumber) => onPageClick(pageNumber)}
          onCellClick={(e) => handleOnCellClick(e)}
          onClick={(e) => onBuildingAddCompany(e)}
          placeholder="Search by cluster"
          onSearchOptionSelect={async (option) => {
            if (option) {
              setLoadingState(true);
              setRowState(option.id);
              await dispatch(getClusterDetail(option.id));
              history.push(`clusters/${option.id}`);
            }
          }}
        />
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
    </div>
  );
};
export default ClusterListing;
