import { Pagination, User, Vendor } from '../../models';
import { Map } from 'immutable';

export interface IAccountsState extends Pagination {
  users?: User[];
  usersCount: number;
  staffUsers: object;
  staffUsersCounts: number;
  vendorUsers?: Vendor[];
  vendorUsersCount: number;
  emailError: boolean;
  userIds: number[];
}

export const initialAccountState: IAccountsState = {
  pages: Map().set(0, {
    data: [],
    fetched: false,
  }),
  usersCount: 0,
  staffUsers: {},
  staffUsersCounts: 0,
  vendorUsersCount: 0,
  emailError: false,
  userIds: [],
};
