import React, { useEffect, useState } from 'react';
import CommonTabComponent from '../../Common/CommonTabComponent';
import { getImageUrl } from '../../../utils';
import EventAttendees from './EventAttendees';
import EventRsvps from './EventRsvps';
import { getEventUsers, IEventsState } from '../../../redux/event';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const RSVPAttendeesListing = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { search } = window.location;
  const query = new URLSearchParams(search);
  const tab = query.get('tab');
  const [status, setStatus] = useState('idle');
  const dispatch = useDispatch();

  const { eventUsers } = useSelector(
    (state: { events: IEventsState }) => state.events
  );

  const columns = [
    {
      Header: 'User name',
      Cell: ({ row }) => {
        return <span>{row.original.userName}</span>;
      },
      className: 'tableAnchor',
    },
    {
      Header: 'Rating',
      Cell: ({ row }) => {
        return (
          <span className="inline-flex">
            {row.original.rating}
            <img src={getImageUrl(`Events/starrating.svg`)} alt="star" />
          </span>
        );
      },
      className: 'table-cell tbl_rating',
    },
    {
      Header: `Review`,
      Cell: ({ row }) => {
        return <span>{row.original.feedback}</span>;
      },
      className: 'table-cell tbl_feedback',
    },
    {
      Header: 'Building',
      Cell: ({ row }) => {
        return <span>{row.original.buildingName}</span>;
      },
    },
    {
      Header: 'Origin',
      Cell: ({ row }) => {
        return <span>{row.original.origin}</span>;
      },
    },
    {
      Header: `${tab === 'total-rsvps' ? 'RSVPed' : 'Attended'}`,
      Cell: ({ row }) => {
        return (
          <>
            {tab === 'total-rsvps' ? (
              <span>
                {row.original.rsvp ? (
                  <img src={getImageUrl(`Events/tick.svg`)} alt="tick" />
                ) : (
                  ''
                )}
              </span>
            ) : (
              <span>
                {row.original.attended ? (
                  <img src={getImageUrl(`Events/tick.svg`)} alt="tick" />
                ) : (
                  ''
                )}
              </span>
            )}
          </>
        );
      },
      className: 'table-cell table_rsvp',
    },
  ];

  const onTabClick = async (tabName: string) => {
    setStatus('fetching');
    await dispatch(
      getEventUsers(Number(eventId), tabName === 'total-rsvps' ? 1 : 0)
    );
    setStatus('fetched');
  };

  useEffect(() => {
    if (tab) {
      (async () => await onTabClick(tab))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-10 py-6 event-listing-section">
      <CommonTabComponent onTabClick={onTabClick} className="tab_margin">
        <div
          data-label="Total-RSVPs"
          data-click={true}
          data-class={true}
          data-count={eventUsers?.['rsvp']}
        >
          <EventRsvps
            status={status}
            columns={columns}
            eventId={Number(eventId)}
          />
        </div>
        <div
          data-label="Total-Attendees"
          data-click={true}
          data-class={true}
          data-count={eventUsers?.['attended']}
        >
          <EventAttendees
            status={status}
            columns={columns}
            eventId={Number(eventId)}
          />
        </div>
      </CommonTabComponent>
    </div>
  );
};
export default RSVPAttendeesListing;
