import React, { useState, useEffect } from 'react';
import CommonLayout from '../CommonLayout/CommonLayout';
import { useSelector, useDispatch } from 'react-redux';
import { Loader, TagBox } from '@alfredclub/hom-planner-theme';
import ReactTooltip from 'react-tooltip';
import {
  getVendors,
  getCompanyVendorDetail,
  getVendorIndividualDetail,
  getFilteredVendors,
  setVendorTable,
  setFilteredVendors,
} from '../../redux/vendor/vendors.action';
import { usePagination, useFetch } from '../../hooks';
import { useHistory, useLocation } from 'react-router-dom';
import AddVendorPopup from './AddVendorPopup';
import './AddVendor/AddVendorForm.scss';
import { TOOLTIP_TEXT } from '../../constants/index';
import { getSerialNumber } from '../../utils';
import { IVendorState } from '../../redux/vendor';
import {
  clearCompanyIndividualForm,
  clearCompanyVendorForm,
} from '../../redux/form';
import { clearVendorServices } from '../../redux/service';

const VendorListing = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [addVendorPopup, setAddVendorPopup] = useState(false);
  const [showLoader, setLoadingState] = useState(false);
  const [setRowClick, setRowState] = useState(null);
  const [pageNo, setPage] = useState(0);
  const [vendorType, setVendorType] = useState(
    queryParams.get('type') === 'individual' ? 1 : 2
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { status } = useFetch(getVendors, [10, 0, 'short', '', 0, vendorType]);
  const { pages, vendorCount, vendorFilters, isTable } = useSelector(
    (state: { vendors: IVendorState }) => state.vendors
  );
  const pagination = usePagination();
  const onPageClick = async (page: number) => {
    await dispatch(setVendorTable(false));
    Object.keys(vendorFilters).length > 0
      ? await pagination(
          pages,
          page - 1,
          getFilteredVendors(
            10,
            page - 1,
            'short',
            vendorFilters?.['regions']?.[0]?.['id'],
            vendorFilters?.['categoryTags']?.[0]?.['id']
          ),
          true
        )
      : await pagination(
          pages,
          page - 1,
          getVendors(10, page - 1, 'short', '', 0, vendorType),
          true
        );
    setPage(page - 1);
    await dispatch(setVendorTable(true));
  };

  const handleOnCellClick = async (e: any) => {
    await dispatch(clearVendorServices());
    await dispatch(clearCompanyVendorForm());
    await dispatch(clearCompanyIndividualForm());
    await dispatch(setFilteredVendors({}));
    setLoadingState(true);
    setRowState(e.row.original.vendorId);
    if (vendorType > 1) {
      await dispatch(getCompanyVendorDetail(e.row.original.vendorId));
      push(`vendors/company/${e.row.original.vendorId}`);
      return;
    }
    await dispatch(getVendorIndividualDetail(e.row.original.vendorId));
    push(`/data-resources/vendors/${e.row.original.vendorId}`);
  };

  const onBuildingAddCompany = async (e: any) => {
    e.preventDefault();
    setAddVendorPopup(true);
    await dispatch(clearCompanyVendorForm());
    await dispatch(clearCompanyIndividualForm());
  };

  const onClosePopup = (e: Event) => {
    e.preventDefault();
    setAddVendorPopup(false);
  };
  const onTabChange = async () => {
    setVendorType(vendorType === 1 ? 2 : 1);
    push(
      `/data-resources/vendors?type=${
        vendorType === 1 ? 'company' : 'individual'
      }`
    );
    setLoading(true);
    await dispatch(getVendors(10, 0, 'short', '', 0, vendorType === 1 ? 2 : 1));
    setLoading(false);
  };
  let timer = null;
  const onSearch = async (inputValue) => {
    clearTimeout(timer);
    return new Promise(
      (res) =>
        (timer = setTimeout(async () => {
          const data = await dispatch(
            getVendors(
              10,
              0,
              'short',
              inputValue,
              1,
              vendorType === 1 ? 1 : 2,
              false
            )
          );
          res(data);
        }, 1000))
    );
  };
  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  const getCategories = (categories: number[]) => {
    return categories?.map((data: number, i: number) => {
      return (
        <span key={i}>{` ${data}${
          i + 1 !== categories.length ? ',' : ''
        }`}</span>
      );
    });
  };

  const selectCategory = async (e) => {
    setRowState(e.row.index);
  };
  return (
    <div>
      {addVendorPopup && (
        <AddVendorPopup
          modalState={addVendorPopup}
          onClosePopup={onClosePopup}
        />
      )}
      {status === 'fetching' || loading || !isTable ? (
        <Loader
          color="#e89a89"
          position="center"
          loading={true}
          size="0.75rem"
        />
      ) : (
        <CommonLayout
          headerTitle="Vendors"
          showTable={true}
          navIconToolTipText={TOOLTIP_TEXT.vendors.new}
          showTabs={true}
          tableColumnsData={pages?.get(pageNo)?.data}
          tableColumnsHeaders={[
            {
              Header: 'Sno',
              accessor: 'id',
              Cell: (row: any) => {
                return <div>{getSerialNumber(pageNo, row.row.index)}</div>;
              },
            },
            {
              Header: 'Vendor name',
              className: 'tableAnchor',
              accessor: (row: any) => (
                <>
                  {row.vendorName}
                  <Loader
                    color="#e89a89"
                    position="center"
                    loading={showLoader && setRowClick === row.vendorId}
                    size="0.75rem"
                    className={`${
                      showLoader && setRowClick === row.vendorId && 'row_loader'
                    }`}
                  />
                </>
              ),
            },
            {
              Header: `${vendorType === 1 ? 'Category tag' : ''}`,
              className: 'table-cell category-tags',
              accessor: 'categoryTags',
              Cell: (row: any) => {
                if (vendorType > 1) {
                  return <></>;
                }
                return (
                  <>
                    <div className="flex">
                      {row.row.original?.categoryTags?.length > 0 &&
                        row.row.original?.categoryTags.map(
                          (category: string, index: number) => {
                            if (index > 1 || category === null) {
                              return <></>;
                            }
                            return (
                              <div key={index}>
                                <TagBox
                                  tagLabel={category}
                                  className="tagbox_position_selected_building pointer-events-none"
                                  hideRemoveTagIcon={true}
                                />
                              </div>
                            );
                          }
                        )}
                      {row.row.original?.categoryTags?.length > 2 && (
                        <div className="mt-3" data-tip="" data-for="categories">
                          <span>
                            +{row.row.original?.categoryTags?.length - 2}
                          </span>
                        </div>
                      )}
                    </div>
                    {row.row.original?.categoryTags?.length > 0 &&
                      setRowClick === row.row.index && (
                        <ReactTooltip
                          id="categories"
                          getContent={() => {
                            return getCategories(
                              row.row.original?.categoryTags
                            );
                          }}
                        />
                      )}
                  </>
                );
              },
            },
            {
              Header: `${vendorType > 1 ? 'HQ Contact Name' : ''}`,
              accessor: 'hqContactName',
            },
            {
              Header: 'Contact',
              accessor: 'contact',
            },
            {
              Header: `${vendorType === 1 ? 'Market' : ''}`,
              accessor: `region`,
            },
            {
              Header: 'Status',
              accessor: (row: any) => (!row.status ? 'Inactive' : 'Active'),
            },
          ]}
          tabType={vendorType}
          isTabShow={true}
          showSearchBar={true}
          showIcon={true}
          showPagination={!(vendorCount < 10) && true}
          recordsCount={vendorCount}
          onPageChange={(pageNumber) => onPageClick(pageNumber)}
          onCellClick={(e) => handleOnCellClick(e)}
          onClick={(e) => onBuildingAddCompany(e)}
          onTabChange={onTabChange}
          tabFirst="Individual"
          tabSecond="Company"
          placeholder="Search Vendor"
          tblClassName="vendor_listing"
          promiseOptions={onSearch}
          optionLabel="vendorName"
          optionKey="vendorId"
          cacheOptions={false}
          onCellMouseEnter={(e) => selectCategory(e)}
          onSearchOptionSelect={async (option) => {
            if (option) {
              await dispatch(clearCompanyVendorForm());
              await dispatch(clearCompanyIndividualForm());
              setLoadingState(true);
              setRowState(option.vendorId);
              if (vendorType > 1) {
                await dispatch(getCompanyVendorDetail(option.vendorId));
                push(`vendors/company/${option.vendorId}`);
                return;
              }
              await dispatch(getVendorIndividualDetail(option.vendorId));
              push(`/data-resources/vendors/${option.vendorId}`);
            }
          }}
          vendorTab={vendorType === 1 ? true : false}
          setPage={setPage}
        />
      )}
    </div>
  );
};
export default VendorListing;
