import React, { useEffect, useState } from 'react';
import { Button, Topnav } from '@alfredclub/hom-planner-theme';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadState } from '../../../utils';
import { usePageRefresh } from '../../../hooks';
import {
  updateCompanyIndividualForm,
  updateContactVendorInCompanyVendor,
  clearCompanyIndividualForm,
} from '../../../redux/form/form.action';
import BasicDetails from './BasicDetails';
import WorkDetails from './WorkDetails';
import VenueServices from './VenueServices';
import OnlineServices from './OnlineServices';
import VendorBio from './VendorBio';
import Certifications from './Certification';
import DeleteElementPopup from '../../Common/DeleteElementPopup';
import CommonNavigation from '../../Common/CommonNavigation';
import {
  createIndividualVendor,
  deleteVendor,
  getVendorIndividualDetail,
  setVendorFetchState,
  updateIndividualVendor,
} from '../../../redux/vendor/vendors.action';
import './AddVendorForm.scss';
import { imageUpload } from '../../../redux/common/common.action';
import { IFormState } from '../../../redux/form';
import { ILoaderState } from '../../../redux/loader';
import { Vendor } from '../../../models';
import { IVendorState } from '../../../redux/vendor/vendor.state';
import CommonLoader from '../../Common/CommonLoader';

const AddVendorForm = () => {
  const { replace, location, push } = useHistory();
  const { search } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const vendorId = parseInt(params['vendorId'], 10);
  const { vendorIndividualForm } = useSelector(
    (state: { forms: IFormState }) => state.forms
  );
  const [isEdit] = useState(vendorId ? true : false);
  const [vendorData, refreshIndividualForm] = useState(
    isEdit ? loadState('vendorIndividualForm') : vendorIndividualForm
  );
  const query = new URLSearchParams(search);
  const [vendorName, setVendorName] = useState(
    query.get('vendorName') ?? vendorData?.['name']
  );

  const [showVendorDelete, setShowVendorDelete] = useState({
    showModal: false,
    isDelete: false,
  });
  usePageRefresh(true, vendorId ? false : true);

  // Decrypt the value of add vendor contact popup fields
  const [vendorContact] = useState(
    query.get('vendorContact')
      ? JSON.parse(atob(query.get('vendorContact')))
      : null
  );

  const { name, ...vendorDataWithoutName } = vendorData;
  const { register, errors, handleSubmit, setValue, getValues, reset } =
    useForm<Vendor>({
      defaultValues: {
        name: vendorContact
          ? vendorContact?.['vendorData']?.['vendorName']
          : vendorName,
        ...vendorDataWithoutName,
      },
    });

  const { loading } = useSelector(
    (state: { loading: ILoaderState }) => state.loading
  );

  const { fetchedVendor } = useSelector(
    (state: { vendors: IVendorState }) => state.vendors
  );

  const addCertificateImage = async (data, filedName) => {
    const objUpload = new FormData();
    objUpload.append('file', data[filedName]['id'][0]);
    data[filedName] = data[filedName]['id'][0]
      ? await dispatch(imageUpload(objUpload))
      : { id: null, file: null };
  };

  const uploadVendorDocImages = async (data) => {
    if (
      vendorData?.['certification']['id']?.['length'] <= 0 ||
      !vendorData?.['certification']['id']
    ) {
      await addCertificateImage(data, 'certification');
    } else {
      data.certification = vendorData?.['certification'];
    }

    if (
      vendorData?.['vendorPortrait']['id']?.['length'] <= 0 ||
      !vendorData?.['vendorPortrait']['id']
    ) {
      await addCertificateImage(data, 'vendorPortrait');
    } else {
      data.vendorPortrait = vendorData?.['vendorPortrait'];
    }

    if (
      vendorData?.['otherImages']['id']?.['length'] <= 0 ||
      !vendorData?.['otherImages']['id']
    ) {
      await addCertificateImage(data, 'otherImages');
    } else {
      data.otherImages = vendorData?.['otherImages'];
    }

    if (
      vendorData?.['vendorAgreement']['id']?.['length'] <= 0 ||
      !vendorData?.['vendorAgreement']['id']
    ) {
      await addCertificateImage(data, 'vendorAgreement');
    } else {
      data.vendorAgreement = vendorData?.['vendorAgreement'];
    }
    if (
      vendorData?.['vendorInsurance']['id']?.['length'] <= 0 ||
      !vendorData?.['vendorInsurance']['id']
    ) {
      await addCertificateImage(data, 'vendorInsurance');
    } else {
      data.vendorInsurance = vendorData?.['vendorInsurance'];
    }
  };

  const storeVendorData = (data) => {
    data['company'] = {
      id: vendorContact
        ? vendorContact?.['vendorId']
        : vendorData?.['company']?.['id']
        ? vendorData?.['company']?.['id']
        : null,
    };
    data['isActive'] = true;
    data['fitnessVendor'] = true;
    if (vendorContact) {
      data['venueServices']['serviceRegions'] = [
        vendorContact?.['vendorData']?.['region'],
      ];
    }
    data['venueServices']['atVenue'] = true;
    data['onlineServices']['atVenue'] = false;
    data['venueServices']['buildings'] =
      vendorData['venueServices']['buildings'];
    data['venueServices']['services'] =
      vendorData?.['venueServices']['services'];
    data['venueServices']['serviceRegions'] =
      data['venueServices']['serviceRegions'] ??
      vendorData['venueServices']['serviceRegions'];
    data['onlineServices']['buildings'] =
      data['onlineServices']['buildings'] ??
      vendorData['onlineServices']['buildings'];
    data['onlineServices']['serviceRegions'] =
      data['onlineServices']['serviceRegions'] ??
      vendorData['onlineServices']['serviceRegions'];
    data['onlineServices']['services'] =
      data['onlineServices']['services'] ??
      vendorData['onlineServices']['services'];
    data['timezone'] = data['timezone'] ? data['timezone'] : { id: null };
  };

  const saveAndRedirect = async (data) => {
    if (vendorContact) {
      await dispatch(updateCompanyIndividualForm(data));
      const indiviaudalVendorId = await dispatch(createIndividualVendor(data));
      if (indiviaudalVendorId) {
        const vendorObj = {
          cellPhone: data.cellPhone,
          email: data.email,
          name: data.name,
          status: true,
          vendorId: indiviaudalVendorId,
        };
        await dispatch(updateContactVendorInCompanyVendor(vendorObj));
      }
      push(
        `/data-resources/vendors/add-comvendor?vendorName=${vendorContact?.['companyVendorName']}`
      );
      return;
    }
    if (isEdit) {
      await dispatch(updateIndividualVendor(vendorId, data));
    } else {
      await dispatch(createIndividualVendor(data));
    }
    // await dispatch(clearCompanyIndividualForm());
    push(`/data-resources/vendors?type=individual`);
  };

  const onSubmit = async (data: Vendor) => {
    await storeVendorData(data);
    await uploadVendorDocImages(data);
    await saveAndRedirect(data);
  };

  const onChangeVendorName = (value: string) => {
    setVendorName(value);
    if (!isEdit) {
      replace(`${location.pathname}?vendorName=${value}`);
    }
  };

  const onDelete = async () => {
    await dispatch(deleteVendor(vendorId));
    await dispatch(clearCompanyIndividualForm());
  };

  const onArchive = async () => {
    const data = getValues();
    data['vendorAgreement'] = { id: null };
    data['vendorInsurance'] = { id: null };
    data['otherImages'] = { id: null };
    data['vendorPortrait'] = { id: null };
    data['certification'] = { id: null };
    data['company'] = { id: null };
    data['isActive'] = !vendorData?.['isActive'];
    data['fitnessVendor'] = true;
    data['venueServices']['availability'] = true;
    data['onlineServices']['atVenue'] = false;
    data['venueServices']['buildings'] =
      vendorData['venueServices']['buildings'];
    data['onlineServices']['buildings'] =
      vendorData['onlineServices']['buildings'];
    await dispatch(updateIndividualVendor(vendorId, data));
    await dispatch(clearCompanyIndividualForm());
  };

  // Handle Refresh the vendor profile page
  useEffect(() => {
    async function getVendorInfo() {
      if (vendorId) {
        await dispatch(getVendorIndividualDetail(vendorId));
      } else {
        await dispatch(setVendorFetchState());
      }
    }
    getVendorInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId]);

  // Reset the vendor profile page state
  useEffect(() => {
    if (isEdit) {
      reset(vendorData);
      refreshIndividualForm(vendorIndividualForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorIndividualForm?.name, fetchedVendor]);

  return (
    <>
      <Topnav
        headerTitle={`${
          vendorContact
            ? `Add Vendor Contact: ${vendorContact?.['vendorData']?.['vendorName']}`
            : vendorData.name !== ''
            ? vendorData.name
            : vendorName
        }`}
      />
      {showVendorDelete.showModal && (
        <DeleteElementPopup
          isDelete={showVendorDelete.isDelete}
          modalState={showVendorDelete.showModal}
          modalClose={() =>
            setShowVendorDelete({ showModal: false, isDelete: false })
          }
          onDelete={onDelete}
          onArchive={onArchive}
          headerText={`Are you sure you want to ${
            showVendorDelete.isDelete
              ? 'delete'
              : `${!vendorData?.['isActive'] ? 'activate' : 'de-activate'}`
          } this Vendor?`}
          subHeader={`${
            !vendorData?.['isActive']
              ? 'You can re-activate this vendor anytime'
              : 'Vendors are deactivated for 30 days before permanent deletion'
          }`}
          buttonLabel={`${
            showVendorDelete.isDelete
              ? 'Delete'
              : `${!vendorData?.['isActive'] ? 'Activate' : 'Deactivate'}`
          } Vendor`}
          redirectValue="/data-resources/vendors?type=individual"
          modalClass="h-64"
        />
      )}

      {fetchedVendor ? (
        <div className="mx-10 vendor-section">
          {vendorContact && (
            <CommonNavigation
              linkTo={`/data-resources/vendors/company/${vendorContact?.['vendorId']}`}
              header={vendorContact?.['vendorData']?.['vendorName']}
              subHeader="Add Vendor Contact"
              className="pt-4"
            />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              label="Save changes"
              className={`${
                loading ? 'button_disable save-changes' : 'save-changes'
              }`}
              disabled={loading}
            />
            <BasicDetails
              formErrors={errors}
              register={register}
              setValue={setValue}
              setVendorName={onChangeVendorName}
              vendorData={vendorData}
              isVendorContact={vendorContact}
            />
            <WorkDetails
              formErrors={errors}
              register={register}
              setValue={setValue}
              vendorData={vendorData}
              isEdit={isEdit}
            />
            <VenueServices
              register={register}
              setValue={setValue}
              vendorData={vendorData}
              vendorName={
                vendorName !== ''
                  ? vendorName
                  : vendorContact?.['vendorData']?.['vendorName']
              }
              isEdit={isEdit}
              vendorId={vendorId}
              isVendorContact={Boolean(query.get('vendorContact'))}
              vendorContact={query.get('vendorContact')}
              decrypedVendorContact={vendorContact}
            />
            <OnlineServices
              register={register}
              setValue={setValue}
              vendorData={vendorData}
              vendorName={
                vendorName !== ''
                  ? vendorName
                  : vendorContact?.['vendorData']?.['vendorName']
              }
              isEdit={isEdit}
              vendorId={vendorId}
              isVendorContact={Boolean(query.get('vendorContact'))}
              vendorContact={query.get('vendorContact')}
            />
            <VendorBio register={register} />
            <Certifications
              register={register}
              vendorData={vendorData}
              reset={reset}
              refreshIndividualForm={refreshIndividualForm}
            />
          </form>
          {isEdit && (
            <>
              <div className="de-activate">
                <label
                  onClick={() => {
                    setShowVendorDelete({ showModal: true, isDelete: false });
                  }}
                >
                  {' '}
                  {vendorData.isActive ? 'Deactivate' : 'Activate'} Vendor
                </label>
                <span>You can reactivate this vendor anytime.</span>
              </div>
              <div className="delete_building_text">
                <label
                  onClick={() => {
                    setShowVendorDelete({ showModal: true, isDelete: true });
                  }}
                >
                  Delete Vendor
                </label>
                <span>
                  Vendors are deactivated for 30 days before permanent deletion
                </span>
              </div>
            </>
          )}
        </div>
      ) : (
        <CommonLoader loading={!fetchedVendor} />
      )}
    </>
  );
};

export default AddVendorForm;
