import React from 'react';
import {
  Button,
  PrimaryLabel,
  Input,
  Dropdown,
} from '@alfredclub/hom-planner-theme';
import ModalPopup from '../../ReactModal/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getManagements } from '../../../redux/management/management.action';
import { getBuildingsByManagement } from '../../../redux/building/buildings.action';
import { useHistory } from 'react-router-dom';
import './Spaces.scss';
import { IBuildingState } from '../../../redux/building/Building.state';
import { IManagementState } from '../../../redux/management/mangement.state';

interface IFormInput {
  spaceName: string;
  companyName: object;
  building: object;
}
const AddNewSpace: React.FC<{
  modalState: boolean;
  onCloseAddNewSpace: any;
}> = ({ modalState, onCloseAddNewSpace }) => {
  const {
    register,
    errors,
    handleSubmit,
    getValues,
    setValue,
  } = useForm<IFormInput>();
  const history = useHistory();
  const onSubmit = () => {
    history.push(
      `/data-resources/clients/spaces/add-space?building=${btoa(
        JSON.stringify(getValues(['building', 'spaceName']))
      )}`
    );
  };
  const dispatch = useDispatch();
  const { allManagements } = useSelector(
    (state: { managements: IManagementState }) => state.managements
  );
  const { allBuildingsByManagement } = useSelector(
    (state: { buildings: IBuildingState }) => state.buildings
  );
  return (
    <ModalPopup
      isOpen={modalState}
      onRequestClose={onCloseAddNewSpace}
      className="add-space-modal"
      shouldCloseOnOverlayClick={false}
    >
      <label className="label">Add New Space</label>
      <form onSubmit={handleSubmit(onSubmit)}>
        {(errors.spaceName || errors.companyName || errors.building) && (
          <div className="cluster-error">All fields are required</div>
        )}
        <PrimaryLabel labelText="Choose Management" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Dropdown
          asyncDropdown={false}
          labelKey="companyName"
          valueKey="id"
          isLabelShow={false}
          label="Management Company"
          options={allManagements}
          onMenuOpen={async () => {
            if (
              allManagements.length <= 0 ||
              Object.keys(allManagements).length <= 0
            ) {
              await dispatch(getManagements(0, 0, 'short', '', 1));
            }
          }}
          ref={() => register({ name: 'companyName.id' }, { required: true })}
          onOptionChange={async (e) => {
            setValue('companyName.id', e['id']);
            await dispatch(getBuildingsByManagement(e['id']));
          }}
          ddlClass="ddl-space"
        />

        <PrimaryLabel labelText="Choose Building" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Dropdown
          asyncDropdown={false}
          labelKey="name"
          valueKey="id"
          isLabelShow={false}
          label="Building"
          options={allBuildingsByManagement}
          ref={() => register({ name: 'building' }, { required: true })}
          onOptionChange={(e) => {
            const building = { id: e['id'], name: e['name'] };
            setValue('building', building);
          }}
          ddlClass="ddl-space"
        />

        <PrimaryLabel labelText="Enter Space name" />
        <PrimaryLabel labelText="*" className="text-red-500" />
        <Input
          name="spaceName"
          label=""
          placeholder="Type here"
          className="input-space"
          isRightLabel={false}
          isLabelShow={false}
          ref={register({ required: true })}
        />

        <Button label="Continue" isArrowShow={true} />
      </form>
    </ModalPopup>
  );
};

export default AddNewSpace;
