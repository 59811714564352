import React, { useState, useEffect } from 'react';
import EventsInventory from './EventsInventory';
import { Button, Loader } from '@alfredclub/hom-planner-theme';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useFetch } from '../../../../hooks';
import { useDispatch } from 'react-redux';
import { getEventsInventory } from '../../../../redux/event';
import { getServiceInventory, serviceVendors } from '../../../../redux/service';
import { clearServiceVendorForm } from '../../../../redux/form/form.action';
import Footer from '../../../Common/Footer';

const EventInventory: React.FC<{
  serviceInventory?: boolean;
}> = ({ serviceInventory }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const serviceIdEdit = parseInt(params['serviceId'], 10);
  const [serviceType] = useState(Number(query.get('serviceType')));
  const [serviceId] = useState(Number(query.get('id')));
  const [isEdit] = useState(serviceIdEdit ? true : false);
  const onAddInventory = () => {
    push(
      `/data-resources/inventory?tab=groups&${
        serviceInventory
          ? `service=${
              isEdit ? params['serviceId'] : serviceId
            }&serviceType=${serviceType}`
          : `event=${params['eventId']}`
      }`,
      serviceInventory
        ? {
            serviceInventory: true,
            eventsInventory: true,
            isEdit,
          }
        : {
            eventsInventory: true,
          }
    );
  };
  const { status } = useFetch(
    serviceInventory ? getServiceInventory : getEventsInventory,
    serviceInventory
      ? isEdit
        ? [Number(params['serviceId'])]
        : [serviceId]
      : [Number(params['eventId'])]
  );
  useEffect(() => {
    async function clearVendorForm() {
      await dispatch(clearServiceVendorForm());
    }
    clearVendorForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = async () => {
    if (!isEdit) {
      await dispatch(serviceVendors(Number(serviceId)));
      push(
        `/data-resources/services/add-service?tab=vendors&serviceType=${serviceType}&id=${serviceId}`
      );
    } else {
      await dispatch(serviceVendors(Number(params['serviceId'])));
      push(
        `/data-resources/services/edit-service/${params['serviceId']}/?tab=vendors&serviceType=${serviceType}&id=${params['serviceId']}`
      );
    }
  };
  return (
    <div className="inventory-wrap">
      <div className="h-16">
        <Button
          className="float-left mr-2"
          label="Add Inventory"
          type="button"
          onClick={onAddInventory}
        />
      </div>
      {status === 'fetched' ? (
        <EventsInventory
          serviceInventory={serviceInventory}
          serviceId={isEdit ? Number(params['serviceId']) : serviceId}
        />
      ) : (
        <Loader
          color="#e89a89"
          position="center"
          loading={status !== 'fetched'}
          size="0.75rem"
        />
      )}
      {serviceInventory && (
        <Footer buttonText="Continue" isArrow={true} onClick={onSubmit} />
      )}
    </div>
  );
};

export default EventInventory;
